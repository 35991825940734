import request from "axios";

/* ACTIONS
================================================================================================ */
const FETCH_ALL = "crowdspring/admin/FETCH_ALL_PERIODIC_TASKS";
const FETCH_ALL_SUCCESS = "crowdspring/admin/FETCH_ALL_PERIODIC_TASKS_SUCCESS";

/* HELPERS
================================================================================================ */

/* INITIAL STATES
================================================================================================ */
const initialState = {
  list: [],
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };
    default:
      return state;
  }
}

export const fetchAllIfNeeded = () => (dispatch, getState) => {
  if (getState().admin.periodicTasks.list.length > 0) {
    return Promise.resolve();
  }

  return dispatch({
    type: FETCH_ALL,
    promise: request.get("/api/v1/radmin/periodic-tasks/"),
  });
};
