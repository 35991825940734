import React from "react";
import PropTypes from "prop-types";

const Header = ({ enabled }) => (
  <div className="row header-middle-content">
    <div className="small-16 columns">
      <h2 className="middle-left-title">
        {enabled === undefined
          ? "Enabling Payoneer account..."
          : enabled
          ? "Success!"
          : "Whoops!"}
      </h2>
    </div>
  </div>
);

export default Header;
