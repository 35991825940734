import request from "axios";
import { getAPIQueryString } from "common/utils";
import { savePageCount } from "common/pager/ducks";
import { CAMPAIGN_BLOCKED_DOMAINS_RESOURCE_NAME } from "wasp/constants";
import { setBannerError } from "error";
import { showBanner, TYPE_SUCCESS } from "common/ducks/banner";
import { toggleActionConfirmation } from "admin/ducks/common";

/* ACTIONS
================================================================================================ */
const FETCH = "wasp/campaign_blocked_domains/FETCH";
const FETCH_REQUEST = "wasp/campaign_blocked_domains/FETCH_REQUEST";
const FETCH_SUCCESS = "wasp/campaign_blocked_domains/FETCH_SUCCESS";

const SAVE = "wasp/campaign_blocked_domains/SAVE";
const SAVE_SUCCESS = "wasp/campaign_blocked_domains/SAVE_SUCCESS";

const CREATE = "wasp/campaign_blocked_domains/CREATE";
const CREATE_SUCCESS = "wasp/campaign_blocked_domains/CREATE_SUCCESS";

const DELETE = "wasp/campaign_blocked_domains/DELETE";
const DELETE_SUCCESS = "wasp/campaign_blocked_domains/DELETE_SUCCESS";

/* HELPERS
================================================================================================ */
export const uploadRequest = (campaignId, data) => {
  return request.post(
    `/api/v1/campaigns/${campaignId}/blocked_domains/upload_csv/`,
    {
      csv_string: data.file,
    }
  );
};

/* INITIAL STATES
================================================================================================ */
const initialState = {
  items: null,
  itemCount: 0,
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_REQUEST:
      return {
        ...state,
        items: null,
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        items: action.payload.results,
        itemCount: action.payload.count,
      };
    case SAVE_SUCCESS:
      return {
        ...state,
        items: state.items
          ? state.items.map((d) =>
              d.id === action.payload.id ? action.payload : d
            )
          : state.items,
      };
    case CREATE_SUCCESS:
      return {
        ...state,
        items: state.items ? [action.payload].concat(state.items) : state.items,
        itemCount: state.itemCount + 1,
      };
    case DELETE_SUCCESS:
      return {
        ...state,
        items: state.items
          ? state.items.filter((d) => d.id !== action.id)
          : state.items,
        itemCount: Math.max(state.itemCount - 1, 0),
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const fetch = (campaignId, query) => (dispatch) => {
  const queryStr = getAPIQueryString(
    CAMPAIGN_BLOCKED_DOMAINS_RESOURCE_NAME,
    query
  );
  const promise = request.get(
    `/api/v1/campaigns/${campaignId}/blocked_domains/?${queryStr}`
  );

  /* save page count in pager state */
  promise.then((res) =>
    dispatch(savePageCount(CAMPAIGN_BLOCKED_DOMAINS_RESOURCE_NAME, res))
  );

  return dispatch({
    type: FETCH,
    promise,
  });
};

export const save = (campaignId, id, formData) => (dispatch) => {
  const promise = request.patch(
    `/api/v1/campaigns/${campaignId}/blocked_domains/${id}/`,
    formData
  );

  promise
    .then(() => {
      dispatch(
        showBanner(
          "Success",
          "Blocked domain successfully saved.",
          undefined,
          TYPE_SUCCESS,
          null,
          3000
        )
      );
    })
    .catch((err) => {
      const errorMsg = err.data
        ? JSON.stringify(err.data)
        : "Make sure the data you entered is correct.";
      dispatch(setBannerError("Error saving blocked domain", errorMsg));
    });

  dispatch({
    type: SAVE,
    promise,
  });

  return promise;
};

export const create = (campaignId, data) => (dispatch) => {
  return dispatch({
    type: CREATE,
    promise: request.post(
      `/api/v1/campaigns/${campaignId}/blocked_domains/`,
      data
    ),
  });
};

export const remove = (campaignId, id) => (dispatch) => {
  const promise = request.delete(
    `/api/v1/campaigns/${campaignId}/blocked_domains/${id}/`
  );

  promise
    .then(() => {
      dispatch(toggleActionConfirmation(null));
    })
    .catch((err) => {
      const errorMsg = err.data ? JSON.stringify(err.data) : "Unkown error.";
      dispatch(setBannerError("Error removing domain from list", errorMsg));
    });

  return dispatch({
    type: DELETE,
    promise,
    id,
  });
};
