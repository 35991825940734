import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import Header from "header/components/title";
import { Meta } from "common/components/meta";
import { getCanonicalURL } from "common/utils";

const ForumsHeaderTitle = () => <Header title="Goodbye forums" />;
const PMsHeaderTitle = () => <Header title="Goodby private messages" />;

const Base = ({ children }) => (
  <div className="l-content" id="content">
    <div className="row">
      <div className="column small-16 medium-10">{children}</div>
    </div>
  </div>
);

Base.propTypes = {
  children: PropTypes.any,
};

const Wrapper = withRouter(Base);

const GoodbyeForums = () => (
  <div>
    <Meta
      title="Forums"
      meta={[{ name: "robots", content: "noindex,follow" }]}
      link={[{ rel: "canonical", href: getCanonicalURL("/goodbye-forums/") }]}
    />

    <p>
      We launched forums in 2008 but in recent years, we found that people
      communicate with us mostly through social networks or through our customer
      support team. As part of our recent upgrade, we removed the forums so that
      we can focus on the channels our customers and creatives are using.
    </p>
    <p>If you have any questions, please contact our support team.</p>
  </div>
);

const GoodbyePMs = () => (
  <div>
    <Meta
      title="Private messages"
      meta={[{ name: "robots", content: "noindex,follow" }]}
      link={[
        {
          rel: "canonical",
          href: getCanonicalURL("/goodbye-privatemessages/"),
        },
      ]}
    />

    <p>
      As part of our recent site upgrade, we removed private messages (PMs).
      While PMs were sometimes useful, they were often abused by spammers and a
      constant source of frustration for customers, creatives and our team. In
      rethinking our entire product, we found a better way to help people
      communicate without using private messages. For example, the
      communications between a client and a creative on an entry are always
      private.
    </p>
    <p>If you have any questions, please contact our support team.</p>
  </div>
);

const Deprecations = {
  Wrapper,
  GoodbyeForums,
  GoodbyePMs,
  ForumsHeaderTitle,
  PMsHeaderTitle,
};
export default Deprecations;
