import { withRouter } from "react-router";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { getHomeTabs } from "brand_studio/containers/home/header_tabs";
import TabbedNavigation from "common/components/tabbed_navigation";

const mapStateToProps = (state, { router, params }) => {
  return {
    router,
    push,
    tabs: getHomeTabs({ brandId: params.id }),
  };
};

export default withRouter(connect(mapStateToProps)(TabbedNavigation));
