import request from "axios";
import { browserHistory } from "react-router";
import { setBannerError } from "error";
import { STAGE_VOTE } from "focus_groups/constants";

/* ACTIONS
================================================================================================ */
const SET_STAGE = "focus_groups/voting/SET_STAGE";

const TOGGLE_VOTING = "focus_groups/voting/TOGGLE_VOTING";

const TOGGLE_CONFIRM_VOTE = "focus_groups/voting/TOGGLE_CONFIRM_VOTE";

const SET_VOTE_INDEX = "focus_groups/voting/SET_VOTE_INDEX";

const VOTE = "focus_groups/voting/VOTE";
const VOTE_REQUEST = "focus_groups/voting/VOTE_REQUEST";
export const VOTE_SUCCESS = "focus_groups/voting/VOTE_SUCCESS";

const SET_RATING = "focus_groups/voting/SET_RATING";

const SET_COMMENT = "focus_groups/voting/SET_COMMENT";

/* HELPERS
================================================================================================ */

/* INITIAL STATES
================================================================================================ */
const initialState = {
  isVoting: false,
  stage: STAGE_VOTE,
  currentRevisionIndex: null,
  currentComment: null,
  currentRating: null,
  isConfirmingVote: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_STAGE:
      return {
        ...state,
        stage: action.payload,
      };
    case TOGGLE_VOTING:
      return {
        ...state,
        isVoting: !state.isVoting,
        currentRevisionIndex: state.currentRevisionIndex ? null : 0,
      };
    case SET_VOTE_INDEX:
      return {
        ...state,
        currentComment: null,
        currentRating: null,
        currentRevisionIndex: action.payload,
      };
    case VOTE_REQUEST:
      return {
        ...state,
        currentRating: null,
      };
    case TOGGLE_CONFIRM_VOTE:
      return {
        ...state,
        isConfirmingVote: !state.isConfirmingVote,
      };
    case SET_RATING:
      return {
        ...state,
        currentRating: action.payload,
      };
    case SET_COMMENT:
      return {
        ...state,
        currentComment: action.payload,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const setStage = (stage) => (dispatch) => {
  return dispatch({
    type: SET_STAGE,
    payload: stage,
  });
};

export const toggleVoting = () => (dispatch) => {
  return dispatch({
    type: TOGGLE_VOTING,
  });
};

export const toggleConfirmVote = () => (dispatch) => {
  return dispatch({
    type: TOGGLE_CONFIRM_VOTE,
  });
};

export const setVoteIndex = (index) => (dispatch) => {
  return dispatch({
    type: SET_VOTE_INDEX,
    payload: index,
  });
};

export const identifyAnonymousUser =
  (groupId, anonymousKey) => (dispatch, getState) => {
    const searchParams = getState().routing.locationBeforeTransitions.search;
    const config = { headers: { "X-Anonymous-Key": anonymousKey } };
    const url = `/api/v1/focus-groups/${groupId}/participants/identify/`;
    /* redirect user to vote page as the newly-created participant */
    return request
      .patch(url, {}, config)
      .then(({ data }) => {
        browserHistory.replace(
          `/focus-group-participate/${groupId}/${data.id}/${data.key}/${searchParams}`
        );
      })
      .catch(() =>
        dispatch(
          setBannerError(
            "Error identifying focus group participant",
            "Please try again."
          )
        )
      );
  };

export const vote =
  (participantKey, groupId, revisionId, data) => (dispatch) => {
    const config = { headers: { "X-Participant-Key": participantKey } };
    const url = `/api/v1/focus-groups/${groupId}/image-revisions/${revisionId}/vote/`;
    const promise = request.post(url, data, config);

    promise.catch(() =>
      dispatch(setBannerError("Error recording vote", "Please try again."))
    );

    dispatch({
      type: VOTE,
      groupId,
      promise,
    });

    return promise;
  };

export const setParticipantInfo =
  (groupId, participantId, participantKey, data) => (dispatch) => {
    const config = { headers: { "X-Participant-Key": participantKey } };
    const url = `/api/v1/focus-groups/${groupId}/participants/${participantId}/set_info/`;
    return request.patch(url, data, config);
  };

export const setRating = (rating) => (dispatch) => {
  return dispatch({
    type: SET_RATING,
    payload: rating,
  });
};

export const setComment = (comment) => (dispatch) => {
  return dispatch({
    type: SET_COMMENT,
    payload: comment,
  });
};
