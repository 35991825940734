import idx from "idx";
import request from "axios";

/* ACTIONS
 ================================================================================================ */
export const RESERVE = "crowdspring/creatives/ELITE_RESERVE";
export const RESERVE_SUCCESS = "crowdspring/creatives/ELITE_RESERVE_SUCCESS";
export const RESERVE_ERROR = "crowdspring/creatives/ELITE_RESERVE_ERROR";

/* INITIAL STATE
 ================================================================================================ */
const initialState = {
  isReserving: false,
  error: null,
};

/* REDUCERS
 ================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESERVE:
      return {
        ...state,
        isReserving: true,
        error: null,
      };
    case RESERVE_SUCCESS:
      return {
        ...state,
        isReserving: false,
        error: null,
      };
    case RESERVE_ERROR:
      return {
        ...state,
        isReserving: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const reserve = (projectId) => (dispatch, getState) => {
  const userId = idx(getState(), (_) => _.user.profile_data.id);
  const payload = {};
  payload[userId] = new Date();

  const opts = {
    method: "post",
    url: `/api/v1/projects/${projectId}/elite_reserve/`,
  };

  dispatch({ type: RESERVE });

  return request(opts)
    .then(() =>
      dispatch({
        type: RESERVE_SUCCESS,
        payload,
      })
    )
    .catch((e) =>
      dispatch({
        type: RESERVE_ERROR,
        payload: e,
      })
    );
};
