import omit from "lodash/omit";

/* ACTION CONSTANTS
================================================================================================ */
const SAVE_LAST_QUERY = "crowdspring/filter/SAVE_LAST_QUERY";

/* INITIAL STATES
================================================================================================ */
export const initialState = {
  lastQueries: {},
};

/* REDUCERS
================================================================================================ */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_LAST_QUERY:
      return {
        ...state,
        lastQueries: {
          ...state.lastQueries,
          [action.resourceName]: {
            ...state.lastQueries[action.resourceName],
            ...action.query,
          },
        },
      };
    default:
      return state;
  }
};

export default reducer;

/* ACTION CREATORS
================================================================================================ */

/* Save last query dict produced by a given filter */
export const saveLastQuery = (resourceName, query) => {
  return {
    type: SAVE_LAST_QUERY,
    resourceName,
    query,
  };
};
