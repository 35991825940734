import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, browserHistory } from "react-router";
import partial from "lodash/partial";
import { v4 } from "uuid";
import classnames from "classnames";
import onClickOutside from "react-onclickoutside";

class NavigationDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = { toggled: false };

    this.toggle = this.toggle.bind(this);
    this.handleSubItemClick = this.handleSubItemClick.bind(this);
  }

  toggle() {
    this.setState({ toggled: !this.state.toggled });
  }

  handleClickOutside() {
    if (this.state.toggled) {
      this.toggle();
    }
  }

  handleSubItemClick(url, onClick) {
    this.toggle();

    if (onClick) {
      onClick();
    } else {
      browserHistory.push(url);
    }
  }

  render() {
    const { links, right, showMore } = this.props;
    const { toggled } = this.state;
    const elements = [];
    let activeElement = null;

    links.forEach(({ url, title, isActive, subItems, onClick }) => {
      if (isActive) {
        activeElement = <span className="selected-item">{title}</span>;
      }
      const onLinkClick = () => {
        this.toggle();

        if (onClick) {
          onClick();
        }
      };

      elements.push(
        <li
          key={v4()}
          className={classnames("dropdown-item", {
            active: isActive && !subItems,
          })}
        >
          {isActive || subItems ? (
            <span>{title}</span>
          ) : (
            <Link onClick={onLinkClick} to={url}>
              {title}
            </Link>
          )}

          {subItems && (
            <ul className="sub-items">
              {subItems.map(({ component, url, isActive, onClick }) => (
                <li
                  key={v4()}
                  className={classnames("dropdown-item", { active: isActive })}
                  onClick={partial(this.handleSubItemClick, url, onClick)}
                >
                  {component}
                </li>
              ))}
            </ul>
          )}
        </li>
      );
    });

    return (
      <div className={classnames("dropdown dropdown-tabs", { right, toggled })}>
        <button type="button" className="dropdown-target" onClick={this.toggle}>
          {showMore ? "More" : activeElement}
        </button>
        {toggled && <ul className="dropdown-menu">{elements}</ul>}
      </div>
    );
  }
}

NavigationDropdown.propTypes = {
  links: PropTypes.array.isRequired,
  right: PropTypes.bool,
  showMore: PropTypes.bool,
};

export default onClickOutside(NavigationDropdown);
