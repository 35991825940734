import request from "axios";
import uniqBy from "lodash/uniqBy";
import debounce from "lodash/debounce";
import pick from "lodash/pick";
import { v4 } from "uuid";
import { getAPIQueryString } from "common/utils";
import { savePageCount } from "common/pager/ducks";
import { DOMAINS_RESOURCE_NAME } from "wasp/constants";
import { setBannerError } from "error";
import { showBanner, TYPE_SUCCESS } from "common/ducks/banner";

/* ACTIONS
================================================================================================ */
const FETCH_DOMAINS = "wasp/domains/FETCH_DOMAINS";
const FETCH_DOMAINS_REQUEST = "wasp/domains/FETCH_DOMAINS_REQUEST";
const FETCH_DOMAINS_SUCCESS = "wasp/domains/FETCH_DOMAINS_SUCCESS";

const SET_SELECTED_DOMAINS = "wasp/domains/SET_SELECTED_DOMAINS";

const SET_PERSONALIZING_DOMAINS = "wasp/domains/SET_PERSONALIZING_DOMAINS";

const SET_PROSPECTS_ORDER = "wasp/domains/SET_PROSPECTS_ORDER";

const SAVE_PROSPECT = "wasp/domains/SAVE_PROSPECT";
const SAVE_PROSPECT_SUCCESS = "wasp/domains/SAVE_PROSPECT_SUCCESS";

const CREATE_PROSPECT = "wasp/domains/CREATE_PROSPECT";
const CREATE_PROSPECT_SUCCESS = "wasp/domains/CREATE_PROSPECT_SUCCESS";

const SET_SELECTED_PROSPECTS = "wasp/domains/SET_SELECTED_PROSPECTS";

const DELETE_PROSPECT = "wasp/domains/DELETE_PROSPECT";
const DELETE_PROSPECT_SUCCESS = "wasp/domains/DELETE_PROSPECT_SUCCESS";

const SEARCH_PROSPECTS = "wasp/domains/SEARCH_PROSPECTS";
const SEARCH_PROSPECTS_REQUEST = "wasp/domains/SEARCH_PROSPECTS_REQUEST";
const SEARCH_PROSPECTS_SUCCESS = "wasp/domains/SEARCH_PROSPECTS_SUCCESS";
const SEARCH_PROSPECTS_FAILURE = "wasp/domains/SEARCH_PROSPECTS_FAILURE";

const UPDATE_DOMAIN = "wasp/domains/UPDATE_DOMAIN";

/* HELPERS
================================================================================================ */
export const uploadDomainsRequest = (campaignId, data) => {
  return request.post(`/api/v1/campaigns/${campaignId}/domains/upload_csv/`, {
    csv_string: data.file,
  });
};

export const setProspectsOrderRequest = debounce(
  (campaignId, domainId, prospects, dispatch) => {
    const ids = prospects.map((p) => p.id);
    request
      .patch(
        `/api/v1/campaigns/${campaignId}/domains/${domainId}/set_prospects_order/`,
        { ids }
      )
      .catch((err) => {
        const errorMsg = err.data ? JSON.stringify(err.data) : "Unknown error.";
        dispatch(setBannerError("Error setting contacts' order", errorMsg));
      });
  },
  2000
);

export const updateDomainRequest = debounce(
  (id, campaignId, payload, dispatch) => {
    request
      .patch(`/api/v1/campaigns/${campaignId}/domains/${id}/`, payload)
      .catch((err) => {
        const errorMsg = err.data
          ? JSON.stringify(err.data)
          : "Make sure the data you entered is correct.";
        dispatch(setBannerError("Error updating domain", errorMsg));
      });
  },
  2000
);

export const fetchAllDomainsRequest = (
  campaignId,
  stage,
  query,
  excludeIds
) => {
  const queryStr = getAPIQueryString(DOMAINS_RESOURCE_NAME, query, {
    excludePager: true,
    extraAPIQuery: { stage, exclude_ids: excludeIds },
  });
  return request.get(
    `/api/v1/campaigns/${campaignId}/domains/all/?${queryStr}`
  );
};

/* INITIAL STATES
================================================================================================ */
const initialState = {
  domains: null,
  domainCount: 0,
  selectedDomains: { mode: "inclusion", items: [] },
  personalizingDomains: [],
  selectedProspects: [],
  searchingProspects: false,
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  const { payload } = action;

  switch (action.type) {
    case FETCH_DOMAINS_REQUEST:
      return {
        ...state,
        domainsRequestId: action.requestId,
        domains: null,
      };
    case FETCH_DOMAINS_SUCCESS:
      /* avoid wrong results due to parallel requests */
      if (action.requestId !== state.domainsRequestId) {
        return state;
      }

      return {
        ...state,
        domains: payload.results,
        domainCount: payload.count,
        stage: action.stage,
      };
    case SET_SELECTED_DOMAINS:
      return {
        ...state,
        selectedDomains: { ...payload, items: uniqBy(payload.items, "id") },
      };
    case SET_PROSPECTS_ORDER:
      return {
        ...state,
        personalizingDomains: state.personalizingDomains.map((d) =>
          d.id === action.domainId ? { ...d, prospects: action.prospects } : d
        ),
      };
    case SET_PERSONALIZING_DOMAINS:
      return {
        ...state,
        personalizingDomains: uniqBy(payload, "id"),
      };
    case SAVE_PROSPECT_SUCCESS:
      return {
        ...state,
        personalizingDomains: state.personalizingDomains.map((d) => {
          if (d.id === payload.domain) {
            return {
              ...d,
              prospects: d.prospects.map((p) =>
                p.id === payload.id ? payload : p
              ),
            };
          }

          return d;
        }),
      };
    case CREATE_PROSPECT_SUCCESS:
      return {
        ...state,
        personalizingDomains: state.personalizingDomains.map((d) => {
          if (d.id === payload.id) {
            return {
              ...d,
              prospects: payload.prospects,
            };
          }

          return d;
        }),
      };
    case SET_SELECTED_PROSPECTS:
      return {
        ...state,
        selectedProspects: uniqBy(payload, "id"),
      };
    case DELETE_PROSPECT_SUCCESS:
      return {
        ...state,
        selectedProspects: state.selectedProspects.filter(
          (p) => p.id !== action.prospect.id
        ),
        personalizingDomains: state.personalizingDomains.map((d) => {
          if (d.id === action.prospect.domain) {
            return {
              ...d,
              prospects: d.prospects.filter((p) => p.id !== action.prospect.id),
            };
          }

          return d;
        }),
      };
    case SEARCH_PROSPECTS_REQUEST:
      return {
        ...state,
        searchingProspects: true,
      };
    case SEARCH_PROSPECTS_FAILURE:
      return {
        ...state,
        searchingProspects: false,
      };
    case SEARCH_PROSPECTS_SUCCESS:
      return {
        ...state,
        searchingProspects: false,
        personalizingDomains: state.personalizingDomains.map((d) => {
          if (d.id === action.domainId) {
            return {
              ...d,
              ...pick(action.payload, "prospects", "prospects_searched"),
            };
          }

          return d;
        }),
      };
    case UPDATE_DOMAIN:
      return {
        ...state,
        personalizingDomains: state.personalizingDomains.map((d) =>
          d.id === action.id ? { ...d, ...payload } : d
        ),
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const fetchDomains =
  (campaignId, stage, query) => (dispatch, getState) => {
    const queryStr = getAPIQueryString(DOMAINS_RESOURCE_NAME, query, {
      extraAPIQuery: { stage },
    });
    const promise = request.get(
      `/api/v1/campaigns/${campaignId}/domains/?${queryStr}`
    );
    const requestId = v4();

    /* save page count in pager state */
    promise.then((res) => {
      /* avoid wrong results due to parallel requests */
      if (getState().wasp.domains.domainsRequestId !== requestId) {
        return;
      }
      dispatch(savePageCount(DOMAINS_RESOURCE_NAME, res));
    });

    return dispatch({
      type: FETCH_DOMAINS,
      requestId,
      stage,
      promise,
    });
  };

export const setSelectedDomains = (payload) => (dispatch) => {
  return dispatch({
    type: SET_SELECTED_DOMAINS,
    payload,
  });
};

export const performAction = (campaignId, data, query, stage) => (dispatch) => {
  const queryStr = getAPIQueryString(DOMAINS_RESOURCE_NAME, query, {
    excludePager: true,
    extraAPIQuery: { stage },
  });
  const promise = request.patch(
    `/api/v1/campaigns/${campaignId}/domains/process/?${queryStr}`,
    data
  );

  promise.catch((err) => {
    const errorMsg = err.data ? JSON.stringify(err.data) : "Unknown error.";
    dispatch(setBannerError("Error performing action", errorMsg));
  });

  return promise;
};

export const setPersonalizingDomains = (payload) => (dispatch) => {
  return dispatch({
    type: SET_PERSONALIZING_DOMAINS,
    payload,
  });
};

export const setProspectsOrder =
  (campaignId, domainId, collection) => (dispatch) => {
    const prospects = collection.map((item) => item.value);

    setProspectsOrderRequest(campaignId, domainId, prospects, dispatch);

    return dispatch({
      type: SET_PROSPECTS_ORDER,
      domainId,
      prospects: prospects.map((p, i) => ({ ...p, order: i + 1 })),
    });
  };

export const saveProspect = (id, formData) => (dispatch) => {
  const promise = request.patch(`/api/v1/prospects/${id}/`, {
    ...formData,
    validate_email: true,
  });

  promise
    .then(() => {
      dispatch(
        showBanner(
          "Success",
          "Contact successfully saved.",
          undefined,
          TYPE_SUCCESS,
          null,
          3000
        )
      );
    })
    .catch((err) => {
      const errorMsg = err.data
        ? JSON.stringify(err.data)
        : "Make sure the data you entered is correct.";
      dispatch(setBannerError("Error saving contact", errorMsg));
    });

  dispatch({
    type: SAVE_PROSPECT,
    promise,
  });

  return promise;
};

export const createProspect = (data) => (dispatch) => {
  return dispatch({
    type: CREATE_PROSPECT,
    promise: request.post("/api/v1/prospects/", data),
  });
};

export const setSelectedProspects = (payload) => (dispatch) => {
  return dispatch({
    type: SET_SELECTED_PROSPECTS,
    payload,
  });
};

export const deleteProspect = (prospect) => (dispatch) => {
  const promise = request.delete(`/api/v1/prospects/${prospect.id}/`);

  promise.catch((err) => {
    const errorMsg = err.data ? JSON.stringify(err.data) : "Unkown error.";
    dispatch(
      setBannerError(`Error deleting contact ${prospect.email}`, errorMsg)
    );
  });

  dispatch({
    type: DELETE_PROSPECT,
    promise,
    prospect,
  });

  return promise;
};

export const searchProspects = (domain) => (dispatch) => {
  const promise = request.post(
    `/api/v1/campaigns/${domain.campaign}/domains/${domain.id}/search_prospects/`
  );

  promise.catch((err) => {
    const errorMsg = err.data ? JSON.stringify(err.data) : "Unkown error.";
    dispatch(
      setBannerError(`Error searching contacts for ${domain.url}`, errorMsg)
    );
  });

  return dispatch({
    type: SEARCH_PROSPECTS,
    domainId: domain.id,
    promise,
  });
};

export const updateDomain = (id, campaignId, payload) => (dispatch) => {
  updateDomainRequest(id, campaignId, payload, dispatch);

  return dispatch({
    type: UPDATE_DOMAIN,
    id,
    payload,
  });
};
