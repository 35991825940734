import request from "axios";
import moment from "moment";
import { setBannerError } from "error";
import { getItemsFromString } from "common/utils";

/* ACTIONS
================================================================================================ */
const FETCH_MASS_EMAILS_SUCCESS = "admin/FETCH_MASS_EMAILS_SUCCESS";

const FETCH_UNSUBSCRIBE_LISTS = "admin/FETCH_UNSUBSCRIBE_LISTS";
const FETCH_UNSUBSCRIBE_LISTS_SUCCESS = "admin/FETCH_UNSUBSCRIBE_LISTS_SUCCESS";

const CLEAR_UNSUBSCRIBE_LISTS = "admin/CLEAR_UNSUBSCRIBE_LISTS";

/* INITIAL STATES
================================================================================================ */
export const initialState = {
  massEmails: [],
  unsubscribeLists: null,
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CLEAR_UNSUBSCRIBE_LISTS:
      return {
        ...state,
        unsubscribeLists: null,
      };
    case FETCH_UNSUBSCRIBE_LISTS_SUCCESS:
      return {
        ...state,
        unsubscribeLists: action.payload,
      };
    case FETCH_MASS_EMAILS_SUCCESS:
      return {
        ...state,
        massEmails: action.payload,
      };
    default:
      return state;
  }
}

const processDate = (dateString) => {
  const date = moment(dateString, "MM/DD/YYYY HH:mm");
  if (date.isValid()) {
    return date.format();
  }
  window.alert("The date you provided is not valid.");
};

export const adminActions =
  (action, formData, method = "patch") =>
  (dispatch) => {
    const data = { ...formData };

    let url = `/api/v1/radmin/reporting/${action}/`;

    if (data.recipients) {
      data.recipients = getItemsFromString(data.recipients, "\n");
    }

    if (action === "conversion_track_by_utm_tags" && data.start) {
      data.start = processDate(data.start);
      if (!data.start) {
        return Promise.reject();
      }
    } else if (action === "conversion_track_by_ip") {
      data.ips = getItemsFromString(data.ips, "\n");
    } else if (action === "conversion_track_by_email") {
      data.emails = getItemsFromString(data.emails, "\n");
    } else if (
      [
        "conversion_track_focus_groups_by_date",
        "track_lead_sources_over_time",
        "conversion_track_by_marketing_segment",
        "conversion_track_by_industry",
        "additional_projects",
      ].includes(action)
    ) {
      data.start = processDate(data.start);
      if (!data.start) {
        return Promise.reject();
      }

      data.end = processDate(data.end);
      if (!data.end) {
        return Promise.reject();
      }
    }

    if (action === "conversion_track_by_utm_tags") {
      data.tag_filters = JSON.parse(data.tag_filters);
    }

    const promise = request[method](url, data);

    promise.catch((err) => {
      const errorMsg = err.data ? JSON.stringify(err.data) : "Unknown error.";
      dispatch(setBannerError("Error performing action", errorMsg));
    });

    return promise;
  };

export const fetchUnsubscribeLists = (userId) => (dispatch) => {
  return dispatch({
    type: FETCH_UNSUBSCRIBE_LISTS,
    promise: request.get(`/api/v1/radmin/users/${userId}/unsubscribe_lists/`),
  });
};

export const clearUnsubscribeLists = () => (dispatch) => {
  return dispatch({
    type: CLEAR_UNSUBSCRIBE_LISTS,
  });
};
