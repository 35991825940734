import request from "axios";

/* ACTIONS
================================================================================================ */
const FETCH_WARNINGS = "admin_verification/FETCH_WARNINGS";
const FETCH_WARNINGS_REQUEST = "admin_verification/FETCH_WARNINGS_REQUEST";
const FETCH_WARNINGS_SUCCESS = "admin_verification/FETCH_WARNINGS_SUCCESS";

const TOGGLE_PROCESS_ID_MODAL = "admin_verification/TOGGLE_PROCESS_ID_MODAL";

const CLEAR_WORK_SAMPLES = "admin_verification/CLEAR_WORK_SAMPLES";

const FETCH_IMAGE_SAMPLES = "admin_verification/FETCH_IMAGE_SAMPLES";
const FETCH_IMAGE_SAMPLES_SUCCESS =
  "admin_verification/FETCH_IMAGE_SAMPLES_SUCCESS";

const FETCH_WRITING_SAMPLES = "admin_verification/FETCH_WRITING_SAMPLES";
const FETCH_WRITING_SAMPLES_SUCCESS =
  "admin_verification/FETCH_WRITING_SAMPLES_SUCCESS";

const TOGGLE_PROCESS_SAMPLES_MODAL =
  "admin_verification/TOGGLE_PROCESS_SAMPLES_MODAL";

const TOGGLE_REPLACE_IMG_MODAL = "admin_verification/TOGGLE_REPLACE_IMG_MODAL";

const ROTATE_IMG = "admin_verification/ROTATE_IMG";
export const ROTATE_IMG_SUCCESS = "admin_verification/ROTATE_IMG_SUCCESS";

/* HELPERS
================================================================================================ */
export const rejectIdValidationRequest = (userId, data) =>
  request.put(`/api/v1/radmin/users/${userId}/reject_id_validation/`, data);

export const approveIdValidationRequest = (userId, data) =>
  request.put(`/api/v1/radmin/users/${userId}/approve_id_validation/`, data);

export const processSamplesRequest = (userId, data) =>
  request.put(`/api/v1/radmin/users/${userId}/process_work_samples/`, data);

export const replaceFieldRequest = (userId, data) =>
  request.put(
    `/api/v1/radmin/users/${userId}/replace_creative_verification_field/`,
    data
  );

const formatSamples = (payload) => {
  return payload
    .filter((s) => !!s.subcategory_group_id)
    .reduce(
      (acc, s) => ({
        ...acc,
        [s.subcategory_group_id]: s,
      }),
      {}
    );
};

/* INITIAL STATES
================================================================================================ */
const initialState = {
  warnings: [],
  showingProcessIdModal: false,
  workSamples: {},
  showingProcessSamplesModal: false,
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_WARNINGS_REQUEST:
      return {
        ...state,
        warnings: [],
      };
    case FETCH_WARNINGS_SUCCESS:
      return {
        ...state,
        warnings: action.payload.warnings.concat(action.payload.errors),
      };
    case TOGGLE_PROCESS_ID_MODAL:
      return {
        ...state,
        showingProcessIdModal: !state.showingProcessIdModal,
      };
    case CLEAR_WORK_SAMPLES:
      return {
        ...state,
        workSamples: {},
      };
    case FETCH_IMAGE_SAMPLES_SUCCESS:
      return {
        ...state,
        workSamples: {
          ...state.workSamples,
          ...formatSamples(action.payload),
        },
      };
    case FETCH_WRITING_SAMPLES_SUCCESS:
      return {
        ...state,
        workSamples: {
          ...state.workSamples,
          ...formatSamples(action.payload),
        },
      };
    case TOGGLE_PROCESS_SAMPLES_MODAL:
      return {
        ...state,
        showingProcessSamplesModal: !state.showingProcessSamplesModal,
      };
    case TOGGLE_REPLACE_IMG_MODAL:
      return {
        ...state,
        replacingImgFor: action.payload,
      };
    default:
      return state;
  }
}

export const fetchWarnings = (userId) => (dispatch) => {
  return dispatch({
    type: FETCH_WARNINGS,
    promise: request.get(
      `/api/v1/users/${userId}/registration_fraud_warnings/`
    ),
  });
};

export const toggleProcessIdModal = () => (dispatch) =>
  dispatch({
    type: TOGGLE_PROCESS_ID_MODAL,
  });

export const fetchImageSamples = (userId) => (dispatch) => {
  return dispatch({
    type: FETCH_IMAGE_SAMPLES,
    promise: request.get(`/api/v1/users/${userId}/get_image_samples/`),
  });
};

export const fetchWritingSamples = (userId) => (dispatch) => {
  return dispatch({
    type: FETCH_WRITING_SAMPLES,
    promise: request.get(`/api/v1/users/${userId}/get_writing_samples/`),
  });
};

export const clearWorkSamples = () => (dispatch) =>
  dispatch({
    type: CLEAR_WORK_SAMPLES,
  });

export const toggleProcessSamplesModal = () => (dispatch) =>
  dispatch({
    type: TOGGLE_PROCESS_SAMPLES_MODAL,
  });

export const toggleReplaceImgModal = (imageFor) => (dispatch) =>
  dispatch({
    type: TOGGLE_REPLACE_IMG_MODAL,
    payload: imageFor,
  });

export const rotateImage = (userId, fieldName, angle) => (dispatch) => {
  const fieldNameLower = fieldName.toLowerCase();

  return dispatch({
    type: ROTATE_IMG,
    promise: request.put(
      `/api/v1/radmin/users/${userId}/rotate_creative_verification_pics/`,
      {
        photograph: fieldNameLower === "selfie",
        photo_id: fieldNameLower === "id",
        angle,
      }
    ),
  });
};
