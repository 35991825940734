import request from "axios";

/* ACTIONS
================================================================================================ */
const FETCH_INDUSTRIES = "crowdspring/common/FETCH_INDUSTRIES";
const FETCH_INDUSTRIES_REQUEST = "crowdspring/common/FETCH_INDUSTRIES_REQUEST";
const FETCH_INDUSTRIES_SUCCESS = "crowdspring/common/FETCH_INDUSTRIES_SUCCESS";

/* INITIAL STATES
================================================================================================ */
const initialIndustries = {
  isFetching: false,
  data: [],
};

/* REDUCERS
================================================================================================ */
const reducer = (state = initialIndustries, action) => {
  switch (action.type) {
    case FETCH_INDUSTRIES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_INDUSTRIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;

/* ACTION CREATORS
================================================================================================ */
export const fetchIndustriesIfNeeded = () => (dispatch, getState) => {
  /* industries already fetched */
  if (getState().industry.data.length > 0) {
    return Promise.resolve();
  }

  return dispatch({
    type: FETCH_INDUSTRIES,
    promise: request.get("/api/v1/industries/"),
  });
};
