import moment from "moment";
import cookie from "react-cookie";
import {
  isCollaborator,
  canCollaborate,
  canProjectHaveFinalRound,
} from "project/utils";
import { NEGATIVE_FEEDBACK_QUESTIONS, ITEMS_BY_ROW } from "entries/constants";
import {
  MOBILE_BREAKPOINT_PX,
  TABLET_BREAKPOINT_PX,
  DESKTOP_BREAKPOINT_PX,
} from "common/variables";

export const isAtYourEntries = ({ routes }) => {
  return !!routes.find((r) => r.name === "your-entries");
};

export const canViewRating = ({ project, entry, user, atYourEntries }) => {
  return (
    !!user.id &&
    (user.is_staff ||
      user.id === project.client ||
      isCollaborator(project) ||
      atYourEntries ||
      (!!entry && user.id === entry.author))
  );
};

export const canViewNewTag = ({ project, user }) => {
  return (
    !!user.id &&
    (user.is_staff || user.id === project.client || isCollaborator(project))
  );
};

export const canRate = ({ entry, user, project }) => {
  return (
    !!user.id &&
    (user.id === project.client || canCollaborate(project, "score_entries")) &&
    ["open", "closed", "finalist_round", "finalist_closed", "awarded"].includes(
      project.status
    ) &&
    !entry.eliminated &&
    !entry.withdrawn
  );
};

export const canWithdraw = ({ entry, user }) => {
  return (
    !!user.id &&
    user.id === entry.author &&
    !entry.withdrawn &&
    !entry.award_value &&
    !parseFloat(entry.tip_value) &&
    !parseFloat(entry.offer_value)
  );
};

export const canUnwithdraw = ({ entry, user }) => {
  return (
    !!user.id &&
    user.id === entry.author &&
    entry.withdrawn &&
    !entry.withdrawn_by_admin
  );
};

export const canEliminate = ({ entry, user, project }) => {
  return (
    !!user.id &&
    user.id === project.client &&
    !entry.withdrawn &&
    !entry.eliminated &&
    ["open", "closed", "finalist_round", "finalist_closed", "awarded"].includes(
      project.status
    )
  );
};

export const canUneliminate = ({ entry, user, project }) => {
  return (
    !!user.id &&
    user.id === project.client &&
    entry.eliminated &&
    !entry.withdrawn
  );
};

export const canViewAwardValue = ({ entry, user, project }) => {
  return (
    !!user.id &&
    (project.client === user.id ||
      isCollaborator(project) ||
      entry.author === user.id)
  );
};

export const canViewTipValue = ({ entry, user, project }) => {
  return (
    !!user.id &&
    (project.client === user.id ||
      isCollaborator(project) ||
      entry.author === user.id)
  );
};

export const canViewOfferValue = ({ entry, user, project }) => {
  return (
    !!user.id &&
    (project.client === user.id ||
      isCollaborator(project) ||
      entry.author === user.id)
  );
};

export const canViewDescription = ({ entry, user, project }) => {
  return (
    !!user.id &&
    (project.client === user.id ||
      isCollaborator(project) ||
      entry.author === user.id)
  );
};

export const canViewAssetPrice = ({ entry, user, project }) => {
  return (
    !!user.id &&
    (project.client === user.id ||
      isCollaborator(project) ||
      entry.author === user.id)
  );
};

export const canFilterByCreative = ({ user, project }) => {
  return !!user.id && (project.client === user.id || isCollaborator(project));
};

export const canComment = ({ entry, user, project }) => {
  return (
    !!user.id &&
    (user.id === project.client ||
      isCollaborator(project) ||
      user.id === entry.author)
  );
};

export const canSendInternalComment = ({ user, project }) => {
  return !!user.id && (user.id === project.client || isCollaborator(project));
};

export const canSendInternalCommentOnly = ({ user, project }) => {
  return (
    !!user.id &&
    isCollaborator(project) &&
    !canCollaborate(project, "comment_entries")
  );
};

export const canTip = ({ availableAwards, entry, user, project }) => {
  return (
    !!user.id &&
    user.id === project.client &&
    !(parseFloat(entry.tip_value) || entry.withdrawn || entry.eliminated) &&
    availableAwards.length === 0
  );
};

export const canOffer = ({ availableAwards, entry, user, project }) => {
  return (
    !!user.id &&
    user.id === project.client &&
    !(
      entry.award_value ||
      parseFloat(entry.tip_value) ||
      parseFloat(entry.offer_value) ||
      entry.withdrawn ||
      entry.eliminated
    ) &&
    availableAwards.length === 0
  );
};

export const canAward = ({ user, entry, project, awardedCreatives }) => {
  const creativeAwarded = entry
    ? !!awardedCreatives.find(({ creative }) => creative === entry.author)
    : false;
  const eliteAwarded = project.elite_only && creativeAwarded;

  let can =
    !!user.id &&
    !eliteAwarded &&
    ["closed", "finalist_round", "finalist_closed", "awarded"].includes(
      project.status
    ) &&
    (project.client === user.id || canCollaborate(project, "award_creatives"));

  if (entry) {
    can =
      can &&
      !(
        entry.award_value ||
        parseFloat(entry.tip_value) ||
        parseFloat(entry.offer_value) ||
        entry.withdrawn ||
        entry.eliminated
      ) &&
      !canToggleFinalist({ project, user }) &&
      (!project.has_finalist_round ||
        (["finalist_closed", "awarded"].includes(project.status) &&
          project.finalist_ids.includes(entry.author))) &&
      (!canProjectHaveFinalRound(project) || !!getAwardsChoice(project));
  }

  return can;
};

export const canFetchAwardedCreatives = ({ user, project }) => {
  return (
    !!user.id &&
    (project.client === user.id || canCollaborate(project, "award_creatives"))
  );
};

export const canRemoveCreative = ({ user, project, entry }) => {
  return (
    !!user.id &&
    project.client === user.id &&
    ["open", "closed", "finalist_round", "finalist_closed"].includes(
      project.status
    ) &&
    !project.elite_only &&
    !project.finalist_ids.includes(entry.author)
  );
};

export const canReportViolation = ({ user, entry, project }) => {
  return (
    !!user.id &&
    (user.id === project.client ||
      isCollaborator(project) ||
      (user.is_creative && user.id !== entry.author))
  );
};

export const canShare = ({ user, project, entry }) => {
  return (
    !!user.id &&
    !project.is_one_to_one &&
    !project.search_exclusion &&
    user.id === entry.author
  );
};

export const showWinnerBanner = ({ project, atYourEntries }) => {
  return ["awarded", "completed"].includes(project.status) && !atYourEntries;
};

export const getNegativeFeedbackQuestions = (subcatSlug) => {
  return (
    NEGATIVE_FEEDBACK_QUESTIONS[(subcatSlug || "").replace("1to1-", "")] ||
    NEGATIVE_FEEDBACK_QUESTIONS["default"]
  );
};

export const getEntriesByRow = () => {
  if (typeof window === "undefined") {
    return ITEMS_BY_ROW["large"];
  }

  if (window.innerWidth <= MOBILE_BREAKPOINT_PX) {
    return ITEMS_BY_ROW["small"];
  }

  if (window.innerWidth <= TABLET_BREAKPOINT_PX) {
    return ITEMS_BY_ROW["medium"];
  }

  return ITEMS_BY_ROW["large"];
};

export const canToggleFinalist = ({ user, project }) => {
  return (
    !!user.id &&
    (project.client === user.id ||
      canCollaborate(project, "award_creatives")) &&
    !project.has_finalist_round &&
    getAwardsChoice(project) === "finalRound"
  );
};

const getAwardsChoiceCookieName = (project) => `choice_project_${project.id}`;

export const getAwardsChoice = (project) => {
  return ["closed", "finalist_round", "finalist_closed"].includes(
    project.status
  )
    ? cookie.load(getAwardsChoiceCookieName(project))
    : "award";
};

export const setAwardsChoice = (project, choice) => {
  cookie.save(getAwardsChoiceCookieName(project), choice, {
    path: "/",
    expires: moment("2100-12-12").toDate(),
  });
};

export const canViewFinalRoundFilter = ({ project, user }) => {
  return (
    !!user.id &&
    (user.is_staff || user.id === project.client || isCollaborator(project)) &&
    project.has_finalist_round
  );
};

export const canAttachToComment = ({ user, project }) => {
  return !!user.id && (user.id === project.client || isCollaborator(project));
};
