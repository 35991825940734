import React from "react";
import PropTypes from "prop-types";
import Money from "common/components/money";
import ProjectLink from "notifications/components/project_link";
import ItemizedCost from "common/components/itemized_cost";
import { OFFER_FEE } from "entries/constants";

const NegotiationItemizedCost = ({ value, label, background, title }) => {
  return (
    <div className="row columns small-16">
      <ItemizedCost
        label={label}
        className={background || ""}
        title={title}
        items={[
          {
            value,
            description: "offer amount",
          },
          {
            value: value * OFFER_FEE,
            description: "crowdspring fee",
          },
        ]}
      />
    </div>
  );
};

/*
 *
 * Entry offer negotiation actions
 *
 */
const CREATIVE_DECLINE = ({ project, entry }) => ({
  label: "Decline offer",
  nextStatus: "creative_declined",
  intro: (
    <div>
      <div className="callout info text-center">
        The client wants to pay{" "}
        <strong>
          <Money value={entry.offer_value} showDecimals={false} />
        </strong>{" "}
        for your entry
      </div>
      <p>
        When you click the red button below, you will decline the client's offer
        without sending a counter-offer.
      </p>
      <p>
        If you would like to continue to negotiate with the client and send a
        counter-offer instead, click the "Cancel" button below and pick the
        option to "Send counter-offer".
      </p>
    </div>
  ),
  hasComments: true,
  commentsLabel: "Briefly explain why you are declining the offer.",
  doneTitle: "You declined the offer",
  doneDetails: "We'll notify the client.",
});

const CREATIVE_ACCEPT = ({ project, entry }) => ({
  label: "Accept offer",
  nextStatus: "creative_accepted",
  intro: (
    <div>
      <div className="callout info text-center">
        The client wants to pay{" "}
        <strong>
          <Money value={entry.offer_value} showDecimals={false} />
        </strong>{" "}
        for your entry
      </div>
      <p>
        When you click the green button below, you will accept the client's
        offer. By accepting it, you agree to participate in a wrap-up and turn
        over the final intellectual property for this work.
      </p>
    </div>
  ),
  disclaimer:
    "I understand that by accepting this offer, I will \
               participate in a wrap-up and turn over the final intellectual property \
               for this work.",
  doneTitle: "You accepted the offer",
  doneDetails: "We'll notify the client.",
});

const SEND_COUNTER_QUOTE = ({ project, entry }) => ({
  label: "Send counter-offer",
  modalLabel: "Decline and send counter-offer",
  nextStatus: "creative_sent_counter_quote",
  intro: (
    <div>
      <div className="callout info text-center">
        The client wants to pay{" "}
        <strong>
          <Money value={entry.offer_value} showDecimals={false} />
        </strong>{" "}
        for your entry
      </div>
      <p>
        When you click the red button below, you will decline the client's offer
        and send a counter-offer (the total amount you would like to receive, in
        U.S. dollars).
      </p>
      <p>
        Enter your counter-offer below. We'll let you know if the client
        accepts, declines, or sends another counter-offer.
      </p>
    </div>
  ),
  requiresNewValue: true,
  nextValueLabel: "My price for this entry:",
  nextValueDetails: () => null,
  hasComments: true,
  commentsLabel: "Explain the counter-offer and why your price is reasonable.",
  disclaimer:
    "I understand that by providing this counter-offer, if the client accepts, I will \
               participate in a wrap-up and turn over the final intellectual property \
               for this work.",
  doneTitle: "You sent a counter-offer",
  doneDetails:
    "We'll notify the client and we'll let you know when they respond to your\
                counter-offer.",
});

const CLIENT_ACCEPT = ({ project, entry }) => ({
  label: "Accept counter-offer",
  nextStatus: "client_accepted",
  intro: (
    <div>
      <div className="callout info text-center">
        {entry.author_username} wants to receive{" "}
        <strong>
          <Money value={entry.offer_value} showDecimals={false} />{" "}
        </strong>{" "}
        for their entry
      </div>
      <div className="row align-middle">
        <div className="columns small-16 large-9">
          <p>
            When you click the green button below, you will accept the
            counter-offer from {entry.author_username}.
          </p>
        </div>
        <div className="columns small-16 large-7">
          <NegotiationItemizedCost
            value={parseInt(entry.offer_value)}
            background="gray"
            title={`Counter-offer from ${entry.author_username}`}
          />
        </div>
      </div>
    </div>
  ),
  doneTitle: "You accepted the creative's counter-offer",
  doneDetails: "We'll notify the creative.",
});

const SEND_COUNTER_OFFER = ({ project, entry }) => ({
  label: "Send counter-offer",
  modalLabel: "Decline and send counter-offer",
  nextStatus: "client_sent_counter_offer",
  intro: (
    <div>
      <div className="callout info text-center">
        {entry.author_username} wants to receive{" "}
        <strong>
          <Money value={entry.offer_value} showDecimals={false} />{" "}
        </strong>{" "}
        for their entry
      </div>
      <div className="row align-middle">
        <div className="columns small-16 large-9">
          <p>
            When you click the red button below, you will decline the
            counter-offer from {entry.author_username} and will send them a
            counter-offer.
          </p>
        </div>
        <div className="columns small-16 large-7">
          <NegotiationItemizedCost
            value={parseInt(entry.offer_value)}
            title={`Counter-offer from ${entry.author_username}`}
            label="Prior cost"
          />
        </div>
      </div>
    </div>
  ),
  requiresNewValue: true,
  nextValueLabel: "The creative will be paid:",
  nextValueDetails: ({ nextValue }) => (
    <NegotiationItemizedCost
      value={nextValue}
      label="New cost"
      background="gray"
    />
  ),
  hasComments: true,
  commentsLabel: "Explain the counter-offer and why your price is reasonable.",
  doneTitle: "You sent a counter-offer",
  doneDetails:
    "We'll notify the creative and we'll let you know when they respond to your \
                counter-offer.",
});

const CLIENT_DECLINE = ({ project, entry }) => ({
  label: "Decline counter-offer",
  nextStatus: "client_declined",
  intro: (
    <div>
      <div className="callout info text-center">
        {entry.author_username} wants to receive{" "}
        <strong>
          <Money value={entry.offer_value} showDecimals={false} />{" "}
        </strong>{" "}
        for their entry
      </div>
      <div className="row align-middle">
        <div className="columns small-16 large-9">
          <p>
            When you click the red button below, you will decline{" "}
            {entry.author_username}'s counter-offer without sending a
            counter-offer.
          </p>
          <p>
            If you would like to continue to negotiate with the them and send a
            counter-offer instead, click the "Cancel" button below and pick the
            option to "Send counter-offer".
          </p>
        </div>
        <div className="columns small-16 large-7">
          <NegotiationItemizedCost
            value={parseInt(entry.offer_value)}
            background="gray"
            title={`Counter-offer from ${entry.author_username}`}
          />
        </div>
      </div>
    </div>
  ),
  hasComments: true,
  commentsLabel: "Briefly explain why you are declining the counter-offer.",
  doneTitle: "You declined the creative's counter-offer",
  doneDetails: "We'll notify the creative.",
});

/*
 *
 * Entry offer negotiation statuses and related definitions
 *
 */
const NEGOTIATION_STATUSES = ({ entry, project }) => ({
  client_sent_offer: {
    label: "Offer Sent",
    actor: "creative",
    actions: [CREATIVE_ACCEPT, SEND_COUNTER_QUOTE, CREATIVE_DECLINE],
    notification: {
      title: (
        <span>
          You received an offer of{" "}
          <Money value={entry.offer_value} showDecimals={false} /> for
          <strong>
            {" "}
            your work on <ProjectLink project={project} />
          </strong>
          .
        </span>
      ),
    },
  },
  client_sent_counter_offer: {
    label: "Client Countered",
    actor: "creative",
    actions: [CREATIVE_ACCEPT, SEND_COUNTER_QUOTE, CREATIVE_DECLINE],
    notification: {
      title: (
        <span>
          You received a counter-offer of{" "}
          <Money value={entry.offer_value} showDecimals={false} /> for
          <strong>
            {" "}
            your work on <ProjectLink project={project} />
          </strong>
          .
        </span>
      ),
    },
  },
  client_declined: {
    label: "Declined",
    actor: "client",
    actions: [SEND_COUNTER_OFFER],
    notification: {
      title: (
        <span>
          Your counter-offer for{" "}
          <strong>
            your work on <ProjectLink project={project} />{" "}
          </strong>
          was declined.
        </span>
      ),
      declined: true,
    },
  },
  client_accepted: {
    label: "Accepted",
    actor: "client",
    actions: [],
    notification: {
      title: (
        <span>
          Your counter-offer for{" "}
          <strong>
            your work on <ProjectLink project={project} />{" "}
          </strong>
          was accepted.
        </span>
      ),
      accepted: true,
    },
  },
  creative_sent_counter_quote: {
    label: "Creative Countered",
    actor: "client",
    actions: [CLIENT_ACCEPT, SEND_COUNTER_OFFER, CLIENT_DECLINE],
    notification: {
      title: (
        <span>
          You received a counter-offer of{" "}
          <Money value={entry.offer_value} showDecimals={false} /> for an entry
          on <ProjectLink project={project} />.
        </span>
      ),
    },
  },
  creative_declined: {
    label: "Declined",
    actor: "client",
    actions: [SEND_COUNTER_OFFER],
    notification: {
      title: (
        <span>
          Your offer for an entry on <ProjectLink project={project} /> was
          declined.
        </span>
      ),
      declined: true,
    },
  },
  creative_accepted: {
    label: "Accepted",
    actor: "client",
    actions: [],
    notification: {
      title: (
        <span>
          Your offer for an entry on <ProjectLink project={project} /> was
          accepted.
        </span>
      ),
      accepted: true,
    },
  },
});

export default NEGOTIATION_STATUSES;
