import request from "axios";

/* ACTIONS
================================================================================================ */
const FETCH_DRAFTS = "crowdspring/pap0/FETCH_DRAFTS";
const FETCH_DRAFTS_SUCCESS = "crowdspring/pap0/FETCH_DRAFTS_SUCCESS";

/* HELPERS
================================================================================================ */

/* INITIAL STATES
================================================================================================ */
export const initialState = {
  drafts: [],
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DRAFTS_SUCCESS:
      return {
        ...state,
        drafts: action.payload.results,
      };
    default:
      return state;
  }
}

export const fetchDrafts = () => (dispatch) => {
  return dispatch({
    type: FETCH_DRAFTS,
    promise: request.get("/api/v1/projects/mine/?status=draft"),
  });
};
