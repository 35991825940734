import idx from "idx";

export const PROJECTS_RESOURCE_NAME = "explore_projects";

export const PROJECTS_SORTING_OPTIONS = [
  { field: "-last_awarded_at", label: "project awarded: recent first" },
  { field: "last_awarded_at", label: "project awarded: oldest first" },
  { field: "-updated_at", label: "project activity: recent first" },
  { field: "updated_at", label: "project activity: oldest first" },
  { field: "-total_awards_and_tips", label: "award: highest first" },
  { field: "total_awards_and_tips", label: "award: lowest first" },
  { field: "deadline", label: "deadline: soonest first" },
  { field: "-deadline", label: "deadline: latest first" },
  { field: "entry_count", label: "number of entries: fewer first" },
  { field: "-entry_count", label: "number of entries: more first" },
];

export const DEFAULT_PROJECTS_SORTING = (user) =>
  idx(user.profile_data, (_) => _.is_creative)
    ? "deadline"
    : "-last_awarded_at";

export const PROJECTS_TIME_LEFT_OPTIONS = [
  { value: "24h", label: "< 24 Hours" },
  { value: "48h", label: "< 48 Hours" },
  { value: "72h", label: "< 72 Hours" },
];

export const PROJECTS_STATUS_OPTIONS = [
  { field: "open", label: "Open" },
  { field: "completed", label: "Completed" },
];

export const PROJECTS_ASSURED_OPTIONS = [
  { field: "true", label: "Assured Award" },
];

export const PROJECTS_PACKAGE_OPTIONS = [
  { field: "Silver", label: "Silver" },
  { field: "Gold", label: "Gold" },
  { field: "Elite", label: "Elite" },
  { field: "Platinum", label: "Platinum" },
];

export const PROJECTS_GALLERY_OPTIONS = [
  { field: "true", label: "Private" },
  { field: "false", label: "Public" },
];

export const PROJECTS_AWARD_OPTIONS = [
  { value: "200_500", label: "$200 - $500" },
  { value: "501_1000", label: "$501 - $1000" },
  { value: "1001", label: "$1000+" },
];

export const CREATIVES_RESOURCE_NAME = "explore_creatives";

export const CREATIVES_SORTING_OPTIONS = [
  { field: "-reputation", label: "reputation: highest first" },
  { field: "reputation", label: "reputation: lowest first" },
  { field: "-date_joined", label: "account age: newest first" },
  { field: "date_joined", label: "account age: oldest first" },
];

export const DEFAULT_CREATIVES_SORTING = "-reputation";

export const CREATIVES_ELITE_OPTIONS = [{ field: "true", label: "Elite" }];

export const CREATIVES_PLATINUM_OPTIONS = [
  { field: "true", label: "Platinum" },
];

export const CREATIVES_LANGUAGE_OPTIONS = [
  { field: "ar", label: "Arabic" },
  { field: "zh", label: "Chinese" },
  { field: "nl", label: "Dutch" },
  { field: "en", label: "English" },
  { field: "fr", label: "French" },
  { field: "de", label: "German" },
  { field: "id", label: "Indonesian" },
  { field: "it", label: "Italian" },
  { field: "ja", label: "Japanese" },
  { field: "ko", label: "Korean" },
  { field: "pt", label: "Portuguese" },
  { field: "ru", label: "Russian" },
  { field: "es", label: "Spanish" },
];
