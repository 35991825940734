import React from "react";
import PropTypes from "prop-types";
import partial from "lodash/partial";
import DropdownMenu from "common/components/dropdown_menu";
import WatchlistButton from "project/containers/watch_project_button";
import CopyLinkButton from "project/containers/copy_link_button";
import { ModalTrigger } from "common/modal";
import { TABLET_BREAKPOINT_PX, SOCIAL_ICONS } from "common/variables";

/**
 * Header actions dropdown menu.
 *
 * @author Nathan Ozelim <nathan@startupfoundry.com>
 */
const ActionsMenu = ({
  canRequestAccess,
  canParticipate,
  requestAccess,
  toggleEntrySubmission,
  canWatch,
  canExtend,
  canUpgrade,
  canDownloadInvoice,
  canAddAwards,
  canShare,
  share,
  toggleViolationModal,
  canReportProjectViolation,
  toggleAwardsModal,
  toggleFeaturesModal,
  toggleExtendModal,
  canReserveElite,
  invoicePdf,
  canRequestEarlyClose,
  canSubmitRevision,
  toggleEntrySelection,
}) => {
  const isMobile =
    typeof window !== "undefined" && window.innerWidth < TABLET_BREAKPOINT_PX;

  const buttons = [
    {
      label: (
        <span>
          <i className="fa fa-fw fa-files-o" /> Submit revision
        </span>
      ),
      action: toggleEntrySelection,
      show: canSubmitRevision && isMobile,
    },
    {
      label: (
        <span>
          <i className="fa fa-fw fa-upload" /> Submit entry
        </span>
      ),
      action: () => toggleEntrySubmission({}),
      show: canParticipate && isMobile,
    },
    {
      label: (
        <span>
          <i className="fa fa-fw fa-user-plus" /> Request access
        </span>
      ),
      action: requestAccess,
      show: canRequestAccess && isMobile,
    },
    {
      label: (
        <ModalTrigger wrapper="span" trigger="elite-reservation-modal">
          <i className="fa fa-fw fa-user-plus" /> Request reservation
        </ModalTrigger>
      ),
      action: () => {},
      show: canReserveElite && isMobile,
    },
    {
      label: (
        <span>
          <i className="fa fa-fw fa-ban" /> Report project
        </span>
      ),
      action: toggleViolationModal,
      show: canReportProjectViolation,
    },
    {
      label: <WatchlistButton />,
      action: () => {}, // action is performed within <WatchlistButton/>
      show: canWatch && isMobile,
      closeOnClick: false,
    },
    {
      action: toggleExtendModal,
      label: (
        <span>
          <i className="fa fa-fw fa-clock-o" /> Extend project
        </span>
      ),
      show: canExtend,
    },
    {
      action: toggleFeaturesModal,
      label: (
        <span>
          <i className="fa fa-fw fa-arrow-up" /> Add features
        </span>
      ),
      show: canUpgrade,
    },
    {
      url: invoicePdf,
      label: (
        <span>
          <i className="fa fa-fw fa-file-pdf-o" /> Download invoice
        </span>
      ),
      target: "_blank",
      show: !!invoicePdf && canDownloadInvoice,
    },
    {
      action: toggleAwardsModal,
      label: (
        <span>
          <i className="fa fa-fw fa-usd" /> Add or increase awards
        </span>
      ),
      show: canAddAwards,
    },
    {
      url: "/help/contact/",
      label: (
        <span>
          <i className="fa fa-fw fa-trophy" /> Pick the winner
        </span>
      ),
      show: canRequestEarlyClose,
    },
    {
      label: <CopyLinkButton />,
      action: () => {}, // action is performed within <CopyLinkButton/>
      show: canShare,
      closeOnClick: false,
    },
    {
      label: (
        <span>
          <i className={SOCIAL_ICONS.facebook} /> Share
        </span>
      ),
      action: partial(share, "facebook"),
      show: canShare,
    },
    {
      label: (
        <span>
          <i className={SOCIAL_ICONS.twitter} /> Post on X (Twitter)
        </span>
      ),
      action: partial(share, "twitter"),
      show: canShare,
    },
  ];

  return (
    <DropdownMenu
      buttons={buttons}
      className="button-wrapper project-tools-menu right"
    />
  );
};

ActionsMenu.propTypes = {
  toggleAwardsModal: PropTypes.func,
  toggleFeaturesModal: PropTypes.func,
  toggleExtendModal: PropTypes.func,
  requestAccess: PropTypes.func,
  toggleEntrySubmission: PropTypes.func.isRequired,
  toggleEntrySelection: PropTypes.func.isRequired,
  toggleViolationModal: PropTypes.func,
  share: PropTypes.func,
  canRequestAccess: PropTypes.bool,
  canParticipate: PropTypes.bool,
  canSubmitRevision: PropTypes.bool.isRequired,
  canWatch: PropTypes.bool,
  canExtend: PropTypes.bool,
  canUpgrade: PropTypes.bool,
  canDownloadInvoice: PropTypes.bool,
  canAddAwards: PropTypes.bool,
  canShare: PropTypes.bool,
  canReportProjectViolation: PropTypes.bool,
  canReserveElite: PropTypes.bool,
  canRequestEarlyClose: PropTypes.bool,
  invoicePdf: PropTypes.string,
};

export default ActionsMenu;
