import { connect } from "react-redux";
import Help from "header/components/help_drawer_button";

export function mapStateToProps({ user }) {
  return {
    isLoggedIn: user.logged_in,
  };
}

export default connect(mapStateToProps)(Help);
