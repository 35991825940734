import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Icon from "common/components/svg_icon";

/* 5 minutes */
const FETCH_INTERVAL = 5 * 60 * 1000;

export default class Notifications extends Component {
  constructor(props) {
    super(props);
    this.fetch = this.fetch.bind(this);
  }

  componentDidMount() {
    const { shouldShow, userId } = this.props;
    this.timeout =
      shouldShow && userId ? setInterval(this.fetch, FETCH_INTERVAL) : null;
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  fetch() {
    const { fetchNotifications, userId } = this.props;
    fetchNotifications(userId);
  }

  render() {
    const { unseen, toggleDrawer, shouldShow } = this.props;

    if (!shouldShow) {
      return null;
    }

    const iconClasses = classnames("svg-icon-md", { inactive: !unseen });
    return (
      <div className="hide-for-small-only notifications ">
        <button
          type="button"
          onClick={toggleDrawer}
          className="btn btn-icon-only btn-notifications"
        >
          <Icon extraClasses={iconClasses} icon="notifications" />
        </button>
        {unseen > 0 ? (
          <span className="badge badge-primary badge-sm">{unseen}</span>
        ) : (
          ""
        )}
      </div>
    );
  }
}

Notifications.propTypes = {
  unseen: PropTypes.number,
  shouldShow: PropTypes.bool,
  toggleDrawer: PropTypes.func.isRequired,
  fetchNotifications: PropTypes.func.isRequired,
  userId: PropTypes.number,
};
