export const getClientLabel = (client) => {
  if (!client) {
    return "The client";
  }

  if (client.first_name && client.last_name) {
    return `${client.first_name} ${client.last_name}`;
  }

  return client.username;
};

export const getParticipantEmail = (email) => {
  if (!email) {
    return "";
  }

  if (email.endsWith("@noemailprovided.com")) {
    return email.split("@")[0].slice(0, 20);
  }

  return email;
};

export const getRevisionStats = ({ revision, group }) => {
  const votes = Object.values(revision.votes);
  const totalScores = votes.reduce(
    (acc, v) => acc + v.score * (group.weight_votes ? v.weight : 1),
    0
  );
  const totalWeight = votes.reduce(
    (acc, v) => acc + (group.weight_votes ? v.weight : 1),
    0
  );

  return {
    commentCount: votes.filter((v) => !!(v.comment || "").trim()).length,
    voteCount: votes.length,
    averageScore:
      totalWeight > 0 ? Math.round((totalScores / totalWeight) * 10) / 10 : 0,
  };
};
