import request from "axios";
import { setBannerError } from "error";
import { atAdmin } from "common/utils";

export const FETCH_SETTINGS = "crowdspring/notifications/FETCH_SETTINGS";
export const FETCH_SETTINGS_REQUEST =
  "crowdspring/notifications/FETCH_SETTINGS_REQUEST";
export const FETCH_SETTINGS_FAILURE =
  "crowdspring/notifications/FETCH_SETTINGS_FAILURE";
export const FETCH_SETTINGS_SUCCESS =
  "crowdspring/notifications/FETCH_SETTINGS_SUCCESS";

export const FETCH_OPTIONS = "crowdspring/notifications/FETCH_OPTIONS";
export const FETCH_OPTIONS_REQUEST =
  "crowdspring/notifications/FETCH_OPTIONS_REQUEST";
export const FETCH_OPTIONS_FAILURE =
  "crowdspring/notifications/FETCH_OPTIONS_FAILURE";
export const FETCH_OPTIONS_SUCCESS =
  "crowdspring/notifications/FETCH_OPTIONS_SUCCESS";

export const UPDATE_SETTINGS = "crowdspring/notifications/UPDATE_SETTINGS";
export const UPDATE_SETTINGS_REQUEST =
  "crowdspring/notifications/UPDATE_SETTINGS_REQUEST";
export const UPDATE_SETTINGS_FAILURE =
  "crowdspring/notifications/UPDATE_SETTINGS_FAILURE";
export const UPDATE_SETTINGS_SUCCESS =
  "crowdspring/notifications/UPDATE_SETTINGS_SUCCESS";

export const TOGGLE_SITE_NOTIFICATION =
  "crowdspring/notifications/TOGGLE_SITE_NOTIFICATION";
export const TOGGLE_EMAIL_NOTIFICATION =
  "crowdspring/notifications/TOGGLE_EMAIL_NOTIFICATION";
export const UPDATE_EMAIL_NOTIFICATION =
  "crowdspring/notifications/UPDATE_EMAIL_NOTIFICATION";
export const TOGGLE_EMAIL_GROUP =
  "crowdspring/notifications/TOGGLE_EMAIL_GROUP";
export const UPDATE_EMAIL_GROUP =
  "crowdspring/notifications/UPDATE_EMAIL_GROUP";
export const CLEAR_EMAIL_GROUP = "crowdspring/notifications/CLEAR_EMAIL_GROUP";

export const INITIAL_STATE = {
  data: null,
  options: null,
  isLoading: false,
};

export function toggleSiteNotification(notificationId) {
  return {
    type: TOGGLE_SITE_NOTIFICATION,
    payload: notificationId * 1,
  };
}

export function toggleEmailNotification(notificationId) {
  return {
    type: TOGGLE_EMAIL_NOTIFICATION,
    payload: notificationId * 1,
  };
}

export function updateEmailNotification(notificationId, frequency) {
  return {
    type: UPDATE_EMAIL_NOTIFICATION,
    payload: { notificationId: notificationId * 1, frequency },
  };
}

export function toggleEmailGroup(id) {
  return {
    type: TOGGLE_EMAIL_GROUP,
    payload: id,
  };
}

export function updateEmailGroup(value) {
  return {
    type: UPDATE_EMAIL_GROUP,
    payload: value,
  };
}

export function clearEmailGroup() {
  return {
    type: CLEAR_EMAIL_GROUP,
  };
}

export function fetchSettings(userId) {
  const opts = {
    method: "get",
    url: `/api/v1/users/${userId}/notifications/get_settings/`,
  };
  return {
    type: FETCH_SETTINGS,
    promise: request(opts),
  };
}

export function fetchOptions(userId) {
  const opts = {
    method: "options",
    url: `/api/v1/users/${userId}/notifications/`,
  };
  return {
    type: FETCH_OPTIONS,
    promise: request(opts),
  };
}

export function updateSettings() {
  return (dispatch, getState) => {
    const state = getState();
    const userId = atAdmin(state)
      ? state.admin.people.currentUser.id
      : state.user.profile_data.id;
    const data = state.notificationSettings.data;
    const opts = {
      method: "put",
      url: `/api/v1/users/${userId}/notifications/set_settings/`,
      data,
    };

    return request(opts)
      .then(() => dispatch({ type: UPDATE_SETTINGS_SUCCESS }))
      .catch(() => dispatch(setBannerError()));
  };
}

export default function reducer(state = INITIAL_STATE, action) {
  if (!action) {
    return INITIAL_STATE;
  }

  switch (action.type) {
    case FETCH_SETTINGS:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case FETCH_OPTIONS:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_OPTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        options: action.payload,
      };
    case TOGGLE_SITE_NOTIFICATION:
      const inSiteArray = state.data.onsite.indexOf(action.payload);
      const onsite =
        inSiteArray === -1
          ? [...state.data.onsite, action.payload]
          : state.data.onsite
              .slice(0, inSiteArray)
              .concat(state.data.onsite.slice(inSiteArray + 1));

      const toggledSiteData = {
        ...state.data,
        onsite,
      };
      return {
        ...state,
        data: toggledSiteData,
      };

    case TOGGLE_EMAIL_NOTIFICATION:
      const inEmailArray = Object.keys(state.data.email).indexOf(
        action.payload.toString()
      );
      const addedEmail = { ...state.data.email };
      const removedEmail = { ...state.data.email };
      addedEmail[action.payload] = 0;
      delete removedEmail[action.payload];

      const toggledEmailData = {
        ...state.data,
        email: inEmailArray !== -1 ? removedEmail : addedEmail,
      };

      return {
        ...state,
        data: toggledEmailData,
      };

    case UPDATE_EMAIL_NOTIFICATION:
      const { notificationId, frequency } = action.payload;
      const newEmail = { ...state.data.email };
      newEmail[notificationId] = frequency;
      const updatedEmailData = {
        ...state.data,
        email: newEmail,
      };

      return {
        ...state,
        data: updatedEmailData,
      };
    case TOGGLE_EMAIL_GROUP:
      const toggledEmailGroup = {};
      const toggledKeys = Object.keys(state.data.email_group);
      const groupValue = toggledKeys.length
        ? state.data.email_group[toggledKeys[0]]
        : Object.keys(state.options.frequencies)[0] * 1;

      toggledEmailGroup[action.payload] = groupValue;

      const toggledGroupData = {
        ...state.data,
        email_group: toggledEmailGroup,
      };

      return {
        ...state,
        data: toggledGroupData,
      };

    case UPDATE_EMAIL_GROUP:
      const updateGroup = { ...state.data.email_group };
      const updateKeys = Object.keys(state.data.email_group);
      if (updateKeys.length) {
        updateGroup[updateKeys[0]] = action.payload;
      }

      const updatedGroupData = {
        ...state.data,
        email_group: updateGroup,
      };

      return {
        ...state,
        data: updatedGroupData,
      };

    case CLEAR_EMAIL_GROUP:
      const clearedGroupData = {
        ...state.data,
        email_group: {},
      };

      return {
        ...state,
        data: clearedGroupData,
      };

    default:
      return state;
  }
}
