import request from "axios";
import PropTypes from "prop-types";
import { browserHistory } from "react-router";
import { setBannerError } from "error";
import { fetchProject, getProject } from "project/ducks/project";

/* ACTION CONSTANTS
================================================================================================ */
const TOGGLE_FEATURES_MODAL = "crowdspring/project/TOGGLE_FEATURES_MODAL";

const TOGGLE_EXTEND_MODAL = "crowdspring/project/TOGGLE_EXTEND_MODAL";

const TOGGLE_EXTEND_SUCCESS_MODAL =
  "crowdspring/project/TOGGLE_EXTEND_SUCCESS_MODAL";

const EXTEND_DEADLINE = "crowdspring/project/EXTEND_DEADLINE";
const EXTEND_DEADLINE_SUCCESS = "crowdspring/project/EXTEND_DEADLINE_SUCCESS";

const TOGGLE_CLOSE_MODAL = "crowdspring/project/TOGGLE_CLOSE_MODAL";

const CLOSE = "crowdspring/project/CLOSE";
const CLOSE_SUCCESS = "crowdspring/project/CLOSE_SUCCESS";

/* INITIAL STATES
================================================================================================ */
export const initialState = {
  showingFeaturesModal: false,
  showingExtendModal: false,
  showingExtendSuccessModal: false,
  showingCloseModal: false,
};

/* HELPERS
================================================================================================ */
/* list of PAP steps in which features can be edited */
export const PAP_STEPS = {
  project_options: "Project Options",
  promotion_options: "Promotion",
  protection_options: "Privacy",
};

/* REDUCERS
================================================================================================ */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_FEATURES_MODAL:
      return {
        ...state,
        showingFeaturesModal: !state.showingFeaturesModal,
      };
    case TOGGLE_EXTEND_MODAL:
      return {
        ...state,
        showingExtendModal: !state.showingExtendModal,
      };
    case TOGGLE_EXTEND_SUCCESS_MODAL:
      return {
        ...state,
        showingExtendSuccessModal: !state.showingExtendSuccessModal,
      };
    case TOGGLE_CLOSE_MODAL:
      return {
        ...state,
        showingCloseModal: !state.showingCloseModal,
      };
    case EXTEND_DEADLINE_SUCCESS:
      return {
        ...state,
        showingExtendModal: false,
        showingExtendSuccessModal: true,
      };
    default:
      return state;
  }
};

export default reducer;

/* ACTION CREATORS
================================================================================================ */
export const toggleFeaturesModal = () => (dispatch) => {
  return dispatch({
    type: TOGGLE_FEATURES_MODAL,
  });
};

export const toggleExtendModal = () => (dispatch) => {
  return dispatch({
    type: TOGGLE_EXTEND_MODAL,
  });
};

export const toggleExtendSuccessModal = () => (dispatch) => {
  return dispatch({
    type: TOGGLE_EXTEND_SUCCESS_MODAL,
  });
};

export const toggleCloseModal = () => {
  return {
    type: TOGGLE_CLOSE_MODAL,
  };
};

export const extendDeadline = (projectId, days) => (dispatch) => {
  const opts = {
    method: "post",
    url: `/api/v1/projects/${projectId}/extend/`,
    data: { days },
  };

  const promise = request(opts);

  promise.catch(() =>
    dispatch(setBannerError("Failed to extend deadline", "Please try again"))
  );

  return dispatch({
    type: EXTEND_DEADLINE,
    promise: promise,
  });
};

export const closeProject = (next) => (dispatch, getState) => {
  const project = getProject(getState());
  const action =
    project.status === "finalist_round" ? "close_finalist_round" : "close";

  const promise = request.post(`/api/v1/projects/${project.id}/${action}/`);

  promise
    .then(() => {
      dispatch(fetchProject(project.id));
      dispatch(toggleCloseModal());
      browserHistory.push(next);
    })
    .catch(() =>
      dispatch(setBannerError("Failed to close project", "Please try again."))
    );

  return dispatch({
    type: CLOSE,
    promise,
  });
};
