import { combineReducers } from "redux";
import PropTypes from "prop-types";
import { reducer as formReducer } from "redux-form";
import pricingReducer from "pap/ducks/pricing";
import projectReducer from "pap/ducks/project";
import workflowReducer from "pap/ducks/workflow";

const RootReducer = combineReducers({
  workflow: workflowReducer,
  pricing: pricingReducer,
  project: projectReducer,
});

export default RootReducer;
