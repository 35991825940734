import React, { Component } from "react";
import PropTypes from "prop-types";
import EntriesControls from "entries/containers/controls";
import ProjectsControls from "explore/containers/projects_controls";
import CreativesControls from "explore/containers/creatives_controls";
import ProfileEntriesControls from "profile/containers/your_entries/controls";
import YourProjectsControls from "your_projects/containers/controls";
import BrandControls from "brand_studio/containers/brand/preview_gallery_controls";

/**
 * Drawer component sliding from the right displaying filtering/sorting on small screens
 * @author Kuba Siemiątkowski <kuba@crowdspring.com>
 */
const RightDrawerComponent = ({ contentType }) => {
  if (contentType && contentType.match("explore")) {
    return (
      <div className="right-drawer-content column explore-controls">
        {contentType === "explore_projects" ? (
          <ProjectsControls skipQuerySave={true} />
        ) : (
          <CreativesControls skipQuerySave={true} />
        )}
      </div>
    );
  }

  if (contentType === "your_projects") {
    return <YourProjectsControls skipQuerySave={true} />;
  }

  if (contentType && contentType.match("project")) {
    return <EntriesControls skipQuerySave={true} />;
  }

  if (contentType === "profile_entries") {
    return <ProfileEntriesControls />;
  }

  if (contentType === "brand") {
    return <BrandControls skipQuerySave={true} />;
  }

  return (
    <div className="right-drawer-content">
      Unknown content type "{contentType}"
    </div>
  );
};

RightDrawerComponent.propTypes = {
  contentType: PropTypes.string,
};

export default RightDrawerComponent;
