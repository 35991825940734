import { combineReducers } from "redux";
import HoneybeeReducer from "email_automations/ducks/honeybee";
import BumblebeeReducer from "email_automations/ducks/bumblebee";
import CommonReducer from "email_automations/ducks/common";
import ContactsReducer from "email_automations/ducks/contacts";
import RssAutomationCreatorsReducer from "email_automations/ducks/rss_automation_creators";

const RootReducer = combineReducers({
  honeybee: HoneybeeReducer,
  bumblebee: BumblebeeReducer,
  common: CommonReducer,
  contacts: ContactsReducer,
  rssAutomationCreators: RssAutomationCreatorsReducer,
});

export default RootReducer;
