import React, { Component } from "react";
import PropTypes from "prop-types";
import capitalize from "lodash/capitalize";
import ModalDialog from "common/components/modal/modal_dialog";
import { CheckBoxField } from "common/components/form_fields";
import {
  ELITE_SPOTS,
  ELITE_SPOTS_TEXT,
  ELITE_TIPPED_SPOTS_TEXT,
} from "project/constants";

/**
 * Modal to confirm Elite package selection.
 *
 * @author Nathan Ozelim <nathan@startupfoundry.com>
 */
export default class ConfirmEliteModal extends Component {
  constructor(props) {
    super(props);

    this.state = { confirmed: false };
    this.handleClose = this.handleClose.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  handleClose() {
    this.setState({ confirmed: false });
    this.props.onClose();
  }

  handleConfirm() {
    this.props.onConfirm();
    this.handleClose();
  }

  render() {
    const { confirmed } = this.state;

    return (
      <ModalDialog
        modalOpen={this.props.showing}
        dialogClasses="confirm-elite-modal"
        modalHeader="Before you continue..."
        closeAction={this.handleClose}
        onPrimary={this.handleConfirm}
        primaryDisabled={!confirmed}
        primaryLabel="Confirm"
        onSecondary={this.handleClose}
        secondaryLabel="Cancel"
      >
        <h2>Elite projects have special rules</h2>

        <ul>
          <li>
            1. Only the <strong>very best</strong> designers on crowdspring
            (those with a reputation of 90 or higher) can participate in Elite
            projects.
          </li>
          <li>
            2.{" "}
            <strong>
              You will work with {ELITE_SPOTS_TEXT} ({ELITE_SPOTS}) designers
            </strong>
            . There is no limit to the number of designs each designer can
            submit and each promises to submit their very best work in your
            project.
          </li>
          <li>
            3. Elite projects have one award to the winner plus tips for the
            other {ELITE_TIPPED_SPOTS_TEXT} participants (the price you pay
            includes the award and all tips).{" "}
            <strong>
              You will receive full intellectual property rights to the winning
              design.
            </strong>{" "}
            You can also add additional awards anytime after the project starts
            and buy the rights to other designs in your project.
          </li>
          <li>
            4. When you post your Elite project,{" "}
            <strong>
              we will invite our very best designers to participate
            </strong>
            . {capitalize(ELITE_SPOTS_TEXT)} of those best designers will be
            able to reserve a spot in your project and by doing so, promise to
            submit their first design within 24 hours after reserving their
            spot.
          </li>
          <li>
            5. <strong>Elite projects are not refundable.</strong> By working
            with the best of the best and guaranteeing your award (and the
            included tips), you will receive exceptional work and agree to waive
            your right to a refund.
          </li>
        </ul>

        <CheckBoxField
          meta={{}}
          label="I have read and agree to these special rules for Elite projects."
          input={{
            value: confirmed,
            name: "confirmed",
            onChange: (v) => this.setState({ confirmed: v }),
          }}
        />
      </ModalDialog>
    );
  }
}

/**
 * PropTypes.
 *
 * @property {bool} showing - whether to show the modal
 * @property {func} onClose - on close callback
 * @property {func} onConfirm - on confirm callback
 */
ConfirmEliteModal.propTypes = {
  showing: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};
