import request from "axios";

/* ACTIONS
================================================================================================ */
const FETCH_TOKEN = "crowdspring/billing/FETCH_TOKEN";
const FETCH_TOKEN_REQUEST = "crowdspring/billing/FETCH_TOKEN_REQUEST";
const FETCH_TOKEN_SUCCESS = "crowdspring/billing/FETCH_TOKEN_SUCCESS";
const FETCH_TOKEN_FAILURE = "crowdspring/billing/FETCH_TOKEN_FAILURE";

const FETCH_METHOD = "crowdspring/billing/FETCH_METHOD";
const FETCH_METHOD_REQUEST = "crowdspring/billing/FETCH_METHOD_REQUEST";
const FETCH_METHOD_SUCCESS = "crowdspring/billing/FETCH_METHOD_SUCCESS";
const FETCH_METHOD_FAILURE = "crowdspring/billing/FETCH_METHOD_FAILURE";

const FETCH_INVOICES = "crowdspring/billing/FETCH_INVOICES";
const FETCH_INVOICES_SUCCESS = "crowdspring/billing/FETCH_INVOICES_SUCCESS";

const SAVE_METHOD = "crowdspring/billing/SAVE_METHOD";

/* HELPERS
================================================================================================ */

/* REDUCERS
 ================================================================================================ */
const initialState = {
  token: null,
  method: undefined, // undefined = 'not yet fetched', null = 'no payment method'
  invoices: [],
  fetchingToken: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TOKEN_REQUEST:
      return {
        ...state,
        fetchingToken: true,
        token: null,
      };
    case FETCH_TOKEN_SUCCESS:
      return {
        ...state,
        fetchingToken: false,
        token: action.payload,
      };
    case FETCH_TOKEN_FAILURE:
      return {
        ...state,
        fetchingToken: false,
      };
    case FETCH_METHOD_REQUEST:
      return {
        ...state,
        method: undefined,
      };
    case FETCH_METHOD_SUCCESS:
      return {
        ...state,
        method: action.payload.find((m) => m.default) || null,
      };
    case FETCH_METHOD_FAILURE:
      return {
        ...state,
      };
    case FETCH_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: action.payload,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const fetchTokenIfNeeded = () => (dispatch, getState) => {
  const state = getState();
  const token = state.billing.token;
  const userId = state.user.profile_data.id;

  if (!token) {
    const promise = request.get(
      `/api/v1/users/${userId}/payment-methods/token/`
    );
    dispatch({
      type: FETCH_TOKEN,
      promise: promise,
    });
    return promise.then((res) => res.data);
  }

  return Promise.resolve(token);
};

export const fetchMethodIfNeeded = (force) => (dispatch, getState) => {
  const state = getState();
  const userId = state.user.profile_data.id;
  const method = state.billing.method;

  if (method === undefined || force) {
    const promise = request.get(`/api/v1/users/${userId}/payment-methods/`);
    dispatch({
      type: FETCH_METHOD,
      promise: promise,
    });
    return promise;
  }
  return Promise.resolve(method);
};

export const saveMethod = (nonce) => (dispatch, getState) => {
  const userId = getState().user.profile_data.id;

  const promise = request.put(`/api/v1/users/${userId}/payment-methods/save/`, {
    nonce,
  });
  dispatch({
    type: SAVE_METHOD,
    promise: promise,
  });
  return promise;
};

export const fetchInvoicesIfNeeded = () => (dispatch, getState) => {
  const state = getState();
  const userId = state.user.profile_data.id;
  const invoices = state.billing.invoices;

  if (invoices.length == 0) {
    const promise = request.get(`/api/v1/users/${userId}/invoices/`);
    dispatch({
      type: FETCH_INVOICES,
      promise: promise,
    });
    return promise;
  }
  return Promise.resolve(invoices);
};
