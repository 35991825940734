import { connect } from "react-redux";
import { hideBanner as hide } from "common/ducks/banner";
import Banner from "common/components/banner";

const mapStateToProps = ({ banner }) => {
  return {
    ...banner,
  };
};

export default connect(mapStateToProps, {
  hide,
})(Banner);
