import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { v4 } from "uuid";
import idx from "idx";
import union from "lodash/union";
import partial from "lodash/partial";

import Textarea from "react-textarea-autosize";
import FileSelector from "common/components/file_selector";
import StarRating from "common/components/star_rating";
import ColorPicker from "common/components/color_picker";

export const DEFAULT_REQUIRED_MESSAGE = "Please enter a value.";
export const DEFAULT_MIN_LENGTH_MESSAGE = (minLength) =>
  `This field must have at least ${minLength} characters.`;
export const EMAIL_INVALID_MESSAGE = "Please enter a valid email.";
export const URL_INVALID_MESSAGE = "Please enter a valid URL.";
export const PHONE_PLACEHOLDER = "+1.877.887.7442";
export const REQUIRED_COLOR_MESSAGE = "Please pick a color.";

const FieldPropTypes = {
  input: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.array,
  meta: PropTypes.object,
};

export const FormGroup = (props) => {
  const { field, children } = props;
  const classes = classnames("form-group", field.formClasses, {
    error: (field.meta.touched || field.alwaysShowError) && field.meta.invalid,
  });

  return <div className={classes}>{children}</div>;
};

/**
 * Form input component.
 *
 * @author Kamil Grymuza <kamil@startupfoundry.com>
 * @author Kuba Siemiątkowski <kuba@crowdspring.com>
 *
 * @returns {void}
 */
export const InputField = ({ field, type }) => {
  const hasError =
    (field.meta.touched || field.alwaysShowError) && field.meta.invalid;
  let value = field.input.value || field.defaultValue;
  let hasValue = !!value;
  if (type === "number") {
    if (field.input.value || field.input.value === 0) {
      value = field.input.value;
      hasValue = true;
    } else if (field.defaultValue || field.defaultValue === 0) {
      value = field.defaultValue;
      hasValue = true;
    }
  }
  const classes = classnames("form-control", field.classes || "", {
    filled: hasValue,
    invalid: field.meta.touched && field.meta.invalid && field.meta.value,
    "with-pre-value": field.preValue,
    "with-post-value": field.postValue,
  });

  let charCountError = null;
  if (field.minCharCount || field.maxCharCount) {
    const charCount = (field.input.value || "").trim().length;
    /* char count lower than minimum */
    if (field.minCharCount && charCount && charCount < field.minCharCount) {
      charCountError = charCount;
      /* char count higher than maximum */
    } else if (field.maxCharCount && charCount > field.maxCharCount) {
      charCountError = field.maxCharCount - charCount;
    }
  }

  return (
    <FormGroup field={field}>
      {field.preValue && <div className="pre-value">{field.preValue}</div>}
      <input
        {...field.input}
        disabled={field.disabled}
        className={classes}
        id={`field-id-${field.input.name}`}
        type={type}
        value={hasValue ? value : ""}
        placeholder={field.placeholder}
      />
      {field.postValue && <div className="post-value">{field.postValue}</div>}
      <span className="bar" />
      <label
        className="form-label animate"
        htmlFor={`field-id-${field.input.name}`}
      >
        <span>
          {field.label}
          {!!field.labelDescription && (
            <span className="show-for-large label-description">
              ({field.labelDescription})
            </span>
          )}
        </span>

        {field.verified === true && (
          <span className="label label-success">
            <i className="fa fa-check-circle" /> Verified
          </span>
        )}

        {field.verified === false && (
          <span className="label label-warning">
            <i className="fa fa-exclamation-triangle" /> Pending
          </span>
        )}
      </label>
      {hasError && <span className="error-msg">{field.meta.error}</span>}

      {charCountError && (
        <div className="char-counters">
          <span className="text-danger">{charCountError}</span>
        </div>
      )}
    </FormGroup>
  );
};

export const TextInputField = (field) => (
  <InputField field={field} type="text" />
);
export const EmailInputField = (field) => (
  <InputField field={field} type="email" />
);
export const DateInputField = (field) => (
  <InputField field={field} type="date" />
);
export const NumberInputField = (field) => (
  <InputField field={field} type="number" />
);
export const MoneyInputField = (field) => (
  <InputField
    field={{
      ...field,
      input: {
        ...field.input,
        onChange: (e) => {
          if (!e.target.value) {
            field.input.onChange(e.target.value);
            return;
          }

          /* make sure value is positive and has no decimal places */
          field.input.onChange(Math.abs(Math.round(e.target.value)));
        },
        step: field.step || 1,
        autoComplete: "off",
      },
      classes: (field.classes || []).concat("money"),
    }}
    type="number"
  />
);
export const PhoneInputField = (field) => (
  <InputField
    type="phone"
    field={{
      ...field,
      placeholder: PHONE_PLACEHOLDER,
      labelDescription: `Be sure to include + and your country code. Example: ${PHONE_PLACEHOLDER}`,
    }}
  />
);

/**
 * Custom select field. It can display array of elements as a list of options.
 * Accepts either a simple array:
 * [
 *   { field: 'foo', label: 'foo' }, { field: 'bar', label: 'Bar' }
 * ]
 *
 * or array of two elements :
 * [
 *   [{ field: 'foo', label: 'foo' }, { field: 'bar', label: 'Bar' }]
 * ]
 *
 * If `customOnChange` is provided, it will be called when option is selected,
 * but the built-in `onChange` is still called.
 *
 * @author Kuba Siemiątkowski <kuba@crowdspring.com>
 * @author Nathan Ozelim <nathan@startupfoundry.com>
 *
 * @returns {void}
 */
const SelectField = (field) => {
  const hasError =
    (field.meta.touched || field.alwaysShowError) && field.meta.invalid;
  const options = [];
  const optionsValues = [];
  const groups = {};
  const hasGroups = !!field.options.find((o) => !o[0] && o.group);

  /* Add extra option in case empty value is allowed */
  if (field.allowEmpty) {
    options.push(
      <option value="" key={v4()}>
        {field.emptyLabel || "Select"}{" "}
      </option>
    );
  }

  field.options.forEach((optionSet) => {
    /* options provided in pairs */
    if (optionSet[0]) {
      options.push(
        <option
          value={optionSet[0].field}
          key={v4()}
          disabled={optionSet[0].disabled}
        >
          {optionSet[0].label}
        </option>,
        <option
          value={optionSet[1].field}
          key={v4()}
          disabled={optionSet[1].disabled}
        >
          {optionSet[1].label}
        </option>
      );
      optionsValues.push(optionSet[0].field, optionSet[1].field);
      /* options provided individually */
    } else {
      const option = (
        <option
          value={optionSet.field}
          key={v4()}
          disabled={optionSet.disabled}
        >
          {optionSet.label}
        </option>
      );
      const groupId = hasGroups
        ? optionSet.group || optionSet.field
        : undefined;

      groups[groupId] = (groups[groupId] || []).concat(option);

      optionsValues.push(optionSet.field, optionSet.field);
    }
  });

  return (
    <FormGroup
      field={{
        ...field,
        formClasses: "select-wrapper " + (field.formClasses || ""),
      }}
    >
      <select
        onBlur={field.input.onBlur}
        className="form-control"
        autoComplete="off"
        required={true} // just so that it's blurred if an empty value is selected
        onChange={(e) => {
          const selectedValue = optionsValues[e.target.selectedIndex];

          field.input.onChange(e);
          if (field.customOnChange) {
            field.customOnChange(selectedValue);
          }
        }}
        value={field.input.value}
        id={`field-id-${field.input.name}`}
        disabled={field.disabled}
      >
        {options}
        {hasGroups
          ? Object.keys(groups).map((group, i) =>
              groups[group].length === 1 &&
              groups[group][0].props.value == group ? (
                groups[group][0]
              ) : (
                <optgroup label={group} key={i}>
                  {groups[group]}
                </optgroup>
              )
            )
          : groups[undefined]}
      </select>
      {field.label && (
        <label
          className="form-label animate"
          htmlFor={`field-id-${field.input.name}`}
        >
          {field.label}
        </label>
      )}
      {hasError && <span className="error-msg">{field.meta.error}</span>}
    </FormGroup>
  );
};

export class MultiSelectField extends Component {
  constructor(props) {
    super(props);

    this.state = { selected: "" };

    this.handleAdd = this.handleAdd.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  handleAdd() {
    const { selected } = this.state;
    const { input } = this.props;
    const newValue = union(input.value || [], [selected]);

    input.onChange(newValue);

    /* wait until form state is updated with new value */
    setTimeout(() => input.onBlur(newValue), 300);

    this.setState({ selected: "" });
  }

  handleRemove(idx) {
    const { input } = this.props;
    const newValue = [
      ...input.value.slice(0, idx),
      ...input.value.slice(idx + 1),
    ];

    input.onChange(newValue);

    /* wait until form state is updated with new value */
    setTimeout(() => input.onBlur(newValue), 300);
  }

  render() {
    const { selected } = this.state;
    const { options, input, meta, disabled, colClasses } = this.props;
    const hasError = meta.touched && meta.invalid;

    return (
      <div
        className={classnames("form-group multi-select-field", {
          error: !meta.valid,
        })}
      >
        <div className="row align-middle">
          <div
            className={classnames(
              "columns",
              idx(colClasses, (_) => _[0]) || "small-12 large-13"
            )}
          >
            <SelectField
              allowEmpty={true}
              disabled={disabled}
              input={{
                value: selected,
                onChange: (e) => this.setState({ selected: e.target.value }),
              }}
              meta={{}}
              options={options}
            />
          </div>
          <div
            className={classnames(
              "columns",
              idx(colClasses, (_) => _[1]) || "small-4 large-3"
            )}
          >
            <button
              className="btn btn-md btn-secondary"
              disabled={!selected || disabled}
              onClick={this.handleAdd}
            >
              Add
            </button>
          </div>
        </div>
        <div className="tags">
          {(input.value || []).map((v, i) => {
            const label = options.find((o) => o.field === v).label;

            return (
              <span key={v} className="tag tag-sm tag-default dismissible">
                {label}{" "}
                <i
                  className="fa fa-times-circle"
                  onClick={disabled ? undefined : partial(this.handleRemove, i)}
                />
              </span>
            );
          })}
        </div>
        {hasError && <div className="error-msg">{meta.error}</div>}
      </div>
    );
  }
}

/**
 * Simple textarea form field.
 *
 * @author Kuba Siemiątkowski <kuba@crowdspring.com>
 *
 * @returns {void}
 */
const TextAreaField = ({
  input,
  classes,
  meta: { touched, error, invalid, valid },
  label,
  alwaysShowError,
  description,
  disabled,
  rows,
  minCharCount,
  maxCharCount,
  placeholder,
  resize = "both",
}) => {
  const charCount = (input.value || "").trim().length;
  let charCountError = null;
  /* char count lower than minimum */
  if (minCharCount && charCount && charCount < minCharCount) {
    charCountError = charCount;
    /* char count higher than maximum */
  } else if (maxCharCount && charCount > maxCharCount) {
    charCountError = maxCharCount - charCount;
  }

  return (
    <FormGroup
      field={{
        alwaysShowError,
        meta: { touched, error, invalid },
        formClasses: classnames("textarea", `resize-${resize}`),
      }}
    >
      {label && (
        <label className="form-label" htmlFor={`field-id-${input.name}`}>
          {label}
        </label>
      )}

      {description && <p className="description">{description}</p>}
      <textarea
        className={classnames(classes || "form-control", {
          empty: !input.value,
        })}
        {...input}
        rows={rows || 1}
        disabled={disabled}
        placeholder={placeholder}
      />
      {(touched || alwaysShowError) && error && (
        <div className="error-msg">{error}</div>
      )}
      {charCountError && (
        <div className="char-counters">
          <span className="text-danger">{charCountError}</span>
        </div>
      )}
    </FormGroup>
  );
};

TextAreaField.propTypes = {
  ...FieldPropTypes,
  classes: PropTypes.string,
};

/**
 * Simple textarea form field.
 *
 * @author Tyler Travitz
 *
 * @returns {void}
 */
const BasicTextAreaField = ({
  input,
  classes,
  meta: { touched, error, invalid, valid },
  label,
  description,
  formClasses,
  minRows,
  disabled,
}) => {
  return (
    <FormGroup field={{ meta: { touched, error, invalid }, formClasses }}>
      {label && (
        <label className="form-label" htmlFor={`field-id-${input.name}`}>
          {label}
        </label>
      )}
      {description && <p className="description">{description}</p>}
      <textarea
        className={classnames(classes || "form-control", {
          empty: !input.value,
        })}
        {...input}
        rows={minRows || 1}
        disabled={disabled}
      />
      {touched && error && <div className="error-msg">{error}</div>}
    </FormGroup>
  );
};

TextAreaField.propTypes = {
  ...FieldPropTypes,
  classes: PropTypes.string,
};

/**
 * Resizable textarea form field.
 *
 * @author Kuba Siemiątkowski <kuba@crowdspring.com>
 * @author Kamil Grymuza <kamil@crowdspring.com>
 *
 * @returns {void}
 */
const ResizableTextAreaField = ({
  input,
  classes,
  rows,
  meta: { touched, error, invalid, valid },
  label,
  description,
  getRef,
  placeholder,
}) => {
  return (
    <FormGroup
      field={{ formClasses: "textarea", meta: { touched, error, invalid } }}
    >
      {description && <p className="description">{description}</p>}
      <Textarea
        className={classnames(classes || "form-control", {
          empty: !input.value,
        })}
        spellCheck={false}
        minRows={rows}
        inputRef={getRef}
        placeholder={placeholder}
        {...input}
      />
      {label && (
        <label
          className="form-label animate"
          htmlFor={`field-id-${input.name}`}
        >
          {label}
        </label>
      )}
      {touched && error && <span className="error-msg">{error}</span>}
    </FormGroup>
  );
};

TextAreaField.propTypes = {
  ...FieldPropTypes,
  classes: PropTypes.string,
};

/**
 * Custom checkbox field with a label and error handling. When `customOnChange`
 * is provided it will be called after the built-in `onChange`.
 *
 * @author Kuba Siemiątkowski <kuba@crowdspring.com>
 *
 * @returns {void}
 */
const CheckBoxField = (field) => {
  const hasError = field.meta.touched && field.meta.invalid;
  const classes = classnames("checkbox form-group", field.className, {
    error: hasError,
    disabled: field.disabled,
  });

  return (
    <div className={classes}>
      <input
        id={`field-id-${field.input.name}`}
        type="checkbox"
        className="form-control"
        onChange={() => {
          const checkedValue = !field.input.value;
          field.input.onChange(checkedValue);

          if (field.customOnChange) {
            field.customOnChange(checkedValue);
          }
        }}
        checked={field.input.value}
        disabled={field.disabled}
      />
      <label htmlFor={`field-id-${field.input.name}`} className="form-label">
        {field.label}
      </label>

      {hasError && <span className="error-msg">{field.meta.error}</span>}

      {field.description && <p className="description">{field.description}</p>}

      {field.htmlDescription && (
        <p
          className="description"
          dangerouslySetInnerHTML={{ __html: field.htmlDescription }}
        />
      )}

      {field.disclaimer && (
        <p className="disclaimer">
          <i className="fa fa-exclamation-triangle" /> {field.disclaimer}
        </p>
      )}
    </div>
  );
};

export class CheckBoxGroupField extends Component {
  constructor(props) {
    super(props);

    this.state = { expanded: false };
  }

  render() {
    const {
      meta,
      input,
      disabled,
      options,
      label,
      collapsableAfter,
      hideAllBox,
    } = this.props;
    const { expanded } = this.state;
    const hasError = meta.touched && meta.invalid;
    const classes = classnames("checkbox-group form-group", {
      error: hasError,
      disabled,
    });
    const canExpand = collapsableAfter && options.length > collapsableAfter;
    const visibleOptions =
      canExpand && !expanded ? options.slice(0, collapsableAfter) : options;

    return (
      <div className={classes}>
        <label className="form-label">{label}</label>

        {!hideAllBox && (
          <CheckBoxField
            meta={{}}
            input={{
              name: `${input.name}-all`,
              value:
                options.length > 0 &&
                !options.find((opt) => !input.value.includes(opt.field)),
              onChange: (v) =>
                input.onChange(v ? options.map((opt) => opt.field) : []),
            }}
            disabled={disabled}
          />
        )}

        {visibleOptions.map((opt) => {
          const name = `${input.name}-${opt.field}`;

          return (
            <CheckBoxField
              key={name}
              meta={{}}
              input={{
                name,
                value: input.value.includes(opt.field),
                onChange: (v) =>
                  input.onChange(
                    v
                      ? input.value.concat(opt.field)
                      : input.value.filter((i) => i !== opt.field)
                  ),
              }}
              label={opt.label}
              disabled={disabled}
            />
          );
        })}

        {canExpand && (
          <a onClick={() => this.setState({ expanded: !expanded })}>
            Show {expanded ? "less" : "more"}
          </a>
        )}
      </div>
    );
  }
}

/**
 * Custom radio button field with a label and error handling.
 *
 * @author Nathan Ozelim <nathan@startupfoundry.com>
 *
 * @returns {void}
 */
const RadioButtonField = (field) => {
  const hasError = field.meta.touched && field.meta.invalid;
  const classes = classnames("radio form-group", { error: hasError });

  return (
    <div className={classes}>
      <input
        id={`field-id-${field.input.name}-${field.value}`}
        name={field.input.name}
        type="radio"
        className="form-control"
        value={field.input.value}
        onChange={() => {}}
        checked={field.value === field.input.value}
        disabled={field.disabled}
      />
      <label
        htmlFor={`field-id-${field.input.name}-${field.value}`}
        className="form-label"
        onClick={
          field.disabled
            ? undefined
            : () => {
                field.input.onChange(field.value);
              }
        }
      >
        {field.label}
      </label>
      {hasError && <span className="error-msg">{field.meta.error}</span>}
    </div>
  );
};

/**
 * Form field wrapping <FileSelector/>.
 *
 * @author Nathan Ozelim <nathan@startupfoundry.com>
 */
export class FileSelectorField extends Component {
  constructor(props) {
    super(props);

    this.handleFileSelected = this.handleFileSelected.bind(this);
    this.remove = this.remove.bind(this);
    this.hide = this.hide.bind(this);
  }

  handleFileSelected(file) {
    const reader = new FileReader();
    const { withPreview, text } = this.props;

    reader.onloadend = () => {
      this.props.input.onChange({
        file: text ? reader.result : reader.result.split(",").pop(),
        preview: withPreview ? reader.result : undefined,
        filename: file.name,
      });
    };

    if (text) {
      reader.readAsText(file);
    } else {
      reader.readAsDataURL(file);
    }
  }

  remove() {
    this.props.input.onChange(null);
  }

  hide() {
    const { input } = this.props;

    input.onChange({
      ...input.value,
      hidden: true,
    });
  }

  render() {
    const {
      input: { value, name },
      label,
      canHide,
    } = this.props;
    const formClasses = classnames(
      this.props.formClasses,
      "file-uploader-wrapper"
    );

    return (
      <FormGroup field={{ ...this.props, formClasses }}>
        <div className="file-uploader-field">
          {label && (
            <label className="form-label" htmlFor={`field-id-${name}`}>
              {label}
            </label>
          )}

          {value ? (
            <div className="attached">
              {canHide && value.id && !value.hidden && (
                <i className="fa fa-eye-slash" onClick={this.hide} />
              )}{" "}
              <i className="fa fa-trash" onClick={this.remove} />{" "}
              {value.filename || value.description}
              {value.hidden && (
                <span className="tag tag-sm tag-plain hidden">HIDDEN</span>
              )}
            </div>
          ) : (
            <div className="form-upload-box">
              <FileSelector
                {...this.props}
                onValidFileSelected={this.handleFileSelected}
              />
            </div>
          )}
        </div>
      </FormGroup>
    );
  }
}

/**
 * Just like <FileSelectorField/>, but allows for multiple files.
 *
 * @author Nathan Ozelim <nathan@startupfoundry.com>
 */
export class MultipleFileSelectorField extends Component {
  constructor(props) {
    super(props);

    this.handleFileChange = this.handleFileChange.bind(this);
  }

  handleFileChange(idx, fileValue) {
    const {
      input: { value, onChange },
    } = this.props;
    const files = [...(value || [])];

    /* remove file from list... */
    if (!fileValue) {
      files.splice(idx, 1);

      /* or replace its value */
    } else {
      files[idx] = fileValue;
    }

    onChange(files);
  }

  render() {
    const {
      input: { value, name },
      meta: { touched, invalid, error },
      acceptedFileType = "",
      canHide,
    } = this.props;
    const files = value || [];
    const hasError = touched && invalid;

    return (
      <div className="multiple-file-uploader-field">
        {/* existing files */}
        <div className="existing-files">
          {files.map((f, i) => (
            <FileSelectorField
              key={i}
              name={`${name}_${i}`}
              meta={{}}
              acceptedFileType={acceptedFileType}
              canHide={canHide}
              input={{ value: f, onChange: (v) => this.handleFileChange(i, v) }}
            />
          ))}
        </div>

        {/* new file */}
        <FileSelectorField
          name={`${name}_${files.length}`}
          meta={{}}
          acceptedFileType={acceptedFileType}
          input={{ onChange: (v) => this.handleFileChange(files.length, v) }}
        />

        {hasError && <span className="error-msg">{error}</span>}
      </div>
    );
  }
}

/**
 * Star rating field.
 *
 * @author Nathan Ozelim <nathan@startupfoundry.com>
 */
const TEXTUAL_TYPES = {
  agreement: [
    "Strongly Disagree",
    "Disagree",
    "Neutral",
    "Agree",
    "Strongly Agree",
  ],
  satisfaction: [
    "Very Dissatisfied",
    "Dissatisfied",
    "Neither",
    "Satisfied",
    "Very Satisfied",
  ],
  probability: [
    "Definitely Not",
    "Probably Not",
    "Might",
    "Likely",
    "Definitely",
  ],
};
export const StarRatingField = (props) => {
  const {
    input: { name, value, onChange },
    label,
    description,
    textualType,
    min = 1,
  } = props;
  /* if not defined, `initialRate` MUST be 0 to denote a pristine field */
  const initialRate = value || 0;

  return (
    <FormGroup field={props}>
      <div className="star-rating-field">
        <label className="form-label" htmlFor={`field-id-${name}`}>
          {label}
        </label>

        {description && <p className="description">{description}</p>}

        <StarRating initialRate={initialRate} min={min} onChange={onChange} />

        <label className="textual-rating">
          {TEXTUAL_TYPES[textualType][value - 1]}
        </label>
      </div>
    </FormGroup>
  );
};

export const ColorPickerField = (props) => {
  const { input, meta } = props;
  const hasError = meta.touched && meta.invalid;

  return (
    <FormGroup field={props}>
      <div className="color-picker-field">
        <ColorPicker value={input.value} onChange={input.onChange} />
        {hasError && <span className="error-msg">{meta.error}</span>}
      </div>
    </FormGroup>
  );
};

export {
  CheckBoxField,
  RadioButtonField,
  TextAreaField,
  BasicTextAreaField,
  ResizableTextAreaField,
  SelectField,
};
