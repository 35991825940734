import React, { Component } from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";
import classnames from "classnames";
import { Link } from "react-router";
import partial from "lodash/partial";
import Icon from "common/components/svg_icon";
import onClickOutside from "react-onclickoutside";

class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.state = { open: false };
    this.renderItem = this.renderItem.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.onItemClick = this.onItemClick.bind(this);
  }

  handleClickOutside(e) {
    /* close dropdown if clicking outside */
    if (this.state.open) {
      this.onToggle(e);
    }
  }

  onToggle(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const open = !this.state.open;
    this.setState({ open });

    /* invoke `onToggle` callback if provided */
    if (this.props.onToggle) {
      this.props.onToggle(open);
    }
  }

  onItemClick(action, closeOnClick, toggleOnClick, e) {
    e.stopPropagation();
    e.preventDefault();

    if (closeOnClick && this.state.open) {
      this.onToggle();
    }

    if (toggleOnClick) {
      this.onToggle();
    }

    action();
  }

  renderItem({
    label,
    show,
    action,
    url,
    extraClasses,
    extraBtnClasses,
    disabled,
    closeOnClick = true,
    toggleOnClick = false,
    target,
    name,
  }) {
    // @TODO: handle cases where `label` has elements that can't be nested (e.g. buttons)
    //        (React validateDOMNesting() warning)

    const onButtonClick = partial(
      this.onItemClick,
      action,
      closeOnClick,
      toggleOnClick
    );
    let content;
    if (url) {
      if (url.startsWith("#")) {
        content = (
          <a
            className={classnames("dropdown-item-button", extraBtnClasses)}
            href={url}
            disabled={disabled}
            onClick={(e) => {
              e.stopPropagation();
              if (this.state.open) {
                this.onToggle();
              }
            }}
          >
            {label}
          </a>
        );
      } else {
        content = (
          <Link
            className={classnames("dropdown-item-button", extraBtnClasses)}
            to={url}
            disabled={disabled}
            target={target}
            onClick={(e) => e.stopPropagation()}
          >
            {label}
          </Link>
        );
      }
    } else {
      content = (
        <button
          className={classnames("dropdown-item-button", extraBtnClasses)}
          onClick={onButtonClick}
          disabled={disabled}
        >
          {label}
        </button>
      );
    }

    return (
      <li
        className={classnames("dropdown-item", extraClasses)}
        key={v4()}
        id={name ? `dropdown_item_${name}` : undefined}
      >
        {content}
      </li>
    );
  }

  render() {
    const { buttons, className, showFirst } = this.props;
    const classes = classnames("dropdown", className);
    const visibleButtons = buttons.filter((b) => b.show);
    const { open } = this.state;

    /* no buttons to be shown */
    if (visibleButtons.length === 0) {
      return null;
    }

    const toggleWrapperClasses =
      "toggle-wrapper " +
      (visibleButtons[0].extraBtnClasses || "")
        .replace(/btn-/g, "")
        .replace(/btn/g, "") +
      (open ? " active" : "");
    const menuButtons = showFirst ? visibleButtons.slice(1) : visibleButtons;

    return (
      <div className={classes}>
        {showFirst ? (
          <ul
            className={classnames("btn-first-wrapper", {
              "single-button": menuButtons.length < 1,
            })}
          >
            {this.renderItem(visibleButtons[0])}

            {menuButtons.length > 0 && (
              <div className={toggleWrapperClasses} onClick={this.onToggle}>
                <i className={"fa fa-angle-" + (open ? "up" : "down")} />
              </div>
            )}
          </ul>
        ) : (
          <button
            type="button"
            className="btn btn-icon-only btn-tools dropdown-menu-toggle"
            onClick={this.onToggle}
          >
            <Icon extraClasses="svg-icon-lg menu" icon="menu" />
          </button>
        )}

        {open && (
          <ul className="dropdown-menu">{menuButtons.map(this.renderItem)}</ul>
        )}
      </div>
    );
  }
}

Dropdown.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      show: PropTypes.bool,
      action: PropTypes.func,
      closeOnClick: PropTypes.bool,
      toggleOnClick: PropTypes.bool,
      disabled: PropTypes.bool,
      target: PropTypes.string,
      extraClasses: PropTypes.string,
      extraBtnClasses: PropTypes.string,
    })
  ),
  showFirst: PropTypes.bool,
  onToggle: PropTypes.func,
  className: PropTypes.string,
};

export default onClickOutside(Dropdown);
