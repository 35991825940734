import request from "axios";
import uniqBy from "lodash/uniqBy";
import moment from "moment";
import fileDownload from "js-file-download";
import { browserHistory } from "react-router";
import { DATETIME_FORMAT } from "common/validators";
import { getAPIQueryString } from "common/utils";
import { savePageCount } from "common/pager/ducks";
import { showBanner, TYPE_SUCCESS } from "common/ducks/banner";
import { setBannerError } from "error";
import {
  DOMAIN_LISTS_RESOURCE_NAME,
  SAVED_DOMAINS_RESOURCE_NAME,
} from "wasp/constants";
import { generateRandomDomain } from "wasp/utils";

/* ACTIONS
================================================================================================ */
const FETCH_ALL_LISTS = "wasp/saved_domains/FETCH_ALL_LISTS";
const FETCH_ALL_LISTS_REQUEST = "wasp/saved_domains/FETCH_ALL_LISTS_REQUEST";
const FETCH_ALL_LISTS_SUCCESS = "wasp/saved_domains/FETCH_ALL_LISTS_SUCCESS";

const FETCH_LISTS = "wasp/saved_domains/FETCH_LISTS";
const FETCH_LISTS_REQUEST = "wasp/saved_domains/FETCH_LISTS_REQUEST";
const FETCH_LISTS_SUCCESS = "wasp/saved_domains/FETCH_LISTS_SUCCESS";

const FETCH_DOMAINS = "wasp/saved_domains/FETCH_DOMAINS";
const FETCH_DOMAINS_REQUEST = "wasp/saved_domains/FETCH_DOMAINS_REQUEST";
const FETCH_DOMAINS_SUCCESS = "wasp/saved_domains/FETCH_DOMAINS_SUCCESS";

const SET_SELECTED_DOMAINS = "wasp/saved_domains/SET_SELECTED_DOMAINS";

const FETCH_LIST = "wasp/saved_domains/FETCH_LIST";
const FETCH_LIST_REQUEST = "wasp/saved_domains/FETCH_LIST_REQUEST";
const FETCH_LIST_SUCCESS = "wasp/saved_domains/FETCH_LIST_SUCCESS";

const SAVE_LIST = "wasp/saved_domains/SAVE_LIST";
const SAVE_LIST_SUCCESS = "wasp/saved_domains/SAVE_LIST_SUCCESS";

const FETCH_DOMAIN = "wasp/saved_domains/FETCH_DOMAIN";
const FETCH_DOMAIN_REQUEST = "wasp/saved_domains/FETCH_DOMAIN_REQUEST";
const FETCH_DOMAIN_SUCCESS = "wasp/saved_domains/FETCH_DOMAIN_SUCCESS";

const SAVE_DOMAIN = "wasp/saved_domains/SAVE_DOMAIN";
const SAVE_DOMAIN_SUCCESS = "wasp/saved_domains/SAVE_DOMAIN_SUCCESS";

/* HELPERS
================================================================================================ */
export const createListRequest = () =>
  request.post("/api/v1/saved_domain_lists/");

export const createSavedDomainRequest = (listId) => {
  return request.post(`/api/v1/saved_domain_lists/${listId}/saved_domains/`, {
    list: listId,
    /* generate a temporary, unique domain */
    url: generateRandomDomain(),
  });
};

export const uploadSavedDomainsRequest = (listId, data) => {
  return request.post(
    `/api/v1/saved_domain_lists/${listId}/saved_domains/upload_csv/`,
    {
      csv_string: data.file,
    }
  );
};

export const exportSavedDomainsRequest = (listId) => {
  const promise = request.get(
    `/api/v1/saved_domain_lists/${listId}/saved_domains/export_csv/`
  );

  promise.then((res) => {
    const filename = res.headers["content-disposition"]
      .split("attachment; filename=")[1]
      .replaceAll("'", "");
    fileDownload(res.data, filename);
  });

  return promise;
};

/* INITIAL STATES
================================================================================================ */
const initialState = {
  allLists: [],
  isFetchingAllLists: false,
  lists: null,
  listCount: 0,
  savedDomains: null,
  savedDomainCount: 0,
  selectedSavedDomains: [],
  list: null,
  savedDomain: null,
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_LISTS_REQUEST:
      return {
        ...state,
        isFetchingAllLists: true,
      };
    case FETCH_ALL_LISTS_SUCCESS:
      return {
        ...state,
        isFetchingAllLists: false,
        allLists: action.payload,
      };
    case FETCH_LISTS_REQUEST:
      return {
        ...state,
        lists: null,
      };
    case FETCH_LISTS_SUCCESS:
      return {
        ...state,
        lists: action.payload.results,
        listCount: action.payload.count,
      };
    case FETCH_DOMAINS_REQUEST:
      return {
        ...state,
        savedDomains: null,
      };
    case FETCH_DOMAINS_SUCCESS:
      return {
        ...state,
        savedDomains: action.payload.results,
        savedDomainCount: action.payload.count,
      };
    case SET_SELECTED_DOMAINS:
      return {
        ...state,
        selectedSavedDomains: uniqBy(action.payload, "id"),
      };
    case FETCH_LIST_REQUEST:
      return {
        ...state,
        list: null,
      };
    case FETCH_LIST_SUCCESS:
    case SAVE_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };
    case FETCH_DOMAIN_REQUEST:
      return {
        ...state,
        savedDomain: null,
      };
    case FETCH_DOMAIN_SUCCESS:
    case SAVE_DOMAIN_SUCCESS:
      return {
        ...state,
        savedDomain: action.payload,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const fetchAllLists = () => (dispatch, getState) => {
  const { isFetchingAllLists } = getState().wasp.savedDomains;

  if (isFetchingAllLists) {
    return Promise.resolve();
  }

  return dispatch({
    type: FETCH_ALL_LISTS,
    promise: request.get("/api/v1/saved_domain_lists/all/"),
  });
};

export const fetchLists = (query) => (dispatch) => {
  const queryStr = getAPIQueryString(DOMAIN_LISTS_RESOURCE_NAME, query);
  const promise = request.get(`/api/v1/saved_domain_lists/?${queryStr}`);

  /* save page count in pager state */
  promise.then((res) =>
    dispatch(savePageCount(DOMAIN_LISTS_RESOURCE_NAME, res))
  );

  return dispatch({
    type: FETCH_LISTS,
    promise,
  });
};

export const fetchSavedDomains = (listId, query) => (dispatch) => {
  const queryStr = getAPIQueryString(SAVED_DOMAINS_RESOURCE_NAME, query);
  const promise = request.get(
    `/api/v1/saved_domain_lists/${listId || "all"}/saved_domains/?${queryStr}`
  );

  /* save page count in pager state */
  promise.then((res) =>
    dispatch(savePageCount(SAVED_DOMAINS_RESOURCE_NAME, res))
  );

  return dispatch({
    type: FETCH_DOMAINS,
    promise,
  });
};

export const setSelectedSavedDomains = (payload) => (dispatch) => {
  return dispatch({
    type: SET_SELECTED_DOMAINS,
    payload,
  });
};

export const deleteSavedDomain = (listId, id) => (dispatch) => {
  const promise = request.delete(
    `/api/v1/saved_domain_lists/${listId}/saved_domains/${id}/`
  );

  promise.catch((err) => {
    const errorMsg = err.data ? JSON.stringify(err.data) : "Unkown error.";
    dispatch(setBannerError("Error deleting domain", errorMsg));
  });

  return promise;
};

export const performSavedDomainBulkAction =
  (listId, action, data, onSuccess) => (dispatch, getState) => {
    const { selectedSavedDomains } = getState().wasp.savedDomains;

    const promise = Promise.all(
      selectedSavedDomains.map((d) =>
        dispatch(deleteSavedDomain(listId || "all", d.id))
      )
    );

    promise.then(() => {
      dispatch(setSelectedSavedDomains([]));
      dispatch(onSuccess());
    });

    return promise;
  };

export const fetchList = (id) => (dispatch) => {
  const promise = request.get(`/api/v1/saved_domain_lists/${id}/`);

  promise.catch((err) => {
    if (err.status === 404) {
      browserHistory.replace("/404/");
    }
  });

  dispatch({
    type: FETCH_LIST,
    promise,
  });

  return promise;
};

export const saveList = (id, formData) => (dispatch) => {
  const promise = request.patch(`/api/v1/saved_domain_lists/${id}/`, formData);

  promise
    .then(() => {
      dispatch(
        showBanner(
          "Success",
          "Domain list successfully saved.",
          undefined,
          TYPE_SUCCESS,
          null,
          3000
        )
      );
    })
    .catch((err) => {
      const errorMsg = err.data
        ? JSON.stringify(err.data)
        : "Make sure the data you entered is correct.";
      dispatch(setBannerError("Error saving domain list", errorMsg));
    });

  dispatch({
    type: SAVE_LIST,
    promise,
  });

  return promise;
};

export const deleteList = (id) => (dispatch) => {
  const promise = request.delete(`/api/v1/saved_domain_lists/${id}/`);

  promise.catch((err) => {
    const errorMsg = err.data ? JSON.stringify(err.data) : "Unkown error.";
    dispatch(setBannerError("Error deleting domain list", errorMsg));
  });

  return promise;
};

export const fetchSavedDomain = (listId, id) => (dispatch) => {
  const promise = request.get(
    `/api/v1/saved_domain_lists/${listId}/saved_domains/${id}/`
  );

  promise.catch((err) => {
    if (err.status === 404) {
      browserHistory.replace("/404/");
    }
  });

  dispatch({
    type: FETCH_DOMAIN,
    promise,
  });

  return promise;
};

export const saveSavedDomain = (listId, id, formData) => (dispatch) => {
  const data = {
    ...formData,
    first_seen: formData.first_seen
      ? moment(formData.first_seen, DATETIME_FORMAT).format()
      : null,
    last_seen: formData.last_seen
      ? moment(formData.last_seen, DATETIME_FORMAT).format()
      : null,
    extra_context: JSON.parse(formData.extra_context),
  };
  const promise = request.patch(
    `/api/v1/saved_domain_lists/${listId}/saved_domains/${id}/`,
    data
  );

  promise
    .then(() => {
      dispatch(
        showBanner(
          "Success",
          "Domain successfully saved.",
          undefined,
          TYPE_SUCCESS,
          null,
          3000
        )
      );
    })
    .catch((err) => {
      const errorMsg = err.data
        ? JSON.stringify(err.data)
        : "Make sure the data you entered is correct.";
      dispatch(setBannerError("Error saving domain", errorMsg));
    });

  dispatch({
    type: SAVE_DOMAIN,
    promise,
  });

  return promise;
};
