import request from "axios";
import PropTypes from "prop-types";
import cookie from "react-cookie";
import idx from "idx";
import { PACKAGE_ITEMS } from "pap/ducks/workflow";
import { AWARD_ASSURED } from "pap/constants";
import { isDiscountFeature } from "pap/utils";
import { fetchCurrentUser } from "common/ducks/user";

const PENDING_CODE_COOKIE_NAME = "pending_discount_code";

/* HELPERS
================================================================================================ */
export const assureProjectRequest = (
  value,
  projectId,
  currentFeatures,
  user
) => {
  const item = PACKAGE_ITEMS.find((item) => item.name == AWARD_ASSURED);

  return updateFeaturesRequest(
    projectId,
    currentFeatures,
    { [item.step]: { [item.fieldName]: value } },
    null,
    user
  );
};

/* Update project package features according to user modifications */
export const updateFeaturesRequest = (
  projectId,
  currentFeatures,
  formValues,
  billables,
  user
) => {
  const updates = [];

  /* go over package items that represent features (i.e. exclude awards) */
  PACKAGE_ITEMS.filter((i) => !!i.name).map((item) => {
    const stepValues = formValues[item.step] || {};
    const fieldValue = stepValues[item.fieldName];
    let feature = null;

    /* group feature */
    if (item.isGroup) {
      const groupFeatures = currentFeatures.filter(
        ({ feature }) => feature.group == item.name
      );

      const discountFeature = groupFeatures.find((f) =>
        isDiscountFeature(f, currentFeatures, billables, user)
      );

      /* no local updates or discount features: ignore */
      if (fieldValue === undefined && !discountFeature) {
        return;
      }

      /* force activation of discount feature if that's the case */
      if (discountFeature) {
        updates.push({ id: discountFeature.feature.id, active: true });

        /* no feature in the group should be active: deactivate whichever is active */
      } else if (fieldValue === null) {
        feature = groupFeatures.find(({ feature }) => feature.active);
        if (feature) {
          updates.push({ id: feature.feature.id, active: false });
        }
        /* activate selected feature in the group */
      } else {
        feature = groupFeatures.find(
          ({ feature }) => feature.id == fieldValue
        ).feature;
        updates.push({ id: feature.id, active: true });
      }

      /* single feature */
    } else {
      feature = currentFeatures.find(
        ({ feature }) => feature.name == item.name
      );

      if (feature) {
        const isDiscount = isDiscountFeature(
          feature,
          currentFeatures,
          billables,
          user
        );

        /* no local updates and is not discount: ignore */
        if (fieldValue === undefined && !isDiscount) {
          return;
        }

        /* force activation of discount feature if that's the case, or just use field value */
        updates.push({
          id: feature.feature.id,
          active: isDiscount || fieldValue,
        });
      }
    }
  });

  return request.patch(`/api/v1/projects/${projectId}/feature/change/`, {
    updates: updates,
  });
};

/* Update project awards according to user modifications */
export const updateAwardsRequest = (projectId, currentAwards, formValues) => {
  const updates = [];
  const stepValues = formValues["award_options"] || {};
  const includedValues = stepValues["included_awards"] || {};
  const extraValues = stepValues["extra_awards"] || [];

  /* update included awards that were modified */
  Object.keys(includedValues).map((featId) => {
    /* get UUID of award based on its feature ID */
    const award = currentAwards.find(({ feature }) => feature.id == featId);
    updates.push({
      command: "edit",
      values: [{ uuid: award.uuid, value: includedValues[featId] }],
    });
  });

  /* update/add/remove extra awards that were modified */
  extraValues.map((award) => {
    /* ignore empty awards */
    if (!(award.uuid || award.value)) {
      return;
    }

    /* new extra award */
    if (!award.uuid) {
      updates.push({ command: "add", values: [award.value] });

      /* removed extra award */
    } else if (award.deleted) {
      updates.push({ command: "remove", uids: [award.uuid] });

      /* updated extra award */
    } else {
      updates.push({
        command: "edit",
        values: [{ uuid: award.uuid, value: award.value }],
      });
    }
  });

  return request.patch(`/api/v1/projects/${projectId}/awardrules/change/`, {
    updates: updates,
  });
};

export const fetchDiscountCode = (slug) => (dispatch, getState) => {
  const { user } = getState();

  const promise = request.get(`/api/v1/discount-codes/${slug}/`);

  promise.then(({ data }) => {
    /* if user is logged in, fetch updated user object with new discount code */
    if (user.logged_in) {
      dispatch(fetchCurrentUser());
      /* otherwise, mark discount code as pending so that it can be stored in user object if/when
       current user signs up */
    } else {
      savePendingDiscountCode(data);
    }
  });

  dispatch({
    type: FETCH_DISCOUNT_CODE,
    promise,
  });

  return promise;
};

/* Get discount code from user object, if any.
   A discount code is stored in the user object until a project is posted,
   in which case it's removed and added to the project as a billable. */
export const getStoredDiscountCode = (user) => {
  const code = idx(user, (_) => _.current_discount_code);
  if (code && code.code_slug) {
    const { amount, percentage, free_features, kind, percentage_over } =
      code.code_details;
    return {
      slug: code.code_slug,
      amount: parseFloat(amount),
      percentage: parseFloat(percentage),
      free_features,
      kind,
      percentage_over,
    };
  }
  return null;
};

/* Get pending discount code from cookies, i.e. a discount code that was retrieved
   before user signed up and therefore was not yet stored in the user object. */
export const getPendingDiscountCode = (remove) => {
  const code = cookie.load(PENDING_CODE_COOKIE_NAME);
  if (remove) {
    cookie.remove(PENDING_CODE_COOKIE_NAME, { path: "/" });
  }
  return code;
};

/* Save pending discount code to cookies, i.e. a discount code that was retrieved
   before user signed up and therefore was not yet stored in the user object. */
const savePendingDiscountCode = ({ slug, cookie_lifetime_days }) => {
  const lifetimeInSecs = cookie_lifetime_days * 24 * 60 * 60;
  cookie.save(PENDING_CODE_COOKIE_NAME, slug, {
    path: "/",
    maxAge: lifetimeInSecs,
  });
};

/* ACTIONS
================================================================================================ */

const FETCH_PRICING = "crowdspring/pap/FETCH_PROJECT_PRICING";
const FETCH_PRICING_REQUEST = "crowdspring/pap/FETCH_PROJECT_PRICING_REQUEST";
const FETCH_PRICING_SUCCESS = "crowdspring/pap/FETCH_PROJECT_PRICING_SUCCESS";
const FETCH_PRICING_FAILURE = "crowdspring/pap/FETCH_PROJECT_PRICING_FAILURE";

const FETCH_DISCOUNT_CODE = "crowdspring/pap/FETCH_DISCOUNT_CODE";

/* ACTION CREATORS
================================================================================================ */

export const fetchPricing = (subCategoryId) => (dispatch) => {
  const promise = request.get(`/api/v1/subcategory-packages/${subCategoryId}/`);
  dispatch({
    type: FETCH_PRICING,
    promise,
  });
  return promise;
};

/* INITIAL STATE
================================================================================================ */
const INITIAL_STATE = {
  packages: null,
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_PRICING_SUCCESS:
      return {
        ...state,
        packages: action.payload.packages,
      };
    default:
      return state;
  }
}
