import React from "react";

export const ELITE_PACKAGE_SLUG = "elite";

/*
 * Features/features groups/resources names.
 * These values MUST match the names defined in the backend.
 */
export const PLATINUM_ONLY_DESIGNERS = "Platinum-only Designers";
export const PLATINUM_ONLY_CREATIVES = "Platinum-only Creatives";
export const UNLIMITED_COLLABORATION = "Team Collaboration";
export const NDAS = "NDAs";
export const SEARCH_EXCLUSION = "Search Exclusion";
export const PRIVATE_PROJECT = "Private Project";
export const PRIVACY_BUNDLE = "Privacy Bundle";
export const AWARD_ASSURED = "Assure your award";
export const UNLIMITED_PRESENTATIONS = "Unlimited Presentations";
export const UNLIMITED_FOCUS_GROUPS = "Unlimited Focus Groups";
export const ONE_FOCUS_GROUP = "One Focus Group";
export const PERSONAL_CONSULTANT = "Personal Project Consultant";
export const DURATION_GROUP = "LENGTH";
export const PROMOTION_GROUP = "PROMOTION";
export const NDAS_MANUAL_APPROVAL = "NDAS_MANUAL_APPROVAL";

/*
 * LEGACY features/features groups names.
 * These values MUST match the names defined in the backend.
 */
export const LEGACY_PLATINUM_ONLY_DESIGNERS = "Platinum creatives only";
export const LEGACY_UNLIMITED_COLLABORATION = "Collaboration features";
export const LEGACY_NDAS = "NDA";
export const LEGACY_SEARCH_EXCLUSION = "Search exclusion";
export const LEGACY_PRIVATE_GALLERY = "Blind project";
export const LEGACY_DURATION = "Length";
export const LEGACY_STARTER_PROMOTION = "Starter promotion";
export const LEGACY_BASIC_PROMOTION = "Basic promotion";
export const LEGACY_ADVANCED_PROMOTION = "Advanced promotion";
export const LEGACY_ULTIMATE_PROMOTION = "Ultimate promotion";
export const LEGACY_TWITTER_PROMOTION = "Twitter promotion";

export const PACKAGE_SELECTOR_DESCRIPTIONS = {
  silver: (
    <span>
      For individuals
      <br className="show-for-large" /> and new small businesses
    </span>
  ),
  gold: (
    <span>
      Perfect for startups
      <br className="show-for-large" /> and growing businesses
    </span>
  ),
  elite: (
    <span>
      Great for businesses,
      <br className="show-for-large" /> agencies & marketers
    </span>
  ),
  platinum: (
    <span>
      All-inclusive for businesses,
      <br className="show-for-large" /> agencies & marketers
    </span>
  ),
};

export const MINI_PROJECT_CAT_SLUG = "update-existing-design";
export const MINI_PROJECT_SUBCAT_SLUG = "update-existing-design";
export const MINI_PROJECT_SUBCAT_NAME = "Update existing design";

export const MIN_AWARD_STEP = 5;
export const DEFAULT_MAX_AWARD = 10000;

export const AWARD_REFERENCES = {
  "1to1-logo-design": { lowMax: 150, fairMax: 250, goodMax: 500 },
  "1to1-web-banner-design": { lowMax: 125, fairMax: 200, goodMax: 300 },
  "1to1-website-design": { lowMax: 300, fairMax: 500, goodMax: 750 },
  "1to1-wall-mural-art-design": { lowMax: 150, fairMax: 250, goodMax: 500 },
  "1to1-vehicle-wrap-design": { lowMax: 150, fairMax: 250, goodMax: 500 },
  "1to1-t-shirt-design": { lowMax: 150, fairMax: 250, goodMax: 400 },
  "1to1-tattoo-design": { lowMax: 150, fairMax: 250, goodMax: 400 },
  "1to1-business-tagline": { lowMax: 150, fairMax: 250, goodMax: 400 },
  "1to1-social-media-assets-design": {
    lowMax: 150,
    fairMax: 250,
    goodMax: 400,
  },
  "1to1-product-design": { lowMax: 500, fairMax: 750, goodMax: 1000 },
  "1to1-presentation-design": { lowMax: 150, fairMax: 250, goodMax: 500 },
  "1to1-poster-design": { lowMax: 150, fairMax: 250, goodMax: 400 },
  "1to1-postcard-or-flyer-design": { lowMax: 150, fairMax: 250, goodMax: 400 },
  "1to1-packaging-design": { lowMax: 200, fairMax: 350, goodMax: 600 },
  "1to1-package-graphics": { lowMax: 150, fairMax: 250, goodMax: 400 },
  "1to1-outdoor-or-signage-design": { lowMax: 150, fairMax: 250, goodMax: 400 },
  "1to1-other-clothing-design": { lowMax: 150, fairMax: 250, goodMax: 400 },
  "1to1-other": { lowMax: 150, fairMax: 250, goodMax: 500 },
  "1to1-mobile-ui-design": { lowMax: 200, fairMax: 400, goodMax: 750 },
  "1to1-merchandise-design": { lowMax: 150, fairMax: 250, goodMax: 400 },
  "1to1-magazine-cover-design": { lowMax: 150, fairMax: 250, goodMax: 400 },
  "1to1-logo-and-social-media-assets-design": {
    lowMax: 175,
    fairMax: 250,
    goodMax: 400,
  },
  "1to1-logo-and-business-card-design": {
    lowMax: 175,
    fairMax: 250,
    goodMax: 500,
  },
  "1to1-letterhead-and-stationery-design": {
    lowMax: 150,
    fairMax: 250,
    goodMax: 400,
  },
  "1to1-landing-page-design": { lowMax: 150, fairMax: 250, goodMax: 500 },
  "1to1-infographics-design": { lowMax: 200, fairMax: 300, goodMax: 400 },
  "1to1-illustration-and-graphic-design": {
    lowMax: 200,
    fairMax: 350,
    goodMax: 400,
  },
  "1to1-icon-button-and-widget-design": {
    lowMax: 150,
    fairMax: 250,
    goodMax: 400,
  },
  "1to1-email-design": { lowMax: 150, fairMax: 250, goodMax: 400 },
  "1to1-naming-a-business-or-product": {
    lowMax: 150,
    fairMax: 250,
    goodMax: 400,
  },
  "1to1-business-card-design": { lowMax: 150, fairMax: 250, goodMax: 400 },
  "1to1-brochure-or-menu-design": { lowMax: 150, fairMax: 250, goodMax: 400 },
  "1to1-book-cover-design": { lowMax: 150, fairMax: 250, goodMax: 400 },
  "1to1-blog-theme-design": { lowMax: 150, fairMax: 250, goodMax: 400 },
};

export const DEFAULT_AWARD_REFERENCES = {
  lowMax: 150,
  fairMax: 250,
  goodMax: 500,
};

export const COST_ITEM_DISCOUNT = "Discount";
export const COST_ITEM_FEATURE_DISCOUNT_SUFFIX = ` ${COST_ITEM_DISCOUNT}`;
export const COST_ITEM_ADMIN_DISCOUNT = "Admin Discount";
