export const REFERRAL_SOURCE_BS = "brand_studio";

export const REFERRAL_MONEY_DISCOUNT = {
  default: "$35",
  [REFERRAL_SOURCE_BS]: "$30",
};

export const REFERRAL_FEATURE_DISCOUNT = {
  default: "$99 advanced promotion",
  [REFERRAL_SOURCE_BS]: "$99 advanced promotion",
};

export const REFERRAL_TOTAL_DISCOUNT = {
  default: "$134",
  [REFERRAL_SOURCE_BS]: "$129",
};

export const REFERRER_COOKIE_NAME = "referrer";
export const REFERRAL_SOURCE_COOKIE_NAME = "referral_source";
export const REFERRER_COOKIE_MAX_AGE = 365 * 24 * 60 * 60; // in seconds

export const MIN_BS_REFERRALS_STR = "three";
