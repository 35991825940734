import { connect } from "react-redux";
import { withRouter } from "react-router";
import { push } from "react-router-redux";
import UserTypeTabs from "your_projects/components/user_type_tabs";

const mapStateToProps = ({ user }) => {
  return {
    user: user.profile_data,
  };
};

export default withRouter(connect(mapStateToProps, { push })(UserTypeTabs));
