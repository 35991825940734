/*
 * Based on the template in Web Starter Kit : https://github.com/google/web-starter-kit/blob/master/app/index.html
 * To add to the config, add an object:
 * {
 *  type: 'link' | 'meta',
 *  sizes: 'widthxheight',
 *  rel: 'rel value'
 *  filename: <Name of your file'
 * }
 */

// Import all your needed files first (webpack will grab the url)
// import chromecon from 'images/chrome-ninja192-precomposed.png';
// import applecon from 'images/apple-ninja152-precomposed.png';
// import mscon from 'images/ms-ninja144-precomposed.png';
import favicon from "assets/images/favicon.png";

const config = {
  title: "crowdspring",
  titleTemplate: "%s | crowdspring",
  link: [
    {
      rel: "icon",
      sizes: "192x192",
      href: "https://assets.crowdspring.com/marketing/cs-app-icon.png",
    },
    { rel: "shortcut icon", href: favicon },
    {
      rel: "apple-touch-icon-precomposed",
      href: "https://assets.crowdspring.com/marketing/cs-app-icon.png",
    },
    {
      rel: "apple-touch-startup-image",
      href: "https://assets.crowdspring.com/marketing/cs-app-launch.png",
    },

    // SEO: If your mobile URL is different from the desktop URL,
    // add a canonical link to the desktop page https://developers.google.com/webmasters/smartphone-sites/feature-phones
    // { 'rel': 'canonical', 'href': 'http://www.example.com/' }
  ],
  meta: [
    { charset: "utf-8" },
    // Setting IE=edge tells Internet Explorer to use the latest engine to render the page and execute Javascript
    { "http-equiv": "X-UA-Compatible", content: "IE=edge" },
    //  Meta descriptions are commonly used on search engine result pages to display preview snippets for a given page.
    {
      name: "description",
      content:
        "The #1 marketplace for logo design, web design, graphic design and company names. 60,000+ happy clients! Work you'll love, guaranteed.",
    },
    // Mobile Safari introduced this tag to let web developers control the viewport's size and scale
    // The width property controls the size of the viewport, the initial-scale property controls
    // the zoom level when the page is first loaded
    { name: "viewport", content: "width=device-width, initial-scale=1" },
    // Add to homescreen for Chrome on Android
    { name: "mobile-web-app-capable", content: "yes" },
    // Add to homescreen for Safari on IOS

    { name: "apple-mobile-web-app-capable", content: "yes" },
    { name: "apple-mobile-web-app-status-bar-style", content: "black" },
    { name: "apple-mobile-web-app-title", content: "crowdspring" },
    // Tile icon for Win8 (144x144 + tile color)
    // { name: 'msapplication-TileImage', content: mscon },
    { name: "msapplication-TileColor", content: "#23B3F1" },

    { name: "theme-color", content: "#23B3F1" },

    // Open Graph defaults
    {
      property: "og:title",
      content:
        "Logos, Website, Graphic Design, Product Design, Naming & More | crowdspring",
    },
    { property: "og:url", content: "https://www.crowdspring.com/" },
    { property: "og:site_name", content: "crowdspring" },
    { property: "og:type", content: "website" },
    {
      property: "og:description",
      content:
        "The #1 marketplace for logo design, web design, graphic design and company names. 60,000+ happy clients! Work you'll love, guaranteed.",
    },
    {
      property: "og:image",
      content:
        "https://assets.crowdspring.com/marketing/crowdspring_fb-share.png",
    },
    { name: "robots", content: "index,follow" },
  ],
};

export default config;
