import capitalize from "lodash/capitalize";
import sortBy from "lodash/sortBy";

export const STATUS_MAP = {
  finalist_round: "Finalist Round (open)",
  finalist_closed: "Finalist Round (closed)",
  "1_1_negotiating": "negotiating",
  "1_1_ready": "awaiting payment",
};

export const PROJECTS_RESOURCE_NAME = "admin_projects";

export const PROJECT_FEATURE_OPTIONS = [
  { field: "presentations_allowed", label: "Unlimited Presentations" },
  { field: "private_gallery", label: "Private gallery" },
  { field: "fast_tracked", label: "Fast-tracked" },
  { field: "assured", label: "Assured" },
  { field: "nda_required", label: "NDAs" },
  { field: "search_exclusion", label: "Search engine exclusion" },
  { field: "collaboration_allowed", label: "Team collaboration" },
  { field: "platinum_only", label: "Platinum-only creatives" },
  { field: "elite_only", label: "Elite-only creatives" },
  { field: "has_dedicated_manager", label: "Dedicated manager" },
  {
    field: "has_personal_project_consultant",
    label: "Personal project consultant",
  },
  { field: "has_twitter_promo", label: "X (Twitter) promo" },
  { field: "has_newsletter_promo", label: "Newsletter promo" },
  { field: "has_top_creatives_promo", label: "Top creatives invitation promo" },
  { field: "featured", label: "Featured promo" },
];

export const PROJECT_ENTRIES_RESOURCE_NAME = "admin_project_entries";

export const REVIEWS_RESOURCE_NAME = "admin_reviews";

export const REVIEW_STATUS_OPTIONS = [
  { field: "published", label: "Published" },
  { field: "unpublished", label: "Rejected" },
  { field: "pending", label: "Pending" },
];

export const PROJECT_ACTIONS_RESOURCE_NAME = "admin_project_actions";

export const ACTION_CREATE_DRAFT = "create_draft";
export const ACTION_POST_PROJECT = "post_project";
export const ACTION_CREATE_PROJECT_MSG = "create_project_message";
export const ACTION_INVITE_CREATIVE = "invite_creative";
export const ACTION_EXTEND_PROJECT = "extend_project";
export const ACTION_CHANGE_AWARDS = "change_awards";
export const ACTION_CHANGE_FEATURES = "change_features";
export const ACTION_INVITE_COLLABORATOR = "invite_collaborator";
export const ACTION_DELETE_COLLABORATOR = "delete_collaborator";
export const ACTION_APPROVE_NDA_REQUEST = "approve_nda_request";
export const ACTION_DENY_NDA_REQUEST = "deny_nda_request";
export const ACTION_CREATE_NDA_REQUEST = "create_nda_request";
export const ACTION_UPDATE_PROJECT_MSG = "update_project_message";
export const ACTION_HIDE_PROJECT_MSG = "hide_project_message";
export const ACTION_UNHIDE_PROJECT_MSG = "unhide_project_message";
export const ACTION_UPDATE_BRIEF = "update_brief";
export const ACTION_CREATE_PROJECT_ATTACHMENT = "create_project_attachment";
export const ACTION_DELETE_PROJECT_ATTACHMENT = "delete_project_attachment";
export const ACTION_DISCOUNT = "discount";
export const ACTION_REFUND = "refund";
export const ACTION_ENABLE_SEARCH_EXCLUSION = "enable_search_exclusion";
export const ACTION_DISABLE_SEARCH_EXCLUSION = "disable_search_exclusion";
export const ACTION_SCORE_ENTRY = "score_entry";
export const ACTION_GIVE_ENTRY_FEEDBACK = "give_entry_feedback";
export const ACTION_ELIMINATE_ENTRY = "eliminate_entry";
export const ACTION_REINSTATE_ENTRY = "reinstate_entry";
export const ACTION_REMOVE_CREATIVE = "remove_creative";
export const ACTION_REINSTATE_CREATIVE = "reinstate_creative";
export const ACTION_CREATE_ENTRY_COMMENT = "create_entry_comment";
export const ACTION_DELETE_ENTRY_COMMENT = "delete_entry_comment";
export const ACTION_AWARD = "award";
export const ACTION_TIP = "tip";
export const ACTION_OFFER = "offer";
export const ACTION_CREATE_ENTRY = "create_entry";
export const ACTION_CREATE_REVISION = "create_revision";
export const ACTION_WITHDRAW_ENTRY = "withdraw_entry";
export const ACTION_WITHDRAW_MULTIPLE_ENTRIES = "withdraw_multiple_entries";
export const ACTION_UNWITHDRAW_MULTIPLE_ENTRIES = "unwithdraw_multiple_entries";
export const ACTION_CREATE_WRAPUP_MSG = "create_wrapup_message";
export const ACTION_DOWNLOAD_WRAPUP_FILE = "download_wrapup_file";
export const ACTION_APPROVE_PROOFS = "approve_proofs";
export const ACTION_APPROVE_FINAL_FILES = "approve_final_files";
export const ACTION_APPROVE_NAME = "approve_name";
export const ACTION_SUBMIT_USER_SURVEY = "submit_user_survey";
export const ACTION_SIGN_ICA = "sign_ica";
export const ACTION_UPLOAD_WRAPUP_FILES = "upload_wrapup_files";
export const ACTION_CREATE_FOCUS_GROUP = "create_focus_group";
export const ACTION_CREATE_FG_ENTRY = "create_focus_group_entry";
export const ACTION_DELETE_FG_ENTRY = "delete_focus_group_entry";
export const ACTION_CREATE_FG_PARTICIPANT = "create_focus_group_participant";
export const ACTION_DELETE_FG_PARTICIPANT = "delete_focus_group_participant";
export const ACTION_CLOSE_FOCUS_GROUP = "close_focus_group";
export const ACTION_OPEN_FOCUS_GROUP = "open_focus_group";
export const ACTION_VOTE_ON_FG_ENTRY = "vote_on_focus_group_entry";
export const ACTION_SET_FG_PARTICIPANT_INFO =
  "set_focus_group_participant_info";
export const ACTION_CREATE_PRESENTATION = "create_presentation";
export const ACTION_CREATE_PRESENTATION_ENTRY = "create_presentation_entry";
export const ACTION_DELETE_PRESENTATION_ENTRY = "delete_presentation_entry";
export const ACTION_CREATE_PRESENTATION_PARTICIPANT =
  "create_presentation_participant";
export const ACTION_DELETE_PRESENTATION_PARTICIPANT =
  "delete_presentation_participant";
export const ACTION_CLOSE_PRESENTATION = "close_presentation";
export const ACTION_VOTE_ON_PRESENTATION_ENTRY = "vote_on_presentation_entry";
export const ACTION_CREATE_PRESENTATION_ENTRY_COMMENT =
  "create_presentation_entry_comment";
export const ACTION_SET_PRESENTATION_PARTICIPANT_INFO =
  "set_presentation_participant_info";
export const ACTION_ADD_FINALIST = "add_finalist";
export const ACTION_REMOVE_FINALIST = "remove_finalist";
export const ACTION_LAUNCH_FINAL_ROUND = "launch_finalist_round";
export const ACTION_EXTEND_FINAL_ROUND = "extend_finalist_round";
export const ACTION_CREATE_NEGOTIATION_MSG = "create_negotiation_message";

const ACTION_GROUPS = {
  General: [
    ACTION_POST_PROJECT,
    ACTION_CREATE_PROJECT_MSG,
    ACTION_INVITE_CREATIVE,
    ACTION_EXTEND_PROJECT,
    ACTION_CHANGE_AWARDS,
    ACTION_CHANGE_FEATURES,
    ACTION_INVITE_COLLABORATOR,
    ACTION_DELETE_COLLABORATOR,
    ACTION_APPROVE_NDA_REQUEST,
    ACTION_DENY_NDA_REQUEST,
    ACTION_CREATE_NDA_REQUEST,
    ACTION_UPDATE_PROJECT_MSG,
    ACTION_HIDE_PROJECT_MSG,
    ACTION_UNHIDE_PROJECT_MSG,
    ACTION_UPDATE_BRIEF,
    ACTION_CREATE_PROJECT_ATTACHMENT,
    ACTION_DELETE_PROJECT_ATTACHMENT,
    ACTION_DISCOUNT,
    ACTION_REFUND,
    ACTION_ENABLE_SEARCH_EXCLUSION,
    ACTION_DISABLE_SEARCH_EXCLUSION,
    ACTION_CREATE_NEGOTIATION_MSG,
  ],
  Entry: [
    ACTION_SCORE_ENTRY,
    ACTION_GIVE_ENTRY_FEEDBACK,
    ACTION_ELIMINATE_ENTRY,
    ACTION_REINSTATE_ENTRY,
    ACTION_REMOVE_CREATIVE,
    ACTION_REINSTATE_CREATIVE,
    ACTION_CREATE_ENTRY_COMMENT,
    ACTION_DELETE_ENTRY_COMMENT,
    ACTION_AWARD,
    ACTION_TIP,
    ACTION_OFFER,
    ACTION_CREATE_ENTRY,
    ACTION_CREATE_REVISION,
    ACTION_WITHDRAW_ENTRY,
    ACTION_WITHDRAW_MULTIPLE_ENTRIES,
  ],
  "Wrap-up": [
    ACTION_CREATE_WRAPUP_MSG,
    ACTION_DOWNLOAD_WRAPUP_FILE,
    ACTION_APPROVE_PROOFS,
    ACTION_APPROVE_FINAL_FILES,
    ACTION_APPROVE_NAME,
    ACTION_SUBMIT_USER_SURVEY,
    ACTION_SIGN_ICA,
    ACTION_UPLOAD_WRAPUP_FILES,
  ],
  "Focus group": [
    ACTION_CREATE_FOCUS_GROUP,
    ACTION_CREATE_FG_ENTRY,
    ACTION_DELETE_FG_ENTRY,
    ACTION_CREATE_FG_PARTICIPANT,
    ACTION_DELETE_FG_PARTICIPANT,
    ACTION_CLOSE_FOCUS_GROUP,
    ACTION_OPEN_FOCUS_GROUP,
    ACTION_VOTE_ON_FG_ENTRY,
    ACTION_SET_FG_PARTICIPANT_INFO,
  ],
  Presentation: [
    ACTION_CREATE_PRESENTATION,
    ACTION_CREATE_PRESENTATION_ENTRY,
    ACTION_DELETE_PRESENTATION_ENTRY,
    ACTION_CREATE_PRESENTATION_PARTICIPANT,
    ACTION_DELETE_PRESENTATION_PARTICIPANT,
    ACTION_CLOSE_PRESENTATION,
    ACTION_VOTE_ON_PRESENTATION_ENTRY,
    ACTION_CREATE_PRESENTATION_ENTRY_COMMENT,
    ACTION_SET_PRESENTATION_PARTICIPANT_INFO,
  ],
  "Finalist Round": [
    ACTION_ADD_FINALIST,
    ACTION_REMOVE_FINALIST,
    ACTION_LAUNCH_FINAL_ROUND,
    ACTION_EXTEND_FINAL_ROUND,
  ],
};

export const ACTION_TYPE_OPTIONS = Object.keys(ACTION_GROUPS).reduce(
  (acc, groupName) => {
    return [
      ...acc,
      ...ACTION_GROUPS[groupName].map((action) => ({
        field: action,
        label: capitalize(action.replace(/_/g, " ")),
        group: groupName,
      })),
    ];
  },
  []
);

export const ACTION_ROLE_OPTIONS = [
  { field: "client", label: "Client" },
  { field: "creative", label: "Creative" },
  { field: "admin", label: "Admin" },
  { field: "collaborator", label: "Collaborator" },
  { field: "focus_group_participant", label: "Focus group participant" },
  { field: "presentation_participant", label: "Presentation participant" },
];

export const UNVERIFIED_RESOURCE_NAME = "admin_unverified";

export const DEACTIVATION_REASON_BANNED = "admin_ban";
export const DEACTIVATION_REASON_SUSPENDED = "admin_suspension";

export const CLIENTS_RESOURCE_NAME = "admin_clients";

export const CREATIVES_RESOURCE_NAME = "admin_creatives";

export const WAITERS_RESOURCE_NAME = "admin_waiters";

export const USER_PROJECTS_RESOURCE_NAME = "admin_user_projects";

export const USER_PAYMENTS_RESOURCE_NAME = "admin_user_payments";
export const USER_PAYMENTS_PAGE_SIZE = 5; // with the default page size, requests take too long

export const USER_ACTIONS_RESOURCE_NAME = "admin_user_actions";

export const USER_ENTRIES_RESOURCE_NAME = "admin_user_entries";

export const CREATIVE_PROJECTS_PAGE_SIZE = 10; // with the default page size, requests take too long

export const WAITER_ACCOUNT_OPTIONS = [
  { field: "true", label: "Duplicate" },
  { field: "false", label: "Unique" },
];

export const WAITER_INVITES_RESOURCE_NAME = "admin_waiter_invites";

export const PROJECT_TITLE_PLACEHOLDER = "[No title]";

export const TESTIMONIALS_RESOURCE_NAME = "testimonials";

export const RECENT_ENTRIES_RESOURCE_NAME = "recent_entries";

export const PAYOUT_REQUESTS_RESOURCE_NAME = "payout_requests";

export const GLOBAL_BLOCKED_EMAILS_RESOURCE_NAME = "global_blocked_emails";

export const UNSUBSCRIBED_EMAILS_RESOURCE_NAME = "unsubscribed_emails";

export const REQUEST_TARGETING_RULES = sortBy(
  [
    {
      field: "authentication_status",
      label: "Authentication status",
      valueOptions: [
        { field: "anonymous", label: "Anonymous" },
        { field: "authenticated", label: "Authenticated" },
      ],
    },
    {
      field: "country_tier",
      label: "Country tier",
      valueOptions: [
        { field: "a_tier", label: "A-tier" },
        { field: "b_tier", label: "B-tier" },
      ],
    },
    {
      field: "user_type",
      label: "User type",
      valueOptions: [
        { field: "client", label: "Client" },
        { field: "creative", label: "Creative" },
        { field: "new_client", label: "New client" },
        { field: "new_creative", label: "New creative" },
      ],
    },
  ],
  (item) => item.label.toLowerCase()
);

export const EMAIL_MX_VALIDATIONS_RESOURCE_NAME = "email_mx_validations";
