import HeaderTitle from "header/components/title";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";

const mapStateToProps = (state, { location: { pathname } }) => {
  let title = "Well, that's embarrassing...";
  if (pathname.indexOf("forbidden-private-project") !== -1) {
    title = "This is a private project...";
  } else if (pathname.indexOf("project-down-for-nap") !== -1) {
    title = "Believe it or not...";
  } else if (
    pathname.includes("clients-only-page") ||
    pathname.includes("creatives-only-page")
  ) {
    title = "You've crossed the streams...";
  }

  return { title };
};

export default withRouter(connect(mapStateToProps)(HeaderTitle));
