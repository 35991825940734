import Checkbox from "notifications/components/checkbox";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toggleSelected } from "notifications/ducks";
import partial from "lodash/partial";

export function mapStateToProps({ notifications }, { notificationId }) {
  const selectedNotifications = notifications.selectedNotifications || [];
  const selectedIndex = selectedNotifications.indexOf(notificationId);
  return {
    selected: selectedIndex !== -1,
  };
}

export function mapDispatchToProps(dispatch, { notificationId }) {
  return bindActionCreators(
    {
      onChange: partial(toggleSelected, notificationId),
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkbox);
