import upperFirst from "lodash/upperFirst";
import { ADMIN_ROOT } from "common/variables";

export const EMAILS_RESOURCE_NAME = "emails";

export const SINGLE_EMAIL_FORM_NAME = "singleEmailForm";

export const AUTOMATIONS_RESOURCE_NAME = "automations";

export const GROUPS_RESOURCE_NAME = "groups";

export const LEADS_RESOURCE_NAME = "leads";

export const GROUP_FORM_NAME = "groupForm";

export const AUTOMATION_FORM_NAME = "automationForm";

export const AUTOMATION_METRICS_FIELDS = [
  {
    name: "count_leads_total",
    label: "Leads",
    alwaysVisible: true,
  },
  {
    name: "count_exited_leads_total",
    label: "Left",
    alwaysVisible: true,
  },
  {
    name: "count_sent_total",
    label: "Sent",
    alwaysVisible: true,
  },
  {
    name: "count_delivered_total",
    label: "Delivered",
    showPercentages: true,
    alwaysVisible: true,
  },
  {
    name: "count_unique_confirmed_opened_total",
    label: "Unique opens",
    showPercentages: true,
    alwaysVisible: true,
  },
  {
    name: "count_unique_clicked_total",
    label: "Unique clicks",
    showPercentages: true,
    alwaysVisible: true,
  },
  {
    name: "count_goal_1_total",
    label: "Primary goal",
    showPercentages: true,
    alwaysVisible: true,
  },
  {
    name: "count_goal_2_total",
    label: "Goal 2",
    showPercentages: true,
    alwaysVisible: true,
  },
  {
    name: "count_goal_3_total",
    label: "Goal 3",
    showPercentages: true,
    alwaysVisible: true,
  },
  {
    name: "count_goal_4_total",
    label: "Goal 4",
    showPercentages: true,
    alwaysVisible: true,
  },
  {
    name: "count_internal_unsubscribe_total",
    label: "Unsubscribes (link)",
    showPercentages: true,
    alwaysVisible: true,
  },
  {
    name: "count_unsubscribe_total",
    label: "Unsubscribes (list)",
    showPercentages: true,
    alwaysVisible: true,
  },
  {
    name: "count_bounce_total",
    label: "Bounces",
    showPercentages: true,
    alwaysVisible: true,
  },
  {
    name: "count_spam_complaint_total",
    label: "Spam",
    showPercentages: true,
    alwaysVisible: true,
  },
];

export const EMAIL_VARIATION_METRICS_FIELDS = AUTOMATION_METRICS_FIELDS.filter(
  (m) => !["count_leads_total", "count_exited_leads_total"].includes(m.name)
);

export const BB_PERIODIC_TASKS = [
  { name: "Update Bumblebee counts", description: "We last updated all data" },
  {
    name: "Send Bumblebee messages",
    description: "We sent the most recent emails",
  },
];

export const HB_PERIODIC_TASKS = [
  { name: "Update Honeybee counts", description: "We last updated all data" },
  {
    name: "Send Honeybee messages",
    description: "We sent the most recent emails",
  },
];

export const GOAL_OPTIONS = [
  { field: "register_as_client", label: "Lead registers as a client" },
  { field: "create_draft", label: "Lead creates a draft project" },
  { field: "post_project", label: "Lead posts a project" },
  { field: "launch_focus_group", label: "Lead launches a focus group" },
];

export const SINGLE_EMAIL_METRICS_FIELDS = [
  {
    name: "count_recipients_total",
    label: "Recipients",
    alwaysVisible: true,
  },
  {
    name: "count_sent_total",
    label: "Sent",
    showPercentages: true,
    alwaysVisible: true,
  },
  {
    name: "count_delivered_total",
    label: "Delivered",
    showPercentages: true,
    alwaysVisible: true,
  },
  {
    name: "count_unique_confirmed_opened_total",
    label: "Unique opens",
    showPercentages: true,
    alwaysVisible: true,
  },
  {
    name: "count_unique_clicked_total",
    label: "Unique clicks",
    showPercentages: true,
    alwaysVisible: true,
  },
  {
    name: "count_goal_1_total",
    label: "Primary goal",
    showPercentages: true,
    alwaysVisible: true,
  },
  {
    name: "count_goal_2_total",
    label: "Goal 2",
    showPercentages: true,
    alwaysVisible: true,
  },
  {
    name: "count_goal_3_total",
    label: "Goal 3",
    showPercentages: true,
    alwaysVisible: true,
  },
  {
    name: "count_goal_4_total",
    label: "Goal 4",
    showPercentages: true,
    alwaysVisible: true,
  },
  {
    name: "count_internal_unsubscribe_total",
    label: "Unsubscribes (link)",
    showPercentages: true,
    alwaysVisible: true,
  },
  {
    name: "count_unsubscribe_total",
    label: "Unsubscribes (list)",
    showPercentages: true,
    alwaysVisible: true,
  },
  {
    name: "count_bounce_total",
    label: "Bounces",
    showPercentages: true,
    alwaysVisible: true,
  },
  {
    name: "count_spam_complaint_total",
    label: "Spam",
    showPercentages: true,
    alwaysVisible: true,
  },
];

export const CONTACT_LISTS_RESOURCE_NAME = "contact_lists";

export const CONTACTS_RESOURCE_NAME = "contacts";

export const MAX_RECOMMENDED_SUBJECT_LENGTH = 78;

export const MAX_PREHEADER_LENGTH = 100;

const UNSUBSCRIBE_LIST_LABELS = { blog: "Blog RSS" };
export const UNSUBSCRIBE_LIST_OPTIONS = [
  "drafts",
  "marketing",
  "business_name_zone",
  "from_ross",
  "webinars",
  "blog",
  "link_building",
].map((field) => ({
  label:
    UNSUBSCRIBE_LIST_LABELS[field] ||
    upperFirst(field).replace(new RegExp("_", "g"), " "),
  field,
}));

export const PRJ_STATUS_CHANGED_EVENT_INTERVALS = [
  { field: "1h", label: "1 hour" },
  { field: "4h", label: "4 hours" },
  { field: "12h", label: "12 hours" },
  { field: "24h", label: "24 hours" },
  { field: "36h", label: "36 hours" },
  { field: "48h", label: "48 hours" },
  { field: "60h", label: "60 hours" },
  { field: "72h", label: "72 hours" },
  { field: "120h", label: "120 hours" },
  { field: "168h", label: "168 hours" },
  { field: "240h", label: "240 hours" },
  { field: "720h", label: "720 hours" },
];

export const CONTACT_LISTS_URL = `${ADMIN_ROOT}/contact-lists/?contact_lists_filter_select_archived=false&contact_lists_filter_select_important=true`;

export const GROUPS_PER_AUTOMATION = 5;

export const BB_ANALYTICS_API_ENDPOINT = "/api/v1/bb/automations/analytics/";

export const CONTACT_ANALYTICS_API_ENDPOINT =
  "/api/v1/contact_lists/analytics/";
