import request from "axios";
import partial from "lodash/partial";
import { getProject } from "project/ducks/project";
import { setBannerError } from "error";
import { delayAction, fetchAll as fetchAllEntries } from "entries/ducks";

/* ACTIONS
 ================================================================================================ */
export const FETCH_INVITES = "crowdspring/creatives/FETCH_INVITES";
export const FETCH_INVITES_SUCCESS =
  "crowdspring/creatives/FETCH_INVITES_SUCCESS";

export const FETCH_REMOVED = "crowdspring/creatives/FETCH_REMOVED";
export const FETCH_REMOVED_SUCCESS =
  "crowdspring/creatives/FETCH_REMOVED_SUCCESS";

export const FETCH_AWARDED = "crowdspring/creatives/FETCH_AWARDED";
export const FETCH_AWARDED_SUCCESS =
  "crowdspring/creatives/FETCH_AWARDED_SUCCESS";

export const FETCH_PARTICIPANTS = "crowdspring/creatives/FETCH_PARTICIPANTS";
export const FETCH_PARTICIPANTS_SUCCESS =
  "crowdspring/creatives/FETCH_PARTICIPANTS_SUCCESS";

export const REINSTATE = "crowdspring/creatives/REINSTATE";

export const TOGGLE_REMOVE_MODAL = "crowdspring/creatives/TOGGLE_REMOVE_MODAL";

export const REMOVE = "crowdspring/creatives/REMOVE";

/* INITIAL STATE
 ================================================================================================ */
const initialState = {
  invites: [],
  removed: [],
  awarded: [],
  participants: [],
  showingRemoveModal: false,
};

/* HELPERS
 ================================================================================================ */
export const getAwarded = (state, excludeOffers) => {
  const all = state.project.creatives.awarded;
  if (excludeOffers) {
    return all.filter((a) => a.payment_type !== "offer");
  }
  return all;
};

/* REDUCERS
 ================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_INVITES_SUCCESS:
      return {
        ...state,
        invites: action.payload.results,
      };
    case FETCH_REMOVED_SUCCESS:
      return {
        ...state,
        removed: action.payload,
      };
    case FETCH_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        participants: action.payload,
      };
    case FETCH_AWARDED_SUCCESS:
      return {
        ...state,
        awarded: action.payload,
      };
    case TOGGLE_REMOVE_MODAL:
      return {
        ...state,
        showingRemoveModal: !state.showingRemoveModal,
        removingCreative: action.payload,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const fetchInvites = (projectId) => (dispatch) => {
  const opts = {
    method: "get",
    url: `/api/v1/projects/${projectId}/invites/`,
  };
  return dispatch({
    type: FETCH_INVITES,
    promise: request(opts),
  });
};

export const fetchRemoved = (projectId) => (dispatch) => {
  const opts = {
    method: "get",
    url: `/api/v1/projects/${projectId}/removed_creatives/`,
  };
  return dispatch({
    type: FETCH_REMOVED,
    promise: request(opts),
  });
};

export const fetchAwarded = (projectId) => (dispatch) => {
  const opts = {
    method: "get",
    url: `/api/v1/projects/${projectId}/awarded/`,
  };
  return dispatch({
    type: FETCH_AWARDED,
    promise: request(opts),
  });
};

export const fetchParticipants = (projectId) => (dispatch) => {
  const opts = {
    method: "get",
    url: `/api/v1/projects/${projectId}/participants/`,
  };
  return dispatch({
    type: FETCH_PARTICIPANTS,
    promise: request(opts),
  });
};

export const reinstate = (projectId, creativeId) => (dispatch) => {
  const opts = {
    method: "patch",
    url: `/api/v1/projects/${projectId}/reinstate_creative/`,
    data: { creative_id: creativeId },
  };

  const promise = request(opts);

  promise
    .then(() =>
      /* reload updated list of removed creatives */
      dispatch(fetchRemoved(projectId))
    )
    .catch(() =>
      dispatch(
        setBannerError("Failed to reinstate creative", "Please try again")
      )
    );

  dispatch({
    type: REINSTATE,
    promise,
  });

  return promise;
};

export const toggleRemoveModal = (creative) => (dispatch) =>
  dispatch({
    type: TOGGLE_REMOVE_MODAL,
    payload: creative,
  });

export const remove = (entriesQuery) => (dispatch, getState) => {
  const state = getState();
  const project = getProject(state);
  const creative = state.project.creatives.removingCreative;

  const opts = {
    method: "patch",
    url: `/api/v1/projects/${project.id}/remove_creative/`,
    data: { creative_id: creative.id },
  };

  const promise = request(opts);

  promise
    .then(() => {
      /* removing creative from entries page */
      if (entriesQuery) {
        dispatch(toggleRemoveModal());
        /* give ElasticSearch enough time to reflect the changes */
        dispatch(
          delayAction(partial(fetchAllEntries, project.id, entriesQuery))
        );

        /* removing creative from entry details page */
      } else {
        /* FIXME: better handle removal from entry details modal */
        /* refresh page after giving ElasticSearch 2s to reflect the changes */
        setTimeout(() => window.location.reload(), 2000);
      }
    })
    .catch(() =>
      dispatch(setBannerError("Error removing creative", "Please try again."))
    );

  return {
    type: REMOVE,
    promise,
  };
};
