import React from "react";
import PropTypes from "prop-types";
import Breadcrumbs from "common/components/breadcrumbs";

const MarketingBreadcrumbs = ({ items, ...restProps }) => (
  <Breadcrumbs {...restProps} items={[{ title: "Home", url: "/" }, ...items]} />
);

MarketingBreadcrumbs.propTypes = {
  items: PropTypes.array.isRequired,
};

export default MarketingBreadcrumbs;
