import request from "axios";
import idx from "idx";
import shuffle from "lodash/shuffle";
import flatten from "lodash/flatten";

/* ACTIONS
================================================================================================ */
const FETCH_CATEGORIES = "crowdspring/common/FETCH_CATEGORIES";
const FETCH_CATEGORIES_REQUEST = "crowdspring/common/FETCH_CATEGORIES_REQUEST";
const FETCH_CATEGORIES_SUCCESS = "crowdspring/common/FETCH_CATEGORIES_SUCCESS";
const FETCH_CATEGORIES_FAILURE = "crowdspring/common/FETCH_CATEGORIES_FAILURE";

const FETCH_BRIEF_QUESTIONS = "crowdspring/common/FETCH_BRIEF_QUESTIONS";
const FETCH_BRIEF_QUESTIONS_SUCCESS =
  "crowdspring/common/FETCH_BRIEF_QUESTIONS_SUCCESS";

const FETCH_TESTIMONIALS = "crowdspring/common/FETCH_TESTIMONIALS";
const FETCH_TESTIMONIALS_SUCCESS =
  "crowdspring/common/FETCH_TESTIMONIALS_SUCCESS";

/* INITIAL STATES
================================================================================================ */
const initialCategories = {
  isFetching: false,
  isError: false,
  data: [],
  briefQuestions: [],
};

/* HELPERS
================================================================================================ */
function shouldFetchCategories(state) {
  // No data and we're not already fetching
  if (!state.categories.data.length && !state.categories.isFetching) {
    return true;
  }

  return false;
}

/* Get flattened list of subcategories, optionally filtered by category ID */
export const getSubCategories = (categories, categoryId) =>
  flatten(
    categories.data.map((c) =>
      !categoryId || c.id == categoryId ? c.subcategories : []
    )
  );

/* Get sub-category by ID or slug */
export const getSubCategory = (categories, subcatIdOrSlug) =>
  getSubCategories(categories).find((s) =>
    [s.id, s.slug].includes(subcatIdOrSlug)
  );

/* Get category by ID or slug */
export const getCategory = (categories, catIdOrSlug) => {
  return categories
    ? categories.find((c) => [c.id, c.slug].includes(catIdOrSlug))
    : null;
};

/* Get sub-category slug by ID */
export const getSubcategorySlug = (categories, subcatId) => {
  const subcat = getSubCategory(
    categories.data ? categories : { data: categories },
    subcatId
  );
  return subcat ? subcat.slug : null;
};

export const getSlugs = (categories, subcatId) =>
  categories.data.reduce((mem, category) => {
    const { slug, subcategories } = category;
    const subcat_slug = subcategories.reduce(
      (subcatmem, subcat) => (subcat.id === subcatId ? subcat.slug : subcatmem),
      ""
    );
    return subcat_slug ? `${slug}/${subcat_slug}` : mem;
  }, "");

/* Filter only non-deprecated categories/subcategories */
export const excludeDeprecated = (categories) => {
  return categories
    .filter((c) => !c.deprecated)
    .map((c) => ({
      ...c,
      subcategories: c.subcategories.filter((s) => !s.deprecated),
    }));
};

/* Filter only categories/sub-categories that can be watched. */
export const getWatchableCategories = (categories) => {
  return excludeDeprecated(categories).filter((c) => !c.is_one_to_one);
};

export const isSubcat1to1 = ({ categories, slug }) => {
  const subCategory = getSubCategory(categories, slug);
  const category = getCategory(
    categories.data,
    idx(subCategory, (_) => _.category)
  );
  return idx(category, (_) => _.is_one_to_one) || false;
};

/* REDUCERS
================================================================================================ */
const categoriesReducer = (state = initialCategories, action) => {
  switch (action.type) {
    case FETCH_CATEGORIES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
      };
    case FETCH_CATEGORIES_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
      };
    case FETCH_BRIEF_QUESTIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        briefQuestions: action.payload,
      };
    case FETCH_TESTIMONIALS_SUCCESS:
      /* randomly pick a testimonial from the list */
      const selected = shuffle(action.payload)[0];

      return {
        ...state,
        testimonial: selected
          ? {
              body: selected.quote,
              author: `${selected.client_name}${
                selected.client_company ? ", " + selected.client_company : ""
              }`,
            }
          : null,
      };
    default:
      return state;
  }
};

export default categoriesReducer;

/* ACTION CREATORS
================================================================================================ */
export function fetchCategories() {
  return {
    type: FETCH_CATEGORIES,
    promise: request.get("/api/v1/project-categories/"),
  };
}

export const fetchCategoriesIfNeeded = () => (dispatch, getState) => {
  if (shouldFetchCategories(getState())) {
    const action = fetchCategories();
    dispatch(action);
    return action.promise;
  }
  const state = getState();
  return Promise.resolve(state.categories);
};

export const fetchBriefQuestions = (subcatId) => (dispatch) => {
  const promise = request.get(
    `/api/v1/project-subcategories/${subcatId}/brief_questions/`
  );
  dispatch({
    type: FETCH_BRIEF_QUESTIONS,
    promise,
  });
  return promise;
};

export const fetchTestimonials = (subcatId) => (dispatch) => {
  dispatch({
    type: FETCH_TESTIMONIALS,
    promise: request.get(`/api/v1/quotes/${subcatId}/`),
  });
};

export const updateKeywords = (data) => (dispatch) => {
  const promise = request.post("/api/v1/project-subcategories/keywords/", data);
  promise.then(() => dispatch(fetchCategories()));
  return promise;
};
