/* banner types */
export const TYPE_ERROR = "error";
export const TYPE_INFO = "info";
export const TYPE_WARNING = "warning";
export const TYPE_SUCCESS = "success";

/* ACTIONS
================================================================================================ */
export const SHOW = "crowdspring/banner/SHOW";
export const HIDE = "crowdspring/banner/HIDE";

/* REDUCERS
 ================================================================================================ */
const initialState = {
  showing: false,
  title: "",
  content: "",
  type: "",
  onHide: null,
  icon: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SHOW:
      const { title, content, type, icon, onHide } = action.payload;
      return {
        showing: true,
        title,
        content,
        type,
        icon,
        onHide,
      };
    case HIDE:
      return { ...initialState };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const showBanner =
  (title, content, icon, type = TYPE_INFO, onHide = null, dismissAfter) =>
  (dispatch, getState) => {
    if (dismissAfter) {
      setTimeout(() => dispatch(hideBanner()), dismissAfter);
    }

    return dispatch({
      type: SHOW,
      payload: { title, content, type, icon, onHide },
    });
  };

export const hideBanner = () => (dispatch, getState) => {
  const { onHide } = getState().banner;
  if (onHide) {
    onHide();
  }
  return dispatch({
    type: HIDE,
  });
};
