import { createAction } from "redux-actions";
import { v4 } from "uuid";

/* ACTIONS
 ================================================================================================ */
export const TOGGLE_MODAL = "crowdspring/modal/TOGGLE";
export const REGISTER_MODAL = "crowdspring/modal/REGISTER";
export const UNREGISTER_MODAL = "crowdspring/modal/UNREGISTER";

export const toggleModal = createAction(TOGGLE_MODAL);
export const registerModal = createAction(REGISTER_MODAL);
export const unregisterModal = createAction(UNREGISTER_MODAL);

/* INITIAL STATE
 ================================================================================================ */
const initialState = {
  id: null,
  show: false,
};

/* REDUCERS
 ================================================================================================ */
export function modalReducer(state = initialState, action) {
  if (state.id !== action.payload) {
    return state;
  }

  switch (action.type) {
    case TOGGLE_MODAL:
      return {
        ...state,
        show: !state.show,
      };
    default:
      return state;
  }
}

export default function reducer(state = [], action) {
  switch (action.type) {
    case TOGGLE_MODAL:
      return state.map((modal) => modalReducer(modal, action));
    case REGISTER_MODAL:
      const newModal = { ...initialState, id: action.payload || v4() };
      return [...state, newModal];
    case UNREGISTER_MODAL:
      return state.filter((modal) => modal.id !== action.payload);
    default:
      return state;
  }
}
