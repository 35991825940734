import React from "react";
import PropTypes from "prop-types";
import { Meta } from "common/components/meta";
import Attribute from "marketing/common/attribute";

const getErrorCode = (path) => {
  const matches = path.match(/\d+/);

  if (matches) {
    return parseInt(matches[0], 10);
  }
  return null;
};

const errorStates = {
  403: {
    title: "Not authorized",
    text: "You don't have permissions to access this page.",
  },
  404: {
    title: "The page monster ate your page.",
    text: "We couldn't find the page you were looking for.",
  },
  500: {
    title: "You found our&nbsp;gremlins.",
    text: "There was a problem with the site. Our team has been notified and we will investigate the problem. Sorry for the trouble.",
  },
};

const ErrorPage = ({ error, router, showRefreshBtn }) => {
  const errorCode = getErrorCode(router.location.pathname);
  const errorInstance =
    (errorCode && errorStates[errorCode]) || errorStates[500];

  return (
    <div className="error-page l-content">
      <Meta
        title="Error"
        meta={[{ name: "robots", content: "noindex,nofollow" }]}
      />
      <div id="content" className="row">
        <div className="small-16 medium-10 large-9 column error-content align-middle">
          <h1 dangerouslySetInnerHTML={{ __html: errorInstance.title }} />
          <p dangerouslySetInnerHTML={{ __html: errorInstance.text }} />
          {error && error.incidentId && (
            <p className="incident-id">Incident Id: {error.incidentId}</p>
          )}

          <div className="error-buttons">
            {showRefreshBtn && typeof window !== "undefined" && (
              <a
                className="btn btn-tertiary btn-block"
                href={window.location.href}
              >
                Refresh the page
              </a>
            )}

            <a
              className={
                "btn btn-block btn-" + (showRefreshBtn ? "text" : "tertiary")
              }
              href="/"
            >
              Go to the homepage
            </a>
          </div>
        </div>
        <div className="small-16 medium-6 large-7 columns">
          <div className="error-image">
            <img
              src="https://assets.crowdspring.com/marketing/crowdspring_error_app-blaster_haim.jpg"
              alt="App Blaster by Haim"
            />
            {router.push && <Attribute to="haim" />}
          </div>
        </div>
      </div>
    </div>
  );
};

ErrorPage.propTypes = {
  error: PropTypes.shape({
    incidentId: PropTypes.string,
  }),
  router: PropTypes.object,
  showRefreshBtn: PropTypes.bool,
};

export default ErrorPage;
