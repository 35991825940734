import React from "react";
import request from "axios";
import idx from "idx";
import moment from "moment";
import cookie from "react-cookie";
import { browserHistory, Link } from "react-router";
import { setBannerError } from "error";
import {
  TEMPLATES_RESOURCE_NAME,
  DESIGNS_RESOURCE_NAME,
  BRANDS_RESOURCE_NAME,
  DISMISSED_REFER_BANNER_COOKIE,
} from "brand_studio/constants";
import { getAPIQueryString } from "common/utils";
import { savePageCount } from "common/pager/ducks";
import { showBanner, hideBanner, TYPE_INFO } from "common/ducks/banner";
import { MIN_BS_REFERRALS_STR } from "marketing/constants";

/* ACTIONS
================================================================================================ */
const FETCH_BRAND = "brand_studio/FETCH_BRAND";
const FETCH_BRAND_REQUEST = "brand_studio/FETCH_BRAND_REQUEST";
const FETCH_BRAND_SUCCESS = "brand_studio/FETCH_BRAND_SUCCESS";

const FETCH_FONTS = "brand_studio/FETCH_FONTS";
const FETCH_FONTS_REQUEST = "brand_studio/FETCH_FONTS_REQUEST";
const FETCH_FONTS_SUCCESS = "brand_studio/FETCH_FONTS_SUCCESS";

const FETCH_DESIGN = "brand_studio/FETCH_DESIGN";
const FETCH_DESIGN_REQUEST = "brand_studio/FETCH_DESIGN_REQUEST";
const FETCH_DESIGN_SUCCESS = "brand_studio/FETCH_DESIGN_SUCCESS";

const SAVE_DESIGN = "brand_studio/SAVE_DESIGN";
const SAVE_DESIGN_REQUEST = "brand_studio/SAVE_DESIGN_REQUEST";
const SAVE_DESIGN_SUCCESS = "brand_studio/SAVE_DESIGN_SUCCESS";
const SAVE_DESIGN_FAILURE = "brand_studio/SAVE_DESIGN_FAILURE";

const FETCH_TEMPLATES = "brand_studio/FETCH_TEMPLATES";
const FETCH_TEMPLATES_REQUEST = "brand_studio/FETCH_TEMPLATES_REQUEST";
const FETCH_TEMPLATES_SUCCESS = "brand_studio/FETCH_TEMPLATES_SUCCESS";

const CLEAR_TEMPLATES = "brand_studio/CLEAR_TEMPLATES";

const FETCH_DESIGNS = "brand_studio/FETCH_DESIGNS";
const FETCH_DESIGNS_REQUEST = "brand_studio/FETCH_DESIGNS_REQUEST";
const FETCH_DESIGNS_SUCCESS = "brand_studio/FETCH_DESIGNS_SUCCESS";

const CLEAR_DESIGNS = "brand_studio/CLEAR_DESIGNS";

const UPDATE_DESIGN_PREVIEW_LOCAL = "brand_studio/UPDATE_DESIGN_PREVIEW_LOCAL";

const UPDATE_TEMPLATE_PREVIEW_LOCAL =
  "brand_studio/UPDATE_TEMPLATE_PREVIEW_LOCAL";

const TOGGLE_ACTION_CONFIRMATION = "brand_studio/TOGGLE_ACTION_CONFIRMATION";

const DELETE_DESIGN = "brand_studio/DELETE_DESIGN";
const DELETE_DESIGN_SUCCESS = "brand_studio/DELETE_DESIGN_SUCCESS";

const FETCH_BRANDS = "brand_studio/FETCH_BRANDS";
const FETCH_BRANDS_REQUEST = "brand_studio/FETCH_BRANDS_REQUEST";
const FETCH_BRANDS_SUCCESS = "brand_studio/FETCH_BRANDS_SUCCESS";

const CLEAR_BRANDS = "brand_studio/CLEAR_BRANDS";

const DELETE_BRAND = "brand_studio/DELETE_BRAND";
const DELETE_BRAND_SUCCESS = "brand_studio/DELETE_BRAND_SUCCESS";

const TOGGLE_GENERATING_TEMPLATES = "brand_studio/TOGGLE_GENERATING_TEMPLATES";

const CLEAR_BRAND = "brand_studio/CLEAR_BRAND";

/* HELPERS
================================================================================================ */

/* INITIAL STATES
================================================================================================ */
const initialState = {
  brand: null,
  design: null,
  savingDesign: false,
  fonts: null,
  designs: null,
  templates: null,
  actionConfirmationInfo: null,
  brands: null,
  showingGeneratingTemplates: false,
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_BRAND_REQUEST:
      return {
        ...state,
        brand: null,
      };
    case FETCH_BRAND_SUCCESS:
      return {
        ...state,
        brand: action.payload,
      };
    case FETCH_FONTS_REQUEST:
      return {
        ...state,
        fonts: null,
      };
    case FETCH_FONTS_SUCCESS:
      return {
        ...state,
        fonts: action.payload,
      };
    case FETCH_DESIGN_REQUEST:
      return {
        ...state,
        design: null,
      };
    case FETCH_DESIGN_SUCCESS:
      return {
        ...state,
        design: action.payload,
      };
    case SAVE_DESIGN_REQUEST:
      return {
        ...state,
        savingDesign: true,
      };
    case SAVE_DESIGN_FAILURE:
      return {
        ...state,
        savingDesign: false,
      };
    case SAVE_DESIGN_SUCCESS:
      return {
        ...state,
        design: action.payload,
        savingDesign: false,
      };
    case CLEAR_TEMPLATES:
    case FETCH_TEMPLATES_REQUEST:
      return {
        ...state,
        templates: null,
      };
    case FETCH_TEMPLATES_SUCCESS:
      return {
        ...state,
        templates: action.payload.results,
      };
    case CLEAR_DESIGNS:
    case FETCH_DESIGNS_REQUEST:
      return {
        ...state,
        designs: null,
      };
    case FETCH_DESIGNS_SUCCESS:
      return {
        ...state,
        designs: action.payload.results,
      };
    case UPDATE_DESIGN_PREVIEW_LOCAL:
      return {
        ...state,
        designs: state.designs
          ? state.designs.map((d) =>
              d.id === action.design.id ? action.design : d
            )
          : state.designs,
      };
    case UPDATE_TEMPLATE_PREVIEW_LOCAL:
      return {
        ...state,
        templates: state.templates
          ? state.templates.map((t) =>
              t.id === action.template.id ? action.template : t
            )
          : state.templates,
      };
    case TOGGLE_ACTION_CONFIRMATION:
      return {
        ...state,
        actionConfirmationInfo: action.info,
      };
    case DELETE_DESIGN_SUCCESS:
      return {
        ...state,
        designs: state.designs
          ? state.designs.filter((d) => d.id !== action.id)
          : state.designs,
      };
    case CLEAR_BRANDS:
    case FETCH_BRANDS_REQUEST:
      return {
        ...state,
        brands: null,
      };
    case FETCH_BRANDS_SUCCESS:
      return {
        ...state,
        brands: action.payload.results,
      };
    case DELETE_BRAND_SUCCESS:
      return {
        ...state,
        brands: state.brands
          ? state.brands.filter((b) => b.id !== action.id)
          : state.brands,
      };
    case TOGGLE_GENERATING_TEMPLATES:
      return {
        ...state,
        showingGeneratingTemplates: !state.showingGeneratingTemplates,
      };
    case CLEAR_BRAND:
      return {
        ...state,
        brand: null,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const createBrand = (website) => (dispatch) => {
  const promise = request.post(
    "/api/v1/brand_studio/brands/" + (website ? `?website=${website}` : "")
  );

  promise
    .then((res) => {
      /* if website was provided wait for assets extraction before going to wizard */
      if (!website) {
        browserHistory.push(`/brand-studio/brand/${res.data.id}/wizard/`);
      }
    })
    .catch((err) => {
      let errorMsg;
      if (idx(err, (_) => _.data.website)) {
        errorMsg = err.data.website;
      } else if (err.data) {
        errorMsg = JSON.stringify(err.data);
      } else {
        errorMsg = "Make sure the data you entered is correct.";
      }

      dispatch(setBannerError("Error creating brand", errorMsg));
    });

  return promise;
};

export const fetchBrand = (id) => (dispatch) => {
  const promise = request.get(`/api/v1/brand_studio/brands/${id}/`);

  promise.catch(() => browserHistory.replace("/404/"));

  return dispatch({
    type: FETCH_BRAND,
    promise,
  });
};

export const saveBrand =
  ({ brandId, data, regenerate, updateLocal, showGeneratingTemplates }) =>
  (dispatch) => {
    let promise = request.patch(
      `/api/v1/brand_studio/brands/${brandId}/`,
      data
    );

    /* fetch updated fonts list in case it was modified while updating the brand */
    promise.then(() => dispatch(fetchFonts()));

    if (updateLocal) {
      promise.then((res) => {
        dispatch({
          type: FETCH_BRAND_SUCCESS,
          payload: res.data,
        });
      });
    }

    if (regenerate) {
      promise = promise.then(() =>
        request.post(
          `/api/v1/brand_studio/brands/${brandId}/generate_templates/`
        )
      );
    }

    promise
      .then(() => {
        if (regenerate) {
          /* clear brand when getting to brand pages in order to prevent double mounting */
          if (!updateLocal) {
            dispatch(clearBrand());
          }

          if (showGeneratingTemplates) {
            dispatch(toggleGeneratingTemplates());
          }

          browserHistory.push(`/brand-studio/brand/${brandId}/templates/`);
        }
      })
      .catch((err) => {
        const errorTitle = regenerate
          ? "Error generating templates"
          : "Error saving brand";
        const errorMsg = err.data
          ? JSON.stringify(err.data)
          : "Make sure the data you entered is correct.";
        dispatch(setBannerError(errorTitle, errorMsg));
      });

    return promise;
  };

export const fetchFonts = () => (dispatch) => {
  const promise = request.get("/api/v1/brand_studio/fonts/");

  dispatch({
    type: FETCH_FONTS,
    promise,
  });

  return promise;
};

export const fetchDesign = (id) => (dispatch) => {
  const promise = request.get(`/api/v1/brand_studio/designs/${id}/`);

  dispatch({
    type: FETCH_DESIGN,
    promise,
  });

  return promise;
};

export const saveDesign = (id, data) => (dispatch) => {
  const promise = request.patch(`/api/v1/brand_studio/designs/${id}/`, data);

  promise.catch((err) => {
    const errorMsg = err.data
      ? JSON.stringify(err.data)
      : "Make sure the data you entered is correct.";
    dispatch(setBannerError("Error saving design", errorMsg));
  });

  return dispatch({
    type: SAVE_DESIGN,
    promise,
  });
};

export const uploadImage = (designId, file) => (dispatch) => {
  return new Promise((accept, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      request
        .post(`/api/v1/brand_studio/designs/${designId}/upload_image/`, {
          content: reader.result.split(",").pop(),
        })
        .then((res) => {
          accept(res.data);
        })
        .catch((err) => {
          const errorMsg = err.data
            ? JSON.stringify(err.data)
            : "Make sure the image you provided is valid.";
          dispatch(setBannerError("Error uploading image", errorMsg));

          reject();
        });
    };
    reader.readAsDataURL(file);
  });
};

export const fetchTemplates = (brandId, query) => (dispatch) => {
  const extraAPIQuery = { brand: brandId };
  const queryStr = getAPIQueryString(TEMPLATES_RESOURCE_NAME, query, {
    extraAPIQuery,
  });
  const promise = request.get(`/api/v1/brand_studio/templates/?${queryStr}`);

  /* save page count in pager state */
  promise.then((res) => dispatch(savePageCount(TEMPLATES_RESOURCE_NAME, res)));

  return dispatch({
    type: FETCH_TEMPLATES,
    promise,
  });
};

export const fetchDesigns = (brandId, query) => (dispatch, getState) => {
  const user = getState().user.profile_data;
  const extraAPIQuery = { brand: brandId };
  const queryStr = getAPIQueryString(DESIGNS_RESOURCE_NAME, query, {
    extraAPIQuery,
  });
  const promise = request.get(`/api/v1/brand_studio/designs/?${queryStr}`);

  promise.then((res) => {
    /* save page count in pager state */
    dispatch(savePageCount(DESIGNS_RESOURCE_NAME, res));

    if (
      res.data.count >= 1 &&
      !user.bs_bonus_templates_unlocked &&
      !cookie.load(DISMISSED_REFER_BANNER_COOKIE)
    ) {
      dispatch(
        showBanner(
          "Want more branded templates for free?",
          <span>
            Tell at least {MIN_BS_REFERRALS_STR} friends about Brand Studio and
            unlock a social media power pack!{" "}
            <Link
              to="/brand-studio/refer-a-friend/"
              onClick={() => dispatch(hideBanner())}
            >
              Here's how
            </Link>
            .
          </span>,
          <i className="fa fa-gift" />,
          TYPE_INFO,
          () => {
            cookie.save(DISMISSED_REFER_BANNER_COOKIE, true, {
              path: "/",
              expires: moment("2100-12-12").toDate(),
            });
          }
        )
      );
    }
  });

  return dispatch({
    type: FETCH_DESIGNS,
    promise,
  });
};

export const updateDesignPreviewLocal = (design) => (dispatch) => {
  return dispatch({
    type: UPDATE_DESIGN_PREVIEW_LOCAL,
    design,
  });
};

export const updateTemplatePreviewLocal = (template) => (dispatch) => {
  return dispatch({
    type: UPDATE_TEMPLATE_PREVIEW_LOCAL,
    template,
  });
};

export const clearTemplates = () => (dispatch) => {
  return dispatch({
    type: CLEAR_TEMPLATES,
  });
};

export const clearDesigns = () => (dispatch) => {
  return dispatch({
    type: CLEAR_DESIGNS,
  });
};

export const toggleActionConfirmation = (info) => (dispatch) => {
  return dispatch({
    type: TOGGLE_ACTION_CONFIRMATION,
    info,
  });
};

export const deleteDesign = (id) => (dispatch) => {
  const promise = request.delete(`/api/v1/brand_studio/designs/${id}/`);

  promise.catch(() =>
    dispatch(setBannerError("Error deleting design", "Please try again."))
  );

  dispatch({
    type: DELETE_DESIGN,
    id,
    promise,
  });

  return promise;
};

export const fetchBrands = (query) => (dispatch) => {
  const queryStr = getAPIQueryString(BRANDS_RESOURCE_NAME, query);
  const promise = request.get(`/api/v1/brand_studio/brands/?${queryStr}`);

  /* save page count in pager state */
  promise.then((res) => dispatch(savePageCount(BRANDS_RESOURCE_NAME, res)));

  dispatch({
    type: FETCH_BRANDS,
    promise,
  });

  return promise;
};

export const clearBrands = () => (dispatch) => {
  return dispatch({
    type: CLEAR_BRANDS,
  });
};

export const deleteBrand = (id) => (dispatch) => {
  const promise = request.delete(`/api/v1/brand_studio/brands/${id}/`);

  promise.catch(() =>
    dispatch(setBannerError("Error deleting brand", "Please try again."))
  );

  dispatch({
    type: DELETE_BRAND,
    id,
    promise,
  });

  return promise;
};

export const toggleGeneratingTemplates = () => (dispatch) => {
  return dispatch({
    type: TOGGLE_GENERATING_TEMPLATES,
  });
};

export const clearBrand = () => (dispatch) => {
  return dispatch({
    type: CLEAR_BRAND,
  });
};
