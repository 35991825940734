/**
import PropTypes from 'prop-types';
 * Redux reducer bundle for project page
 * @author Kuba Siemiątkowski <kuba@crowdspring.com>
 */

import { combineReducers } from "redux";
import request from "axios";
import idx from "idx";
import partial from "lodash/partial";
import {
  FORBIDDEN_1_1_CODE,
  FORBIDDEN_DELETED_CODE,
  FORBIDDEN_CANCELLED_CODE,
  FORBIDDEN_HIDDEN_CODE,
  FORBIDDEN_1_1_NOT_SELECTED_CODE,
} from "common/variables";
import entriesReducer, { singleEntryReducer } from "project/ducks/entries";
import { browserHistory } from "react-router";
import * as commonImports from "project/ducks/common";
import { RESERVE_SUCCESS } from "project/ducks/elite";
import { getServerResponse } from "common/server/ducks";
import {
  ADD_FINALIST_SUCCESS,
  REMOVE_FINALIST_SUCCESS,
  START_FINAL_ROUND_SUCCESS,
  EXTEND_FINAL_ROUND_SUCCESS,
} from "entries/ducks";

/* ACTION CONSTANTS
================================================================================================ */
export const UPDATE_AUTO_NDA = "crowdspring/project/UPDATE_AUTO_NDA";
export const UPDATE_AUTO_NDA_REQUEST =
  "crowdspring/project/UPDATE_AUTO_NDA_REQUEST";
export const UPDATE_AUTO_NDA_FAILURE =
  "crowdspring/project/UPDATE_AUTO_NDA_FAILURE";
export const UPDATE_AUTO_NDA_SUCCESS =
  "crowdspring/project/UPDATE_AUTO_NDA_SUCCESS";

export const FETCH_INVOICE = "crowdspring/project/FETCH_INVOICE";
export const FETCH_INVOICE_SUCCESS =
  "crowdspring/project/FETCH_INVOICE_SUCCESS";

/* INITIAL STATES
================================================================================================ */
export const initialState = {
  isFetching: false,
  isError: null,
  data: {},
  newProjects: [],
};

/* HELPERS
================================================================================================ */
export function getProject(state) {
  return state.project.projectPage.project.data;
}
export function getStats(state) {
  return state.project.projectPage.project.stats;
}
export function getNewProjects(state) {
  return state.project.projectPage.project.newProjects;
}
export function getCategory(project, categories) {
  return categories.find((c) => c.id * 1 === project.category * 1);
}
export function getSubCategory(project, categories) {
  const cat = getCategory(project, categories);
  if (!cat) return {};
  return (
    cat.subcategories.find(
      (s) => s.id * 1 === (project.sub_category || project.subcategory) * 1
    ) || {}
  );
}
export function getInvoice(state) {
  return state.project.projectPage.project.invoice;
}
export const reportViolationRequest = (data) =>
  request.post("/api/v1/report-violation/", data);

/* REDUCERS
================================================================================================ */
export const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case commonImports.FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
      };
    case commonImports.FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isError: false,
        isFetching: false,
      };
    case commonImports.FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
      };
    case commonImports.FETCH_STATS_SUCCESS:
      return {
        ...state,
        stats: action.payload,
      };
    case commonImports.FETCH_NEW_PROJECTS_SUCCESS:
      return {
        ...state,
        newProjects: action.payload,
      };
    case UPDATE_AUTO_NDA_SUCCESS:
      const data = {
        ...state.data,
        nda_auto_approve: !state.data.nda_auto_approve,
      };
      return {
        ...state,
        data,
      };
    case commonImports.TOGGLE_VIOLATION:
      return {
        ...state,
        violationInfo: action.payload,
      };
    case RESERVE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          elite_reservations: {
            ...state.data.elite_reservations,
            ...action.payload,
          },
        },
      };
    case commonImports.TOGGLE_STATS:
      return {
        ...state,
        showingStatsModal: !state.showingStatsModal,
      };
    case FETCH_INVOICE_SUCCESS:
      return {
        ...state,
        invoice: action.payload,
      };
    case REMOVE_FINALIST_SUCCESS:
    case ADD_FINALIST_SUCCESS:
    case START_FINAL_ROUND_SUCCESS:
    case EXTEND_FINAL_ROUND_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

const reducer = combineReducers({
  project: projectReducer,
  entries: entriesReducer,
  entryModal: singleEntryReducer,
});

export default reducer;

/* ACTION CREATORS
================================================================================================ */

export function updateAutoNDA(projectId, nda_auto_approve) {
  const opts = {
    method: "patch",
    url: `/api/v1/projects/${projectId}/nda_auto_approve/`,
    data: { nda_auto_approve },
  };

  return {
    type: UPDATE_AUTO_NDA,
    promise: request(opts).catch(() => {}),
    payload: {
      id: projectId,
      nda_auto_approve,
    },
  };
}

/* Update project locally */
export const updateProjectLocal = (payload) => (dispatch) =>
  dispatch({
    type: commonImports.FETCH_SUCCESS,
    payload,
  });

const handleFetchError = (serverRes, res) => {
  let redirectTo = "/error/";

  /* project not found */
  if (res.status * 1 === 404) {
    redirectTo = "/404/";
    /* handle specific forbidden errors */
  } else if (res.status * 1 === 403) {
    const errorCode = idx(res, (_) => _.data.error_code) * 1;

    if (errorCode === FORBIDDEN_1_1_CODE) {
      redirectTo = "/forbidden-private-project/";
    } else if (errorCode === FORBIDDEN_1_1_NOT_SELECTED_CODE) {
      redirectTo = "/forbidden-private-project/?participated=true";
    } else if (
      [
        FORBIDDEN_DELETED_CODE,
        FORBIDDEN_CANCELLED_CODE,
        FORBIDDEN_HIDDEN_CODE,
      ].includes(errorCode)
    ) {
      redirectTo = "/project-down-for-nap/";
    }
  }

  /* client-side */
  if (browserHistory) {
    browserHistory.push(redirectTo);
    /* server-side */
  } else if (serverRes) {
    serverRes.redirect(301, redirectTo);
  }

  /* keep the rejection chain */
  throw res;
};

export const fetchProject = (id) => (dispatch, getState) => {
  const promise = request
    .get(`/api/v1/projects/${id}/`)
    .catch(partial(handleFetchError, getServerResponse(getState())));

  dispatch({
    type: commonImports.FETCH,
    promise,
  });

  return promise;
};

export const fetchProjectIfNeeded =
  ({ projectId }) =>
  (dispatch, getState) => {
    const state = getState();
    const project = getProject(state);
    const id = projectId || 0;

    if (project && project.id === id) {
      return Promise.resolve({ data: project });
    } else if (id) {
      const promise = dispatch(fetchProject(id));
      return promise;
    }

    return Promise.resolve();
  };

export const fetchStatsIfNeeded = (projectId) => (dispatch, getState) => {
  const state = getState();
  const stats = getStats(state);

  if (stats && stats.id === projectId) {
    return Promise.resolve({ data: stats });
  }

  const promise = request.get(`/api/v1/projects/${projectId}/stats/`);
  dispatch({
    type: commonImports.FETCH_STATS,
    promise,
  });
  return promise;
};

export const fetchNewProjects = (projectId) => (dispatch) => {
  return dispatch({
    type: commonImports.FETCH_NEW_PROJECTS,
    promise: request.get(`/api/v1/projects/${projectId}/new_related/`),
  });
};

export const fetchInvoice = (projectId) => (dispatch) => {
  return dispatch({
    type: FETCH_INVOICE,
    promise: request.get(`/api/v1/projects/${projectId}/invoice/`),
  });
};

// Other
export function toggleViolationModal(info) {
  return {
    type: commonImports.TOGGLE_VIOLATION,
    payload: info,
  };
}

export function toggleStatsModal() {
  return {
    type: commonImports.TOGGLE_STATS,
  };
}
