import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import first from "lodash/first";
import StarRating from "common/components/star_rating";
import ProjectLink from "notifications/components/project_link";
import EntryLink from "notifications/components/entry_link";
import EntryCommentLink from "notifications/components/entry_comment_link";
import WrapupLink from "notifications/components/wrapup_link";
import Money from "common/components/money";
import Thumbnail from "common/components/thumbnail";
import OFFER_NEGOTIATION_STATUSES from "entries/offer_statuses";
import { ELITE_SPOTS, DAYS_TO_AWARD } from "project/constants";
import { MINI_PROJECT_SUBCAT_NAME } from "pap/constants";
import { MIN_BS_REFERRALS_STR } from "marketing/constants";

const UserLink = ({ user }) => (
  <Link to={`/users/${user.username}/`} target="_blank">
    {user.username}
  </Link>
);

const RevisionImagery = ({ revision, tags = [], message }) => {
  const thumbnails = revision.thumbnails || [];
  const thumbnailImage = revision.name ? null : first(thumbnails);

  if (!revision.name && !thumbnailImage) {
    return null;
  }

  return (
    <div className="entry-tile">
      <Thumbnail
        className="tile read-only entry-thumbnail"
        image={thumbnailImage}
      >
        {revision.name && <div className="revision-name">{revision.name}</div>}
        {message && <div className="message">{message}</div>}
        {tags.map((t, i) => (
          <div key={i} className="flag offered hide-for-small-only">
            <div>{t.content}</div>
          </div>
        ))}
      </Thumbnail>
    </div>
  );
};

const EntryOfferNegotiation = (props) => {
  const { revision, entry, comment, project } = props;
  const statusInfo = OFFER_NEGOTIATION_STATUSES({ entry, project })[
    entry.offer_negotiation_status
  ];
  const { title, declined, accepted } = statusInfo.notification;
  const message =
    declined || accepted ? (
      <div className={"text-" + (declined ? "danger" : "success")}>
        <i
          className={
            "fa fa-" +
            (declined
              ? "window-close-o text-danger"
              : "check-circle text-success")
          }
        />
        {declined ? "Declined" : "Accepted"}
      </div>
    ) : null;

  return (
    <div className="row">
      <div className="small-10 columns">
        <div className="notification-content">
          <h5 className="title">{title}</h5>
          {comment && <p className="content quote">&quot;{comment}&quot;</p>}
        </div>
      </div>
      <div className="small-6 columns notification-imagery">
        <RevisionImagery
          revision={revision}
          tags={[{ content: "Offer" }]}
          message={message}
        />
      </div>
    </div>
  );
};

const EntryDeleted = ({ project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          Your entry has been removed from <ProjectLink project={project} />.
        </h5>
      </div>
    </div>
  </div>
);

const ClientCommentedEntry = ({
  author,
  revision,
  comment,
  project,
  entry,
}) => (
  <div className="row">
    <div className="small-10 columns">
      <div className="notification-content">
        <h5 className="title">
          <UserLink user={author} /> added a comment to your entry in{" "}
          <EntryCommentLink project={project} entry={entry} />.
        </h5>
        <p className="content quote">&quot;{comment.body}&quot;</p>
      </div>
    </div>
    <div className="small-6 columns notification-imagery">
      <RevisionImagery revision={revision} />
    </div>
  </div>
);

const EntryScored = ({ revision, project, entry }) => (
  <div className="row">
    <div className="small-10 columns">
      <div className="notification-content">
        <h5 className="title">
          The client rated your entry in{" "}
          <EntryLink project={project} entry={entry} />.
        </h5>
        <StarRating
          readonly={true}
          initialRate={revision.score || 0}
          extraClasses="md"
        />
      </div>
    </div>
    <div className="small-6 columns notification-imagery">
      <RevisionImagery revision={revision} />
    </div>
  </div>
);

const Invited = ({ project }) => {
  // we have two subcategories because we updated the context on the backend, and
  // the ui needs to handle both
  const { sub_category, subcategory } = project;
  const subcategoryName = sub_category ? sub_category.name : subcategory.name;
  return (
    <div className="row">
      <div className="small-16 columns">
        <div className="notification-content">
          <h5 className="title">
            You have been invited to participate in a{" "}
            <ProjectLink
              project={project}
              linkTitle={`${subcategoryName} project`}
            />
            .
          </h5>
        </div>
      </div>
    </div>
  );
};

const WatchedProjectEnding = ({ project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          A project you are watching, <ProjectLink project={project} /> is
          ending soon.
        </h5>
      </div>
    </div>
  </div>
);

const OneToOneClientRequestedQuote = ({ project, comment }) => {
  return (
    <div className="row">
      <div className="small-16 columns">
        <div className="notification-content">
          <h5 className="title">
            You were asked for a quote for a{" "}
            {project.mini ? MINI_PROJECT_SUBCAT_NAME : "1-to-1"} project,{" "}
            <ProjectLink project={project} />.
          </h5>
          {comment && <p className="content quote">&quot;{comment}&quot;</p>}
        </div>
      </div>
    </div>
  );
};

const OneToOneClientSentOffer = ({ project, comment }) => {
  return (
    <div className="row">
      <div className="small-16 columns">
        <div className="notification-content">
          <h5 className="title">
            You received an offer for a{" "}
            {project.mini ? MINI_PROJECT_SUBCAT_NAME : "1-to-1"} project,{" "}
            <ProjectLink project={project} />.
          </h5>
          {comment && <p className="content quote">&quot;{comment}&quot;</p>}
        </div>
      </div>
    </div>
  );
};

const OneToOneClientSentCounterOffer = ({ project, comment }) => {
  return (
    <div className="row">
      <div className="small-16 columns">
        <div className="notification-content">
          <h5 className="title">
            You received a counter-offer for a{" "}
            {project.mini ? MINI_PROJECT_SUBCAT_NAME : "1-to-1"} project,{" "}
            <ProjectLink project={project} />.
          </h5>
          {comment && <p className="content quote">&quot;{comment}&quot;</p>}
        </div>
      </div>
    </div>
  );
};

const OneToOneClientDeclinedQuote = ({ project, comment }) => {
  return (
    <div className="row">
      <div className="small-16 columns">
        <div className="notification-content">
          <h5 className="title">
            Your quote for a{" "}
            {project.mini ? MINI_PROJECT_SUBCAT_NAME : "1-to-1"} project,{" "}
            <ProjectLink project={project} /> was declined.
          </h5>
          {comment && <p className="content quote">&quot;{comment}&quot;</p>}
        </div>
      </div>
    </div>
  );
};

const OneToOneClientAcceptedQuote = ({ project, comment }) => {
  return (
    <div className="row">
      <div className="small-16 columns">
        <div className="notification-content">
          <h5 className="title">
            Your quote for a{" "}
            {project.mini ? MINI_PROJECT_SUBCAT_NAME : "1-to-1"} project,{" "}
            <ProjectLink project={project} /> was accepted.
          </h5>
          {comment && <p className="content quote">&quot;{comment}&quot;</p>}
        </div>
      </div>
    </div>
  );
};

const OneToOneCreativeSentQuote = ({ project, comment }) => {
  return (
    <div className="row">
      <div className="small-16 columns">
        <div className="notification-content">
          <h5 className="title">
            You received a quote for a{" "}
            {project.mini ? MINI_PROJECT_SUBCAT_NAME : "1-to-1"} project,{" "}
            <ProjectLink project={project} />.
          </h5>
          {comment && <p className="content quote">&quot;{comment}&quot;</p>}
        </div>
      </div>
    </div>
  );
};

const OneToOneCreativeSentCounterQuote = ({ project, comment }) => {
  return (
    <div className="row">
      <div className="small-16 columns">
        <div className="notification-content">
          <h5 className="title">
            You received a counter-offer for a{" "}
            {project.mini ? MINI_PROJECT_SUBCAT_NAME : "1-to-1"} project,{" "}
            <ProjectLink project={project} />.
          </h5>
          {comment && <p className="content quote">&quot;{comment}&quot;</p>}
        </div>
      </div>
    </div>
  );
};

const OneToOneCreativeDeclinedOffer = ({ project, comment }) => {
  return (
    <div className="row">
      <div className="small-16 columns">
        <div className="notification-content">
          <h5 className="title">
            Your offer for a{" "}
            {project.mini ? MINI_PROJECT_SUBCAT_NAME : "1-to-1"} project,{" "}
            <ProjectLink project={project} /> was declined.
          </h5>
          {comment && <p className="content quote">&quot;{comment}&quot;</p>}
        </div>
      </div>
    </div>
  );
};

const OneToOneCreativeAcceptedOffer = ({ project, comment }) => {
  return (
    <div className="row">
      <div className="small-16 columns">
        <div className="notification-content">
          <h5 className="title">
            Your offer for a{" "}
            {project.mini ? MINI_PROJECT_SUBCAT_NAME : "1-to-1"} project,{" "}
            <ProjectLink project={project} /> was accepted.
          </h5>
          {comment && <p className="content quote">&quot;{comment}&quot;</p>}
        </div>
      </div>
    </div>
  );
};

const WrapupNudge = ({ project, award }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          You received a nudge on <WrapupLink project={project} award={award} />
          .
        </h5>
      </div>
    </div>
  </div>
);

const WatchedProjectEnds = ({ project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          The watched project <ProjectLink project={project} /> ended.
        </h5>
      </div>
    </div>
  </div>
);

const WatchedProjectAwarded = ({ project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          The watched project <ProjectLink project={project} /> was awarded.
        </h5>
      </div>
    </div>
  </div>
);

const TipReceived = ({ project, tip }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          You received a <Money value={tip.amount} /> tip on project{" "}
          <ProjectLink project={project} />.
        </h5>
      </div>
    </div>
  </div>
);

const RegistrationWelcome = ({}) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">Your email was successfully confirmed.</h5>
      </div>
    </div>
  </div>
);

/* TODO: this notification isn't triggered anywhere in the backend. */
const ProjectMatchesSpecialization = ({}) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">A new project matches your specializations.</h5>
      </div>
    </div>
  </div>
);

const UninvitedToCollaborate = ({ project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          You&apos;ve been uninvited to collaborate in project{" "}
          <ProjectLink project={project} />.
        </h5>
      </div>
    </div>
  </div>
);

const CollaborationPermissionsUpdated = ({ project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          Collaboration permissions for project{" "}
          <ProjectLink project={project} /> have been updated.
        </h5>
      </div>
    </div>
  </div>
);

const InvitedToCollaborate = ({ project, message, invitation_link }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          You&apos;ve invited to collaborate in project{" "}
          <ProjectLink project={project} />.
        </h5>
        <p className="content link">
          <Link to={invitation_link}>Click here to accept</Link>
        </p>
      </div>
    </div>
  </div>
);

const AcceptedCollaborationInvite = ({ project, collaborator }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          <UserLink user={collaborator} /> accepted your invite to collaborate
          in project <ProjectLink project={project} />.
        </h5>
      </div>
    </div>
  </div>
);

const PaymentReceived = ({ project, payment, tip }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          You received a <Money value={(tip || payment).amount} /> payment for
          project <ProjectLink project={project} />.
        </h5>
      </div>
    </div>
  </div>
);

const RequestedPasswordReset = ({}) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          An email with a link to reset your password was sent.
        </h5>
      </div>
    </div>
  </div>
);

const SentEmailValidation = ({}) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          An email with a link to validate your email was sent.
        </h5>
      </div>
    </div>
  </div>
);

const ReceivedNdaRequest = ({ project, creative }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          <UserLink user={creative} /> requested access to your project,{" "}
          <ProjectLink project={project} />.
        </h5>
      </div>
    </div>
  </div>
);

const ReceivedAutoApprovedNdaRequest = ({ project, creative }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          <UserLink user={creative} /> requested access to your project,{" "}
          <ProjectLink project={project} /> and was automatically approved.
        </h5>
      </div>
    </div>
  </div>
);

const CreativeCommentedEntry = ({
  author,
  revision,
  comment,
  project,
  entry,
}) => (
  <div className="row">
    <div className="small-10 columns">
      <div className="notification-content">
        <h5 className="title">
          <UserLink user={author} /> added a comment to their entry in{" "}
          <EntryCommentLink project={project} entry={entry} />.
        </h5>
        <p className="content quote">&quot;{comment.body}&quot;</p>
      </div>
    </div>
    <div className="small-6 columns notification-imagery">
      <RevisionImagery revision={revision} />
    </div>
  </div>
);

const CollabCommentedEntry = ({
  author,
  revision,
  comment,
  project,
  entry,
  internal,
}) => (
  <div className="row">
    <div className="small-10 columns">
      <div className="notification-content">
        <h5 className="title">
          <UserLink user={author} /> added{" "}
          {internal ? "an internal note" : "a comment"} to an entry in{" "}
          <EntryCommentLink project={project} entry={entry} />.
        </h5>
        <p className="content quote">&quot;{comment.body}&quot;</p>
      </div>
    </div>
    <div className="small-6 columns notification-imagery">
      <RevisionImagery revision={revision} />
    </div>
  </div>
);

/* TODO: this notification isn't triggered anywhere in the backend. */
const ClientUpdatedBrief = ({}) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">A project brief was updated.</h5>
      </div>
    </div>
  </div>
);

const RemovedFromProject = ({ project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          You were removed from a project, <ProjectLink project={project} />.
        </h5>
      </div>
    </div>
  </div>
);

const DeniedNdaRequest = ({ project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          Your access request to a project, <ProjectLink project={project} />{" "}
          was denied.
        </h5>
      </div>
    </div>
  </div>
);

const AwardedEntry = ({ project, entry }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          Your entry in <EntryLink project={project} entry={entry} /> was
          awarded.
        </h5>
      </div>
    </div>
  </div>
);

const ApprovedNdaRequest = ({ project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          Your access request to a project, <ProjectLink project={project} />{" "}
          was approved. If this is an Elite project, the approval of your NDA
          request does not mean you will be able to participate in the project.
          You will first need to secure one of the {ELITE_SPOTS} slots.
        </h5>
      </div>
    </div>
  </div>
);

const WrapupStarted = ({ award, project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          <WrapupLink
            project={project}
            award={award}
            capitalize={true}
            isNew={true}
          />{" "}
          has just started.
        </h5>
      </div>
    </div>
  </div>
);

const WrapupProofsApproved = ({ award, project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          Proofs were approved for{" "}
          <WrapupLink project={project} award={award} />.
        </h5>
      </div>
    </div>
  </div>
);

const WrapupProofsAdded = ({ award, project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          Proofs were added in <WrapupLink project={project} award={award} />.
        </h5>
      </div>
    </div>
  </div>
);

const WrapupDue = ({ award, project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          The deadline for <WrapupLink project={project} award={award} /> has
          been reached.
        </h5>
      </div>
    </div>
  </div>
);

const WrapupSurveyEncourageClient = ({ award, project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          You have not yet completed a final survey for{" "}
          <WrapupLink project={project} award={award} />. Please take a few
          minutes to complete.
        </h5>
      </div>
    </div>
  </div>
);

const WrapupSurveyEncourageCreative = ({ award, project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          You have not yet completed a final survey for{" "}
          <WrapupLink project={project} award={award} />. Please take a few
          minutes to complete.
        </h5>
      </div>
    </div>
  </div>
);

const WrapupNameApproved = ({ award, project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          The submitted name was approved in{" "}
          <WrapupLink project={project} award={award} />.
        </h5>
      </div>
    </div>
  </div>
);

const WrapupFilesApproved = ({ award, project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          Final files were approved in{" "}
          <WrapupLink project={project} award={award} />.
        </h5>
      </div>
    </div>
  </div>
);

const WrapupFilesAdded = ({ award, project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          Final files were added in{" "}
          <WrapupLink project={project} award={award} />.
        </h5>
      </div>
    </div>
  </div>
);

const WrapupCreativeSigned = ({ award, project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          The creative signed the contract in{" "}
          <WrapupLink project={project} award={award} />.
        </h5>
      </div>
    </div>
  </div>
);

const WrapupClientSigned = ({ award, project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          The client signed the contract in{" "}
          <WrapupLink project={project} award={award} />.
        </h5>
      </div>
    </div>
  </div>
);

const WorkSamplesDenied = () => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          The work samples you uploaded were reviewed but rejected by
          crowdspring.
          <Link to="/account/id-verification/">Find Out Why</Link>
        </h5>
      </div>
    </div>
  </div>
);

const WorkSamplesApproved = () => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          The work samples you uploaded were approved by crowdspring.
        </h5>
      </div>
    </div>
  </div>
);

const AccountDeleted = () => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">Your account was successfully deleted.</h5>
      </div>
    </div>
  </div>
);

const ParticipatingProjectEnding = ({ project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          A project in which you are participating,{" "}
          <ProjectLink project={project} /> is ending soon.
        </h5>
      </div>
    </div>
  </div>
);

const ParticipatingProjectEnds = ({ project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          A project in which you are participating,{" "}
          <ProjectLink project={project} /> ended.
        </h5>
      </div>
    </div>
  </div>
);

const MyProjectEndsIn4h = ({ project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          Your project, <ProjectLink project={project} />, will be closed for
          submissions in 4 hours.
        </h5>
      </div>
    </div>
  </div>
);

const MyProjectEndsIn48h = ({ project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          Your project, <ProjectLink project={project} />, will be closed for
          submissions in 48 hours.
        </h5>
      </div>
    </div>
  </div>
);

const MyProjectEndsIn24h = ({ project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          Your project, <ProjectLink project={project} />, will be closed for
          submissions in 24 hours.
        </h5>
      </div>
    </div>
  </div>
);

const CreativeSubmittedRevision = ({ creative, project, entry, revision }) => (
  <div className="row">
    <div className="small-10 columns">
      <div className="notification-content">
        <h5 className="title">
          <UserLink user={creative} /> submitted a new revision to their entry
          in <EntryLink project={project} entry={entry} />.
        </h5>
      </div>
    </div>
    <div className="small-6 columns notification-imagery">
      <RevisionImagery revision={revision} />
    </div>
  </div>
);

const CreativeSubmittedEntry = ({ creative, project, entry }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          <UserLink user={creative} /> submitted a new entry to{" "}
          <EntryLink project={project} entry={entry} />.
        </h5>
      </div>
    </div>
  </div>
);

const CreativeWasPaidOffer = ({ project, offer }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          You received the payment for a <Money value={offer.amount} /> offer on
          project <ProjectLink project={project} />.
        </h5>
      </div>
    </div>
  </div>
);

const ProjectPostedInWatchedSubcat = ({ project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          A new{" "}
          <Money
            value={project.total_awards_and_tips || project.total_awards}
          />{" "}
          {project.category.name} project, <ProjectLink project={project} />,
          was just posted!
        </h5>
      </div>
    </div>
  </div>
);

const WrapupMessage = ({ project, wrapup, message }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          You received a message on{" "}
          <WrapupLink project={project} award={wrapup.award_id} />.
        </h5>
        <p className="content quote">&quot;{message}&quot;</p>
      </div>
    </div>
  </div>
);

const ProjectMessage = ({ project, message }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          A new project update was posted on <ProjectLink project={project} />.
        </h5>
        <p className="content quote">&quot;{message}&quot;</p>
      </div>
    </div>
  </div>
);

const ParticipatedProjectAwarded = ({ project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          The project in which you are participating,{" "}
          <ProjectLink project={project} />, was awarded.
        </h5>
      </div>
    </div>
  </div>
);

const MoreNamesNeededAt80Pct = ({ project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          We could use your help on a naming project,{" "}
          <ProjectLink project={project} />. We want to be sure we meet our
          guarantee and deliver lots of naming ideas for the client.
        </h5>
      </div>
    </div>
  </div>
);

const MoreNamesNeededAt50Pct = ({ project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          We could use your help on a naming project,{" "}
          <ProjectLink project={project} />. We want to be sure we meet our
          guarantee and deliver lots of naming ideas for the client.
        </h5>
      </div>
    </div>
  </div>
);

const PresentationFeedbackReceived = ({ project, presentation }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          The presentation{" "}
          <ProjectLink
            project={project}
            append={`presentations/${presentation.id}/`}
          >
            {presentation.name}
          </ProjectLink>{" "}
          has received feedback from one of its participants.
        </h5>
      </div>
    </div>
  </div>
);

const CreativeRegistrationProcessed = ({ admin_note }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          We processed your application to work on crowdspring.{" "}
          <Link to="/account/id-verification/">See details.</Link>
        </h5>
      </div>
    </div>
  </div>
);

const SelectedForEliteProject = ({ project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          Good news. You have received one of the five spots in{" "}
          <ProjectLink project={project} linkTitle="an Elite project" />.
        </h5>
      </div>
    </div>
  </div>
);

const RankedTipReceived = ({ project, tip }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          You received a <Money value={tip.amount} /> guaranteed tip on project{" "}
          <ProjectLink project={project} />.
        </h5>
      </div>
    </div>
  </div>
);

const MoreLogoEntriesNeeded = ({ project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          We could use your help on a {project.sub_category.name} project,{" "}
          <ProjectLink project={project} />. We want to be sure we can deliver
          lots of ideas to the client.
        </h5>
      </div>
    </div>
  </div>
);

const QualityReviewWarning = ({ group_names, note }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          Your work in {group_names.join(", ")} is dangerously close to not
          meeting our minimum standards for quality.
        </h5>
      </div>
    </div>
  </div>
);

const QualityReviewDeactivate = ({ note }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          Your account has been deactivated as part of crowdspring's quality
          review process.
        </h5>
      </div>
    </div>
  </div>
);

const QualityReviewGroupsRevoked = ({ group_names, note }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          Your permissions to participate in {group_names.join(", ")} have been
          revoked as part of crowdspring's quality review process.
        </h5>
      </div>
    </div>
  </div>
);

const FinalRoundLaunchedParticipant = ({ project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          Unfortunately, you were not selected as a finalist in{" "}
          <ProjectLink project={project} />.
        </h5>
      </div>
    </div>
  </div>
);

const FinalRoundLaunchedFinalist = ({ project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          You were selected as a finalist in <ProjectLink project={project} />.
          You have 3 days to submit revisions or new designs.
        </h5>
      </div>
    </div>
  </div>
);

const FinalRoundExtended = ({ project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          The Finalist Round in <ProjectLink project={project} /> was extended
          for a few extra days.
        </h5>
      </div>
    </div>
  </div>
);

const FinalRoundEndedFinalist = ({ project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          The Finalist Round in <ProjectLink project={project} /> has ended. The
          client has {DAYS_TO_AWARD} days to pick the winner.
        </h5>
      </div>
    </div>
  </div>
);

const FinalRoundEndedClient = ({ project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          The Finalist Round in your project <ProjectLink project={project} />{" "}
          has ended. You have up to {DAYS_TO_AWARD} days to pick the winner.
        </h5>
      </div>
    </div>
  </div>
);

const OneToOneNotSelected = ({ project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          Unfortunately, you were not selected in the negotiation for the{" "}
          {project.mini ? MINI_PROJECT_SUBCAT_NAME : "1-to-1"} project{" "}
          <ProjectLink project={project} />. For privacy reasons, you won't be
          able to access this project moving forward.
        </h5>
      </div>
    </div>
  </div>
);

const NegotiationMessage = ({ author, message, project, internal }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          You received a negotiation message on{" "}
          <ProjectLink project={project} append="negotiation/" />.
        </h5>
        <p className="content quote">&quot;{message}&quot;</p>
      </div>
    </div>
  </div>
);

const OneToOnePaid = ({ project }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          The client has paid for the{" "}
          {project.mini ? MINI_PROJECT_SUBCAT_NAME : "1-to-1"} project{" "}
          <ProjectLink project={project} />. You can now start working on it.
        </h5>
      </div>
    </div>
  </div>
);

const TestimonialAdded = ({ project, user }) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">
          Your client in the project <ProjectLink project={project} /> wrote a
          new testimonial for you. You can review it{" "}
          <Link to={`/users/${user.username}/testimonials/`}>here</Link>.
        </h5>
      </div>
    </div>
  </div>
);

const BSTemplatesUnlocked = ({}) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">You unlocked bonus Brand Studio templates!</h5>
        <p className="content">
          You'll{" "}
          <Link to="/brand-studio/brands/">
            find your free social media pack in your TEMPLATES tab in Brand
            Studio
          </Link>
          .
        </p>
      </div>
    </div>
  </div>
);

const BSReferAFriendPromotion = ({}) => (
  <div className="row">
    <div className="small-16 columns">
      <div className="notification-content">
        <h5 className="title">Want more free Brand Studio templates?</h5>
        <p className="content">
          To get your free social media pack,{" "}
          <Link to="/brand-studio/refer-a-friend/">
            invite at least {MIN_BS_REFERRALS_STR} friends or share your
            personal referral code
          </Link>
          .
        </p>
      </div>
    </div>
  </div>
);

export default {
  EntryScored,
  ClientCommentedEntry,
  EntryDeleted,
  Invited,
  WatchedProjectEnding,
  OneToOneClientRequestedQuote,
  CreativeSubmittedRevision,
  CreativeSubmittedEntry,
  OneToOneClientSentOffer,
  OneToOneClientSentCounterOffer,
  OneToOneClientDeclinedQuote,
  OneToOneClientAcceptedQuote,
  OneToOneCreativeSentQuote,
  OneToOneCreativeSentCounterQuote,
  OneToOneCreativeDeclinedOffer,
  OneToOneCreativeAcceptedOffer,
  WrapupNudge,
  WatchedProjectEnds,
  WatchedProjectAwarded,
  TipReceived,
  RegistrationWelcome,
  ProjectMatchesSpecialization,
  UninvitedToCollaborate,
  CollaborationPermissionsUpdated,
  InvitedToCollaborate,
  AcceptedCollaborationInvite,
  PaymentReceived,
  RequestedPasswordReset,
  SentEmailValidation,
  ReceivedNdaRequest,
  ReceivedAutoApprovedNdaRequest,
  CreativeCommentedEntry,
  ClientUpdatedBrief,
  RemovedFromProject,
  DeniedNdaRequest,
  AwardedEntry,
  ApprovedNdaRequest,
  WrapupStarted,
  WrapupProofsApproved,
  WrapupProofsAdded,
  WrapupSurveyEncourageClient,
  WrapupDue,
  WrapupNameApproved,
  WrapupFilesApproved,
  WrapupFilesAdded,
  WrapupSurveyEncourageCreative,
  WrapupCreativeSigned,
  WrapupClientSigned,
  WorkSamplesDenied,
  WorkSamplesApproved,
  AccountDeleted,
  ParticipatingProjectEnding,
  ParticipatingProjectEnds,
  MyProjectEndsIn4h,
  MyProjectEndsIn48h,
  MyProjectEndsIn24h,
  EntryOfferNegotiation,
  CreativeWasPaidOffer,
  ProjectPostedInWatchedSubcat,
  WrapupMessage,
  ProjectMessage,
  ParticipatedProjectAwarded,
  MoreNamesNeededAt80Pct,
  MoreNamesNeededAt50Pct,
  PresentationFeedbackReceived,
  CreativeRegistrationProcessed,
  SelectedForEliteProject,
  RankedTipReceived,
  MoreLogoEntriesNeeded,
  CollabCommentedEntry,
  QualityReviewWarning,
  QualityReviewDeactivate,
  QualityReviewGroupsRevoked,
  FinalRoundLaunchedParticipant,
  FinalRoundLaunchedFinalist,
  FinalRoundExtended,
  FinalRoundEndedFinalist,
  FinalRoundEndedClient,
  OneToOneNotSelected,
  NegotiationMessage,
  OneToOnePaid,
  TestimonialAdded,
  BSTemplatesUnlocked,
  BSReferAFriendPromotion,
};
