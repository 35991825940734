import request from "axios";
import { formatStats } from "admin/ducks/common";

/* ACTIONS
================================================================================================ */
const FETCH_STATS = "admin/brand_studio/FETCH_STATS";
const FETCH_STATS_SUCCESS = "admin/brand_studio/FETCH_STATS_SUCCESS";

/* INITIAL STATES
================================================================================================ */
const initialState = {
  stats: {},
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_STATS_SUCCESS:
      return {
        ...state,
        stats: formatStats(action.payload),
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const fetchStats = () => (dispatch) =>
  dispatch({
    type: FETCH_STATS,
    promise: request.get("/api/v1/brand_studio/admin_stats/"),
  });
