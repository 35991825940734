import React from "react";
import StarRating from "common/components/star_rating";

export const ENTRIES_RESOURCE_NAME = "entries";

const SORTING_OPTIONS = [
  { field: "-created_at", label: "date added: newest first" },
  { field: "created_at", label: "date added: oldest first" },
  { field: "-entry_updated_at", label: "entry activity: most recent first" },
  { field: "entry_updated_at", label: "entry activity: oldest first" },
  { field: "name_lower", label: "name: A to Z", entryTypes: ["name"] },
  { field: "-name_lower", label: "name: Z to A", entryTypes: ["name"] },
  { field: "-score", label: "entry score: highest first" },
  { field: "score", label: "entry score: lowest first" },
];

export const getSortingOptions = (entryType, canSortByRating = true) => {
  return SORTING_OPTIONS.filter(
    (opt) => !opt.entryTypes || opt.entryTypes.includes(entryType)
  ).filter((opt) => canSortByRating || !opt.field.includes("score"));
};

export const SCORE_OPTIONS = [
  {
    field: "0",
    label: <StarRating readonly={true} initialRate={0} extraClasses="inline" />,
  },
  {
    field: "1",
    label: <StarRating readonly={true} initialRate={1} extraClasses="inline" />,
  },
  {
    field: "2",
    label: <StarRating readonly={true} initialRate={2} extraClasses="inline" />,
  },
  {
    field: "3",
    label: <StarRating readonly={true} initialRate={3} extraClasses="inline" />,
  },
  {
    field: "4",
    label: <StarRating readonly={true} initialRate={4} extraClasses="inline" />,
  },
  {
    field: "5",
    label: <StarRating readonly={true} initialRate={5} extraClasses="inline" />,
  },
];

export const ELIMINATED_OPTIONS = [{ field: "true", label: "Eliminated" }];

export const WITHDRAWN_OPTIONS = [{ field: "true", label: "Withdrawn" }];

export const ACTIVE_OPTIONS = [{ field: "true", label: "In use" }];

export const AWARDED_OPTIONS = [{ field: "true", label: "Awarded" }];

export const WINNER_OPTIONS = [{ field: "true", label: "Winner" }];

export const NEW_OPTIONS = [{ field: "true", label: "New" }];

export const POSITIVE_FEEDBACK_THRESHOLD = 3;

export const NEGATIVE_FEEDBACK_QUESTIONS = {
  default: [
    { name: "brief_requirements", label: "Didn't meet brief requirements" },
    { name: "composition", label: "Composition" },
    { name: "color", label: "Color" },
    { name: "font", label: "Font" },
    { name: "spacing", label: "Spacing" },
  ],
  "naming-a-business-or-product": [
    { name: "brief_requirements", label: "Didn't meet brief requirements" },
    { name: "fitness", label: "Isn't a good fit for us" },
    { name: "spelling", label: "Difficult to pronounce or spell" },
    { name: "distinctiveness", label: "Not distinctive enough" },
    { name: "too_long", label: "Too long" },
  ],
  "business-tagline": [
    { name: "brief_requirements", label: "Didn't meet brief requirements" },
    { name: "fitness", label: "Isn't a good fit for us" },
    { name: "spelling", label: "Difficult to pronounce or spell" },
    { name: "distinctiveness", label: "Not distinctive enough" },
    { name: "too_long", label: "Too long" },
  ],
};

export const ITEMS_BY_ROW = {
  small: 1,
  medium: 2,
  large: 3,
};

/* number of seconds before "feedback sent" notice is dismissed */
export const SHOW_FEEDBACK_SENT_FOR = 3;

export const TIP_FEE = 0.15;

export const OFFER_FEE = 0.15;
export const MINIMUM_OFFER = 100;

export const FINAL_ROUND_OPTIONS = [
  { field: "true", label: "Show only entries by finalists" },
];

export const FINALISTS_ONLY_QUERY_PARAM =
  "entries_filter_checkboxes_by_finalists_only";
