import request from "axios";
import { PAYOUT_REQUESTS_RESOURCE_NAME } from "admin/constants";
import { getAPIQueryString } from "common/utils";
import { savePageCount } from "common/pager/ducks";
import { formatStats } from "admin/ducks/common";
import { setBannerError } from "error";

/* ACTIONS
================================================================================================ */
const FETCH_ALL = "admin/payout_requests/FETCH_ALL";
const FETCH_ALL_REQUEST = "admin/payout_requests/FETCH_ALL_REQUEST";
const FETCH_ALL_SUCCESS = "admin/payout_requests/FETCH_ALL_SUCCESS";

const FETCH_STATS = "admin/payout_requests/FETCH_STATS";
const FETCH_STATS_SUCCESS = "admin/payout_requests/FETCH_STATS_SUCCESS";

const FETCH = "admin/payout_requests/FETCH";
const FETCH_REQUEST = "admin/payout_requests/FETCH_REQUEST";
const FETCH_SUCCESS = "admin/payout_requests/FETCH_SUCCESS";

const PROCESS = "admin/payout_requests/PROCESS";
const PROCESS_SUCCESS = "admin/payout_requests/PROCESS_SUCCESS";

const SET_ACTIONS = "admin/payout_requests/SET_ACTIONS";

/* HELPERS
================================================================================================ */
export const getQueryString = (query) => {
  const extraAPIQuery = {
    admin_preset: query.preset,
  };
  return getAPIQueryString(PAYOUT_REQUESTS_RESOURCE_NAME, query, {
    extraAPIQuery,
  });
};

/* INITIAL STATES
================================================================================================ */
const initialState = {
  list: [],
  isFetching: false,
  requestCount: 0,
  stats: {},
  actions: [],
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_REQUEST:
      return {
        ...state,
        queryStr: action.queryStr,
        isFetching: true,
      };
    case FETCH_ALL_SUCCESS:
      /* avoid wrong results due to parallel requests */
      if (action.queryStr !== state.queryStr) {
        return state;
      }

      return {
        ...state,
        isFetching: false,
        list: action.payload.results,
        requestCount: action.payload.count,
      };
    case FETCH_STATS_SUCCESS:
      return {
        ...state,
        stats: formatStats(action.payload),
      };
    case FETCH_REQUEST:
      return {
        ...state,
        actions: [],
        single: undefined,
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        single: action.payload,
      };
    case PROCESS_SUCCESS:
      return {
        ...state,
        single: action.payload,
        actions: [],
      };
    case SET_ACTIONS:
      return {
        ...state,
        actions: action.payload,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const fetchAll = (query) => (dispatch) => {
  const queryStr = getQueryString(query);
  const promise = request.get(`/api/v1/radmin/payout/requests/?${queryStr}`);

  /* save page count in pager state */
  promise.then((res) =>
    dispatch(savePageCount(PAYOUT_REQUESTS_RESOURCE_NAME, res))
  );

  return dispatch({
    type: FETCH_ALL,
    promise,
    queryStr,
  });
};

export const fetchStats = () => (dispatch) => {
  return dispatch({
    type: FETCH_STATS,
    promise: request.get(`/api/v1/radmin/payout/requests/stats/`),
  });
};

export const fetch = (id) => (dispatch) => {
  return dispatch({
    type: FETCH,
    promise: request.get(`/api/v1/radmin/payout/requests/${id}/`),
  });
};

export const process = (data) => (dispatch, getState) => {
  const { actions, single } = getState().admin.payoutRequests;
  const singleId = data ? data[0].id : single.id;
  data =
    data ||
    actions.map((action) => ({
      ...action,
      id: action.request.id,
      request: undefined,
    }));
  const promise = request.patch(
    `/api/v1/radmin/payout/requests/${singleId}/process/`,
    data
  );

  promise.catch(() =>
    dispatch(setBannerError("Error processing requests", "Please try again."))
  );

  dispatch({
    type: PROCESS,
    promise,
  });

  return promise;
};

export const setActions = (payload) => (dispatch) => {
  return dispatch({
    type: SET_ACTIONS,
    payload,
  });
};
