import { connect } from "react-redux";
import VerifyEmailHeader from "common/components/header_verify_email";

const mapStateToProps = ({ user }) => {
  return {
    emailVerified: user.profile_data.is_email_validated
      ? true
      : user.emailVerified,
  };
};

export default connect(mapStateToProps)(VerifyEmailHeader);
