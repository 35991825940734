import request from "axios";
import idx from "idx";
import {
  ADD_COMMENT_SUCCESS,
  ADD_COMMENT_REPLY_SUCCESS,
} from "project/ducks/common";
import {
  WITHDRAW_SUCCESS,
  UNWITHDRAW_SUCCESS,
  REMOVE_CREATIVE_SUCCESS,
  HIDE_SUCCESS,
  UNHIDE_SUCCESS,
} from "admin/ducks/project_entries";
import { setBannerError } from "error";

/* ACTIONS
================================================================================================ */
export const FETCH = "admin/FETCH_PROJECT_ENTRY";
export const FETCH_SUCCESS = "admin/FETCH_PROJECT_ENTRY_SUCCESS";

export const FETCH_COMMENTS = "admin/FETCH_PROJECT_REVISION_COMMENTS";
export const FETCH_COMMENTS_REQUEST =
  "admin/FETCH_PROJECT_REVISION_COMMENTS_REQUEST";
export const FETCH_COMMENTS_SUCCESS =
  "admin/FETCH_PROJECT_REVISION_COMMENTS_SUCCESS";

export const SET_CURRENT_REVISION = "admin/SET_CURRENT_PROJECT_REVISION";

export const ZOOM_IN = "admin/PROJECT_ENTRY_ZOOM_IN";
export const ZOOM_OUT = "admin/PROJECT_ENTRY_ZOOM_OUT";

/* HELPERS
================================================================================================ */
export const MAX_ZOOM_SCALE = 8;

/* INITIAL STATES
================================================================================================ */
export const initialState = {
  entry: null,
  comments: [],
  zoom: 1,
  scaleLevel: 4,
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  let newScale;

  switch (action.type) {
    case FETCH_SUCCESS:
      return {
        ...state,
        entry: action.payload,
        currentRevisionId:
          action.currentRevisionId ||
          idx(action.payload, (_) => _.revisions[0].id),
      };
    case FETCH_COMMENTS_REQUEST:
      return {
        ...state,
        comments: [],
      };
    case FETCH_COMMENTS_SUCCESS:
      return {
        ...state,
        comments: action.payload,
      };
    case ADD_COMMENT_SUCCESS:
      return {
        ...state,
        comments: [...state.comments, action.payload],
      };
    case ADD_COMMENT_REPLY_SUCCESS:
      return {
        ...state,
        comments: state.comments.map((comment) => {
          if (comment.id === action.payload.reply_to) {
            return {
              ...comment,
              replies: [...comment.replies, action.payload],
            };
          }
          return comment;
        }),
      };
    case SET_CURRENT_REVISION:
      return {
        ...state,
        currentRevisionId: action.payload,
      };
    case ZOOM_IN:
      newScale = Math.min(state.scaleLevel + 1, MAX_ZOOM_SCALE);
      return {
        ...state,
        scaleLevel: newScale,
        zoom: newScale * 0.25,
      };
    case ZOOM_OUT:
      newScale = Math.max(state.scaleLevel - 1, 0);
      return {
        ...state,
        scaleLevel: newScale,
        zoom: newScale * 0.25,
      };
    case WITHDRAW_SUCCESS:
    case REMOVE_CREATIVE_SUCCESS:
      return {
        ...state,
        entry:
          state.entry && action.payload.includes(state.entry.id)
            ? { ...state.entry, withdrawn: true, withdrawn_by_admin: true }
            : state.entry,
      };
    case UNWITHDRAW_SUCCESS:
      return {
        ...state,
        entry:
          state.entry && action.payload.includes(state.entry.id)
            ? { ...state.entry, withdrawn: false, withdrawn_by_admin: false }
            : state.entry,
      };
    case HIDE_SUCCESS:
      return {
        ...state,
        entry:
          action.id === idx(state, (_) => _.entry.id)
            ? { ...state.entry, hidden: true }
            : state.entry,
      };
    case UNHIDE_SUCCESS:
      return {
        ...state,
        entry:
          action.id === idx(state, (_) => _.entry.id)
            ? { ...state.entry, hidden: false }
            : state.entry,
      };
    default:
      return state;
  }
}

export const fetch = (projectId, currentRevisionId, id) => (dispatch) => {
  return dispatch({
    type: FETCH,
    promise: request.get(`/api/v1/projects/${projectId}/entries/${id}/`),
    currentRevisionId,
  });
};

export const fetchComments = (entryType, revisionId) => (dispatch) => {
  dispatch({
    type: FETCH_COMMENTS,
    promise: request.get(
      `/api/v1/project-${entryType}-entry-revisions/${revisionId}/comments/`
    ),
  });
};

export const setCurrentRevision = (revisionId) => (dispatch, getState) => {
  dispatch({
    type: SET_CURRENT_REVISION,
    payload: revisionId,
  });
};

export const zoomIn = () => {
  return {
    type: ZOOM_IN,
  };
};

export const zoomOut = () => {
  return {
    type: ZOOM_OUT,
  };
};

export const updateComment =
  (entryType, revisionId, id, data) => (dispatch) => {
    const promise = request.patch(
      `/api/v1/project-${entryType}-entry-revisions/${revisionId}/comments/${id}/`,
      data
    );

    promise
      .then(() => {
        dispatch(fetchComments(entryType, revisionId));
      })
      .catch((err) => {
        const errorMsg = idx(err, (_) => _.data.message) || "Unknown error.";
        dispatch(setBannerError("Error updating comment", errorMsg));
      });

    return promise;
  };

export const hideComment = (entryType, revisionId, id) => (dispatch) => {
  const promise = request.patch(
    `/api/v1/project-${entryType}-entry-revisions/${revisionId}/comments/${id}/hide/`
  );

  promise
    .then(() => {
      dispatch(fetchComments(entryType, revisionId));
    })
    .catch((err) => {
      const errorMsg = idx(err, (_) => _.data.message) || "Unknown error.";
      dispatch(setBannerError("Error hiding comment", errorMsg));
    });

  return promise;
};

export const unhideComment = (entryType, revisionId, id) => (dispatch) => {
  const promise = request.patch(
    `/api/v1/project-${entryType}-entry-revisions/${revisionId}/comments/${id}/unhide/`
  );

  promise
    .then(() => {
      dispatch(fetchComments(entryType, revisionId));
    })
    .catch((err) => {
      const errorMsg = idx(err, (_) => _.data.message) || "Unknown error.";
      dispatch(setBannerError("Error unhiding comment", errorMsg));
    });

  return promise;
};
