import React from "react";
import PropTypes from "prop-types";
import NotificationWrapper from "notifications/containers/wrapper";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import AllCheckbox from "notifications/containers/all_checkbox";
import ActionSelect from "notifications/containers/action_select";

const NotificationsList = ({ typeDisplay, notificationsList, showChecks }) => {
  if (!notificationsList) {
    return null;
  }

  if (!notificationsList.length) {
    return (
      <div className="row">
        <div className="small-16 columns">
          <div className="empty-state no-results">
            <div className="empty-state-container">
              <h3>No {typeDisplay} notifications.</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="notifications-list">
      {showChecks && (
        <div className="action-selector hide-for-small-only">
          <div className="row">
            <div className="medium-2 large-1 columns">
              <AllCheckbox notificationsList={notificationsList} />
            </div>
            <div className="medium-8 large-4 columns">
              <ActionSelect />
            </div>
          </div>
        </div>
      )}
      <ReactCSSTransitionGroup
        transitionName="new-notification"
        transitionEnterTimeout={250}
        transitionLeaveTimeout={250}
      >
        {notificationsList.map((notification) => (
          <NotificationWrapper
            key={notification.uuid}
            notification={notification}
            showChecks={showChecks}
          />
        ))}
      </ReactCSSTransitionGroup>
    </div>
  );
};

NotificationsList.propTypes = {
  notificationsList: PropTypes.array,
  showChecks: PropTypes.bool,
  typeDisplay: PropTypes.string,
};

export default NotificationsList;
