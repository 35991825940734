import Checkbox from "notifications/components/checkbox";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toggleAllSelected } from "notifications/ducks";
import partial from "lodash/partial";

export function mapStateToProps({ notifications }, { notificationsList }) {
  const { selectedNotifications } = notifications;
  const list = notificationsList || [];
  const selected = list.reduce(
    (mem, { uuid }) => mem && selectedNotifications.indexOf(uuid) !== -1,
    true
  );

  return {
    selected,
    notificationId: "all",
  };
}

export function mapDispatchToProps(dispatch, { notificationsList }) {
  return bindActionCreators(
    {
      onChange: partial(toggleAllSelected, notificationsList),
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkbox);
