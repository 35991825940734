import request from "axios";
import pick from "lodash/pick";
import uniq from "lodash/uniq";
import range from "lodash/range";
import idx from "idx";
import { showBanner, TYPE_SUCCESS } from "common/ducks/banner";
import { setBannerError } from "error";
import { GROUPS_PER_AUTOMATION } from "email_automations/constants";

/* ACTIONS
================================================================================================ */
const FETCH_ALL = "rss_automation_creators/FETCH_ALL";
const FETCH_ALL_REQUEST = "rss_automation_creators/FETCH_ALL_REQUEST";
const FETCH_ALL_SUCCESS = "rss_automation_creators/FETCH_ALL_SUCCESS";

const FETCH = "rss_automation_creators/FETCH";
const FETCH_REQUEST = "rss_automation_creators/FETCH_REQUEST";
const FETCH_SUCCESS = "rss_automation_creators/FETCH_SUCCESS";

/* HELPERS
================================================================================================ */
export const createRequest = () =>
  request.post("/api/v1/bb/rss_automation_creators/");

/* INITIAL STATES
================================================================================================ */
const initialState = {
  all: null,
  single: null,
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_REQUEST:
      return {
        ...state,
        all: null,
      };
    case FETCH_ALL_SUCCESS:
      return {
        ...state,
        all: action.payload,
      };
    case FETCH_REQUEST:
      return {
        ...state,
        single: null,
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        single: action.payload,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const fetchAll = () => (dispatch) => {
  return dispatch({
    type: FETCH_ALL,
    promise: request.get("/api/v1/bb/rss_automation_creators/"),
  });
};

export const fetch = (id) => (dispatch) => {
  return dispatch({
    type: FETCH,
    promise: request.get(`/api/v1/bb/rss_automation_creators/${id}/`),
  });
};

export const save = (id, formData) => (dispatch) => {
  const data = {
    ...pick(
      formData,
      "enabled",
      "name",
      "feed_url",
      "subject",
      "from_email",
      "from_name",
      "reply_to",
      "preheader",
      "text_content",
      "contact_list",
      "goal_1",
      "goal_2",
      "goal_3",
      "goal_4",
      "unsubscribe_strategy"
    ),
    contact_list_filters: JSON.parse(formData.contact_list_filters),
    groups: uniq(
      range(1, GROUPS_PER_AUTOMATION + 1)
        .map((i) => formData[`group_${i}`])
        .filter((g) => !!g)
    ),
    extra_link_attributes: pick(
      formData,
      "utm_source",
      "utm_medium",
      "utm_campaign"
    ),
    html_content: idx(formData, (_) => _.html_data.html) || "",
    html_design: idx(formData, (_) => _.html_data.design) || "",
  };

  const promise = request.patch(
    `/api/v1/bb/rss_automation_creators/${id}/`,
    data
  );

  promise
    .then(() => {
      dispatch(
        showBanner(
          "Success",
          "RSS automation successfully saved.",
          undefined,
          TYPE_SUCCESS,
          null,
          3000
        )
      );

      dispatch(fetch(id));
    })
    .catch((err) => {
      const errorMsg = err.data
        ? JSON.stringify(err.data)
        : "Make sure the data you entered is correct.";
      dispatch(setBannerError("Error saving RSS automation", errorMsg));
    });

  return promise;
};

export const remove = (id) => (dispatch) => {
  const promise = request.delete(`/api/v1/bb/rss_automation_creators/${id}/`);

  promise.catch((err) => {
    const errorMsg = err.data ? JSON.stringify(err.data) : "Unkown error.";
    dispatch(setBannerError("Error deleting RSS automation", errorMsg));
  });

  return promise;
};
