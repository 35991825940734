/* ACTION CONSTANTS
================================================================================================ */
const SAVE_LAST_QUERY = "crowdspring/pager/SAVE_LAST_QUERY";

const SAVE_PAGE_COUNT = "crowdspring/pager/SAVE_PAGE_COUNT";

/* INITIAL STATES
================================================================================================ */
export const initialState = {
  lastQueries: {},
  pageCounts: {},
};

/* REDUCERS
================================================================================================ */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_LAST_QUERY:
      return {
        ...state,
        lastQueries: {
          ...state.lastQueries,
          [action.resourceName]: action.query,
        },
      };
    case SAVE_PAGE_COUNT:
      return {
        ...state,
        pageCounts: {
          ...state.pageCounts,
          [action.resourceName]: action.count,
        },
      };
    default:
      return state;
  }
};

export default reducer;

/* ACTION CREATORS
================================================================================================ */

/* Save last query dict produced by a given pager.
 * Used only for the purpose of keeping a history in case the same query needs to be restored after
 * one or more navigation changes occur. */
export const saveLastQuery = (resourceName, query) => {
  return {
    type: SAVE_LAST_QUERY,
    resourceName,
    query,
  };
};

export const savePageCount = (resourceName, response) => {
  return {
    type: SAVE_PAGE_COUNT,
    count: Math.ceil(response.data.count / response.data.limit),
    resourceName,
  };
};
