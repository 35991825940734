import {
  PROJECT_ENTRIES_URL_REGEXP,
  EXPLORE_PROJECTS_URL_REGEXP,
  EXPLORE_CREATIVES_URL_REGEXP,
  PROJECT_YOUR_ENTRIES_URL_REGEXP,
} from "common/variables";

const PROFILE_ENTRIES_URL_REGEXP = /^\/users\/(.)*\/(your-entries)\/[a-z]+/;

/* ACTIONS
 ================================================================================================ */
export const SET_CONTENT = "crowdspring/right_drawer/SET_CONTENT";

/* INITIAL STATE
 ================================================================================================ */
const initialState = {
  content: null,
};

/* HELPERS
 ================================================================================================ */

/* REDUCERS
 ================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_CONTENT:
      const url = action.payload;
      let content = null;

      if (
        url.match(PROJECT_ENTRIES_URL_REGEXP) ||
        url.match(PROJECT_YOUR_ENTRIES_URL_REGEXP)
      ) {
        content = "project";
      } else if (url.match(EXPLORE_CREATIVES_URL_REGEXP)) {
        content = "explore_creatives";
      } else if (url.match(EXPLORE_PROJECTS_URL_REGEXP)) {
        content = "explore_projects";
      } else if (url.match(PROFILE_ENTRIES_URL_REGEXP)) {
        content = "profile_entries";
      } else if (url.match(/your-projects/)) {
        content = "your_projects";
      } else if (url.match(/\/brand-studio\/brand\/\d+\//)) {
        content = "brand";
      }

      return {
        content,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const setDrawerContent = (contentUrl) => ({
  type: SET_CONTENT,
  payload: contentUrl,
});
