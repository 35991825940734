import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router";
import { cleanProjectTitle } from "project/utils";
import ProjectTags from "common/components/project_tags";
import Breadcrumbs from "project/containers/breadcrumbs";
import HeaderDeadline from "project/containers/header_deadline";
import Tour from "project/containers/tour";

const STATUS_TAGS = (project) => {
  return {
    open: { classes: "tag-highlight success" },
    awarded: { classes: "awarded" },
    completed: { classes: "completed" },
    closed: { classes: "closed" },
    finalist_round: { classes: "final-round", label: "Finalist Round" },
    finalist_closed: { classes: "closed", label: "Finalist Round Closed" },
    "1_1_negotiating": {
      classes: "tag-highlight warning",
      label: "negotiating",
    },
    "1_1_ready": {
      classes: "tag-highlight warning",
      label: "awaiting payment",
    },
  }[project.status];
};

/**
 * Project page specific header title
 *
 * @author Kuba Siemiątkowski <kuba@crowdspring.com>
 * @author Jing Wang <jing@crowdspring.com>
 */
const HeaderProjectInfo = ({
  project,
  category,
  subCategory,
  userId,
  showPromoContent,
  canClose,
  toggleCloseModal,
  titleWords,
  canCloseFinalRound,
  showTour,
}) => {
  if (!project.id) {
    return null;
  }

  const statusTag = STATUS_TAGS(project);
  const statusClasses = statusTag
    ? statusTag.classes || "tag-default"
    : "tag-default";
  const statusLabel = statusTag
    ? statusTag.label || project.status
    : project.status;
  const ownsProject = project.client === userId;

  let subCatLink;
  if (subCategory.deprecated) {
    subCatLink = "/categories/";
  } else if (!project.is_one_to_one) {
    subCatLink = `/${subCategory.slug}/`;
  } else if (!project.mini) {
    subCatLink = "/1to1-projects/";
  }

  return (
    <div className="row header-middle-content project-middle">
      <div className="small-16 medium-10 columns">
        <Breadcrumbs />

        <div className="project-title">
          {ownsProject ? (
            <span className="hide-for-small-only title-description">
              Your <Link to={subCatLink}>{subCategory.name}</Link> project
            </span>
          ) : (
            <span className="hide-for-small-only title-description">
              A <Link to={subCatLink}>{subCategory.name} </Link>
              project by{" "}
              <Link to={`/users/${project.client_username}/`}>
                {project.client_username}
              </Link>
            </span>
          )}
          <h1 className="middle-left-title">
            {cleanProjectTitle(project, titleWords)}
          </h1>
        </div>
      </div>

      {!showPromoContent && (
        <div className="small-16 medium-6 columns project-status-wrapper">
          <span
            className={classNames(`tag tag-sm project-status ${statusClasses}`)}
          >
            {statusLabel}
          </span>

          <ProjectTags
            project={project}
            className="project-tags"
            showColors={false}
          />

          <HeaderDeadline project={project} />

          {(canClose || canCloseFinalRound) && (
            <button
              type="button"
              onClick={toggleCloseModal}
              className="btn btn-text btn-close btn-sm"
            >
              Award and proceed to wrap-up
            </button>
          )}
        </div>
      )}

      {showTour && <Tour />}
    </div>
  );
};

HeaderProjectInfo.propTypes = {
  project: PropTypes.shape({
    status: PropTypes.string,
    title: PropTypes.string,
  }),
  category: PropTypes.object,
  subCategory: PropTypes.object,
  userId: PropTypes.number,
  showPromoContent: PropTypes.bool.isRequired,
  canClose: PropTypes.bool.isRequired,
  canCloseFinalRound: PropTypes.bool.isRequired,
  toggleCloseModal: PropTypes.func.isRequired,
  titleWords: PropTypes.array.isRequired,
  showTour: PropTypes.bool.isRequired,
};

export default HeaderProjectInfo;
