import React from "react";
import { connect } from "react-redux";
import HeaderTitle from "header/components/title";

const mapStateToProps = () => {
  return {
    title: "Releases and Bugfixes",
    subTitle: (
      <span>
        Keep up to date on all significant new features
        <br /> and bugfixes on crowdspring.com
      </span>
    ),
  };
};

export default connect(mapStateToProps)(HeaderTitle);
