import { connect } from "react-redux";
import HeaderTabs from "your_projects/components/header_tabs";

const mapStateToProps = ({ user }) => {
  return {
    user: user.profile_data,
  };
};

export default connect(mapStateToProps)(HeaderTabs);
