import React from "react";
import PropTypes from "prop-types";
import Icon from "common/components/svg_icon";

const SubcategoryIcon = ({ subcat }) => {
  return (
    <Icon
      className="icon"
      loadFn={() =>
        import(`assets/images/icons/pap/PAP_cat_icon_${subcat.slug}.svg`)
      }
    />
  );
};

SubcategoryIcon.propTypes = {
  subcat: PropTypes.object.isRequired,
};

export default SubcategoryIcon;
