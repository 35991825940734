import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { TextInputField } from "common/components/form_fields";

const Form = ({ handleSubmit, submit, label }) => {
  /* prevent propagation of enter key to parent forms, e.g. from groups field to automation form */
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <form onSubmit={handleSubmit} onKeyPress={handleKeyPress}>
      <Field
        name="search"
        label={label || "Search"}
        component={TextInputField}
      />
      <i className="fa fa-search" onClick={submit} />
    </form>
  );
};

export default reduxForm({ enableReinitialize: true })(Form);
