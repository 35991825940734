import React from "react";

// Should match the definitions in app/assets/scss/common/_settings.scss
// see http://foundation.zurb.com/sites/docs/v/5.5.3/media-queries.html
export const MOBILE_BREAKPOINT_PX = 631;
export const TABLET_BREAKPOINT_PX = 1024;
export const DESKTOP_BREAKPOINT_PX = 1200;
export const X_DESKTOP_BREAKPOINT_PX = 1440;

export const PAGINATION_PAGE_SIZE = 24;

export const SCORE_MESSAGES = [
  "VERY BAD",
  "BAD",
  "JUST OKAY",
  "GOOD",
  "VERY GOOD",
];

export const ENTRY_SCALE_LEVELS = 8;

export const PAP14_URL_REGEXP =
  /start-a-project\/.*(brief|details|review|payment)/;
export const PROJECT_URL_REGEXP = /[A-Za-z0-9-]+-[0-9]+/;
export const PROJECT_ENTRIES_URL_REGEXP = /[A-Za-z0-9-]+-[0-9]+\/entries/;
export const PROJECT_WRAPUP_URL_REGEXP = /[A-Za-z0-9-]+-[0-9]+\/wrapup/;
export const PROJECT_FOCUS_GROUP_URL_REGEXP =
  /[A-Za-z0-9-]+-[0-9]+\/focus-groups/;
export const PROJECT_PRESENTATION_URL_REGEXP =
  /[A-Za-z0-9-]+-[0-9]+\/presentations/;
export const PROJECT_SETTINGS_URL_REGEXP = /[A-Za-z0-9-]+-[0-9]+\/settings/;
export const PROJECT_YOUR_ENTRIES_URL_REGEXP =
  /[A-Za-z0-9-]+-[0-9]+\/your-entries/;
export const EXPLORE_URL_REGEXP = /(explore)(\W)?$/;
export const EXPLORE_PROJECTS_URL_REGEXP = /explore/;
export const EXPLORE_CREATIVES_URL_REGEXP = /explore\/creatives/;

/* admin views root URL */
export const ADMIN_ROOT = "/stefan";
export const ADMIN_REGEX = /^\/admin/;

/* error code returned when trying to retrieve a forbidden 1-to-1 project */
export const FORBIDDEN_1_1_CODE = 403010500;
/* error code returned when trying to retrieve a forbidden 1-to-1 project in which
   the current user participated in the negotiation phase, but was not selected */
export const FORBIDDEN_1_1_NOT_SELECTED_CODE = 403010600;
/* error code returned when trying to retrieve a forbidden deleted project */
export const FORBIDDEN_DELETED_CODE = 403010700;
/* error code returned when trying to retrieve a forbidden cancelled project */
export const FORBIDDEN_CANCELLED_CODE = 403010800;
/* error code returned when trying to retrieve a forbidden hidden project */
export const FORBIDDEN_HIDDEN_CODE = 403010900;

/* default entry sorting used by the API (i.e. when no sorting is explicitly set) */
export const DEFAULT_API_ENTRY_SORTING = "-created_at";

export const MARKETING_SEGMENTS = [
  { field: "agency", label: "Agency" },
  { field: "business_owner", label: "Business owner" },
  { field: "product_entrepreneur", label: "Product entrepreneur" },
  { field: "top_n_companies", label: "Top N companies" },
];

export const REPUTATION_EXPLANATION = (
  <span>
    Platinum creatives have a reputation of 80-90,
    <br />
    while Elite creatives have a reputation of 90 to 100.
    <br />
    These are the best, most experienced creatives on
    <br />
    crowdspring, as measured by the quality of their work.
  </span>
);

export const ADDITIONAL_SKILLS = [
  { field: "coding", label: "Coding" },
  { field: "visme", label: "Visme" },
  { field: "canva", label: "Canva" },
];

export const API_DATE_FORMAT = "YYYY-MM-DD";

export const SUBCAT_SEARCH_FUZZY_OPTS = {
  minMatchCharLength: 1,
  threshold: 0.4,
};

export const RECAPTCHA_V3_KEY = {
  development: "6LcxITUpAAAAAFrVfqYmN3I8-hZiFnQFyysG3q29",
  staging: "6LdXQDUpAAAAALZ_9G-_YA2RfbyAu5zwViDohoCb",
  production: "6LcyQTUpAAAAAC5ZPtCYQzRWKR732_LmqInv9YSK",
}[process.env.FRONTEND_ENV];

export const URL_TRUNCATE_LENGTH = 100;

export const ANCHOR_TRUNCATE_LENGTH = 25;

export const MKT_CLIENTS_COUNT = "101,000";

export const MKT_CREATIVES_COUNT = "220,000+";

export const SOCIAL_URLS = {
  twitter: "https://twitter.com/",
  behance: "https://behance.net/",
  dribbble: "https://dribbble.com/",
  facebook: "https://facebook.com/",
  linkedin: "https://linkedin.com/in/",
};

export const SOCIAL_ICONS = {
  twitter: "fa-brands fa-fw fa-x-twitter",
  behance: "fa fa-fw fa-behance",
  dribbble: "fa fa-fw fa-dribbble",
  facebook: "fa fa-fw fa-facebook",
  linkedin: "fa fa-fw fa-linkedin",
  email: "fa fa-fw fa-envelope",
  youtube: "fa fa-fw fa-youtube",
};

export const SUSPENSION_CATEGORY_BEHAVIORAL = "behavioral";
export const SUSPENSION_CATEGORY_DESIGN_BASED = "design_based";
export const SUSPENSION_CATEGORY_GENERIC = "generic";

export const SUSPENSION_CATEGORIES = [
  { label: "Behavioral", field: SUSPENSION_CATEGORY_BEHAVIORAL },
  { label: "Design-based", field: SUSPENSION_CATEGORY_DESIGN_BASED },
  { label: "Generic", field: SUSPENSION_CATEGORY_GENERIC },
];
