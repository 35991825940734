import { withRouter } from "react-router";
import { connect } from "react-redux";
import HeaderTitle from "header/components/title";

const mapStateToProps = () => {
  return {
    title: "Work privately with one creative",
  };
};

export default withRouter(connect(mapStateToProps)(HeaderTitle));
