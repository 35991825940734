import request from "axios";
import idx from "idx";
import pick from "lodash/pick";
import { browserHistory } from "react-router";
import { getServerResponse } from "common/server/ducks";
import { showBanner, TYPE_SUCCESS } from "common/ducks/banner";
import { setBannerError } from "error";

/* ACTIONS
================================================================================================ */
const FETCH_PROFILE = "profile/common/FETCH_PROFILE";
const FETCH_PROFILE_REQUEST = "profile/common/FETCH_PROFILE_REQUEST";
const FETCH_PROFILE_SUCCESS = "profile/common/FETCH_PROFILE_SUCCESS";

const TOGGLE_ACTION_CONFIRMATION = "profile/common/TOGGLE_ACTION_CONFIRMATION";

const UPDATE_PROFILE = "profile/common/UPDATE_PROFILE";
const UPDATE_PROFILE_SUCCESS = "profile/common/UPDATE_PROFILE_SUCCESS";

/* HELPERS
================================================================================================ */

/* INITIAL STATES
================================================================================================ */
const INITIAL_STATE = {};

/* REDUCERS
================================================================================================ */
export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_PROFILE_REQUEST:
      return {
        ...state,
        profile: null,
      };
    case FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
      };
    case TOGGLE_ACTION_CONFIRMATION:
      return {
        ...state,
        actionConfirmationInfo: action.info,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...pick(action.payload, action.fields),
        },
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const fetchProfileIfNeeded = (username) => (dispatch, getState) => {
  if (idx(getState().profile.common.profile, (_) => _.username) === username) {
    return Promise.resolve();
  }

  const promise = request.get(`/api/v1/user-profiles/${username}/`);

  promise
    .then((res) => {
      const serverRes = getServerResponse(getState());
      const inactiveUrl = "/inactive-user/";

      if (res.data.is_active) {
        return;
      }

      /* client-side */
      if (browserHistory) {
        browserHistory.replace(inactiveUrl);
        /* server-side */
      } else {
        serverRes.redirect(301, inactiveUrl);
        serverRes.end();
      }
    })
    .catch((res) => {
      /* Handle missing profile.
       In case `browserHistory` is not available, it means server-rendering is being performed
       and the 404 error will be handled on the request level.
       Otherwise, user is redirected to /404. */
      if (res.status === 404 && browserHistory) {
        browserHistory.replace("/404/");
      }
    });

  dispatch({
    type: FETCH_PROFILE,
    promise,
  });

  return promise;
};

export const toggleActionConfirmation = (info) => (dispatch) => {
  return dispatch({
    type: TOGGLE_ACTION_CONFIRMATION,
    info,
  });
};

export const showSuccessBanner = () => (dispatch) => {
  dispatch(
    showBanner(
      "Updated!",
      "Your profile was successfully updated.",
      undefined,
      TYPE_SUCCESS
    )
  );
};

export const showErrorBanner = (err, errDetails) => (dispatch) => {
  dispatch(
    setBannerError(
      err || "Error updating profile",
      errDetails || "Please try again."
    )
  );
};

export const updateProfile = (data) => (dispatch, getState) => {
  const { profile } = getState().profile.common;
  const promise = request.patch(`/api/v1/users/${profile.id}/`, data);

  promise
    .then(() => dispatch(showSuccessBanner()))
    .catch((err) => {
      dispatch(
        showErrorBanner(null, err.data ? JSON.stringify(err.data) : null)
      );
    });

  dispatch({
    type: UPDATE_PROFILE,
    fields: Object.keys(data),
    promise,
  });

  return promise;
};
