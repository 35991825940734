import request from "axios";
import { DEFAULT_PAGINATION_PAGE_SIZE } from "common/pager/constants";

/* ACTIONS
================================================================================================ */
export const FETCH_ALL = "crowdspring/product_updates/FETCH_ALL";
export const FETCH_ALL_REQUEST =
  "crowdspring/product_updates/FETCH_ALL_REQUEST";
export const FETCH_ALL_SUCCESS =
  "crowdspring/product_updates/FETCH_ALL_SUCCESS";

/* HELPERS
================================================================================================ */

/* INITIAL STATES
================================================================================================ */
export const initialState = {
  items: [],
  isFetching: false,
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_ALL_SUCCESS:
      return {
        ...state,
        items: action.payload,
        isFetching: false,
      };
    default:
      return state;
  }
}

export const fetchAll = (userType) => (dispatch) => {
  return dispatch({
    type: FETCH_ALL,
    promise: request.get(`/api/v1/updates/${userType}_updates/`),
  });
};
