import TabbedNavigation from "common/components/tabbed_navigation";
import { withRouter } from "react-router";
import { push } from "react-router-redux";
import { connect } from "react-redux";

export const aboutTabs = {
  links: [
    {
      url: "/about-us/",
      matchExact: true,
      name: "About",
    },
    {
      url: "/about-us/team/",
      matchExact: true,
      name: "Team",
    },
    {
      url: "/jobs/",
      name: "Jobs",
    },
    {
      url: "/press/",
      name: "Press",
    },
    {
      url: "/partners/",
      name: "Partners",
    },
    {
      url: "/affiliates/",
      name: "Affiliates",
    },
    {
      url: "/give-back/",
      name: "Give Back",
    },
  ],
};

function mapStateToProps(state, { router }) {
  return {
    tabs: aboutTabs,
    router,
  };
}

export default withRouter(connect(mapStateToProps, { push })(TabbedNavigation));
