import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import idx from "idx";
import partial from "lodash/partial";
import { push } from "react-router-redux";
import { fetchBrands, clearBrands } from "brand_studio/ducks";
import HeaderTabs from "brand_studio/components/home/header_tabs";

export const getHomeTabs = ({ brandId }) => {
  let links = [];

  if (brandId) {
    const baseUrl = `/brand-studio/brand/${brandId}/`;
    links = links.concat([
      { url: `${baseUrl}templates/`, name: "Templates" },
      { url: `${baseUrl}designs/`, name: "Designs" },
      { url: baseUrl, matchExact: true, name: "Brand Library" },
    ]);
  }
  links = links.concat([
    { url: "/brand-studio/brands/", name: "Brands" },
    { url: "/brand-studio/refer-a-friend/", name: "Refer a friend" },
  ]);

  return { links };
};

const mapStateToProps = ({ brandStudio }, { router }) => {
  const { brands } = brandStudio;

  return {
    router,
    push,
    tabs: getHomeTabs({ brandId: idx(brands, (_) => _[0].id) }),
  };
};

const mapDispatchToProps = (dispatch, { location, params }) => {
  return bindActionCreators(
    {
      fetch: partial(fetchBrands, location.query),
      clear: clearBrands,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderTabs)
);
