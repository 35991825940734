import React from "react";
import PropTypes from "prop-types";
import Filter from "common/filter/containers";
import { TYPE_CHECKBOXES } from "common/filter/constants";
import { DESIGN_CATEGORY_OPTIONS } from "brand_studio/constants";

const Controls = ({ resourceName, skipQuerySave }) => {
  if (!resourceName) {
    return null;
  }

  /* this component is rendered in 2 places, one for mobile and another for desktop */
  const mobileVersion = skipQuerySave;

  return (
    <aside className="sidebar sidebar-filter">
      <div
        className="controls-group"
        id={mobileVersion ? undefined : "controls_group_category"}
      >
        <div className="group-title">Category</div>
        <Filter
          field="category"
          type={TYPE_CHECKBOXES}
          resourceName={resourceName}
          options={DESIGN_CATEGORY_OPTIONS}
          skipQuerySave={skipQuerySave}
        />
      </div>
    </aside>
  );
};

Controls.propTypes = {
  resourceName: PropTypes.string,
  skipQuerySave: PropTypes.bool,
};

export default Controls;
