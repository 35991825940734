import { getVariationName } from "firefly/utils";

export const EXPERIMENTS_RESOURCE_NAME = "experiments";

export const GROUPS_RESOURCE_NAME = "experiment_groups";

export const VISITORS_EXPLANATION =
  "Number of unique visitors that viewed the experiment.";
export const VIEWS_EXPLANATION =
  "Number of experiment views. The same visitor can view an experiment multiple times.";
export const CONVERSIONS_EXPLANATION =
  "Number of unique visitors that converted.";
export const CONV_RATE_EXPLANATION =
  "Percentage of unique visitors that converted.";
export const PROB_TO_BE_BEST_EXPLANATION =
  "Probability to be the best among all variations and original.";
export const PROB_TO_BEAT_ORIG_EXPLANATION =
  "Probability to be better than the original.";
export const RISK_EXPLANATION =
  "If chosen and actually worse, the decline expected in conversions.";
export const UPLIFT_EXPLANATION =
  "Increase in conversions compared to the original.";
export const UPLIFT_INTERVAL_EXPLANATION =
  "95% confidence interval for the uplift.";

export const GROUP_METRICS_FIELDS = [
  {
    name: "count_visitors_total",
    label: "Visitors",
    alwaysVisible: true,
    tooltipContent: VISITORS_EXPLANATION,
  },
  {
    name: "count_views_total",
    label: "Views",
    alwaysVisible: true,
    tooltipContent: VIEWS_EXPLANATION,
  },
];

export const GROUPS_PER_EXPERIMENT = 3;

export const GOALS_PER_EXPERIMENT = 3;

export const GOAL_OPTIONS = [
  { field: "register_as_client", label: "Visitor registers as a client" },
  { field: "create_draft", label: "Visitor creates a draft project" },
  { field: "post_project", label: "Visitor posts a project" },
];

export const ANALYTICS_API_ENDPOINT = (experiment) =>
  `/api/v1/experiments/${experiment.id}/analytics/`;

export const VARIATION_COLORS = [
  "#34495e",
  "#3498db",
  "#e74c3c",
  "#2ecc71",
  "#9b59b6",
  "#e67e22",
  "#1abc9c",
  "#f1c40f",
  "#95a5a6",
  "#3f51b5",
  "#00bcd4",
  "#ff6b81",
  "#006400",
  "#ff6b6b",
];

export const COLORS_MAP = {
  Total: "#ffc107",
  ...VARIATION_COLORS.reduce(
    (acc, color, i) => ({
      ...acc,
      [getVariationName({}, i, true)]: color,
    }),
    {}
  ),
};
