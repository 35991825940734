import React from "react";
import PropTypes from "prop-types";
import omit from "lodash/omit";
import { Link } from "react-router";
import { getProjectUrl } from "project/utils";

const ProjectLink = ({
  project,
  subcategory,
  append = "",
  linkTitle = null,
  children,
  search,
  ...rest
}) => {
  const restProps = omit(rest, ["dispatch", "entry"]);
  return (
    <Link
      to={getProjectUrl(project, subcategory, append) + (search || "")}
      {...restProps}
    >
      {children || linkTitle || project.title}
    </Link>
  );
};

ProjectLink.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number,
    slug: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  subcategory: PropTypes.string.isRequired,
  linkTitle: PropTypes.string,
  append: PropTypes.string,
  children: PropTypes.any,
};

export default ProjectLink;
