import request from "axios";
import { browserHistory } from "react-router";
import queryString from "query-string";
import { getAPIQueryString } from "common/utils";
import {
  PORTFOLIO_IMAGES_RESOURCE_NAME,
  IMAGE_ENTRIES_RESOURCE_NAME,
  PORTFOLIO_ENTRIES_PAGE_SIZE,
} from "profile/constants";
import { showErrorBanner } from "profile/ducks/common";
import { DEFAULT_PAGINATION_PAGE_SIZE } from "common/pager/constants";
import { savePageCount } from "common/pager/ducks";

/* ACTIONS
================================================================================================ */
const FETCH_IMAGES = "profile/portfolio/FETCH_IMAGES";
const FETCH_IMAGES_REQUEST = "profile/portfolio/FETCH_IMAGES_REQUEST";
const FETCH_IMAGES_SUCCESS = "profile/portfolio/FETCH_IMAGES_SUCCESS";

const FETCH_NAMES = "profile/portfolio/FETCH_NAMES";
const FETCH_NAMES_REQUEST = "profile/portfolio/FETCH_NAMES_REQUEST";
const FETCH_NAMES_SUCCESS = "profile/portfolio/FETCH_NAMES_SUCCESS";

const DELAY_ACTION_START = "profile/portfolio/DELAY_ACTION_START";
const DELAY_ACTION_END = "profile_portfolio/DELAY_ACTION_END";

const SET_ORDER_IMAGES = "profile/portfolio/SET_ORDER_IMAGES";

const SET_ORDER_NAMES = "profile/portfolio/SET_ORDER_NAMES";

const CLEAR_IMAGES = "profile/portfolio/CLEAR_IMAGES";

const CLEAR_NAMES = "profile/portfolio/CLEAR_NAMES";

const FETCH_IMAGE = "profile/portfolio/FETCH_IMAGE";
const FETCH_IMAGE_REQUEST = "profile/portfolio/FETCH_IMAGE_REQUEST";
const FETCH_IMAGE_SUCCESS = "profile/portfolio/FETCH_IMAGE_SUCCESS";

const FETCH_NAME = "profile/portfolio/FETCH_NAME";
const FETCH_NAME_REQUEST = "profile/portfolio/FETCH_NAME_REQUEST";
const FETCH_NAME_SUCCESS = "profile/portfolio/FETCH_NAME_SUCCESS";

const FETCH_PARENT_IMAGES_PAGE = "profile/portfolio/FETCH_PARENT_IMAGES_PAGE";
const FETCH_PARENT_IMAGES_PAGE_SUCCESS =
  "profile/portfolio/FETCH_PARENT_IMAGES_PAGE_SUCCESS";

const FETCH_PARENT_NAMES_PAGE = "profile/portfolio/FETCH_PARENT_NAMES_PAGE";
const FETCH_PARENT_NAMES_PAGE_SUCCESS =
  "profile/portfolio/FETCH_PARENT_NAMES_PAGE_SUCCESS";

const CLEAR_PARENT_IMAGES_PAGE = "profile/portfolio/CLEAR_PARENT_IMAGES_PAGE";

const CLEAR_PARENT_NAMES_PAGE = "profile/portfolio/CLEAR_PARENT_NAMES_PAGE";

const FETCH_IMAGE_ENTRIES = "profile/portfolio/FETCH_IMAGE_ENTRIES";
const FETCH_IMAGE_ENTRIES_REQUEST =
  "profile/portfolio/FETCH_IMAGE_ENTRIES_REQUEST";
const FETCH_IMAGE_ENTRIES_SUCCESS =
  "profile/portfolio/FETCH_IMAGE_ENTRIES_SUCCESS";

const FETCH_NAME_ENTRIES = "profile/portfolio/FETCH_NAME_ENTRIES";
const FETCH_NAME_ENTRIES_REQUEST =
  "profile/portfolio/FETCH_NAME_ENTRIES_REQUEST";
const FETCH_NAME_ENTRIES_SUCCESS =
  "profile/portfolio/FETCH_NAME_ENTRIES_SUCCESS";

/* HELPERS
================================================================================================ */
const DELAY_INTERVAL = 2000;

const processParentPageData = (action) => {
  const updatedState = {
    previous: null,
    next: null,
    current: null,
    totalCount: 0,
  };
  const currentRelativeIdx = action.payload.results.findIndex(
    (u) => u.id === parseInt(action.itemId)
  );

  if (currentRelativeIdx < 0) {
    return updatedState;
  }

  updatedState.totalCount = action.payload.count;

  updatedState.current = {
    idx: action.offset + currentRelativeIdx,
  };

  if (currentRelativeIdx > 0) {
    updatedState.previous = {
      id: action.payload.results[currentRelativeIdx - 1].id,
      idx: updatedState.current.idx - 1,
    };
  }

  if (currentRelativeIdx < action.payload.results.length - 1) {
    updatedState.next = {
      id: action.payload.results[currentRelativeIdx + 1].id,
      idx: updatedState.current.idx + 1,
    };
  }

  return updatedState;
};

const formatEntries = (entries) => {
  /* make sure revisions are ungrouped */
  return entries.reduce((acc, entry) => {
    entry.revisions.forEach(
      (rev) => (acc = acc.concat({ ...entry, revisions: [rev] }))
    );
    return acc;
  }, []);
};

/* INITIAL STATES
================================================================================================ */
const initialState = {
  images: [],
  imageCount: 0,
  isFetchingImages: false,

  names: [],
  nameCount: 0,
  isFetchingNames: false,

  imageEntries: [],
  imageEntryCount: [],
  isFetchingImageEntries: false,

  nameEntries: [],
  nameEntryCount: [],
  isFetchingNameEntries: false,
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_IMAGES_REQUEST:
      return {
        ...state,
        isFetchingImages: true,
      };
    case FETCH_IMAGES_SUCCESS:
      return {
        ...state,
        images: state.images.concat(action.payload.results),
        imageCount: action.payload.count,
        isFetchingImages: false,
      };
    case FETCH_NAMES_REQUEST:
      return {
        ...state,
        isFetchingNames: true,
      };
    case FETCH_NAMES_SUCCESS:
      return {
        ...state,
        names: state.names.concat(action.payload.results),
        nameCount: action.payload.count,
        isFetchingNames: false,
      };
    case DELAY_ACTION_START:
      return {
        ...state,
        isFetchingImages:
          action.resourceName === PORTFOLIO_IMAGES_RESOURCE_NAME
            ? true
            : state.isFetchingImages,
        isFetchingNames:
          action.resourceName === PORTFOLIO_IMAGES_RESOURCE_NAME
            ? state.isFetchingNames
            : true,
      };
    case DELAY_ACTION_END:
      return {
        ...state,
        isFetchingImages:
          action.resourceName === PORTFOLIO_IMAGES_RESOURCE_NAME
            ? false
            : state.isFetchingImages,
        isFetchingNames:
          action.resourceName === PORTFOLIO_IMAGES_RESOURCE_NAME
            ? state.isFetchingNames
            : false,
      };
    case SET_ORDER_IMAGES:
      return {
        ...state,
        images: action.payload,
      };
    case SET_ORDER_NAMES:
      return {
        ...state,
        names: action.payload,
      };
    case CLEAR_IMAGES:
      return {
        ...state,
        images: [],
        imageCount: 0,
      };
    case CLEAR_NAMES:
      return {
        ...state,
        names: [],
        nameCount: 0,
      };
    case FETCH_IMAGE_REQUEST:
      return {
        ...state,
        image: null,
      };
    case FETCH_IMAGE_SUCCESS:
      return {
        ...state,
        image: action.payload,
      };
    case FETCH_NAME_REQUEST:
      return {
        ...state,
        name: null,
      };
    case FETCH_NAME_SUCCESS:
      return {
        ...state,
        name: action.payload,
      };
    case FETCH_PARENT_IMAGES_PAGE_SUCCESS:
      return {
        ...state,
        imagePageInfo: processParentPageData(action),
      };
    case FETCH_PARENT_NAMES_PAGE_SUCCESS:
      return {
        ...state,
        namePageInfo: processParentPageData(action),
      };
    case CLEAR_PARENT_IMAGES_PAGE:
      return {
        ...state,
        imagePageInfo: null,
      };
    case CLEAR_PARENT_NAMES_PAGE:
      return {
        ...state,
        namePageInfo: null,
      };
    case FETCH_IMAGE_ENTRIES_REQUEST:
      return {
        ...state,
        isFetchingImageEntries: true,
      };
    case FETCH_IMAGE_ENTRIES_SUCCESS:
      return {
        ...state,
        imageEntries: formatEntries(action.payload.results),
        imageEntryCount: action.payload.count,
        isFetchingImageEntries: false,
      };
    case FETCH_NAME_ENTRIES_REQUEST:
      return {
        ...state,
        isFetchingNameEntries: true,
      };
    case FETCH_NAME_ENTRIES_SUCCESS:
      return {
        ...state,
        nameEntries: formatEntries(action.payload.results),
        nameEntryCount: action.payload.count,
        isFetchingNameEntries: false,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const fetchAll = (resourceName, query) => (dispatch, getState) => {
  const { profile } = getState().profile.common;
  const { imageCount, nameCount, images, names } = getState().profile.portfolio;
  const isImage = resourceName === PORTFOLIO_IMAGES_RESOURCE_NAME;
  const count = isImage ? imageCount : nameCount;
  const items = isImage ? images : names;

  if (items.length > 0 && items.length >= count) {
    return Promise.resolve();
  }

  const extraAPIQuery = {
    limit: DEFAULT_PAGINATION_PAGE_SIZE,
    offset:
      Math.ceil(items.length / DEFAULT_PAGINATION_PAGE_SIZE) *
      DEFAULT_PAGINATION_PAGE_SIZE,
  };
  const queryStr = getAPIQueryString(resourceName, query, { extraAPIQuery });
  const promise = request.get(
    `/api/v1/users/${profile.username}/portfolio_${
      isImage ? "images" : "names"
    }/?${queryStr}`
  );

  return dispatch({
    type: isImage ? FETCH_IMAGES : FETCH_NAMES,
    promise,
  });
};

export const clearAll = (resourceName) => (dispatch) => {
  const isImage = resourceName === PORTFOLIO_IMAGES_RESOURCE_NAME;
  return dispatch({
    type: isImage ? CLEAR_IMAGES : CLEAR_NAMES,
  });
};

const delayAction =
  (resourceName, action, interval = DELAY_INTERVAL) =>
  (dispatch) => {
    dispatch({
      type: DELAY_ACTION_START,
      resourceName,
    });

    setTimeout(() => {
      dispatch({
        type: DELAY_ACTION_END,
        resourceName,
      });

      action();
    }, interval);
  };

export const remove = (id, resourceName, query) => (dispatch, getState) => {
  const { profile } = getState().profile.common;
  const isImage = resourceName === PORTFOLIO_IMAGES_RESOURCE_NAME;
  const promise = request.delete(
    `/api/v1/users/${profile.username}/portfolio_${
      isImage ? "images" : "names"
    }/${id}/`
  );

  promise
    .then(() => {
      dispatch(clearAll(resourceName));
      /* give ElasticSearch enough time to reflect the changes */
      dispatch(
        delayAction(resourceName, () => dispatch(fetchAll(resourceName, query)))
      );
    })
    .catch(() => {
      dispatch(showErrorBanner("Error deleting item"));
    });

  return promise;
};

export const setOrder = (resourceName, collection) => (dispatch, getState) => {
  const { profile } = getState().profile.common;
  const updatedAll = collection.map((i) => i.value);
  const updatedIds = updatedAll.map((i) => i.id);
  const isImage = resourceName === PORTFOLIO_IMAGES_RESOURCE_NAME;

  dispatch({
    type: isImage ? SET_ORDER_IMAGES : SET_ORDER_NAMES,
    payload: updatedAll,
  });

  /* wait 2s to send updates to the backend */
  if (window.orderTimer) {
    clearTimeout(window.orderTimer);
  }
  window.orderTimer = setTimeout(() => {
    request
      .patch(
        `/api/v1/users/${profile.username}/portfolio_${
          isImage ? "images" : "names"
        }/set_order/`,
        { ids: updatedIds }
      )
      .catch(() => dispatch(showErrorBanner("Error setting items' order")));
  }, 2000);
};

export const fetch = (id, resourceName) => (dispatch, getState) => {
  const { profile } = getState().profile.common;
  const isImage = resourceName === PORTFOLIO_IMAGES_RESOURCE_NAME;
  const promise = request.get(
    `/api/v1/users/${profile.username}/portfolio_${
      isImage ? "images" : "names"
    }/${id}/`
  );

  return dispatch({
    type: isImage ? FETCH_IMAGE : FETCH_NAME,
    promise,
  });
};

export const fetchParentPage =
  (resourceName, itemId, itemIdx, listQuery) => (dispatch, getState) => {
    const { profile } = getState().profile.common;
    const isImage = resourceName === PORTFOLIO_IMAGES_RESOURCE_NAME;
    /* get current item plus previous and next ones */
    const offset = itemIdx > 0 ? itemIdx - 1 : 0;
    const limit = 3;
    const query = queryString.stringify({
      ...listQuery,
      offset,
      limit,
    });

    return dispatch({
      type: isImage ? FETCH_PARENT_IMAGES_PAGE : FETCH_PARENT_NAMES_PAGE,
      promise: request.get(
        `/api/v1/users/${profile.username}/portfolio_${
          isImage ? "images" : "names"
        }/?${query}`
      ),
      itemId,
      offset,
    });
  };

export const clearParentPage = (resourceName) => (dispatch) => {
  const isImage = resourceName === PORTFOLIO_IMAGES_RESOURCE_NAME;
  return dispatch({
    type: isImage ? CLEAR_PARENT_IMAGES_PAGE : CLEAR_PARENT_NAMES_PAGE,
  });
};

export const fetchEntries = (query, resourceName) => (dispatch, getState) => {
  const { profile } = getState().profile.common;
  const isImage = resourceName === IMAGE_ENTRIES_RESOURCE_NAME;
  const queryStr = getAPIQueryString(resourceName, query, {
    pageSize: PORTFOLIO_ENTRIES_PAGE_SIZE,
  });
  const promise = request.get(
    `/api/v1/users/${profile.username}/portfolio_${
      isImage ? "images" : "names"
    }/available/?${queryStr}`
  );

  /* save page count in pager state */
  promise.then((res) => dispatch(savePageCount(resourceName, res)));

  return dispatch({
    type: isImage ? FETCH_IMAGE_ENTRIES : FETCH_NAME_ENTRIES,
    promise,
  });
};

export const create = (data, resourceName) => (dispatch, getState) => {
  const { profile } = getState().profile.common;
  const isImage = resourceName === PORTFOLIO_IMAGES_RESOURCE_NAME;
  const promise = request.post(
    `/api/v1/users/${profile.username}/portfolio_${
      isImage ? "images" : "names"
    }/`,
    data
  );

  promise
    .then(() => {
      dispatch(clearAll(resourceName));
      /* give ElasticSearch enough time to reflect the changes */
      dispatch(
        delayAction(resourceName, () =>
          browserHistory.push(
            `/users/${profile.username}/${isImage ? "" : "names/"}`
          )
        )
      );
    })
    .catch(() => {
      dispatch(showErrorBanner("Error adding item"));
    });

  return promise;
};
