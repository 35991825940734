import request from "axios";
import { REVIEWS_RESOURCE_NAME } from "admin/constants";
import { getAPIQueryString } from "common/utils";
import { savePageCount } from "common/pager/ducks";

/* ACTIONS
================================================================================================ */
export const FETCH_ALL = "crowdspring/admin/FETCH_REVIEWS";
export const FETCH_ALL_REQUEST = "crowdspring/admin/FETCH_REVIEWS_REQUEST";
export const FETCH_ALL_SUCCESS = "crowdspring/admin/FETCH_REVIEWS_SUCCESS";

export const FETCH_STATS = "crowdspring/admin/FETCH_REVIEWS_STATS";
export const FETCH_STATS_SUCCESS =
  "crowdspring/admin/FETCH_REVIEWS_STATS_SUCCESS";

export const FETCH = "crowdspring/admin/FETCH_REVIEW";
export const FETCH_SUCCESS = "crowdspring/admin/FETCH_REVIEW_SUCCESS";

/* HELPERS
================================================================================================ */
export const approveRequest = (id, data) =>
  request.put(`/api/v1/csratings/${id}/approve/`, data);

/* INITIAL STATES
================================================================================================ */
export const initialState = {
  list: [],
  isFetching: false,
  reviewCount: 0,
  stats: {},
  currentReview: null,
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_REQUEST:
      return {
        ...state,
        queryStr: action.queryStr,
        isFetching: true,
      };
    case FETCH_ALL_SUCCESS:
      /* avoid wrong results due to parallel requests */
      if (action.queryStr !== state.queryStr) {
        return state;
      }

      return {
        ...state,
        isFetching: false,
        list: action.payload.results,
        reviewCount: action.payload.count,
      };
    case FETCH_STATS_SUCCESS:
      return {
        ...state,
        stats: action.payload,
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        currentReview: action.payload,
      };
    default:
      return state;
  }
}

export const fetchAll = (query) => (dispatch) => {
  const extraAPIQuery = {
    admin_preset: query.preset,
  };
  const queryStr = getAPIQueryString(REVIEWS_RESOURCE_NAME, query, {
    extraAPIQuery,
  });
  const promise = request.get(`/api/v1/csratings/?${queryStr}`);

  /* save page count in pager state */
  promise.then((res) => dispatch(savePageCount(REVIEWS_RESOURCE_NAME, res)));

  return dispatch({
    type: FETCH_ALL,
    queryStr,
    promise,
  });
};

export const fetchStats = () => (dispatch) =>
  dispatch({
    type: FETCH_STATS,
    promise: request.get("/api/v1/csratings/admin_stats/"),
  });

export const fetch = (id) => (dispatch) =>
  dispatch({
    type: FETCH,
    promise: request.get(`/api/v1/csratings/${id}/`),
  });
