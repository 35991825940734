import { connect } from "react-redux";
import PropTypes from "prop-types";
import RightDrawerComponent from "common/components/base_ui/right_drawer_component";

const mapStateToProps = (state) => {
  return {
    contentType: state.rightDrawer.content,
  };
};

export default connect(mapStateToProps)(RightDrawerComponent);
