import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Link } from "react-router";

/**
 * Header title component
 * @author Kuba Siemiątkowski <kuba@crowdspring.com>
 */
const HeaderTitle = ({
  title,
  subTitle,
  cta,
  isTitleMain = false,
  children,
  preTitle,
}) => {
  const titleClasses = classnames("small-16 column", { "medium-12": cta });

  return (
    <div className="row header-middle-content hide-for-small-only">
      <div className={titleClasses}>
        {preTitle}

        {isTitleMain ? (
          <h1 className="middle-left-title">{title}</h1>
        ) : (
          <h2 className="middle-left-title">{title}</h2>
        )}

        {subTitle && <h3 className="middle-left-subtitle">{subTitle}</h3>}

        {children}
      </div>

      {cta && (
        <div className="small-16 medium-4 column middle-right-action">
          <Link className="btn btn-primary" to={cta.url}>
            {cta.label}
          </Link>
        </div>
      )}
    </div>
  );
};

HeaderTitle.propTypes = {
  title: PropTypes.string,
  preTitle: PropTypes.object,
  isTitleMain: PropTypes.bool,
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  cta: PropTypes.shape({
    url: PropTypes.string,
    label: PropTypes.string,
  }),
  children: PropTypes.node,
};

export default HeaderTitle;
