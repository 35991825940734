import { connect } from "react-redux";
import { withRouter } from "react-router";
import { saveLastQuery } from "common/filter/ducks";
import { getValueFromQuery } from "common/filter/utils";
import Filter from "common/filter/components";

export const mapStateToProps = (
  state,
  { resourceName, field, type, location }
) => {
  return {
    value: getValueFromQuery(resourceName, field, type, location.query),
  };
};

export default withRouter(connect(mapStateToProps, { saveLastQuery })(Filter));
