import { combineReducers } from "redux";
import commonReducer from "profile/ducks/common";
import imageEntriesReducer from "profile/ducks/image_entries";
import nameEntriesReducer from "profile/ducks/name_entries";
import testimonialsReducer from "profile/ducks/testimonials";
import clientProjectsReducer from "profile/ducks/client_projects";
import portfolioReducer from "profile/ducks/portfolio";

const rootReducer = combineReducers({
  common: commonReducer,
  imageEntries: imageEntriesReducer,
  nameEntries: nameEntriesReducer,
  testimonials: testimonialsReducer,
  clientProjects: clientProjectsReducer,
  portfolio: portfolioReducer,
});

export default rootReducer;
