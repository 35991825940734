/* ACTIONS
 ================================================================================================ */
export const CLIENT_INIT = "crowdspring/server/INIT";

/* INITIAL STATE
 ================================================================================================ */
const initialState = {
  req: null,
  res: null,
};

export const getServerResponse = ({ server }) => server.res;

/* REDUCERS
 ================================================================================================ */
export default function serverReducer(state = initialState, action) {
  switch (action.type) {
    case CLIENT_INIT:
      return {
        req: null,
        res: null,
      };
    default:
      return state;
  }
}
