import { connect } from "react-redux";
import { withRouter } from "react-router";
import { push } from "react-router-redux";
import ProjectTypeTabs from "your_projects/components/project_type_tabs";

const mapStateToProps = ({ yourProjects }) => {
  return {
    counts: yourProjects.counts,
  };
};

export default withRouter(connect(mapStateToProps, { push })(ProjectTypeTabs));
