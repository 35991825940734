import request from "axios";
import cookie from "react-cookie";
import { setBannerError } from "error";

export const SEND_CONTACT = "crowdspring/help/SEND_CONTACT";
export const SEND_CONTACT_REQUEST = "crowdspring/help/SEND_CONTACT_REQUEST";
export const SEND_CONTACT_FAILURE = "crowdspring/help/SEND_CONTACT_FAILURE";
export const SEND_CONTACT_SUCCESS = "crowdspring/help/SEND_CONTACT_SUCCESS";

const checkBlockStatus = (dispatch, getState) => {
  const user = getState().user.profile_data;

  /* ignore creatives */
  if (user.is_creative || !cookie.load("contact_blocked")) {
    return false;
  }

  dispatch(
    setBannerError(
      "We noticed suspicious activity and blocked your access.",
      "You can try again in 24 hours."
    )
  );

  return true;
};

const setBlockStatus = (getState) => {
  const user = getState().user.profile_data;

  /* ignore creatives */
  if (user.is_creative) {
    return;
  }

  /* if more than 2 requests are made in a 5 minute window, block for 24 hours */
  let name, maxAge;
  if (
    cookie.load("contact_blocked") ||
    (cookie.load("contact_strike_1") && cookie.load("contact_strike_2"))
  ) {
    name = "contact_blocked";
    maxAge = 24 * 60 * 60;
  } else {
    maxAge = 5 * 60;
    if (cookie.load("contact_strike_1")) {
      name = "contact_strike_2";
    } else {
      name = "contact_strike_1";
    }
  }

  cookie.save(name, "true", { path: "/", maxAge });
};

export function sendContact(data) {
  const opts = {
    method: "post",
    url: "/api/v1/contact/",
    data,
  };

  return (dispatch, getState) => {
    if (checkBlockStatus(dispatch, getState)) {
      return;
    }

    dispatch({ type: SEND_CONTACT_REQUEST });

    request(opts)
      .then(() => {
        setBlockStatus(getState);

        return dispatch({ type: SEND_CONTACT_SUCCESS });
      })
      .catch(() => dispatch({ type: SEND_CONTACT_FAILURE }));
  };
}

export function sendAgencyContact(data) {
  const opts = {
    method: "post",
    url: "/api/v1/contact-agencies/",
    data,
  };

  return (dispatch, getState) => {
    if (checkBlockStatus(dispatch, getState)) {
      return;
    }

    dispatch({ type: SEND_CONTACT_REQUEST });

    request(opts)
      .then(() => {
        setBlockStatus(getState);

        return dispatch({ type: SEND_CONTACT_SUCCESS });
      })
      .catch(() => dispatch({ type: SEND_CONTACT_FAILURE }));
  };
}

export const INITIAL_STATE = {
  isLoading: false,
  error: null,
  failure: false,
  success: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SEND_CONTACT_REQUEST:
      return {
        ...state,
        data: null,
        isLoading: true,
      };
    case SEND_CONTACT_SUCCESS:
      return {
        ...state,
        success: true,
        failure: false,
        isLoading: false,
      };
    case SEND_CONTACT_FAILURE:
      return {
        ...state,
        error: action.payload,
        failure: true,
        isLoading: false,
        success: false,
      };
    default:
      return state;
  }
}
