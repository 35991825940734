import React from "react";
import PropTypes from "prop-types";
import HeaderTitle from "header/components/title";
import { EXPIRATION_IN_DAYS } from "notifications/constants";

const NotificationsHeaderTitle = () => (
  <HeaderTitle
    title="Account Notifications"
    subTitle={`Notifications are deleted after ${EXPIRATION_IN_DAYS} days.`}
  />
);

export default NotificationsHeaderTitle;
