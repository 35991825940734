import React from "react";
import moment from "moment";
import { Link } from "react-router";
import Image from "common/components/image";
import { SOCIAL_ICONS } from "common/variables";

const Footer = () => (
  <footer className="l-footer">
    <div className="row">
      <div className="columns small-16">
        <nav className="primary-nav">
          <div className="group">
            <div className="group-title">Categories</div>
            <ul>
              <li>
                <Link to="/logo-design/">Logo design</Link>
              </li>
              <li>
                <Link to="/naming-a-business-or-product/">Business names</Link>
              </li>
              <li>
                <Link to="/business-card-design/">Business card design</Link>
              </li>
              <li>
                <Link to="/website-design/">Website design</Link>
              </li>
              <li>
                <Link to="/package-graphics/">Package graphics</Link>
              </li>
              <li>
                <Link to="/packaging-design/">Packaging design</Link>
              </li>
              <li>
                <Link to="/product-design/">Product design</Link>
              </li>
              <li>
                <Link to="/t-shirt-design/">T-shirt design</Link>
              </li>
              <li>
                <Link to="/book-cover-design/">Book cover design</Link>
              </li>
              <li>
                <Link to="/categories/">Browse all categories</Link>
              </li>
            </ul>
          </div>
          <div className="group">
            <div className="group-title">Product</div>
            <ul>
              <li>
                <Link to="/how-it-works/">Design contests</Link>
              </li>
              <li>
                <Link to="/1to1-projects/">1-to-1 projects</Link>
              </li>
              <li>
                <Link to="/how-it-works/">How it Works</Link>
              </li>
              <li>
                <Link to="/pricing/">Pricing</Link>
              </li>
              <li>
                <Link to="/agencies/">Agencies</Link>
              </li>
              <li>
                <Link to="/agency-resources/">Agency Resources</Link>
              </li>
              <li>
                <Link to="/help/">Help center</Link>
              </li>
              <li>
                <Link to="/guarantee/">Guarantee</Link>
              </li>
              <li>
                <Link to="/trust-and-security/">Trust and security</Link>
              </li>
            </ul>
          </div>
          <div className="group">
            <div className="group-title">Find a designer</div>
            <ul>
              <li>
                <Link to="/explore/creatives/">Graphic designer</Link>
              </li>
              <li>
                <Link to="/explore/creatives/?explore_creatives_filter_checkboxes_permitted_sub_category_ids=22%2C26%2C23%2C24%2C25">
                  Logo designer
                </Link>
              </li>
              <li>
                <Link to="/explore/creatives/?explore_creatives_filter_checkboxes_permitted_sub_category_ids=31%2C32%2C33%2C42%2C34%2C35%2C36%2C37">
                  Web designer
                </Link>
              </li>
              <li>
                <Link to="/explore/creatives/?explore_creatives_filter_checkboxes_permitted_sub_category_ids=30">
                  Product designer
                </Link>
              </li>
              <li>
                <Link to="/explore/creatives/?explore_creatives_filter_checkboxes_permitted_sub_category_ids=12%2C13%2C14%2C15%2C16%2C17%2C18">
                  Print designer
                </Link>
              </li>
              <li>
                <Link to="/explore/creatives/?explore_creatives_filter_checkboxes_permitted_sub_category_ids=29">
                  Packaging designer
                </Link>
              </li>
              <li>
                <Link to="/explore/creatives/?explore_creatives_filter_checkboxes_permitted_sub_category_ids=5">
                  Book cover designer
                </Link>
              </li>
              <li>
                <Link to="/explore/creatives/">All freelance designers</Link>
              </li>
              <li>
                <Link to="/register/">Join as a designer or namer</Link>
              </li>
            </ul>
          </div>
          <div className="group">
            <div className="group-title">Resources</div>
            <ul>
              <li>
                <a href="/blog/">Blog</a> | <Link to="/guides/">Guides</Link> |{" "}
                <Link to="/webinars/">Webinars</Link>
              </li>
              <li>
                <Link to="/books/">Books</Link> |{" "}
                <Link to="/quizzes/">Quizzes</Link>
              </li>
              <li>
                <a href="/blog/small-business-ideas/">Small business ideas</a>
              </li>
              <li>
                <a href="/blog/starting-a-business/">Starting a business</a>
              </li>
              <li>
                <Link to="/how-to-form-an-LLC/">How to form an LLC</Link>
              </li>
              <li>
                <a href="/blog/types-of-businesses/">Business types</a> |{" "}
                <a href="/blog/how-to-start-a-corporation/">Corporations</a>
              </li>
              <li>
                <a href="/blog/brand-identity/">Brand identity guide</a>
              </li>
              <li>
                <Link to="/how-to-write-a-business-plan/">
                  How to write a business plan
                </Link>
              </li>
              <li>
                <Link to="/dba-state-requirements/">
                  DBA state requirements
                </Link>
              </li>
              <li>
                <Link to="/marketing-psychology/">Marketing Psychology</Link>
              </li>
              <li>
                <Link to="/cost-of-design/">Cost of design</Link>
              </li>
              <li>
                <a href="/resources/">SmallBiz resource center</a>
              </li>
            </ul>
          </div>
          <div className="group">
            <div className="group-title">Company</div>
            <ul>
              <li>
                <Link to="/about-us/">About us</Link>
              </li>
              <li>
                <Link to="/help/contact/">Contact us</Link>
              </li>
              <li>
                <Link to="/reviews/">Reviews</Link>
              </li>
              <li>
                <Link to="/case-studies/">Case studies</Link>
              </li>
              <li>
                <Link to="/affiliates/">Become an affiliate</Link>
              </li>
              <li>
                <Link to="/partner-program/">Partner with crowdspring</Link>
              </li>
              <li>
                <Link to="/press/">Newsroom</Link>
              </li>
              <li>
                <Link to="/give-back/">Give back</Link>
              </li>
            </ul>
          </div>
        </nav>
        <nav className="secondary-nav">
          <div className="logo-wrapper">
            <Link to="/">
              <Image
                src="https://assets.crowdspring.com/marketing/cs_logo-300x59.png"
                alt="crowdspring logo"
              />
            </Link>
          </div>
          <ul className="social-media">
            <li>
              <a href="https://www.linkedin.com/company/crowdspring/">
                <i className={SOCIAL_ICONS.linkedin} />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/crowdspring/">
                <i className={SOCIAL_ICONS.facebook} />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/crowdspring/">
                <i className={SOCIAL_ICONS.twitter} />
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/crowdspring/">
                <i className={SOCIAL_ICONS.youtube} />
              </a>
            </li>
          </ul>
          <ul className="other">
            <li>
              <Link to="/privacy-policy/">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/user-agreement/">User Agreement</Link>
            </li>
            <li>© {moment().year()} All Rights Reserved</li>
          </ul>
        </nav>
      </div>
    </div>
  </footer>
);

export default Footer;
