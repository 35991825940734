import request from "axios";
import { DEFAULT_PAGINATION_PAGE_SIZE } from "common/pager/constants";
import { getProjectIdFromUrl } from "project/utils";
import { executeRecaptcha } from "common/recaptcha";

/* ACTIONS
================================================================================================ */
export const FETCH_CASE_STUDIES = "marketing/FETCH_CASE_STUDIES";
export const FETCH_CASE_STUDIES_SUCCESS =
  "marketing/FETCH_CASE_STUDIES_SUCCESS";

export const FETCH_REVIEWS = "marketing/FETCH_REVIEWS";
export const FETCH_REVIEWS_REQUEST = "marketing/FETCH_REVIEWS_REQUEST";
export const FETCH_REVIEWS_SUCCESS = "marketing/FETCH_REVIEWS_SUCCESS";

export const FETCH_REVIEW_STATS = "marketing/FETCH_REVIEW_STATS";
export const FETCH_REVIEW_STATS_SUCCESS =
  "marketing/FETCH_REVIEW_STATS_SUCCESS";

export const CLEAR_REVIEWS = "marketing/CLEAR_REVIEWS";

export const FETCH_REFERRALS = "marketing/FETCH_REFERRALS";
export const FETCH_REFERRALS_SUCCESS = "marketing/FETCH_REFERRALS_SUCCESS";

const FETCH_BOOKS = "marketing/FETCH_BOOKS";
const FETCH_BOOKS_SUCCESS = "marketing/FETCH_BOOKS_SUCCESS";

const FETCH_GUIDES = "marketing/FETCH_GUIDES";
const FETCH_GUIDES_SUCCESS = "marketing/FETCH_GUIDES_SUCCESS";

const FETCH_WEBINARS = "marketing/FETCH_WEBINARS";
const FETCH_WEBINARS_SUCCESS = "marketing/FETCH_WEBINARS_SUCCESS";

const FETCH_MAGNETS = "marketing/FETCH_MAGNETS";
const FETCH_MAGNETS_SUCCESS = "marketing/FETCH_MAGNETS_SUCCESS";

/* HELPERS
================================================================================================ */
export const createReferralsRequest = (formData) =>
  request.post("/api/v1/referrals/", formData);

export const subscribeRequest = (formData) => {
  let action, apiSuffix;
  if (formData.list_id) {
    action = "external_contact";
    apiSuffix = "contact_lists/create_external_contact";
  } else {
    action = "external_bumblebee_lead";
    apiSuffix = "bb/external_leads";
  }

  return executeRecaptcha({ action }).then((token) => {
    return request.post(`/api/v1/${apiSuffix}/`, {
      ...formData,
      recaptcha_token: token,
    });
  });
};

/* INITIAL STATES
================================================================================================ */
export const initialState = {
  caseStudies: [],
  reviews: [],
  reviewCount: 0,
  nextReviewPage: 1,
  fetchingReviews: false,
  referrals: [],
  reviewStats: {},
  books: [],
  guides: [],
  webinars: [],
  magnets: [],
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_REVIEW_STATS_SUCCESS:
      return {
        ...state,
        reviewStats: action.payload,
      };
    case FETCH_CASE_STUDIES_SUCCESS:
      return {
        ...state,
        caseStudies: action.payload,
      };
    case FETCH_REVIEWS_REQUEST:
      return {
        ...state,
        fetchingReviews: true,
      };
    case FETCH_REVIEWS_SUCCESS:
      return {
        ...state,
        reviews: [...state.reviews, ...action.payload.results],
        reviewCount: action.payload.count,
        nextReviewPage: state.nextReviewPage + 1,
        fetchingReviews: false,
      };
    case CLEAR_REVIEWS:
      return {
        ...state,
        reviews: [],
        reviewCount: 0,
        nextReviewPage: 1,
      };
    case FETCH_REFERRALS_SUCCESS:
      return {
        ...state,
        referrals: action.payload,
      };
    case FETCH_BOOKS_SUCCESS:
      return {
        ...state,
        books: action.payload,
      };
    case FETCH_GUIDES_SUCCESS:
      return {
        ...state,
        guides: action.payload,
      };
    case FETCH_WEBINARS_SUCCESS:
      return {
        ...state,
        webinars: action.payload,
      };
    case FETCH_MAGNETS_SUCCESS:
      return {
        ...state,
        magnets: action.payload,
      };
    default:
      return state;
  }
}

export const fetchReviewStatsIfNeeded =
  (params = {}) =>
  (dispatch, getState) => {
    const { marketing } = getState();
    const hasProjectId = !!getProjectIdFromUrl(params);

    /* ignore if fetching from project pages or already fetched */
    if (hasProjectId || Object.keys(marketing.reviewStats).length > 0) {
      return Promise.resolve();
    }

    return dispatch({
      type: FETCH_REVIEW_STATS,
      promise: request.get("/api/v1/csratings/stats/"),
    });
  };

export const fetchCaseStudiesIfNeeded = () => (dispatch, getState) => {
  const { caseStudies } = getState().marketing;

  if (!!caseStudies && caseStudies.length > 0) {
    return Promise.resolve();
  }

  return dispatch({
    type: FETCH_CASE_STUDIES,
    promise: request.get("/api/v1/case-studies/"),
  });
};

export const fetchReviews =
  (subcatIds, opts = {}) =>
  (dispatch, getState) => {
    let { pageSize, sortBy } = opts;
    pageSize = pageSize || DEFAULT_PAGINATION_PAGE_SIZE;
    const { nextReviewPage } = getState().marketing;
    const offset = (nextReviewPage - 1) * pageSize;
    let queryStr = `?limit=${pageSize}&offset=${offset}&published=published`;
    if (subcatIds) {
      queryStr += subcatIds.reduce(
        (acc, subcatId) => `${acc}&subcategories=${subcatId}`,
        ""
      );
    }
    if (sortBy) {
      queryStr += `&sort=${sortBy}`;
    }
    const promise = request.get(`/api/v1/csratings/${queryStr}`);

    dispatch({
      type: FETCH_REVIEWS,
      promise,
    });

    return promise;
  };

export const clearReviews = () => {
  return {
    type: CLEAR_REVIEWS,
  };
};

export const fetchReferrals = () => (dispatch) => {
  return dispatch({
    type: FETCH_REFERRALS,
    promise: request.get("/api/v1/referrals/"),
  });
};

export const fetchBooksIfNeeded = () => (dispatch, getState) => {
  const { books } = getState().marketing;

  if (!!books && books.length > 0) {
    return Promise.resolve();
  }

  return dispatch({
    type: FETCH_BOOKS,
    promise: request.get("/api/v1/books/"),
  });
};

export const fetchGuidesIfNeeded = () => (dispatch, getState) => {
  const { guides } = getState().marketing;

  if (!!guides && guides.length > 0) {
    return Promise.resolve();
  }

  return dispatch({
    type: FETCH_GUIDES,
    promise: request.get("/api/v1/marketing-guides/"),
  });
};

export const fetchWebinarsIfNeeded = () => (dispatch, getState) => {
  const { webinars } = getState().marketing;

  if (!!webinars && webinars.length > 0) {
    return Promise.resolve();
  }

  return dispatch({
    type: FETCH_WEBINARS,
    promise: request.get("/api/v1/webinars/"),
  });
};

export const fetchMagnetsIfNeeded = () => (dispatch, getState) => {
  const { magnets } = getState().marketing;

  if (magnets.length > 0) {
    return Promise.resolve();
  }

  return dispatch({
    type: FETCH_MAGNETS,
    promise: request.get("/api/v1/lead_magnets/"),
  });
};
