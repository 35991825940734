import React from "react";
import cookie from "react-cookie";

const EXPERIMENTS_COOKIE = "firefly_experiments";

export const getExperimentsFromResponse = (res) => {
  for (const cookie of res.headers["set-cookie"] || []) {
    const [cookieName, cookieValue] = cookie.split(";")[0].split("=");
    if (cookieName === EXPERIMENTS_COOKIE) {
      return JSON.parse(decodeURIComponent(cookieValue));
    }
  }
  return [];
};

/* get variation for a given experiment by looking at:
   - variations stored in `server` ducks state (server-side)
   - cookies stored in the browser (client-side) */
export const getVariation = (experimentId, state) => {
  let experiments;
  if (__CLIENT__) {
    experiments = cookie.load(EXPERIMENTS_COOKIE);
  } else {
    experiments = state.server.experiments;
  }
  return experiments?.find((exp) => exp.id === experimentId)?.variation ?? null;
};

export const makeVariationsEven = (variations) => {
  variations.forEach(
    (variation) =>
      (variation.allocation = parseFloat((100 / variations.length).toFixed(1)))
  );
};

export const getVariationName = (variation, index, ignoreCustomName) => {
  if (index === 0) {
    return "Original";
  }

  const baseName = `Variation ${index}`;

  if (ignoreCustomName) {
    return baseName;
  }

  return (
    <span>
      {baseName}{" "}
      {!!variation.name && (
        <i className="fa fa-question-circle" title={variation.name} />
      )}
    </span>
  );
};

export const getTotalAllocation = (variations) =>
  Math.round(variations.reduce((acc, v) => acc + v.allocation, 0));
