import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { unTrailingSlashIt } from "trailing-slash-it";
import {
  setViewType as setImageViewType,
  setGrouping as setImageGrouping,
} from "profile/ducks/image_entries";
import {
  setViewType as setNameViewType,
  setGrouping as setNameGrouping,
} from "profile/ducks/name_entries";
import {
  IMAGE_ENTRIES_RESOURCE_NAME,
  NAME_ENTRIES_RESOURCE_NAME,
} from "profile/constants";
import Controls from "profile/components/your_entries/controls";

const getEntryType = ({ location }) =>
  unTrailingSlashIt(location.pathname).split("/").splice(-1)[0];

const getStateProps = (state, entryType) => {
  const { imageEntries, nameEntries } = state.profile;

  return {
    image: {
      resourceName: IMAGE_ENTRIES_RESOURCE_NAME,
      viewType: imageEntries.viewType,
      grouped: imageEntries.grouped,
    },
    name: {
      resourceName: NAME_ENTRIES_RESOURCE_NAME,
      viewType: nameEntries.viewType,
      grouped: nameEntries.grouped,
    },
  }[entryType];
};

const getDispatchProps = (entryType) => {
  return {
    image: {
      setViewType: setImageViewType,
      setGrouping: setImageGrouping,
    },
    name: {
      setViewType: setNameViewType,
      setGrouping: setNameGrouping,
    },
  }[entryType];
};

const mapStateToProps = (state, ownProps) => {
  const entryType = getEntryType(ownProps);

  return {
    entryType,
    ...getStateProps(state, entryType),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const entryType = getEntryType(ownProps);

  return bindActionCreators(
    {
      ...getDispatchProps(entryType),
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Controls)
);
