import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import classnames from "classnames";

const Breadcrumbs = ({ items, extraClasses, style, linkStyle }) => {
  return (
    <ol
      style={style}
      itemScope={true}
      itemType="https://schema.org/BreadcrumbList"
      className={classnames("breadcrumbs", extraClasses)}
    >
      {items.map((item, i) => {
        const isLast = i === items.length - 1;

        return (
          <li
            key={i}
            itemProp="itemListElement"
            itemScope={true}
            itemType="https://schema.org/ListItem"
          >
            <Link itemProp="item" to={item.url} style={linkStyle}>
              <span itemProp="name">{item.title}</span>
            </Link>

            {!isLast && <span className="separator">{">"}</span>}

            <meta itemProp="position" content={i + 1} />
          </li>
        );
      })}
    </ol>
  );
};

Breadcrumbs.propTypes = {
  items: PropTypes.array.isRequired,
  extraClasses: PropTypes.string,
  style: PropTypes.object,
  linkStyle: PropTypes.object,
};

export default Breadcrumbs;
