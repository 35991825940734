import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import classnames from "classnames";
import { v4 } from "uuid";
import Logo from "header/containers/logo";
import Avatar from "common/components/avatar";
import Icon from "common/components/svg_icon";

/**
 * Drawer component sliding from the left with navigation
 *
 * @author Kuba Siemiątkowski <kuba@crowdspring.com>
 */
export class LeftSideNavigation extends Component {
  constructor(props) {
    super(props);

    this.renderLinkContent = this.renderLinkContent.bind(this);
  }

  renderLinkContent(link) {
    if (!link.subItems) {
      return (
        <Link rel={link.rel} to={link.url} activeClassName="active">
          {link.name}
        </Link>
      );
    }

    return (
      <div className="link-with-sub-items">
        <div className="link-name">{link.name}</div>
        {link.subItems.map((item, i) => (
          <Link key={i} to={item.url}>
            {item.name}
          </Link>
        ))}
      </div>
    );
  }

  render() {
    const { links } = this.props;

    return (
      <div className="side-navigation">
        <Logo sidebar={true} />
        <ul className="primary-nav">
          {links
            .filter((link) => link.mobileNav === "left")
            .map((link) => (
              <li key={v4()} className="nav-item-side">
                {this.renderLinkContent(link)}
              </li>
            ))}
        </ul>
      </div>
    );
  }
}

/**
 * PropTypes.
 *
 * @property {array} links - array of links objects to render
 */
LeftSideNavigation.propTypes = {
  links: PropTypes.array,
};

/**
 * Drawer component sliding from the right with navigation and profile picture.
 *
 * @author Nathan Ozelim <nathan@startupfoundry.com>
 */
export const RightSideNavigation = ({
  links,
  userAvatar,
  userProfile,
  unseenNotificationsCount,
  toggleRightNav,
}) => (
  <div className="side-navigation hide-for-large">
    <button className="btn btn-icon-only close-button" onClick={toggleRightNav}>
      <Icon icon="close" extraClasses="svg-icon-lg" />
    </button>
    <div className="user-info text-center">
      <Avatar
        user={{ ...userProfile, avatar: userAvatar }}
        onClick={toggleRightNav}
        extraClasses="avatar-lg avatar-shadow avatar-inline"
      />
      {!!userProfile && !!userProfile.first_name && (
        <h3>{userProfile.first_name}</h3>
      )}
    </div>
    <RightNavLinks
      links={links}
      userProfile={userProfile}
      unseenNotificationsCount={unseenNotificationsCount}
    />
  </div>
);

export const RightNavLinks = ({
  userProfile,
  links,
  unseenNotificationsCount,
  classes,
}) => (
  <ul className={classnames(classes)}>
    {userProfile && userProfile.id && (
      <li className="nav-item-side notification-nav">
        <Link to={`/users/${userProfile.username}/`}>Profile</Link>
      </li>
    )}
    {/* 'Logout' link will be placed at the bottom below */}
    {links
      .filter((link) => link.mobileNav === "right" && link.name !== "Logout")
      .map((link) => (
        <li key={v4()} className="nav-item-side">
          <Link to={link.url} rel={link.rel} activeClassName="active">
            {link.name}
          </Link>
        </li>
      ))}
    <li className="nav-item-side notification-nav">
      <Link to="/notifications/">
        Notifications
        {!!unseenNotificationsCount && (
          <span className="badge badge-primary show-for-small-only">
            {" "}
            {unseenNotificationsCount}
          </span>
        )}
      </Link>
    </li>
    <li
      className={classnames("nav-item-side whats-new-nav", {
        "has-new-updates": userProfile.product_updates > 0,
      })}
    >
      <Link to={`/whats-new/${userProfile.is_client ? "client" : "creative"}/`}>
        What's New
      </Link>
    </li>
    {links
      .filter((link) => link.mobileNav === "right" && link.name === "Logout")
      .map((link) => (
        <li key={v4()} className="nav-item-side">
          <Link to={link.url} rel={link.rel} activeClassName="active">
            {link.name}
          </Link>
        </li>
      ))}
  </ul>
);

RightNavLinks.propTypes = {
  links: PropTypes.array,
  userProfile: PropTypes.object,
  unseenNotificationsCount: PropTypes.number,
  classes: PropTypes.string,
};

/**
 * PropTypes.
 *
 * @property {array} links - array of links objects to render
 */
RightSideNavigation.propTypes = {
  links: PropTypes.array,
  userProfile: PropTypes.object,
  unseenNotificationsCount: PropTypes.number,
  userAvatar: PropTypes.string,
  toggleRightNav: PropTypes.func,
};
