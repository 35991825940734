import { browserHistory } from "react-router";
import * as Sentry from "@sentry/browser";
import { showBanner, TYPE_ERROR } from "common/ducks/banner";

export const SET_UNHANDLED_ERROR = "crowdspring/error/SET_UNHANDLED_ERROR";

export const initialState = {
  unhandledError: null,
};

export const setUnhandledError =
  (error, details = {}, bannerContent) =>
  (dispatch) => {
    /* report exception to Sentry and either go to error page or show error banner */
    Sentry.withScope((scope) => {
      Sentry.setExtra("details", details);
      Sentry.captureMessage(error, "error");
    });

    if (bannerContent) {
      dispatch(setBannerError(error, bannerContent));
    } else {
      browserHistory.push("/error");
    }
    return dispatch({ type: SET_UNHANDLED_ERROR, payload: error });
  };

export const setBannerError = (title, content) =>
  showBanner(title, content, null, TYPE_ERROR);

export default function reducer(state = initialState, action) {
  if (!action) {
    return initialState;
  }

  switch (action.type) {
    case SET_UNHANDLED_ERROR:
      return {
        ...state,
        unhandledError: action.payload,
      };
    default:
      return state;
  }
}
