import request from "axios";
import fileDownload from "js-file-download";
import { getAPIQueryString } from "common/utils";
import { savePageCount } from "common/pager/ducks";
import {
  BLOCKED_DOMAINS_RESOURCE_NAME,
  BLOCKED_REASONS_RESOURCE_NAME,
  BLOCKED_PAGE_SIZE,
} from "wasp/constants";
import { setBannerError } from "error";
import { showBanner, TYPE_SUCCESS } from "common/ducks/banner";
import { toggleActionConfirmation } from "admin/ducks/common";

/* ACTIONS
================================================================================================ */
const FETCH_BLOCKED_DOMAINS = "wasp/blocked_domains/FETCH_BLOCKED_DOMAINS";
const FETCH_BLOCKED_DOMAINS_REQUEST =
  "wasp/blocked_domains/FETCH_BLOCKED_DOMAINS_REQUEST";
const FETCH_BLOCKED_DOMAINS_SUCCESS =
  "wasp/blocked_domains/FETCH_BLOCKED_DOMAINS_SUCCESS";

const SAVE_BLOCKED_DOMAIN = "wasp/blocked_domains/SAVE_BLOCKED_DOMAIN";
const SAVE_BLOCKED_DOMAIN_SUCCESS =
  "wasp/blocked_domains/SAVE_BLOCKED_DOMAIN_SUCCESS";

const FETCH_ALL_REASONS = "wasp/blocked_domains/FETCH_ALL_REASONS";
const FETCH_ALL_REASONS_SUCCESS =
  "wasp/blocked_domains/FETCH_ALL_REASONS_SUCCESS";

const CREATE_BLOCKED_DOMAIN = "wasp/blocked_domains/CREATE_BLOCKED_DOMAIN";
const CREATE_BLOCKED_DOMAIN_SUCCESS =
  "wasp/blocked_domains/CREATE_BLOCKED_DOMAIN_SUCCESS";

const DELETE_BLOCKED_DOMAIN = "wasp/blocked_domains/DELETE_BLOCKED_DOMAIN";
const DELETE_BLOCKED_DOMAIN_SUCCESS =
  "wasp/blocked_domains/DELETE_BLOCKED_DOMAIN_SUCCESS";

const FETCH_REASONS = "wasp/blocked_domains/FETCH_REASONS";
const FETCH_REASONS_REQUEST = "wasp/blocked_domains/FETCH_REASONS_REQUEST";
const FETCH_REASONS_SUCCESS = "wasp/blocked_domains/FETCH_REASONS_SUCCESS";

const SAVE_REASON = "wasp/blocked_domains/SAVE_REASON";
const SAVE_REASON_SUCCESS = "wasp/blocked_domains/SAVE_REASON_SUCCESS";

const DELETE_REASON = "wasp/blocked_domains/DELETE_REASON";
const DELETE_REASON_SUCCESS = "wasp/blocked_domains/DELETE_REASON_SUCCESS";

const CREATE_REASON = "wasp/blocked_domains/CREATE_REASON";
const CREATE_REASON_SUCCESS = "wasp/blocked_domains/CREATE_REASON_SUCCESS";

/* HELPERS
================================================================================================ */
export const uploadBlockedDomainsRequest = (data, extraValues) => {
  return request.post("/api/v1/blocked_domains/upload_csv/", {
    csv_string: data.file,
    ...extraValues,
  });
};

export const exportBlockedDomainsRequest = (query) => {
  const queryStr = getAPIQueryString(BLOCKED_DOMAINS_RESOURCE_NAME, query, {
    excludePager: true,
  });
  const promise = request.get(
    `/api/v1/blocked_domains/export_csv/?${queryStr}`
  );

  promise.then((res) => {
    const filename = res.headers["content-disposition"]
      .split("attachment; filename=")[1]
      .replaceAll("'", "");
    fileDownload(res.data, filename);
  });

  return promise;
};

/* INITIAL STATES
================================================================================================ */
const initialState = {
  blockedDomains: null,
  blockedDomainCount: 0,
  allReasons: [],
  reasons: null,
  reasonCount: 0,
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_BLOCKED_DOMAINS_REQUEST:
      return {
        ...state,
        blockedDomains: null,
      };
    case FETCH_BLOCKED_DOMAINS_SUCCESS:
      return {
        ...state,
        blockedDomains: action.payload.results,
        blockedDomainCount: action.payload.count,
      };
    case SAVE_BLOCKED_DOMAIN_SUCCESS:
      return {
        ...state,
        blockedDomains: state.blockedDomains
          ? state.blockedDomains.map((d) =>
              d.id === action.payload.id ? action.payload : d
            )
          : state.blockedDomains,
      };
    case FETCH_ALL_REASONS_SUCCESS:
      return {
        ...state,
        allReasons: action.payload,
      };
    case CREATE_BLOCKED_DOMAIN_SUCCESS:
      return {
        ...state,
        blockedDomains: state.blockedDomains
          ? [action.payload].concat(state.blockedDomains)
          : state.blockedDomains,
        blockedDomainCount: state.blockedDomainCount + 1,
      };
    case DELETE_BLOCKED_DOMAIN_SUCCESS:
      return {
        ...state,
        blockedDomains: state.blockedDomains
          ? state.blockedDomains.filter((d) => d.id !== action.id)
          : state.blockedDomains,
        blockedDomainCount: Math.max(state.blockedDomainCount - 1, 0),
      };
    case FETCH_REASONS_REQUEST:
      return {
        ...state,
        reasons: null,
      };
    case FETCH_REASONS_SUCCESS:
      return {
        ...state,
        reasons: action.payload.results,
        reasonCount: action.payload.count,
      };
    case SAVE_REASON_SUCCESS:
      return {
        ...state,
        reasons: state.reasons
          ? state.reasons.map((r) =>
              r.id === action.payload.id ? action.payload : r
            )
          : state.reasons,
      };
    case DELETE_REASON_SUCCESS:
      return {
        ...state,
        reasons: state.reasons
          ? state.reasons.filter((r) => r.id !== action.id)
          : state.reasons,
        reasonCount: Math.max(state.reasonCount - 1, 0),
      };
    case CREATE_REASON_SUCCESS:
      return {
        ...state,
        reasons: state.reasons
          ? [action.payload].concat(state.reasons)
          : state.reasons,
        reasonCount: state.reasonCount + 1,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const fetchBlockedDomains = (query) => (dispatch) => {
  /* use smaller page size to prevent too many admin note requests */
  const queryStr = getAPIQueryString(BLOCKED_DOMAINS_RESOURCE_NAME, query, {
    pageSize: BLOCKED_PAGE_SIZE,
  });
  const promise = request.get(`/api/v1/blocked_domains/?${queryStr}`);

  /* save page count in pager state */
  promise.then((res) =>
    dispatch(savePageCount(BLOCKED_DOMAINS_RESOURCE_NAME, res))
  );

  return dispatch({
    type: FETCH_BLOCKED_DOMAINS,
    promise,
  });
};

export const saveBlockedDomain = (id, formData) => (dispatch) => {
  const promise = request.patch(`/api/v1/blocked_domains/${id}/`, formData);

  promise
    .then(() => {
      dispatch(
        showBanner(
          "Success",
          "Blocked domain successfully saved.",
          undefined,
          TYPE_SUCCESS,
          null,
          3000
        )
      );
    })
    .catch((err) => {
      const errorMsg = err.data
        ? JSON.stringify(err.data)
        : "Make sure the data you entered is correct.";
      dispatch(setBannerError("Error saving blocked domain", errorMsg));
    });

  dispatch({
    type: SAVE_BLOCKED_DOMAIN,
    promise,
  });

  return promise;
};

export const fetchAllReasons = () => (dispatch) => {
  return dispatch({
    type: FETCH_ALL_REASONS,
    promise: request.get("/api/v1/blocked_reasons/all/"),
  });
};

export const createBlockedDomain = (data) => (dispatch) => {
  return dispatch({
    type: CREATE_BLOCKED_DOMAIN,
    promise: request.post("/api/v1/blocked_domains/", data),
  });
};

export const deleteBlockedDomain = (id) => (dispatch) => {
  const promise = request.delete(`/api/v1/blocked_domains/${id}/`);

  promise
    .then(() => {
      dispatch(toggleActionConfirmation(null));
    })
    .catch((err) => {
      const errorMsg = err.data ? JSON.stringify(err.data) : "Unkown error.";
      dispatch(setBannerError("Error removing domain from list", errorMsg));
    });

  return dispatch({
    type: DELETE_BLOCKED_DOMAIN,
    promise,
    id,
  });
};

export const fetchReasons = (query) => (dispatch) => {
  /* use smaller page size to prevent too many admin note requests */
  const queryStr = getAPIQueryString(BLOCKED_REASONS_RESOURCE_NAME, query, {
    pageSize: BLOCKED_PAGE_SIZE,
  });
  const promise = request.get(`/api/v1/blocked_reasons/?${queryStr}`);

  /* save page count in pager state */
  promise.then((res) =>
    dispatch(savePageCount(BLOCKED_REASONS_RESOURCE_NAME, res))
  );

  return dispatch({
    type: FETCH_REASONS,
    promise,
  });
};

export const saveReason = (id, formData) => (dispatch) => {
  const promise = request.patch(`/api/v1/blocked_reasons/${id}/`, formData);

  promise
    .then(() => {
      dispatch(
        showBanner(
          "Success",
          "Block reason successfully saved.",
          undefined,
          TYPE_SUCCESS,
          null,
          3000
        )
      );
    })
    .catch((err) => {
      const errorMsg = err.data
        ? JSON.stringify(err.data)
        : "Make sure the data you entered is correct.";
      dispatch(setBannerError("Error saving block reason", errorMsg));
    });

  dispatch({
    type: SAVE_REASON,
    promise,
  });

  return promise;
};

export const deleteReason = (id) => (dispatch) => {
  const promise = request.delete(`/api/v1/blocked_reasons/${id}/`);

  promise
    .then(() => {
      dispatch(toggleActionConfirmation(null));
    })
    .catch((err) => {
      const errorMsg = err.data ? JSON.stringify(err.data) : "Unkown error.";
      dispatch(setBannerError("Error removing reason from list", errorMsg));
    });

  return dispatch({
    type: DELETE_REASON,
    promise,
    id,
  });
};

export const createReason = (data) => (dispatch) => {
  return dispatch({
    type: CREATE_REASON,
    promise: request.post("/api/v1/blocked_reasons/", data),
  });
};
