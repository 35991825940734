import idx from "idx";
import isEqual from "lodash/isEqual";
import * as Sentry from "@sentry/browser";

const BLACKLISTED_ERRORS = [
  "NetworkError: A network error occurred.",
  "ChunkLoadError",
];

const WHITELISTED_URLS = ["127.0.0.1", "crowdspring"];

const IGNORED_UNHANDLED_REJECTIONS = [
  {
    status: 400,
    url: new RegExp(
      "^/api/v1/creative-registration-waiters/redeem_registration_invite/$"
    ),
    message: "This invite has already been redeemed.",
  },
  {
    status: 400,
    url: new RegExp("^/api/v1/discount-codes/.+/$"),
  },
  {
    status: 400,
    url: new RegExp("^/api/v1/projects/[0-9]+/removed_creatives/$"),
    message: "This operation may not be performed on 1-to-1 projects.",
  },
  {
    status: 400,
    url: new RegExp("^/api/v1/projects/[0-9]+/invites/$"),
    message: "This operation may not be performed on 1-to-1 projects.",
  },
  {
    status: 400,
    url: new RegExp(
      "^/api/v1/projects/[0-9]+/entries/[0-9]+/create_revision/$"
    ),
  },
  {
    status: 400,
    url: new RegExp("^/api/v1/users/[0-9]+/payment-methods/save/$"),
  },
  {
    status: 400,
    url: new RegExp("^/api/v1/users/[0-9]+/set_mobile/\\?smart=true$"),
  },
  {
    status: 400,
    url: new RegExp("^/api/v1/users/[0-9]+/submit_image_samples/$"),
  },
  {
    status: 400,
    url: new RegExp("^/api/v1/users/[0-9]+/submit_id_validation/$"),
  },
  {
    status: 400,
    url: new RegExp("^/api/v1/wrapups/[0-9]+/create_testimonial/$"),
  },
  {
    status: 403,
  },
  {
    status: 404,
  },
  {
    status: 500,
  },
];

const IGNORED_GENERAL_ERRORS = [{}];

export const getOptions = () => ({
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: [new Sentry.Replay()],
  ignoreErrors: BLACKLISTED_ERRORS,
  allowUrls: WHITELISTED_URLS,
  beforeSend: (event) => {
    /* filter out ignored unhandled rejections */
    if (
      idx(event, (_) => _.exception.values[0].type) === "UnhandledRejection"
    ) {
      const status = idx(event, (_) => _.extra.__serialized__.status);
      const url = idx(event, (_) => _.extra.__serialized__.config.url) || "";
      const message = idx(event, (_) => _.extra.__serialized__.data.message);
      const data = idx(event, (_) => _.extra.__serialized__.data);

      const ignored = IGNORED_UNHANDLED_REJECTIONS.find(
        (r) =>
          (!r.status || r.status === status) &&
          (!r.url ||
            (typeof r.url === "string" ? r.url === url : url.match(r.url))) &&
          (!r.message || r.message === message) &&
          (!r.data || isEqual(r.data, data))
      );

      if (ignored) {
        return undefined;
      }
    }

    /* filter out ignored general errors */
    if (idx(event, (_) => _.exception.values[0].type) === "Error") {
      const value = idx(event, (_) => _.exception.values[0].value);

      const ignored = IGNORED_GENERAL_ERRORS.find(
        (e) => !e.value || e.value === value
      );

      if (ignored) {
        return undefined;
      }
    }

    return event;
  },
});
