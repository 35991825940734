import idx from "idx";

/* Get query parameter name related to given resource */
const getPagerParamName = (resourceName) => `${resourceName}_page`;

/* Get the value for a given pager from the query dict */
export const getValueFromQuery = (resourceName, query) => {
  return parseInt(idx(query, (_) => _[getPagerParamName(resourceName)]) || "1");
};

/* Get the query dict given the pager value */
export const getQueryFromValue = (resourceName, value) => {
  return {
    [getPagerParamName(resourceName)]: value || undefined,
  };
};

/* Get the API query dict for a given pager based on the (URL) query dict */
export const getAPIQuery = (resourceName, pageSize, query) => {
  const value = getValueFromQuery(resourceName, query);
  return {
    offset: (value - 1) * pageSize,
    limit: pageSize,
  };
};

/* Get the page count for a given pager from the state */
export const getPageCountFromState = (resourceName, state) => {
  return idx(state, (_) => _.pager.pageCounts[resourceName]) || 0;
};
