import React, { Component } from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";

const actions = [
  {
    field: "unread",
    label: "Unread",
  },
  {
    field: "read",
    label: "Read",
  },
  {
    field: "delete",
    label: "Delete",
  },
];

export default class ActionSelect extends Component {
  constructor(props) {
    super(props);
    this.changed = this.changed.bind(this);
  }

  changed(e) {
    const { onChange, userId, selectedNotifications } = this.props;
    onChange(e.target.value, userId, selectedNotifications);
  }

  render() {
    const options = actions.map(({ field, label }) => (
      <option
        key={v4()}
        value={field}
        disabled={this.props.selectedNotifications.length == 0}
      >
        {label}
      </option>
    ));
    return (
      <div className="form-group select-wrapper select-action">
        <select className="form-control" onChange={this.changed}>
          <option value="">With selected...</option>
          {options}
        </select>
      </div>
    );
  }
}

ActionSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  selectedNotifications: PropTypes.array,
};
