import React from "react";
import PropTypes from "prop-types";
import WatchlistButton from "project/containers/watch_project_button";
import ReserveButton from "project/containers/reserve_button";
import Icon from "common/components/svg_icon";
import Menu from "project/containers/header_actions_menu";
import SubmitEntryDropdown from "project/containers/entry_submission/submit_dropdown";

/**
 * Project page specific header buttons
 *
 * @author Kuba Siemiątkowski <kuba@crowdspring.com>
 * @author Jing Wang <jing@crowdspring.com>
 * @author Nathan Ozelim <nathan@startupfoundry.com>
 */
const HeaderButtons = ({
  toggleRightDrawer,
  requestAccess,
  showFilters,
  canRequestAccess,
  canWatch,
  showPromoContent,
}) => {
  if (showPromoContent) {
    return null;
  }

  return (
    <div className="project-header-controls">
      {/* Reverse order for float right */}

      <Menu />

      <ReserveButton />

      <SubmitEntryDropdown />

      {canRequestAccess && (
        <div className="button-wrapper show-for-large">
          <button className="btn btn-tertiary" onClick={requestAccess}>
            Request access
          </button>
        </div>
      )}

      {showFilters && (
        <div className="hide-for-large button-wrapper">
          <button
            type="button"
            onClick={toggleRightDrawer}
            className="btn btn-icon-only btn-filter header-btn-filter"
          >
            <Icon extraClasses="svg-icon-lg filter" icon="filter" />
          </button>
        </div>
      )}

      {canWatch && (
        <div className="button-wrapper project-watchers show-for-large">
          <WatchlistButton classes="btn btn-text" />
        </div>
      )}
    </div>
  );
};

HeaderButtons.propTypes = {
  toggleRightDrawer: PropTypes.func.isRequired,
  requestAccess: PropTypes.func.isRequired,
  showFilters: PropTypes.bool,
  canRequestAccess: PropTypes.bool,
  canWatch: PropTypes.bool,
  showPromoContent: PropTypes.bool.isRequired,
};

export default HeaderButtons;
