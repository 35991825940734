import idx from "idx";

/* Get query parameter name related to given resource */
const getSearchParamName = (resourceName) => `${resourceName}_search`;

/* Get the value for a given searcher from the query dict */
export const getValueFromQuery = (resourceName, query) => {
  return idx(query, (_) => _[getSearchParamName(resourceName)]);
};

/* Get the query dict given the searcher value */
export const getQueryFromValue = (resourceName, value) => {
  return {
    [getSearchParamName(resourceName)]: value || undefined,
  };
};

/* Get the API query dict for a given searcher based on the (URL) query dict */
export const getAPIQuery = (resourceName, paramName, query) => {
  const value = getValueFromQuery(resourceName, query);
  return {
    [paramName]: value,
  };
};

/* Apply query client-side on list of provided items */
export const applyClientQuery = (items, resourceName, query, opts = {}) => {
  const { searchFields } = opts;
  const value = getValueFromQuery(resourceName, query);

  if (!value) {
    return items;
  }

  return items.filter(
    (item) =>
      searchFields.filter((field) =>
        (item[field] || "").toLowerCase().includes(value.toLowerCase())
      ).length > 0
  );
};
