import request from "axios";
import pick from "lodash/pick";
import range from "lodash/range";
import uniq from "lodash/uniq";
import queryString from "query-string";
import { browserHistory } from "react-router";
import { getAPIQueryString } from "common/utils";
import { savePageCount } from "common/pager/ducks";
import { setBannerError } from "error";
import { showBanner, TYPE_SUCCESS } from "common/ducks/banner";
import { toggleActionConfirmation } from "admin/ducks/common";
import { ADMIN_ROOT } from "common/variables";
import {
  EXPERIMENTS_RESOURCE_NAME,
  GROUPS_RESOURCE_NAME,
  GROUPS_PER_EXPERIMENT,
  GOALS_PER_EXPERIMENT,
} from "firefly/constants";

/* ACTIONS
================================================================================================ */
const FETCH_EXPERIMENTS = "firefly/experiments/FETCH_EXPERIMENTS";
const FETCH_EXPERIMENTS_REQUEST =
  "firefly/experiments/FETCH_EXPERIMENTS_REQUEST";
const FETCH_EXPERIMENTS_SUCCESS =
  "firefly/experiments/FETCH_EXPERIMENTS_SUCCESS";

const FETCH_TOTALS = "firefly/experiments/FETCH_TOTALS";
const FETCH_TOTALS_SUCCESS = "firefly/experiments/FETCH_TOTALS_SUCCESS";

const FETCH_GROUP = "firefly/experiments/FETCH_GROUP";
const FETCH_GROUP_REQUEST = "firefly/experiments/FETCH_GROUP_REQUEST";
const FETCH_GROUP_SUCCESS = "firefly/experiments/FETCH_GROUP_SUCCESS";

const LAUNCH_EXPERIMENT = "firefly/experiments/LAUNCH_EXPERIMENT";
const LAUNCH_EXPERIMENT_SUCCESS =
  "firefly/experiments/LAUNCH_EXPERIMENT_SUCCESS";

const PAUSE_EXPERIMENT = "firefly/experiments/PAUSE_EXPERIMENT";
const PAUSE_EXPERIMENT_SUCCESS = "firefly/experiments/PAUSE_EXPERIMENT_SUCCESS";

const RESUME_EXPERIMENT = "firefly/experiments/RESUME_EXPERIMENT";
const RESUME_EXPERIMENT_SUCCESS =
  "firefly/experiments/RESUME_EXPERIMENT_SUCCESS";

const COMPLETE_EXPERIMENT = "firefly/experiments/COMPLETE_EXPERIMENT";
const COMPLETE_EXPERIMENT_SUCCESS =
  "firefly/experiments/COMPLETE_EXPERIMENT_SUCCESS";

const FETCH_GROUPS = "firefly/experiments/FETCH_GROUPS";
const FETCH_GROUPS_REQUEST = "firefly/experiments/FETCH_GROUPS_REQUEST";
const FETCH_GROUPS_SUCCESS = "firefly/experiments/FETCH_GROUPS_SUCCESS";

const FETCH_GROUP_TOTALS = "firefly/experiments/FETCH_GROUP_TOTALS";
const FETCH_GROUP_TOTALS_SUCCESS =
  "firefly/experiments/FETCH_GROUP_TOTALS_SUCCESS";

const PAUSE_GROUP = "firefly/experiments/PAUSE_GROUP";
const PAUSE_GROUP_SUCCESS = "firefly/experiments/PAUSE_GROUP_SUCCESS";

const RESUME_GROUP = "firefly/experiments/RESUME_GROUP";
const RESUME_GROUP_SUCCESS = "firefly/experiments/RESUME_GROUP_SUCCESS";

const COMPLETE_GROUP = "firefly/experiments/COMPLETE_GROUP";
const COMPLETE_GROUP_SUCCESS = "firefly/experiments/COMPLETE_GROUP_SUCCESS";

const FETCH_ALL_GROUPS = "firefly/experiments/FETCH_ALL_GROUPS";
const FETCH_ALL_GROUPS_SUCCESS = "firefly/experiments/FETCH_ALL_GROUPS_SUCCESS";

const SAVE_GROUP = "firefly/experiments/SAVE_GROUP";
const SAVE_GROUP_SUCCESS = "firefly/experiments/SAVE_GROUP_SUCCESS";

const FETCH_EXPERIMENT = "firefly/experiments/FETCH_EXPERIMENT";
const FETCH_EXPERIMENT_REQUEST = "firefly/experiments/FETCH_EXPERIMENT_REQUEST";
const FETCH_EXPERIMENT_SUCCESS = "firefly/experiments/FETCH_EXPERIMENT_SUCCESS";

const SAVE_EXPERIMENT = "firefly/experiments/SAVE_EXPERIMENT";
const SAVE_EXPERIMENT_SUCCESS = "firefly/experiments/SAVE_EXPERIMENT_SUCCESS";

/* HELPERS
================================================================================================ */
export const activateExperimentRequest = (id) =>
  request.patch(`/api/v1/experiments/${id}/activate/`);

export const createExperimentRequest = () =>
  request.post("/api/v1/experiments/");

export const createGroupRequest = () =>
  request.post("/api/v1/experiment_groups/");

export const getGoalFields = () =>
  range(1, GOALS_PER_EXPERIMENT + 1).map((i) => `goal_${i}`);

/* INITIAL STATES
================================================================================================ */
const initialState = {
  experiments: null,
  experimentCount: 0,
  totals: {},
  group: null,
  groups: null,
  groupCount: 0,
  groupTotals: {},
  allGroups: [],
  experiment: null,
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  let fieldsOfInterest;

  switch (action.type) {
    case FETCH_EXPERIMENTS_REQUEST:
      return {
        ...state,
        experiments: null,
      };
    case FETCH_EXPERIMENTS_SUCCESS:
      return {
        ...state,
        experiments: action.payload.results,
        experimentCount: action.payload.count,
      };
    case FETCH_TOTALS_SUCCESS:
      return {
        ...state,
        totals: action.payload,
      };
    case FETCH_GROUP_REQUEST:
      return {
        ...state,
        group: null,
      };
    case SAVE_GROUP_SUCCESS:
    case FETCH_GROUP_SUCCESS:
      return {
        ...state,
        group: action.payload,
      };
    case LAUNCH_EXPERIMENT_SUCCESS:
    case PAUSE_EXPERIMENT_SUCCESS:
    case RESUME_EXPERIMENT_SUCCESS:
    case COMPLETE_EXPERIMENT_SUCCESS:
      fieldsOfInterest = pick(
        action.payload,
        "id",
        "status",
        "last_action",
        "last_action_at",
        "last_action_by",
        "launched_at"
      );

      return {
        ...state,
        experiments: state.experiments
          ? state.experiments.map((c) =>
              c.id === fieldsOfInterest.id ? { ...c, ...fieldsOfInterest } : c
            )
          : state.experiments,
        experiment:
          state.experiment?.id === fieldsOfInterest.id
            ? { ...state.experiment, ...fieldsOfInterest }
            : state.experiment,
      };
    case FETCH_GROUPS_REQUEST:
      return {
        ...state,
        groups: null,
      };
    case FETCH_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.payload.results,
        groupCount: action.payload.count,
      };
    case FETCH_GROUP_TOTALS_SUCCESS:
      return {
        ...state,
        groupTotals: action.payload,
      };
    case PAUSE_GROUP_SUCCESS:
    case RESUME_GROUP_SUCCESS:
    case COMPLETE_GROUP_SUCCESS:
      fieldsOfInterest = pick(
        action.payload,
        "id",
        "status",
        "last_action",
        "last_action_at",
        "last_action_by"
      );

      return {
        ...state,
        groups: state.groups
          ? state.groups.map((g) =>
              g.id === fieldsOfInterest.id ? { ...g, ...fieldsOfInterest } : g
            )
          : state.groups,
        group:
          state.group?.id === fieldsOfInterest.id
            ? { ...state.group, ...fieldsOfInterest }
            : state.group,
      };
    case FETCH_ALL_GROUPS_SUCCESS:
      return {
        ...state,
        allGroups: action.payload,
      };
    case FETCH_EXPERIMENT_REQUEST:
      return {
        ...state,
        experiment: null,
      };
    case FETCH_EXPERIMENT_SUCCESS:
    case SAVE_EXPERIMENT_SUCCESS:
      return {
        ...state,
        experiment: action.payload,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const fetchExperiments = (query, status, groupId) => (dispatch) => {
  let statusVal;
  if (status !== "all") {
    if (status === "important") {
      statusVal = "live";
    } else {
      statusVal = status;
    }
  }

  const extraAPIQuery = {
    groups: groupId ? [groupId] : undefined,
    status: statusVal,
    important: status === "important" ? true : undefined,
  };
  const queryStr = getAPIQueryString(EXPERIMENTS_RESOURCE_NAME, query, {
    extraAPIQuery,
  });
  const promise = request.get(`/api/v1/experiments/?${queryStr}`);

  /* save page count in pager state */
  promise.then((res) =>
    dispatch(savePageCount(EXPERIMENTS_RESOURCE_NAME, res))
  );

  return dispatch({
    type: FETCH_EXPERIMENTS,
    promise,
  });
};

export const fetchTotals = (groupId) => (dispatch) => {
  const queryStr = queryString.stringify({ group_id: groupId });

  return dispatch({
    type: FETCH_TOTALS,
    promise: request.get(`/api/v1/experiments/totals/?${queryStr}`),
  });
};

export const fetchGroup = (id) => (dispatch) => {
  return dispatch({
    type: FETCH_GROUP,
    promise: request.get(`/api/v1/experiment_groups/${id}/`),
  });
};

export const cloneExperiment = (id) => (dispatch) => {
  const promise = request.post(`/api/v1/experiments/${id}/clone/`);

  promise
    .then((res) => {
      dispatch(toggleActionConfirmation(null));
      browserHistory.push(`${ADMIN_ROOT}/firefly/experiment/${res.data.id}/`);
    })
    .catch((err) => {
      const errorMsg = err.data ? JSON.stringify(err.data) : "Unknown error.";
      dispatch(setBannerError("Error duplicating experiment", errorMsg));
    });

  return promise;
};

export const deleteExperiment = (id) => (dispatch) => {
  const promise = request.delete(`/api/v1/experiments/${id}/`);

  promise
    .then(() => {
      browserHistory.push(`${ADMIN_ROOT}/firefly/experiments/all/`);
      dispatch(toggleActionConfirmation(null));
      dispatch(fetchTotals());
    })
    .catch((err) => {
      const errorMsg = err.data ? JSON.stringify(err.data) : "Unknown error.";
      dispatch(setBannerError("Error deleting experiment", errorMsg));
    });

  return promise;
};

const performExperimentAction = (id, actionName, actionType, dispatch) => {
  const promise = request.patch(`/api/v1/experiments/${id}/${actionName}/`);

  promise
    .then(() => {
      dispatch(toggleActionConfirmation(null));
      dispatch(fetchTotals());
    })
    .catch((err) => {
      const errorMsg = err.data ? JSON.stringify(err.data) : "Unknown error.";
      dispatch(
        setBannerError(
          `Error performing the following action: ${actionName}`,
          errorMsg
        )
      );
    });

  dispatch({ type: actionType, promise });

  return promise;
};

export const launchExperiment = (id) => (dispatch) =>
  performExperimentAction(id, "launch", LAUNCH_EXPERIMENT, dispatch);

export const pauseExperiment = (id) => (dispatch) =>
  performExperimentAction(id, "pause", PAUSE_EXPERIMENT, dispatch);

export const resumeExperiment = (id) => (dispatch) =>
  performExperimentAction(id, "resume", RESUME_EXPERIMENT, dispatch);

export const completeExperiment = (id) => (dispatch) =>
  performExperimentAction(id, "complete", COMPLETE_EXPERIMENT, dispatch);

export const fetchGroups = (query, status) => (dispatch) => {
  const extraAPIQuery = status === "all" ? {} : { status };
  const queryStr = getAPIQueryString(GROUPS_RESOURCE_NAME, query, {
    extraAPIQuery,
  });
  const promise = request.get(`/api/v1/experiment_groups/?${queryStr}`);

  /* save page count in pager state */
  promise.then((res) => dispatch(savePageCount(GROUPS_RESOURCE_NAME, res)));

  return dispatch({
    type: FETCH_GROUPS,
    promise,
  });
};

export const fetchGroupTotals = () => (dispatch) => {
  return dispatch({
    type: FETCH_GROUP_TOTALS,
    promise: request.get("/api/v1/experiment_groups/totals/"),
  });
};

export const cloneGroup = (id) => (dispatch) => {
  const promise = request.post(`/api/v1/experiment_groups/${id}/clone/`);

  promise
    .then((res) => {
      dispatch(toggleActionConfirmation(null));
      browserHistory.push(`${ADMIN_ROOT}/firefly/group/${res.data.id}/`);
    })
    .catch((err) => {
      const errorMsg = err.data ? JSON.stringify(err.data) : "Unknown error.";
      dispatch(setBannerError("Error duplicating group", errorMsg));
    });

  return promise;
};

export const deleteGroup = (id) => (dispatch) => {
  const promise = request.delete(`/api/v1/experiment_groups/${id}/`);

  promise
    .then(() => {
      browserHistory.push(`${ADMIN_ROOT}/firefly/groups/all/`);
      dispatch(toggleActionConfirmation(null));
      dispatch(fetchGroupTotals());
    })
    .catch((err) => {
      const errorMsg = err.data ? JSON.stringify(err.data) : "Unknown error.";
      dispatch(setBannerError("Error deleting group", errorMsg));
    });

  return promise;
};

const performGroupAction = (id, actionName, actionType, dispatch) => {
  const promise = request.patch(
    `/api/v1/experiment_groups/${id}/${actionName}/`
  );

  promise
    .then(() => {
      dispatch(toggleActionConfirmation(null));
      dispatch(fetchGroupTotals());
    })
    .catch((err) => {
      const errorMsg = err.data ? JSON.stringify(err.data) : "Unknown error.";
      dispatch(
        setBannerError(
          `Error performing the following action: ${actionName}`,
          errorMsg
        )
      );
    });

  dispatch({ type: actionType, promise });

  return promise;
};

export const pauseGroup = (id) => (dispatch) =>
  performGroupAction(id, "pause", PAUSE_GROUP, dispatch);

export const resumeGroup = (id) => (dispatch) =>
  performGroupAction(id, "resume", RESUME_GROUP, dispatch);

export const completeGroup = (id) => (dispatch) =>
  performGroupAction(id, "complete", COMPLETE_GROUP, dispatch);

export const fetchAllGroups = () => (dispatch) => {
  return dispatch({
    type: FETCH_ALL_GROUPS,
    promise: request.get("/api/v1/experiment_groups/all/"),
  });
};

export const saveGroup = (id, formData) => (dispatch) => {
  const promise = request.patch(`/api/v1/experiment_groups/${id}/`, formData);

  promise
    .then(() => {
      dispatch(
        showBanner(
          "Success",
          "Group successfully saved.",
          undefined,
          TYPE_SUCCESS,
          null,
          3000
        )
      );
    })
    .catch((err) => {
      const errorMsg = err.data
        ? JSON.stringify(err.data)
        : "Make sure the data you entered is correct.";
      dispatch(setBannerError("Error saving group", errorMsg));
    });

  dispatch({
    type: SAVE_GROUP,
    promise,
  });

  return promise;
};

export const fetchExperiment = (id) => (dispatch) => {
  const promise = request.get(`/api/v1/experiments/${id}/`);

  promise.catch((err) => {
    if (err.status === 404) {
      browserHistory.replace("/404/");
    }
  });

  return dispatch({
    type: FETCH_EXPERIMENT,
    promise,
  });
};

export const saveExperiment = (id, formData) => (dispatch, getState) => {
  const { experiment } = getState().firefly.experiments;
  const goalFields = getGoalFields();
  let data = {
    ...pick(formData, [
      ...goalFields,
      "description",
      "important",
      "name",
      "targeting_rules",
      "url",
    ]),
    groups: uniq(
      range(1, GROUPS_PER_EXPERIMENT + 1)
        .map((i) => formData[`group_${i}`])
        .filter((g) => !!g)
    ),
    variations: formData.variationsData.variations,
  };

  if (experiment.status !== "draft") {
    data = pick(
      data,
      "description",
      "groups",
      "important",
      "name",
      "url",
      "variations"
    );
  }

  const promise = request.patch(`/api/v1/experiments/${id}/`, data);

  promise
    .then(() => {
      dispatch(
        showBanner(
          "Success",
          "Experiment successfully saved.",
          undefined,
          TYPE_SUCCESS,
          null,
          3000
        )
      );
    })
    .catch((err) => {
      const errorMsg = err.data
        ? JSON.stringify(err.data)
        : "Make sure the data you entered is correct.";
      dispatch(setBannerError("Error saving experiment", errorMsg));
    });

  dispatch({
    type: SAVE_EXPERIMENT,
    promise,
  });

  return promise;
};
