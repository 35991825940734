import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import partial from "lodash/partial";

/**
 * Wrapup progress bar.
 *
 * @author Nathan Ozelim <nathan@startupfoundry.com>
 */
export default class ProgressBar extends Component {
  constructor(props) {
    super(props);

    this.state = { tooltipEnabled: true };

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.handleStepClick = this.handleStepClick.bind(this);
    this.goToLatestStep = this.goToLatestStep.bind(this);
  }

  toggleTooltip() {
    this.setState({ tooltipEnabled: !this.state.tooltipEnabled });
  }

  renderToolTip(idx, currentIdx) {
    return (
      <div className="tooltip show-for-large" onClick={this.toggleTooltip}>
        <h3>Step {idx + 1} Complete!</h3>

        <button className="btn btn-tertiary" onClick={this.goToLatestStep}>
          Go to Step {currentIdx + 1}
        </button>
      </div>
    );
  }

  handleStepClick(disabled, stepName) {
    if (!disabled) {
      this.props.onStepClick(stepName);
      /* enable tooltip as step changes */
      this.setState({ tooltipEnabled: true });
    }
  }

  goToLatestStep(e) {
    /* go to latest wrapup step */
    e.stopPropagation();
    const { steps, wrapup, onStepClick } = this.props;
    onStepClick(steps[wrapup.stepIdx].name);
  }

  renderSteps() {
    const { steps, wrapup, isCreative } = this.props;
    const { tooltipEnabled } = this.state;

    return steps.map((step, i) => {
      const classes = classNames("columns step", step.name, {
        active: step.active,
        completed: step.completed,
        available: step.available,
      });
      const handleClick = () =>
        this.handleStepClick(i > wrapup.stepIdx || step.active, step.name);

      return {
        component: (
          <div key={i} className={classes} onClick={handleClick}>
            <label>Step {i + 1}</label>
            <h4>{step.title}</h4>

            {isCreative &&
              step.completed &&
              step.active &&
              tooltipEnabled &&
              this.renderToolTip(i, wrapup.stepIdx)}
          </div>
        ),
        active: step.active,
      };
    });
  }

  render() {
    const steps = this.renderSteps();

    return (
      <div className="progress-bar">
        <div className="row">{steps.map((step) => step.component)}</div>
      </div>
    );
  }
}

ProgressBar.propTypes = {
  steps: PropTypes.array.isRequired,
  onStepClick: PropTypes.func.isRequired,
  isCreative: PropTypes.bool.isRequired,
};
