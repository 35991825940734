import React from "react";
import { Route, IndexRedirect, IndexRoute } from "react-router";
import cookie from "react-cookie";
import axios from "axios";
import at from "lodash/at";
import loadable from "loadable-components";
import { ADMIN_ROOT } from "common/variables";
import App from "common/containers/app";
import { LoadingIndicator } from "common/components/preloader";

/* In the server-side, components are always imported statically */
let marketingComponents,
  nonMarketingComponents,
  profileComponents,
  fgComponents,
  adminComponents,
  brandComponents;
if (!__CLIENT__) {
  marketingComponents = require("component_groups/marketing");
  nonMarketingComponents = require("component_groups/non_marketing");
  profileComponents = require("component_groups/profile");
  fgComponents = require("component_groups/focus_groups");
  adminComponents = require("component_groups/admin");
  brandComponents = require("component_groups/brand_studio");
}

const getComponent = (path, group) => {
  if (!__CLIENT__) {
    let module = nonMarketingComponents;
    if (group === "admin") {
      module = adminComponents;
    } else if (group === "marketing") {
      module = marketingComponents;
    } else if (group === "profile") {
      module = profileComponents;
    } else if (group === "focusGroups") {
      module = fgComponents;
    } else if (group === "brandStudio") {
      module = brandComponents;
    }
    return at(module, [path])[0];
  }

  let load = () => import("component_groups/non_marketing");
  if (group === "admin") {
    load = () => import("component_groups/admin");
  } else if (group === "marketing") {
    /* load only specific file if available */
    if (path === "LandingPage") {
      load = () => import("component_groups/marketing/landing_page");
    } else if (path === "HomePage") {
      load = () => import("component_groups/marketing/home");
    } else if (path === "HowPage") {
      load = () => import("component_groups/marketing/how");
    } else if (path === "TestimonialsPage") {
      load = () => import("component_groups/marketing/testimonials");
    } else if (path === "PricingPage") {
      load = () => import("component_groups/marketing/pricing");
    } else if (path === "ExternalCategories") {
      load = () => import("component_groups/marketing/external_categories");
    } else {
      load = () => import("component_groups/marketing");
    }
  } else if (group === "profile") {
    load = () => import("component_groups/profile");
  } else if (group === "focusGroups") {
    load = () => import("component_groups/focus_groups");
  } else if (group === "brandStudio") {
    load = () => import("component_groups/brand_studio");
  }

  return loadable(load, {
    render: ({ Component, loading, ownProps }) => {
      if (loading) {
        /* avoid flickering on very first render */
        if (window.isFirstRender) {
          const contentEl = document.getElementById("content");
          if (contentEl) {
            return (
              <div dangerouslySetInnerHTML={{ __html: contentEl.outerHTML }} />
            );
          }
        }
        return <LoadingIndicator fullScreen={false} alone={true} />;
      }

      const C = at(Component, [path])[0];

      return <C {...ownProps} />;
    },
  });
};

/* intercept all requests and add CSRF header appropriately */
axios.interceptors.request.use((config) => {
  const csrfToken = cookie.load("csrftoken");

  let headers = {
    ...config.headers,
    "X-FrontendURL": typeof window !== "undefined" ? window.location : "",
  };

  if (csrfToken) {
    headers = {
      ...headers,
      "X-CSRFToken": csrfToken,
    };
  }

  return {
    ...config,
    headers,
  };
});

/*
 * @param {Redux Store}
 * We require store as an argument here because we wish to get
 * state from the store after it has been authenticated.
 */
export default (store) => {
  function requireAuth(nextState, replace) {
    const state = store.getState();
    if (!state.user.logged_in) {
      replace({
        pathname: "/login/",
        state: { nextPathname: nextState.location.pathname },
      });
    }
  }

  function requireClientOrAnon(nextState, replace) {
    const state = store.getState();
    if (state.user.logged_in && !state.user.profile_data.is_client) {
      replace({
        pathname: "/clients-only-page/",
        state: { nextPathname: nextState.location.pathname },
      });
    }
  }

  function requireClient(nextState, replace) {
    const state = store.getState();
    if (!state.user.logged_in || !state.user.profile_data.is_client) {
      replace({
        pathname: "/clients-only-page/",
        state: { nextPathname: nextState.location.pathname },
      });
    }
  }

  function requireCreative(nextState, replace) {
    const state = store.getState();
    if (!state.user.logged_in || !state.user.profile_data.is_creative) {
      replace({
        pathname: "/creatives-only-page/",
        state: { nextPathname: nextState.location.pathname },
      });
    }
  }

  function checkEmailValidated(nextState, replace) {
    const state = store.getState();
    if (state.user.is_email_validated) {
      replace({
        pathname: "/your-projects",
        state: { nextPathname: nextState.location.pathname },
      });
    }
  }

  return (
    <Route path="/" component={App}>
      <IndexRoute component={getComponent("HomePage", "marketing")} />
      <Route path="login" component={getComponent("Login")} />
      <Route path="logout" component={getComponent("Logout")} />
      <Route
        path="reviews/case-studies/:pageSlug"
        component={getComponent("LandingPage", "marketing")}
      />
      <Route
        path="reviews/:pageSlug"
        component={getComponent("LandingPage", "marketing")}
      />
      <Route
        path="reviews"
        component={getComponent("TestimonialsPage", "marketing")}
      />
      <Route
        path="how-it-works"
        components={getComponent("HowPage", "marketing")}
      />
      <Route
        path="case-studies"
        components={getComponent("CaseStudies", "marketing")}
      />
      <Route path="books" components={getComponent("Books", "marketing")} />
      <Route path="guides" components={getComponent("Guides", "marketing")} />
      <Route
        path="webinars"
        components={getComponent("Webinars", "marketing")}
      />
      <Route
        path="quizzes"
        components={getComponent("LeadMagnets", "marketing")}
      />
      <Route
        path="pricing"
        components={getComponent("PricingPage", "marketing")}
      />
      <Route path="categories" component={(p) => p.children}>
        <IndexRoute
          component={getComponent("ExternalCategories", "marketing")}
        />
        <Route
          path="popular"
          component={getComponent("ExternalCategories", "marketing")}
        />
      </Route>
      <Route
        path="about-us"
        component={getComponent("About", "marketing")}
        pageClass="about-us"
      >
        <IndexRoute component={getComponent("Home", "marketing")} />
      </Route>
      <Route
        path="about-us/team"
        component={getComponent("About", "marketing")}
        pageClass="team"
      >
        <IndexRoute component={getComponent("Team", "marketing")} />
      </Route>
      <Route
        path="jobs"
        component={getComponent("About", "marketing")}
        pageClass="jobs"
      >
        <IndexRoute component={getComponent("Jobs", "marketing")} />
      </Route>
      <Route
        path="press"
        component={getComponent("Press.Wrapper", "marketing")}
      >
        <IndexRoute component={getComponent("Press.All", "marketing")} />
        <Route
          name="releases"
          path="releases"
          component={getComponent("Press.Releases", "marketing")}
        >
          <Route
            path="releases/:releaseSlug"
            component={getComponent("Press.Releases", "marketing")}
          />
        </Route>
        <Route
          name="news"
          path="in-the-news"
          component={getComponent("Press.News", "marketing")}
        />
      </Route>
      <Route
        path="partners/:pageSlug"
        component={getComponent("LandingPage", "marketing")}
      />
      <Route
        path="partners"
        component={getComponent("About", "marketing")}
        pageClass="partners"
      >
        <IndexRoute component={getComponent("Partners", "marketing")} />
      </Route>
      <Route
        path="affiliates"
        component={getComponent("About", "marketing")}
        pageClass="affiliates"
      >
        <IndexRoute component={getComponent("Affiliates", "marketing")} />
      </Route>
      <Route path="user-agreement" component={getComponent("Legal.Wrapper")}>
        <IndexRoute component={getComponent("Legal.Agreement")} />
      </Route>
      <Route path="privacy-policy" component={getComponent("Legal.Wrapper")}>
        <IndexRoute component={getComponent("Legal.Privacy")} />
      </Route>
      <Route path="copyright-policy" component={getComponent("Legal.Wrapper")}>
        <IndexRoute component={getComponent("Legal.Copyright")} />
      </Route>
      <Route
        path="standards-of-conduct-client"
        component={getComponent("Legal.Wrapper")}
      >
        <IndexRoute component={getComponent("Legal.StandardsClient")} />
      </Route>
      <Route
        path="standards-of-conduct-creative"
        component={getComponent("Legal.Wrapper")}
      >
        <IndexRoute component={getComponent("Legal.StandardsCreative")} />
      </Route>
      <Route
        path="refer-a-friend"
        component={getComponent("ReferAFriend")}
        onEnter={requireClient}
      />
      <Route path="referral/:referrer" component={getComponent("Referral")} />
      <Route
        path="whats-new/:userType"
        component={getComponent("ProductUpdates")}
        onEnter={requireAuth}
      />
      <Route
        path="verify/:verifyString/:hexString"
        component={getComponent("VerifyEmail")}
        onEnter={checkEmailValidated}
      />
      <Route
        path="reset-password/:uid/:key"
        component={getComponent("PasswordReset")}
      />
      <Route
        path="validate/id/:uid/:key"
        component={getComponent("ValidateId")}
      />

      <Route
        path="brand-studio"
        component={getComponent("NewBrand", "brandStudio")}
        onEnter={requireClientOrAnon}
      />
      <Route
        path="brand-studio/brand/:id/wizard"
        component={getComponent("BrandWizard", "brandStudio")}
        onEnter={requireClientOrAnon}
      />
      <Route
        path="brand-studio/brands"
        component={getComponent("Brands", "brandStudio")}
        onEnter={requireClient}
      />
      <Route
        path="brand-studio/refer-a-friend"
        component={getComponent("ReferAFriend", "brandStudio")}
        onEnter={requireClient}
      />
      <Route
        path="brand-studio/brand/:id"
        component={getComponent("Brand", "brandStudio")}
        onEnter={requireClient}
      >
        <IndexRoute
          name="details"
          component={getComponent("BrandDetails", "brandStudio")}
        />
        <Route
          name="templates"
          path="templates"
          component={getComponent("BrandTemplates", "brandStudio")}
        />
        <Route
          name="designs"
          path="designs"
          component={getComponent("BrandDesigns", "brandStudio")}
        />
      </Route>
      <Route
        path="brand-studio/brand/:brandId/design/:id"
        component={getComponent("DesignEditor", "brandStudio")}
        onEnter={requireClient}
      />

      <Route
        path="your-projects(/:userType)(/:projectType)"
        component={getComponent("YourProjects")}
        name="your-projects"
        onEnter={requireAuth}
      />

      <Route
        path="account/info"
        component={getComponent("Info")}
        onEnter={requireAuth}
      />
      <Route
        path="account/notifications"
        component={getComponent("NotificationSettings")}
        onEnter={requireAuth}
      />
      <Route
        path="account/id-verification"
        component={getComponent("IdVerification")}
        onEnter={requireAuth}
      />
      <Route
        path="account/billing"
        component={getComponent("Billing")}
        onEnter={requireAuth}
      />
      <Route
        path="account/deleted"
        component={getComponent("AccountDeleted")}
      />
      <Route
        path="account/suspended/:suspensionId"
        component={getComponent("AccountSuspended")}
      />
      <Route
        path="account/payments"
        component={getComponent("PayOut")}
        onEnter={requireAuth}
      >
        <IndexRedirect to="requested" />
        <Route
          path="requested"
          component={getComponent("RequestedPaymentsTable")}
        />
        <Route
          path="history"
          component={getComponent("HistoryPaymentsTable")}
        />
      </Route>

      <Route
        path="enable-payoneer"
        component={getComponent("EnablePayoneer")}
        onEnter={requireAuth}
      />

      <Route
        name="notifications"
        path="notifications"
        component={getComponent("NotificationsPage")}
        onEnter={requireAuth}
      />

      <Route
        name="vote-focus-group"
        path="focus-group-participate/:focusGroupId/:participantId/:participantKey"
        component={getComponent("Voting", "focusGroups")}
      />

      <Route
        name="vote-focus-group"
        path="focus-group-participate/:focusGroupId/:anonymousKey"
        component={getComponent("Voting", "focusGroups")}
      />

      <Route
        path="collaboration-invite/:projectId/:inviteId/:inviteKey"
        component={getComponent("AcceptCollaborationInvite")}
      />

      <Route path="explore" component={getComponent("Explore")}>
        <IndexRoute
          name="explore_projects"
          component={getComponent("ExploreProjects")}
        />
        <Route
          name="explore_creatives"
          path="creatives"
          component={getComponent("ExploreCreatives")}
        />
      </Route>

      <Route
        path="start-a-project/:categorySlug/:subCategorySlug/details/:stepName"
        component={getComponent("PAPDetails")}
        onEnter={requireClientOrAnon}
      />
      <Route
        path="start-a-project/:projectId/details/:stepName"
        component={getComponent("PAPDetailsV2")}
      />

      <Route
        path="start-a-project/:projectId/brief/all"
        component={getComponent("PAPBriefAllV2")}
      />
      <Route
        path="start-a-project/:projectId/brief(/:stepName)"
        component={getComponent("PAPBriefV2")}
      />
      <Route
        path="start-a-project/:categorySlug/:subCategorySlug/brief/all"
        component={getComponent("PAPBriefAllV2")}
        onEnter={requireClientOrAnon}
      />
      <Route
        path="start-a-project/:categorySlug/:subCategorySlug/brief(/:stepName)"
        component={getComponent("PAPBriefV2")}
        onEnter={requireClientOrAnon}
      />

      <Route
        path="start-a-project/:projectID/review"
        component={getComponent("PAPReview")}
      />

      <Route
        path="start-a-project/:projectID/payment"
        component={getComponent("PAPPayment")}
      />

      <Route
        path="start-a-project/start-1-1"
        component={getComponent("OtOSubcatSelection")}
        onEnter={requireClientOrAnon}
      />

      <Route
        path="start-a-project"
        component={(p) => p.children}
        onEnter={requireClientOrAnon}
      >
        <IndexRoute component={getComponent("Categories")} />
        <Route path="popular" component={getComponent("Categories")} />
      </Route>

      <Route
        path="select-1-1-creatives/:subcatSlugOrProjectId"
        component={getComponent("OtOCreativeSelection")}
      />

      <Route
        path="users/:username"
        component={getComponent("Profile", "profile")}
      >
        <Route
          path="testimonials"
          component={getComponent("ProfileTestimonials", "profile")}
        />
        <Route
          path="your-entries"
          component={getComponent("AllYourEntries", "profile")}
        >
          <Route
            path="image"
            component={getComponent("YourImageEntries", "profile")}
          />
          <Route
            path="name"
            component={getComponent("YourNameEntries", "profile")}
          />
        </Route>
        <Route path="names" component={getComponent("Portfolio", "profile")}>
          <IndexRoute component={getComponent("PortfolioNames", "profile")} />
        </Route>
        <Route
          path="image/:id"
          component={getComponent("PortfolioImageDetails", "profile")}
        />
        <Route
          path="name/:id"
          component={getComponent("PortfolioNameDetails", "profile")}
        />
      </Route>

      <Route path="register" component={getComponent("RegistrationStep1")} />
      <Route
        path="register/continue"
        component={getComponent("RegistrationStep2")}
        onEnter={requireAuth}
      />

      <Route
        path="goodbye-forums"
        component={getComponent("Deprecations.Wrapper")}
      >
        <IndexRoute component={getComponent("Deprecations.GoodbyeForums")} />
      </Route>

      <Route
        path="goodbye-privatemessages"
        component={getComponent("Deprecations.Wrapper")}
      >
        <IndexRoute component={getComponent("Deprecations.GoodbyePMs")} />
      </Route>

      <Route path="/help" component={getComponent("HelpPage")}>
        <IndexRoute component={getComponent("HelpHome")} />
        <Route
          path="search/:searchStr"
          component={getComponent("HelpSearch")}
        />
        <Route path="guides/:guideSlug" component={getComponent("HelpGuide")} />
        <Route path="guides" component={getComponent("AllHelpGuides")} />
        <Route
          path="contact/consultation-agencies"
          component={getComponent("ConsultationForAgencies")}
        />
        <Route
          path="contact/consultation-chinese"
          component={getComponent("ConsultationChinese")}
        />
        <Route
          path="contact/consultation"
          component={getComponent("Consultation")}
        />
        <Route path="contact" component={getComponent("ContactHelp")} />
        <Route
          path=":categorySlug/:articleSlug"
          component={getComponent("HelpArticle")}
        />
        <Route path=":categorySlug" component={getComponent("HelpCategory")} />
      </Route>

      <Route
        path="partner/promo/:codeSlug"
        component={getComponent("DiscountCode")}
      />

      <Route path={ADMIN_ROOT} component={getComponent("Admin", "admin")}>
        <IndexRedirect to="projects/all/" />
        <Route
          path="projects/all"
          component={getComponent("AdminProjects", "admin")}
        />
        <Route
          path="projects/1-to-1"
          component={getComponent("AdminProjects", "admin")}
          otoOnly={true}
        />
        <Route
          path="project/:projectId"
          component={getComponent("AdminProject", "admin")}
        >
          <IndexRedirect to="overview/" />
          <Route
            path="overview"
            component={getComponent("AdminProjectOverview", "admin")}
          />
          <Route
            path="billing"
            component={getComponent("AdminProjectBilling", "admin")}
          />
          <Route
            path="entries"
            component={getComponent("AdminProjectEntries", "admin")}
          />
          <Route
            path="entry/:entryId(/:drawerType)"
            component={getComponent("AdminProjectSingleEntry", "admin")}
          />
          <Route
            path="people"
            component={getComponent("AdminProjectPeople", "admin")}
          >
            <IndexRedirect to="participating" />
            <Route
              path="participating"
              component={getComponent("AdminProjectParticipating", "admin")}
            />
            <Route
              path="pending"
              component={getComponent("AdminProjectNDAs", "admin")}
            />
            <Route
              path="approved"
              component={getComponent("AdminProjectNDAs", "admin")}
            />
            <Route
              path="denied"
              component={getComponent("AdminProjectNDAs", "admin")}
            />
            <Route
              path="invited"
              component={getComponent("AdminProjectInvited", "admin")}
            />
            <Route
              path="removed"
              component={getComponent("AdminProjectRemoved", "admin")}
            />
            <Route
              path="watchers"
              component={getComponent("AdminProjectWatchers", "admin")}
            />
            <Route
              path="reservations"
              component={getComponent("AdminProjectReservations", "admin")}
            />
            <Route
              path="ranking"
              component={getComponent("AdminProjectRanking", "admin")}
            />
            <Route
              path="finalists"
              component={getComponent("AdminProjectFinalists", "admin")}
            />
          </Route>
          <Route
            path="brief"
            component={getComponent("AdminProjectBrief", "admin")}
          />
          <Route
            path="wrapup/:awardId"
            component={getComponent("ProjectWrapup")}
          />
          <Route
            path="activity"
            component={getComponent("AdminProjectActivity", "admin")}
          />
          <Route
            path="negotiation"
            component={getComponent("AdminProjectNegotiation", "admin")}
          />
        </Route>

        <Route
          path="people/unverified"
          component={getComponent("AdminUnverified", "admin")}
        />
        <Route
          path="people/clients"
          component={getComponent("AdminClients", "admin")}
        />
        <Route
          path="people/creatives"
          component={getComponent("AdminCreatives", "admin")}
        />
        <Route
          path="people/waitlist"
          component={getComponent("AdminWaiters", "admin")}
        />
        <Route
          path="people/recent-entries"
          component={getComponent("AdminRecentEntries", "admin")}
        />
        <Route
          path="people/blocked-emails/global"
          component={getComponent("GlobalBlockedDomains", "admin")}
        />
        <Route
          path="people/blocked-emails/unsubscribe-lists"
          component={getComponent("UnsubscribedEmails", "admin")}
        />
        <Route
          path="people/blocked-emails/email-mx-validations"
          component={getComponent("EmailMxValidations", "admin")}
        />

        <Route
          path="client/:userId"
          component={getComponent("AdminUser", "admin")}
        >
          <IndexRedirect to="overview/" />
          <Route
            path="overview"
            component={getComponent("AdminClientOverview", "admin")}
          />
          <Route
            path="projects"
            component={getComponent("AdminClientProjects", "admin")}
          >
            <IndexRedirect to="active/" />
            <Route
              path="drafts"
              component={getComponent("AdminClientDraftProjects", "admin")}
            />
            <Route
              path="active"
              component={getComponent("AdminClientActiveProjects", "admin")}
            />
            <Route
              path="wrapup"
              component={getComponent("AdminClientAwardedProjects", "admin")}
            />
            <Route
              path="completed"
              component={getComponent("AdminClientCompletedProjects", "admin")}
            />
          </Route>
          <Route
            path="payments"
            component={getComponent("AdminClientPayments", "admin")}
          >
            <IndexRedirect to="all/" />
            <Route
              path="all"
              component={getComponent("AdminClientAllPayments", "admin")}
            />
            <Route
              path="refunded"
              component={getComponent("AdminClientRefundedPayments", "admin")}
            />
          </Route>
          <Route
            path="profile"
            component={getComponent("AdminProfile", "admin")}
          />
          <Route
            path="notifications"
            component={getComponent("AdminUserNotifications", "admin")}
          />
          <Route
            path="activity"
            component={getComponent("AdminUserActivity", "admin")}
          />
          <Route
            path="announcements"
            component={getComponent("AdminUserAnnouncements", "admin")}
          />
        </Route>

        <Route
          path="creative/:userId"
          component={getComponent("AdminUser", "admin")}
        >
          <IndexRedirect to="overview/" />
          <Route
            path="overview"
            component={getComponent("AdminCreativeOverview", "admin")}
          />
          <Route
            path="entries"
            component={getComponent("AdminUserEntries", "admin")}
          />
          <Route
            path="projects"
            component={getComponent("AdminCreativeProjects", "admin")}
          >
            <IndexRedirect to="participating/" />
            <Route
              path="participating"
              component={getComponent(
                "AdminCreativeParticipatingProjects",
                "admin"
              )}
            />
            <Route
              path="wrapup"
              component={getComponent("AdminCreativeWrapupProjects", "admin")}
            />
            <Route
              path="awarded"
              component={getComponent("AdminCreativeAwardedProjects", "admin")}
            />
            <Route
              path="history"
              component={getComponent("AdminCreativeHistoryProjects", "admin")}
            />
          </Route>
          <Route
            path="profile"
            component={getComponent("AdminProfile", "admin")}
          />
          <Route
            path="notifications"
            component={getComponent("AdminUserNotifications", "admin")}
          />
          <Route
            path="activity"
            component={getComponent("AdminUserActivity", "admin")}
          />
          <Route
            path="payments"
            component={getComponent("AdminCreativePayments", "admin")}
          >
            <IndexRedirect to="requested/" />
            <Route
              path="requested"
              component={getComponent(
                "AdminCreativeRequestedPayments",
                "admin"
              )}
            />
            <Route
              path="history"
              component={getComponent("AdminCreativeHistoryPayments", "admin")}
            />
          </Route>
          <Route
            path="reputation"
            component={getComponent("AdminCreativeReputation", "admin")}
          />
          <Route
            path="subcat-reputation"
            component={getComponent("AdminCreativeSubcatReputation", "admin")}
          />
          <Route
            path="quality-review"
            component={getComponent("AdminQualityReview", "admin")}
          />
          <Route
            path="announcements"
            component={getComponent("AdminUserAnnouncements", "admin")}
          />
        </Route>

        <Route
          path="creative-verification/:userId"
          component={getComponent("AdminUser", "admin")}
        />

        <Route
          path="money/payments"
          component={getComponent("AdminPayments", "admin")}
        />

        <Route
          path="money/payment/:paymentId"
          component={getComponent("SinglePayment", "admin")}
        >
          <IndexRedirect to="overview/" />
          <Route
            path="overview"
            component={getComponent("PaymentOverview", "admin")}
          />
        </Route>

        <Route
          path="money/batches"
          component={getComponent("AdminBatches", "admin")}
        />

        <Route
          path="money/payout-requests"
          component={getComponent("AdminPayoutRequests", "admin")}
        />
        <Route
          path="money/payout-request/:id"
          component={getComponent("AdminPayoutRequest", "admin")}
        />

        <Route
          path="reviews"
          component={getComponent("AdminReviews", "admin")}
        />
        <Route
          path="review/:reviewId"
          component={getComponent("AdminReview", "admin")}
        />

        <Route
          path="marketing"
          component={getComponent("AdminMarketing", "admin")}
        />

        <Route
          path="honeybee/emails/:status"
          component={getComponent("HoneybeeEmails", "admin")}
        />
        <Route
          path="honeybee/email/:id"
          component={getComponent("HoneybeeEmail", "admin")}
        />

        <Route
          path="bumblebee/automations/:status"
          component={getComponent("BumblebeeAutomations", "admin")}
        />
        <Route
          path="bumblebee/automation/:id"
          component={getComponent("BumblebeeAutomation", "admin")}
        >
          <Route
            path="leads"
            component={getComponent("BumblebeeLeads", "admin")}
          />
        </Route>
        <Route
          path="bumblebee/groups/:status"
          component={getComponent("BumblebeeGroups", "admin")}
        />
        <Route
          path="bumblebee/group/:groupId/automations/:status"
          component={getComponent("BumblebeeAutomations", "admin")}
        />
        <Route
          path="bumblebee/group/:id"
          component={getComponent("BumblebeeGroup", "admin")}
        />
        <Route
          path="bumblebee/leads"
          component={getComponent("BumblebeeLeads", "admin")}
        />
        <Route
          path="bumblebee/rss-automation-creators"
          component={getComponent("RssAutomationCreators", "admin")}
        />
        <Route
          path="bumblebee/rss-automation-creator/:id"
          component={getComponent("RssAutomationCreator", "admin")}
        />

        <Route
          path="announcements"
          component={getComponent("AdminAnnouncements", "admin")}
        />
        <Route
          path="announcement/:id"
          component={getComponent("AdminAnnouncement", "admin")}
        />

        <Route
          path="contact-lists"
          component={getComponent("ContactLists", "admin")}
        />
        <Route
          path="contact-list/:id"
          component={getComponent("ContactList", "admin")}
        />
        <Route
          path="contact-list/:listId/contact/:id"
          component={getComponent("Contact", "admin")}
        />

        <Route
          path="brand-studio"
          component={getComponent("AdminBrandStudio", "admin")}
        />

        <Route
          path="hive/monitored-url/new"
          component={getComponent("HiveUrl", "admin")}
        />
        <Route
          path="hive/monitored-url/:id"
          component={getComponent("HiveUrl", "admin")}
        />
        <Route
          path="hive/monitored-url-group/:id"
          component={getComponent("HiveUrlGroup", "admin")}
        />
        <Route
          path="hive/monitored-urls/"
          component={getComponent("HiveUrls", "admin")}
        />
        <Route
          path="hive/monitored-url-group/:groupId/monitored-urls"
          component={getComponent("HiveUrls", "admin")}
        />
        <Route
          path="hive/monitored-url-groups/"
          component={getComponent("HiveUrlGroups", "admin")}
        />
        <Route
          path="hive/monitored-url-domains/"
          component={getComponent("HiveUrlDomains", "admin")}
        />

        <Route
          path="searched-terms"
          component={getComponent("SearchedTerms", "admin")}
        />

        <Route
          path="wasp/campaigns/:status"
          component={getComponent("WaspCampaigns", "admin")}
        />
        <Route
          path="wasp/groups/:status"
          component={getComponent("WaspGroups", "admin")}
        />
        <Route
          path="wasp/group/:id"
          component={getComponent("WaspGroup", "admin")}
        />
        <Route
          path="wasp/group/:groupId/campaigns/:status"
          component={getComponent("WaspCampaigns", "admin")}
        />
        <Route
          path="wasp/campaign/:id"
          component={getComponent("WaspCampaign", "admin")}
        >
          <Route
            path="domains/inspect"
            component={getComponent("WaspDomainsInspect", "admin")}
          />
          <Route
            path="domains/personalize"
            component={getComponent("WaspDomainsPersonalize", "admin")}
          />
          <Route
            path="domains/send"
            component={getComponent("WaspDomainsSend", "admin")}
          />
          <Route
            path="blocked-domains"
            component={getComponent("WaspCampaignBlockedDomains", "admin")}
          />
          <Route
            path="excluded-domains"
            component={getComponent("WaspCampaignExcludedDomains", "admin")}
          />
        </Route>
        <Route
          path="wasp/blocked-domains"
          component={getComponent("WaspBlockedDomains", "admin")}
        />
        <Route
          path="wasp/blocked-reasons"
          component={getComponent("WaspBlockedReasons", "admin")}
        />
        <Route
          path="wasp/domain-lists"
          component={getComponent("WaspDomainLists", "admin")}
        />
        <Route
          path="wasp/domain-list/:id"
          component={getComponent("WaspDomainList", "admin")}
        />
        <Route
          path="wasp/domain-list/:listId/domain/:id"
          component={getComponent("WaspSavedDomain", "admin")}
        />
        <Route
          path="wasp/analytics/summary"
          component={getComponent("WaspAnalyticsSummary", "admin")}
        />
        <Route
          path="wasp/analytics/individual"
          component={getComponent("WaspAnalyticsIndividual", "admin")}
        />
        <Route
          path="wasp/analytics/team"
          component={getComponent("WaspAnalyticsTeam", "admin")}
        />
        <Route
          path="wasp/analytics/hive"
          component={getComponent("WaspAnalyticsHive", "admin")}
        />

        <Route
          path="firefly/experiments/:status"
          component={getComponent("FireflyExperiments", "admin")}
        />
        <Route
          path="firefly/group/:groupId/experiments/:status"
          component={getComponent("FireflyExperiments", "admin")}
        />
        <Route
          path="firefly/groups/:status"
          component={getComponent("FireflyGroups", "admin")}
        />
        <Route
          path="firefly/group/:id"
          component={getComponent("FireflyGroup", "admin")}
        />
        <Route
          path="firefly/experiment/:id"
          component={getComponent("FireflyExperiment", "admin")}
        >
          <Route
            path="insights"
            component={getComponent("FireflyAnalytics", "admin")}
          />
        </Route>

        <Route path="honey" component={getComponent("Honey", "admin")} />
      </Route>

      <Route
        path="inactive-user"
        component={getComponent("InactiveUser", "profile")}
      />
      <Route path="error" component={getComponent("ErrorPage")} status={500} />
      <Route path="404" component={getComponent("ErrorPage")} status={404} />
      <Route
        path="forbidden-private-project"
        component={getComponent("Forbidden1to1")}
      />
      <Route
        path="project-down-for-nap"
        component={getComponent("ProjectDown")}
      />
      <Route path="clients-only-page" component={getComponent("ClientsOnly")} />
      <Route
        path="creatives-only-page"
        component={getComponent("CreativesOnly")}
      />
      <Route
        path="redeem-registration-invite/:hash"
        component={getComponent("RedeemRegistrationInvite")}
      />
      <Route
        path=":service/unsubscribe/:unsubscribe_uuid"
        component={getComponent("EmailAutomationsUnsubscribe")}
      />
      <Route
        path="confirm-subscription/:id"
        component={getComponent("ConfirmSubscription")}
      />

      <Route
        path="/:subcategory/:pageSlug"
        component={getComponent("ProjectOrLanding")}
      >
        {/* Brief view is used as the index because it'll always be present for all possible
            project variations. */}
        <IndexRoute name="brief" component={getComponent("ProjectBrief")} />
        <Route
          name="entries"
          path="entries"
          component={getComponent("Entries")}
        />

        <Route
          name="single"
          path="entry/:entryId"
          component={getComponent("SingleEntry")}
        >
          <IndexRoute
            name="singleEntry"
            component={getComponent("SingleEntryIndex")}
          />
          <Route
            name="singleComments"
            path="comments"
            component={getComponent("SingleEntryComments")}
            onEnter={requireAuth}
            i
          />
          <Route
            name="singleRevisions"
            path="revisions"
            component={getComponent("SingleEntryRevisions")}
          />
        </Route>

        <Route
          name="your-entries"
          path="your-entries"
          component={getComponent("Entries")}
          onEnter={requireCreative}
        />

        <Route
          name="wrapup"
          path="wrapup/intro"
          component={getComponent("ProjectWrapupIntro")}
          onEnter={requireAuth}
        />
        <Route
          name="wrapup"
          path="wrapup/:awardId"
          component={getComponent("ProjectWrapup")}
          onEnter={requireAuth}
        />

        <Route
          path="focus-groups"
          component={getComponent("All", "focusGroups")}
          onEnter={requireClient}
        />
        <Route
          path="focus-groups/new"
          component={getComponent("New", "focusGroups")}
          onEnter={requireClient}
        />
        <Route
          path="focus-groups/:focusGroupId/edit-entries"
          component={getComponent("EditEntries", "focusGroups")}
          onEnter={requireClient}
        />
        <Route
          path="focus-groups/:focusGroupId/add-participants"
          component={getComponent("AddParticipants", "focusGroups")}
          onEnter={requireClient}
        />

        <Route
          name="presentations-new"
          path="presentations/new"
          component={getComponent("NewPresentation")}
          onEnter={requireClient}
        />
        <Route
          path="presentations/:presentationId"
          component={getComponent("Presentation")}
          onEnter={requireClient}
        >
          <IndexRedirect to="results" />
          <Route path="results" component={getComponent("PresentationResults")}>
            <Route
              path=":revisionId"
              component={getComponent("PresentationRevisionResults")}
            >
              <IndexRedirect to="comments" />
              <Route
                path="comments"
                component={getComponent("PresentationRevisionComments")}
              />
              <Route
                path="votes"
                component={getComponent("PresentationRevisionVotes")}
              />
            </Route>
          </Route>
          <Route
            path="settings"
            component={getComponent("PresentationSettings")}
          />
          <Route
            path="participants"
            component={getComponent("PresentationParticipants")}
          />
        </Route>

        <Route
          name="settings"
          path="settings"
          component={getComponent("ProjectSettings")}
          onEnter={requireClient}
        >
          <Route
            name="pending"
            path="pending"
            component={getComponent("PendingAccess")}
          />
          <Route
            name="approved"
            path="approved"
            component={getComponent("ApprovedAccess")}
          />
          <Route
            name="denied"
            path="denied"
            component={getComponent("DeniedAccess")}
          />
          <Route
            name="invites"
            path="invites"
            component={getComponent("Invited")}
          />
          <Route
            name="removed"
            path="removed"
            component={getComponent("RemovedAccess")}
          />
          <Route
            name="participants"
            path="participants"
            component={getComponent("Participants")}
          />
        </Route>

        <Route
          path="tour"
          component={getComponent("ProjectTourIntro")}
          onEnter={requireClient}
        />

        <Route
          path="negotiation"
          component={getComponent("ProjectNegotiation")}
        />
      </Route>
      <Route
        path="(:parentSlug1/)(:parentSlug2/):pageSlug"
        component={getComponent("LandingPage", "marketing")}
      />
      <Route path="*" component={getComponent("ErrorPage")} status={404} />
    </Route>
  );
};
