import { URL_CHANGE } from "header/ducks";
import { fetchNotifications } from "notifications/ducks";
import { setDrawerContent } from "common/ducks/right_drawer";

/*
 * Ignore location changes in case the URL path has changed between the calls
 * `next(action)` and `getState()`.
 * It should only happen if another `LOCATION_CHANGE` action was processed
 * by the reducer in the meantime.
 */
function shouldIgnore(state, action) {
  /* new path passed along the `LOCATION_CHANGE` action */
  const actionPath = action.payload.pathname;
  /* new path as read from the state */
  const statePath = state.routing.locationBeforeTransitions.pathname;
  return actionPath !== statePath;
}

/*
 * Only fetch notifications upon URL changes if
 * - user is logged in
 * - user is not on notifications page
 * - the URL change is not about query updates related to resource sorting/filtering/searching/etc
 *   (which otherwise could trigger lots of unnecessary API requests)
 */
function shouldFetchNotifications(state, action) {
  const hasResourceQueries = !!Object.keys(action.payload.query).find(
    (q) =>
      q.includes("sort") ||
      q.includes("filter") ||
      q.includes("search") ||
      q.includes("page")
  );
  const onNotificationsPage =
    action.payload.pathname.match(/^\/notifications\//);
  return state.user.logged_in && !onNotificationsPage && !hasResourceQueries;
}

/**
 * Header middleware that controls what should be visible in the header component
 * @author Kuba Siemiątkowski <kuba@crowdspring.com>
 */
const headerMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    if (!action.type) {
      return action;
    }

    const nextAction = next(action);
    const state = getState();

    if (
      action.type === "@@router/LOCATION_CHANGE" &&
      !shouldIgnore(state, action)
    ) {
      dispatch({
        type: URL_CHANGE,
        payload: {
          pathname: action.payload.pathname,
          user: state.user,
        },
      });

      if (shouldFetchNotifications(state, action)) {
        dispatch(fetchNotifications(state.user.profile_data.id));
      }

      dispatch(setDrawerContent(action.payload.pathname));
    }

    return nextAction;
  };

export default headerMiddleware;
