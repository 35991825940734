import { connect } from "react-redux";
import Header from "your_projects/components/header";

const mapStateToProps = ({ user }) => {
  return {
    user: user.profile_data,
  };
};

export default connect(mapStateToProps)(Header);
