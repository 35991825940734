import { combineReducers } from "redux";
import { routerReducer as routing } from "react-router-redux";
import categories from "common/ducks/categories";
import user from "common/ducks/user";
import contract from "common/ducks/contract";
import billing from "common/ducks/billing";
import message from "common/ducks/message";
import industry from "common/ducks/industry";
import payout from "common/ducks/payout";
import banner from "common/ducks/banner";
import explore from "explore/ducks";
import project from "project/ducks";
import pap from "pap/ducks";
import { RESET_PAP } from "pap/ducks/workflow";
import header from "header/ducks";
import rightDrawer from "common/ducks/right_drawer";
import error from "error/ducks";
import notificationSettings from "account/ducks/notification_settings";
import creativeRegistration from "registration/creative/ducks";
import notificationsReducer from "notifications/ducks";
import { reducer as formReducer } from "redux-form";
import helpReducer from "help/ducks";
import landingPageReducer from "landing_pages/ducks";
import inviteReducer from "invite/ducks";
import adminReducer from "admin/ducks";
import singleEntryReducer from "single_entry/ducks";
import { modalReducer } from "common/modal";
import serverReducer from "common/server";
import sorterReducer from "common/sorter/ducks";
import searcherReducer from "common/searcher/ducks";
import filterReducer from "common/filter/ducks";
import pagerReducer from "common/pager/ducks";
import marketingReducer from "marketing/ducks";
import productUpdatesReducer from "product_updates/ducks";
import entriesReducer from "entries/ducks";
import pap0Reducer from "pap0/ducks";
import yourProjectsReducer from "your_projects/ducks";
import announcementsReducer from "announcements/ducks";
import emailAutomationsReducer from "email_automations/ducks";
import announcementsAdminReducer from "announcements_admin/ducks";
import oneToOneReducer from "one_to_one/ducks";
import profileReducer from "profile/ducks";
import focusGroupsReducer from "focus_groups/ducks";
import itemNavigationReducer from "common/ducks/item_navigation";
import brandStudioReducer from "brand_studio/ducks";
import tourReducer from "common/ducks/tour";
import hiveReducer from "hive/ducks";
import searchedTermsReducer from "searched_terms/ducks";
import waspReducer from "wasp/ducks";
import fireflyReducer from "firefly/ducks";
import honeyReducer from "honey/ducks";
import twoFactorAuthReducer from "account/ducks/two_factor_auth";

// Combine reducers with routeReducer which keeps track of
// router state
const rootReducer = combineReducers({
  categories,
  project,
  routing,
  user,
  contract,
  billing,
  message,
  industry,
  payout,
  banner,
  explore,
  pap,
  header,
  rightDrawer,
  error,
  creativeRegistration,
  notificationSettings,
  notifications: notificationsReducer,
  form: formReducer.plugin({
    pap_wizard: (state, action) => {
      switch (action.type) {
        case RESET_PAP:
          return undefined;
        default:
          return state;
      }
    },
  }),
  help: helpReducer,
  invite: inviteReducer,
  admin: adminReducer,
  landing_pages: landingPageReducer,
  single_entry: singleEntryReducer,
  modal: modalReducer,
  server: serverReducer,
  sorter: sorterReducer,
  searcher: searcherReducer,
  filter: filterReducer,
  pager: pagerReducer,
  marketing: marketingReducer,
  productUpdates: productUpdatesReducer,
  entries: entriesReducer,
  pap0: pap0Reducer,
  yourProjects: yourProjectsReducer,
  announcements: announcementsReducer,
  emailAutomations: emailAutomationsReducer,
  announcementsAdmin: announcementsAdminReducer,
  oneToOne: oneToOneReducer,
  profile: profileReducer,
  focusGroups: focusGroupsReducer,
  itemNavigation: itemNavigationReducer,
  brandStudio: brandStudioReducer,
  tour: tourReducer,
  hive: hiveReducer,
  searchedTerms: searchedTermsReducer,
  wasp: waspReducer,
  firefly: fireflyReducer,
  honey: honeyReducer,
  twoFactorAuth: twoFactorAuthReducer,
});

export default rootReducer;
