import { connect } from "react-redux";
import { withRouter } from "react-router";
import HeaderText from "header/components/title";

const mapStateToProps = () => {
  return {
    title: "Sorry, this profile is inactive...",
  };
};

export default withRouter(connect(mapStateToProps)(HeaderText));
