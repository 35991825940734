import { connect } from "react-redux";
import sortBy from "lodash/sortBy";
import omit from "lodash/omit";
import { excludeDeprecated } from "common/ducks/categories";
import { fetchCountriesIfNeeded } from "common/ducks/user";
import {
  CREATIVES_SORTING_OPTIONS,
  DEFAULT_CREATIVES_SORTING,
  CREATIVES_ELITE_OPTIONS,
  CREATIVES_PLATINUM_OPTIONS,
  CREATIVES_LANGUAGE_OPTIONS,
} from "explore/constants";
import CreativesControls from "explore/components/creatives_controls";

export const mapStateToProps = ({ user, categories }) => {
  const cats = excludeDeprecated(categories.data).filter(
    (cat) => !cat.is_one_to_one
  );
  const subcategoryOptions = sortBy(cats, "name").reduce((acc, cat) => {
    return acc.concat(
      sortBy(
        cat.subcategories.map((subcat) => {
          return {
            field: subcat.id.toString(),
            label: subcat.name,
            group: cat.name,
          };
        }),
        "label"
      )
    );
  }, []);

  return {
    sortingOptions: CREATIVES_SORTING_OPTIONS,
    defaultSorting: DEFAULT_CREATIVES_SORTING,
    eliteOptions: CREATIVES_ELITE_OPTIONS,
    platinumOptions: CREATIVES_PLATINUM_OPTIONS,
    countryOptions: user.countries.map((c) => omit(c, "group")),
    languageOptions: CREATIVES_LANGUAGE_OPTIONS,
    subcategoryOptions,
  };
};

export default connect(mapStateToProps, {
  fetchCountriesIfNeeded,
})(CreativesControls);
