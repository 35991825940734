import { connect } from "react-redux";
import { toggleNDAPreview } from "pap/ducks/workflow";
import ContractPreviewModal from "common/components/modal/contract_preview";

const mapStateToProps = ({ pap }) => {
  const {
    featureSelected,
    stepValues = {},
    project,
  } = pap.workflow.ndaPreviewOptions || {};

  /* value is determined according to the following precedence order:
     form (dirty) value -> project field value -> default value */
  let portfolioUseAllowed = true;
  if (featureSelected) {
    if (stepValues["nda_portfolio_use_allowed"] !== undefined) {
      portfolioUseAllowed = stepValues["nda_portfolio_use_allowed"];
    } else if (project) {
      portfolioUseAllowed = project["nda_portfolio_use_allowed"];
    }
  }

  return {
    showing: pap.workflow.showingNDAPreview,
    url: `/api/v1/projects/nda_sample/?portfolio_use_allowed=${portfolioUseAllowed}`,
    header: "Sample NDA",
  };
};

export default connect(mapStateToProps, {
  onClose: toggleNDAPreview,
})(ContractPreviewModal);
