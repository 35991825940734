import React from "react";
import idx from "idx";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getClientLabel } from "focus_groups/utils";
import { STAGE_DONE, STAGE_VOTE } from "focus_groups/constants";
import HeaderTitle from "header/components/title";

const mapStateToProps = ({ focusGroups }) => {
  const group = focusGroups.common.current;
  const project = idx(group, (_) => _.project);
  const clientLabel = getClientLabel(idx(project, (_) => _.client));
  const stage = focusGroups.voting.stage;
  let title, subTitle;

  if (idx(group, (_) => _.status) === "closed") {
    title = "This focus group has closed.";
  } else if (stage === STAGE_VOTE) {
    title = `${clientLabel} would love your feedback.`;
    subTitle = (
      <span>
        Rate your favorites and leave your feedback below. Click any
        <br />
        design to make it larger. Make your vote count!
      </span>
    );
  } else if (stage === STAGE_DONE) {
    title = `Thanks for helping ${clientLabel}!`;
  }

  return {
    title,
    subTitle,
  };
};

export default withRouter(connect(mapStateToProps)(HeaderTitle));
