import React from "react";
import PropTypes from "prop-types";
import {
  TYPE_SELECT,
  TYPE_CHECKBOXES,
  TYPE_RANGE_SELECT,
} from "common/filter/constants";
import { PROJECTS_RESOURCE_NAME } from "your_projects/constants";
import Searcher from "common/searcher/containers";
import SorterSelect from "common/sorter/components/select";
import Filter from "common/filter/containers";
import { PARAMS_MAP } from "your_projects/ducks";
import { PROJECT_STATUS_OPTIONS } from "project/constants";

const Controls = ({
  sortingOptions,
  subcategoryOptions,
  industryOptions,
  assuredOptions,
  packageOptions,
  awardsOptions,
  skipQuerySave,
  params,
}) => {
  if (params.userType === "client") {
    return null;
  }

  const statusOptions = [];
  if (params.userType && params.projectType) {
    (PARAMS_MAP[params.userType][params.projectType].status || []).forEach(
      (s) => {
        const option = PROJECT_STATUS_OPTIONS.find((o) => s === o.field);
        if (option) {
          statusOptions.push({ field: s, label: option.label });
        }
      }
    );
  }

  return (
    <aside className="sidebar sidebar-filter">
      <div className="controls-group">
        <Searcher resourceName={PROJECTS_RESOURCE_NAME} />
      </div>
      <div className="controls-group">
        <h5>Sort</h5>
        <SorterSelect
          resourceName={PROJECTS_RESOURCE_NAME}
          options={sortingOptions}
          defaultValue="-updated_at"
        />
      </div>
      <div className="controls-group">
        <h5>Industry</h5>
        <Filter
          field="industry"
          type={TYPE_SELECT}
          resourceName={PROJECTS_RESOURCE_NAME}
          options={industryOptions}
          skipQuerySave={skipQuerySave}
        />
      </div>
      <div className="controls-group">
        <h5>Award</h5>
        <Filter
          field="total_awards_and_tips"
          type={TYPE_RANGE_SELECT}
          resourceName={PROJECTS_RESOURCE_NAME}
          options={awardsOptions}
          skipQuerySave={skipQuerySave}
        />
      </div>
      {statusOptions.length > 1 && params.projectType === "participating" && (
        <div className="controls-group">
          <h5>Status</h5>
          <Filter
            field="filter_status"
            type={TYPE_CHECKBOXES}
            resourceName={PROJECTS_RESOURCE_NAME}
            options={statusOptions}
            skipQuerySave={skipQuerySave}
          />
        </div>
      )}
      <div className="controls-group">
        <h5>Category</h5>
        <Filter
          field="subcategory"
          type={TYPE_CHECKBOXES}
          resourceName={PROJECTS_RESOURCE_NAME}
          options={subcategoryOptions}
          skipQuerySave={skipQuerySave}
        />
      </div>
      <div className="controls-group">
        <h5>Type</h5>
        <Filter
          field="assured"
          type={TYPE_CHECKBOXES}
          resourceName={PROJECTS_RESOURCE_NAME}
          options={assuredOptions}
          skipQuerySave={skipQuerySave}
        />
        <Filter
          field="package_name"
          type={TYPE_CHECKBOXES}
          resourceName={PROJECTS_RESOURCE_NAME}
          options={packageOptions}
          skipQuerySave={skipQuerySave}
        />
      </div>
    </aside>
  );
};

export default Controls;
