import request from "axios";
import { URL_CHANGE } from "header/ducks";
import { PAGINATION_PAGE_SIZE } from "common/variables";
import { getPaginationValues } from "common/utils";
import { logout } from "common/ducks/user";

export const TOGGLE_DRAWER = "crowdspring/notifications/TOGGLE_DRAWER";
export const FETCH_NOTIFICATIONS =
  "crowdspring/notifications/FETCH_NOTIFICATIONS";
export const FETCH_NOTIFICATIONS_REQUEST =
  "crowdspring/notifications/FETCH_NOTIFICATIONS_REQUEST";
export const FETCH_NOTIFICATIONS_FAILURE =
  "crowdspring/notifications/FETCH_NOTIFICATIONS_FAILURE";
export const FETCH_NOTIFICATIONS_SUCCESS =
  "crowdspring/notifications/FETCH_NOTIFICATIONS_SUCCESS";

export const READ_NOTIFICATION = "crowdspring/notifications/READ_NOTIFICATION";
export const READ_NOTIFICATION_REQUEST =
  "crowdspring/notifications/READ_NOTIFICATION_REQUEST";
export const READ_NOTIFICATION_FAILURE =
  "crowdspring/notifications/READ_NOTIFICATION_FAILURE";
export const READ_NOTIFICATION_SUCCESS =
  "crowdspring/notifications/READ_NOTIFICATION_SUCCESS";

export const UNREAD_NOTIFICATION =
  "crowdspring/notifications/UNREAD_NOTIFICATION";
export const UNREAD_NOTIFICATION_REQUEST =
  "crowdspring/notifications/UNREAD_NOTIFICATION_REQUEST";
export const UNREAD_NOTIFICATION_FAILURE =
  "crowdspring/notifications/UNREAD_NOTIFICATION_FAILURE";
export const UNREAD_NOTIFICATION_SUCCESS =
  "crowdspring/notifications/UNREAD_NOTIFICATION_SUCCESS";

export const SEE_NOTIFICATION = "crowdspring/notifications/SEE_NOTIFICATION";
export const SEE_NOTIFICATION_REQUEST =
  "crowdspring/notifications/SEE_NOTIFICATION_REQUEST";
export const SEE_NOTIFICATION_FAILURE =
  "crowdspring/notifications/SEE_NOTIFICATION_FAILURE";
export const SEE_NOTIFICATION_SUCCESS =
  "crowdspring/notifications/SEE_NOTIFICATION_SUCCESS";

export const DELETE_NOTIFICATION =
  "crowdspring/notifications/DELETE_NOTIFICATION";
export const DELETE_NOTIFICATION_REQUEST =
  "crowdspring/notifications/DELETE_NOTIFICATION_REQUEST";
export const DELETE_NOTIFICATION_FAILURE =
  "crowdspring/notifications/DELETE_NOTIFICATION_FAILURE";
export const DELETE_NOTIFICATION_SUCCESS =
  "crowdspring/notifications/DELETE_NOTIFICATION_SUCCESS";

export const TOGGLE_SELECTED = "crowdspring/notifications/TOGGLE_SELECTED";
export const TOGGLE_ALL_SELECTED =
  "crowdspring/notifications/TOGGLE_ALL_SELECTED";

export function toggleDrawer() {
  return {
    type: TOGGLE_DRAWER,
  };
}

export function toggleSelected(notificationId) {
  return {
    type: TOGGLE_SELECTED,
    payload: notificationId,
  };
}

export function toggleAllSelected(notifications) {
  return {
    type: TOGGLE_ALL_SELECTED,
    payload: notifications.map(({ uuid }) => uuid),
  };
}

export const fetchNotifications =
  (userId, offset = 0) =>
  (dispatch) => {
    const opts = {
      method: "get",
      url: `/api/v1/users/${userId}/notifications/?limit=${PAGINATION_PAGE_SIZE}&offset=${offset}`,
    };
    const promise = request(opts);

    promise.catch((res) => {
      if (res.status === 403) {
        dispatch(logout(undefined, true));
        return;
      }
    });

    return dispatch({
      type: FETCH_NOTIFICATIONS,
      promise,
    });
  };

const ActionMap = {
  read: READ_NOTIFICATION,
  unread: UNREAD_NOTIFICATION,
  see: SEE_NOTIFICATION,
  delete: DELETE_NOTIFICATION,
};

const ActionMethodMap = {
  read: "patch",
  unread: "patch",
  see: "patch",
  delete: "delete",
};

export function notificationAction(action, userId, uuid) {
  const uuids = typeof uuid === "string" ? [uuid] : uuid;
  const opts = {
    method: ActionMethodMap[action],
    data: { uuids },
    url: `/api/v1/users/${userId}/notifications/${action}/`,
  };

  return {
    type: ActionMap[action],
    promise: request(opts),
    payload: { userId, uuids },
  };
}

export const INITIAL_STATE = {
  isLoading: false,
  data: {},
  showDrawer: false,
  actionType: null,
  selectedNotifications: [],
  pageCount: PAGINATION_PAGE_SIZE,
  currentPage: 1,
  notificationCount: 0,
};

export function reduceNotification(state, action) {
  if (action.payload.uuids.indexOf(state.uuid) === -1) {
    return state;
  }

  switch (action.type) {
    case READ_NOTIFICATION_REQUEST:
      return {
        ...state,
        read: true,
      };
    case UNREAD_NOTIFICATION_REQUEST:
      return {
        ...state,
        read: false,
      };
    case SEE_NOTIFICATION_REQUEST:
      return {
        ...state,
        seen: true,
      };
    case DELETE_NOTIFICATION_REQUEST:
      return null;
    default:
      return state;
  }
}

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TOGGLE_DRAWER:
      return {
        ...state,
        showDrawer: !state.showDrawer,
      };
    case URL_CHANGE: {
      return {
        ...state,
        showDrawer: false,
      };
    }
    case FETCH_NOTIFICATIONS:
    case FETCH_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_NOTIFICATIONS_SUCCESS:
      const { currentPage, pageCount } = getPaginationValues(action.payload);

      return {
        ...state,
        isLoading: false,
        data: action.payload,
        currentPage,
        pageCount,
        notificationCount: action.payload.count,
      };

    case READ_NOTIFICATION_REQUEST:
    case UNREAD_NOTIFICATION_REQUEST:
    case SEE_NOTIFICATION_REQUEST:
    case DELETE_NOTIFICATION_REQUEST:
      const reducedResults = state.data.results.map((notification) =>
        reduceNotification(notification, action)
      );

      const data = {
        ...state.data,
        results: reducedResults.filter((result) => result !== null),
      };

      return {
        ...state,
        data,
      };

    case READ_NOTIFICATION_SUCCESS:
    case UNREAD_NOTIFICATION_SUCCESS:
    case DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        selectedNotifications: [],
      };
    case TOGGLE_SELECTED:
      const listIndex = state.selectedNotifications.indexOf(action.payload);
      const addedNotification = [
        ...state.selectedNotifications,
        action.payload,
      ];
      const removedNotification = state.selectedNotifications
        .slice(0, listIndex)
        .concat(state.selectedNotifications.slice(listIndex + 1));

      return {
        ...state,
        selectedNotifications:
          listIndex === -1 ? addedNotification : removedNotification,
      };
    case TOGGLE_ALL_SELECTED:
      return {
        ...state,
        selectedNotifications: state.selectedNotifications.length
          ? []
          : action.payload,
      };
    default:
      return state;
  }
}
