import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import * as Sentry from "@sentry/browser";
import NOTIFICATIONS_MAPPING from "notifications/mapping";
import NotificationCheckbox from "notifications/containers/checkbox";
import TimeWithTooltip from "common/components/time_with_tooltip";

export default class NotificationWrapper extends Component {
  constructor(props) {
    super(props);
    this.clicked = this.clicked.bind(this);
  }

  componentDidMount() {
    const { notification } = this.props;

    if (!NOTIFICATIONS_MAPPING[notification.n_id]) {
      /* report missing notification to Sentry */
      Sentry.withScope((scope) => {
        Sentry.captureMessage(
          `Missing onsite notification ID ${notification.n_id}`,
          "error"
        );
      });
    }
  }

  clicked() {
    const { notification, userId, readNotification } = this.props;
    if (!notification.read) {
      readNotification(userId, notification.uuid);
    }
  }

  render() {
    const { notification, showChecks } = this.props;
    const { n_id, read, context, datetime } = notification;
    const NotificationComponent = NOTIFICATIONS_MAPPING[n_id];
    const notifClass = classnames("notification", { read });

    return (
      <div className={classnames({ row: showChecks })}>
        {showChecks && (
          <div className="medium-2 large-1 columns hide-for-small-only">
            <NotificationCheckbox notificationId={notification.uuid} />
          </div>
        )}
        <div
          className={classnames({
            "small-16 medium-14 large-15 columns": showChecks,
          })}
        >
          <div className={notifClass} onClick={this.clicked}>
            <div className="row">
              <div className="small-16 columns">
                <div className="notification-date">
                  <TimeWithTooltip time={datetime} />
                </div>
              </div>
            </div>
            {NotificationComponent ? (
              <NotificationComponent {...context} />
            ) : (
              <div>Component for Notification {n_id} not yet implemented</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

NotificationWrapper.propTypes = {
  notification: PropTypes.shape({
    n_id: PropTypes.number,
    read: PropTypes.bool,
    context: PropTypes.object,
    uuid: PropTypes.string,
    datetime: PropTypes.string,
  }),
  userId: PropTypes.number,
  readNotification: PropTypes.func.isRequired,
  showChecks: PropTypes.bool,
};
