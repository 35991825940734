import request from "axios";
import PropTypes from "prop-types";
import { CREATIVES_RESOURCE_NAME } from "explore/constants";
import { getAPIQueryString } from "common/utils";
import { savePageCount } from "common/pager/ducks";

/* ACTIONS
================================================================================================ */
const FETCH = "crowdspring/explore/FETCH_CREATIVES";
const FETCH_REQUEST = "crowdspring/explore/FETCH_CREATIVES_REQUEST";
const FETCH_SUCCESS = "crowdspring/explore/FETCH_CREATIVES_SUCCESS";
const FETCH_FAILURE = "crowdspring/explore/FETCH_CREATIVES_FAILURE";

/* INITIAL STATES
================================================================================================ */
export const initialState = {
  isFetching: false,
  isError: false,
  data: [],
};

/* HELPERS
================================================================================================ */
export const getCreatives = (state) => state.explore.creatives;

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_SUCCESS:
      const data = action.payload;

      return {
        ...state,
        isFetching: false,
        data: data.results,
      };
    case FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
================================================================================================ */
export const fetchCreatives = (query) => (dispatch) => {
  const queryStr = getAPIQueryString(CREATIVES_RESOURCE_NAME, query, {
    extraAPIQuery: { is_active: true },
  });
  const promise = request.get(`/api/v1/user-profiles/?${queryStr}`);

  /* save page count in pager state */
  promise.then((res) => dispatch(savePageCount(CREATIVES_RESOURCE_NAME, res)));

  return dispatch({
    type: FETCH,
    promise,
  });
};
