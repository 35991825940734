import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";

/**
 * String filter field.
 *
 * @author Nathan Ozelim <nathan@startupfoundry.com>
 */
const StringFilter = ({ value, string, stringLabel, onChange }) => {
  return (
    <Link onClick={() => onChange(value === string ? undefined : string)}>
      {stringLabel}
    </Link>
  );
};

StringFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  string: PropTypes.string.isRequired,
  stringLabel: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default StringFilter;
