import request from "axios";
import { NAME_ENTRIES_RESOURCE_NAME } from "profile/constants";
import { getAPIQueryString } from "common/utils";
import { savePageCount } from "common/pager/ducks";

/* ACTIONS
================================================================================================ */
const FETCH_ALL = "profile/name_entries/FETCH_ALL";
const FETCH_ALL_REQUEST = "profile/name_entries/FETCH_ALL_REQUEST";
const FETCH_ALL_SUCCESS = "profile/name_entries/FETCH_ALL_SUCCESS";

const CLEAR_ALL = "profile/name_entries/CLEAR_ALL";

const SET_VIEW_TYPE = "profile/name_entries/SET_VIEW_TYPE";

const SET_GROUPING = "profile/name_entries/SET_GROUPING";

/* HELPERS
================================================================================================ */
export const getEntries = (state) => {
  const { list, grouped } = state.profile.nameEntries;
  if (grouped) {
    return list;
  }
  /* if not grouped, return an entry copy for each revision */
  return list.reduce((acc, entry) => {
    entry.revisions.forEach(
      (rev) => (acc = acc.concat({ ...entry, revisions: [rev] }))
    );
    return acc;
  }, []);
};

/* INITIAL STATES
================================================================================================ */
const initialState = {
  list: [],
  isFetching: false,
  grouped: false,
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_ALL_SUCCESS:
      return {
        ...state,
        list: action.payload.results,
        isFetching: false,
      };
    case CLEAR_ALL:
      return {
        ...state,
        list: [],
      };
    case SET_VIEW_TYPE:
      return {
        ...state,
        viewType: action.payload,
      };
    case SET_GROUPING:
      return {
        ...state,
        grouped: action.payload,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const fetchAll = (userId, query) => (dispatch, getState) => {
  const queryStr = getAPIQueryString(NAME_ENTRIES_RESOURCE_NAME, query);
  const promise = request.get(
    `/api/v1/users/${userId}/all_name_entries/?${queryStr}`
  );

  /* save page count in pager state */
  promise.then((res) =>
    dispatch(savePageCount(NAME_ENTRIES_RESOURCE_NAME, res))
  );

  return dispatch({
    type: FETCH_ALL,
    promise,
  });
};

export const clearAll = () => {
  return {
    type: CLEAR_ALL,
  };
};

export const setViewType = (type) => {
  return {
    type: SET_VIEW_TYPE,
    payload: type,
  };
};

export const setGrouping = (grouped) => {
  return {
    type: SET_GROUPING,
    payload: grouped,
  };
};
