import request from "axios";
import partial from "lodash/partial";
import sortBy from "lodash/sortBy";
import { ENTRIES_RESOURCE_NAME } from "focus_groups/constants";
import { getAPIQueryString } from "common/utils";
import { savePageCount } from "common/pager/ducks";

/* ACTIONS
================================================================================================ */
const FETCH_ALL = "focus_groups/entries/FETCH_ALL";
const FETCH_ALL_REQUEST = "focus_groups/entries/FETCH_ALL_REQUEST";
const FETCH_ALL_SUCCESS = "focus_groups/entries/FETCH_ALL_SUCCESS";

const CLEAR_ALL = "focus_groups/entries/CLEAR_ALL";

const SET_GROUPING = "focus_groups/entries/SET_GROUPING";

const SET_SELECTED = "focus_groups/entries/SET_SELECTED";

/* HELPERS
================================================================================================ */
export const getEntries = (state) => {
  const { list, grouped } = state.focusGroups.entries;

  if (grouped) {
    return list;
  }

  /* if not grouped, return an entry copy for each revision */
  return list.reduce((acc, entry) => {
    entry.revisions.forEach(
      (rev) => (acc = acc.concat({ ...entry, revisions: [rev] }))
    );
    return acc;
  }, []);
};

export const isEntrySelected = (selected, entry) => {
  const revisionIds = entry.revisions.map((r) => r.id);
  return !!selected.find((e) => revisionIds.includes(e.revisions[0].id));
};

/* INITIAL STATES
================================================================================================ */
export const initialState = {
  list: [],
  isFetching: false,
  grouped: false,
  selected: [],
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_REQUEST:
      return {
        ...state,
        isFetching: true,
        allQueryStr: action.queryStr,
      };
    case FETCH_ALL_SUCCESS:
      /* handle race conditions by only updating the state with the result of the
         last request made */
      if (state.allQueryStr !== action.queryStr) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        isFetching: false,
        list: action.payload.results,
      };
    case CLEAR_ALL:
      return {
        ...state,
        list: [],
      };
    case SET_GROUPING:
      return {
        ...state,
        grouped: action.payload,
      };
    case SET_SELECTED:
      return {
        ...state,
        selected: action.payload,
      };
    default:
      return state;
  }
}

export const fetchAll = (projectId, focusGroupId, query) => (dispatch) => {
  const extraAPIQuery = focusGroupId
    ? {
        for_focus_group: focusGroupId,
      }
    : {
        entry_eliminated: false,
        entry_withdrawn: false,
      };
  const queryStr = getAPIQueryString(ENTRIES_RESOURCE_NAME, query, {
    extraAPIQuery,
  });
  const promise = request.get(
    `/api/v1/projects/${projectId}/entries/?${queryStr}`
  );

  /* save page count in pager state */
  promise.then((res) => dispatch(savePageCount(ENTRIES_RESOURCE_NAME, res)));

  return dispatch({
    type: FETCH_ALL,
    queryStr,
    promise,
  });
};

export const clearAll = () => {
  return {
    type: CLEAR_ALL,
  };
};

export const setGrouping = (grouped) => {
  return {
    type: SET_GROUPING,
    payload: grouped,
  };
};

export const setSelected = (payload) => {
  return {
    type: SET_SELECTED,
    payload,
  };
};
