import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { getProject, getSubCategory, getCategory } from "project/ducks/project";
import { toggleCloseModal } from "project/ducks/features";
import {
  showPromoContent,
  canClose,
  canCloseFinalRound,
  showTour,
} from "project/utils";
import HeaderTitle from "project/components/header_title";

const mapStateToProps = (state, { routes }) => {
  const { titleWords } = state.admin.projects;
  const project = getProject(state);
  const user = state.user.profile_data;

  return {
    project,
    category: getCategory(project, state.categories.data) || {},
    subCategory: getSubCategory(project, state.categories.data) || {},
    userId: user.id,
    showPromoContent: showPromoContent({ user, project, routes }),
    canClose: canClose(state),
    canCloseFinalRound: canCloseFinalRound(state),
    showTour: showTour({ user, project }),
    titleWords,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    toggleCloseModal,
  })(HeaderTitle)
);
