import React, { Component } from "react";
import PropTypes from "prop-types";
import cookie from "react-cookie";
import moment from "moment";
import { TABLET_BREAKPOINT_PX } from "common/variables";

let ShepherdTour, ShepherdTourContext, Shepherd;
if (__CLIENT__) {
  ({ ShepherdTour, ShepherdTourContext } = require("react-shepherd"));
  Shepherd = require("shepherd.js");
}

const applyStepsDefaults = (steps) => {
  return steps.map((step, i) => {
    const buttons = [
      { text: "Exit", type: "cancel", classes: "btn btn-text btn-md btn-exit" },
    ];

    if (i > 0) {
      buttons.push({
        text: "Back",
        type: "back",
        classes: "btn btn-secondary btn-md",
      });
    }

    if (i < steps.length - 1) {
      buttons.push({
        text: "Next",
        type: "next",
        classes: "btn btn-tertiary btn-md",
      });
    } else {
      buttons.push({
        text: "Done",
        type: "complete",
        classes: "btn btn-tertiary btn-md",
      });
    }

    /* by default, use 'hide' callback also for 'destroy' event */
    const when = {};
    if (step.when && step.when.hide) {
      when.destroy = step.when.hide;
    }

    return {
      canClickTarget: false,
      modalOverlayOpeningPadding: 8,
      scrollTo: { block: "end", inline: "nearest" },
      buttons,
      ...step,
      attachTo: { on: "bottom", ...step.attachTo },
      when: {
        ...when,
        ...step.when,
      },
    };
  });
};

const DEFAULT_OPTIONS = {
  useModalOverlay: true,
  exitOnEsc: true,
};

const DEFAULT_START_WAIT = 1500;

const SHOWN_COOKIE_NAME = (id) => `tour_${id}_shown`;

export const START_BUTTON_ID = (id) => `tour_${id}_start_btn`;

class StartButton extends Component {
  static contextType = ShepherdTourContext;

  constructor(props) {
    super(props);

    this.start = this.start.bind(this);
    this.handleInactive = this.handleInactive.bind(this);
    this.handleComplete = this.handleComplete.bind(this);
  }

  start() {
    const { id, toggle } = this.props;

    /* don't start tour if component is no longer mounted */
    if (!window.tours[id].unmounted) {
      toggle(id);
      window.tours[id].start();
    }
  }

  handleInactive() {
    this.props.toggle(null);
  }

  handleComplete() {
    const { onComplete } = this.props;

    if (onComplete) {
      onComplete();
    }
  }

  componentDidMount() {
    const { id, startAutomatically } = this.props;
    const COOKIE_NAME = SHOWN_COOKIE_NAME(id);

    /* store tour reference in window object so that it can be used later */
    window.tours = window.tours || {};
    window.tours[id] = this.context;

    /* show tour upon mount only once */
    if (startAutomatically && !cookie.load(COOKIE_NAME)) {
      setTimeout(this.start, DEFAULT_START_WAIT);
      cookie.save(COOKIE_NAME, true, {
        path: "/",
        expires: moment("2100-12-12").toDate(),
      });
    }

    window.tours[id].on("inactive", this.handleInactive);
    window.tours[id].on("complete", this.handleComplete);
  }

  componentWillUnmount() {
    const { id } = this.props;

    /* cancel tour if already in progress */
    if (window.tours[id].isActive()) {
      window.tours[id].cancel();
    }

    window.tours[id].unmounted = true;

    window.tours[id].off("inactive", this.handleInactive);
    window.tours[id].off("complete", this.handleComplete);
  }

  render() {
    const { startBtnLabel, id } = this.props;

    return (
      <a onClick={this.start} id={START_BUTTON_ID(id)}>
        {startBtnLabel}
      </a>
    );
  }
}

StartButton.propTypes = {
  id: PropTypes.string.isRequired,
  startBtnLabel: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
  startAutomatically: PropTypes.bool,
};

StartButton.defaultProps = {
  startBtnLabel: "Start tour",
  startAutomatically: true,
};

/* don't show tour on non-desktop devices */
export const showTour = () =>
  typeof window !== "undefined" && window.innerWidth >= TABLET_BREAKPOINT_PX;

const Tour = ({ steps, ...restProps }) => {
  if (!showTour()) {
    return null;
  }

  return (
    <ShepherdTour
      steps={applyStepsDefaults(steps)}
      tourOptions={DEFAULT_OPTIONS}
    >
      <StartButton {...restProps} />
    </ShepherdTour>
  );
};

export default Tour;

Tour.propTypes = {
  steps: PropTypes.array.isRequired,
};
