import React from "react";
import PropTypes from "prop-types";

const NDAPreviewButton = ({ onToggle, ...restProps }) => (
  <a className="sample-nda-link" onClick={() => onToggle(restProps)}>
    View sample NDA
  </a>
);

export default NDAPreviewButton;
