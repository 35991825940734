import request from "axios";

export const FETCH_FAQS = "crowdspring/help/FETCH_FAQS";
export const FETCH_FAQS_REQUEST = "crowdspring/help/FETCH_FAQS_REQUEST";
export const FETCH_FAQS_FAILURE = "crowdspring/help/FETCH_FAQS_FAILURE";
export const FETCH_FAQS_SUCCESS = "crowdspring/help/FETCH_FAQS_SUCCESS";

export const fetchAllFaqsIfNeeded = () => (dispatch, getState) => {
  /* FAQs already fetched */
  if (getState().help.faqs.data) {
    return Promise.resolve();
  }

  const opts = {
    method: "get",
    url: "/api/v1/faqs/",
  };

  return dispatch({
    type: FETCH_FAQS,
    promise: request(opts),
  });
};

export const INITIAL_STATE = {
  isLoading: false,
  data: null,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_FAQS_REQUEST:
      return {
        ...state,
        data: null,
        isLoading: true,
      };
    case FETCH_FAQS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
}
