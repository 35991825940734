import request from "axios";
import { USER_ACTIONS_RESOURCE_NAME } from "admin/constants";
import { getAPIQueryString } from "common/utils";
import { savePageCount } from "common/pager/ducks";

/* ACTIONS
================================================================================================ */
export const FETCH_ALL = "crowdspring/admin/FETCH_USER_ACTIONS";
export const FETCH_ALL_REQUEST = "crowdspring/admin/FETCH_USER_ACTIONS_REQUEST";
export const FETCH_ALL_SUCCESS = "crowdspring/admin/FETCH_USER_ACTIONS_SUCCESS";

export const CLEAR_ALL = "crowdspring/admin/CLEAR_USER_ACTIONS";

/* HELPERS
================================================================================================ */

/* INITIAL STATES
================================================================================================ */
export const initialState = {
  list: [],
  isFetching: false,
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_REQUEST:
      return {
        ...state,
        isFetching: true,
        list: [],
      };
    case FETCH_ALL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: action.payload.results,
      };
    case CLEAR_ALL:
      return {
        ...state,
        list: [],
      };
    default:
      return state;
  }
}

export const fetchAll = (userId, query) => (dispatch, getState) => {
  const queryStr = getAPIQueryString(USER_ACTIONS_RESOURCE_NAME, query);
  const promise = request.get(
    `/api/v1/radmin/users/${userId}/actions/?${queryStr}`
  );

  /* save page count in pager state */
  promise.then((res) =>
    dispatch(savePageCount(USER_ACTIONS_RESOURCE_NAME, res))
  );

  return dispatch({
    type: FETCH_ALL,
    promise,
  });
};

export const clearAll = () => {
  return {
    type: CLEAR_ALL,
  };
};
