import React from "react";
import { connect } from "react-redux";
import HeaderTitle from "header/components/title";

const mapStateToProps = ({ project }) => {
  const accepted = project.collaborators.data.inviteAccepted;

  let title = "Accepting invitation to collaborate...";
  if (accepted === true) {
    title = "Success!";
  } else if (accepted === false) {
    title = "Whoops!";
  }

  return {
    title,
  };
};

export default connect(mapStateToProps)(HeaderTitle);
