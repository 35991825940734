import React, { Component } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import partial from "lodash/partial";
import { Link, browserHistory } from "react-router";

const SUB_CATEGORIES = [
  {
    slug: "packaging-design",
    title: "Packaging Design",
    intro: (
      <>
        <p>
          Choose <strong>Packaging Design</strong> for a full, structural design
          of your product's container - like a{" "}
          <strong>box, bottle, or any other unique packaging</strong> form that
          needs to be custom-made to showcase your product.
        </p>
        <p>
          The scope of this project would be for the{" "}
          <strong>structural design</strong> of the package and{" "}
          <strong>
            does not include any graphic design such as labels, stickers, or
            product information
          </strong>
          .
        </p>
      </>
    ),
    bullets: [
      "Create unique packaging that enhances your product's appeal",
      "Make every unboxing an experience to remember",
      "Customized exclusively to your brand's needs",
    ],
    btnLabel: "I need custom packaging design",
    images: [
      "https://assets.crowdspring.com/marketing/cup-for-italy_package-design_crowdspring.jpg",
      "https://assets.crowdspring.com/marketing/package+design+2.png",
      "https://assets.crowdspring.com/marketing/bottle-design.jpg",
      "https://assets.crowdspring.com/marketing/package.jpg",
    ],
  },
  {
    slug: "package-graphics",
    title: "Package Graphics",
    intro: (
      <>
        <p>
          Choose <strong>Package Graphics</strong> if you know the dimensions of
          your product's container and you need professionally designed{" "}
          <strong>labels, stickers, or product information</strong> to add the
          perfect finishing touch.
        </p>
        <p>
          The scope of this project would be for the{" "}
          <strong>graphic design</strong> of the package and{" "}
          <strong>
            does not include any structural design or modification
          </strong>
          .
        </p>
      </>
    ),
    bullets: [
      "Add eye-catching artwork to make your brand memorable",
      "Establish a consistent visual identity for your product line",
      "Engage customers with story-driven, branded graphics",
    ],
    btnLabel: "I need custom package graphics",
    images: [
      "https://assets.crowdspring.com/marketing/cellular-beauty_package-graphics_crowdspring.jpg",
      "https://assets.crowdspring.com/marketing/ishira_package-design_crowdspring.jpg",
      "https://assets.crowdspring.com/marketing/nutribab_package-graphics_crowdspring.jpg",
      "https://assets.crowdspring.com/marketing/package+graphics+2-cropped.png",
    ],
  },
];

export default class SelectPackageSubcat extends Component {
  constructor(props) {
    super(props);

    this.goToPap = this.goToPap.bind(this);
    this.renderSubcat = this.renderSubcat.bind(this);
  }

  goToPap(slug) {
    const { replace, location, onLinkClick } = this.props;
    const pathname = `/start-a-project/product-packaging/${slug}/details/package/`;

    if (onLinkClick) {
      onLinkClick();
    }

    browserHistory[replace ? "replace" : "push"]({
      pathname,
      query: location.query.origin ? location.query : {},
      state: { packageSubcatSelected: true },
    });
  }

  renderSubcat({ slug, btnLabel, title, intro, bullets, images }) {
    return (
      <div key={slug} className="columns sub-category">
        <h2 className="subcat-title">{title}</h2>
        <div className="row images">
          {images.map((img, i) => (
            <div key={i} className="columns small-8">
              <img src={img} />
            </div>
          ))}
        </div>
        <div className="intro">{intro}</div>
        <ul className="bullets">
          {bullets.map((b, i) => (
            <li key={i}>
              <i className="fa fa-long-arrow-right" /> {b}
            </li>
          ))}
        </ul>
        <button
          className="btn btn-primary"
          onClick={partial(this.goToPap, slug)}
        >
          {btnLabel}
        </button>
      </div>
    );
  }

  render() {
    const { background, cta } = this.props;

    return (
      <div className={cn("block block-select-package-subcat", background)}>
        <div className="row columns">
          <div className="block-header">
            <h2>Do you need Packaging Design or Package Graphics?</h2>
          </div>
        </div>

        <div className="row small-up-1 medium-up-2">
          {SUB_CATEGORIES.map(this.renderSubcat)}
        </div>

        {!!cta && (
          <p className="text-center cta">
            <Link to={cta.link}>{cta.label}</Link>
          </p>
        )}
      </div>
    );
  }
}

SelectPackageSubcat.propTypes = {
  background: PropTypes.string,
  cta: PropTypes.object,
  replace: PropTypes.bool,
  onLinkClick: PropTypes.func,
  location: PropTypes.object.isRequired,
};
