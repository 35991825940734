import React from "react";
import request from "axios";
import { browserHistory } from "react-router";
import { ADMIN_ROOT } from "common/variables";
import { getWatchableCategories } from "common/ducks/categories";
import cookie from "react-cookie";
import idx from "idx";
import union from "lodash/union";
import without from "lodash/without";
import reduce from "lodash/reduce";
import pick from "lodash/pick";
import { setBannerError } from "error";
import { showBanner, TYPE_SUCCESS } from "common/ducks/banner";
import { FORBIDDEN_INACTIVE_CODE } from "account/constants";
import {
  REFERRER_COOKIE_NAME,
  REFERRAL_SOURCE_COOKIE_NAME,
} from "marketing/constants";
import { setGoogleDimension } from "common/tracking";
import { toggleAgencyQuestion } from "pap/ducks/workflow";
import { executeRecaptcha } from "common/recaptcha";
import { getSuspensionUrl } from "common/utils";

/* ACTIONS
================================================================================================ */
const GET_USER_DATA_REQUEST = "user/GET_USER_DATA_REQUEST";
export const GET_USER_DATA_SUCCESS = "user/GET_USER_DATA_SUCCESS";
const GET_USER_DATA_FAILURE = "user/GET_USER_DATA_FAILURE";

const UPDATE_USER = "user/UPDATE_USER";
const UPDATE_USER_SUCCESS = "user/UPDATE_USER_SUCCESS";
const UPDATE_USER_FAILURE = "user/UPDATE_USER_FAILURE";

const SET_EMAIL = "user/SET_EMAIL";
const SET_EMAIL_SUCCESS = "user/SET_EMAIL_SUCCESS";

const VERIFY_EMAIL = "user/VERIFY_EMAIL";
const VERIFY_EMAIL_SUCCESS = "user/VERIFY_EMAIL_SUCCESS";
const VERIFY_EMAIL_FAILURE = "user/VERIFY_EMAIL_FAILURE";

const FETCH_LEAD_SOURCES = "user/FETCH_LEAD_SOURCES";
const FETCH_LEAD_SOURCES_SUCCESS = "user/FETCH_LEAD_SOURCES_SUCCESS";

const FETCH_TIMEZONES = "user/FETCH_TIMEZONES";
const FETCH_TIMEZONES_SUCCESS = "user/FETCH_TIMEZONES_SUCCESS";

const FETCH_COUNTRIES = "user/FETCH_COUNTRIES";
const FETCH_COUNTRIES_SUCCESS = "user/FETCH_COUNTRIES_SUCCESS";

const RESEND_VALIDATION_EMAIL = "user/RESEND_VALIDATION_EMAIL";
const RESEND_VALIDATION_EMAIL_REQUEST = "user/RESEND_VALIDATION_EMAIL_REQUEST";

const WATCH_SUB_CATEGORY = "user/WATCH_SUB_CATEGORY";
const UNWATCH_SUB_CATEGORY = "user/UNWATCH_SUB_CATEGORY";
const WATCH_SUB_CATEGORY_ONSITE = "user/WATCH_SUB_CATEGORY_ONSITE";
const UNWATCH_SUB_CATEGORY_ONSITE = "user/UNWATCH_SUB_CATEGORY_ONSITE";

const FETCH_IP_INFO = "user/FETCH_IP_INFO";
const FETCH_IP_INFO_SUCCESS = "user/FETCH_IP_INFO_SUCCESS";

const FETCH_CHAT_PERMISSIONS = "user/FETCH_CHAT_PERMISSIONS";
const FETCH_CHAT_PERMISSIONS_SUCCESS = "user/FETCH_CHAT_PERMISSIONS_SUCCESS";

const FETCH_LANGUAGES = "user/FETCH_LANGUAGES";
const FETCH_LANGUAGES_SUCCESS = "user/FETCH_LANGUAGES_SUCCESS";

const FETCH_SUSPENSION_INFO = "user/FETCH_SUSPENSION_INFO";
const FETCH_SUSPENSION_INFO_REQUEST = "user/FETCH_SUSPENSION_INFO_REQUEST";
const FETCH_SUSPENSION_INFO_SUCCESS = "user/FETCH_SUSPENSION_INFO_SUCCESS";

const initialState = {
  logged_in: false,
  profile_data: {},
  email: "",
  leadSources: [],
  timezones: [],
  countries: [],
  suspensionInfo: null,
};

/* HELPERS
================================================================================================ */
export const profileDefaults = {
  user_timezone: "US/Central",
  receive_communication: true,
  country: "us",
};
export const createUserRequest = (formData) => {
  const referrer = cookie.load(REFERRER_COOKIE_NAME);
  const referralSource = cookie.load(REFERRAL_SOURCE_COOKIE_NAME);

  return executeRecaptcha({ action: "registration" }).then((token) => {
    const promise = request.post("/api/v1/users/", {
      ...formData,
      recaptcha_token: token,
      referrer: referrer ? atob(referrer) : undefined,
      referral_source: referralSource,
    });

    if (referrer) {
      promise.then(() => cookie.remove(REFERRER_COOKIE_NAME, { path: "/" }));
    }

    if (referralSource) {
      promise.then(() =>
        cookie.remove(REFERRAL_SOURCE_COOKIE_NAME, { path: "/" })
      );
    }

    return promise;
  });
};

export const updateUserRequest = (userId, formData) =>
  request.patch(`/api/v1/users/${userId}/`, formData);

export const deactivateAccountRequest = (userId, data) =>
  request.patch(`/api/v1/users/${userId}/deactivate/`, data);

export const sendPwdResetLinkRequest = (formData) =>
  request.patch("/api/v1/users/reset_password_request/", formData);

export const resetPasswordRequest = (formData) =>
  request.post("/api/v1/reset-password/", formData);

export const createRegistrationWaiterRequest = (formData) => {
  return executeRecaptcha({ action: "creative_waitlist" }).then((token) => {
    return request.post("/api/v1/creative-registration-waiters/", {
      ...formData,
      recaptcha_token: token,
    });
  });
};

const watchSubcategoryRequest = (subcatId, frequency, dispatch) => {
  return request
    .get(`/api/v1/project-subcategories/${subcatId}/watch_${frequency}/`)
    .catch(() => {
      dispatch(
        setBannerError("Error watching subcategory", "Please try again")
      );
    });
};

const unwatchSubcategoryRequest = (subcatId, dispatch) => {
  return request
    .get(`/api/v1/project-subcategories/${subcatId}/unwatch_email/`)
    .catch(() => {
      dispatch(
        setBannerError("Error unwatching subcategory", "Please try again")
      );
    });
};

const watchSubcategoryOnsiteRequest = (subcatId, dispatch) => {
  return request
    .get(`/api/v1/project-subcategories/${subcatId}/watch_onsite/`)
    .catch(() => {
      dispatch(
        setBannerError("Error watching subcategory", "Please try again")
      );
    });
};

const unwatchSubcategoryOnsiteRequest = (subcatId, dispatch) => {
  return request
    .get(`/api/v1/project-subcategories/${subcatId}/unwatch_onsite/`)
    .catch(() => {
      dispatch(
        setBannerError("Error unwatching subcategory", "Please try again")
      );
    });
};

/* Return modified profile data with updated sub-category watchlists. */
export const updateSubcatWatchlists = (
  onsite,
  action,
  profile,
  subcatId,
  frequency
) => {
  let watchlist, otherWatchlist;

  /* if onsite, just add or remove to/from its watchlist depending on the action */
  if (onsite) {
    watchlist = "watchlist_subcat_ids_onsite";
    if (action === "unwatch") {
      profile[watchlist] = without(profile[watchlist], subcatId);
    } else {
      profile[watchlist] = union(profile[watchlist], [subcatId]);
    }

    /* otherwise, add or remove to/from respective watchlists depending on the action and
     * frequency */
  } else {
    if (action === "watch") {
      watchlist = `watchlist_subcat_ids_${frequency}`;
      if (frequency === "immediate") {
        otherWatchlist = "watchlist_subcat_ids_digest";
      } else {
        otherWatchlist = "watchlist_subcat_ids_immediate";
      }
      profile[watchlist] = union(profile[watchlist], [subcatId]);
      profile[otherWatchlist] = without(profile[otherWatchlist], subcatId);
    } else {
      watchlist = "watchlist_subcat_ids_digest";
      otherWatchlist = "watchlist_subcat_ids_immediate";
      profile[watchlist] = without(profile[watchlist], subcatId);
      profile[otherWatchlist] = without(profile[otherWatchlist], subcatId);
    }
  }

  return { ...profile };
};

export const fetchEliteWarningsRequest = (id) =>
  request.get(`/api/v1/users/${id}/elite_warnings/`);

/* convert choices retrieved from the backend into the format used in the frontend */
export const formatChoices = (payload) =>
  payload.map((c) => ({
    field: c[0],
    label: c[1],
    group: c[2] === true ? "Most used" : c[2] === false ? "Others" : undefined,
  }));

const formatLanguages = (data) => {
  const list = data.map((language) => ({
    label: language[1],
    value: language[0],
  }));
  const abbreviations = data.reduce((mem, language) => {
    mem[language[0]] = language[1];
    return mem;
  }, {});

  return {
    list,
    abbreviations,
  };
};

/* REDUCERS
 ================================================================================================ */

export default function reducer(state = initialState, action) {
  let profile = null;
  let subcatId, frequency;

  switch (action.type) {
    case GET_USER_DATA_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_USER_DATA_SUCCESS:
      profile = { ...action.data };

      profile.user_type = profile.is_client ? "client" : "creative";

      return {
        ...state,
        isFetching: false,
        profile_data: profile,
        logged_in: true,
      };
    case GET_USER_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
      };
    case "USER_DATA_REMOVED":
      return Object.assign({}, state, {
        isFetching: false,
        profile_data: {},
        logged_in: false,
      });
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        error: action.error.data,
      };
    case UPDATE_USER_SUCCESS:
      profile = { ...action.payload };
      return {
        ...state,
        profile_data: profile,
        error: null,
      };
    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        profile_data: { ...state.profile_data, is_email_validated: true },
        emailVerified: true,
      };
    case VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        emailVerified: false,
      };
    case FETCH_LEAD_SOURCES_SUCCESS:
      return {
        ...state,
        leadSources: formatChoices(action.payload),
      };
    case FETCH_TIMEZONES_SUCCESS:
      return {
        ...state,
        timezones: formatChoices(action.payload),
      };
    case FETCH_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: formatChoices(action.payload),
      };
    case RESEND_VALIDATION_EMAIL_REQUEST:
      return {
        ...state,
        validationEmailResent: true,
      };
    case WATCH_SUB_CATEGORY:
      profile = { ...state.profile_data };
      ({ subcatId, frequency } = action.payload);
      return {
        ...state,
        profile_data: updateSubcatWatchlists(
          false,
          "watch",
          profile,
          subcatId,
          frequency
        ),
      };
    case UNWATCH_SUB_CATEGORY:
      profile = { ...state.profile_data };
      ({ subcatId } = action.payload);
      return {
        ...state,
        profile_data: updateSubcatWatchlists(
          false,
          "unwatch",
          profile,
          subcatId
        ),
      };
    case WATCH_SUB_CATEGORY_ONSITE:
      profile = { ...state.profile_data };
      ({ subcatId } = action.payload);
      return {
        ...state,
        profile_data: updateSubcatWatchlists(true, "watch", profile, subcatId),
      };
    case UNWATCH_SUB_CATEGORY_ONSITE:
      profile = { ...state.profile_data };
      ({ subcatId } = action.payload);
      return {
        ...state,
        profile_data: updateSubcatWatchlists(
          true,
          "unwatch",
          profile,
          subcatId
        ),
      };
    case FETCH_IP_INFO_SUCCESS:
      return {
        ...state,
        ipInfo: action.payload,
      };
    case FETCH_CHAT_PERMISSIONS_SUCCESS:
      return {
        ...state,
        canChat: action.payload,
      };
    case FETCH_LANGUAGES_SUCCESS:
      return {
        ...state,
        languages: formatLanguages(action.payload),
      };
    case SET_EMAIL_SUCCESS:
      return {
        ...state,
        profile_data: {
          ...state.profile_data,
          ...pick(action.payload, ["email", "is_email_validated"]),
        },
      };
    case FETCH_SUSPENSION_INFO_REQUEST:
      return {
        ...state,
        suspensionInfo: null,
      };
    case FETCH_SUSPENSION_INFO_SUCCESS:
      return {
        ...state,
        suspensionInfo: action.payload,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */

/* Update user locally */
export const updateUserLocal = (data) => (dispatch) =>
  dispatch({
    type: GET_USER_DATA_SUCCESS,
    data,
  });

export const verifyEmail = (base64, hex32, registering) => (dispatch) => {
  const promise = request.get(`/api/v1/verify/${base64}/${hex32}/`);
  promise.then(() => {
    /* continue registration if that's the case */
    if (registering) {
      browserHistory.push("/register/continue/");
    }
  });

  return dispatch({
    type: VERIFY_EMAIL,
    promise,
  });
};

/* Re-send email validation email. */
export const resendValidationEmail = () => (dispatch, getState) => {
  const userId = getState().user.profile_data.id;
  return dispatch({
    type: RESEND_VALIDATION_EMAIL,
    promise: request.put(`/api/v1/users/${userId}/resend_validation_email/`),
  });
};

export const login = (identification, password, code, next) => (dispatch) => {
  return executeRecaptcha({ action: "login" }).then((token) => {
    const promise = request.post("/api/v1/login/", {
      identification,
      password,
      code,
      recaptcha_token: token,
    });

    promise
      .then((response) => {
        // Reset the CSRF token for all requests after login
        /* @TODO: probably this is not needed anymore since we are adding the header on the fly */
        request.defaults.headers.common["X-CSRFToken"] =
          cookie.load("csrftoken");

        if (response.data.user_converted_from) {
          // dimension1 = user_converted_from (defined in analytics)
          try {
            setGoogleDimension("dimension1", response.data.user_converted_from);
          } catch (e) {
            console.error(e);
          }
        }

        dispatch({
          type: GET_USER_DATA_SUCCESS,
          data: response.data,
        });

        /* (un)toggle agency question if logging in from PAP */
        dispatch(
          toggleAgencyQuestion(
            !idx(response, (_) => _.data.is_agency_question_answered)
          )
        );

        /* redirect user to appropriate URL */
        if (next === null) {
          return;
        }
        let redirectTo = "/your-projects/";
        if (next) {
          redirectTo = next;
        } else if (idx(response, (_) => _.data.is_staff)) {
          redirectTo = ADMIN_ROOT;
        }
        browserHistory.push(redirectTo);
      })
      .catch((err) => {
        if (err?.data?.error_code === FORBIDDEN_INACTIVE_CODE) {
          const suspensionId = err.data.message?.suspension_id;

          if (suspensionId) {
            browserHistory.push(getSuspensionUrl(suspensionId));
          } else {
            dispatch(showInactiveBanner());
          }
        }
      });

    return promise;
  });
};

export const fetchLeadSourcesIfNeeded = () => (dispatch, getState) => {
  const state = getState();

  if (state.user.leadSources.length === 0) {
    return dispatch({
      type: FETCH_LEAD_SOURCES,
      promise: request({ method: "get", url: "/api/v1/users/lead_sources/" }),
    });
  }

  return Promise.resolve();
};

export const fetchTimezonesIfNeeded = () => (dispatch, getState) => {
  const state = getState();

  if (state.user.timezones.length === 0) {
    return dispatch({
      type: FETCH_TIMEZONES,
      promise: request({ method: "get", url: "/api/v1/users/timezones/" }),
    });
  }

  return Promise.resolve();
};

export const fetchCountriesIfNeeded = () => (dispatch, getState) => {
  const state = getState();

  if (state.user.countries.length === 0) {
    return dispatch({
      type: FETCH_COUNTRIES,
      promise: request({ method: "get", url: "/api/v1/users/countries/" }),
    });
  }

  return Promise.resolve();
};

export const updateUser = (data) => (dispatch) => {
  const promise = updateUserRequest(data["id"], data);

  dispatch({
    type: UPDATE_USER,
    promise,
  });

  return promise;
};

export const fetchCurrentUser = () => (dispatch) => {
  request.get("/api/v1/users/current/").then(({ data }) => {
    dispatch({
      type: GET_USER_DATA_SUCCESS,
      data,
    });
  });
};

/* Watch(email) a specific sub-category with a given frequency. */
export const watchSubcategory = (subcatId, frequency) => (dispatch) => {
  dispatch({
    type: WATCH_SUB_CATEGORY,
    payload: { subcatId, frequency },
  });
  return watchSubcategoryRequest(subcatId, frequency, dispatch);
};

/* Unwatch(email) a specific sub-category. */
export const unwatchSubcategory = (subcatId) => (dispatch) => {
  dispatch({
    type: UNWATCH_SUB_CATEGORY,
    payload: { subcatId },
  });
  return unwatchSubcategoryRequest(subcatId, dispatch);
};

/* Watch(email) a specific category with a given frequency. */
export const watchCategory = (cat, frequency) => (dispatch) => {
  reduce(
    cat.subcategories,
    (acc, subcat) => {
      dispatch({
        type: WATCH_SUB_CATEGORY,
        payload: { subcatId: subcat.id, frequency },
      });

      /* make requests sequentially to avoid race conditions */
      return acc.then(() =>
        watchSubcategoryRequest(subcat.id, frequency, dispatch)
      );
    },
    Promise.resolve()
  );
};

/* Unwatch(email) a specific category. */
export const unwatchCategory = (cat) => (dispatch) => {
  reduce(
    cat.subcategories,
    (acc, subcat) => {
      dispatch({
        type: UNWATCH_SUB_CATEGORY,
        payload: { subcatId: subcat.id },
      });

      /* make requests sequentially to avoid race conditions */
      return acc.then(() => unwatchSubcategoryRequest(subcat.id, dispatch));
    },
    Promise.resolve()
  );
};

/* Unwatch(email) all categories. */
export const unwatchAllCategories = () => (dispatch, getState) => {
  const cats = getWatchableCategories(getState().categories.data);
  cats.forEach((cat) => {
    cat.subcategories.forEach((subcat) => {
      dispatch({
        type: UNWATCH_SUB_CATEGORY,
        payload: { subcatId: subcat.id },
      });
    });
  });

  return request
    .get("/api/v1/project-subcategories/unwatch_email_all/")
    .catch(() => {
      dispatch(
        setBannerError("Error unwatching all subcategories", "Please try again")
      );
    });
};

/* Watch(onsite) a specific sub-category. */
export const watchSubcategoryOnsite = (subcatId) => (dispatch) => {
  dispatch({
    type: WATCH_SUB_CATEGORY_ONSITE,
    payload: { subcatId },
  });
  return watchSubcategoryOnsiteRequest(subcatId, dispatch);
};

/* Unwatch(onsite) a specific sub-category. */
export const unwatchSubcategoryOnsite = (subcatId) => (dispatch) => {
  dispatch({
    type: UNWATCH_SUB_CATEGORY_ONSITE,
    payload: { subcatId },
  });
  return unwatchSubcategoryOnsiteRequest(subcatId, dispatch);
};

/* Watch(onsite) a specific category. */
export const watchCategoryOnsite = (cat) => (dispatch) => {
  reduce(
    cat.subcategories,
    (acc, subcat) => {
      dispatch({
        type: WATCH_SUB_CATEGORY_ONSITE,
        payload: { subcatId: subcat.id },
      });

      /* make requests sequentially to avoid race conditions */
      return acc.then(() => watchSubcategoryOnsiteRequest(subcat.id, dispatch));
    },
    Promise.resolve()
  );
};

/* Unwatch(onsite) a specific category. */
export const unwatchCategoryOnsite = (cat) => (dispatch) => {
  reduce(
    cat.subcategories,
    (acc, subcat) => {
      dispatch({
        type: UNWATCH_SUB_CATEGORY_ONSITE,
        payload: { subcatId: subcat.id },
      });

      /* make requests sequentially to avoid race conditions */
      return acc.then(() =>
        unwatchSubcategoryOnsiteRequest(subcat.id, dispatch)
      );
    },
    Promise.resolve()
  );
};

export const showInactiveBanner = () => (dispatch) => {
  const content = (
    <p>
      It looks like your account is inactive, so you won't be able to log in or
      reset your password. If you have questions or need help, please{" "}
      <a href="/help/contact/" target="_blank">
        contact our support team
      </a>
      .
    </p>
  );
  dispatch(setBannerError("Account inactive", content));
};

export const setAgencySegment = (isAgency) => (dispatch, getState) => {
  const profileData = getState().user.profile_data;
  const currentSegments = profileData.marketing_segments || [];

  let newSegment;
  if (isAgency) {
    newSegment = "agency";
  } else if (currentSegments.includes("agency")) {
    newSegment = "business_owner";
  }

  let segments;
  if (newSegment) {
    /* "agency", "top_n_companies" and "business_owner" are mutually exclusive */
    segments = without(
      currentSegments,
      "agency",
      "top_n_companies",
      "business_owner"
    ).concat([newSegment]);
  } else {
    segments = currentSegments;
  }

  const promise = request.patch(
    `/api/v1/users/${profileData.id}/marketing_segments/`,
    {
      segments,
    }
  );

  promise.then((res) =>
    dispatch(
      updateUser({
        id: profileData.id,
        is_agency_question_answered: true,
        marketing_segments: res.data.marketing_segments,
      })
    )
  );

  return promise;
};

export const fetchIpInfoIfNeeded = () => (dispatch, getState) => {
  const { ipInfo } = getState().user;

  if (ipInfo) {
    return Promise.resolve({ data: ipInfo });
  }

  const promise = request.get("/api/v1/users/ip_info/");

  dispatch({
    type: FETCH_IP_INFO,
    promise,
  });

  return promise;
};

export const fetchChatPermsIfNeeded = () => (dispatch, getState) => {
  const state = getState();

  if (state.user.canChat === undefined) {
    return dispatch({
      type: FETCH_CHAT_PERMISSIONS,
      promise: request.get("/api/v1/users/can_chat/"),
    });
  }

  return Promise.resolve();
};

export const fetchLanguagesIfNeeded = () => (dispatch, getState) => {
  const state = getState();

  if (!state.user.languages) {
    return dispatch({
      type: FETCH_LANGUAGES,
      promise: request.get("/api/v1/users/languages/"),
    });
  }

  return Promise.resolve();
};

export const setEmail = (data) => (dispatch, getState) => {
  const user = getState().user.profile_data;
  const promise = request.patch(`/api/v1/users/${user.id}/set_email/`, data);

  promise.then(() => {
    dispatch(
      showBanner(
        "Done!",
        "Your email was successfully updated.",
        undefined,
        TYPE_SUCCESS
      )
    );
  });

  dispatch({
    type: SET_EMAIL,
    promise,
  });

  return promise;
};

export const setPassword = (data) => (dispatch, getState) => {
  const user = getState().user.profile_data;
  const promise = request.patch(`/api/v1/users/${user.id}/set_password/`, data);

  promise.then(() => {
    dispatch(
      showBanner(
        "Done!",
        "Your password was successfully updated.",
        undefined,
        TYPE_SUCCESS
      )
    );
  });

  return promise;
};

export const logout = (redirectTo, resumeLastUrl) => (dispatch, getState) => {
  request
    .get("/api/v1/logout/")
    .then(() => {
      cookie.remove("sessionid");
      cookie.remove("csrftoken");

      dispatch({
        type: "USER_DATA_REMOVED",
        data: null,
      });

      if (resumeLastUrl) {
        const lastUrl = getState().routing.locationBeforeTransitions.pathname;
        browserHistory.push(`/login/?resuming=true&next=${lastUrl}`);
      } else {
        browserHistory.push(redirectTo || "/login/");
      }
    })
    .catch((response) => {
      dispatch({
        type: "USER_LOGOUT_FAILED",
        response,
      });
    });
};

export const fetchSuspensionInfo = (suspensionId) => (dispatch) => {
  const promise = request.get(`/api/v1/users/suspensions/?id=${suspensionId}`);

  promise.catch(() => browserHistory.replace("/404/"));

  return dispatch({
    type: FETCH_SUSPENSION_INFO,
    promise,
  });
};

export const reactivateAccount = (suspensionId, password) => (dispatch) => {
  const promise = request.patch("/api/v1/users/reactivate/", {
    suspension_id: suspensionId,
    password,
  });

  promise.then(() => {
    browserHistory.push("/login/");
    dispatch(
      showBanner(
        "Account reactivated!",
        "Log in to access your projects.",
        undefined,
        TYPE_SUCCESS
      )
    );
  });

  return promise;
};
