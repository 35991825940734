import request from "axios";
import { browserHistory } from "react-router";

export const FETCH_ALL_GUIDES = "crowdspring/help/FETCH_ALL_GUIDES";
export const FETCH_ALL_GUIDES_REQUEST =
  "crowdspring/help/FETCH_ALL_GUIDES_REQUEST";
export const FETCH_ALL_GUIDES_FAILURE =
  "crowdspring/help/FETCH_ALL_GUIDES_FAILURE";
export const FETCH_ALL_GUIDES_SUCCESS =
  "crowdspring/help/FETCH_ALL_GUIDES_SUCCESS";

export const FETCH_GUIDE = "crowdspring/help/FETCH_GUIDE";
export const FETCH_GUIDE_REQUEST = "crowdspring/help/FETCH_GUIDE_REQUEST";
export const FETCH_GUIDE_FAILURE = "crowdspring/help/FETCH_GUIDE_FAILURE";
export const FETCH_GUIDE_SUCCESS = "crowdspring/help/FETCH_GUIDE_SUCCESS";

export const VOTE_GUIDE = "crowdspring/help/VOTE_GUIDE";
export const VOTE_GUIDE_REQUEST = "crowdspring/help/VOTE_GUIDE_REQUEST";
export const VOTE_GUIDE_FAILURE = "crowdspring/help/VOTE_GUIDE_FAILURE";
export const VOTE_GUIDE_SUCCESS = "crowdspring/help/VOTE_GUIDE_SUCCESS";

export const fetchAllGuidesIfNeeded = () => (dispatch, getState) => {
  /* guides already fetched */
  if (getState().help.guides.data) {
    return Promise.resolve();
  }

  const opts = {
    method: "get",
    url: "/api/v1/guides/",
  };

  return dispatch({
    type: FETCH_ALL_GUIDES,
    promise: request(opts),
  });
};

export const fetchGuideIfNeeded = (guideSlug) => (dispatch, getState) => {
  /* guide already fetched */
  const guide = getState().help.guides.guide;
  if (guide && guide.slug === guideSlug) {
    return Promise.resolve();
  }

  const promise = request({
    method: "get",
    url: `/api/v1/guides/${guideSlug}/`,
  });

  /* Handle missing guide.
     In case `browserHistory` is not available, it means server-rendering is being performed
     and the 404 error will be handled on the request level.
     Otherwise, user is redirected to /404. */
  promise.catch((response) => {
    if (response.status === 404 && browserHistory) {
      browserHistory.push("/404");
    }
  });

  return dispatch({
    type: FETCH_GUIDE,
    promise,
  });
};

export function voteGuide(guideSlug, up) {
  const endpoint = up ? "helpful" : "unhelpful";
  const opts = {
    method: "get",
    url: `/api/v1/guides/${guideSlug}/${endpoint}/`,
  };
  return {
    type: VOTE_GUIDE,
    promise: request(opts),
    payload: { up },
  };
}

export const INITIAL_STATE = {
  isLoading: false,
  data: null,
  guide: null,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_GUIDE_REQUEST:
      return {
        ...state,
        isLoading: true,
        guide: null,
      };
    case FETCH_GUIDE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        guide: action.payload,
      };
    case FETCH_ALL_GUIDES_REQUEST:
      return {
        ...state,
        data: null,
        isLoading: true,
      };
    case FETCH_ALL_GUIDES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case VOTE_GUIDE_REQUEST:
      const helpful = action.payload.up
        ? state.guide.votes.helpful + 1
        : state.guide.votes.helpful;
      const total = state.guide.votes.total + 1;

      return {
        ...state,
        isLoading: false,
        guide: {
          ...state.guide,
          votes: { helpful, total },
        },
      };
    default:
      return state;
  }
}
