import { combineReducers } from "redux";
import campaignsReducer from "wasp/ducks/campaigns";
import savedDomainsReducer from "wasp/ducks/saved_domains";
import blockedDomainsReducer from "wasp/ducks/blocked_domains";
import domainsReducer from "wasp/ducks/domains";
import campaignBlockedDomainsReducer from "wasp/ducks/campaign_blocked_domains";
import campaignExcludedDomainsReducer from "wasp/ducks/campaign_excluded_domains";

export default combineReducers({
  blockedDomains: blockedDomainsReducer,
  campaigns: campaignsReducer,
  savedDomains: savedDomainsReducer,
  domains: domainsReducer,
  campaignBlockedDomains: campaignBlockedDomainsReducer,
  campaignExcludedDomains: campaignExcludedDomainsReducer,
});
