import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import partial from "lodash/partial";
import { watch, unwatch } from "project/ducks/watchlist";
import { getProject } from "project/ducks/project";
import { getProjectIdFromUrl } from "project/utils";
import WatchProjectButton from "project/components/watch_project_button";

export function mapStateToProps({ project, user }, { params }) {
  return {
    user: user.profile_data,
    project: getProject({ project }),
    projectId: getProjectIdFromUrl(params),
  };
}

export function mapDispatchToProps(dispatch, { params }) {
  const projectId = getProjectIdFromUrl(params);

  return bindActionCreators(
    {
      watch: partial(watch, projectId),
      unwatch: partial(unwatch, projectId),
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WatchProjectButton)
);
