/* ACTIONS
================================================================================================ */
export const TOGGLE = "common/tour/TOGGLE";

/* REDUCERS
 ================================================================================================ */
const initialState = {
  activeId: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE:
      return {
        ...state,
        activeId: action.id,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const toggle = (id) => (dispatch) => {
  return dispatch({ type: TOGGLE, id });
};
