import { connect } from "react-redux";
import { getProject } from "project/ducks/project";
import FinalRoundFaqs from "entries/components/final_round_faqs";

const mapStateToProps = (state) => {
  return {
    project: getProject(state),
    user: state.user.profile_data,
  };
};

export default connect(mapStateToProps)(FinalRoundFaqs);
