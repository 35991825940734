import request from "axios";

/* ACTIONS
================================================================================================ */
export const FETCH = "crowdspring/admin/FETCH_ADMIN_NOTES";
export const FETCH_REQUEST = "crowdspring/admin/FETCH_ADMIN_NOTES_REQUEST";
export const FETCH_SUCCESS = "crowdspring/admin/FETCH_ADMIN_NOTES_SUCCESS";
export const FETCH_FAILURE = "crowdspring/admin/FETCH_ADMIN_NOTES_FAILURE";

export const ADD = "crowdspring/admin/ADD_ADMIN_NOTES";
export const ADD_REQUEST = "crowdspring/admin/ADD_ADMIN_NOTES_REQUEST";
export const ADD_SUCCESS = "crowdspring/admin/ADD_ADMIN_NOTES_SUCCESS";
export const ADD_FAILURE = "crowdspring/admin/ADD_ADMIN_NOTES_FAILURE";

export const UPDATE_IMPORTANT = "crowdspring/admin/UPDATE_IMPORTANT_NOTE";
export const UPDATE_IMPORTANT_SUCCESS =
  "crowdspring/admin/UPDATE_IMPORTANT_NOTE";

/* HELPERS
================================================================================================ */
const getListApi = (type, id) =>
  "/api/v1/" + (id ? `${type}/${id}/admin_notes/` : `${type}_admin_notes/`);

/* INITIAL STATES
================================================================================================ */
export const initialState = {
  isFetching: false,
  isError: false,
  lists: {},
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        lists: {
          ...state.lists,
          [action.notesType]: {
            ...state.lists[action.notesType],
            [action.notesId]: action.payload,
          },
        },
      };
    case ADD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        lists: {
          ...state.lists,
          [action.notesType]: {
            ...state.lists[action.notesType],
            [action.notesId]: [
              action.payload,
              ...((state.lists[action.notesType] || {})[action.notesId] || []),
            ],
          },
        },
      };
    default:
      return state;
  }
}

export const fetchNotes =
  (type = "projects", id = 0) =>
  (dispatch) => {
    const url = getListApi(type, id);

    return dispatch({
      type: FETCH,
      promise: request.get(url),
      notesType: type,
      notesId: id,
    });
  };

export const addNote =
  (type = "projects", id = 0, body) =>
  (dispatch) => {
    const url = getListApi(type, id);

    return dispatch({
      type: ADD,
      promise: request.post(url, body),
      notesType: type,
      notesId: id,
    });
  };

export const updateImportantNote =
  (type = "projects", id = 0, body) =>
  (dispatch) => {
    const url = `/api/v1/radmin/${type}/${id}/update_priority_admin_note/`;
    const fieldMap = {
      projects: "project_admin_note",
      users: "user_admin_note",
    };
    const field = fieldMap[type];

    return dispatch({
      type: UPDATE_IMPORTANT,
      promise: request.patch(url, { [field]: body }),
    });
  };
