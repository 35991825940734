import { connect } from "react-redux";
import HeaderTitle from "header/components/title";

const mapStateToProps = (state) => {
  return {
    title: "Brand Studio",
    cta: { url: "/brand-studio/", label: "Create new brand" },
  };
};

export default connect(mapStateToProps)(HeaderTitle);
