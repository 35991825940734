import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Tooltip from "react-tooltip";
import { v4 } from "uuid";
import { isMobile } from "common/utils";

const TimeWithTooltip = ({
  time,
  format = "fromNow",
  tooltipFormat = "MMMM D, YYYY hh:mm a",
  className,
  place,
}) => {
  const uuid = v4();
  let timeStr = "";
  if (format === "fromNow") {
    timeStr = moment(time).fromNow();
  }

  return (
    <span
      className={className}
      data-tip={true}
      data-for={uuid}
      data-event={isMobile() ? "click" : undefined}
    >
      {timeStr}
      <Tooltip class="tooltip" effect="solid" id={uuid} place={place}>
        {moment(time).format(tooltipFormat)}
      </Tooltip>
    </span>
  );
};

TimeWithTooltip.propTypes = {
  time: PropTypes.string.isRequired,
  format: PropTypes.string,
  tooltipFormat: PropTypes.string,
  className: PropTypes.string,
  place: PropTypes.string,
};

export default TimeWithTooltip;
