import request from "axios";
import idx from "idx";
import queryString from "query-string";
import { setBannerError } from "error";

/* ACTIONS
================================================================================================ */
const FETCH_PROJECT = "one_to_one/FETCH_PROJECT";
const FETCH_PROJECT_SUCCESS = "one_to_one/FETCH_PROJECT_SUCCESS";

const FETCH_NEGOTIATIONS = "one_to_one/FETCH_NEGOTIATIONS";
const FETCH_NEGOTIATIONS_SUCCESS = "one_to_one/FETCH_NEGOTIATIONS_SUCCESS";

const FETCH_SUGGESTED_CREATIVES = "one_to_one/FETCH_SUGGESTED_CREATIVES";
const FETCH_SUGGESTED_CREATIVES_REQUEST =
  "one_to_one/FETCH_SUGGESTED_CREATIVES_REQUEST";
const FETCH_SUGGESTED_CREATIVES_SUCCESS =
  "one_to_one/FETCH_SUGGESTED_CREATIVES_SUCCESS";

const ADVANCE_NEGOTIATION = "one_to_one/ADVANCE_NEGOTIATION";
const ADVANCE_NEGOTIATION_SUCCESS = "one_to_one/ADVANCE_NEGOTIATION_SUCCESS";

const SET_NEGOTIATION_ACTION = "one_to_one/SET_NEGOTIATION_ACTION";

const TOGGLE_PAYMENT = "one_to_one/TOGGLE_PAYMENT";

/* HELPERS
================================================================================================ */

/* INITIAL STATES
================================================================================================ */
const initialState = {
  negotiations: [],
  suggestedCreatives: [],
  showingPayment: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROJECT_SUCCESS:
      return {
        ...state,
        project: action.payload,
      };
    case FETCH_NEGOTIATIONS_SUCCESS:
      return {
        ...state,
        negotiations: action.payload,
      };
    case FETCH_SUGGESTED_CREATIVES_REQUEST:
      return {
        ...state,
        isFetchingSuggestions: true,
      };
    case FETCH_SUGGESTED_CREATIVES_SUCCESS:
      return {
        ...state,
        isFetchingSuggestions: false,
        suggestedCreatives: action.payload,
      };
    case ADVANCE_NEGOTIATION_SUCCESS:
      return {
        ...state,
        negotiations: state.negotiations.map((n) =>
          n.id === action.payload.id ? action.payload : n
        ),
      };
    case SET_NEGOTIATION_ACTION:
      return {
        ...state,
        negotiationAction: action.payload,
      };
    case TOGGLE_PAYMENT:
      return {
        ...state,
        showingPayment: !state.showingPayment,
      };
    default:
      return state;
  }
}

export const fetchProject = (id) => (dispatch) => {
  const promise = request.get(`/api/v1/projects/${id}/`);

  dispatch({
    type: FETCH_PROJECT,
    promise,
  });

  return promise;
};

export const fetchNegotiations = (projectId) => (dispatch) => {
  const promise = request.get(
    `/api/v1/projects/${projectId}/one_to_one_negotiations/`
  );

  dispatch({
    type: FETCH_NEGOTIATIONS,
    promise,
  });

  return promise;
};

export const fetchSuggestedCreatives =
  (subcatId, includeUsernames = [], requiredUsernames = []) =>
  (dispatch) => {
    const queryStr = queryString.stringify({
      include: includeUsernames,
      required: requiredUsernames,
    });

    const promise = request.get(
      `/api/v1/project-subcategories/${subcatId}/suggested_creatives/?${queryStr}`
    );

    dispatch({
      type: FETCH_SUGGESTED_CREATIVES,
      promise,
    });

    return promise;
  };

export const createNegotiations = (projectId, creativeIds) => (dispatch) => {
  let negotiationIds = [];
  const promise = creativeIds
    .reduce(
      (acc, creativeId) =>
        acc.then(() => {
          const createPromise = request.post(
            `/api/v1/projects/${projectId}/one_to_one_negotiations/`,
            {
              creative: creativeId,
            }
          );
          createPromise.then(
            (res) => (negotiationIds = negotiationIds.concat(res.data.id))
          );
          return createPromise;
        }),
      Promise.resolve()
    )
    .then(() => negotiationIds);

  promise.catch(() => {
    dispatch(
      setBannerError(
        "Error creating 1-to-1 negotiations",
        "Please refresh the page."
      )
    );
  });

  return promise;
};

export const advanceNegotiation = (projectId, id, data) => (dispatch) => {
  const promise = request.patch(
    `/api/v1/projects/${projectId}/one_to_one_negotiations/${id}/advance/`,
    {
      ...data,
      history_length: data.history_length || 0,
    }
  );

  promise.catch((err) => {
    dispatch(
      setBannerError(
        "Error advancing 1-to-1 negotiations",
        idx(err, (_) => _.data.message) || "Please try again."
      )
    );
  });

  dispatch({
    type: ADVANCE_NEGOTIATION,
    promise,
  });

  return promise;
};

export const advanceNegotiations = (projectId, ids, data) => (dispatch) => {
  return ids.reduce(
    (acc, id) =>
      acc.then(() => dispatch(advanceNegotiation(projectId, id, data))),
    Promise.resolve()
  );
};

export const setNegotiationAction = (action) => (dispatch) => {
  return dispatch({
    type: SET_NEGOTIATION_ACTION,
    payload: action,
  });
};

export const togglePayment = () => (dispatch) => {
  return dispatch({
    type: TOGGLE_PAYMENT,
  });
};
