import React, { Component } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import { getQueryFromValue } from "common/sorter/utils";
import { getQueryFromValue as getPagerQueryFromValue } from "common/pager/utils";

/**
 * Re-usable sorter component.
 * The sorting value is always read/updated from/to the URL query parameters.
 * The actual display of sorting options is delegated to the component passed
 * in the `component` prop, which, in turn, is provided with the following common props:
 * - value: current sorting value
 * - defaultValue: default sorting value used by the API when no sorting parameters are specified
 * - onChange: callback to be invoked when the sorting value is changed
 *
 * @author Nathan Ozelim <nathan@startupfoundry.com>
 */
export default class Sorter extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    /* save initial sorting query */
    const { resourceName, saveLastQuery, value } = this.props;
    const sorterQuery = getQueryFromValue(resourceName, value);
    saveLastQuery(resourceName, sorterQuery);
  }

  componentDidUpdate(prevProps) {
    const { resourceName, saveLastQuery, value } = this.props;

    /* save query when parameters are updated */
    if (!isEqual(prevProps.value, value)) {
      const sorterQuery = getQueryFromValue(resourceName, value);
      saveLastQuery(resourceName, sorterQuery);
    }
  }

  handleChange(value) {
    const { router, resourceName } = this.props;
    const currentLocation = router.getCurrentLocation();
    const sorterQuery = getQueryFromValue(resourceName, value);
    /* clear page when updating sorting */
    const pagerQuery = getPagerQueryFromValue(resourceName, "");
    const updatedQuery = {
      ...currentLocation.query,
      ...sorterQuery,
      ...pagerQuery,
    };

    /* update URL with new query parameters */
    router.push({
      ...currentLocation,
      query: updatedQuery,
    });
  }

  render() {
    const Component = this.props.component;
    return <Component {...this.props} onChange={this.handleChange} />;
  }
}

Sorter.propTypes = {
  router: PropTypes.object.isRequired,
  resourceName: PropTypes.string.isRequired,
  saveLastQuery: PropTypes.func.isRequired,
  value: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};
