import React from "react";
import PropTypes from "prop-types";
import { ModalTrigger } from "common/modal";

const ReserveButton = ({ canReserve }) =>
  canReserve ? (
    <div className="button-wrapper show-for-large">
      <ModalTrigger
        className="btn btn-tertiary"
        trigger="elite-reservation-modal"
      >
        Request reservation
      </ModalTrigger>
    </div>
  ) : null;

ReserveButton.defaultProps = {
  canReserve: false,
};

ReserveButton.propTypes = {
  canReserve: PropTypes.bool,
};

export default ReserveButton;
