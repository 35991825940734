import React, { Component } from "react";
import PropTypes from "prop-types";
import request from "axios";
import isEqual from "lodash/isEqual";
import { Link } from "react-router";
import classnames from "classnames";

export default class Item extends Component {
  constructor(props) {
    super(props);

    this.handleBtnClick = this.handleBtnClick.bind(this);
    this.trackEvent = this.trackEvent.bind(this);
  }

  componentDidMount() {
    this.trackView();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.item, this.props.item)) {
      this.trackView();
    }
  }

  trackView() {
    this.trackEvent("unique_view");
  }

  trackEvent(name) {
    const { item, trackingDisabled } = this.props;

    /* also ignore fake "email delivery errors" announcement */
    if (trackingDisabled || isNaN(item.id)) {
      return;
    }

    request.post("/api/v1/announcements/events/", {
      announcement: item.id,
      name,
    });
  }

  handleBtnClick(item) {
    this.props.onDismiss(item);
    this.trackEvent("unique_click");
  }

  render() {
    const { item, onDismiss } = this.props;

    return (
      <div className={classnames("item", item.level)}>
        <button type="button" className="close" onClick={() => onDismiss(item)}>
          <i className="fa fa-times-circle" />
        </button>
        <div className="row">
          <div className="columns small-3 medium-2 icon">
            <i className={`fa fa-${item.icon || "info"}`} />
          </div>

          <div className="columns small-12 medium-13 body">
            <h3>{item.title}</h3>

            <div dangerouslySetInnerHTML={{ __html: item.body }} />

            {item.button_link && (
              <Link
                key={
                  item.button_link /* use `button_link` as key to prevent going to wrong links in case of multiple announcements */
                }
                to={item.button_link}
                target="_blank"
                onClick={() => this.handleBtnClick(item)}
                className="btn btn-tertiary btn-md"
              >
                {item.button_label}
              </Link>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Item.propTypes = {
  item: PropTypes.object.isRequired,
  onDismiss: PropTypes.func.isRequired,
  trackingDisabled: PropTypes.bool,
};
