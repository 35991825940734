import React, { Component } from "react";
import PropTypes from "prop-types";

export default class TextArea extends Component {
  constructor(props) {
    super(props);

    /* this.props.value reflects this.state when inputs are blurred */
    this.state = { value: props.value };
    this.updateValue = this.updateValue.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;

    /* update this.state to make sure it reflects this.props.value in case
       it was changed outside of this component */
    if (prevProps.value !== value) {
      this.setState({ value });
    }
  }

  updateValue() {
    this.props.onChange(this.state.value);
  }

  render() {
    const { value } = this.state;
    const { field } = this.props;

    return (
      <div className="textarea-filter">
        <textarea
          placeholder="Enter 1 value per line..."
          name={`filter_${field}`}
          value={value}
          onChange={(e) => this.setState({ value: e.target.value })}
          onBlur={this.updateValue}
          rows={4}
        />
      </div>
    );
  }
}

TextArea.propTypes = {
  onChange: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
