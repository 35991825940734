import React from "react";
import PropTypes from "prop-types";
import ProjectLink from "notifications/components/project_link";

const WrapupLink = ({ project, award, isNew, capitalize }) => {
  let label = "a wrap-up";
  if (isNew) {
    label = "a new wrap-up";
  }
  if (capitalize) {
    label = label[0].toUpperCase() + label.slice(1);
  }
  return (
    <ProjectLink
      project={project}
      append={`wrapup/${award}/`}
      linkTitle={label}
    />
  );
};

export default WrapupLink;
