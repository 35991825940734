import { connect } from "react-redux";
import { toggleSelectPackageSubcat } from "pap/ducks/workflow";
import SelectPackageSubcatModal from "pap/components/common/select_package_subcat_modal";

const mapStateToProps = ({ pap }) => {
  return {
    showing: pap.workflow.showingSelectPackageSubcat,
  };
};

export default connect(mapStateToProps, {
  onClose: toggleSelectPackageSubcat,
})(SelectPackageSubcatModal);
