import request from "axios";

/* ACTIONS
================================================================================================ */
const FETCH_LAST = "quality_review/FETCH_LAST";
const FETCH_LAST_REQUEST = "quality_review/FETCH_LAST_REQUEST";
const FETCH_LAST_SUCCESS = "quality_review/FETCH_LAST_SUCCESS";

const FETCH_LAST_COMPLETED = "quality_review/FETCH_LAST_COMPLETED";
const FETCH_LAST_COMPLETED_REQUEST =
  "quality_review/FETCH_LAST_COMPLETED_REQUEST";
const FETCH_LAST_COMPLETED_SUCCESS =
  "quality_review/FETCH_LAST_COMPLETED_SUCCESS";

const CREATE = "quality_review/CREATE";
const CREATE_SUCCESS = "quality_review/CREATE_SUCCESS";

const VOTE = "quality_review/VOTE";
const VOTE_SUCCESS = "quality_review/VOTE_SUCCESS";

const BREAK_TIE = "quality_review/BREAK_TIE";
const BREAK_TIE_SUCCESS = "quality_review/BREAK_TIE_SUCCESS";

const TOGGLE_ACCOUNT_DEACTIVATION =
  "quality_review/TOGGLE_ACCOUNT_DEACTIVATION";

const TOGGLE_PROCESSING = "quality_review/TOGGLE_PROCESSING";

const TOGGLE_EMAIL_PREVIEW = "quality_review/TOGGLE_EMAIL_PREVIEW";

const TOGGLE_WARNING_REMOVAL = "quality_review/TOGGLE_WARNING_REMOVAL";

const REMOVE_WARNINGS = "quality_review/REMOVE_WARNINGS";
const REMOVE_WARNINGS_SUCCESS = "quality_review/REMOVE_WARNINGS_SUCCESS";

/* HELPERS
================================================================================================ */

/* INITIAL STATES
================================================================================================ */
const initialState = {
  last: null,
  lastCompleted: null,
  showingDeactivation: false,
  showingProcessing: false,
  showingEmailPreview: false,
  showingWarningRemoval: false,
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_LAST_REQUEST:
      return {
        ...state,
        last: null,
      };
    case FETCH_LAST_SUCCESS:
      return {
        ...state,
        last: action.payload,
      };
    case FETCH_LAST_COMPLETED_REQUEST:
      return {
        ...state,
        lastCompleted: null,
      };
    case FETCH_LAST_COMPLETED_SUCCESS:
      return {
        ...state,
        lastCompleted: action.payload,
      };
    case CREATE_SUCCESS:
    case VOTE_SUCCESS:
    case BREAK_TIE_SUCCESS:
    case REMOVE_WARNINGS_SUCCESS:
      return {
        ...state,
        last: action.payload,
        lastCompleted:
          action.payload.status === "completed"
            ? action.payload
            : state.lastCompleted,
      };
    case TOGGLE_ACCOUNT_DEACTIVATION:
      return {
        ...state,
        showingDeactivation: !state.showingDeactivation,
      };
    case TOGGLE_PROCESSING:
      return {
        ...state,
        showingProcessing: !state.showingProcessing,
      };
    case TOGGLE_EMAIL_PREVIEW:
      return {
        ...state,
        showingEmailPreview: !state.showingEmailPreview,
      };
    case TOGGLE_WARNING_REMOVAL:
      return {
        ...state,
        showingWarningRemoval: !state.showingWarningRemoval,
      };
    default:
      return state;
  }
}

export const fetchLast = (userId) => (dispatch) => {
  return dispatch({
    type: FETCH_LAST,
    promise: request.get(`/api/v1/quality_reviews/${userId}/latest/`),
  });
};

export const fetchLastCompleted = (userId) => (dispatch) => {
  return dispatch({
    type: FETCH_LAST_COMPLETED,
    promise: request.get(`/api/v1/quality_reviews/${userId}/latest_completed/`),
  });
};

export const create = (userId, data) => (dispatch) => {
  const promise = request.post(
    `/api/v1/quality_reviews/${userId}/initiate/`,
    data
  );

  dispatch({
    type: CREATE,
    promise,
  });

  return promise;
};

export const vote = (userId, data) => (dispatch) => {
  const promise = request.put(`/api/v1/quality_reviews/${userId}/vote/`, data);

  dispatch({
    type: VOTE,
    promise,
  });

  return promise;
};

export const breakTie = (userId, data) => (dispatch) => {
  const promise = request.put(
    `/api/v1/quality_reviews/${userId}/break_tie/`,
    data
  );

  dispatch({
    type: BREAK_TIE,
    promise,
  });

  return promise;
};

export const toggleDeactivation = () => (dispatch) => {
  return dispatch({
    type: TOGGLE_ACCOUNT_DEACTIVATION,
  });
};

export const toggleProcessing = () => (dispatch) => {
  return dispatch({
    type: TOGGLE_PROCESSING,
  });
};

export const toggleEmailPreview = () => (dispatch) => {
  return dispatch({
    type: TOGGLE_EMAIL_PREVIEW,
  });
};

export const toggleWarningRemoval = () => (dispatch) => {
  return dispatch({
    type: TOGGLE_WARNING_REMOVAL,
  });
};

export const removeWarnings = (userId, data) => (dispatch) => {
  const promise = request.patch(
    `/api/v1/quality_reviews/${userId}/remove_groups_to_notify/`,
    data
  );

  dispatch({
    type: REMOVE_WARNINGS,
    promise,
  });

  return promise;
};
