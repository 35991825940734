import React from "react";
import PropTypes from "prop-types";
import HeaderTitle from "header/components/title";

const VerifyEmailHeader = ({ emailVerified }) => {
  let title = "Verifying your email...";
  if (emailVerified === true) {
    title = "Success!";
  } else if (emailVerified === false) {
    title = "Whoops!";
  }
  return <HeaderTitle title={title} />;
};

export default VerifyEmailHeader;
