import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import idx from "idx";
import partial from "lodash/partial";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { showBanner, hideBanner } from "common/ducks/banner";
import { fetchAll as fetchFocusGroups } from "focus_groups/ducks/common";
import { fetchAll as fetchPresentations } from "presentations/ducks";
import { getWrapups } from "project/ducks/wrapup";
import {
  getProjectIdFromUrl,
  isCollaborator,
  getProjectUrl,
  canViewAllEntries,
  showPromoContent,
  showTour,
} from "project/utils";
import { getProject } from "project/ducks/project";
import { getSubcategorySlug } from "common/ducks/categories";
import HeaderTabs from "project/components/header_tabs";

export function mapStateToProps(state, { router, routes }) {
  const project = getProject(state);
  const subcategory = getSubcategorySlug(
    state.categories,
    project.sub_category
  );
  const projectUrl = getProjectUrl(project, subcategory) || "";
  const user = state.user.profile_data;
  const userId = user.id;
  const isCreative = !!user.is_creative;
  const presentations =
    idx(state, (_) => _.project.presentations.presentations) || [];

  return {
    project,
    projectUrl,
    router,
    presentations,
    wrapups: getWrapups(state),
    isCreative,
    isCollaborator: isCollaborator(project),
    isOwner: project.id && project.client === userId,
    isAuthenticated: !!userId,
    showEntries: canViewAllEntries(state),
    showPromoContent: showPromoContent({ user, project, routes }),
    showTour: showTour({ user, project }),
  };
}

export function mapDispatchToProps(dispatch, { params }) {
  const projectId = getProjectIdFromUrl(params);

  return bindActionCreators(
    {
      fetchFocusGroups: partial(fetchFocusGroups, projectId),
      fetchPresentations: partial(fetchPresentations, projectId),
      showBanner,
      hideBanner,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderTabs)
);
