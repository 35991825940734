import React, { Component } from "react";
import idx from "idx";
import truncate from "lodash/truncate";
import * as Sentry from "@sentry/browser";
import ErrorPage from "error/containers";

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    /* report error details to Sentry */
    Sentry.withScope((scope) => {
      const msg = truncate(idx(error, (_) => _.message) || "", { length: 50 });
      let title = "Error captured by error boundary";
      if (msg) {
        title = `${title}: ${msg}`;
      }
      Sentry.setExtra("summary", error);
      Sentry.setExtra("details", errorInfo);
      Sentry.captureMessage(title, "error");
    });

    /* send the user to the blog if the error is due to a code split failure */
    if (error?.message.includes("Minified React error #130")) {
      location.href = "https://www.crowdspring.com/blog/";
    }
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage showRefreshBtn={true} />;
    }

    return this.props.children;
  }
}
