import React from "react";
import PropTypes from "prop-types";
import ProjectLink from "common/components/project_link";

const NotificationProjectLink = (props) => {
  // we have two subcategories because we updated the context on the backend, and
  // the ui needs to handle both
  const subcategorySlug = props.project.sub_category
    ? props.project.sub_category.slug
    : props.project.subcategory.slug;

  return <ProjectLink {...props} subcategory={subcategorySlug} />;
};

export default NotificationProjectLink;
