import { combineReducers } from "redux";
import ProjectsReducer from "admin/ducks/projects";
import PaymentsReducer from "admin/ducks/payments";
import NotesReducer from "admin/ducks/notes";
import PeopleReducer from "admin/ducks/people";
import BatchesReducer from "admin/ducks/batches";
import UserEntriesReducer from "admin/ducks/user_entries";
import ProjectEntriesReducer from "admin/ducks/project_entries";
import ProjectSingleEntryReducer from "admin/ducks/project_single_entry";
import ProjectActionsReducer from "admin/ducks/project_actions";
import UserActionsReducer from "admin/ducks/user_actions";
import ReviewsReducer from "admin/ducks/reviews";
import VerificationReducer from "admin/ducks/verification";
import QualityReviewReducer from "admin/ducks/quality_review";
import MarketingReducer from "admin/ducks/marketing";
import CommonReducer from "admin/ducks/common";
import PeriodicTasksReducer from "admin/ducks/periodic_tasks";
import RecentEntriesReducer from "admin/ducks/recent_entries";
import PayoutRequestsReducer from "admin/ducks/payout_requests";
import BrandStudioReducer from "admin/ducks/brand_studio";
import BlockedEmailsReducer from "admin/ducks/blocked_emails";
import EntriesReducer from "admin/ducks/entries";

const RootReducer = combineReducers({
  common: CommonReducer,
  projects: ProjectsReducer,
  notes: NotesReducer,
  people: PeopleReducer,
  payments: PaymentsReducer,
  batches: BatchesReducer,
  userEntries: UserEntriesReducer,
  projectEntries: ProjectEntriesReducer,
  projectSingleEntry: ProjectSingleEntryReducer,
  projectActions: ProjectActionsReducer,
  userActions: UserActionsReducer,
  reviews: ReviewsReducer,
  verification: VerificationReducer,
  marketing: MarketingReducer,
  qualityReview: QualityReviewReducer,
  periodicTasks: PeriodicTasksReducer,
  recentEntries: RecentEntriesReducer,
  payoutRequests: PayoutRequestsReducer,
  brandStudio: BrandStudioReducer,
  blockedEmails: BlockedEmailsReducer,
  entries: EntriesReducer,
});

export default RootReducer;
