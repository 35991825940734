import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Link } from "react-router";
import NotificationsList from "notifications/containers/list";
import Icon from "common/components/svg_icon";
import { EXPIRATION_IN_DAYS } from "notifications/constants";

class Drawer extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { userId, markAllAsSeen, notificationIds, showDrawer } = this.props;
    if (
      !nextProps.hide &&
      !showDrawer &&
      nextProps.showDrawer &&
      notificationIds.length
    ) {
      markAllAsSeen(userId, notificationIds);
    }
  }

  render() {
    const { showDrawer, toggleDrawer, hide } = this.props;
    if (hide) {
      return null;
    }

    const classes = classnames("notification-drawer", { open: showDrawer });
    return (
      <div className={classes}>
        <div className="notifications-drawer-container">
          <div className="notifications-drawer-header">
            <div className="row collapse">
              <div className="small-8 columns">
                <button
                  className="btn btn-icon-only btn-close"
                  onClick={toggleDrawer}
                >
                  <Icon icon="close" />
                </button>
                <h4>Notifications</h4>
              </div>
              <div className="small-8 columns text-right">
                <ul className="notification-links">
                  <li>
                    <Link to="/notifications/">View all</Link>
                  </li>
                  <li>
                    <Link to="/account/notifications/">
                      <i className="fa fa-gears" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="expiration-notice">
              Notifications are deleted after {EXPIRATION_IN_DAYS} days.
            </div>
          </div>
          <NotificationsList />
        </div>
      </div>
    );
  }
}

Drawer.propTypes = {
  hide: PropTypes.bool,
  showDrawer: PropTypes.bool,
  toggleDrawer: PropTypes.func.isRequired,
  markAllAsSeen: PropTypes.func.isRequired,
  notificationIds: PropTypes.array.isRequired,
  userId: PropTypes.number,
};

export default Drawer;
