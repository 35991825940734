import request from "axios";
import partial from "lodash/partial";
import moment from "moment";
import { combineReducers } from "redux";
import { createAction, handleActions } from "redux-actions";
import { SubmissionError } from "redux-form";
import { setBannerError } from "error";
import { showBanner, TYPE_SUCCESS } from "common/ducks/banner";
import { reducer as formReducer } from "redux-form";

export const FETCH_COLLABORATORS_REQUEST =
  "crowdspring/collaborators/FETCH_COLLABORATORS_REQUEST";
export const FETCH_COLLABORATORS_SUCCESS =
  "crowdspring/collaborators/FETCH_COLLABORATORS_SUCCESS";

export const UPDATE_PERMISSION_SUCCESS =
  "crowdspring/collaborators/UPDATE_PERMISSION_SUCCESS";

export const INVITE_COLLABORATOR_REQUEST =
  "crowdspring/collaborators/INVITE_COLLABORATOR_REQUEST";
export const INVITE_COLLABORATOR_SUCCESS =
  "crowdspring/collaborators/INVITE_COLLABORATOR_SUCCESS";

export const RESEND_COLLABORATOR_REQUEST =
  "crowdspring/collaborators/RESEND_COLLABORATOR_REQUEST";
export const RESEND_COLLABORATOR_SUCCESS =
  "crowdspring/collaborators/RESEND_COLLABORATOR_SUCCESS";

export const DELETE_COLLABORATOR_REQUEST =
  "crowdspring/collaborators/DELETE_COLLABORATOR_REQUEST";
export const DELETE_COLLABORATOR_SUCCESS =
  "crowdspring/collaborators/DELETE_COLLABORATOR_SUCCESS";

export const SET_DELETE_COLLABORATOR =
  "crowdspring/collaborators/SET_DELETE_COLLABORATOR";
export const TOGGLE_COLLABORATOR_MODAL =
  "crowdspring/collaborators/TOGGLE_COLLABORATOR_MODAL";

export const COLLABORATOR_HISTORY_REQUEST =
  "crowdspring/collaborators/COLLABORATOR_HISTORY_REQUEST";
export const COLLABORATOR_HISTORY_SUCCESS =
  "crowdspring/collaborators/COLLABORATOR_HISTORY_SUCCESS";
export const CLOSE_COLLABORATOR_HISTORY =
  "crowdspring/collaborators/CLOSE_COLLABORATOR_HISTORY";

export const ACCEPT_INVITE_REQUEST =
  "crowdspring/collaborators/ACCEPT_INVITE_REQUEST";
export const ACCEPT_INVITE_SUCCESS =
  "crowdspring/collaborators/ACCEPT_INVITE_SUCCESS";
export const ACCEPT_INVITE_FAILURE =
  "crowdspring/collaborators/ACCEPT_INVITE_FAILURE";

export const reqCollaborators = createAction(FETCH_COLLABORATORS_REQUEST);
export const recCollaborators = createAction(FETCH_COLLABORATORS_SUCCESS);

export const reqInvite = createAction(INVITE_COLLABORATOR_REQUEST);
export const recInvite = createAction(INVITE_COLLABORATOR_SUCCESS);

export const reqDelete = createAction(INVITE_COLLABORATOR_REQUEST);
export const recDelete = createAction(INVITE_COLLABORATOR_SUCCESS);

export const reqResend = createAction(RESEND_COLLABORATOR_REQUEST);
export const recResend = createAction(RESEND_COLLABORATOR_SUCCESS);

export const updatedPermission = createAction(UPDATE_PERMISSION_SUCCESS);

export const setDeleteCollaborator = createAction(SET_DELETE_COLLABORATOR);
export const toggleCollaboratorModal = createAction(TOGGLE_COLLABORATOR_MODAL);

export const reqCollaboratorHistory = createAction(
  COLLABORATOR_HISTORY_REQUEST,
  (id, user) => ({
    id,
    user,
  })
);
export const recCollaboratorHistory = createAction(
  COLLABORATOR_HISTORY_SUCCESS
);
export const closeCollaboratorHistory = createAction(
  CLOSE_COLLABORATOR_HISTORY
);

export const reqAcceptInvite = createAction(ACCEPT_INVITE_REQUEST);
export const recAcceptInvite = createAction(ACCEPT_INVITE_SUCCESS);
export const refAcceptInvite = createAction(ACCEPT_INVITE_FAILURE);

export const fetchCollaborators = (projectId) => (dispatch) => {
  dispatch(reqCollaborators());
  const collabRoutes = `/api/v1/projects/${projectId}/collaboration-invites/`;
  const permRoutes = `/api/v1/projects/${projectId}/collaboration-invites/available_permissions/`;
  const reqCollabs = partial(request.get, collabRoutes);
  const reqPerms = partial(request.get, permRoutes);

  request
    .all([reqCollabs(), reqPerms()])
    .then(
      request.spread((collaborators, permissions) => {
        dispatch(
          recCollaborators({
            collaborators: collaborators.data,
            permissions: permissions.data,
          })
        );
      })
    )
    .catch(() =>
      dispatch(
        setBannerError(
          "Collaborator Error",
          "There was an error retrieving collaborators"
        )
      )
    );
};

export const inviteCollaborator =
  (projectId, values) => (dispatch, getState) => {
    const availablePerms = (
      getState().project.collaborators.data.permissions || []
    ).map((perm) => perm[0]);
    const permissions = availablePerms.reduce(
      (acc, perm) => ({ ...acc, [perm]: values[perm] || false }),
      {}
    );
    const data = { ...values, permissions };

    dispatch(reqInvite());
    return request
      .post(`/api/v1/projects/${projectId}/collaboration-invites/`, data)
      .then((newCollab) => {
        dispatch(recInvite(newCollab));
        dispatch(fetchCollaborators(projectId));
      })
      .catch((e) => {
        throw new SubmissionError({
          _error:
            e.data.message || "Failed to send invitation. Please try again.",
        });
      });
  };

export const resend = (projectId, id) => (dispatch) => {
  dispatch(reqResend());
  request
    .patch(`/api/v1/projects/${projectId}/collaboration-invites/${id}/resend/`)
    .then(() => {
      dispatch(recResend({ id }));
      dispatch(
        showBanner(
          "Success",
          "Invitation successfully resent.",
          undefined,
          TYPE_SUCCESS,
          null,
          10000
        )
      );
    })
    .catch(() =>
      dispatch(
        setBannerError("Error resending invitation", "Please try again.")
      )
    );
};

export const updatePermission = (projectId, id, permissions) => (dispatch) => {
  const route = `/api/v1/projects/${projectId}/collaboration-invites/${id}/`;
  return request
    .patch(route, { permissions })
    .then(() => dispatch(updatedPermission({ id, permissions })))
    .catch(() =>
      dispatch(
        setBannerError("Failed to update permissions", "Please try again.")
      )
    );
};

export const removeCollaborator = (projectId) => (dispatch, getState) => {
  const { id } = getState().project.collaborators.data.remove;
  const route = `/api/v1/projects/${projectId}/collaboration-invites/${id}/`;

  return request
    .delete(route)
    .then(() => {
      dispatch(setDeleteCollaborator(null));
      dispatch(fetchCollaborators(projectId));
    })
    .catch(() =>
      dispatch(
        setBannerError("Failed to remove collaborator", "Please try again.")
      )
    );
};

export const fetchHistory = (projectId, id, user) => (dispatch) => {
  const route = `/api/v1/projects/${projectId}/collaboration-invites/${id}/history/`;
  dispatch(reqCollaboratorHistory(id, user));
  return request
    .get(route)
    .then(({ data }) => {
      dispatch(recCollaboratorHistory(data));
    })
    .catch(() =>
      dispatch(
        setBannerError("Failed to retrieve activity", "Please try again.")
      )
    );
};

export const acceptInvite = (projectId, inviteId, key) => (dispatch) => {
  dispatch(reqAcceptInvite());

  const route = `/api/v1/projects/${projectId}/collaboration-invites/${inviteId}/accept/`;

  return request
    .patch(route, { key })
    .then(({ data }) => {
      dispatch(recAcceptInvite(data));
    })
    .catch(() => dispatch(refAcceptInvite()));
};

const INITIAL_STATE = {
  isLoading: false,
  collaborators: null,
  permissions: null,
  showModal: false,
  remove: null,
  historyUser: null,
  historyId: null,
  historyList: [],
  inviteAccepted: undefined,
};

export function collaboratorReducer(state, action) {
  if (action.payload && action.payload.id !== state.id) {
    return state;
  }

  if (action.type === UPDATE_PERMISSION_SUCCESS) {
    const permissions = {
      ...state.permissions,
      ...action.payload.permissions,
    };

    return {
      ...state,
      permissions,
    };
  } else if (action.type === RESEND_COLLABORATOR_SUCCESS) {
    return {
      ...state,
      last_sent_at: moment().format(),
    };
  }

  return state;
}

const mapCollaborators = (state, action) => {
  const collaborators = state.collaborators.map((collaborator) =>
    collaboratorReducer(collaborator, action)
  );
  return {
    ...state,
    collaborators,
  };
};

const allCollaboratorsReducer = handleActions(
  {
    [reqCollaborators]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [recCollaborators]: (state, action) => {
      const { collaborators, permissions } = action.payload;
      return {
        ...state,
        collaborators,
        permissions,
        isLoading: false,
      };
    },
    [setDeleteCollaborator]: (state, action) => ({
      ...state,
      remove: action.payload,
    }),
    [toggleCollaboratorModal]: (state) => ({
      ...state,
      showModal: !state.showModal,
    }),
    [recInvite]: (state) => ({
      ...state,
      showModal: false,
    }),
    [updatedPermission]: mapCollaborators,
    [recResend]: mapCollaborators,
    [reqCollaboratorHistory]: (state, action) => ({
      ...state,
      isLoading: true,
      historyUser: action.payload.user,
      historyId: action.payload.id,
      historyList: [],
    }),
    [recCollaboratorHistory]: (state, action) => ({
      ...state,
      isLoading: false,
      historyList: action.payload,
    }),
    [closeCollaboratorHistory]: (state) => ({
      ...state,
      isLoading: false,
      historyUser: null,
      historyId: null,
      historyList: [],
    }),
    [reqAcceptInvite]: (state) => ({
      ...state,
      inviteAccepted: undefined,
    }),
    [recAcceptInvite]: (state, action) => ({
      ...state,
      inviteAccepted: true,
      inviteProject: action.payload,
    }),
    [refAcceptInvite]: (state) => ({
      ...state,
      inviteAccepted: false,
    }),
  },
  INITIAL_STATE
);

const RootReducer = combineReducers({
  form: formReducer,
  data: allCollaboratorsReducer,
});

export default RootReducer;
