import request from "axios";
import { browserHistory } from "react-router";

export const FETCH_CATEGORIES = "crowdspring/help/FETCH_CATEGORIES";
export const FETCH_CATEGORIES_REQUEST =
  "crowdspring/help/FETCH_CATEGORIES_REQUEST";
export const FETCH_CATEGORIES_FAILURE =
  "crowdspring/help/FETCH_CATEGORIES_FAILURE";
export const FETCH_CATEGORIES_SUCCESS =
  "crowdspring/help/FETCH_CATEGORIES_SUCCESS";

export const FETCH_CATEGORY_ARTICLES =
  "crowdspring/help/FETCH_CATEGORY_ARTICLES";
export const FETCH_CATEGORY_ARTICLES_REQUEST =
  "crowdspring/help/FETCH_CATEGORY_ARTICLES_REQUEST";
export const FETCH_CATEGORY_ARTICLES_FAILURE =
  "crowdspring/help/FETCH_CATEGORY_ARTICLES_FAILURE";
export const FETCH_CATEGORY_ARTICLES_SUCCESS =
  "crowdspring/help/FETCH_CATEGORY_ARTICLES_SUCCESS";

export const fetchAllCategoriesIfNeeded = () => (dispatch, getState) => {
  /* categories already fetched */
  if (getState().help.categories.data) {
    return Promise.resolve();
  }

  const opts = {
    method: "get",
    url: "/api/v1/article-categories/",
  };

  return dispatch({
    type: FETCH_CATEGORIES,
    promise: request(opts),
  });
};

export const fetchCategoryArticlesIfNeeded =
  (categorySlug) => (dispatch, getState) => {
    /* articles for this category already fetched */
    const categories = getState().help.categories;
    if (
      categories.articles &&
      categories.articlesCategorySlug === categorySlug
    ) {
      return Promise.resolve();
    }

    const promise = request({
      method: "get",
      url: `/api/v1/article-categories/${categorySlug}/`,
    });

    /* Handle missing category.
     In case `browserHistory` is not available, it means server-rendering is being performed
     and the 404 error will be handled on the request level.
     Otherwise, user is redirected to /404. */
    promise.catch((response) => {
      if (response.status === 404 && browserHistory) {
        browserHistory.push("/404");
      }
    });

    return dispatch({
      type: FETCH_CATEGORY_ARTICLES,
      articlesCategorySlug: categorySlug,
      promise,
    });
  };

export const INITIAL_STATE = {
  isLoading: false,
  data: null,
  articles: null,
  articlesCategorySlug: null,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_CATEGORIES_REQUEST:
      return {
        ...state,
        isLoading: true,
        data: null,
      };
    case FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case FETCH_CATEGORY_ARTICLES_REQUEST:
      return {
        ...state,
        isLoading: true,
        articles: null,
      };
    case FETCH_CATEGORY_ARTICLES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        articles: action.payload.results,
        articlesCategorySlug: action.articlesCategorySlug,
      };
    default:
      return state;
  }
}
