import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Link } from "react-router";
import partial from "lodash/partial";
import Fuse from "fuse.js";
import { TextInputField } from "common/components/form_fields";
import Money from "common/components/money";
import author1 from "assets/images/pap_testimonial-1-author.jpg";
import author2 from "assets/images/pap_testimonial-2-author.jpg";
import author3 from "assets/images/pap_testimonial-3-author.jpg";
import author4 from "assets/images/pap_testimonial-4-author.jpg";
import oneToOneIcon from "assets/images/icons/pap/PAP_cat_icon_1-to-1.svg";
import designConsultationIcon from "assets/images/icons/pap/PAP_cat_icon_design-consultation.svg";
import SubcategoryIcon from "common/components/subcategory_icon";
import { getSubCategories } from "common/ducks/categories";
import { SUBCAT_SEARCH_FUZZY_OPTS } from "common/variables";
import { CONTEXT_SUBCAT_SEARCH } from "searched_terms/constants";
import { createTermRequest } from "searched_terms/ducks";
import { scrollTo } from "common/utils";

const TESTIMONIALS_BY_CAT_ID = ({ hidePricing }) => ({
  7: hidePricing
    ? {
        width: "small-16 medium-16 large-8",
        alt: "Patricia F. crowdspring testimonial photo",
        name: "Patricia F.",
        description: "New Port Richey, FL",
        text: "I love crowdspring. I have used them 3 times for logos. I had so many designs to pick from. Each time I ended up with the perfect logo for my companies.",
      }
    : {
        width: "small-16 medium-16 large-8",
        avatar: author1,
        alt: "Matthew Stephenson crowdspring testimonial photo",
        name: "Matthew Stephenson, Executive Director",
        description: "Code2College - Pflugerville, TX",
        text: "We received nearly 100 entries from over 25 creatives and had so many high-quality submissions, I had to ask friends for help to choose a final logo.",
      },
  10: {
    width: "small-16 medium-16 large-12",
    avatar: author2,
    alt: "Jeff Knowles crowdspring testimonial photo",
    name: "Jeff Knowles",
    description: "Florida Fundraising Association - Vero Beach, FL",
    text: "I’ve had great success with crowdspring, using the website to both redesign an existing website and to create a website from scratch. Both projects produced a large number of high quality design options.  Should I have a need for high quality design work in the future, crowdspring will be my first stop.",
  },
  8: {
    width: "small-16 medium-16 large-8",
    avatar: author3,
    alt: "Bill Moore crowdspring testimonial photo",
    name: "Bill Moore, CEO",
    description: "Zello - Austin,TX",
    text: "Crowdspring's army of creatives generated thousands of ideas for naming our company. Now, Zello is the most popular walkie-talkie app with more than 100 million registered users.",
  },
  3: {
    width: "small-16 medium-16 large-8",
    avatar: author4,
    alt: "Elyse Dickerson crowdspring testimonial photo",
    name: "Elyse Dickerson, CEO",
    description: "Eosera - Fort Worth,TX",
    text: "I love everything about crowdspring. The network of creatives is vast and provides great diversity in the results.",
  },
});

export const BUNDLE_SUBCATS = {
  "logo-and-business-card-design": {
    savings: 149,
    items: ["Logo", "Business card", "Letterhead", "Envelope"],
  },
  "logo-and-social-media-assets-design": {
    savings: 199,
    items: ["Logo", "Avatars for 3 platforms", "Headers for 3 platforms"],
  },
  "letterhead-and-stationery-design": {
    savings: 149,
    items: ["Business card", "Letterhead", "Envelope"],
  },
};

export default class CategoriesGrid extends Component {
  constructor(props) {
    super(props);

    this.state = { searchValue: "" };

    this.setSearchValue = this.setSearchValue.bind(this);
  }

  componentDidMount() {
    const { router } = this.props;
    const visit = router?.location?.query?.visit;

    if (visit) {
      scrollTo(visit, { offset: -16 });
    }
  }

  renderConsultationTile(key) {
    const { hidePricing } = this.props;

    return (
      <Link to="/help/contact/consultation/" key={key}>
        <div
          className={classnames("tile subcategory", {
            "pricing-hidden": hidePricing,
          })}
        >
          <i
            className="icon"
            dangerouslySetInnerHTML={{ __html: designConsultationIcon }}
          />
          <h4>Design Consultation</h4>
          <p className="description">
            Can't decide?
            <br />
            Let our experts help you.
          </p>
          <div className="pricing">
            <span className="min-price">free</span>
          </div>
          <div className="actions">
            <button className="btn btn-primary">Request consultation</button>
          </div>
        </div>
      </Link>
    );
  }

  render1to1Tile(key) {
    const { oneToOneUrl, oneToOneBtnLabel } = this.props;

    return (
      <Link to={oneToOneUrl} key={key}>
        <div className="tile subcategory">
          <i
            className="icon"
            dangerouslySetInnerHTML={{ __html: oneToOneIcon }}
          />
          <h4>Work 1-to-1</h4>
          <p className="description">
            Find a creative and work with them directly.
          </p>
          <div className="pricing">
            <label>From</label>
            <Money className="min-price" value="200" showDecimals={false} />
          </div>
          <div className="actions">
            <button className="btn btn-primary">
              {oneToOneBtnLabel || "Start 1-to-1"}
            </button>
          </div>
        </div>
      </Link>
    );
  }

  renderTestimonial(testimonial) {
    return (
      <div
        className={classnames(
          "columns align-middle",
          testimonial.width || "medium-8"
        )}
      >
        <div className="testimonial">
          <div className="row">
            <div
              className={classnames("columns text-center", {
                "small-8 small-offset-4 medium-4 medium-offset-0":
                  testimonial.avatar,
                "small-8 small-offset-2 medium-1 medium-offset-0":
                  !testimonial.avatar,
              })}
            >
              {!!testimonial.avatar && (
                <img
                  className="avatar avatar-lg avatar-round avatar-shadow"
                  src={testimonial.avatar}
                  alt={testimonial.alt}
                />
              )}
            </div>
            <div className="columns small-14 small-offset-1 medium-12 medium-offset-0">
              <div className="testimonial-text">
                <p>{testimonial.text}</p>
                <div className="attribution">
                  <div className="name">{testimonial.name}</div>
                  <div className="description">{testimonial.description}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSubcat(cat, subcat, key) {
    const { onSubcatClick, subcatBtnLabel, hidePricing } = this.props;
    const bundle = BUNDLE_SUBCATS[subcat.slug];

    return (
      <div
        className={classnames("tile subcategory", {
          bundle,
          "pricing-hidden": hidePricing,
        })}
        key={key}
        onClick={partial(onSubcatClick, cat, subcat)}
      >
        <SubcategoryIcon subcat={subcat} />
        <h4>{subcat.name}</h4>
        <p className="description">{subcat.description}</p>
        {!!bundle && (
          <div className="bundle-items">
            <ul>
              {bundle.items.map((item) => (
                <li key={item}>
                  <i className="fa fa-check" /> {item}
                </li>
              ))}
            </ul>
          </div>
        )}
        {!hidePricing && (
          <div className="pricing">
            <label>From</label>
            <Money
              className="min-price"
              value={subcat["minimum_price"]}
              showDecimals={false}
            />
            {!!bundle && (
              <div className="bundle-savings">
                Save <Money value={bundle.savings} showDecimals={false} />
              </div>
            )}
          </div>
        )}
        <div className="actions">
          <button className="btn btn-primary">
            {subcatBtnLabel || "Start"}{" "}
          </button>
        </div>
      </div>
    );
  }

  renderColumns(cat, showConsultationTile, show1to1Tile) {
    let columns = [];
    let items = [...cat.subcategories];
    if (showConsultationTile) {
      items = items.concat({ isConsultationTile: true });
    }
    if (show1to1Tile) {
      items = items.concat({ is1to1Tile: true });
    }

    items.forEach((item) => {
      const bundle = item.slug ? BUNDLE_SUBCATS[item.slug] : false;

      /* 1 bundle item per column */
      if (bundle) {
        columns = columns.concat([[{ ...item, bundle }]]);
      } else {
        const lastColumn = columns.slice(-1)[0];
        const beforeLastColumn = columns.slice(-2)[0];

        /* 2 non-bundle items per column if the preceding column was taken by
           a bundle item */
        if (
          lastColumn &&
          lastColumn.length === 1 &&
          !lastColumn[0].bundle &&
          beforeLastColumn &&
          !!beforeLastColumn[0].bundle
        ) {
          lastColumn[1] = item;
        } else {
          columns = columns.concat([[item]]);
        }
      }
    });

    return columns.map((column, i) => (
      <div className="columns small-16 medium-8 large-4" key={i}>
        {column.map((item, j) => {
          if (item.isConsultationTile) {
            return this.renderConsultationTile(j);
          }

          if (item.is1to1Tile) {
            return this.render1to1Tile(j);
          }

          return this.renderSubcat(cat, item, j);
        })}
      </div>
    ));
  }

  getCategories() {
    const { showSearch, categories, extraCategories } = this.props;
    const { searchValue } = this.state;
    const allCategories = categories.concat(extraCategories || []);

    if (!showSearch || !searchValue.trim()) {
      return allCategories;
    }

    const allSubcats = getSubCategories({ data: allCategories });
    const fuse = new Fuse(allSubcats, {
      ...SUBCAT_SEARCH_FUZZY_OPTS,
      keys: ["name", "keywords"],
    });
    const subcatIds = fuse.search(searchValue).map((r) => r.item.id);
    const matchedCats = allCategories.reduce((accCats, category) => {
      const matchedSubcats = category.subcategories.reduce(
        (accSubcats, subcat) =>
          subcatIds.includes(subcat.id) ? [...accSubcats, subcat] : accSubcats,
        []
      );

      return matchedSubcats.length
        ? [...accCats, { ...category, subcategories: matchedSubcats }]
        : accCats;
    }, []);

    return matchedCats;
  }

  setSearchValue(content) {
    this.setState({ searchValue: content });
    createTermRequest({ content, context: CONTEXT_SUBCAT_SEARCH });
  }

  render() {
    const { showSearch, popularOnly, oneToOneUrl, hidePricing } = this.props;
    const { searchValue } = this.state;
    const categories = this.getCategories();
    const showNonSubcatTiles = !showSearch || !searchValue.trim();

    return (
      <div className="categories-grid">
        {showSearch && (
          <div className="search-wrapper">
            <div className="row">
              <div className="columns small-16">
                <label htmlFor="category">
                  I need a<span className="hide-for-large">:</span>
                </label>
                <TextInputField
                  input={{
                    value: searchValue,
                    onChange: (e) => this.setSearchValue(e.target.value),
                  }}
                  meta={{}}
                  placeholder="logo, website, business name, product design..."
                />
              </div>
            </div>
          </div>
        )}

        {categories.map((cat, i) => {
          const showConsultationTile =
            !popularOnly &&
            showNonSubcatTiles &&
            [
              "logo-identity",
              "clothing-merchandise",
              "product-packaging",
            ].includes(cat.slug);
          const show1to1Tile =
            !!oneToOneUrl &&
            showNonSubcatTiles &&
            [
              "logo-identity",
              "web-mobile-design",
              "business-advertising",
              "art-illustration",
            ].includes(cat.slug);
          const testimonial = TESTIMONIALS_BY_CAT_ID({ hidePricing })[cat.id];

          return (
            <div key={i} id={cat.slug} className="category">
              <div className="row">
                <div className="columns small-16">
                  <h3>{cat.name}</h3>
                </div>
              </div>

              <div className="row">
                {this.renderColumns(cat, showConsultationTile, show1to1Tile)}

                {showNonSubcatTiles &&
                  !!testimonial &&
                  this.renderTestimonial(testimonial)}
              </div>
            </div>
          );
        })}

        <div className="row experts-cta text-center">
          <div className="columns small-16">
            {categories.length === 0
              ? "Didn't find what you need?"
              : "Have questions?"}{" "}
            <br className="show-for-small-only" />
            <Link to="/help/contact/">
              Let our experts help you <i className="fa fa-long-arrow-right" />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

CategoriesGrid.propTypes = {
  categories: PropTypes.array.isRequired,
  extraCategories: PropTypes.array,
  onSubcatClick: PropTypes.func.isRequired,
  oneToOneUrl: PropTypes.string,
  oneToOneBtnLabel: PropTypes.string,
  subcatBtnLabel: PropTypes.string,
  showSearch: PropTypes.bool,
  popularOnly: PropTypes.bool,
  hidePricing: PropTypes.bool,
  router: PropTypes.object,
};
