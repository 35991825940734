import React, { Component } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import classnames from "classnames";
import { getQueryFromValue } from "common/filter/utils";
import { getQueryFromValue as getPagerQueryFromValue } from "common/pager/utils";
import {
  TYPE_CHECKBOXES,
  TYPE_SELECT,
  TYPE_RANGE,
  TYPE_RANGE_SELECT,
  TYPE_STRING,
  TYPE_TEXTAREA,
} from "common/filter/constants";
import Checkboxes from "common/filter/components/checkboxes";
import Select from "common/filter/components/select";
import Range from "common/filter/components/range";
import StringFilter from "common/filter/components/string";
import TextArea from "common/filter/components/textarea";

const FILTER_COMPONENTS = {
  [TYPE_CHECKBOXES]: Checkboxes,
  [TYPE_SELECT]: Select,
  [TYPE_RANGE]: Range,
  [TYPE_RANGE_SELECT]: Select,
  [TYPE_STRING]: StringFilter,
  [TYPE_TEXTAREA]: TextArea,
};

/**
 * Re-usable filter component.
 * Filter values are always read/updated from/to the URL query parameters.
 * The actual display of filters is delegated to the component determined based
 * on the `type` prop. It is provided with the following common props:
 * - value: current filter value
 * - onChange: callback to be invoked when filter value is changed
 *
 * @author Nathan Ozelim <nathan@startupfoundry.com>
 */
export default class Filter extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    /* save initial query for filter */
    const { resourceName, saveLastQuery, field, type, value, skipQuerySave } =
      this.props;
    const filterQuery = getQueryFromValue(resourceName, field, type, value);
    if (!skipQuerySave) {
      saveLastQuery(resourceName, filterQuery);
    }
  }

  componentDidUpdate(prevProps) {
    const { resourceName, saveLastQuery, field, type, value, skipQuerySave } =
      this.props;

    /* save query when parameters are updated */
    if (!isEqual(prevProps.value, value) && !skipQuerySave) {
      const filterQuery = getQueryFromValue(resourceName, field, type, value);
      saveLastQuery(resourceName, filterQuery);
    }
  }

  handleChange(value) {
    const { router, resourceName, field, type } = this.props;
    const currentLocation = router.getCurrentLocation();
    const filterQuery = getQueryFromValue(resourceName, field, type, value);
    /* clear page when updating filters */
    const pagerQuery = getPagerQueryFromValue(resourceName, "");
    const updatedQuery = {
      ...currentLocation.query,
      ...filterQuery,
      ...pagerQuery,
    };

    /* update URL with new query parameters */
    router.push({
      ...currentLocation,
      query: updatedQuery,
    });
  }

  render() {
    const { type } = this.props;

    const Component = FILTER_COMPONENTS[type];

    return (
      <div className={classnames("filter", type)}>
        <Component {...this.props} onChange={this.handleChange} />
      </div>
    );
  }
}

Filter.propTypes = {
  router: PropTypes.object.isRequired,
  resourceName: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  saveLastQuery: PropTypes.func.isRequired,
  skipQuerySave: PropTypes.bool,
};
