import request from "axios";
import queryString from "query-string";
import { browserHistory } from "react-router";
import { PROJECTS_RESOURCE_NAME } from "your_projects/constants";
import { getAPIQueryString } from "common/utils";
import { savePageCount } from "common/pager/ducks";
import { setBannerError } from "error";
import { getValueFromQuery } from "common/filter/utils";
import { TYPE_CHECKBOXES } from "common/filter/constants";
import merge from "lodash/merge";

/* ACTIONS
================================================================================================ */
const FETCH = "crowdspring/your_projects/FETCH";
const FETCH_REQUEST = "crowdspring/your_projects/FETCH_REQUEST";
const FETCH_SUCCESS = "crowdspring/your_projects/FETCH_SUCCESS";

const FETCH_COUNTS = "crowdspring/your_projects/FETCH_COUNTS";
const FETCH_COUNTS_SUCCESS = "crowdspring/your_projects/FETCH_COUNTS_SUCCESS";

const CLEAR = "crowdspring/your_projects/CLEAR";

const TOGGLE_REMOVE_DRAFT = "crowdspring/your_projects/TOGGLE_REMOVE_DRAFT";

/* HELPERS
================================================================================================ */
const URL_MAP = {
  client: {
    default: "/api/v1/projects/mine/",
  },
  creative: {
    default: "/api/v1/projects/participated/",
    watchlist: "/api/v1/watchlist/",
  },
};

export const PARAMS_MAP = {
  client: {
    drafts: {
      status: ["draft", "1_1_negotiating", "1_1_ready"],
    },
    active: {
      status: ["open", "closed", "finalist_round", "finalist_closed"],
    },
    wrapup: {
      status: ["awarded"],
    },
    complete: {
      status: ["completed"],
    },
  },
  creative: {
    watchlist: {},
    participating: {
      status: [
        "open",
        "closed",
        "finalist_round",
        "finalist_closed",
        "1_1_negotiating",
        "1_1_ready",
      ],
    },
    wrapup: {
      status: ["awarded"],
      awarded: true,
    },
    awarded: {
      status: ["completed"],
      awarded: true,
    },
    history: {
      status: ["awarded", "completed"],
      awarded: false,
    },
  },
};

/* INITIAL STATES
================================================================================================ */
export const initialState = {
  list: [],
  isFetching: false,
  counts: { client: {}, creative: {} },
  removingDraft: null,
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        list: [],
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: action.payload.results,
        counts: {
          ...state.counts,
          [action.userType]: {
            ...state.counts[action.userType],
            [action.projectType]: action.payload.count,
          },
        },
      };
    case CLEAR:
      return {
        ...state,
        list: [],
      };
    case FETCH_COUNTS_SUCCESS:
      return {
        ...state,
        counts: merge({}, state.counts, action.payload),
      };
    case TOGGLE_REMOVE_DRAFT:
      return {
        ...state,
        removingDraft: action.payload,
      };
    default:
      return state;
  }
}

export const fetch = (userType, projectType, query) => (dispatch, getState) => {
  const url = URL_MAP[userType][projectType] || URL_MAP[userType]["default"];
  let params = PARAMS_MAP[userType][projectType];
  /* override status filter if specified */
  const overridenStatus = getValueFromQuery(
    PROJECTS_RESOURCE_NAME,
    "filter_status",
    TYPE_CHECKBOXES,
    query
  );
  if (overridenStatus.length > 0) {
    params = {
      ...params,
      filter_status: undefined,
      status: overridenStatus,
    };
  }
  const queryStr = getAPIQueryString(PROJECTS_RESOURCE_NAME, query, {
    extraAPIQuery: params,
  });
  const promise = request.get(`${url}?${queryStr}`);

  /* save page count in pager state */
  promise.then((res) => dispatch(savePageCount(PROJECTS_RESOURCE_NAME, res)));

  return dispatch({
    type: FETCH,
    promise,
    userType,
    projectType,
  });
};

export const clear = () => {
  return {
    type: CLEAR,
  };
};

export const fetchCounts =
  (activeUserType, activeProjectType) => (dispatch, getState) => {
    const user = getState().user.profile_data;
    const counts = { client: {}, creative: {} };

    let counters = ["client", "creative"].reduce((acc, userType) => {
      let userTypeCounters;

      if (!user[`is_${userType}`]) {
        userTypeCounters = [];
      } else {
        userTypeCounters = Object.keys(PARAMS_MAP[userType]).map(
          (projectType) => {
            const params = PARAMS_MAP[userType][projectType];
            const url =
              URL_MAP[userType][projectType] || URL_MAP[userType]["default"];

            return {
              requestUrl: `${url}?${queryString.stringify(params)}`,
              projectType,
              userType,
            };
          }
        );
      }

      return acc.concat(userTypeCounters);
    }, []);

    /* don't fetch count for active `userType` and `projectType`, since it will be
     provided along with the project list */
    counters = counters.filter(
      (c) =>
        !(c.userType === activeUserType && c.projectType === activeProjectType)
    );

    counters.reduce((acc, counter, i) => {
      return acc.then(() => {
        return request.get(counter.requestUrl).then((res) => {
          counts[counter.userType][counter.projectType] = res.data.count;
          if (i === counters.length - 1) {
            dispatch({
              type: FETCH_COUNTS_SUCCESS,
              payload: counts,
            });
          }
        });
      });
    }, Promise.resolve());
  };

export const toggleRemoveDraft = (draft) => ({
  type: TOGGLE_REMOVE_DRAFT,
  payload: draft,
});

export const removeDraft = () => (dispatch, getState) => {
  const { removingDraft } = getState().yourProjects;

  return request
    .delete(`/api/v1/projects/${removingDraft.id}/`)
    .then(() => {
      dispatch(toggleRemoveDraft(null));
      /* Give the backend (Elastic Search) 2s to reflect the changes before drafts are
       fetched again. */
      setTimeout(
        () => browserHistory.push("/your-projects/client/drafts/"),
        2000
      );
    })
    .catch((e) =>
      dispatch(setBannerError("Error removing draft", "Please try again."))
    );
};
