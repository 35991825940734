import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, browserHistory } from "react-router";
import classNames from "classnames";

/**
 * Renders a tab for navigation using react-router's Link component.
 * If provided, render link sub-items in a dropdown.
 *
 * @author Kuba Siemiątkowski <kuba@crowdspring.com>
 * @author Jing Wang <jing@crowdspring.com>
 */
export default class NavigationTab extends Component {
  constructor(props) {
    super(props);

    this.state = { subItemsToggled: false };
    this.toggleSubItems = this.toggleSubItems.bind(this);
  }

  toggleSubItems() {
    this.setState({ subItemsToggled: !this.state.subItemsToggled });
  }

  render() {
    const { to, title, isActive, subItems, onClick } = this.props;
    const { subItemsToggled } = this.state;
    const id = "tab_" + (title || "").toLowerCase().replace(/ /g, "_");

    return (
      <li className={classNames("tab-item", { selected: isActive })} id={id}>
        {subItems ? null : (
          <Link to={to} onClick={onClick}>
            {title}
          </Link>
        )}
        {subItems && (
          <div className="dropdown">
            <span
              className="dropdown-menu-toggle"
              onClick={this.toggleSubItems}
            >
              {title}
            </span>
            {subItemsToggled && (
              <ul className="dropdown-menu">
                {subItems.map(({ component, url, isActive, onClick }, i) => (
                  <li
                    onClick={onClick || (() => browserHistory.push(url))}
                    key={i}
                    className={classNames("dropdown-item", {
                      selected: isActive,
                    })}
                  >
                    {component}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </li>
    );
  }
}

/**
 * PropTypes.
 *
 * @property {string} to - internal url to navigate to
 * @property {string} title - title to display on the tab
 * @property {bool} isActive - whether to show active tab
 * @property {array} subItems - list of link sub-items
 */
NavigationTab.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
  isActive: PropTypes.bool,
  subItems: PropTypes.array,
};
