import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import classNames from "classnames";
import loggedInLogo from "assets/images/crowdspring_badge.svg";
import loggedOutLogo from "assets/images/crowdspring_logo.svg";

const Logo = ({ isLoggedIn, sidebar = false }) => {
  const logo = isLoggedIn ? loggedInLogo : loggedOutLogo;
  const logoClasses = classNames("cs-logo", {
    "logged-in": isLoggedIn,
    standard: !isLoggedIn,
  });
  const wrapperClasses = classNames("columns", {
    "small-16": sidebar,
    "small-8 large-1": isLoggedIn && !sidebar,
    "small-8 large-3": !isLoggedIn && !sidebar,
  });
  const to = isLoggedIn ? "/your-projects" : "/";

  return (
    <div className={wrapperClasses}>
      <div className={logoClasses}>
        <Link to={to} className="homelink">
          <div
            className="svg-logo"
            dangerouslySetInnerHTML={{ __html: logo }}
          />
        </Link>
      </div>
    </div>
  );
};

Logo.propTypes = {
  isLoggedIn: PropTypes.bool,
  sidebar: PropTypes.bool,
};

export default Logo;
