import request from "axios";
import { setBannerError } from "error";

/* ACTIONS
================================================================================================ */
const GENERATE_SUGGESTION = "honey/GENERATE_SUGGESTION";
const GENERATE_SUGGESTION_REQUEST = "honey/GENERATE_SUGGESTION_REQUEST";
const GENERATE_SUGGESTION_SUCCESS = "honey/GENERATE_SUGGESTION_SUCCESS";

const CLEAR_SUGGESTION = "honey/CLEAR_SUGGESTION";

/* HELPERS
================================================================================================ */

/* INITIAL STATES
================================================================================================ */
const initialState = {
  suggestion: null,
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GENERATE_SUGGESTION_REQUEST:
    case CLEAR_SUGGESTION:
      return {
        ...state,
        suggestion: null,
      };
    case GENERATE_SUGGESTION_SUCCESS:
      return {
        ...state,
        suggestion: action.payload,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const generateSuggestion = (formData) => (dispatch) => {
  const data = {
    ...formData,
    article_url:
      formData.article_source === "url" ? formData.article_url : undefined,
    article_html:
      formData.article_source === "html" ? formData.article_html : undefined,
    article_excerpt:
      formData.article_source === "excerpt"
        ? formData.article_excerpt
        : undefined,
  };

  const promise = request.post(
    "/api/v1/monitored_urls/generate_link_insertion_suggestion/",
    data
  );

  promise.catch((err) => {
    const errorMsg = err.data ? JSON.stringify(err.data) : "Please try again.";
    dispatch(setBannerError("Error generating suggestion", errorMsg));
  });

  dispatch({
    type: GENERATE_SUGGESTION,
    promise,
  });

  return promise;
};

export const clearSuggestion = () => (dispatch) =>
  dispatch({
    type: CLEAR_SUGGESTION,
  });
