import request from "axios";
import queryString from "query-string";
import idx from "idx";
import pick from "lodash/pick";
import range from "lodash/range";
import uniq from "lodash/uniq";
import isEqual from "lodash/isEqual";
import { browserHistory } from "react-router";
import { getAPIQueryString, getItemsFromString } from "common/utils";
import { savePageCount } from "common/pager/ducks";
import {
  CAMPAIGNS_RESOURCE_NAME,
  GROUPS_RESOURCE_NAME,
  GOALS_PER_CAMPAIGN,
  GROUPS_PER_CAMPAIGN,
} from "wasp/constants";
import { setBannerError } from "error";
import { showBanner, TYPE_SUCCESS } from "common/ducks/banner";
import { toggleActionConfirmation } from "admin/ducks/common";
import { ADMIN_ROOT } from "common/variables";
import {
  fromFormDataToEmails,
  fromEmailsToFormData,
} from "email_automations/ducks/bumblebee";

/* ACTIONS
================================================================================================ */
const FETCH_CAMPAIGNS = "wasp/campaigns/FETCH_CAMPAIGNS";
const FETCH_CAMPAIGNS_REQUEST = "wasp/campaigns/FETCH_CAMPAIGNS_REQUEST";
const FETCH_CAMPAIGNS_SUCCESS = "wasp/campaigns/FETCH_CAMPAIGNS_SUCCESS";

const FETCH_TOTALS = "wasp/campaigns/FETCH_TOTALS";
const FETCH_TOTALS_SUCCESS = "wasp/campaigns/FETCH_TOTALS_SUCCESS";

const FETCH_GROUP = "wasp/campaigns/FETCH_GROUP";
const FETCH_GROUP_REQUEST = "wasp/campaigns/FETCH_GROUP_REQUEST";
const FETCH_GROUP_SUCCESS = "wasp/campaigns/FETCH_GROUP_SUCCESS";

const FETCH_GROUPS = "wasp/campaigns/FETCH_GROUPS";
const FETCH_GROUPS_REQUEST = "wasp/campaigns/FETCH_GROUPS_REQUEST";
const FETCH_GROUPS_SUCCESS = "wasp/campaigns/FETCH_GROUPS_SUCCESS";

const FETCH_GROUP_TOTALS = "wasp/campaigns/FETCH_GROUP_TOTALS";
const FETCH_GROUP_TOTALS_SUCCESS = "wasp/campaigns/FETCH_GROUP_TOTALS_SUCCESS";

const LAUNCH_CAMPAIGN = "wasp/campaigns/LAUNCH_CAMPAIGN";
const LAUNCH_CAMPAIGN_SUCCESS = "wasp/campaigns/LAUNCH_CAMPAIGN_SUCCESS";

const PAUSE_CAMPAIGN = "wasp/campaigns/PAUSE_CAMPAIGN";
const PAUSE_CAMPAIGN_SUCCESS = "wasp/campaigns/PAUSE_CAMPAIGN_SUCCESS";

const RESUME_CAMPAIGN = "wasp/campaigns/RESUME_CAMPAIGN";
const RESUME_CAMPAIGN_SUCCESS = "wasp/campaigns/RESUME_CAMPAIGN_SUCCESS";

const SOFT_COMPLETE_CAMPAIGN = "wasp/campaigns/SOFT_COMPLETE_CAMPAIGN";
const SOFT_COMPLETE_CAMPAIGN_SUCCESS =
  "wasp/campaigns/SOFT_COMPLETE_CAMPAIGN_SUCCESS";

const COMPLETE_CAMPAIGN = "wasp/campaigns/COMPLETE_CAMPAIGN";
const COMPLETE_CAMPAIGN_SUCCESS = "wasp/campaigns/COMPLETE_CAMPAIGN_SUCCESS";

const PAUSE_GROUP = "wasp/campaigns/PAUSE_GROUP";
const PAUSE_GROUP_SUCCESS = "wasp/campaigns/PAUSE_GROUP_SUCCESS";

const RESUME_GROUP = "wasp/campaigns/RESUME_GROUP";
const RESUME_GROUP_SUCCESS = "wasp/campaigns/RESUME_GROUP_SUCCESS";

const SOFT_COMPLETE_GROUP = "wasp/campaigns/SOFT_COMPLETE_GROUP";
const SOFT_COMPLETE_GROUP_SUCCESS =
  "wasp/campaigns/SOFT_COMPLETE_GROUP_SUCCESS";

const COMPLETE_GROUP = "wasp/campaigns/COMPLETE_GROUP";
const COMPLETE_GROUP_SUCCESS = "wasp/campaigns/COMPLETE_GROUP_SUCCESS";

const FETCH_CAMPAIGN = "wasp/campaigns/FETCH_CAMPAIGN";
const FETCH_CAMPAIGN_REQUEST = "wasp/campaigns/FETCH_CAMPAIGN_REQUEST";
const FETCH_CAMPAIGN_SUCCESS = "wasp/campaigns/FETCH_CAMPAIGN_SUCCESS";

const FETCH_ALL_GROUPS = "wasp/campaigns/FETCH_ALL_GROUPS";
const FETCH_ALL_GROUPS_SUCCESS = "wasp/campaigns/FETCH_ALL_GROUPS_SUCCESS";

const SAVE_CAMPAIGN = "wasp/campaigns/SAVE_CAMPAIGN";
const SAVE_CAMPAIGN_SUCCESS = "wasp/campaigns/SAVE_CAMPAIGN_SUCCESS";

const SAVE_GROUP = "wasp/campaigns/SAVE_GROUP";
const SAVE_GROUP_SUCCESS = "wasp/campaigns/SAVE_GROUP_SUCCESS";

/* HELPERS
================================================================================================ */
export const createCampaignRequest = () => request.post("/api/v1/campaigns/");

export const createGroupRequest = () =>
  request.post("/api/v1/campaign_groups/");

export const getPriorCampaignIds = (data) => {
  return getItemsFromString(data.entry_prior_campaign_ids);
};

export const getGoalFields = () =>
  range(1, GOALS_PER_CAMPAIGN + 1).map((i) => `goal_${i}`);

/* INITIAL STATES
================================================================================================ */
const initialState = {
  campaigns: null,
  campaignCount: 0,
  totals: {},
  group: null,
  groups: null,
  groupCount: 0,
  groupTotals: {},
  campaign: null,
  allGroups: [],
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  let fieldsOfInterest;

  switch (action.type) {
    case FETCH_CAMPAIGNS_REQUEST:
      return {
        ...state,
        campaigns: null,
      };
    case FETCH_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaigns: action.payload.results,
        campaignCount: action.payload.count,
      };
    case FETCH_TOTALS_SUCCESS:
      return {
        ...state,
        totals: {
          ...action.payload,
          complete: action.payload.complete + action.payload.soft_complete,
        },
      };
    case FETCH_GROUP_REQUEST:
      return {
        ...state,
        group: null,
      };
    case SAVE_GROUP_SUCCESS:
    case FETCH_GROUP_SUCCESS:
      return {
        ...state,
        group: action.payload,
      };
    case FETCH_GROUPS_REQUEST:
      return {
        ...state,
        groups: null,
      };
    case FETCH_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.payload.results,
        groupCount: action.payload.count,
      };
    case FETCH_GROUP_TOTALS_SUCCESS:
      return {
        ...state,
        groupTotals: {
          ...action.payload,
          complete: action.payload.complete + action.payload.soft_complete,
        },
      };
    case LAUNCH_CAMPAIGN_SUCCESS:
    case PAUSE_CAMPAIGN_SUCCESS:
    case RESUME_CAMPAIGN_SUCCESS:
    case SOFT_COMPLETE_CAMPAIGN_SUCCESS:
    case COMPLETE_CAMPAIGN_SUCCESS:
      fieldsOfInterest = pick(
        action.payload,
        "id",
        "status",
        "last_action",
        "last_action_at",
        "last_action_by"
      );

      return {
        ...state,
        campaigns: state.campaigns
          ? state.campaigns.map((c) =>
              c.id === fieldsOfInterest.id ? { ...c, ...fieldsOfInterest } : c
            )
          : state.campaigns,
        campaign:
          idx(state, (_) => _.campaign.id) === fieldsOfInterest.id
            ? { ...state.campaign, ...fieldsOfInterest }
            : state.campaign,
      };
    case PAUSE_GROUP_SUCCESS:
    case RESUME_GROUP_SUCCESS:
    case SOFT_COMPLETE_GROUP_SUCCESS:
    case COMPLETE_GROUP_SUCCESS:
      fieldsOfInterest = pick(
        action.payload,
        "id",
        "status",
        "last_action",
        "last_action_at",
        "last_action_by",
        "live_campaign_count"
      );

      return {
        ...state,
        groups: state.groups
          ? state.groups.map((g) =>
              g.id === fieldsOfInterest.id ? { ...g, ...fieldsOfInterest } : g
            )
          : state.groups,
        group:
          idx(state, (_) => _.group.id) === fieldsOfInterest.id
            ? { ...state.group, ...fieldsOfInterest }
            : state.group,
      };
    case FETCH_CAMPAIGN_REQUEST:
      return {
        ...state,
        campaign: null,
      };
    case FETCH_CAMPAIGN_SUCCESS:
    case SAVE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaign: {
          ...action.payload,
          emails: fromEmailsToFormData(action.payload),
        },
      };
    case FETCH_ALL_GROUPS_SUCCESS:
      return {
        ...state,
        allGroups: action.payload,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const fetchCampaigns = (query, status, groupId) => (dispatch) => {
  let statusVal;
  if (status !== "all") {
    if (status === "complete") {
      statusVal = ["complete", "soft_complete"];
    } else if (status === "important") {
      statusVal = "live";
    } else {
      statusVal = status;
    }
  }

  const extraAPIQuery = {
    groups: groupId ? [groupId] : undefined,
    status: statusVal,
    important: status === "important" ? true : undefined,
  };
  const queryStr = getAPIQueryString(CAMPAIGNS_RESOURCE_NAME, query, {
    extraAPIQuery,
  });
  const promise = request.get(`/api/v1/campaigns/?${queryStr}`);

  /* save page count in pager state */
  promise.then((res) => dispatch(savePageCount(CAMPAIGNS_RESOURCE_NAME, res)));

  return dispatch({
    type: FETCH_CAMPAIGNS,
    promise,
  });
};

export const fetchTotals = (groupId) => (dispatch) => {
  const queryStr = queryString.stringify({ group_id: groupId });

  return dispatch({
    type: FETCH_TOTALS,
    promise: request.get(`/api/v1/campaigns/totals/?${queryStr}`),
  });
};

export const fetchGroup = (id) => (dispatch) => {
  return dispatch({
    type: FETCH_GROUP,
    promise: request.get(`/api/v1/campaign_groups/${id}/`),
  });
};

export const fetchGroups = (query, status) => (dispatch) => {
  let statusVal;
  if (status !== "all") {
    if (status === "complete") {
      statusVal = ["complete", "soft_complete"];
    } else {
      statusVal = status;
    }
  }

  const extraAPIQuery = { status: statusVal };
  const queryStr = getAPIQueryString(GROUPS_RESOURCE_NAME, query, {
    extraAPIQuery,
  });
  const promise = request.get(`/api/v1/campaign_groups/?${queryStr}`);

  /* save page count in pager state */
  promise.then((res) => dispatch(savePageCount(GROUPS_RESOURCE_NAME, res)));

  return dispatch({
    type: FETCH_GROUPS,
    promise,
  });
};

export const fetchGroupTotals = () => (dispatch) => {
  return dispatch({
    type: FETCH_GROUP_TOTALS,
    promise: request.get("/api/v1/campaign_groups/totals/"),
  });
};

export const cloneCampaign = (id) => (dispatch) => {
  const promise = request.post(`/api/v1/campaigns/${id}/clone/`);

  promise
    .then((res) => {
      dispatch(toggleActionConfirmation(null));
      browserHistory.push(`${ADMIN_ROOT}/wasp/campaign/${res.data.id}/`);
    })
    .catch((err) => {
      const errorMsg = err.data ? JSON.stringify(err.data) : "Unknown error.";
      dispatch(setBannerError("Error duplicating campaign", errorMsg));
    });

  return promise;
};

export const deleteCampaign = (id) => (dispatch) => {
  const promise = request.delete(`/api/v1/campaigns/${id}/`);

  promise
    .then(() => {
      browserHistory.push(`${ADMIN_ROOT}/wasp/campaigns/all/`);
      dispatch(toggleActionConfirmation(null));
      dispatch(fetchTotals());
    })
    .catch((err) => {
      const errorMsg = err.data ? JSON.stringify(err.data) : "Unknown error.";
      dispatch(setBannerError("Error deleting campaign", errorMsg));
    });

  return promise;
};

const performCampaignAction = (id, actionName, actionType, dispatch) => {
  const promise = request.patch(`/api/v1/campaigns/${id}/${actionName}/`);

  promise
    .then(() => {
      dispatch(toggleActionConfirmation(null));
      dispatch(fetchTotals());
    })
    .catch((err) => {
      const errorMsg = err.data ? JSON.stringify(err.data) : "Unknown error.";
      dispatch(
        setBannerError(
          `Error performing the following action: ${actionName}`,
          errorMsg
        )
      );
    });

  dispatch({ type: actionType, promise });

  return promise;
};

export const launchCampaign = (id) => (dispatch) =>
  performCampaignAction(id, "launch", LAUNCH_CAMPAIGN, dispatch);

export const pauseCampaign = (id) => (dispatch) =>
  performCampaignAction(id, "pause", PAUSE_CAMPAIGN, dispatch);

export const resumeCampaign = (id) => (dispatch) =>
  performCampaignAction(id, "resume", RESUME_CAMPAIGN, dispatch);

export const softCompleteCampaign = (id) => (dispatch) =>
  performCampaignAction(id, "soft_complete", SOFT_COMPLETE_CAMPAIGN, dispatch);

export const completeCampaign = (id) => (dispatch) =>
  performCampaignAction(id, "complete", COMPLETE_CAMPAIGN, dispatch);

export const cloneGroup = (id) => (dispatch) => {
  const promise = request.post(`/api/v1/campaign_groups/${id}/clone/`);

  promise
    .then((res) => {
      dispatch(toggleActionConfirmation(null));
      browserHistory.push(`${ADMIN_ROOT}/wasp/group/${res.data.id}/`);
    })
    .catch((err) => {
      const errorMsg = err.data ? JSON.stringify(err.data) : "Unknown error.";
      dispatch(setBannerError("Error duplicating group", errorMsg));
    });

  return promise;
};

export const deleteGroup = (id) => (dispatch) => {
  const promise = request.delete(`/api/v1/campaign_groups/${id}/`);

  promise
    .then(() => {
      browserHistory.push(`${ADMIN_ROOT}/wasp/groups/all/`);
      dispatch(toggleActionConfirmation(null));
      dispatch(fetchGroupTotals());
    })
    .catch((err) => {
      const errorMsg = err.data ? JSON.stringify(err.data) : "Unknown error.";
      dispatch(setBannerError("Error deleting group", errorMsg));
    });

  return promise;
};

const performGroupAction = (id, actionName, actionType, dispatch) => {
  const promise = request.patch(`/api/v1/campaign_groups/${id}/${actionName}/`);

  promise
    .then(() => {
      dispatch(toggleActionConfirmation(null));
      dispatch(fetchGroupTotals());
    })
    .catch((err) => {
      const errorMsg = err.data ? JSON.stringify(err.data) : "Unknown error.";
      dispatch(
        setBannerError(
          `Error performing the following action: ${actionName}`,
          errorMsg
        )
      );
    });

  dispatch({ type: actionType, promise });

  return promise;
};

export const pauseGroup = (id) => (dispatch) =>
  performGroupAction(id, "pause", PAUSE_GROUP, dispatch);

export const resumeGroup = (id) => (dispatch) =>
  performGroupAction(id, "resume", RESUME_GROUP, dispatch);

export const softCompleteGroup = (id) => (dispatch) =>
  performGroupAction(id, "soft_complete", SOFT_COMPLETE_GROUP, dispatch);

export const completeGroup = (id) => (dispatch) =>
  performGroupAction(id, "complete", COMPLETE_GROUP, dispatch);

export const fetchCampaign = (id) => (dispatch) => {
  const promise = request.get(`/api/v1/campaigns/${id}/`);

  promise.catch((err) => {
    if (err.status === 404) {
      browserHistory.replace("/404/");
    }
  });

  return dispatch({
    type: FETCH_CAMPAIGN,
    promise,
  });
};

export const fetchAllGroups = () => (dispatch) => {
  return dispatch({
    type: FETCH_ALL_GROUPS,
    promise: request.get("/api/v1/campaign_groups/all/"),
  });
};

export const saveCampaign = (id, formData) => (dispatch, getState) => {
  const { campaign } = getState().wasp.campaigns;
  const goalFields = getGoalFields();

  let data = {
    ...pick(formData, [
      ...goalFields,
      "name",
      "assignee",
      "entry_domain_list",
      "unsubscribe_strategy",
      "important",
      "outgoing_domain_list",
      "streak_name",
      "hours_between_prospects",
      "blocked_reasons",
      "entry_prior_campaign_waiting_days",
      "blocked_hive_anchor_domain",
    ]),
    groups: uniq(
      range(1, GROUPS_PER_CAMPAIGN + 1)
        .map((i) => formData[`group_${i}`])
        .filter((g) => !!g)
    ),
    entry_prior_campaign_ids: getPriorCampaignIds(formData),
    extra_link_attributes: pick(
      formData,
      "utm_source",
      "utm_medium",
      "utm_campaign"
    ),
    emails: isEqual(campaign.emails, formData.emails)
      ? undefined
      : fromFormDataToEmails(formData),
  };

  if (campaign.status !== "draft") {
    data = pick(
      data,
      "assignee",
      "groups",
      "name",
      "unsubscribe_strategy",
      "extra_link_attributes",
      "important",
      "outgoing_domain_list",
      "emails",
      "hours_between_prospects",
      "blocked_reasons",
      "blocked_hive_anchor_domain"
    );
  }

  const promise = request.patch(`/api/v1/campaigns/${id}/`, data);

  promise
    .then(() => {
      dispatch(
        showBanner(
          "Success",
          "Campaign successfully saved.",
          undefined,
          TYPE_SUCCESS,
          null,
          3000
        )
      );
    })
    .catch((err) => {
      const errorMsg = err.data
        ? JSON.stringify(err.data)
        : "Make sure the data you entered is correct.";
      dispatch(setBannerError("Error saving campaign", errorMsg));
    });

  dispatch({
    type: SAVE_CAMPAIGN,
    promise,
  });

  return promise;
};

export const saveGroup = (id, formData) => (dispatch) => {
  const promise = request.patch(`/api/v1/campaign_groups/${id}/`, formData);

  promise
    .then(() => {
      dispatch(
        showBanner(
          "Success",
          "Group successfully saved.",
          undefined,
          TYPE_SUCCESS,
          null,
          3000
        )
      );
    })
    .catch((err) => {
      const errorMsg = err.data
        ? JSON.stringify(err.data)
        : "Make sure the data you entered is correct.";
      dispatch(setBannerError("Error saving group", errorMsg));
    });

  dispatch({
    type: SAVE_GROUP,
    promise,
  });

  return promise;
};
