import Notifications from "header/components/notifications";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchNotifications, toggleDrawer } from "notifications/ducks";
import { withRouter } from "react-router";

export function mapStateToProps({ user, notifications }, { location }) {
  const userId = user.logged_in ? user.profile_data.id : null;
  const onNotificationsPage = location.pathname.match(/^\/notifications\//);
  const shouldShow = user.logged_in && !onNotificationsPage;
  const results = user.logged_in ? notifications.data.results : [];
  const unseen = results ? results.filter((n) => !n.seen).length : 0;

  return {
    userId,
    shouldShow,
    unseen,
  };
}

export function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchNotifications, toggleDrawer }, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Notifications)
);
