import request from "axios";
import { getAPIQueryString } from "common/utils";
import { savePageCount } from "common/pager/ducks";
import { CAMPAIGN_EXCLUDED_DOMAINS_RESOURCE_NAME } from "wasp/constants";

/* ACTIONS
================================================================================================ */
const FETCH = "wasp/campaign_excluded_domains/FETCH";
const FETCH_REQUEST = "wasp/campaign_excluded_domains/FETCH_REQUEST";
const FETCH_SUCCESS = "wasp/campaign_excluded_domains/FETCH_SUCCESS";

/* HELPERS
================================================================================================ */

/* INITIAL STATES
================================================================================================ */
const initialState = {
  items: null,
  itemCount: 0,
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_REQUEST:
      return {
        ...state,
        items: null,
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        items: action.payload.results,
        itemCount: action.payload.count,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const fetch = (campaignId, query) => (dispatch) => {
  const queryStr = getAPIQueryString(
    CAMPAIGN_EXCLUDED_DOMAINS_RESOURCE_NAME,
    query
  );
  const promise = request.get(
    `/api/v1/campaigns/${campaignId}/excluded_domains/?${queryStr}`
  );

  /* save page count in pager state */
  promise.then((res) =>
    dispatch(savePageCount(CAMPAIGN_EXCLUDED_DOMAINS_RESOURCE_NAME, res))
  );

  return dispatch({
    type: FETCH,
    promise,
  });
};
