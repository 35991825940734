import { connect } from "react-redux";
import { toggleEliteConfirmation } from "pap/ducks/workflow";
import EliteConfirmationModal from "pap/components/common/elite_confirmation_modal";

const mapStateToProps = ({ pap }) => {
  return {
    showing: pap.workflow.showingEliteConfirmation,
    onConfirm: pap.workflow.onEliteConfirm,
  };
};

export default connect(mapStateToProps, {
  onClose: toggleEliteConfirmation,
})(EliteConfirmationModal);
