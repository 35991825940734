import React from "react";
import ICAContractPreviewModal from "pap/containers/contract_preview_modal";
import NDAPreviewModal from "pap/containers/nda_preview_modal";
import EliteConfirmationModal from "pap/containers/elite_confirmation_modal";
import SelectPackageSubcatModal from "pap/containers/select_package_subcat_modal";
import FinalRoundModal from "entries/containers/awards/final_round_confirmation";
import FinalRoundExtensionModal from "entries/containers/awards/final_round_extension";
import SelectLogoSubcatModal from "pap/containers/common/select_logo_subcat_modal";

/**
 * Component composed of "global" modals, i.e. those that can be triggered from more than
 * one place in the app or that need to be placed higher in the DOM hierarchy.
 *
 * @author Nathan Ozelim <nathan@startupfoundry.com>
 */
const GlobalModals = () => (
  <div className="global-modals">
    <ICAContractPreviewModal />
    <EliteConfirmationModal />
    <NDAPreviewModal />
    <SelectPackageSubcatModal />
    <FinalRoundModal />
    <FinalRoundExtensionModal />
    <SelectLogoSubcatModal />
  </div>
);

export default GlobalModals;
