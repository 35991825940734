import { connect } from "react-redux";
import { fetch, dismiss } from "announcements/ducks";
import Announcements from "announcements/components";

const mapStateToProps = ({ announcements: { items } }) => {
  return {
    items,
  };
};

export default connect(mapStateToProps, {
  fetch,
  onDismiss: dismiss,
})(Announcements);
