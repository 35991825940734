import { connect } from "react-redux";
import { toggleContractPreview } from "pap/ducks/workflow";
import ContractPreviewModal from "common/components/modal/contract_preview";

const mapStateToProps = ({ pap }) => {
  return {
    showing: pap.workflow.showingContractPreview,
    url: "/api/v1/projects/ica_sample/",
    header: "Sample Contract",
  };
};

export default connect(mapStateToProps, {
  onClose: toggleContractPreview,
})(ContractPreviewModal);
