import request from "axios";
import idx from "idx";
import { fetchProject, getProject } from "project/ducks/project";
import { setBannerError } from "error";

/* ACTIONS
 ================================================================================================ */
export const FETCH_NDAS = "crowdspring/ndas/FETCH_NDAS";
export const FETCH_NDAS_SUCCESS = "crowdspring/ndas/FETCH_NDAS_SUCCESS";

export const FETCH_CONTRACT = "crowdspring/ndas/FETCH_CONTRACT";
export const FETCH_CONTRACT_SUCCESS = "crowdspring/ndas/FETCH_CONTRACT_SUCCESS";

export const CREATE_NDA = "crowdspring/ndas/CREATE_NDA";

export const UPDATE_NDA = "crowdspring/ndas/UPDATE_NDA";

export const TOGGLE_SIGN_MODAL = "crowdspring/ndas/TOGGLE_SIGN_MODAL";

export const CLEAR_CONTRACT = "crowdspring/ndas/CLEAR_CONTRACT";

/* INITIAL STATE
 ================================================================================================ */
const initialState = {
  ndaContract: null,
  showingSignModal: false,
  data: [],
};

/* HELPERS
 ================================================================================================ */

/* REDUCERS
 ================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CONTRACT_SUCCESS:
      return {
        ...state,
        ndaContract: action.payload,
      };
    case CLEAR_CONTRACT:
      return {
        ...state,
        ndaContract: null,
      };
    case TOGGLE_SIGN_MODAL:
      return {
        ...state,
        showingSignModal: !state.showingSignModal,
      };
    case FETCH_NDAS_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const fetchNDAs = (projectId) => (dispatch) => {
  const opts = {
    method: "get",
    url: `/api/v1/projects/${projectId}/ndas/`,
  };
  return dispatch({
    type: FETCH_NDAS,
    promise: request(opts),
  });
};

export const fetchContract = (projectId) => (dispatch) => {
  const opts = {
    method: "get",
    url: `/api/v1/projects/${projectId}/creative_nda_contract/`,
  };

  return dispatch({
    type: FETCH_CONTRACT,
    promise: request(opts),
  });
};

export const createNDA = (projectId, contractId) => (dispatch) => {
  const opts = {
    method: "post",
    url: `/api/v1/projects/${projectId}/ndas/`,
    data: { nda_accepted: true, contract_id: contractId },
  };

  const promise = request(opts);

  promise
    .then(() =>
      /* reload project with updated NDA request status */
      dispatch(fetchProject(projectId))
    )
    .catch(() =>
      dispatch(setBannerError("Failed to request access", "Please try again"))
    );

  return dispatch({
    type: CREATE_NDA,
    promise,
  });
};

export const updateNDA = (projectId, ndaId, data) => (dispatch) => {
  const opts = {
    method: "patch",
    url: `/api/v1/projects/${projectId}/ndas/${ndaId}/`,
    data,
  };

  const promise = request(opts);

  promise
    .then(() =>
      /* get updated NDAs list */
      dispatch(fetchNDAs(projectId))
    )
    .catch(() =>
      dispatch(setBannerError("Failed to update NDA", "Please try again"))
    );

  return {
    type: UPDATE_NDA,
    promise,
  };
};

export const toggleSignModal = () => (dispatch) =>
  dispatch({
    type: TOGGLE_SIGN_MODAL,
  });

export const requestAccess = () => (dispatch, getState) => {
  const { ndaContract } = getState().project.ndas;
  const project = getProject(getState());

  /* if contract was already signed, go straight to NDA creation */
  if (idx(ndaContract, (_) => _.signed_at)) {
    dispatch(createNDA(project.id, ndaContract.id));
  } else {
    dispatch(toggleSignModal());
  }
};

export const clearContract = () => (dispatch) =>
  dispatch({
    type: CLEAR_CONTRACT,
  });
