import idx from "idx";

/* Get query parameter name related to given resource */
const getSortParamName = (resourceName) => `${resourceName}_sort`;

/* Get the value for a given sorter from the query dict */
export const getValueFromQuery = (resourceName, query) => {
  return idx(query, (_) => _[getSortParamName(resourceName)]);
};

/* Get the query dict given the sorter value */
export const getQueryFromValue = (resourceName, value) => {
  return {
    [getSortParamName(resourceName)]: value || undefined,
  };
};

/* Get the API query dict for a given sorter based on the (URL) query dict */
export const getAPIQuery = (resourceName, paramName, query) => {
  const value = getValueFromQuery(resourceName, query);
  return {
    [paramName]: value,
  };
};
