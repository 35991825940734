import { connect } from "react-redux";
import { withRouter } from "react-router";
import { saveLastQuery } from "common/sorter/ducks";
import { getValueFromQuery } from "common/sorter/utils";
import Sorter from "common/sorter/components";

export const mapStateToProps = (state, { resourceName, location }) => {
  return {
    value: getValueFromQuery(resourceName, location.query),
  };
};

export default withRouter(connect(mapStateToProps, { saveLastQuery })(Sorter));
