/**
import PropTypes from 'prop-types';
 * Common variables for project page reducers
 * @author Kuba Siemiątkowski <kuba@crowdspring.com>
 */

/* PROJECT ACTIONS
================================================================================================ */
export const FETCH = "crowdspring/project/FETCH";
export const FETCH_REQUEST = "crowdspring/project/FETCH_REQUEST";
export const FETCH_SUCCESS = "crowdspring/project/FETCH_SUCCESS";
export const FETCH_FAILURE = "crowdspring/project/FETCH_FAILURE";
export const FETCH_STATS = "crowdspring/project/FETCH_STATS";
export const FETCH_STATS_SUCCESS = "crowdspring/project/FETCH_STATS_SUCCESS";
export const FETCH_NEW_PROJECTS = "crowdspring/project/FETCH_NEW_PROJECTS";
export const FETCH_NEW_PROJECTS_SUCCESS =
  "crowdspring/project/FETCH_NEW_PROJECTS_SUCCESS";
export const TOGGLE_VIOLATION = "crowdspring/project/TOGGLE_VIOLATION";
export const TOGGLE_STATS = "crowdspring/project/TOGGLE_STATS";

/* ENTRY ACTIONS
================================================================================================ */
export const ENTRY_ZOOM_IN = "crowdspring/project/ENTRY_ZOOM_IN";
export const ENTRY_ZOOM_OUT = "crowdspring/project/ENTRY_ZOOM_OUT";
export const ENTRY_RESET_ZOOM = "crowdspring/project/ENTRY_RESET_ZOOM";

export const TOGGLE_ENTRY_DELETE = "crowdspring/project/TOGGLE_ENTRY_DELETE";
export const TOGGLE_ENTRY_REINSTATE =
  "crowdspring/project/TOGGLE_ENTRY_REINSTATE";

/* REVISION ACTIONS
================================================================================================ */
export const SCORE_REVISION = "crowdspring/project/SCORE_REVISION";
export const SCORE_REVISION_SUCCESS =
  "crowdspring/project/SCORE_REVISION_SUCCESS";
export const SCORE_REVISION_FAILURE =
  "crowdspring/project/SCORE_REVISION_FAILURE";
export const FETCH_COMMENTS = "crowdspring/project/FETCH_COMMENTS";
export const FETCH_COMMENTS_SUCCESS =
  "crowdspring/project/FETCH_COMMENTS_SUCCESS";
export const FETCH_COMMENTS_FAILURE =
  "crowdspring/project/FETCH_COMMENTS_FAILURE";
export const ADD_COMMENT = "crowdspring/project/ADD_COMMENT";
export const ADD_COMMENT_SUCCESS = "crowdspring/project/ADD_COMMENT_SUCCESS";
export const ADD_COMMENT_FAILURE = "crowdspring/project/ADD_COMMENT_FAILURE";
export const ADD_COMMENT_REPLY = "crowdspring/project/ADD_COMMENT_REPLY";
export const ADD_COMMENT_REPLY_SUCCESS =
  "crowdspring/project/ADD_COMMENT_REPLY_SUCCESS";
export const ADD_COMMENT_REPLY_FAILURE =
  "crowdspring/project/ADD_COMMENT_REPLY_FAILURE";
export const REVISION_FEEDBACK = "crowdspring/project/REVISION_FEEDBACK";
export const REVISION_FEEDBACK_SUCCESS =
  "crowdspring/project/REVISION_FEEDBACK_SUCCESS";
export const REVISION_FEEDBACK_FAILURE =
  "crowdspring/project/REVISION_FEEDBACK_FAILURE";
