import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router";
import Tooltip from "react-tooltip";
import { isMobile } from "common/utils";
import { isProjectTagline, canProjectHaveFinalRound } from "project/utils";
import { DAYS_TO_AWARD } from "project/constants";
import DeadlineLabel from "project/components/deadline_label";

export default class HeaderDeadline extends Component {
  getDeadline() {
    const { project } = this.props;

    if (!project.deadline) {
      return "";
    }

    if (project.status === "closed") {
      /* deadline to award or start Finalist Round */
      return moment(project.deadline).add(DAYS_TO_AWARD, "days").format();
    }

    if (project.status === "finalist_round") {
      /* deadline to submit entries during Finalist Round */
      return project.finalist_round_deadline;
    }

    if (project.status === "finalist_closed") {
      /* deadline to award after Finalist Round ended */
      return moment(project.finalist_round_deadline)
        .add(DAYS_TO_AWARD, "days")
        .format();
    }

    /* deadline to submit entries */
    return project.deadline;
  }

  renderTooltipContent() {
    const { project, user, canClose } = this.props;
    const isOwner = project.client === user.id;
    const deadline = this.getDeadline();
    const deadlineStr = deadline
      ? moment(deadline).format("MMMM D, YYYY h:mm a")
      : "";
    const canHaveFinalRound = canProjectHaveFinalRound(project);

    if (project.status === "closed") {
      return (
        <span>
          {isOwner ? "You" : project.client_username} must pick a winner
          {canHaveFinalRound && " or start a Finalist Round"} by {deadlineStr}.
        </span>
      );
    }

    if (project.status === "finalist_round") {
      return (
        <span>The Finalist Round for this project ends on {deadlineStr}.</span>
      );
    }

    if (project.status === "finalist_closed") {
      return (
        <span>
          {isOwner ? "You" : project.client_username} must pick a winner by{" "}
          {deadlineStr}.
        </span>
      );
    }

    return (
      <span>
        <p>This project is open for submissions until {deadlineStr}.</p>
        {isOwner && (
          <span>
            Already found a{" "}
            {project.entry_type === "image"
              ? "design"
              : isProjectTagline(project)
              ? "tagline"
              : "name"}{" "}
            you love and want to close your project early to declare a winner?{" "}
            {canClose ? (
              "Just click the button below."
            ) : (
              <span>
                Just{" "}
                <Link to="/help/contact/" target="_blank">
                  contact our support team
                </Link>{" "}
                and we'll be happy to help.
              </span>
            )}
          </span>
        )}
      </span>
    );
  }

  render() {
    const { project } = this.props;

    if (
      !["open", "closed", "finalist_round", "finalist_closed"].includes(
        project.status
      )
    ) {
      return null;
    }

    const tooltipContent = this.renderTooltipContent();

    return (
      <span className="project-duration">
        <DeadlineLabel deadline={this.getDeadline()} />

        {!!tooltipContent && (
          <span>
            <i
              className="fa fa-info-circle"
              data-tip={true}
              data-for="tooltip-deadline"
              data-event={isMobile() ? "click" : undefined}
            />
          </span>
        )}

        {!!tooltipContent && (
          <Tooltip
            class="tooltip"
            effect="solid"
            place="left"
            id="tooltip-deadline"
            delayHide={500}
          >
            {tooltipContent}
          </Tooltip>
        )}
      </span>
    );
  }
}

HeaderDeadline.propTypes = {
  project: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  canClose: PropTypes.bool.isRequired,
};
