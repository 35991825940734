import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toggleModal } from "common/modal/ducks";
import Trigger from "common/modal/components/trigger";

export function mapStateToProps() {
  return {};
}

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onClick: toggleModal,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Trigger);
