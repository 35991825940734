import ActionSelect from "notifications/components/action_select";
import { notificationAction } from "notifications/ducks";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

export function mapStateToProps({ user, notifications }) {
  const userId = user.logged_in ? user.profile_data.id : null;
  const selectedNotifications = user.logged_in
    ? notifications.selectedNotifications
    : [];

  return { userId, selectedNotifications };
}

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onChange: notificationAction,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionSelect);
