import { connect } from "react-redux";
import HeaderTitle from "header/components/title";

export const TITLE = "Verify your ID";

const mapStateToProps = () => {
  return {
    title: TITLE,
  };
};

export default connect(mapStateToProps)(HeaderTitle);
