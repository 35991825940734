import idx from "idx";
import request from "axios";
import reduce from "lodash/reduce";
import { PAGINATION_PAGE_SIZE } from "common/variables";
import { getPaginationValues } from "common/utils";

/* ACTIONS
================================================================================================ */
export const FETCH = "crowdspring/admin/FETCH_PAYMENTS";
export const FETCH_REQUEST = "crowdspring/admin/FETCH_PAYMENTS_REQUEST";
export const FETCH_SUCCESS = "crowdspring/admin/FETCH_PAYMENTS_SUCCESS";
export const FETCH_FAILURE = "crowdspring/admin/FETCH_PAYMENTS_FAILURE";

export const FETCH_SINGLE = "crowdspring/admin/FETCH_SINGLE_PAYMENT";
export const FETCH_SINGLE_REQUEST =
  "crowdspring/admin/FETCH_SINGLE_PAYMENT_REQUEST";
export const FETCH_SINGLE_SUCCESS =
  "crowdspring/admin/FETCH_SINGLE_PAYMENT_SUCCESS";
export const FETCH_SINGLE_FAILURE =
  "crowdspring/admin/FETCH_SINGLE_PAYMENT_FAILURE";

export const SET_FILTERS = "crowdspring/admin/SET_PAYMENT_FILTERS";

/* INITIAL STATES
================================================================================================ */
export const initialState = {
  isFetching: false,
  isError: false,
  list: [],
  currentPayment: null,
  tokens: {},
  pageCount: PAGINATION_PAGE_SIZE,
  currentPage: 1,
  paymentCount: 0,
  filters: "",
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_REQUEST:
    case FETCH_SINGLE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_SUCCESS:
      const data = action.payload;
      const { currentPage, pageCount } = getPaginationValues(data);
      return {
        ...state,
        currentPage,
        pageCount,
        paymentCount: data.count,
        isFetching: false,
        list: data.results,
      };
    case FETCH_SINGLE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        currentPayment: action.payload,
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    default:
      return state;
  }
}

export const getFiltersFromState = ({ admin }) =>
  idx(admin, (_) => _.payments.filters);

export const fetchPayments =
  (offset = 0, filterStr = "") =>
  (dispatch, getState) => {
    const filter = getFiltersFromState(getState()) || filterStr;
    const url = `/api/v1/payments/?limit=${PAGINATION_PAGE_SIZE}&offset=${offset}${filter}`;

    return dispatch({
      type: FETCH,
      promise: request.get(url),
    });
  };

export const fetchPaymentDetails = (id) => (dispatch) =>
  dispatch({
    type: FETCH_SINGLE,
    promise: request.get(`/api/v1/payments/${id}/`),
  });

export const changedFilters = (filters) => (dispatch) => {
  const filterStr = reduce(
    filters,
    (mem, filter, filterKey) => {
      const addFilter =
        filterKey.indexOf("=") === -1 ? `${filterKey}=${filter}` : filterKey;
      return filter ? `${mem}&${addFilter}` : mem;
    },
    ""
  );

  dispatch({ type: SET_FILTERS, payload: filterStr });
  return dispatch(fetchPayments(0, `${filterStr}`));
};
