import React from "react";
import PropTypes from "prop-types";
import range from "lodash/range";
import classnames from "classnames";
import Avatar from "common/components/avatar";
import { MAX_ROUND_FINALISTS } from "project/constants";

export const AVATAR_CLASSES = (small) =>
  classnames("avatar-round avatar-shadow avatar-inline", {
    "avatar-md": !small,
    "avatar-sm": small,
  });

const Finalists = ({ project, hidePlaceholders, small }) => {
  if (!project) {
    return null;
  }

  const emptySlots = Math.max(
    MAX_ROUND_FINALISTS - project.finalists.length,
    0
  );

  return (
    <div className="project-finalists">
      {project.finalists.map((f) => (
        <Avatar key={f.id} user={f} extraClasses={AVATAR_CLASSES(small)} />
      ))}

      {!hidePlaceholders &&
        range(emptySlots).map((i) => (
          <Avatar key={i} extraClasses={AVATAR_CLASSES(small)} />
        ))}
    </div>
  );
};

Finalists.propTypes = {
  project: PropTypes.object,
  hidePlaceholders: PropTypes.bool,
  small: PropTypes.bool,
};

export default Finalists;
