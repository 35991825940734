import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import categories from "help/ducks/categories";
import article from "help/ducks/article";
import search from "help/ducks/search";
import guides from "help/ducks/guides";
import faqs from "help/ducks/faqs";
import contact from "help/ducks/contact";

const RootReducer = combineReducers({
  categories,
  article,
  search,
  guides,
  faqs,
  contact,
  form: formReducer,
});

export default RootReducer;
