import moment from "moment";
import isArray from "lodash/isArray";
import isEmail from "sane-email-validation";

export const DATETIME_FORMAT = "MM/DD/YYYY HH:mm";

export const DATE_FORMAT = "MM/DD/YYYY";

export const validateRequired = (v) => {
  const errorMsg = "Please enter a value.";

  if (typeof v === "string") {
    return v.trim().length === 0 ? errorMsg : undefined;
  }

  if (typeof v === "number") {
    return undefined;
  }

  if (isArray(v)) {
    return v.length === 0 ? errorMsg : undefined;
  }

  return !v ? errorMsg : undefined;
};

export const validateDatetime = (v) => {
  /* ignore if `v` is empty */
  if (validateRequired(v)) {
    return;
  }

  const obj = moment(v, DATETIME_FORMAT);

  if (!obj.isValid()) {
    return "Please enter a valid date and time.";
  }
};

export const validateDate = (v) => {
  /* ignore if `v` is empty */
  if (validateRequired(v)) {
    return;
  }

  const obj = moment(v, DATE_FORMAT);

  if (!obj.isValid()) {
    return "Please enter a valid date.";
  }
};

export const validateEmail = (v) => {
  /* ignore if `v` is empty */
  if (validateRequired(v)) {
    return;
  }

  if (!isEmail(v)) {
    return "Please enter a valid email.";
  }
};

export const validateJSONString = (v) => {
  /* ignore if `v` is empty */
  if (validateRequired(v)) {
    return;
  }

  try {
    JSON.parse(v);
  } catch (SyntaxError) {
    return "Please enter a valid JSON string.";
  }
};

export const validateURL = (v, allValues, props, protocolOptional = false) => {
  /* ignore if `v` is empty */
  if (validateRequired(v)) {
    return;
  }

  /* https://stackoverflow.com/a/5717133/14584537 */
  const pattern = new RegExp(
    "^(https?:\\/\\/)" +
      (protocolOptional ? "?" : "") + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator

  return pattern.test(v) ? undefined : "Please enter a valid URL.";
};
