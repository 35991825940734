import { connect } from "react-redux";
import { getProject } from "project/ducks/project";
import { atAdmin } from "common/utils";
import Finalists from "entries/components/finalists";

const mapStateToProps = (state) => {
  return {
    project: atAdmin(state)
      ? state.admin.projects.currentProject
      : getProject(state),
  };
};

export default connect(mapStateToProps)(Finalists);
