import url from "url";
import idx from "idx";
import queryString from "query-string";
import cloneDeep from "lodash/cloneDeep";
import uniq from "lodash/uniq";
import moment from "moment";
import { scroller } from "react-scroll";
import {
  PAGINATION_PAGE_SIZE,
  ADMIN_ROOT,
  MOBILE_BREAKPOINT_PX,
} from "common/variables";
import { getAPIQuery as getSortAPIQuery } from "common/sorter/utils";
import { getAPIQuery as getSearchAPIQuery } from "common/searcher/utils";
import { getAPIQuery as getFilterAPIQuery } from "common/filter/utils";
import { getAPIQuery as getPagerAPIQuery } from "common/pager/utils";
import { DEFAULT_PAGINATION_PAGE_SIZE } from "common/pager/constants";
import { applyClientQuery as applyFilterClientQuery } from "common/filter/utils";
import { applyClientQuery as applySearchClientQuery } from "common/searcher/utils";

/**
 * Different util functions reused accross the app.
 *
 * @author Kuba Siemiątkowski <kuba@crowdspring.com>
 * @author Nathan Ozelim <nathan@startupfoundry.com>
 */

function parsePageFromUrl(urlStr) {
  const { query } = url.parse(urlStr);
  const page = idx(queryString.parse(query), (_) => _.page) * 1 || 1;
  return page;
}

/**
 * This function calculates the current page number and total page numbers using
 * data sent by the API.
 *
 * @param {Object} paginationData - object with next/prev offsets and other data needed
 * @returns {Object} object with two keys - `currentPage` and `pageCount`
 */
function getPaginationValues(paginationData) {
  let currentPage;
  let pageCount;

  if (paginationData.previous === null) {
    currentPage = 1;
  } else if (paginationData.limit === 1) {
    currentPage = paginationData.next
      ? paginationData.next - 1
      : paginationData.previous - 1;
  } else {
    currentPage = paginationData.next
      ? paginationData.next / paginationData.limit
      : (paginationData.previous + paginationData.limit) /
          paginationData.limit +
        1;
  }

  if (paginationData.count <= paginationData.limit) {
    pageCount = 1;
  } else {
    pageCount = Math.ceil(paginationData.count / paginationData.limit);
  }

  return {
    currentPage,
    pageCount,
  };
}

/* Return whether app is running on a mobile browser. */
const isMobile = () => {
  if (typeof navigator === "undefined" || !navigator.userAgent) {
    return false;
  }

  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
};

export const isSafari = () => {
  return typeof window !== "undefined" && !!window.safari;
};

const calculatePageOffset = (page, pageSize = PAGINATION_PAGE_SIZE) =>
  (page - 1) * pageSize;

/* Share link on the given social network. */
const shareLink = (link, socialNetwork) => {
  let fullLink = "";
  if (socialNetwork == "facebook") {
    fullLink = `https://www.facebook.com/sharer/sharer.php?u=${link}`;
  } else if (socialNetwork == "twitter") {
    fullLink = `https://twitter.com/intent/tweet?text=${link}`;
  } else if (socialNetwork == "linkedin") {
    fullLink = `https://www.linkedin.com/shareArticle?mini=true&url=${link}`;
  }
  window.open(fullLink, "_blank");
};

/* Get time left label given a date/time string. */
const getTimeLeftLabel = (str, opts = {}) => {
  const defaultOpts = {
    emptyLabel: "-",
    negativeLabel: "Overdue",
    append: "",
    forceMax: true,
    daysUnit: "d",
    hoursUnit: "h",
    minutesUnit: "m",
  };
  const mergedOpts = { ...defaultOpts, ...opts };

  /* no time string provided */
  if (!str) {
    return mergedOpts.emptyLabel;
  }

  const hoursLeft = moment(str).diff(moment(), "hours");
  const daysLeft = Math.round(hoursLeft / 24);
  const minutesLeft = moment(str).diff(moment(), "minutes");

  /* time left < 0 */
  if (moment().isAfter(str)) {
    return mergedOpts.negativeLabel;
  }

  let label = "";

  /* time left > 6 days */
  if (hoursLeft > 6 * 24 && mergedOpts.forceMax) {
    label = `7${mergedOpts.daysUnit}`;

    /* 2 days <= time left <= 6 days */
  } else if (hoursLeft >= 2 * 24) {
    label = `${daysLeft}${mergedOpts.daysUnit}`;

    /* 1 hour <= time left < 2 days */
  } else if (hoursLeft >= 1) {
    label = `${hoursLeft}${mergedOpts.hoursUnit}`;

    /* time left < 1 hour */
  } else {
    label = `${minutesLeft}${mergedOpts.minutesUnit}`;
  }

  return label + (mergedOpts.append ? ` ${mergedOpts.append}` : "");
};

/**
 * This function returns a canonical URL consisting of the domain name followed by the provided
 * urlSuffix. The purpose of this function is to avoid hardcoding the domain/protocol all over the
 * place.
 *
 * If the leading or trailing slash is missing from the `urlSuffix` it is added, so this function
 * also performs a little bit of cleanup to ensure consistent canonical URLs.
 *
 * @param {String} urlSuffix - the part that should come after the domain name construct the URL
 * @returns {String} - a URL that can be used as the value of the `href` attribute in the canonical
 * link META tag
 */
const getCanonicalURL = (urlSuffix) => {
  if (urlSuffix) {
    if (!urlSuffix.startsWith("/")) {
      urlSuffix = `/${urlSuffix}`;
    }
    if (!urlSuffix.endsWith("/")) {
      urlSuffix = `${urlSuffix}/`;
    }
    return `https://www.crowdspring.com${urlSuffix}`;
  } else {
    return `https://www.crowdspring.com`;
  }
};

/* Return whether a given URL is active, by comparing it with the current router pathname. */
const isUrlActive = (url, pathname, matchExact = false, regex = null) => {
  const urlWithSlash = !url.endsWith("/") ? `${url}/` : url;
  const pathWithSlash = !pathname.endsWith("/") ? `${pathname}/` : pathname;

  if (regex) {
    return !!pathname.match(regex);
  }

  if (matchExact) {
    return pathWithSlash === urlWithSlash;
  }

  return pathWithSlash.indexOf(urlWithSlash) !== -1;
};

/* Get API query dict from sorting/search/filter/etc URL query parameters. */
const getAPIQuery = (resourceName, query = {}, opts = {}) => {
  const {
    sortParamName = "sort",
    searchParamName = "search",
    pageSize = DEFAULT_PAGINATION_PAGE_SIZE,
    extraAPIQuery = {},
    excludePager = false,
    excludeSorting = false,
  } = opts;
  const sortingAPIQuery = getSortAPIQuery(resourceName, sortParamName, query);
  const searchAPIQuery = getSearchAPIQuery(
    resourceName,
    searchParamName,
    query
  );
  const filterAPIQuery = getFilterAPIQuery(resourceName, query);
  const pagerAPIQuery = getPagerAPIQuery(resourceName, pageSize, query);

  return {
    ...searchAPIQuery,
    ...filterAPIQuery,
    ...(excludeSorting ? {} : sortingAPIQuery),
    ...(excludePager ? {} : pagerAPIQuery),
    ...extraAPIQuery,
  };
};

const getAPIQueryString = (resourceName, query = {}, opts = {}) => {
  return queryString.stringify(getAPIQuery(resourceName, query, opts));
};

/* Utility that checks if the API query string for a given resource has changed. */
export const hasAPIQueryStringChanged = (
  resourceName,
  prevProps,
  currentProps,
  ignoreLocationKey,
  opts
) => {
  const prevString = getAPIQueryString(
    resourceName,
    prevProps.location.query,
    opts
  );
  const currentString = getAPIQueryString(
    resourceName,
    currentProps.location.query,
    opts
  );
  const prevLocationKey = prevProps.location.key;
  const currentLocationKey = currentProps.location.key;

  return (
    prevString !== currentString ||
    (!ignoreLocationKey && prevLocationKey !== currentLocationKey)
  );
};

/* Get last combined sorting/search/filter/etc query for a given resource */
const getLastResourceQuery = (
  resourceName,
  { sorter, searcher, filter, pager }
) => {
  const lastSortQuery = sorter.lastQueries[resourceName] || {};
  const lastSearchQuery = searcher.lastQueries[resourceName] || {};
  const lastFilterQuery = filter.lastQueries[resourceName] || {};
  const lastPagerQuery = pager.lastQueries[resourceName] || {};

  return {
    ...lastSortQuery,
    ...lastSearchQuery,
    ...lastFilterQuery,
    ...lastPagerQuery,
  };
};

/* Apply query client-side on list of provided items */
const applyClientQuery = (items, resourceName, query = {}, opts = {}) => {
  let updatedItems = cloneDeep(items);

  /* TODO: only filtering and searching is currently supported */
  updatedItems = applyFilterClientQuery(
    updatedItems,
    resourceName,
    query,
    opts
  );
  updatedItems = applySearchClientQuery(
    updatedItems,
    resourceName,
    query,
    opts
  );

  return updatedItems;
};

/* FIXME: due to a bug with z-index + -webkit-overflow-scrolling on mobile Safari, disable smooth
   scrolling when modals are showing up.
   https://stackoverflow.com/questions/37696746/ios-not-respecting-z-index-with-webkit-overflow-scrolling */
const handleIosModalsOverlap = () => {
  if (
    navigator.userAgent.includes("iPad") ||
    navigator.userAgent.includes("iPhone")
  ) {
    setInterval(() => {
      if (!document.body) {
        return;
      }

      const disabledScrollStyle = "-webkit-overflow-scrolling: auto";
      const smoothScrollDisabled = (
        document.body.getAttribute("style") || ""
      ).includes(disabledScrollStyle);
      const hasModalsOpen =
        document.getElementsByClassName("modal-dialog").length +
          document.getElementsByClassName("single-entry-modal").length +
          document.getElementsByClassName("add-item-form").length +
          document.getElementsByClassName("package-details").length >
        0;

      if (hasModalsOpen) {
        if (!smoothScrollDisabled) {
          document.body.setAttribute("style", disabledScrollStyle);
        }
      } else {
        if (smoothScrollDisabled) {
          document.body.setAttribute("style", "");
        }
      }
    }, 500);
  }
};

const preventDefault = (func) => (e) => {
  if (e && e.preventDefault) {
    e.preventDefault();
  }

  func();
};

const scrollToModalTop = (modalClassName) => {
  const modalEl = document.getElementsByClassName(modalClassName)[0];
  if (!modalEl) {
    console.warn(`No modal with class "${modalClassName}" found`);
    return;
  }

  const containerEl = modalEl.getElementsByClassName(
    "modal-content-container"
  )[0];
  if (!containerEl) {
    console.warn(
      `No scrolling container found for modal with class "${modalClassName}"`
    );
    return;
  }

  containerEl.scrollTop = 0;
};

const scrollToPageTop = () => {
  /* scroll to top in desktop/tablet devices */
  if (document.getElementById("app-scroller")) {
    scroller.scrollTo("app-header", { containerId: "app-scroller" });
  }
  /* scroll to top in mobile devices */
  if (document.getElementById("content_and_footer_wrapper")) {
    scroller.scrollTo("content", { containerId: "content_and_footer_wrapper" });
  }
};

const getPageScrollElement = (idOnly) => {
  /* desktop/tablet devices */
  let elId = "app-scroller";
  /* mobile devices */
  if (
    typeof window !== "undefined" &&
    window.innerWidth < MOBILE_BREAKPOINT_PX
  ) {
    elId = "content_and_footer_wrapper";
  }
  return idOnly ? elId : document.getElementById(elId);
};

export const getPageScrollPosition = () =>
  idx(getPageScrollElement(), (_) => _.scrollTop) || 0;

export const setPageScrollPosition = (pos) => {
  const el = getPageScrollElement();
  if (el) {
    el.scrollTop = pos;
  }
};

export const scrollTo = (id, opts) => {
  scroller.scrollTo(id, { containerId: getPageScrollElement(true), ...opts });
};

const atAdmin = (state) =>
  (
    idx(state, (_) => _.routing.locationBeforeTransitions.pathname) || ""
  ).startsWith(ADMIN_ROOT);

/* sanitize string to prevent XSS attacks */
const sanitizeString = (str) => {
  const map = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#x27;",
    "/": "&#x2F;",
  };
  return str ? str.replace(/[&<>"'/]/gi, (m) => map[m]) : str;
};

export const getItemsFromString = (string, separator = ",", unique = true) => {
  const items = (string || "")
    .split(separator)
    .map((i) => i.trim())
    .filter((i) => !!i);

  if (unique) {
    return uniq(items);
  }

  return items;
};

export const ignoreEnterKey = (e) => {
  if (e.key === "Enter") {
    e.preventDefault();
    e.stopPropagation();
  }
};

export const getSuspensionUrl = (suspensionId) =>
  `/account/suspended/${suspensionId}/`;

export {
  getPaginationValues,
  isMobile,
  parsePageFromUrl,
  calculatePageOffset,
  shareLink,
  getTimeLeftLabel,
  getCanonicalURL,
  isUrlActive,
  getAPIQueryString,
  getLastResourceQuery,
  preventDefault,
  handleIosModalsOverlap,
  scrollToModalTop,
  atAdmin,
  scrollToPageTop,
  sanitizeString,
  getAPIQuery,
  applyClientQuery,
};

/* Creates the settings for time throughout the site */
moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "1m",
    m: "1m",
    mm: "%dm",
    h: "1h",
    hh: "%dh",
    d: "1d",
    dd: "%dd",
    M: "1mo",
    MM: "%dmos",
    y: "1y",
    yy: "%dy",
  },
});
