import React from "react";
import PropTypes from "prop-types";
import ModalDialog from "common/components/modal/modal_dialog";

/**
 * Modal to display an HTML preview of a contract built from crowdspring templates.
 *
 * @author Nathan Ozelim <nathan@startupfoundry.com>
 */
const ContractPreviewModal = ({ showing, onClose, url, header }) => {
  return (
    <ModalDialog
      dialogClasses="contract-preview-modal"
      contentClasses="wide"
      modalOpen={showing}
      closeAction={onClose}
      modalHeader={header || "Contract preview"}
      onPrimary={onClose}
      primaryLabel="Done"
    >
      <iframe src={url} />
    </ModalDialog>
  );
};

/**
 * PropTypes.
 *
 * @property {string} url - contract preview URL
 * @property {string} header - custom modal header
 * @property {bool} showing - whether modal should be shown
 * @property {func} onClose - function called to close the modal
 */
ContractPreviewModal.propTypes = {
  url: PropTypes.string,
  header: PropTypes.string,
  showing: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ContractPreviewModal;
