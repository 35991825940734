import { connect } from "react-redux";
import { getProject } from "project/ducks/project";
import ProjectRedirect from "common/server/components/project_redirect";

function mapStateToProps(state) {
  return {
    categories: state.categories,
    project: getProject(state),
    server: state.server,
  };
}

export default connect(mapStateToProps)(ProjectRedirect);
