import React from "react";
import PropTypes from "prop-types";
import HeaderTitle from "header/components/title";
import UserTypeTabs from "your_projects/containers/user_type_tabs";

const Header = ({ user }) => {
  const cta = user.is_client
    ? { url: "/start-a-project/", label: "Start a new project" }
    : null;

  return (
    <HeaderTitle cta={cta} title="Your Projects">
      <div className="show-for-large">
        <UserTypeTabs dropdownOnly={true} />
      </div>
    </HeaderTitle>
  );
};

export default Header;
