import request from "axios";
import { browserHistory } from "react-router";
import partial from "lodash/partial";
import sortBy from "lodash/sortBy";
import some from "lodash/some";
import idx from "idx";
import {
  ENTRIES_RESOURCE_NAME,
  SHOW_FEEDBACK_SENT_FOR,
  FINALISTS_ONLY_QUERY_PARAM,
} from "entries/constants";
import { getAPIQueryString } from "common/utils";
import { savePageCount } from "common/pager/ducks";
import { hideBanner } from "common/ducks/banner";
import { getEntriesByRow } from "entries/utils";
import { setBannerError } from "error";
import { getProject, fetchProject } from "project/ducks/project";
import {
  fetchAwarded as fetchAwardedCreatives,
  getAwarded as getAwardedCreatives,
} from "project/ducks/creatives";
import { fetchWrapupsIfNeeded } from "project/ducks/wrapup";
import { getProjectUrl } from "project/utils";
import { getSubcategorySlug } from "common/ducks/categories";

/* ACTIONS
================================================================================================ */
const FETCH_ALL = "entries/FETCH_ALL";
const FETCH_ALL_REQUEST = "entries/FETCH_ALL_REQUEST";
const FETCH_ALL_SUCCESS = "entries/FETCH_ALL_SUCCESS";

const CLEAR_ALL = "entries/CLEAR_ALL";

const SET_VIEW_TYPE = "entries/SET_VIEW_TYPE";

const SET_GROUPING = "entries/SET_GROUPING";

const RATE = "entries/RATE";
const RATE_SUCCESS = "entries/RATE_SUCCESS";

const DISMISS_FEEDBACK = "entries/DISMISS_FEEDBACK";

const GIVE_FEEDBACK = "entries/GIVE_FEEDBACK";
const GIVE_FEEDBACK_SUCCESS = "entries/GIVE_FEEDBACK_SUCCESS";

const TOGGLE_DELETE = "entries/TOGGLE_DELETE";

const DELETE = "entries/DELETE";
const DELETE_SUCCESS = "entries/DELETE_SUCCESS";

const DELAY_ACTION_START = "entries/DELAY_ACTION_START";
const DELAY_ACTION_END = "entries/DELAY_ACTION_END";

const TOGGLE_TIP = "entries/TOGGLE_TIP";

const TIP = "entries/TIP";
export const TIP_SUCCESS = "entries/TIP_SUCCESS";

const FETCH_UNDER_NEGOTIATION = "entries/FETCH_UNDER_NEGOTIATION";
const FETCH_UNDER_NEGOTIATION_SUCCESS =
  "entries/FETCH_UNDER_NEGOTIATION_SUCCESS";

const SET_OFFER_NEGOTIATION_ACTION = "entries/SET_OFFER_NEGOTIATION_ACTION";

const ADVANCE_OFFER_NEGOTIATION = "entries/ADVANCE_OFFER_NEGOTIATION";
export const ADVANCE_OFFER_NEGOTIATION_SUCCESS =
  "entries/ADVANCE_OFFER_NEGOTIATION_SUCCESS";

const TOGGLE_OFFER = "entries/TOGGLE_OFFER";

const FETCH_ICA = "entries/FETCH_ICA";
const FETCH_ICA_REQUEST = "entries/FETCH_ICA_REQUEST";
const FETCH_ICA_SUCCESS = "entries/FETCH_ICA_SUCCESS";

const TOGGLE_REINSTATE = "entries/TOGGLE_REINSTATE";

const REINSTATE = "entries/REINSTATE";
const REINSTATE_SUCCESS = "entries/REINSTATE_SUCCESS";

const TOGGLE_AWARD = "entries/TOGGLE_AWARD";

const AWARD = "entries/AWARD";
const AWARD_SUCCESS = "entries/AWARD_SUCCESS";

const FETCH_WINNER = "entries/FETCH_WINNER";
const FETCH_WINNER_SUCCESS = "entries/FETCH_WINNER_SUCCESS";
const FETCH_WINNER_FAILURE = "entries/FETCH_WINNER_FAILURE";

const FETCH_PACKAGE_TIPPED = "entries/FETCH_PACKAGE_TIPPED";
const FETCH_PACKAGE_TIPPED_REQUEST = "entries/FETCH_PACKAGE_TIPPED_REQUEST";
const FETCH_PACKAGE_TIPPED_SUCCESS = "entries/FETCH_PACKAGE_TIPPED_SUCCESS";

const REMOVE_FINALIST = "entries/REMOVE_FINALIST";
export const REMOVE_FINALIST_SUCCESS = "entries/REMOVE_FINALIST_SUCCESS";

const ADD_FINALIST = "entries/ADD_FINALIST";
export const ADD_FINALIST_SUCCESS = "entries/ADD_FINALIST_SUCCESS";

const TOGGLE_FINAL_ROUND = "entries/TOGGLE_FINAL_ROUND";

const START_FINAL_ROUND = "entries/START_FINAL_ROUND";
export const START_FINAL_ROUND_SUCCESS = "entries/START_FINAL_ROUND_SUCCESS";

const TOGGLE_FINAL_ROUND_EXTENSION = "entries/TOGGLE_FINAL_ROUND_EXTENSION";

const EXTEND_FINAL_ROUND = "entries/EXTEND_FINAL_ROUND";
export const EXTEND_FINAL_ROUND_SUCCESS = "entries/EXTEND_FINAL_ROUND_SUCCESS";

/* HELPERS
================================================================================================ */
const DELAY_INTERVAL = 2000;

export const getEntries = (state) => {
  const { list, grouped, ratedRevisionId, viewType } = state.entries;
  const entriesByRow = getEntriesByRow();
  let entries = list;

  /* if not grouped, return an entry copy for each revision */
  if (!grouped) {
    entries = entries.reduce((acc, entry) => {
      entry.revisions.forEach(
        (rev) =>
          (acc = acc.concat({
            ...entry,
            key: `rev_${rev.id}`,
            revisions: [rev],
          }))
      );
      return acc;
    }, []);
  }

  /* add feedback item to list if entry just scored */
  if (ratedRevisionId) {
    let feedbackItem;
    entries.forEach((entry, i) => {
      const ratedRevision = entry.revisions.find(
        (rev) => rev.id === ratedRevisionId
      );

      if (ratedRevision) {
        feedbackItem = {
          ...entry,
          revisions: [ratedRevision],
          isFeedback: true,
          horizontalOffset: viewType === "grid" ? i % entriesByRow : 0,
          position:
            viewType === "grid"
              ? parseInt(i / entriesByRow) * entriesByRow + entriesByRow
              : i + 1,
        };
      }
    });

    if (feedbackItem) {
      entries = [
        ...entries.slice(0, feedbackItem.position),
        feedbackItem,
        ...entries.slice(feedbackItem.position),
      ];
    }
  }

  return entries;
};

/* Get project awards, sorted by value */
export const getAwards = (state, excludeIssued, prj) => {
  const project = prj || getProject(state);

  if (!project || !project.award_rules) {
    return [];
  }

  const awards = sortBy(
    project.award_rules.filter(({ feature }) => feature.position !== 0),
    (a) => -a.feature.value
  );
  /* exclude issued awards if that's the case */
  if (excludeIssued) {
    const awardedCreatives = getAwardedCreatives(state, true);
    return awards.filter(
      (a) => !awardedCreatives.find((aw) => aw.award_uuid == a.uuid)
    );
  }
  return awards;
};

const updateRatingLocal = (list, action) => {
  return list.map((entry) => {
    if (entry.id !== action.payload.entry) {
      return entry;
    }

    return {
      ...entry,
      revisions: entry.revisions.map((revision) => {
        if (revision.id !== action.payload.id) {
          return revision;
        }

        return {
          ...revision,
          score: action.payload.score,
        };
      }),
    };
  });
};

export const createEntryRequest = ({ projectId, data }) => {
  return request.post(`/api/v1/projects/${projectId}/entries/`, data);
};

export const createRevisionRequest = ({ projectId, entryId, data }) => {
  return request.post(
    `/api/v1/projects/${projectId}/entries/${entryId}/create_revision/`,
    data
  );
};

/* INITIAL STATES
================================================================================================ */
export const initialState = {
  list: [],
  isFetching: false,
  ratedRevisionId: null,
  feedbackGiven: false,
  deletingEntry: null,
  tippingEntry: null,
  underNegotiation: [],
  offeringEntry: null,
  reinstatingEntry: null,
  awardingEntry: null,
  awardingEntryAward: null,
  winner: null,
  packageTipped: null,
  showingFinalRound: false,
  extendingFinalRound: false,
  allIds: [],
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_REQUEST:
      return {
        ...state,
        isFetching: true,
        ratedRevisionId: null,
        allQueryStr: action.queryStr,
      };
    case FETCH_ALL_SUCCESS:
      /* handle race conditions by only updating the state with the result of the
         last request made */
      if (state.allQueryStr !== action.queryStr) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        isFetching: false,
        list: action.payload.results,
        allIds: action.payload.all_ids,
      };
    case CLEAR_ALL:
      return {
        ...state,
        list: [],
        ratedRevisionId: null,
      };
    case SET_VIEW_TYPE:
      return {
        ...state,
        viewType: action.payload,
      };
    case SET_GROUPING:
      return {
        ...state,
        grouped: action.payload,
      };
    case RATE_SUCCESS:
      return {
        ...state,
        ratedRevisionId: action.payload.id,
        list: updateRatingLocal(state.list, action),
        feedbackGiven: false,
      };
    case DISMISS_FEEDBACK:
      return {
        ...state,
        ratedRevisionId: null,
        feedbackGiven: false,
      };
    case GIVE_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedbackGiven: true,
      };
    case TOGGLE_DELETE:
      return {
        ...state,
        deletingEntry: action.entry,
      };
    case DELETE_SUCCESS:
      return {
        ...state,
        deletingEntry: null,
      };
    case DELAY_ACTION_START:
      return {
        ...state,
        isFetching: true,
      };
    case DELAY_ACTION_END:
      return {
        ...state,
        isFetching: false,
      };
    case TOGGLE_TIP:
      return {
        ...state,
        tippingEntry: action.entry,
      };
    case TIP_SUCCESS:
      return {
        ...state,
        list: state.list.map((entry) => {
          if (entry.id === action.payload.id) {
            return { ...entry, tip_value: action.payload.tip_value };
          }
          return entry;
        }),
      };
    case FETCH_UNDER_NEGOTIATION_SUCCESS:
      return {
        ...state,
        underNegotiation: action.payload,
      };
    case SET_OFFER_NEGOTIATION_ACTION:
      return {
        ...state,
        offerNegotiationAction: action.data.action,
        offerNegotiationEntry: action.data.entry,
      };
    case TOGGLE_OFFER:
      return {
        ...state,
        offeringEntry: action.entry,
      };
    case ADVANCE_OFFER_NEGOTIATION_SUCCESS:
      return {
        ...state,
        list: state.list.map((entry) => {
          if (entry.id === action.payload.id) {
            return { ...entry, offer_value: action.payload.offer_value };
          }
          return entry;
        }),
      };
    case FETCH_ICA_REQUEST:
      return {
        ...state,
        ica: undefined,
      };
    case FETCH_ICA_SUCCESS:
      return {
        ...state,
        ica: action.payload,
      };
    case TOGGLE_REINSTATE:
      return {
        ...state,
        reinstatingEntry: action.entry,
      };
    case REINSTATE_SUCCESS:
      return {
        ...state,
        reinstatingEntry: null,
      };
    case TOGGLE_AWARD:
      return {
        ...state,
        awardingEntry: action.entry,
        awardingEntryAward: action.award,
      };
    case AWARD_SUCCESS:
      return {
        ...state,
        list: state.list.map((entry) => {
          if (entry.id === action.payload.entry.id) {
            return {
              ...entry,
              award_value: action.payload.entry.award_value,
              winner: action.payload.entry.winner,
              runnerup: action.payload.entry.runnerup,
            };
          }
          return entry;
        }),
      };
    case FETCH_WINNER_SUCCESS:
      return {
        ...state,
        winner: action.payload,
      };
    case FETCH_WINNER_FAILURE:
      return {
        ...state,
        winner: null,
      };
    case FETCH_PACKAGE_TIPPED_REQUEST:
      return {
        ...state,
        packageTipped: null,
      };
    case FETCH_PACKAGE_TIPPED_SUCCESS:
      return {
        ...state,
        packageTipped: action.payload,
      };
    case TOGGLE_FINAL_ROUND:
      return {
        ...state,
        showingFinalRound: !state.showingFinalRound,
      };
    case TOGGLE_FINAL_ROUND_EXTENSION:
      return {
        ...state,
        extendingFinalRound: !state.extendingFinalRound,
      };
    default:
      return state;
  }
}

export const fetchAll = (projectId, query, ownOnly) => (dispatch, getState) => {
  const user = getState().user.profile_data;
  const opts = {
    extraAPIQuery: {
      include_all_ids: true,
    },
  };
  if (ownOnly) {
    opts.extraAPIQuery.entry_author_username = user.username;
  }

  const queryStr = getAPIQueryString(ENTRIES_RESOURCE_NAME, query, opts);

  const promise = request.get(
    `/api/v1/projects/${projectId}/entries/?${queryStr}`
  );

  /* save page count in pager state */
  promise.then((res) => dispatch(savePageCount(ENTRIES_RESOURCE_NAME, res)));

  return dispatch({
    type: FETCH_ALL,
    queryStr,
    promise,
  });
};

export const clearAll = () => {
  return {
    type: CLEAR_ALL,
  };
};

export const setViewType = (type) => {
  return {
    type: SET_VIEW_TYPE,
    payload: type,
  };
};

export const setGrouping = (grouped) => {
  return {
    type: SET_GROUPING,
    payload: grouped,
  };
};

export const rate = (entry, rating) => (dispatch) => {
  const url = `/api/v1/project-${entry.entry_type}-entry-revisions/${entry.revisions[0].id}/score/`;
  const promise = request.patch(url, { score: rating });

  clearTimeout(window.feedbackSentTimer);

  promise.catch(() =>
    dispatch(setBannerError("Error scoring entry", "Please try again."))
  );

  return dispatch({
    type: RATE,
    promise,
  });
};

export const dismissFeedback = () => {
  return {
    type: DISMISS_FEEDBACK,
  };
};

export const giveFeedback = (entry, feedback) => (dispatch) => {
  const isEmpty = !some(Object.values(feedback), (v) =>
    typeof v === "string" ? !!v.trim() : !!v
  );
  const url = `/api/v1/project-${entry.entry_type}-entry-revisions/${entry.revisions[0].id}/feedback/`;
  /* don't send empty feedbacks to the server */
  const promise = isEmpty
    ? Promise.resolve()
    : request.patch(url, { feedback });

  promise
    .then(() => {
      window.feedbackSentTimer = setTimeout(
        () => dispatch(dismissFeedback()),
        SHOW_FEEDBACK_SENT_FOR * 1000
      );
    })
    .catch(() =>
      dispatch(setBannerError("Error providing feedback", "Please try again."))
    );

  return dispatch({
    type: GIVE_FEEDBACK,
    promise,
  });
};

export const toggleDelete = (entry = null) => {
  return {
    type: TOGGLE_DELETE,
    entry,
  };
};

export const delayAction =
  (action, interval = DELAY_INTERVAL) =>
  (dispatch) => {
    dispatch({
      type: DELAY_ACTION_START,
    });

    setTimeout(() => {
      dispatch({
        type: DELAY_ACTION_END,
      });

      dispatch(action());
    }, interval);
  };

export const deleteEntry = (query, ownOnly, entry) => (dispatch, getState) => {
  const user = getState().user.profile_data;
  const isAuthor = user.id === entry.author;
  const data = isAuthor ? { withdrawn: true } : { eliminated: true };
  const promise = request.patch(
    `/api/v1/projects/${entry.project}/entries/${entry.id}/`,
    data
  );

  promise
    .then(() => {
      /* give ElasticSearch enough time to reflect the changes */
      dispatch(delayAction(partial(fetchAll, entry.project, query, ownOnly)));
    })
    .catch(() =>
      dispatch(
        setBannerError(
          `Error ${isAuthor ? "withdrawing" : "eliminating"} entry`,
          "Please try again."
        )
      )
    );

  dispatch({
    type: DELETE,
    promise,
  });
};

export const toggleTip = (entry = null) => {
  return {
    type: TOGGLE_TIP,
    entry,
  };
};

export const tip = (entry, data) => (dispatch) => {
  const promise = request.post(`/api/v1/projects/${entry.project}/tip/`, {
    ...data,
    entry: entry.id,
  });

  dispatch({
    type: TIP,
    promise,
  });

  return promise;
};

export const fetchUnderNegotiation = (projectId) => (dispatch) => {
  return dispatch({
    type: FETCH_UNDER_NEGOTIATION,
    promise: request.get(
      `/api/v1/projects/${projectId}/entries/under_offer_negotiation/`
    ),
  });
};

export const setOfferNegotiationAction = (action, entry) => {
  return {
    type: SET_OFFER_NEGOTIATION_ACTION,
    data: { action, entry },
  };
};

export const toggleOffer = (entry = null) => {
  return {
    type: TOGGLE_OFFER,
    entry,
  };
};

export const advanceOfferNegotiation = (projectId, data) => (dispatch) => {
  const promise = request.patch(
    `/api/v1/projects/${projectId}/offer_negotiation/`,
    data
  );

  dispatch({
    type: ADVANCE_OFFER_NEGOTIATION,
    promise,
  });

  return promise;
};

export const fetchICA =
  (projectId, creativeId, expectedValue) => (dispatch) => {
    return dispatch({
      type: FETCH_ICA,
      promise: request.get(
        `/api/v1/projects/${projectId}/client_ica_contract/` +
          `?creative=${creativeId}&expected_value=${expectedValue}`
      ),
    });
  };

export const toggleReinstate = (entry = null) => {
  return {
    type: TOGGLE_REINSTATE,
    entry,
  };
};

export const reinstate = (query, ownOnly, entry) => (dispatch, getState) => {
  const user = getState().user.profile_data;
  const isAuthor = user.id === entry.author;
  const data = isAuthor ? { withdrawn: false } : { eliminated: false };
  const promise = request.patch(
    `/api/v1/projects/${entry.project}/entries/${entry.id}/`,
    data
  );

  promise
    .then(() => {
      /* give ElasticSearch enough time to reflect the changes */
      dispatch(delayAction(partial(fetchAll, entry.project, query, ownOnly)));
    })
    .catch(() =>
      dispatch(
        setBannerError(
          `Error ${isAuthor ? "un-withdrawing" : "un-eliminating"} entry`,
          "Please try again."
        )
      )
    );

  dispatch({
    type: REINSTATE,
    promise,
  });
};

export const toggleAward = (entry = null, award = null) => {
  return {
    type: TOGGLE_AWARD,
    entry,
    award,
  };
};

export const award = (signatureData) => (dispatch, getState) => {
  const state = getState();
  const { categories } = state;
  const project = getProject(state);
  const { awardingEntry, awardingEntryAward } = state.entries;
  const promise = request.post(
    `/api/v1/projects/${awardingEntry.project}/award/`,
    {
      award: awardingEntryAward.uuid,
      entry: awardingEntry.id,
      ...signatureData,
    }
  );

  promise
    .then(({ data }) => {
      /* fetch updated project */
      dispatch(fetchProject(awardingEntry.project));

      /* fetch updated wrap-ups list */
      dispatch(fetchWrapupsIfNeeded(awardingEntry.project, true)).then(() => {
        /* go to created wrap-up in case all awards were issued */
        if (data.all_awards_issued) {
          const subcategorySlug = getSubcategorySlug(
            categories,
            project.sub_category
          );
          const url = getProjectUrl(
            project,
            subcategorySlug,
            `wrapup/${data.award_id}/`
          );
          browserHistory.push(url);
        }
      });

      /* fetch updated awarded creatives list */
      dispatch(fetchAwardedCreatives(awardingEntry.project));

      /* hide banner about focus groups, since it doesn't make sense anymore */
      dispatch(hideBanner());
    })
    .catch(() => {
      dispatch(setBannerError("Error awarding creative", "Please try again."));
    });

  dispatch({
    type: AWARD,
    promise,
  });

  return promise;
};

export const fetchWinner = (projectId) => (dispatch) => {
  return dispatch({
    type: FETCH_WINNER,
    promise: request.get(`/api/v1/projects/${projectId}/entries/winner/`),
  });
};

export const fetchPackageTipped = (projectId) => (dispatch) => {
  return dispatch({
    type: FETCH_PACKAGE_TIPPED,
    promise: request.get(`/api/v1/projects/${projectId}/package_tipped/`),
  });
};

export const removeFinalist = (projectId, creativeId) => (dispatch) => {
  const promise = request.patch(
    `/api/v1/projects/${projectId}/remove_finalist/`,
    { creative: creativeId }
  );

  promise.catch(() =>
    dispatch(setBannerError("Error removing finalist", "Please try again."))
  );

  dispatch({
    type: REMOVE_FINALIST,
    promise,
  });
};

export const addFinalist = (projectId, creativeId) => (dispatch) => {
  const promise = request.patch(`/api/v1/projects/${projectId}/add_finalist/`, {
    creative: creativeId,
  });

  promise.catch((res) => {
    const error = idx(res, (_) => _.data.creative[0]) || "Please try again";
    dispatch(setBannerError("Error adding finalist", error));
  });

  dispatch({
    type: ADD_FINALIST,
    promise,
  });
};

export const toggleFinalRoundConfirmation = () => {
  return {
    type: TOGGLE_FINAL_ROUND,
  };
};

export const startFinalRound = () => (dispatch, getState) => {
  const state = getState();
  const { pathname } = state.routing.locationBeforeTransitions;
  const project = getProject(state);

  const promise = request.get(
    `/api/v1/projects/${project.id}/launch_finalist_round/`
  );

  promise
    .then(() => {
      dispatch(toggleFinalRoundConfirmation());
      browserHistory.push({
        pathname,
        query: {
          [FINALISTS_ONLY_QUERY_PARAM]: true,
        },
      });
    })
    .catch(() =>
      dispatch(
        setBannerError("Error launching Finalist Round", "Please try again.")
      )
    );

  dispatch({
    type: START_FINAL_ROUND,
    promise,
  });
};

export const toggleFinalRoundExtension = () => {
  return {
    type: TOGGLE_FINAL_ROUND_EXTENSION,
  };
};

export const extendFinalRound = () => (dispatch, getState) => {
  const project = getProject(getState());

  const promise = request.get(
    `/api/v1/projects/${project.id}/extend_finalist_round/`
  );

  promise
    .then(() => {
      dispatch(toggleFinalRoundExtension());
    })
    .catch(() =>
      dispatch(
        setBannerError("Error extending Finalist Round", "Please try again.")
      )
    );

  dispatch({
    type: EXTEND_FINAL_ROUND,
    promise,
  });
};
