import request from "axios";
import { PROJECTS_RESOURCE_NAME } from "profile/constants";
import { getAPIQueryString } from "common/utils";
import { savePageCount } from "common/pager/ducks";

/* ACTIONS
================================================================================================ */
const FETCH_ALL = "profile/client_projects/FETCH_ALL";
const FETCH_ALL_REQUEST = "profile/client_projects/FETCH_ALL_REQUEST";
const FETCH_ALL_SUCCESS = "profile/client_projects/FETCH_ALL_SUCCESS";

/* HELPERS
================================================================================================ */

/* INITIAL STATES
================================================================================================ */
const initialState = {
  list: [],
  isFetching: false,
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_ALL_SUCCESS:
      return {
        ...state,
        list: action.payload.results,
        isFetching: false,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const fetchAll = (query) => (dispatch, getState) => {
  const { profile } = getState().profile.common;
  const queryStr = getAPIQueryString(PROJECTS_RESOURCE_NAME, query);
  const promise = request.get(
    `/api/v1/user-profiles/${profile.username}/posted_projects/?${queryStr}`
  );

  /* save page count in pager state */
  promise.then((res) => dispatch(savePageCount(PROJECTS_RESOURCE_NAME, res)));

  return dispatch({
    type: FETCH_ALL,
    promise,
  });
};
