import Wrapper from "notifications/components/wrapper";
import { notificationAction } from "notifications/ducks";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import partial from "lodash/partial";

export function mapStateToProps({ user }) {
  const userId = user.logged_in ? user.profile_data.id : null;

  return { userId };
}

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      readNotification: partial(notificationAction, "read"),
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper);
