import React, { Component } from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { LoadingIndicator } from "common/components/preloader";

/*
 * A component allowing for selecting (from disk) or dropping a file onto a drop-zone.
 *
 * It only accepts files matching a pre-defined MIME type and can also validate image dimensions
 * if an image is selected.
 *
 * To use it, you should include it in some other component and specify the optional MIME type
 * (`image/*` by default) and optional image dimension constraints. If a valid file is dropped the
 * `onValidFileSelected` prop will be called and passed the corresponding File object.
 *
 * You can then dispatch an action and hide this component, upload a file or manipulate it further
 * (e.g. start a cropper to crop the image, before uploading).
 *
 * XXX: we may need to implement the server-side fallback for checking image dimensions for older
 * browsers
 *
 * @author Kamil Grymuza <kamil@startupfoundry.com>
 * @author Kuba Siemiątkowski <kuba@crowdspring.com>
 */

const INITIAL_STATE = {
  isFileValid: false,
  isFileProcessed: false,
  isWrongFileType: false,
  extraValidationsError: null,
  exceedsMaxSize: false,
};

export default class FileSelector extends Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;

    this.handleOnDrop = this.handleOnDrop.bind(this);
    this.handleOnDropRejected = this.handleOnDropRejected.bind(this);
    this.onOpenClick = this.onOpenClick.bind(this);
    this.performExtraValidations = this.performExtraValidations.bind(this);
  }

  onOpenClick(e) {
    /* stop propagation since the button is clicked inside the dropzone
       and we don't want the file browser to open twice */
    e.preventDefault();
    e.stopPropagation();
    this.dropzone.open();
  }

  /*
   * Returns true if according to the file is an image.
   */
  isImageFile(file) {
    return file.type.indexOf("image/") == 0;
  }

  imageIsTooBig(image) {
    return (
      image.width > this.props.maxImageWidth ||
      image.height > this.props.maxImageHeight
    );
  }

  imageIsTooSmall(image) {
    return (
      image.width < this.props.minImageWidth ||
      image.height < this.props.minImageHeight
    );
  }

  /*
   * Checks whether the image satisfies the constraints and if yes, calls
   * the `onValidFileSelected` prop providing it with the corresponding File object.
   *
   * @param image: An `Image` instance. Expected to have width and height attributes.
   * @param imageFile: a `File` instance, from which `image` was originally derived
   */
  processImage(image, imageFile) {
    if (this.imageIsTooBig(image) || this.imageIsTooSmall(image)) {
      this.setState({
        isFileProcessed: true,
        isFileValid: false,
      });
      if (this.props.onInvalidOrRejected) {
        this.props.onInvalidOrRejected();
      }
    } else {
      this.setState({
        isFileProcessed: true,
        isFileValid: true,
      });

      if (this.props.onValidFileSelected) {
        this.props.onValidFileSelected(imageFile);
      }
    }
  }

  /*
   * Constructs an `Image` instance based on the provided `imageFile` and calls
   * `processImage` when the image is loaded.
   */
  loadAndProcessImage(imageFile) {
    const image = new Image();
    image.onload = this.processImage.bind(this, image, imageFile);
    image.src = imageFile.preview;

    return image;
  }

  performExtraValidations(file) {
    /* TODO: extend this function with other pertinent validations */

    /* RAR files are not accepted (note that this cannot be handled by the
     * `accept` <Dropzone/> prop, since it's a rejection criteria, not an acceptance one) */
    if (file.name.toLowerCase().endsWith(".rar")) {
      this.setState({ extraValidationsError: "RAR files are not accepted." });
      if (this.props.onInvalidOrRejected) {
        this.props.onInvalidOrRejected();
      }
      return false;
    }

    return true;
  }

  /*
   * Wraps Dropzone's onDropAccepted event and based on the logic contained here,
   * optionally, calls the onValidFileSelected prop.
   */
  handleOnDrop(droppedFiles) {
    /* clear state */
    this.setState(INITIAL_STATE);

    let file = droppedFiles[0];

    /* perform extra validations before actually considering files as accepted */
    if (!this.performExtraValidations(file)) {
      return;
    }

    if (this.props.onFileSelected) {
      this.props.onFileSelected(file);
    }

    if (this.isImageFile(file)) {
      this.loadAndProcessImage(file);
    } else if (this.props.onValidFileSelected) {
      this.props.onValidFileSelected(file);
    }
  }

  /*
   * Handler for Dropzone's onDropRejected event, used for displaying the relevant
   * error message.
   */
  handleOnDropRejected(droppedFiles) {
    const { maxSize, onInvalidOrRejected } = this.props;

    /* clear state */
    this.setState(INITIAL_STATE);

    if (droppedFiles[0].size > maxSize * 1024 * 1024) {
      this.setState({ exceedsMaxSize: true });
    } else {
      this.setState({ isWrongFileType: true });
    }
    if (this.props.onInvalidOrRejected) {
      this.props.onInvalidOrRejected();
    }
  }

  /*
   * Displays the original instructions for the user.
   */
  renderInstructions() {
    const { acceptedFileType, extraInstructions, addFileLabel } = this.props;
    const fileIcon =
      acceptedFileType == "application/pdf"
        ? "fa fa-file-pdf-o"
        : "fa fa-file-image-o";

    return (
      <div className="add-file-wrap">
        <div className="icon">
          <i className={fileIcon} />
        </div>
        <span className="upload-hint">Drag file here to upload or</span>
        <button type="button" className="btn btn-secondary add-file">
          {addFileLabel || "Add file"}
        </button>
        {!!extraInstructions && (
          <span className="extra-instructions">{extraInstructions}</span>
        )}
      </div>
    );
  }

  renderImageDimensionsError() {
    return (
      <div>
        <span>
          <h4>Whoops!</h4>
        </span>
        <p>Looks like the file you are trying to upload is too small.</p>
        <p>
          Make sure it is at least {this.props.minImageWidth}px x{" "}
          {this.props.minImageHeight}px.
        </p>

        <button
          type="button"
          className="btn btn-secondary add-file"
          onClick={this.onOpenClick}
        >
          Try again
        </button>
      </div>
    );
  }

  renderWrongFileTypeError() {
    const { acceptedFileType } = this.props;

    return (
      <div className="error">
        <h4>Whoops!</h4>
        <p>
          Please select a file with the correct format.
          <br />
          Accepted formats are: <i>{acceptedFileType}</i>.
        </p>
        <button
          type="button"
          className="btn btn-secondary add-file"
          onClick={this.onOpenClick}
        >
          Try again
        </button>
      </div>
    );
  }

  renderExtraValidationsError() {
    return (
      <div className="error">
        <h4>Whoops!</h4>
        <p>{this.state.extraValidationsError}</p>
        <button
          type="button"
          className="btn btn-secondary add-file"
          onClick={this.onOpenClick}
        >
          Try again
        </button>
      </div>
    );
  }

  renderExceedsSizeError() {
    const { maxSize } = this.props;
    return (
      <div className="error">
        <h4>Whoops!</h4>
        <p>The file you selected exceeds the maximum size of {maxSize} MB.</p>
        <button
          type="button"
          className="btn btn-secondary add-file"
          onClick={this.onOpenClick}
        >
          Try again
        </button>
      </div>
    );
  }

  render() {
    const { acceptedFileType, maxSize } = this.props;
    const {
      isWrongFileType,
      isFileProcessed,
      isFileValid,
      exceedsMaxSize,
      extraValidationsError,
    } = this.state;

    let contents = this.renderInstructions();

    if (isWrongFileType) {
      contents = this.renderWrongFileTypeError();
    } else if (exceedsMaxSize) {
      contents = this.renderExceedsSizeError();
    } else if (isFileProcessed && !isFileValid) {
      contents = this.renderImageDimensionsError();
    } else if (extraValidationsError) {
      contents = this.renderExtraValidationsError();
    }

    return (
      <div className="file-uploader">
        <Dropzone
          className="file-dropzone"
          onDropAccepted={this.handleOnDrop}
          onDropRejected={this.handleOnDropRejected}
          activeClassName="active"
          multiple={false}
          ref={(c) => {
            this.dropzone = c;
          }}
          accept={acceptedFileType}
          maxSize={maxSize * 1024 * 1024}
        >
          {contents}
        </Dropzone>
      </div>
    );
  }
}

FileSelector.defaultProps = {
  acceptedFileType: "image/jpg,image/jpeg,image/png,image/gif",
  maxSize: 100,
};

FileSelector.propTypes = {
  onFileSelected: PropTypes.func,
  onInvalidOrRejected: PropTypes.func,
  // Called when a valid file is dropped or selected
  onValidFileSelected: PropTypes.func,
  // A list of accepted mime types for this uploader (comma-separated), defaults to
  // `image/jpg,image/jpeg,image/png,image/gif`
  acceptedFileType: PropTypes.string,
  maxImageWidth: PropTypes.number,
  maxImageHeight: PropTypes.number,
  minImageWidth: PropTypes.number,
  minImageHeight: PropTypes.number,
  // Maximum file size in MB
  maxSize: PropTypes.number,
  addFileLabel: PropTypes.string,
};
