import { connect } from "react-redux";
import HeaderTitle from "header/components/title";

const mapStateToProps = () => {
  return {
    title: "Confirm Your Subscription",
  };
};

export default connect(mapStateToProps)(HeaderTitle);
