import request from "axios";
import PropTypes from "prop-types";
import { PROJECTS_RESOURCE_NAME } from "explore/constants";
import { getAPIQueryString } from "common/utils";
import { savePageCount } from "common/pager/ducks";

/* ACTIONS
================================================================================================ */
const FETCH = "crowdspring/explore/FETCH_PROJECTS";
const FETCH_REQUEST = "crowdspring/explore/FETCH_PROJECTS_REQUEST";
const FETCH_SUCCESS = "crowdspring/explore/FETCH_PROJECTS_SUCCESS";
const FETCH_FAILURE = "crowdspring/explore/FETCH_PROJECTS_FAILURE";

/* INITIAL STATES
================================================================================================ */
export const initialState = {
  isFetching: false,
  isError: false,
  data: [],
};

/* HELPERS
================================================================================================ */
export const getProjects = (state) => state.explore.projects;

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_SUCCESS:
      const data = action.payload;

      return {
        ...state,
        isFetching: false,
        data: data.results,
      };
    case FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: true,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
================================================================================================ */
export const fetchProjects = (query) => (dispatch) => {
  const queryStr = getAPIQueryString(PROJECTS_RESOURCE_NAME, query);
  const promise = request.get(`/api/v1/projects/?${queryStr}`);

  /* save page count in pager state */
  promise.then((res) => dispatch(savePageCount(PROJECTS_RESOURCE_NAME, res)));

  return dispatch({
    type: FETCH,
    promise,
  });
};
