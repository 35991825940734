import "babel-polyfill";
import PropTypes from "prop-types";
import React from "react";
import { hydrate } from "react-dom";
import { Provider } from "react-redux";
import { Router, browserHistory } from "react-router";
import { syncHistoryWithStore } from "react-router-redux";
import * as Sentry from "@sentry/browser";
import createRoutes from "routes";
import configureStore from "store/configureStore";
import * as DSNS from "common/sentry_dsns";
import { getOptions } from "common/sentry_browser";

// Setup Sentry
const sentryDSN = DSNS[process.env.FRONTEND_ENV];
if (sentryDSN) {
  Sentry.init({ dsn: sentryDSN, ...getOptions() });
}

// Grab the state from a global injected into
// server-generated HTML
const initialState = window.__INITIAL_STATE__; // eslint-disable-line
const store = configureStore(initialState, browserHistory);
const history = syncHistoryWithStore(browserHistory, store);
const routes = createRoutes(store);
/* track very first render so that we can use it elsewhere */
window.isFirstRender = true;
// Router converts <Route> element hierarchy to a route config:
// Read more https://github.com/rackt/react-router/blob/latest/docs/Glossary.md#routeconfig
hydrate(
  <Provider store={store}>
    <Router history={history}>{routes}</Router>
  </Provider>,
  document.getElementById("app-wrapper")
);
window.isFirstRender = false;
