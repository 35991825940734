import React from "react";
import PropTypes from "prop-types";
import Sorter from "common/sorter/containers";
import { SelectField } from "common/components/form_fields";

/**
 * Sorter select field.
 *
 * @author Nathan Ozelim <nathan@startupfoundry.com>
 */
const SorterSelect = ({ options, value, defaultValue, onChange }) => {
  return (
    <SelectField
      options={options}
      meta={{}}
      allowEmpty={!defaultValue}
      emptyLabel="no sorting"
      input={{
        value: value || defaultValue,
        name: "sorting",
        onChange: (e) => onChange(e.target.value),
      }}
    />
  );
};

SorterSelect.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
};

const Wrapped = (props) => <Sorter {...props} component={SorterSelect} />;

export default Wrapped;
