import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import ProjectTypeTabs from "your_projects/containers/project_type_tabs";
import UserTypeTabs from "your_projects/containers/user_type_tabs";

const HeaderTabs = ({ user }) => {
  return (
    <span>
      <span
        className={classnames({
          "show-for-large": user.is_client && user.is_creative,
        })}
      >
        <ProjectTypeTabs />
      </span>
      <span className="hide-for-large">
        <UserTypeTabs />
      </span>
    </span>
  );
};

export default HeaderTabs;
