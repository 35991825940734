/**
import PropTypes from 'prop-types';
 * Redux reducer bundle for entries and single entry view on project page
 * @author Kuba Siemiątkowski <kuba@crowdspring.com>
 */
import { browserHistory } from "react-router";
import request from "axios";
import idx from "idx";
import pick from "lodash/pick";
import omit from "lodash/omit";
import uniq from "lodash/uniq";
import sortBy from "lodash/sortBy";

import {
  ENTRY_SCALE_LEVELS,
  DEFAULT_API_ENTRY_SORTING,
} from "common/variables";
import { getPaginationValues, calculatePageOffset } from "common/utils";
import { getProject, fetchProject } from "project/ducks/project";
import { getAwarded as getAwardedCreatives } from "project/ducks/creatives";
import * as commonImports from "project/ducks/common";
import revisionsReducer, * as revisionImports from "project/ducks/revisions";
import { getSubcategorySlug } from "common/ducks/categories";
import { setBannerError } from "error";
import { getProjectUrl } from "project/utils";
import { FETCH_SINGLE_SUCCESS } from "single_entry/ducks";

/* INITIAL STATES
================================================================================================ */
export const initialEntriesState = {
  isFetching: false,
  isError: null,
  entriesById: {},
  revisionsById: {},
  commentsByRevision: {},
  visibleEntries: [],
  itemsCount: 0,
  pageSize: 0,
  pageCount: 0,
  currentPage: 0,
  nextPage: null,
  prevPage: null,
  currentSelectedEntry: null,
  currentSelectedRevision: null,
  showDeleteModal: false,
  showReinstateModal: false,
  scoredRevision: null,
  feedbackSent: false,
  username: null,
};

export const singleEntryInitialState = {
  scaleLevel: 4,
  zoom: 1,
  maxScale: ENTRY_SCALE_LEVELS,
  currentPage: 0,
};

/* HELPERS
================================================================================================ */
export const DISMISS_FEEDBACK = "DISMISS_FEEDBACK";
export const dismissFeedback = () => ({ type: DISMISS_FEEDBACK });
export const getProjectEntriesState = (state) =>
  state.project.projectPage.entries;
export const getProjectEntries = (state) =>
  getProjectEntriesState(state).entriesById;
export const getEntry = (state, id) =>
  getProjectEntriesState(state).entriesById[id];
export const getEntryModalState = (state) =>
  state.project.projectPage.entryModal;
export const getOffset = ({ currentPage }) => calculatePageOffset(currentPage);

const insertEntries = (entry, entries, revisions) => {
  const newEntry = {};

  Object.entries(entry).forEach((e) => {
    const [k, v] = e;
    if (k === "revisions") {
      if (v.length) {
        const entryRevisions = [];

        v.forEach((rev) => {
          entryRevisions.push(rev.id);
          revisions[rev.id] = rev;
        });

        if (entryRevisions.length > 1) {
          newEntry.hasMultiple = true;
        }

        entryRevisions.sort((a, b) => a < b);
        newEntry.revisions = entryRevisions;
      } else {
        newEntry.revisions = [];
      }
    } else {
      newEntry[k] = v;
    }
  });

  entries[newEntry.id] = newEntry;
};

/* Get project ranked tips, sorted by value */
export const getRankedTips = (state) => {
  const project = getProject(state);
  return sortBy(project.package_tips || [], (t) => -t.feature.value);
};

/* REDUCERS
================================================================================================ */
export default function entriesReducer(state = initialEntriesState, action) {
  let data;
  let entries;
  let entry;
  let revisions;
  let visibleEntries;

  switch (action.type) {
    // Entries
    case FETCH_SINGLE_SUCCESS:
      entries = {};
      revisions = {};
      insertEntries(action.payload, entries, revisions);

      return {
        ...state,
        entriesById: { ...state.entriesById, ...entries },
        revisionsById: { ...state.revisionsById, ...revisions },
      };
    case commonImports.TOGGLE_ENTRY_DELETE:
      return {
        ...state,
        showDeleteModal: !state.showDeleteModal,
        currentSelectedEntry: action.payload,
      };
    case commonImports.TOGGLE_ENTRY_REINSTATE:
      return {
        ...state,
        showReinstateModal: !state.showReinstateModal,
        currentSelectedEntry: action.payload,
      };
    case commonImports.SCORE_REVISION:
      return {
        ...state,
        scoredRevision: action.payload.id,
        feedbackSent: false,
      };

    case DISMISS_FEEDBACK:
      return {
        ...state,
        scoredRevision: null,
        feedbackSent: false,
      };
    case commonImports.REVISION_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedbackSent: true,
      };

    // Revisions
    case commonImports.SCORE_REVISION_SUCCESS:
    case commonImports.SCORE_REVISION_FAILURE:
    case commonImports.FETCH_COMMENTS:
    case commonImports.FETCH_COMMENTS_SUCCESS:
    case commonImports.FETCH_COMMENTS_FAILURE:
    case commonImports.ADD_COMMENT:
    case commonImports.ADD_COMMENT_SUCCESS:
    case commonImports.ADD_COMMENT_FAILURE:
    case commonImports.ADD_COMMENT_REPLY:
    case commonImports.ADD_COMMENT_REPLY_SUCCESS:
    case commonImports.ADD_COMMENT_REPLY_FAILURE:
      return revisionsReducer(state, action);
    default:
      return state;
  }
}

export const singleEntryReducer = (state = singleEntryInitialState, action) => {
  switch (action.type) {
    case commonImports.ENTRY_ZOOM_IN:
      if (state.scaleLevel < state.maxScale) {
        const newScale = state.scaleLevel + 1;
        const zoom = newScale * 0.25;
        return {
          ...state,
          zoom,
          scaleLevel: newScale,
        };
      }
      return state;
    case commonImports.ENTRY_ZOOM_OUT:
      const newScale = state.scaleLevel - 1;
      if (Math.max(newScale, 0)) {
        const zoom = newScale * 0.25;
        return {
          ...state,
          zoom,
          scaleLevel: newScale,
        };
      }
      return state;
    case commonImports.ENTRY_RESET_ZOOM:
      const defaultState = { ...singleEntryInitialState };

      return {
        ...state,
        scaleLevel: defaultState.scaleLevel,
        zoom: defaultState.zoom,
      };
    default:
      return state;
  }
};

/* ACTION CREATORS
================================================================================================ */
export const zoomInEntry = () => ({
  type: commonImports.ENTRY_ZOOM_IN,
});

export const zoomOutEntry = () => ({
  type: commonImports.ENTRY_ZOOM_OUT,
});

export const resetZoom = () => ({
  type: commonImports.ENTRY_RESET_ZOOM,
});

export const deleteEntry = (projectId) => (dispatch, getState) => {
  const state = getState();
  const userId = idx(state, (_) => _.user.profile_data.id);
  const entryId = getProjectEntriesState(state).currentSelectedEntry;
  const entry = getEntry(state, entryId);
  const isAuthor = entry.author === userId;
  const formData = isAuthor ? { withdrawn: true } : { eliminated: true };

  return request
    .patch(`/api/v1/projects/${projectId}/entries/${entryId}/`, { ...formData })
    .then(({ data }) => {
      /* go to entries or your-entries page, after giving Elastic Search 2s to reflect changes */
      const append = isAuthor ? "your-entries" : "entries";
      const project = getProject(state);
      const subcategory = getSubcategorySlug(
        state.categories,
        project.sub_category
      );
      setTimeout(() => {
        window.location = getProjectUrl(project, subcategory, append);
      }, 2000);
    })
    .catch((error) =>
      dispatch(
        setBannerError(
          `Error ${isAuthor ? "withdrawing" : "eliminating"} entry`,
          "Please try again"
        )
      )
    );
};

export const reinstateEntry = (projectId) => (dispatch, getState) => {
  const state = getState();
  const userId = idx(state, (_) => _.user.profile_data.id);
  const entryId = getProjectEntriesState(state).currentSelectedEntry;
  const entry = getEntry(state, entryId);
  const isAuthor = entry.author === userId;
  const formData = isAuthor ? { withdrawn: false } : { eliminated: false };

  return request
    .patch(`/api/v1/projects/${projectId}/entries/${entryId}/`, { ...formData })
    .then(({ data }) => {
      const append = isAuthor ? "your-entries" : "entries";
      /* go to entries or your-entries page, after giving Elastic Search 2s to reflect changes */
      const project = getProject(state);
      const subcategory = getSubcategorySlug(
        state.categories,
        project.sub_category
      );
      setTimeout(() => {
        window.location = getProjectUrl(project, subcategory, append);
      }, 2000);
    })
    .catch((error) =>
      dispatch(
        setBannerError(
          `Error ${isAuthor ? "un-withdrawing" : "un-eliminating"} entry`,
          "Please try again"
        )
      )
    );
};

export const toggleEntryDelete = (entryId) => ({
  type: commonImports.TOGGLE_ENTRY_DELETE,
  payload: entryId,
});

export const toggleEntryReinstate = (entryId) => ({
  type: commonImports.TOGGLE_ENTRY_REINSTATE,
  payload: entryId,
});
