import axios from "axios";
import PropTypes from "prop-types";
import { FETCH_SUCCESS } from "project/ducks/common";
import { FETCH_SINGLE_SUCCESS } from "admin/ducks/projects";

/* ACTIONS
================================================================================================ */
const FETCH_ATTACHMENTS = "crowdspring/pap/FETCH_ATTACHMENTS";
const FETCH_ATTACHMENTS_SUCCESS = "crowdspring/pap/FETCH_ATTACHMENTS_SUCCESS";

/* HELPERS
================================================================================================ */
export const createProjectRequest = (data) =>
  axios.post("/api/v1/projects/", data);

export const updateProjectRequest = (projectID, data) =>
  axios.patch(`/api/v1/projects/${projectID}/`, data);

export const updateBriefRequest = (projectID, data) => {
  return axios.post(`/api/v1/projects/${projectID}/create_brief_update/`, data);
};

/* Create attachment */
const createAttachmentRequest = (projectId, data) => {
  return axios.post(`/api/v1/projects/${projectId}/attachments/`, data);
};

/* Delete attachment */
const deleteAttachmentRequest = (projectId, id, hideOnly) =>
  axios.delete(
    `/api/v1/projects/${projectId}/attachments/${id}/${
      hideOnly ? "?hide_only=true" : ""
    }`
  );

/* Utility function to update the list of project attachments */
export const updateAttachments = (projectId, currentAttachments, formData) => {
  const updatedAttachments = formData["file_upload"] || [];
  const promises = [];

  /* attachments to be deleted */
  currentAttachments.map((att) => {
    if (!updatedAttachments.find((att2) => att2.id == att.id)) {
      promises.push(deleteAttachmentRequest(projectId, att.id));
    }
  });

  /* attachments to be hidden */
  updatedAttachments
    .filter((att) => att.id && att.hidden)
    .forEach((att) => {
      promises.push(deleteAttachmentRequest(projectId, att.id, true));
    });

  /* attachments to be created */
  updatedAttachments.map((att) => {
    if (!att.id) {
      promises.push(createAttachmentRequest(projectId, att));
    }
  });

  return Promise.all(promises);
};

/* INITIAL STATE
================================================================================================ */
const INITIAL_STATE = {
  projectData: null,
  attachments: [],
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_SUCCESS:
    case FETCH_SINGLE_SUCCESS:
      return {
        ...state,
        projectData: action.payload,
      };
    case FETCH_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        attachments: action.payload,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */

/* Fetch attachments */
export const fetchAttachments = (projectId) => (dispatch) =>
  projectId
    ? dispatch({
        type: FETCH_ATTACHMENTS,
        promise: axios.get(`/api/v1/projects/${projectId}/attachments/`),
      })
    : Promise.resolve();
