import request from "axios";
import reduce from "lodash/reduce";
import some from "lodash/some";
import uniqBy from "lodash/uniqBy";
import pick from "lodash/pick";
import moment from "moment";
import queryString from "query-string";
import { createAction } from "redux-actions";
import { updateSubcatWatchlists } from "common/ducks/user";
import { getWatchableCategories } from "common/ducks/categories";
import { fetchSettings as fetchNotificationSettings } from "account/ducks/notification_settings";
import { setBannerError } from "error";
import {
  UNVERIFIED_RESOURCE_NAME,
  CLIENTS_RESOURCE_NAME,
  CREATIVES_RESOURCE_NAME,
  WAITERS_RESOURCE_NAME,
  USER_PROJECTS_RESOURCE_NAME,
  USER_PAYMENTS_RESOURCE_NAME,
  USER_PAYMENTS_PAGE_SIZE,
  CREATIVE_PROJECTS_PAGE_SIZE,
  WAITER_INVITES_RESOURCE_NAME,
  TESTIMONIALS_RESOURCE_NAME,
} from "admin/constants";
import { getAPIQueryString, getItemsFromString } from "common/utils";
import { savePageCount } from "common/pager/ducks";
import { formatStats } from "admin/ducks/common";
import { ROTATE_IMG_SUCCESS } from "admin/ducks/verification";

/* ACTIONS
================================================================================================ */
const FETCH_UNVERIFIED = "admin/FETCH_UNVERIFIED";
const FETCH_UNVERIFIED_REQUEST = "admin/FETCH_UNVERIFIED_REQUEST";
const FETCH_UNVERIFIED_SUCCESS = "admin/FETCH_UNVERIFIED_SUCCESS";

const FETCH_UNVERIFIED_STATS = "admin/FETCH_UNVERIFIED_STATS";
const FETCH_UNVERIFIED_STATS_SUCCESS = "admin/FETCH_UNVERIFIED_STATS_SUCCESS";

const FETCH_CLIENTS = "admin/FETCH_CLIENTS";
const FETCH_CLIENTS_REQUEST = "admin/FETCH_CLIENTS_REQUEST";
const FETCH_CLIENTS_SUCCESS = "admin/FETCH_CLIENTS_SUCCESS";

const FETCH_CLIENT_STATS = "admin/FETCH_CLIENT_STATS";
const FETCH_CLIENT_STATS_SUCCESS = "admin/FETCH_CLIENT_STATS_SUCCESS";

const FETCH_CREATIVES = "admin/FETCH_CREATIVES";
const FETCH_CREATIVES_REQUEST = "admin/FETCH_CREATIVES_REQUEST";
const FETCH_CREATIVES_SUCCESS = "admin/FETCH_CREATIVES_SUCCESS";

const FETCH_CREATIVE_STATS = "admin/FETCH_CREATIVE_STATS";
const FETCH_CREATIVE_STATS_SUCCESS = "admin/FETCH_CREATIVE_STATS_SUCCESS";

const FETCH_WAITERS = "admin/FETCH_WAITERS";
const FETCH_WAITERS_REQUEST = "admin/FETCH_WAITERS_REQUEST";
const FETCH_WAITERS_SUCCESS = "admin/FETCH_WAITERS_SUCCESS";

const FETCH_WAITER_STATS = "admin/FETCH_WAITER_STATS";
const FETCH_WAITER_STATS_SUCCESS = "admin/FETCH_WAITER_STATS_SUCCESS";

const FETCH_SINGLE = "admin/FETCH_SINGLE_PEOPLE";
const FETCH_SINGLE_REQUEST = "admin/FETCH_SINGLE_PEOPLE_REQUEST";
const FETCH_SINGLE_SUCCESS = "admin/FETCH_SINGLE_PEOPLE_SUCCESS";

const FETCH_IMPERSONATE_SUCCESS = "admin/FETCH_IMPERSONATE_PEOPLE_SUCCESS";

const FETCH_PAYMENT_METHOD = "admin/FETCH_PAYMENT_METHOD";
const FETCH_PAYMENT_METHOD_SUCCESS = "admin/FETCH_PAYMENT_METHOD_SUCCESS";

const FETCH_OWNED_PROJECTS = "admin/FETCH_OWNED_PROJECTS";
const FETCH_OWNED_PROJECTS_SUCCESS = "admin/FETCH_OWNED_PROJECTS_SUCCESS";

const FETCH_PAYMENTS = "admin/FETCH_USER_PAYMENTS";
const FETCH_PAYMENTS_REQUEST = "admin/FETCH_USER_PAYMENTS_REQUEST";
const FETCH_PAYMENTS_SUCCESS = "admin/FETCH_USER_PAYMENTS_SUCCESS";

const UPDATE_USER = "admin/UPDATE_USER";
const UPDATE_USER_SUCCESS = "admin/UPDATE_USER_SUCCESS";

const FETCH_RATINGS_RECEIVED = "admin/FETCH_RATINGS_RECEIVED";
const FETCH_RATINGS_RECEIVED_SUCCESS = "admin/FETCH_RATINGS_RECEIVED_SUCCESS";

const WATCH_SUB_CATEGORY = "admin/WATCH_SUB_CATEGORY";
const UNWATCH_SUB_CATEGORY = "admin/UNWATCH_SUB_CATEGORY";
const WATCH_SUB_CATEGORY_ONSITE = "admin/WATCH_SUB_CATEGORY_ONSITE";
const UNWATCH_SUB_CATEGORY_ONSITE = "admin/UNWATCH_SUB_CATEGORY_ONSITE";

const FETCH_PENDING_AWARDS = "admin/FETCH_PENDING_AWARDS";
const FETCH_PENDING_AWARDS_SUCCESS = "admin/FETCH_PENDING_AWARDS_SUCCESS";

const FETCH_PAYOUT_ACCOUNTS = "admin/FETCH_PAYOUT_ACCOUNTS";
const FETCH_PAYOUT_ACCOUNTS_SUCCESS = "admin/FETCH_PAYOUT_ACCOUNTS_SUCCESS";

const FETCH_PARTICIPATED_PROJECTS = "admin/FETCH_PARTICIPATED_PROJECTS";
const FETCH_PARTICIPATED_PROJECTS_REQUEST =
  "admin/FETCH_PARTICIPATED_PROJECTS_REQUEST";
const FETCH_PARTICIPATED_PROJECTS_SUCCESS =
  "admin/FETCH_PARTICIPATED_PROJECTS_SUCCESS";

const FETCH_TESTIMONIALS = "admin/FETCH_TESTIMONIALS";
const FETCH_TESTIMONIALS_REQUEST = "admin/FETCH_TESTIMONIALS_REQUEST";
const FETCH_TESTIMONIALS_SUCCESS = "admin/FETCH_TESTIMONIALS_SUCCESS";

const DELETE_TESTIMONIAL = "admin/DELETE_TESTIMONIAL";

const UPDATE_HISTORY_PAYMENTS_PAGE = "admin/UPDATE_HISTORY_PAYMENTS_PAGE";

const FETCH_REQUESTED_PAYMENTS = "admin/FETCH_REQUESTED_PAYMENTS";
const FETCH_REQUESTED_PAYMENTS_SUCCESS =
  "admin/FETCH_REQUESTED_PAYMENTS_SUCCESS";

const FETCH_HISTORY_PAYMENTS_PAGE = "admin/FETCH_HISTORY_PAYMENTS_PAGE";
const FETCH_HISTORY_PAYMENTS_PAGE_SUCCESS =
  "admin/FETCH_HISTORY_PAYMENTS_PAGE_SUCCESS";

const FETCH_REPUTATION_DATAPOINTS = "admin/FETCH_REPUTATION_DATAPOINTS";
const FETCH_REPUTATION_DATAPOINTS_SUCCESS =
  "admin/FETCH_REPUTATION_DATAPOINTS_SUCCESS";

const FETCH_SUBCAT_REPUTATION = "admin/FETCH_SUBCAT_REPUTATION";
const FETCH_SUBCAT_REPUTATION_SUCCESS = "admin/FETCH_SUBCAT_REPUTATION_SUCCESS";

const FETCH_LAST_WAITERS_INVITATION = "admin/FETCH_LAST_WAITERS_INVITATION";
const FETCH_LAST_WAITERS_INVITATION_SUCCESS =
  "admin/FETCH_LAST_WAITERS_INVITATION_SUCCESS";

const FETCH_WAITERS_TO_INVITE_COUNT = "admin/FETCH_WAITERS_TO_INVITE_COUNT";
const FETCH_WAITERS_TO_INVITE_COUNT_REQUEST =
  "admin/FETCH_WAITERS_TO_INVITE_COUNT_REQUEST";
const FETCH_WAITERS_TO_INVITE_COUNT_SUCCESS =
  "admin/FETCH_WAITERS_TO_INVITE_COUNT_SUCCESS";

const FETCH_WAITER_INVITES = "admin/FETCH_WAITER_INVITES";
const FETCH_WAITER_INVITES_REQUEST = "admin/FETCH_WAITER_INVITES_REQUEST";
const FETCH_WAITER_INVITES_SUCCESS = "admin/FETCH_WAITER_INVITES_SUCCESS";

const FETCH_EMAIL_DELIVERABILITY = "admin/FETCH_EMAIL_DELIVERABILITY";
const FETCH_EMAIL_DELIVERABILITY_REQUEST =
  "admin/FETCH_EMAIL_DELIVERABILITY_REQUEST";
const FETCH_EMAIL_DELIVERABILITY_SUCCESS =
  "admin/FETCH_EMAIL_DELIVERABILITY_SUCCESS";

const FETCH_CHAT_EXCLUDED_COUNTRIES = "admin/FETCH_CHAT_EXCLUDED_COUNTRIES";
const FETCH_CHAT_EXCLUDED_COUNTRIES_SUCCESS =
  "admin/FETCH_CHAT_EXCLUDED_COUNTRIES_SUCCESS";

const SET_SELECTED_USERS = "admin/SET_SELECTED_USERS";

export const impersonateSuccess = createAction(FETCH_IMPERSONATE_SUCCESS);

/* HELPERS
================================================================================================ */
const HISTORY_PAYMENTS_PAGE_SIZE = 5;

const watchSubcategoryRequest = ({ userId, subcatId, frequency, dispatch }) => {
  return request
    .patch(`/api/v1/radmin/users/${userId}/subcat_watch_${frequency}/`, {
      subcategory: subcatId,
    })
    .catch(() =>
      dispatch(
        setBannerError("Error watching sub-category", "Please try again.")
      )
    );
};

const unwatchSubcategoryRequest = ({ userId, subcatId, dispatch }) => {
  return request
    .patch(`/api/v1/radmin/users/${userId}/subcat_unwatch_email/`, {
      subcategory: subcatId,
    })
    .catch(() =>
      dispatch(
        setBannerError("Error unwatching sub-category", "Please try again.")
      )
    );
};

const watchSubcategoryOnsiteRequest = ({ userId, subcatId, dispatch }) => {
  return request
    .patch(`/api/v1/radmin/users/${userId}/subcat_watch_onsite/`, {
      subcategory: subcatId,
    })
    .catch(() =>
      dispatch(
        setBannerError(
          "Error watching sub-category onsite",
          "Please try again."
        )
      )
    );
};

const unwatchSubcategoryOnsiteRequest = ({ userId, subcatId, dispatch }) => {
  return request
    .patch(`/api/v1/radmin/users/${userId}/subcat_unwatch_onsite/`, {
      subcategory: subcatId,
    })
    .catch(() =>
      dispatch(
        setBannerError(
          "Error unwatching sub-category onsite",
          "Please try again."
        )
      )
    );
};

export const inviteWaitersRequest = (data) =>
  request.put(
    "/api/v1/creative-registration-waiters/invite_to_register/",
    data
  );

const updateSegments = (user, formData) => {
  const segments = [formData["segment"]].concat(
    formData.is_product_entrepreneur === "yes" ? "product_entrepreneur" : []
  );

  return request.patch(`/api/v1/users/${user.id}/marketing_segments/`, {
    segments,
  });
};

export const deleteWaitersRequest = (ids) => {
  return request.post(
    "/api/v1/creative-registration-waiters/batch_delete/",
    ids
  );
};

/* INITIAL STATES
================================================================================================ */
export const initialState = {
  isFetching: false,
  isError: false,
  currentUser: null,
  tokens: {},
  unverified: [],
  unverifiedCount: 0,
  unverifiedStats: {},
  clients: [],
  clientsCount: 0,
  clientStats: {},
  creatives: [],
  creativesCount: 0,
  creativeStats: {},
  waiters: [],
  waitersCount: 0,
  waiterStats: {},
  paymentMethod: null,
  ownedProjects: [],
  ownedProjectsCount: 0,
  isFetchingPayments: false,
  payments: [],
  paymentsCount: 0,
  ratingsReceived: [],
  pendingAwards: [],
  payoutAccounts: [],
  isFetchingProjects: false,
  participatedProjects: [],
  participatedProjectsCount: 0,
  testimonials: [],
  historyPayments: {
    pages: [],
    currentPage: 1,
    pageCount: 0,
  },
  requestedPayments: [],
  subcatReputation: [],
  lastWaitersInvitation: null,
  waitersToInviteCount: 0,
  fetchingWaitersToInviteCount: false,
  isFetchingWaiterInvites: false,
  waiterInvites: [],
  waiterInvitesCount: 0,
  emailDeliveryStatus: "unknown",
  chatExcludedCountries: [],
  isFetchingTestimonials: false,
  testimonialCount: 0,
  selectedUsers: [],
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_UNVERIFIED_REQUEST:
      return {
        ...state,
        unverifiedQueryStr: action.queryStr,
        isFetching: true,
      };
    case FETCH_UNVERIFIED_SUCCESS:
      /* avoid wrong results due to parallel requests */
      if (action.queryStr !== state.unverifiedQueryStr) {
        return state;
      }

      return {
        ...state,
        isFetching: false,
        unverified: action.payload.results,
        unverifiedCount: action.payload.count,
      };
    case FETCH_UNVERIFIED_STATS_SUCCESS:
      return {
        ...state,
        unverifiedStats: formatStats(action.payload),
      };
    case FETCH_CLIENT_STATS_SUCCESS:
      return {
        ...state,
        clientStats: formatStats(action.payload),
      };
    case FETCH_CLIENTS_REQUEST:
      return {
        ...state,
        clientsQueryStr: action.queryStr,
        isFetching: true,
      };
    case FETCH_CLIENTS_SUCCESS:
      /* avoid wrong results due to parallel requests */
      if (action.queryStr !== state.clientsQueryStr) {
        return state;
      }

      return {
        ...state,
        isFetching: false,
        clients: action.payload.results,
        clientsCount: action.payload.count,
      };
    case FETCH_CREATIVE_STATS_SUCCESS:
      return {
        ...state,
        creativeStats: formatStats(action.payload),
      };
    case FETCH_CREATIVES_REQUEST:
      return {
        ...state,
        creativesQueryStr: action.queryStr,
        isFetching: true,
      };
    case FETCH_CREATIVES_SUCCESS:
      /* avoid wrong results due to parallel requests */
      if (action.queryStr !== state.creativesQueryStr) {
        return state;
      }

      return {
        ...state,
        isFetching: false,
        creatives: action.payload.results,
        creativesCount: action.payload.count,
      };
    case FETCH_WAITER_STATS_SUCCESS:
      return {
        ...state,
        waiterStats: action.payload,
      };
    case FETCH_WAITERS_REQUEST:
      return {
        ...state,
        waitersQueryStr: action.queryStr,
        isFetching: true,
      };
    case FETCH_WAITERS_SUCCESS:
      /* avoid wrong results due to parallel requests */
      if (action.queryStr !== state.waitersQueryStr) {
        return state;
      }

      return {
        ...state,
        isFetching: false,
        waiters: action.payload.results,
        waitersCount: action.payload.count,
      };
    case FETCH_OWNED_PROJECTS_SUCCESS:
      return {
        ...state,
        ownedProjects: action.payload.results,
        ownedProjectsCount: action.payload.count,
      };
    case FETCH_PAYMENTS_REQUEST:
      return {
        ...state,
        payments: [],
        paymentsCount: 0,
        isFetchingPayments: true,
      };
    case FETCH_PAYMENTS_SUCCESS:
      return {
        ...state,
        payments: action.payload.results,
        paymentsCount: action.payload.count,
        isFetchingPayments: false,
      };
    case FETCH_SINGLE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_SINGLE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        currentUser: action.payload,
      };
    case FETCH_IMPERSONATE_SUCCESS: {
      const tokens = { ...state.tokens };
      tokens[action.payload.user_id] = action.payload.impersonation_token;

      return {
        ...state,
        tokens,
      };
    }
    case FETCH_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        paymentMethod: action.payload.find((m) => m.default) || null,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
      };
    case FETCH_RATINGS_RECEIVED_SUCCESS:
      return {
        ...state,
        ratingsReceived: action.payload,
      };
    case WATCH_SUB_CATEGORY:
      return {
        ...state,
        currentUser: updateSubcatWatchlists(
          false,
          "watch",
          { ...state.currentUser },
          action.payload.subcatId,
          action.payload.frequency
        ),
      };
    case UNWATCH_SUB_CATEGORY:
      return {
        ...state,
        currentUser: updateSubcatWatchlists(
          false,
          "unwatch",
          { ...state.currentUser },
          action.payload.subcatId
        ),
      };
    case WATCH_SUB_CATEGORY_ONSITE:
      return {
        ...state,
        currentUser: updateSubcatWatchlists(
          true,
          "watch",
          { ...state.currentUser },
          action.payload.subcatId
        ),
      };
    case UNWATCH_SUB_CATEGORY_ONSITE:
      return {
        ...state,
        currentUser: updateSubcatWatchlists(
          true,
          "unwatch",
          { ...state.currentUser },
          action.payload.subcatId
        ),
      };
    case FETCH_PENDING_AWARDS_SUCCESS:
      return {
        ...state,
        pendingAwards: action.payload,
      };
    case FETCH_PAYOUT_ACCOUNTS_SUCCESS:
      return {
        ...state,
        payoutAccounts: action.payload,
      };
    case FETCH_PARTICIPATED_PROJECTS_REQUEST:
      return {
        ...state,
        isFetchingProjects: true,
      };
    case FETCH_PARTICIPATED_PROJECTS_SUCCESS:
      return {
        ...state,
        isFetchingProjects: false,
        participatedProjects: action.payload.results,
        participatedProjectsCount: action.payload.count,
      };
    case FETCH_TESTIMONIALS_REQUEST:
      return {
        ...state,
        isFetchingTestimonials: true,
      };
    case FETCH_TESTIMONIALS_SUCCESS:
      return {
        ...state,
        isFetchingTestimonials: false,
        testimonials: action.payload.results,
        testimonialCount: action.payload.count,
      };
    case FETCH_REQUESTED_PAYMENTS_SUCCESS:
      return {
        ...state,
        requestedPayments: action.payload,
      };
    case FETCH_HISTORY_PAYMENTS_PAGE_SUCCESS: {
      const { historyPayments } = state;
      const pages = [...historyPayments.pages];
      pages[historyPayments.currentPage - 1] = action.payload.results;

      return {
        ...state,
        historyPayments: {
          ...historyPayments,
          pages,
          pageCount: Math.ceil(
            action.payload.count / HISTORY_PAYMENTS_PAGE_SIZE
          ),
        },
      };
    }
    case UPDATE_HISTORY_PAYMENTS_PAGE:
      return {
        ...state,
        historyPayments: {
          ...state.historyPayments,
          currentPage: parseInt(action.page),
        },
      };
    case FETCH_REPUTATION_DATAPOINTS_SUCCESS:
      return {
        ...state,
        reputationDatapoints: action.payload,
      };
    case FETCH_SUBCAT_REPUTATION_SUCCESS:
      return {
        ...state,
        subcatReputation: action.payload,
      };
    case FETCH_LAST_WAITERS_INVITATION_SUCCESS:
      return {
        ...state,
        lastWaitersInvitation: action.payload.results[0],
      };
    case FETCH_WAITERS_TO_INVITE_COUNT_REQUEST:
      return {
        ...state,
        fetchingWaitersToInviteCount: true,
      };
    case FETCH_WAITERS_TO_INVITE_COUNT_SUCCESS:
      return {
        ...state,
        waitersToInviteCount: action.payload.count,
        fetchingWaitersToInviteCount: false,
      };
    case FETCH_WAITER_INVITES_REQUEST:
      return {
        ...state,
        isFetchingWaiterInvites: true,
      };
    case FETCH_WAITER_INVITES_SUCCESS:
      return {
        ...state,
        isFetchingWaiterInvites: false,
        waiterInvites: action.payload.results,
        waiterInvitesCount: action.payload.count,
      };
    case FETCH_EMAIL_DELIVERABILITY_REQUEST:
      return {
        ...state,
        emailDeliveryStatus: initialState.emailDeliveryStatus,
      };
    case FETCH_EMAIL_DELIVERABILITY_SUCCESS:
      return {
        ...state,
        emailDeliveryStatus:
          action.payload.status === "blocked" ? "blocked" : "normal",
      };
    case FETCH_CHAT_EXCLUDED_COUNTRIES_SUCCESS:
      return {
        ...state,
        chatExcludedCountries: action.payload,
      };
    case SET_SELECTED_USERS:
      return {
        ...state,
        selectedUsers: uniqBy(action.payload, "id"),
      };
    case ROTATE_IMG_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          creative_verification: {
            ...state.currentUser.creative_verification,
            ...pick(
              action.payload.creative_verification,
              "photo_id",
              "photograph"
            ),
          },
        },
      };
    default:
      return state;
  }
}

export const getUnverifiedQueryString = (query) => {
  let preFilteredStatuses = ["submitted", "waiting_for_creative"];
  /* if no preset is chosen, don't list creatives whose verification is in draft status */
  if (query.preset) {
    preFilteredStatuses = preFilteredStatuses.concat("draft");
  }

  const extraAPIQuery = {
    admin_preset: query.preset,
    is_creative: true,
    is_active: true,
  };

  /* pre-filter certain statuses only if not explicitly filtering by them */
  if (
    !some(Object.keys(query), (k) => k.includes("creative_verification_status"))
  ) {
    extraAPIQuery.creative_verification_status = preFilteredStatuses;
  }

  return getAPIQueryString(UNVERIFIED_RESOURCE_NAME, query, { extraAPIQuery });
};

export const fetchUnverified = (query) => (dispatch) => {
  const queryStr = getUnverifiedQueryString(query);
  const promise = request.get(`/api/v1/users/?${queryStr}`);

  /* save page count in pager state */
  promise.then((res) => dispatch(savePageCount(UNVERIFIED_RESOURCE_NAME, res)));

  return dispatch({
    type: FETCH_UNVERIFIED,
    queryStr,
    promise,
  });
};

export const fetchUnverifiedStats = () => (dispatch) => {
  return dispatch({
    type: FETCH_UNVERIFIED_STATS,
    promise: request.get(`/api/v1/users/admin_stats_unverified/`),
  });
};

export const fetchClientStats = () => (dispatch) => {
  return dispatch({
    type: FETCH_CLIENT_STATS,
    promise: request.get(`/api/v1/users/admin_stats_client/`),
  });
};

export const fetchClients = (query) => (dispatch) => {
  const extraAPIQuery = {
    admin_preset: query.preset,
    is_valid_client: true,
  };
  const queryStr = getAPIQueryString(CLIENTS_RESOURCE_NAME, query, {
    extraAPIQuery,
  });
  const promise = request.get(`/api/v1/users/?${queryStr}`);

  /* save page count in pager state */
  promise.then((res) => dispatch(savePageCount(CLIENTS_RESOURCE_NAME, res)));

  return dispatch({
    type: FETCH_CLIENTS,
    queryStr,
    promise,
  });
};

export const fetchCreativeStats = () => (dispatch) => {
  return dispatch({
    type: FETCH_CREATIVE_STATS,
    promise: request.get(`/api/v1/users/admin_stats_creative/`),
  });
};

export const fetchCreatives = (query) => (dispatch) => {
  const extraAPIQuery = {
    admin_preset: query.preset,
    is_creative: true,
  };
  const queryStr = getAPIQueryString(CREATIVES_RESOURCE_NAME, query, {
    extraAPIQuery,
  });
  const promise = request.get(`/api/v1/users/?${queryStr}`);

  /* save page count in pager state */
  promise.then((res) => dispatch(savePageCount(CREATIVES_RESOURCE_NAME, res)));

  return dispatch({
    type: FETCH_CREATIVES,
    queryStr,
    promise,
  });
};

export const fetchWaiterStats = () => (dispatch) => {
  return dispatch({
    type: FETCH_WAITER_STATS,
    promise: request.get(`/api/v1/creative-registration-waiters/admin_stats/`),
  });
};

export const fetchWaiters = (query) => (dispatch) => {
  const extraAPIQuery = {
    admin_preset: query.preset,
  };
  const queryStr = getAPIQueryString(WAITERS_RESOURCE_NAME, query, {
    extraAPIQuery,
  });
  const promise = request.get(
    `/api/v1/creative-registration-waiters/?${queryStr}`
  );

  /* save page count in pager state */
  promise.then((res) => dispatch(savePageCount(WAITERS_RESOURCE_NAME, res)));

  return dispatch({
    type: FETCH_WAITERS,
    queryStr,
    promise,
  });
};

export const fetchUser = (id) => (dispatch) =>
  dispatch({
    type: FETCH_SINGLE,
    promise: request.get(`/api/v1/users/${id}/`),
  });

export const impersonate = (user_id) => (dispatch) =>
  request
    .post("/api/v1/impersonate/", { user_id })
    .then(({ data }) => dispatch(impersonateSuccess({ ...data, user_id })));

export const individualAdminActions =
  (id, action, fields, method = "patch") =>
  (dispatch, getState) => {
    const { people } = getState().admin;

    let data = fields;
    let url = `/api/v1/radmin/users/${id}/${action}/`;

    if (action === "suspend" && fields.suspension_duration) {
      data = {
        ...fields,
        reactivate_at: moment()
          .add(fields.suspension_duration, "days")
          .format(),
      };
    } else if (action === "change_billing_info") {
      url = `/api/v1/users/${id}/`;
    } else if (action === "delete_payment_method") {
      url = `/api/v1/users/${id}/payment-methods/delete/`;
      data = {
        id: people.paymentMethod.id,
      };
    }

    let promise;

    if (action === "change_client_segments") {
      promise = updateSegments(people.currentUser, data).then(() =>
        dispatch(fetchUser(id))
      );
    } else {
      promise = request[method](url, data).then(() => {
        dispatch(fetchUser(id));

        if (action === "delete_payment_method") {
          dispatch(fetchPaymentMethod(id));
        }

        if (["correct_reputation", "recalculate_reputation"].includes(action)) {
          dispatch(fetchReputationDatapoints(id));
        }
      });
    }

    promise.catch((err) => {
      const errorMsg = err.data ? JSON.stringify(err.data) : "Unknown error.";
      dispatch(setBannerError("Error performing action", errorMsg));
    });

    return promise;
  };

export const peopleAdminActions =
  (id, action, fields, method = "patch", onBulkActionSuccess) =>
  (dispatch, getState) => {
    if (action.startsWith("bulk_")) {
      const { selectedUsers } = getState().admin.people;
      const individualAction = action.split("bulk_")[1];

      const promise = Promise.all(
        selectedUsers.map((u) =>
          request[method](
            `/api/v1/radmin/users/${u.id}/${individualAction}/`,
            fields
          )
        )
      );

      promise
        .then(() => {
          dispatch(setSelectedUsers([]));

          if (onBulkActionSuccess) {
            dispatch(onBulkActionSuccess());
          }
        })
        .catch(() =>
          dispatch(
            setBannerError("Error performing action", "Please try again.")
          )
        );

      return promise;
    } else if (action === "delete_braintree_customers") {
      fields["customer_ids"] = getItemsFromString(fields["customer_ids"], "\n");
    }

    return request[method](`/api/v1/radmin/users/${action}/`, fields);
  };

export const fetchPaymentMethod = (userId) => (dispatch) => {
  return dispatch({
    type: FETCH_PAYMENT_METHOD,
    promise: request.get(`/api/v1/users/${userId}/payment-methods/`),
  });
};

export const fetchOwnedProjects = (userId, tab, query) => (dispatch) => {
  const STATUS_BY_TAB = {
    drafts: ["draft", "1_1_negotiating", "1_1_ready"],
    active: ["open", "closed", "finalist_round", "finalist_closed"],
    wrapup: ["awarded"],
    completed: ["completed", "cancelled"],
  };

  const extraAPIQuery = {
    status: STATUS_BY_TAB[tab],
  };
  const queryStr = getAPIQueryString(USER_PROJECTS_RESOURCE_NAME, query, {
    extraAPIQuery,
  });
  const promise = request.get(
    `/api/v1/users/${userId}/projects_as_client/?${queryStr}`
  );

  /* save page count in pager state */
  promise.then((res) =>
    dispatch(savePageCount(USER_PROJECTS_RESOURCE_NAME, res))
  );

  return dispatch({
    type: FETCH_OWNED_PROJECTS,
    promise,
  });
};

export const fetchPayments = (userId, tab, query) => (dispatch) => {
  const extraAPIQuery = {
    status: tab === "refunded" ? "refunded" : undefined,
    customer: userId,
  };
  const queryStr = getAPIQueryString(USER_PAYMENTS_RESOURCE_NAME, query, {
    pageSize: USER_PAYMENTS_PAGE_SIZE,
    extraAPIQuery,
  });
  const promise = request.get(`/api/v1/payments/?${queryStr}`);

  /* save page count in pager state */
  promise.then((res) =>
    dispatch(savePageCount(USER_PAYMENTS_RESOURCE_NAME, res))
  );

  return dispatch({
    type: FETCH_PAYMENTS,
    promise,
  });
};

export const updateUser = (id, data) => (dispatch) => {
  const promise = request.patch(`/api/v1/users/${id}/`, data);

  dispatch({
    type: UPDATE_USER,
    promise,
  });

  promise.catch(() =>
    dispatch(setBannerError("Error updating user", "Please try again."))
  );

  return promise;
};

export const fetchRatingsReceived = (userId) => (dispatch) => {
  return dispatch({
    type: FETCH_RATINGS_RECEIVED,
    promise: request.get(`/api/v1/users/${userId}/ratings_received/`),
  });
};

export const unwatchAllCategories = () => (dispatch, getState) => {
  const state = getState();
  const userId = state.admin.people.currentUser.id;
  const cats = getWatchableCategories(state.categories.data);
  cats.forEach((cat) => {
    cat.subcategories.forEach((subcat) => {
      dispatch({
        type: UNWATCH_SUB_CATEGORY,
        payload: { subcatId: subcat.id },
      });
    });
  });

  return request
    .patch(`/api/v1/radmin/users/${userId}/subcat_unwatch_email_all/`)
    .catch(() =>
      dispatch(
        setBannerError("Error unwatching all categories", "Please try again.")
      )
    );
};

export const watchSubcategory =
  (subcatId, frequency) => (dispatch, getState) => {
    const state = getState();
    const userId = state.admin.people.currentUser.id;

    dispatch({
      type: WATCH_SUB_CATEGORY,
      payload: { subcatId, frequency },
    });

    return watchSubcategoryRequest({ userId, subcatId, frequency, dispatch });
  };

export const unwatchSubcategory = (subcatId) => (dispatch, getState) => {
  const state = getState();
  const userId = state.admin.people.currentUser.id;

  dispatch({
    type: UNWATCH_SUB_CATEGORY,
    payload: { subcatId },
  });

  return unwatchSubcategoryRequest({ userId, subcatId, dispatch });
};

export const watchCategory = (cat, frequency) => (dispatch, getState) => {
  const state = getState();
  const userId = state.admin.people.currentUser.id;

  reduce(
    cat.subcategories,
    (acc, subcat) => {
      dispatch({
        type: WATCH_SUB_CATEGORY,
        payload: { subcatId: subcat.id, frequency },
      });

      /* make requests sequentially to avoid race conditions */
      return acc.then(() =>
        watchSubcategoryRequest({
          userId,
          subcatId: subcat.id,
          frequency,
          dispatch,
        })
      );
    },
    Promise.resolve()
  );
};

export const unwatchCategory = (cat) => (dispatch, getState) => {
  const state = getState();
  const userId = state.admin.people.currentUser.id;

  reduce(
    cat.subcategories,
    (acc, subcat) => {
      dispatch({
        type: UNWATCH_SUB_CATEGORY,
        payload: { subcatId: subcat.id },
      });

      /* make requests sequentially to avoid race conditions */
      return acc.then(() =>
        unwatchSubcategoryRequest({ userId, subcatId: subcat.id, dispatch })
      );
    },
    Promise.resolve()
  );
};

export const watchSubcategoryOnsite = (subcatId) => (dispatch, getState) => {
  const state = getState();
  const userId = state.admin.people.currentUser.id;

  dispatch({
    type: WATCH_SUB_CATEGORY_ONSITE,
    payload: { subcatId },
  });

  return watchSubcategoryOnsiteRequest({ userId, subcatId, dispatch });
};

export const unwatchSubcategoryOnsite = (subcatId) => (dispatch, getState) => {
  const state = getState();
  const userId = state.admin.people.currentUser.id;

  dispatch({
    type: UNWATCH_SUB_CATEGORY_ONSITE,
    payload: { subcatId },
  });

  return unwatchSubcategoryOnsiteRequest({ userId, subcatId, dispatch });
};

export const watchCategoryOnsite = (cat) => (dispatch, getState) => {
  const state = getState();
  const userId = state.admin.people.currentUser.id;

  reduce(
    cat.subcategories,
    (acc, subcat) => {
      dispatch({
        type: WATCH_SUB_CATEGORY_ONSITE,
        payload: { subcatId: subcat.id },
      });

      /* make requests sequentially to avoid race conditions */
      return acc.then(() =>
        watchSubcategoryOnsiteRequest({ userId, subcatId: subcat.id, dispatch })
      );
    },
    Promise.resolve()
  );
};

export const unwatchCategoryOnsite = (cat) => (dispatch, getState) => {
  const state = getState();
  const userId = state.admin.people.currentUser.id;

  reduce(
    cat.subcategories,
    (acc, subcat) => {
      dispatch({
        type: UNWATCH_SUB_CATEGORY_ONSITE,
        payload: { subcatId: subcat.id },
      });

      /* make requests sequentially to avoid race conditions */
      return acc.then(() =>
        unwatchSubcategoryOnsiteRequest({
          userId,
          subcatId: subcat.id,
          dispatch,
        })
      );
    },
    Promise.resolve()
  );
};

export const resetNotificationSettings =
  (id, action, fields, method = "patch") =>
  (dispatch) => {
    const promise = request[method](
      `/api/v1/users/${id}/notifications/${action}/`,
      fields
    ).then(() => {
      dispatch(fetchNotificationSettings(id));
    });

    promise.catch(() =>
      dispatch(setBannerError("Error performing action", "Please try again."))
    );

    return promise;
  };

export const fetchPendingAwards = (userId) => (dispatch) => {
  return dispatch({
    type: FETCH_PENDING_AWARDS,
    promise: request.get(
      `/api/v1/users/${userId}/payout/unrequested-payments/`
    ),
  });
};

export const fetchPayoutAccounts = (userId) => (dispatch) => {
  return dispatch({
    type: FETCH_PAYOUT_ACCOUNTS,
    promise: request.get(`/api/v1/users/${userId}/payout/bindings/`),
  });
};

export const fetchParticipatedProjects = (userId, tab, query) => (dispatch) => {
  const STATUS_BY_TAB = {
    participating: [
      "open",
      "closed",
      "finalist_round",
      "finalist_closed",
      "1_1_negotiating",
      "1_1_ready",
    ],
    wrapup: ["awarded"],
    won: ["completed", "cancelled"],
    history: ["awarded", "completed", "cancelled"],
  };

  const AWARDED_BY_TAB = {
    wrapup: true,
    won: true,
    history: false,
  };

  const extraAPIQuery = {
    status: STATUS_BY_TAB[tab],
    awarded: AWARDED_BY_TAB[tab],
  };
  const queryStr = getAPIQueryString(USER_PROJECTS_RESOURCE_NAME, query, {
    pageSize: CREATIVE_PROJECTS_PAGE_SIZE,
    extraAPIQuery,
  });
  const promise = request.get(
    `/api/v1/users/${userId}/projects_as_creative/?${queryStr}`
  );

  /* save page count in pager state */
  promise.then((res) =>
    dispatch(savePageCount(USER_PROJECTS_RESOURCE_NAME, res))
  );

  return dispatch({
    type: FETCH_PARTICIPATED_PROJECTS,
    promise,
  });
};

export const fetchTestimonials = (query, userId) => (dispatch) => {
  const queryStr = getAPIQueryString(TESTIMONIALS_RESOURCE_NAME, query);
  const promise = request.get(
    `/api/v1/users/${userId}/testimonials/?${queryStr}`
  );

  /* save page count in pager state */
  promise.then((res) =>
    dispatch(savePageCount(TESTIMONIALS_RESOURCE_NAME, res))
  );

  return dispatch({
    type: FETCH_TESTIMONIALS,
    promise,
  });
};

export const deleteTestimonial = (userId, id) => (dispatch) => {
  const promise = request.delete(`/api/v1/users/${userId}/testimonials/${id}/`);

  promise.catch(() =>
    dispatch(setBannerError("Error deleting testimonial", "Please try again."))
  );

  dispatch({
    type: DELETE_TESTIMONIAL,
    promise,
    id,
  });

  return promise;
};

export const updateHistoryPaymentsPage = (userId, page) => (dispatch) => {
  dispatch({
    type: UPDATE_HISTORY_PAYMENTS_PAGE,
    page,
  });

  dispatch(fetchHistoryPaymentsPage(userId, page));
};

export const fetchHistoryPaymentsPage = (userId, page) => (dispatch) => {
  const offset = (page - 1) * HISTORY_PAYMENTS_PAGE_SIZE;
  const limit = HISTORY_PAYMENTS_PAGE_SIZE;

  return dispatch({
    type: FETCH_HISTORY_PAYMENTS_PAGE,
    promise: request.get(
      `/api/v1/users/${userId}/payout/requests/completed/?limit=${limit}&offset=${offset}`
    ),
  });
};

export const fetchRequestedPayments = (userId) => (dispatch) => {
  return dispatch({
    type: FETCH_REQUESTED_PAYMENTS,
    promise: request.get(
      `/api/v1/users/${userId}/payout/requests/in_progress/`
    ),
  });
};

export const fetchReputationDatapoints = (userId) => (dispatch) => {
  return dispatch({
    type: FETCH_REPUTATION_DATAPOINTS,
    promise: request.get(`/api/v1/users/${userId}/reputation_datapoints/`),
  });
};

export const fetchSubcatReputation = (userId) => (dispatch) => {
  return dispatch({
    type: FETCH_SUBCAT_REPUTATION,
    promise: request.get(`/api/v1/reputation/subcategory/?user=${userId}`),
  });
};

export const fetchLastWaitersInvitation = () => (dispatch) => {
  return dispatch({
    type: FETCH_LAST_WAITERS_INVITATION,
    promise: request.get(
      "/api/v1/creative-registration-waiters/registration_invite_lists/"
    ),
  });
};

export const fetchWaitersToInviteCount = (criteria) => (dispatch) => {
  const { intendedSubcats, createdAtSince, createdAtUntil } = criteria;
  const params = queryString.stringify({
    intended_sub_category_ids: intendedSubcats,
    created_at__gte: createdAtSince,
    created_at__lte: createdAtUntil,
  });

  return dispatch({
    type: FETCH_WAITERS_TO_INVITE_COUNT,
    promise: request.get(`/api/v1/creative-registration-waiters/?${params}`),
  });
};

export const fetchWaiterInvites = (query) => (dispatch) => {
  const queryStr = getAPIQueryString(WAITER_INVITES_RESOURCE_NAME, query);
  const promise = request.get(
    `/api/v1/creative-registration-waiters/registration_invite_lists/?${queryStr}`
  );

  /* save page count in pager state */
  promise.then((res) =>
    dispatch(savePageCount(WAITER_INVITES_RESOURCE_NAME, res))
  );

  return dispatch({
    type: FETCH_WAITER_INVITES,
    promise,
  });
};

export const fetchEmailDeliverability = (userId) => (dispatch) => {
  return dispatch({
    type: FETCH_EMAIL_DELIVERABILITY,
    promise: request.get(`/api/v1/users/${userId}/deliverability/`),
  });
};

export const fetchChatExcludedCountries = () => (dispatch) =>
  dispatch({
    type: FETCH_CHAT_EXCLUDED_COUNTRIES,
    promise: request.get("/api/v1/chat_excluded_countries/"),
  });

export const updateChatExcludedCountries =
  (_, { excludedCodes }) =>
  (dispatch, getState) => {
    const codes = excludedCodes;
    const current = getState().admin.people.chatExcludedCountries;

    const toAdd = codes.filter((n) => !current.find((c) => c.code === n));
    const toRemove = current.filter((c) => !codes.find((n) => c.code === n));

    let premises = toAdd.reduce(
      (acc, c) =>
        acc.then(() =>
          request.post("/api/v1/chat_excluded_countries/", { code: c })
        ),
      Promise.resolve()
    );
    premises = toRemove.reduce(
      (acc, c) =>
        acc.then(() =>
          request.delete(`/api/v1/chat_excluded_countries/${c.id}/`)
        ),
      premises
    );

    premises.then(() => dispatch(fetchChatExcludedCountries()));

    return premises;
  };

export const setSelectedUsers = (payload) => (dispatch) => {
  return dispatch({
    type: SET_SELECTED_USERS,
    payload,
  });
};
