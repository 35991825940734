import React from "react";
import idx from "idx";
import cookie from "react-cookie";
import last from "lodash/last";
import partial from "lodash/partial";
import moment from "moment";
import trailingSlashIt from "trailing-slash-it";
import { Link } from "react-router";
import {
  DISMISSED_FG_BANNERS_COOKIE,
  ELITE_SPOTS,
  MIN_PORTFOLIO_ITEMS_PER_PROJECT,
} from "project/constants";
import { getProject, getInvoice } from "project/ducks/project";
import { getProjectEntries } from "project/ducks/entries";
import { getProjectRevisions } from "project/ducks/revisions";
import { getSubCategory } from "common/ducks/categories";
import { TYPE_WARNING } from "common/ducks/banner";
import { HELP_URL as FG_HELP_URL } from "focus_groups/constants";
import { showTour as showTourCommon } from "common/components/tour";

/**
 * Returns whether logged in user is an active (i.e. invitation was accepted) project
 * collaborator.
 *
 * @param {object} project - project data
 * @returns {bool}
 *
 */
export const isCollaborator = (project) =>
  project ? !!project.collaborator_permissions : false;

/**
 * Returns whether logged in user can perform the given collaboration action.
 *
 * @param {object} project - project data
 * @param {string} action - collaboration action to be performed
 * @returns {bool}
 *
 */
export const canCollaborate = (project, action) =>
  isCollaborator(project) && project.collaborator_permissions[action];

/**
 * This function returns the projectId when passing in the params retrieved from url in react-router
 * Make sure to wrap your container/component with withRouter to get this.props.params
 *
 * @param {string} params - params retrieved from react-router
 * @returns {Number} projectId retrieved from url
 *
 */
export function getProjectUrl(project, subcategory, append = "") {
  const { id, slug } = project;

  /* make sure `append` never starts with a trailing slash */
  const newAppend = append && append.startsWith("/") ? append.slice(1) : append;

  return trailingSlashIt(`/${subcategory}/${slug}-${id}/${newAppend}`);
}

/**
 * This function returns the projectId when passing in the params retrieved from url in react-router
 * Make sure to wrap your container/component with withRouter to get this.props.params
 *
 * @param {string} params - params retrieved from react-router
 * @returns {Number} projectId retrieved from url
 *
 */
export function getProjectIdFromUrl(params) {
  const projectUrl = params && params.pageSlug ? params.pageSlug : "";
  const idStr = projectUrl ? last(projectUrl.split("-")) : null;
  return idStr * 1 === NaN ? 0 : idStr * 1; //eslint-disable-line
}

/**
 * This function determines whether to show naming/logo state
 *
 * @param {object} state - redux state, obtained from mapStateToProps
 * @returns {Boolean} returns whether to show dense view or image view
 *
 */
export function showNamingState(state) {
  const project = getProject(state);
  const entry_type = idx(project, (_) => _.entry_type);
  return project.id && entry_type !== "image";
}

/**
 * This function returns a revision object by id
 *
 * @param {object} state - redux state, obtained from mapStateToProps
 * @param {object} revisionId - revision id
 * @returns {object} revision object
 *
 */
export function getRevisionById(state, revisionId) {
  return idx(getProjectRevisions(state), (_) => _[revisionId]);
}

/**
 * This function returns true if you can report a project violation
 *
 * @param {object} state - redux state, obtained from mapStateToProps
 * @param {number} entryId - entry id, obtained from props
 * @returns {bool} - must be a creative. If project is NDAd, user must have been approved.
 *
 */
export function canReportProjectViolation(state) {
  const user = idx(state, (_) => _.user.profile_data);
  const project = getProject(state);

  return (
    user.id &&
    (project.nda_required
      ? project.nda_request_status === "accepted"
      : user.is_creative)
  );
}

/**
 * Whether the current user can request NDAd access to the current project
 *
 * @param {object} state - redux state, obtained from mapStateToProps
 * @returns {bool} - whether current user can request access
 *
 */
export function canRequestProjectAccess(state) {
  const project = getProject(state);

  return (
    /* make sure general participation requirements are met in the first place */
    !getParticipationForbiddenReason(state, {
      /* Elite creatives must sign the NDA before reserving their spots */
      skipEliteReservationCheck: true,
      skipNdaCheck: true,
      skipStatusCheck: project.status === "1_1_negotiating",
    }) &&
    project.nda_required &&
    !project.nda_request_status &&
    !!state.project.ndas.ndaContract
  );
}

/**
 * Whether current user has reserved a spot in the current elite project
 *
 * @param {object} state - redux state, obtained from mapStateToProps
 * @returns {bool} - whether current user has a reserved spot
 *
 */
export function isEliteReserved(state) {
  const user = idx(state, (_) => _.user.profile_data);
  const userId = user.id ? user.id * 1 : null;
  const project = getProject(state);
  const eliteIds = Object.keys(project.elite_reservations || {}).map(
    (id) => id * 1
  );

  return userId && eliteIds.indexOf(userId) !== -1;
}

/**
 * Whether current user was chosen as a participant in the current elite project
 *
 * @param {object} state - redux state, obtained from mapStateToProps
 * @returns {bool} - whether current user was chosen as a participant
 *
 */
export function isEliteParticipant(state) {
  const user = idx(state, (_) => _.user.profile_data);
  const project = getProject(state);
  const eliteIds = (project.elite_participants || []).map((id) => parseInt(id));

  return user.id && eliteIds.includes(parseInt(user.id));
}

/**
 * Whether current elite project is full
 *
 * @param {object} state - redux state, obtained from mapStateToProps
 * @returns {bool} - whether current elite project is full
 *
 */
export function isEliteFull(state) {
  const project = getProject(state);
  return (project.elite_participants || []).length >= ELITE_SPOTS;
}

/**
 * Whether current user can reserve a spot in the current Elite project
 *
 * @param {object} state - redux state, obtained from mapStateToProps
 * @returns {bool} - whether user can reserve a spot
 *
 */
export function canReserveElite(state) {
  const project = getProject(state);

  return (
    /* make sure general participation requirements are met in the first place */
    !getParticipationForbiddenReason(state, {
      skipEliteReservationCheck: true,
    }) &&
    project.elite_only &&
    !isEliteParticipant(state)
  );
}

/**
 * Get the reason why the current user can't participate in the current project
 *
 * @param {object} state - redux state, obtained from mapStateToProps
 * @returns {string} - reason why the current user can't participate
 *
 */
export function getParticipationForbiddenReason(state, opts = {}) {
  const user = idx(state, (_) => _.user.profile_data);
  const project = getProject(state);
  const subCategory = getSubCategory(state.categories, project.sub_category);

  if (!opts.skipStatusCheck) {
    if (project.has_finalist_round) {
      if (!project.finalist_ids.includes(user.id)) {
        return "You are not a finalist in this project.";
      }

      if (project.status !== "finalist_round") {
        return "The Finalist Round for this project is over.";
      }
    } else if (project.status !== "open") {
      return "This project is not open. But there are so many other great projects...";
    }
  }

  if (!user.id) {
    return "You are not logged in. But you can fix this...";
  }

  if (user.id === project.client) {
    return "This is your project!";
  }

  if (isCollaborator(project)) {
    return "You are a collaborator in this project!";
  }

  if (!user.is_creative) {
    return "You are not approved to participate in any projects on crowdspring.";
  }

  if (!user.allowed_to_participate) {
    return "You are not approved to participate in any projects on crowdspring.";
  }

  if (!(user.permitted_sub_category_ids || []).includes(project.sub_category)) {
    return `You are not approved to participate in ${subCategory.name} projects.`;
  }

  if (
    project.entry_type === "name" &&
    moment(project.start_date).isBefore(user.date_joined)
  ) {
    return "Sorry, you can't participate in naming or tagline projects posted before you joined crowdspring.";
  }

  if (project.removed_creative_ids.includes(user.id)) {
    return "Ugh! You have been removed from this project.";
  }

  if (
    project.platinum_only &&
    !user.is_platinum &&
    !project.creative_ids.includes(user.id)
  ) {
    return "Bummer. This project is only open to Platinum creatives.";
  }

  if (project.elite_only && !user.is_elite && !isEliteParticipant(state)) {
    return "Bummer. This project is only open to Elite creatives.";
  }

  if (!project.creative_ids.includes(user.id)) {
    let threshold, projectType;

    if (project.elite_only) {
      threshold = MIN_PORTFOLIO_ITEMS_PER_PROJECT.elite;
      projectType = "Elite";
    } else if (project.platinum_only) {
      threshold = MIN_PORTFOLIO_ITEMS_PER_PROJECT.platinum;
      projectType = "Platinum";
    } else {
      threshold = MIN_PORTFOLIO_ITEMS_PER_PROJECT.other;
      projectType = "crowdspring";
    }

    if ((user.portfolio_item_count || 0) < threshold) {
      return `You're so close... You must have at least ${threshold} portfolio items to participate in ${projectType} projects.`;
    }
  }

  if (project.elite_only && !isEliteParticipant(state)) {
    const eliteReservationWindowEnd = getEliteReservationWindowEnd(state);

    if (!isEliteWindowOpen(state)) {
      if (isEliteReserved(state)) {
        if (isEliteFull(state)) {
          return `Sorry, the reservation window for this Elite project closed at ${eliteReservationWindowEnd}, and unfortunately you didn't receive one of the ${ELITE_SPOTS} spots to participate.`;
        }
        return "Patience you must have! We will send you an email within the next 30 minutes to let you know if you've been accepted to work in this project.";
      }

      if (isEliteFull(state)) {
        return `Sorry, the reservation window for this Elite project closed at ${eliteReservationWindowEnd}.`;
      }
    }

    if (isEliteReserved(state)) {
      return `Patience you must have! Please wait until the reservation window closes at ${eliteReservationWindowEnd}, when ${ELITE_SPOTS} participants will be selected and notified.`;
    }

    if (!opts.skipEliteReservationCheck) {
      return "You're so close... You need a reservation. Click the pink Reservation button to request a spot.";
    }
  }

  if (!opts.skipNdaCheck && project.nda_required) {
    if (!project.nda_request_status) {
      return "Can you keep a secret? You haven't signed an NDA for this project yet.";
    }

    if (project.nda_request_status === "pending") {
      return "Patience you must have! Your request to view/participate in this private project is pending.";
    }

    if (project.nda_request_status === "denied") {
      return "Oh no! Your request to view/participate in this private project was denied.";
    }
  }
}

export const getHighestParticipationProject = ({ user }) => {
  const portfolioItemCount = user.portfolio_item_count || 0;

  if (
    user.id &&
    user.is_active &&
    user.is_creative &&
    user.allowed_to_participate &&
    portfolioItemCount >= MIN_PORTFOLIO_ITEMS_PER_PROJECT.other
  ) {
    if (
      user.is_elite &&
      portfolioItemCount >= MIN_PORTFOLIO_ITEMS_PER_PROJECT.elite
    ) {
      return "Elite";
    }

    if (
      user.is_platinum &&
      portfolioItemCount >= MIN_PORTFOLIO_ITEMS_PER_PROJECT.platinum
    ) {
      return "Platinum";
    }

    return "Silver/Gold";
  }

  return "None";
};

export function canWatch(state) {
  const user = idx(state, (_) => _.user.profile_data);
  const project = getProject(state);

  return (
    user.id &&
    user.is_creative &&
    project.client !== user.id &&
    !isCollaborator(project) &&
    !project.is_one_to_one
  );
}

export function canExtendDeadline(state, checkUserOnly) {
  const user = idx(state, (_) => _.user.profile_data);
  const project = getProject(state);

  const can = user.id && project.client === user.id;

  if (checkUserOnly) {
    return can;
  }

  return can && !project.deadline_original && project.status === "closed";
}

export function canClose(state) {
  const user = idx(state, (_) => _.user.profile_data);
  const project = getProject(state);

  return (
    !!user.id &&
    project.client === user.id &&
    project.status === "open" &&
    (project.is_one_to_one || moment().diff(project.start_date, "hours") >= 48)
  );
}

export const canCloseFinalRound = (state) => {
  const user = idx(state, (_) => _.user.profile_data);
  const project = getProject(state);

  return (
    !!user.id &&
    project.client === user.id &&
    project.status === "finalist_round"
  );
};

/**
 * This function returns true if you can request a project to be closed earlier
 *
 * @param {object} state - redux state, obtained from mapStateToProps
 * @returns {bool} - project must be open and user must be the project owner.
 *
 */
export function canRequestEarlyClose(state) {
  const user = idx(state, (_) => _.user.profile_data);
  const project = getProject(state);

  return user.id && project.client === user.id && project.status === "open";
}

/**
 * This function returns true if you can upgrade a project
 *
 * @param {object} state - redux state, obtained from mapStateToProps
 * @returns {bool} - project must be open and can't be legacy, user must be the project owner
 *
 */
export function canUpgrade(state) {
  const user = idx(state, (_) => _.user.profile_data);
  const project = getProject(state);

  return (
    user.id &&
    project.client === user.id &&
    project.status === "open" &&
    !project.is_legacy
  );
}

/**
 * This function returns true if you can download the project's invoice
 *
 * @param {object} state - redux state, obtained from mapStateToProps
 * @returns {bool} - user must be the project owner
 *
 */
export function canDownloadInvoice(state) {
  const user = idx(state, (_) => _.user.profile_data);
  const project = getProject(state);
  const invoice = getInvoice(state);

  return user.id && project.client === user.id && !!invoice;
}

/**
 * This function returns true if you can add awards to a project
 *
 * @param {object} state - redux state, obtained from mapStateToProps
 * @returns {bool} - project has to be open, closed for submissions or awarded.
                     It can't be legacy. User must be the project owner.
 *
 */
export function canAddAwards(state) {
  const user = idx(state, (_) => _.user.profile_data);
  const project = getProject(state);

  return (
    user.id &&
    project.client === user.id &&
    ["open", "closed", "awarded"].includes(project.status) &&
    !project.has_finalist_round &&
    !project.is_legacy
  );
}

export function canShare(state, entryId) {
  const user = idx(state, (_) => _.user.profile_data);
  const project = getProject(state);
  const entry = entryId ? getProjectEntries(state)[entryId] : null;

  return (
    !project.is_one_to_one &&
    !project.search_exclusion &&
    (!entry || user.id === entry.author)
  );
}

/**
 * This function returns true if you can view a withdrawn entry
 *
 * @param {object} state - redux state, obtained from mapStateToProps
 * @returns {bool} - must be entry author
 *
 */
export function canViewWithdrawn(state, entryId, entryObj) {
  const userId = idx(state, (_) => _.user.profile_data.id);
  const entry = entryId ? getProjectEntries(state)[entryId] : entryObj;

  return userId && userId === entry.author;
}

/**
 * This function returns true if you can view the revisions of an entry
 *
 * @param {object} state - redux state, obtained from mapStateToProps
 * @returns {bool} - only image entries. Also, if withdrawn, only authorized users can see it.
 *
 */
export function canViewRevisions(state, entryId, entryObj) {
  const entry = entryId ? getProjectEntries(state)[entryId] : entryObj;

  if (entry.entry_type !== "image") {
    return false;
  }

  if (entry.withdrawn) {
    return canViewWithdrawn(state, entryId, entryObj);
  }

  return true;
}

/**
 * This function returns if you can view the entry details pager
 *
 * @param {object} state - redux state, obtained from mapStateToProps
 * @returns {bool} - if project has private gallery, only the project owner and collaborators.
 *                   Otherwise, everyone.
 *
 */
export function canViewEntryDetailsPager(state) {
  const userId = idx(state, (_) => _.user.profile_data.id);
  const project = getProject(state);

  if (!project.private_gallery) {
    return true;
  }

  return userId && (userId === project.client || isCollaborator(project));
}

/**
 * This function returns true if you can view the all project entries
 *
 * @param {object} state - redux state, obtained from mapStateToProps
 * @returns {bool} - if project has private gallery, only owner and collaborators are allowed.
                     If project is NDAd, only owner, collaborators and accepted creatives are
                     allowed. Otherwise, everyone is allowed.
 *
 */
export function canViewAllEntries(state) {
  const userId = idx(state, (_) => _.user.profile_data.id);
  const project = getProject(state) || {};
  const isOwner = userId === project.client;
  const isCollab = isCollaborator(project);

  return !(
    (project.private_gallery && !(isOwner || isCollab)) ||
    (project.nda_required &&
      !(isOwner || isCollab || project.nda_request_status === "accepted"))
  );
}

/**
 * This function returns true if project details can only be seen by authenticated users
 *
 * @param {object} state - redux state, obtained from mapStateToProps
 * @returns {bool} - user must be unauthenticated and project must have search exclusion enabled.
 *
 */
export function requiresLogin(state) {
  const user = idx(state, (_) => _.user.profile_data);
  const project = getProject(state);
  return !user.id && project.search_exclusion;
}

/**
 * Whether current elite project reservation window is open
 *
 * @param {object} state - redux state, obtained from mapStateToProps
 * @returns {bool} - whether reservation window is open
 *
 */
export function isEliteWindowOpen(state) {
  const project = getProject(state);
  return (
    project.status === "open" &&
    moment(project.start_date)
      .add(project.elite_reservation_window_minutes, "m")
      .isAfter()
  );
}

export function getEliteReservationWindowEnd(state) {
  const project = getProject(state);
  if (!project.start_date || !project.elite_reservation_window_minutes) {
    return "";
  }
  return moment(project.start_date)
    .add(project.elite_reservation_window_minutes, "m")
    .format("MM/DD/YYYY h:mma");
}

export function getFGPromoBannerContent(project, hideBanner) {
  const dismissedBanners = cookie.load(DISMISSED_FG_BANNERS_COOKIE) || [];
  const firstDismissed = dismissedBanners.find(
    (b) => b.version === 1 && b.id === project.id
  );
  const handleHideFGPromoBanner = (version) => {
    cookie.save(
      DISMISSED_FG_BANNERS_COOKIE,
      [...dismissedBanners, { version, id: project.id }],
      { path: "/", expires: moment("2100-12-12").toDate() }
    );
  };

  if (project.entry_type === "image") {
    /* first banner: shown when project is open and closes in less than 24h */
    if (
      project.status === "open" &&
      moment(project.deadline).diff(moment(), "hours") < 24 &&
      !firstDismissed
    ) {
      return {
        title:
          "Want feedback from people you trust to help you pick the winner in your project?",
        content: (
          <p>
            You can launch a free focus group at any time.
            <Link target="_blank" to={FG_HELP_URL} onClick={hideBanner}>
              {" "}
              Here's how it works.
            </Link>
          </p>
        ),
        onHide: partial(handleHideFGPromoBanner, 1),
        type: TYPE_WARNING,
      };
    }
  }

  return null;
}

export const isProjectTagline = (project) => {
  return [28, 94].includes(idx(project, (_) => _.sub_category));
};

export const canProjectHaveFinalRound = (project, ignoreStatus = false) => {
  return (
    !project.is_one_to_one &&
    !project.elite_only &&
    project.entry_type === "image" &&
    (ignoreStatus || project.status === "closed")
  );
};

export const getProjectTab = (routes) =>
  idx(routes, (_) => _[2].name) || idx(routes, (_) => _[2].path);

export const getProjectTabUrl = ({ project, params, tab }) =>
  getProjectUrl(project, params.subcategory, tab + "/");

export const showPromoContent = ({ project, user, routes }) => {
  return !!(
    idx(project, (_) => _.id) &&
    getProjectTab(routes) === "brief" &&
    (!user.id ||
      (user.is_client &&
        !user.is_creative &&
        user.id !== project.client &&
        !isCollaborator(project)))
  );
};

export const getEntryName = ({ project }) => {
  if (idx(project, (_) => _.entry_type) === "name") {
    return isProjectTagline(project) ? "tagline" : "name";
  }
  return "design";
};

export const getCreativeName = ({ project }) => {
  return idx(project, (_) => _.entry_type) === "name" ? "creative" : "designer";
};

export const canSubmitRevision = ({ state, project, user }) => {
  return (
    !!user.id &&
    !getParticipationForbiddenReason(state) &&
    project.entry_type === "image" &&
    state.project.entrySubmission.hasEntries
  );
};

export const cleanProjectTitle = (project, titleWords) => {
  if (!project.title) {
    return "";
  }

  if (!project.is_title_cleanable) {
    return project.title;
  }

  /* remove extra white spaces */
  let title = project.title.trim().replace(/\s+/g, " ");
  /* capitalize words */
  title = title.replace(/\w\S*/g, (m) => {
    return m.charAt(0).toUpperCase() + m.substr(1).toLowerCase();
  });
  /* enforce case in special words */
  titleWords.forEach((o) => {
    title = title.replace(new RegExp(`\\b${o.content}\\b`, "gi"), o.content);
  });

  return title;
};

export const augmentCategoryName = (category) => {
  if (
    [
      "logo-identity",
      "product-packaging",
      "business-advertising",
      "clothing-merchandise",
      "book-magazine",
      "other",
    ].includes(category.slug)
  ) {
    return category.name + " Design";
  }

  return category.name;
};

export const augmentSubCategoryName = (subcat) => {
  let name = subcat.name.replace(" (design only)", "");

  if (
    [
      "clothing",
      "merchandise-design",
      "book-cover-design",
      "business-card-design",
      "brochure-or-menu-design",
      "web-banner-design",
      "tattoo-design",
      "magazine-cover-design",
      "vehicle-wrap-design",
      "t-shirt-design",
      "logo-design",
      "blog-theme-design",
      "icon-button-and-widget-design",
      "logo-and-business-card-design",
      "other-clothing-design",
      "infographics-design",
      "wall-mural-art-design",
      "package-graphics",
      "logo-and-social-media-assets-design",
      "presentation-design",
      "letterhead-and-stationery-design",
      "outdoor-or-signage-design",
      "landing-page-design",
      "mobile-ui-design",
      "postcard-or-flyer-design",
      "poster-design",
      "social-media-assets-design",
      "newsletter-design",
      "website-design",
    ].includes(subcat.slug)
  ) {
    return name + " Design";
  }

  return name;
};

export const canIncreaseAward = ({ state, wrapup }) => {
  let can = canAddAwards(state);

  if (wrapup) {
    /* don't show for offers or awards that were paid */
    can =
      can &&
      wrapup.award.type === "award" &&
      wrapup.stepIdx < wrapup.steps.length - 1;
  }

  return can;
};

export const showTour = ({ project, user }) => {
  return !!(
    showTourCommon() &&
    idx(project, (_) => _.id) &&
    user.id &&
    ["open", "closed", "finalist_round", "finalist_closed"].includes(
      project.status
    ) &&
    (user.id === project.client || isCollaborator(project))
  );
};

export const isStockArtForbidden = (project) => {
  /* "Logo & Identity" category and "Social Media Assets" sub-category projects */
  return (
    [7, 18].includes(project.category) ||
    [36, 92].includes(project.sub_category)
  );
};
