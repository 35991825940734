import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toggleFinalRoundConfirmation, startFinalRound } from "entries/ducks";
import { getProject } from "project/ducks/project";
import Avatar from "common/components/avatar";
import { AVATAR_CLASSES } from "entries/components/finalists";
import ConfirmationDialog from "common/components/modal/confirmation_dialog";

const ASSURE_FAQ =
  "/help/creating-project/what-does-it-mean-when-a-project-is-client-assured/";
const PRIMARY_ACTION_TEXT = "Start the Finalist Round!";

const mapStateToProps = (state) => {
  const { showingFinalRound } = state.entries;
  const project = getProject(state);

  return {
    dialogVisible: showingFinalRound,
    dialogHeader: "Start Finalist Round",
    dialogTitle: "Confirm finalists",
    primaryActionText: PRIMARY_ACTION_TEXT,
    dialogMessage: project.id ? (
      <div className="final-round-confirmation-msg">
        <p>
          Your designers look forward to continuing to collaborate with you in
          the Finalist Round.
        </p>
        <p>By starting the Finalist Round, you:</p>
        <ul>
          {!project.assured && (
            <li>
              <a href={ASSURE_FAQ} target="_blank">
                assure the project
              </a>{" "}
              and promise to select one of the finalists as the winner.
            </li>
          )}
          <li>
            understand that to encourage high quality and participation, the
            winner will get 80% of the award and all finalists will equally
            split the remaining 20%.
          </li>
          <li>
            understand that you will receive final files and will own only the
            design you select as the winner.
          </li>
          <li>
            understand that you will be able to make offers and purchase the
            rights to any other designs in your project.
          </li>
          <li>
            understand that once the Finalist Round starts you cannot add or
            remove finalists.
          </li>
        </ul>
        <div className="finalists-info">
          <strong>
            By clicking "{PRIMARY_ACTION_TEXT}" you will start the Finalist
            Round with the following creatives:
            <ul>
              {project.finalists.map((f) => (
                <li key={f.id}>
                  <Avatar user={f} extraClasses={AVATAR_CLASSES()} />{" "}
                  {f.username}
                </li>
              ))}
            </ul>
          </strong>
        </div>
      </div>
    ) : undefined,
  };
};

export default connect(mapStateToProps, {
  submitAction: startFinalRound,
  toggleDialog: toggleFinalRoundConfirmation,
})(ConfirmationDialog);
