import { withRouter } from "react-router";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import TabbedNavigation from "common/components/tabbed_navigation";

export function mapStateToProps({ header }, { router }) {
  return {
    tabs: header.tabsNavigation,
    router,
  };
}

export default withRouter(connect(mapStateToProps, { push })(TabbedNavigation));
