import React from "react";
import PropTypes from "prop-types";
import { renderToString } from "react-dom/server";
import unionWith from "lodash/unionWith";
import Helmet from "react-helmet";
import config from "helmconfig";

export const Meta = ({
  title,
  titleTemplate,
  meta = [],
  link = [],
  script = [],
}) => {
  /* merge default and custom meta tags by name or property (used by OpenGraph) */
  const helmMeta = unionWith(
    meta,
    config.meta,
    (v1, v2) =>
      (v1.name && v2.name && v1.name === v2.name) ||
      (v1.property && v2.property && v1.property === v2.property)
  );
  const helmLink = [...config.link, ...link];

  return (
    <Helmet
      title={title || config.title}
      titleTemplate={titleTemplate || config.titleTemplate}
      meta={helmMeta}
      link={helmLink}
      script={script}
    />
  );
};

Meta.propTypes = {
  title: PropTypes.string,
  meta: PropTypes.array,
  link: PropTypes.array,
  script: PropTypes.array,
  titleTemplate: PropTypes.string,
};

renderToString(<Meta />);
const header = Helmet.rewind;

export default header;
