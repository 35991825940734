import React, { Component } from "react";
import PropTypes from "prop-types";
import ModalDialog from "common/components/modal/modal_dialog";

/**
 * Reusable confirmation dialog.
 * @author Kuba Siemiątkowski <kuba@crowdspring.com>
 */
const ConfirmationDialog = ({
  dialogVisible,
  dialogHeader,
  dialogTitle,
  dialogMessage,
  submitAction,
  primaryActionText,
  toggleDialog,
  secondaryActionText,
  primaryDisabled,
}) => {
  return (
    <ModalDialog
      dialogClasses="confirmation-dialog"
      contentClasses="confirmation-dialog"
      modalOpen={dialogVisible}
      closeAction={toggleDialog}
      modalHeader={dialogHeader}
      onPrimary={submitAction}
      primaryLabel={primaryActionText || "Yes"}
      primaryDisabled={primaryDisabled}
      onSecondary={toggleDialog}
      secondaryLabel={secondaryActionText || "Cancel"}
    >
      <div className="dialog-message">
        {dialogTitle && <h2>{dialogTitle}</h2>}
        {dialogMessage}
      </div>
    </ModalDialog>
  );
};

ConfirmationDialog.propTypes = {
  dialogHeader: PropTypes.string,
  dialogTitle: PropTypes.string,
  dialogMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dialogVisible: PropTypes.bool,
  toggleDialog: PropTypes.func,
  submitAction: PropTypes.func,
  primaryActionText: PropTypes.string,
  secondaryActionText: PropTypes.string,
  primaryDisabled: PropTypes.bool,
};

export default ConfirmationDialog;
