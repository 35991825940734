import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, browserHistory } from "react-router";
import { LoadingIndicator } from "common/components/preloader";
import { Meta } from "common/components/meta";

export const getDiscountDescription = (code) => {
  let description = "";

  if (code.kind === "flat") {
    if (code.amount && parseInt(code.amount)) {
      description += `$${parseInt(code.amount)} `;
    }
  } else {
    if (code.percentage && parseInt(code.percentage)) {
      description += `${parseInt(code.percentage)}% `;
    }
  }

  if (description) {
    description += "discount";
  }

  code.free_features.forEach((f) => {
    if (description) {
      description += " and ";
    }
    description += `${f}`;
  });

  return description;
};

/* get banner title and content referring to provided discount code */
export const getBannerContents = (code, scenario, extra = {}) => {
  let title,
    content,
    icon = <i className="fa fa-tag" />;

  if (!code) {
    return null;
  }

  const description = getDiscountDescription(code);

  if (scenario === "unused") {
    const { draft, hideBanner } = extra;

    title = "Unused discount";

    content = (
      <p>
        You haven't yet used your {description} - just{" "}
        {draft ? (
          <Link
            onClick={hideBanner}
            to={`/start-a-project/${draft.id}/review/`}
          >
            launch your project
          </Link>
        ) : (
          <Link onClick={hideBanner} to="/categories/">
            launch a project
          </Link>
        )}{" "}
        to claim it!
      </p>
    );
  } else {
    /* code has just been fetched */
    if (scenario === "fetched") {
      title = "It's in the bag!";

      /* code is ready to be applied at PAP */
    } else {
      title = "Your discount code is ready to be applied!";
    }

    content = `Your ${description} will be applied when you post your project.`;
  }

  return { title, content, icon };
};

/**
 * Discount code screen.
 *
 * @author Nathan Ozelim <nathan@startupfoundry.com>
 */
export default class DiscountCode extends Component {
  constructor(props) {
    super(props);

    this.state = { fetched: null, error: "" };
  }

  componentDidMount() {
    const { codeSlug, fetch, showBanner } = this.props;

    fetch(codeSlug)
      .then(({ data }) => {
        this.setState({ fetched: true });

        const bannerContents = getBannerContents(data, "fetched");
        showBanner(
          bannerContents.title,
          bannerContents.content,
          bannerContents.icon
        );

        /* redirect user if an internal redirect URL is provided */
        if (data.redirect_url) {
          browserHistory.push(data.redirect_url);
        }
      })
      .catch((err) => {
        let error =
          "The coupon code you are trying to retrieve is not valid anymore.";
        if (err.status == 404) {
          error = "The coupon code you are trying to retrieve does not exist.";
        }
        this.setState({ fetched: false, error });
      });
  }

  render() {
    const { fetched, error } = this.state;

    return (
      <div className="l-content info-screen" id="content">
        <Meta title="Coupon Code" />
        <header className="l-header">
          <div className="header-middle">
            <div className="row">
              <div className="columns small-16">
                <h2 className="middle-left-title">
                  {fetched === null
                    ? "Retrieving coupon code..."
                    : fetched
                    ? "Success!"
                    : "Whoops!"}
                </h2>
              </div>
            </div>
          </div>
        </header>

        {fetched === null ? (
          <LoadingIndicator />
        ) : (
          <div className="row">
            <div className="columns medium-8 small-16">
              <h2>{fetched ? "It's in the bag!" : "Invalid coupon code"}</h2>

              {fetched ? (
                <p>
                  You coupon code will be applied when you post your project.
                </p>
              ) : (
                <p>
                  {error} If you have questions, please{" "}
                  <Link to="/help/contact/">get in touch</Link>.
                </p>
              )}
            </div>

            <div className="columns medium-8 small-16 icon hide-for-small-only">
              <i
                className={"fa fa-" + (fetched ? "check-circle" : "frown-o")}
              ></i>
            </div>
          </div>
        )}
      </div>
    );
  }
}

DiscountCode.propTypes = {
  fetch: PropTypes.func,
  showBanner: PropTypes.func,
  codeSlug: PropTypes.string,
};
