import request from "axios";
import idx from "idx";
import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { canCollaborate } from "project/utils";
import { setBannerError } from "error";

/* ACTION CONSTANTS
 ================================================================================================ */
export const INVITE_CREATIVE = "crowdspring/invite/INVITE_CREATIVE";
export const REQ_INVITE = "crowdspring/invite/REQ_INVITE";
export const REQ_INVITE_SUCCESS = "crowdspring/invite/REQ_INVITE_SUCCESS";
export const REQ_INVITE_ERROR = "crowdspring/invite/REQUEST_INVITE_ERROR";

const FETCH_OPEN_PROJECTS = "crowdspring/invite/FETCH_OPEN_PROJECTS";
const FETCH_OPEN_PROJECTS_REQUEST =
  "crowdspring/invite/FETCH_OPEN_PROJECTS_REQUEST";
const FETCH_OPEN_PROJECTS_SUCCESS =
  "crowdspring/invite/FETCH_OPEN_PROJECTS_SUCCESS";

/* HELPERS
================================================================================================ */

/* get projects for which creative can be invited: active projects that are owned by the user
   or in which she's a valid collaborator */
export const getInviteableProjects = (state) => {
  const userId = idx(state, (_) => _.user.profile_data.id);
  const { openProjects } = state.invite.modal;
  return openProjects.filter(
    (project) =>
      userId === project.client || canCollaborate(project, "invite_creatives")
  );
};

/* ACTION CREATORS
 ================================================================================================ */
export const inviteCreative = (creative) => ({
  type: INVITE_CREATIVE,
  payload: creative,
});

export const closeInviteModal = () => ({
  type: INVITE_CREATIVE,
  payload: null,
});

export const reqInvite = (creativeId, data) => (dispatch) => {
  const opts = {
    url: `/api/v1/users/${creativeId}/invite/`,
    method: "post",
    data,
  };

  dispatch({ type: REQ_INVITE });
  return request(opts)
    .then((response) =>
      dispatch({ type: REQ_INVITE_SUCCESS, payload: response })
    )
    .catch((response) => {
      dispatch(setBannerError("Failed to invite creative", "Please try again"));
      dispatch({ type: REQ_INVITE_ERROR, payload: response });
    });
};

export const fetchOpenProjects = () => (dispatch) => {
  dispatch({
    type: FETCH_OPEN_PROJECTS,
    promise: request.get("/api/v1/projects/mine/?status=open"),
  });
};

export const initialState = {
  creative: null,
  success: null,
  error: null,
  isLoading: false,
  openProjects: [],
  isLoadingProjects: false,
};

/* REDUCERS
 ================================================================================================ */
export function reducer(state = initialState, action) {
  switch (action.type) {
    case INVITE_CREATIVE:
      return {
        ...state,
        creative: action.payload,
        error: null,
        success: null,
      };
    case REQ_INVITE:
      return {
        ...state,
        isLoading: true,
      };
    case REQ_INVITE_SUCCESS:
      return {
        ...state,
        success: true,
        error: null,
        isLoading: false,
      };
    case REQ_INVITE_ERROR:
      return {
        ...state,
        success: null,
        error: action.payload,
        isLoading: false,
      };
    case FETCH_OPEN_PROJECTS_REQUEST:
      return {
        ...state,
        openProjects: [],
        isLoadingProjects: true,
      };
    case FETCH_OPEN_PROJECTS_SUCCESS:
      return {
        ...state,
        openProjects: action.payload.results,
        isLoadingProjects: false,
      };
    default:
      return state;
  }
}

const RootReducer = combineReducers({
  modal: reducer,
  form: formReducer,
});

export default RootReducer;
