import React from "react";
import PropTypes from "prop-types";
import Icon from "common/components/svg_icon";

const HeaderButtons = ({ toggleRightDrawer, resourceName }) => {
  if (!resourceName) {
    return null;
  }

  return (
    <div className="hide-for-large button-wrapper">
      <button
        type="button"
        onClick={toggleRightDrawer}
        className="btn btn-icon-only btn-filter header-btn-filter"
      >
        <Icon extraClasses="svg-icon-lg filter" icon="filter" />
      </button>
    </div>
  );
};

HeaderButtons.propTypes = {
  resourceName: PropTypes.string,
  toggleRightDrawer: PropTypes.func.isRequired,
};

export default HeaderButtons;
