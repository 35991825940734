import React, { Component } from "react";
import PropTypes from "prop-types";
import without from "lodash/without";
import difference from "lodash/difference";
import union from "lodash/union";
import groupBy from "lodash/groupBy";
import every from "lodash/every";
import some from "lodash/some";
import partial from "lodash/partial";
import isEqual from "lodash/isEqual";
import classnames from "classnames";
import { CheckBoxField } from "common/components/form_fields";

/**
 * Checkboxes filter field.
 *
 * @author Nathan Ozelim <nathan@startupfoundry.com>
 */
export default class Checkboxes extends Component {
  constructor(props) {
    super(props);

    this.state = { openGroups: {}, expandedGroups: {} };

    this.getGroups = this.getGroups.bind(this);
    this.setInitialState = this.setInitialState.bind(this);
    this.toggleGroupOpen = this.toggleGroupOpen.bind(this);
    this.toggleGroupExpanded = this.toggleGroupExpanded.bind(this);
    this.renderOption = this.renderOption.bind(this);
  }

  getGroups() {
    return groupBy(this.props.options, "group");
  }

  setInitialState() {
    const { value } = this.props;
    const groups = this.getGroups();
    let openGroups = {},
      expandedGroups = {};
    Object.keys(groups).forEach((group) => {
      /* make group open and expanded if any of its options is checked */
      const hasCheckedOpt = some(groups[group], (opt) =>
        value.includes(opt.field)
      );
      openGroups = { ...openGroups, [group]: hasCheckedOpt };
      expandedGroups = { ...expandedGroups, [group]: hasCheckedOpt };
    });
    this.setState({ openGroups, expandedGroups });
  }

  componentDidMount() {
    this.setInitialState();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.options, this.props.options)) {
      this.setInitialState();
    }
  }

  toggleGroupOpen(group) {
    const { openGroups } = this.state;
    this.setState({
      openGroups: { ...openGroups, [group]: !openGroups[group] },
    });
  }

  toggleGroupExpanded(group) {
    const { expandedGroups } = this.state;
    this.setState({
      expandedGroups: { ...expandedGroups, [group]: !expandedGroups[group] },
    });
  }

  renderCount(opt) {
    const { items, field, showCount } = this.props;

    if (!showCount) {
      return "";
    }

    let optField = opt.field;
    if (opt.field === "true") {
      optField = true;
    } else if (opt.field === "false") {
      optField = false;
    }

    return ` (${items.filter((i) => i[field] === optField).length})`;
  }

  renderOption(opt) {
    const { value, onChange, field } = this.props;
    const checked = value.includes(opt.field);
    const label =
      typeof opt.label === "string"
        ? `${opt.label}${this.renderCount(opt)}`
        : opt.label;

    return (
      <CheckBoxField
        input={{
          name: `filter_${field}_${opt.field}`,
          value: checked,
          onChange: (v) =>
            onChange(v ? value.concat(opt.field) : without(value, opt.field)),
        }}
        label={label}
        key={opt.field}
        meta={{}}
      />
    );
  }

  renderGroup(key, group, options) {
    const { field, value, onChange, collapsableAfter } = this.props;
    const hasGroupOption = group !== "undefined";
    const { openGroups, expandedGroups } = this.state;
    const isGroupOpen = openGroups[group];
    const isGroupExpanded = expandedGroups[group];
    const canExpand = collapsableAfter && options.length > collapsableAfter;
    const visibleOptions =
      canExpand && !isGroupExpanded
        ? options.slice(0, collapsableAfter)
        : options;

    return (
      <div
        key={key}
        className={classnames("group", { "with-group-option": hasGroupOption })}
      >
        {hasGroupOption && (
          <div>
            <CheckBoxField
              input={{
                name: `filter_group_${field}_${group}`,
                value: every(options, (opt) => value.includes(opt.field)),
                onChange: (v) => {
                  const fields = options.map((opt) => opt.field);
                  onChange(
                    v ? union(value, fields) : difference(value, fields)
                  );
                },
              }}
              label={group}
              meta={{}}
            />
            <i
              className={"fa fa-" + (isGroupOpen ? "minus" : "plus")}
              onClick={partial(this.toggleGroupOpen, group)}
            />
          </div>
        )}

        {(!hasGroupOption || isGroupOpen) &&
          visibleOptions.map(this.renderOption)}

        {(!hasGroupOption || isGroupOpen) && canExpand && (
          <a
            className={classnames("collapse show-more-toggle", {
              uncollapsed: isGroupExpanded,
            })}
            onClick={partial(this.toggleGroupExpanded, group)}
          >
            {isGroupExpanded ? "Show less" : "Show more"}
          </a>
        )}
      </div>
    );
  }

  render() {
    const groups = this.getGroups();

    return (
      <div className="checkboxes-filter">
        {Object.keys(groups).map((group, i) =>
          this.renderGroup(i, group, groups[group])
        )}
      </div>
    );
  }
}

Checkboxes.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
  collapsableAfter: PropTypes.number,
  items: PropTypes.array,
  showCount: PropTypes.bool,
};
