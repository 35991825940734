import request from "axios";
import { RECENT_ENTRIES_RESOURCE_NAME } from "admin/constants";
import { getAPIQueryString } from "common/utils";

/* ACTIONS
================================================================================================ */
const FETCH_ALL = "admin/recent_entries/FETCH_ALL";
const FETCH_ALL_REQUEST = "admin/recent_entries/FETCH_ALL_REQUEST";
const FETCH_ALL_SUCCESS = "admin/recent_entries/FETCH_ALL_SUCCESS";

/* HELPERS
================================================================================================ */
const ungroupRevisions = (payload) => {
  return payload.reduce((acc, entry) => {
    entry.revisions.forEach(
      (rev) =>
        (acc = acc.concat({
          ...entry,
          key: `${entry.entry_type}_${rev.id}`,
          revisions: [rev],
        }))
    );
    return acc;
  }, []);
};

/* INITIAL STATES
================================================================================================ */
const initialState = {
  list: [],
  isFetching: false,
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_REQUEST:
      return {
        ...state,
        queryStr: action.queryStr,
        isFetching: true,
      };
    case FETCH_ALL_SUCCESS:
      /* avoid wrong results due to parallel requests */
      if (action.queryStr !== state.queryStr) {
        return state;
      }

      return {
        ...state,
        isFetching: false,
        list: ungroupRevisions(action.payload),
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const fetchAll = (query) => (dispatch) => {
  const queryStr = getAPIQueryString(RECENT_ENTRIES_RESOURCE_NAME, query, {
    extraAPIQuery: { limit: undefined, offset: undefined },
  });

  return dispatch({
    type: FETCH_ALL,
    promise: request.get(`/api/v1/radmin/project_entries/recent/?${queryStr}`),
    queryStr,
  });
};
