import { connect } from "react-redux";
import { withRouter } from "react-router";
import last from "lodash/last";
import {
  TEMPLATES_RESOURCE_NAME,
  DESIGNS_RESOURCE_NAME,
} from "brand_studio/constants";
import Controls from "brand_studio/components/brand/preview_gallery_controls";

export const mapStateToProps = (state, { routes }) => {
  const routeName = last(routes).name;
  let resourceName;
  if (routeName === "templates") {
    resourceName = TEMPLATES_RESOURCE_NAME;
  } else if (routeName === "designs") {
    resourceName = DESIGNS_RESOURCE_NAME;
  }

  return {
    resourceName,
  };
};

export default withRouter(connect(mapStateToProps)(Controls));
