import React from "react";
import PropTypes from "prop-types";
import Dropdown from "common/components/dropdown_menu";

const SubmitDropdown = ({
  canParticipate,
  canSubmitRevision,
  toggleEntrySubmission,
  toggleEntrySelection,
}) => {
  if (!canParticipate) {
    return null;
  }

  const buttons = [
    {
      label: "Submit revision",
      action: toggleEntrySelection,
      extraBtnClasses: "btn btn-tertiary",
      show: canSubmitRevision,
    },
    {
      label: "Submit new entry",
      action: () => toggleEntrySubmission({}),
      extraBtnClasses: "btn" + (canSubmitRevision ? "" : " btn-tertiary"),
      show: true,
    },
  ];

  return (
    <div className="button-wrapper show-for-large">
      <Dropdown showFirst={true} buttons={buttons} className="with-buttons" />
    </div>
  );
};

SubmitDropdown.propTypes = {
  canParticipate: PropTypes.bool.isRequired,
  canSubmitRevision: PropTypes.bool.isRequired,
  toggleEntrySubmission: PropTypes.func.isRequired,
  toggleEntrySelection: PropTypes.func.isRequired,
};

export default SubmitDropdown;
