import { connect } from "react-redux";
import capitalize from "lodash/capitalize";
import { withRouter, browserHistory } from "react-router";
import { getProject } from "project/ducks/project";
import {
  DAYS_TO_AWARD,
  MAX_ROUND_FINALISTS,
  FINAL_ROUND_DURATION,
  PROJECT_EXTENSION,
} from "project/constants";
import {
  getProjectTabUrl,
  getCreativeName,
  getEntryName,
  canProjectHaveFinalRound,
  canUpgrade,
} from "project/utils";
import { MAX_SELECTED_REVISIONS } from "focus_groups/constants";
import Tour from "common/containers/tour";

export const TOUR_ID = "project";

const goToTab = ({ project, tab, params }) => {
  const url = getProjectTabUrl({ project, params, tab });

  /* already in tab, ignore */
  if (url === document.location.pathname) {
    return Promise.resolve();
  }

  return new Promise((resolve) => {
    browserHistory.push(url);
    /* wait for any re-renders triggered by the action above, otherwise the target element
       may get lost */
    setTimeout(resolve, 1000);
  });
};

const getTourDefinition = ({ project, user, params, canUpgrade }) => {
  const isOwner = project.client === user.id;
  const isImage = project.entry_type === "image";
  const isOtO = project.is_one_to_one;
  const creativeName = getCreativeName({ project });
  const entryName = getEntryName({ project });
  const wrapupUrl = getProjectTabUrl({ project, params, tab: "wrapup/intro" });
  const settingsTab = isOtO ? "settings/pending" : "settings/invites";
  const canHaveFinalRound = canProjectHaveFinalRound(project, true);

  const BRIEF_INTRO = {
    beforeShowPromise: () => goToTab({ project, tab: "", params }),
    attachTo: { element: "#tab_brief" },
    modalOverlayOpeningPadding: 0,
    title: "Your project brief is here",
    text: `
      The requirements for your project, along with any files you've shared with
      ${
        isOtO ? "the creative" : "participants"
      }, are in the BRIEF tab of your project.
    `,
  };
  const BRIEF_UPDATES = {
    beforeShowPromise: () => goToTab({ project, tab: "", params }),
    attachTo: { element: ".brief-section .message-editor" },
    title: "Share project updates and add internal notes",
    text: `
      Send ${
        isOtO
          ? "the creative a message"
          : "all participants and watchers a public message"
      }
      by leaving an "update". We will immediately notify
      ${
        isOtO ? "the creative" : "all participants and watchers"
      } via email.<br/><br/>

      You can also add an internal note that only you (and your collaborators) can see.
    `,
  };
  const BRIEF_FEEDBACK = {
    beforeShowPromise: () => goToTab({ project, tab: "", params }),
    attachTo: { element: ".feedback-item" },
    modalOverlayOpeningPadding: 0,
    title: "See a smile or frown?",
    text: `
      A smiley face icon in your project means you're doing a great job scoring and giving
      feedback. ${
        isOtO
          ? ""
          : "Click that icon to compare your project to similar projects."
      }
      <br/><br/>

      Remember, you'll get better ${entryName}s (and more of them) if your project shows a
      smiley face. ${capitalize(
        creativeName
      )}s prefer to work with clients who are actively
      providing feedback and scoring.
    `,
  };
  const ENTRIES_INTRO = {
    beforeShowPromise: () => goToTab({ project, tab: "entries", params }),
    attachTo: { element: "#tab_entries" },
    modalOverlayOpeningPadding: 0,
    title: `${
      isOtO ? "The creative" : `${capitalize(creativeName)}s`
    } will submit ${entryName}s`,
    text: `
      ${
        isOtO ? "The creative needs" : `${capitalize(creativeName)}s need`
      } a little
      time to come up with ${
        isImage ? "creative" : "great"
      } ideas. Soon, they'll start submitting
      custom ${entryName}s for your review!<br/><br/>

      We'll email you when a new ${entryName} is submitted. You can change what notifications you
      receive, and how often, in <a target="_blank" href="/account/notifications/">
      your account</a>.<br/><br/>

      If you score and give feedback promptly, ${
        isOtO ? "the creative" : `${creativeName}s`
      } will
      have time to submit revised ${entryName}s for your review. You can work with
      ${
        isOtO ? "the creative" : `each ${creativeName}`
      } to get the ${entryName} you love.
    `,
  };
  const ENTRIES_SCORE = {
    beforeShowPromise: () => goToTab({ project, tab: "entries", params }),
    attachTo: { element: ".entry .entry-tile" },
    title: "Score & give feedback",
    text: `
      Scoring and giving feedback on ${entryName}s is easy, fun, and the best way to improve
      the quality of the ${entryName}s and increase participation. In fact, by scoring and giving
      feedback to all ${entryName}s, you'll get twice as many ${entryName}s - and better ones!
      <br/><br/>

      Comment and score ${entryName}s from 1 to 5 stars (5 is best). Comments and scores are
      private. Only you and the entry's creator can see them.
    `,
  };
  const ENTRIES_VIEWS = {
    beforeShowPromise: () => goToTab({ project, tab: "entries", params }),
    attachTo: { element: ".project-section .controls-group.view-selector" },
    title: "Flexible project views",
    text: `
      View ${entryName}s as ${isImage ? "tiles" : "list"} (default view) or in a
      ${
        isImage ? '"list" format (more details)' : "table format"
      }. Click a ${entryName} to see all
      comments and details. Hover over any ${entryName} and a three dot menu on the top left of the
      tile will show you more tools.
    `,
  };
  const ENTRIES_TOOLS = {
    beforeShowPromise: () => goToTab({ project, tab: "entries", params }),
    attachTo: { element: ".project-section .entries-controls-rest" },
    title: "Useful project tools",
    text: `
      View individual ${entryName}s${
      isImage ? " or group revisions (designs with minor tweaks)" : ""
    },
      sort and filter ${entryName}s based on score, and see new ${entryName}s using the tools on
      the right in your ENTRIES gallery.
    `,
  };
  const ENTRIES_WINNER = {
    beforeShowPromise: () => goToTab({ project, tab: "entries", params }),
    when: {
      show: () => {
        const linkEl = document.querySelector(".tile-left > a");
        if (linkEl) {
          linkEl.style.display = "block";
          linkEl.style["pointer-events"] = "none";
        }

        const btnWrapperEl =
          document.getElementsByClassName("entry-btn-wrapper")[0];
        if (btnWrapperEl) {
          btnWrapperEl.style.display = "block";
        }
      },
      hide: () => {
        const linkEl = document.querySelector(".tile-left > a");
        if (linkEl) {
          linkEl.style.removeProperty("display");
          linkEl.style.removeProperty("pointer-events");
        }

        const btnWrapperEl =
          document.getElementsByClassName("entry-btn-wrapper")[0];
        if (btnWrapperEl) {
          btnWrapperEl.style.removeProperty("display");
        }
      },
    },
    attachTo: { element: ".entry .entry-tile .entry-thumbnail" },
    title: `Pick the winning ${entryName}`,
    text: `
      When your project closes, you will have ${DAYS_TO_AWARD} days to pick the winner(s)
      ${
        canHaveFinalRound
          ? `or select up to ${MAX_ROUND_FINALISTS} finalists for the Finalist Round.
      The Finalist Round gives you an extra ${FINAL_ROUND_DURATION} days to work exclusively with
      your favorite ${creativeName}s`
          : ""
      }.<br/><br/>

      When you're ready to wrap-up, award your favorite ${entryName} and sign the contract.
      The contract transfers full intellectual property rights to you.
      To learn about project wrap-up, take the tour in the wrap-up tab of your project.
    `,
  };
  const ENTRIES_OFFERS = {
    beforeShowPromise: () => {
      return goToTab({ project, tab: "entries", params }).then(() => {
        const btnEl = document.querySelector(".tile-tools .btn-tools");
        const menuEl = document.querySelector(".tile-tools .dropdown-menu");
        if (btnEl && !menuEl) {
          btnEl.click();
        }
        return Promise.resolve();
      });
    },
    when: {
      show: () => {
        const offerElement = document.querySelector(
          "#dropdown_item_make_offer_1 .dropdown-item-button"
        );
        if (offerElement) {
          offerElement.style.color = "white";
        }

        const tipElement = document.querySelector(
          "#dropdown_item_tip_creative_1 .dropdown-item-button"
        );
        if (tipElement) {
          tipElement.style.color = "white";
        }

        const linkEl = document.querySelector(".tile-left > a");
        if (linkEl) {
          linkEl.style.display = "block";
          linkEl.style["pointer-events"] = "none";
        }

        const menuEl = document.getElementsByClassName("tile-tools")[0];
        if (menuEl) {
          menuEl.style.opacity = 1;
        }
      },
      hide: () => {
        const offerElement = document.querySelector(
          "#dropdown_item_make_offer_1 .dropdown-item-button"
        );
        if (offerElement) {
          offerElement.style.removeProperty("color");
        }

        const tipElement = document.querySelector(
          "#dropdown_item_tip_creative_1 .dropdown-item-button"
        );
        if (tipElement) {
          tipElement.style.removeProperty("color");
        }

        const linkEl = document.querySelector(".tile-left > a");
        if (linkEl) {
          linkEl.style.removeProperty("display");
          linkEl.style.removeProperty("pointer-events");
        }

        const menuEl = document.getElementsByClassName("tile-tools")[0];
        if (menuEl) {
          menuEl.style.removeProperty("opacity");
        }
      },
    },
    attachTo: { element: ".tile-tools .dropdown-menu" },
    title: "Make offers and tip any participant",
    text: `
      After you assign ${
        isOtO ? "the award" : "all awards"
      } in your project, you can make offers
      on other ${entryName}s. If you and the ${
      isOtO ? "creative" : "offeree"
    } agree on a price,
      you'll have a separate project wrap-up for that ${entryName}.<br/><br/>

      You can also tip ${
        isOtO ? "the creative" : "any participant"
      } to thank them for their
      "above and beyond" work.
    `,
  };
  const WRAPUP_INTRO = {
    beforeShowPromise: () => goToTab({ project, tab: "wrapup/intro", params }),
    attachTo: { element: "#tab_wrap-up" },
    modalOverlayOpeningPadding: 0,
    title: "Wrap-up tour",
    text: `
      When your project closes, you will have up to ${DAYS_TO_AWARD} days to award your favorite
      entry. What happens after you award? <a href="${wrapupUrl}" target="_blank">Start wrap-up tour</a>.
    `,
  };
  const FOCUS_GROUPS_INTRO = {
    beforeShowPromise: () => goToTab({ project, tab: "focus-groups", params }),
    attachTo: { element: "#tab_focus_groups" },
    modalOverlayOpeningPadding: 0,
    title: `Make smarter ${entryName} decisions`,
    text: `
      Pick up to ${MAX_SELECTED_REVISIONS} ${entryName}s and invite colleagues, customers,
      and others to comment and vote on their favorites. You can launch unlimited focus groups.
      <br/><br/>

      Focus groups work best when you project closes and all ${entryName}s have been submitted,
      but you can launch one anytime.
    `,
  };
  const SETTINGS_AWARDS = {
    beforeShowPromise: () => goToTab({ project, tab: settingsTab, params }),
    attachTo: { element: ".sidebar-container.awards" },
    title: "Adjust or increase awards",
    text: `
      ${
        canHaveFinalRound
          ? "If you haven't started a Finalist Round, you"
          : "You"
      }
      ${
        isOtO
          ? ""
          : "can add awards in your project before you pick the winner(s), and you"
      }
      can increase any existing awards before you approve
      ${isImage ? "final files" : `the winning ${entryName}`}.<br/><br/>

      ${
        canHaveFinalRound
          ? `If you already started a Finalist Round, you can't add or
      increase awards without help from our team. Please contact our support team and we'll
      be happy to help.`
          : ""
      }
    `,
  };
  const SETTINGS_ASSURE = {
    beforeShowPromise: () => goToTab({ project, tab: settingsTab, params }),
    attachTo: { element: ".sidebar-container.awards .assured-container" },
    title: "Assure award(s)",
    text: `
      Consider assuring your award. That means you promise that someone will win. Assured projects
      get higher quality ${entryName}s and more participants.
    `,
  };
  const SETTINGS_FEATURES = {
    beforeShowPromise: () => goToTab({ project, tab: settingsTab, params }),
    attachTo: { element: ".sidebar-container.features" },
    title: "Add features",
    text: `
      If your project category has other optional features (such as search exclusion, promotion,
      privacy, etc.) you can add features here. Just click the pink "Add features" button.
    `,
  };
  const SETTINGS_ACCESS = {
    beforeShowPromise: () => goToTab({ project, tab: settingsTab, params }),
    attachTo: { element: ".access-wrapper" },
    title: "Access",
    text: `
      ${
        isOtO
          ? ""
          : `If you invite or remove participants, you'll see their usernames here.
      ${
        project.status === "open"
          ? `You can invite any creative to participate in your project.
      Just click the "Invite" button to get started.`
          : ""
      }`
      }

      ${!isOtO && project.nda_required ? "<br/><br/>" : ""}

      ${
        project.nda_required
          ? `Because you required ${isOtO ? "the creative" : "all participants"}
      to sign a non-disclosure agreement (NDA), we list their username${
        isOtO ? "" : "s"
      } here,
      show you whether you approved or denied their request to participate, and give you a link to
      download a copy of their NDA. We also sent a copy of each NDA to you via email.`
          : ""
      }
    `,
  };
  const SETTINGS_COLLABORATION = {
    beforeShowPromise: () => goToTab({ project, tab: settingsTab, params }),
    attachTo: { element: ".collaboration-wrapper" },
    title: "Collaborators",
    text: `
      If you invite collaborators to your project, your collaborators (and the permissions you
      give each) will be shown here. You can change permissions for any collaborator at anyime.
    `,
  };
  const STATUS = {
    attachTo: { element: ".tag.project-status" },
    title: "Status (i.e. OPEN)",
    text: "We will always show the status of your project here.",
  };
  const ASSURED = {
    attachTo: { element: ".tag.assured" },
    modalOverlayOpeningPadding: 0,
    title: "Assured",
    text: `
      You elected to assure your award. Smart! Assuring a project means you promise that someone
      will win. Assured projects get higher quality
      ${entryName}s${isOtO ? "." : " and more participants."}
    `,
  };
  const TAGS = {
    attachTo: { element: ".project-tags" },
    title: "Project tags",
    text: `
      If you chose any special features in your project (i.e. promotion, NDA, closed gallery, etc.)
      we will list all selected features here.
    `,
  };
  const DEADLINE = {
    attachTo: { element: ".deadline-label" },
    modalOverlayOpeningPadding: 0,
    title: "Time left",
    text: `
      You'll always know how much time is left in your project.<br/><br/>

      If you find a ${entryName} you love before the project closes and want to pick a
      winner right away, please contact our support team and we'll be happy to help.<br/><br/>

      Once your project closes, you'll have up to ${DAYS_TO_AWARD} days to pick the winner.
      Need more time to pick the winner? You can extend up to ${PROJECT_EXTENSION} more days.
      Need to extend for more than ${PROJECT_EXTENSION} days? Please contact our support team.
    `,
  };
  const ACTIONS_MENU = {
    beforeShowPromise: () => {
      const btnEl = document.querySelector(".project-tools-menu .btn-tools");
      const menuEl = document.querySelector(
        ".project-tools-menu .dropdown-menu"
      );
      if (btnEl && !menuEl) {
        btnEl.click();
      }
      return Promise.resolve();
    },
    attachTo: { element: ".project-tools-menu .dropdown-menu" },
    modalOverlayOpeningPadding: 0,
    title: "Three dot menu",
    text: `
      You can take quick actions on your project in this menu. Although some of these actions are
      also available elsewhere, we created this quick menu to save you time.
    `,
  };

  const steps = [
    BRIEF_INTRO,
    BRIEF_UPDATES,
    BRIEF_FEEDBACK,
    ENTRIES_INTRO,
    ENTRIES_SCORE,
    ENTRIES_VIEWS,
    ENTRIES_TOOLS,
    ENTRIES_WINNER,
  ];

  if (isOwner) {
    steps.push(ENTRIES_OFFERS);
  }

  steps.push(WRAPUP_INTRO);

  if (
    (isOwner && project.max_focus_groups_allowed === null) ||
    project.max_focus_groups_allowed > 0
  ) {
    steps.push(FOCUS_GROUPS_INTRO);
  }

  if (isOwner) {
    steps.push(SETTINGS_AWARDS);

    if (!project.assured) {
      steps.push(SETTINGS_ASSURE);
    }

    if (canUpgrade) {
      steps.push(SETTINGS_FEATURES);
    }

    if (SETTINGS_ACCESS.text.trim()) {
      steps.push(SETTINGS_ACCESS);
    }

    if (project.collaboration_allowed) {
      steps.push(SETTINGS_COLLABORATION);
    }
  }

  steps.push(STATUS);

  if (project.assured) {
    steps.push(ASSURED);
  }

  steps.push(TAGS);

  steps.push(DEADLINE);

  steps.push(ACTIONS_MENU);

  return {
    id: TOUR_ID,
    startBtnLabel: "",
    onComplete: () => goToTab({ project, tab: "entries", params }),
    steps,
  };
};

const mapStateToProps = (state, { params }) => {
  const project = getProject(state);
  const user = state.user.profile_data;

  return getTourDefinition({
    project,
    user,
    params,
    canUpgrade: canUpgrade(state),
  });
};

export default withRouter(connect(mapStateToProps)(Tour));
