import React from "react";
import PropTypes from "prop-types";
import { browserHistory } from "react-router";
import { getSubCategory } from "common/ducks/categories";
import { BUNDLE_SUBCATS } from "pap0/components/categories_grid";
import SubcategoryIcon from "common/components/subcategory_icon";
import ModalDialog from "common/components/modal/modal_dialog";
import Money from "common/components/money";

const ITEMS = [
  { slug: "logo-design", description: "I just need a logo." },
  {
    slug: "logo-and-business-card-design",
    description: "I need a logo, business card, and letterhead.",
  },
  {
    slug: "logo-and-social-media-assets-design",
    description: "I need a logo and social media assets.",
  },
];

const SelectLogoSubcatModal = ({ showing, onClose, location, categories }) => {
  const closeAndGoBack = () => {
    onClose();
    browserHistory.goBack();
  };
  const onSubcatClick = (slug) => {
    browserHistory.replace({
      ...location,
      pathname: `/start-a-project/logo-identity/${slug}/details/package/`,
    });
    onClose();
  };

  return (
    <ModalDialog
      modalOpen={showing}
      modalHeader="Before you continue..."
      dialogClasses="select-logo-subcat-modal"
      contentClasses="wide"
      closeAction={closeAndGoBack}
      onPrimary={closeAndGoBack}
      primaryLabel="Back"
    >
      <div className="row">
        <div className="row columns small-16">
          <h3>
            We are excited to help create a custom logo for your brand! Which of
            the following would best suit your design goals?
          </h3>
        </div>

        <div className="row small-up-1 medium-up-3 categories-grid">
          {ITEMS.map((item) => {
            const subcat = getSubCategory(categories, item.slug);
            const savings = BUNDLE_SUBCATS[item.slug]?.savings;

            if (!subcat) {
              return null;
            }

            return (
              <div key={item.slug} className="columns">
                <div
                  className="tile subcategory"
                  onClick={() => onSubcatClick(subcat.slug)}
                >
                  <SubcategoryIcon subcat={subcat} />
                  <h4>{subcat.name}</h4>
                  <p className="description">{item.description}</p>

                  <div className="pricing">
                    <label>From</label>
                    <Money
                      className="min-price"
                      value={subcat["minimum_price"]}
                      showDecimals={false}
                    />
                    {!!savings && (
                      <div className="bundle-savings">
                        Save <Money value={savings} showDecimals={false} />
                      </div>
                    )}
                  </div>
                  <div className="actions">
                    <button className="btn btn-primary">Start</button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </ModalDialog>
  );
};

SelectLogoSubcatModal.propTypes = {
  showing: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  categories: PropTypes.object.isRequired,
};

export default SelectLogoSubcatModal;
