import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import idx from "idx";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {
  TYPE_ERROR,
  TYPE_INFO,
  TYPE_WARNING,
  TYPE_SUCCESS,
} from "common/ducks/banner";

const refresh = () => window.location.reload();

const DEFAULTS = {
  [TYPE_ERROR]: {
    title: "Whoops! Something went wrong!",
    content: "Sorry, an error came up just now.",
    icon: <i className="fa fa-exclamation" />,
  },
  [TYPE_INFO]: {
    title: "We've got a new info for you!",
    content: "",
    icon: <i className="fa fa-info" />,
  },
  [TYPE_WARNING]: {
    title: "We've got a new info for you!",
    content: "",
    icon: <i className="fa fa-info" />,
  },
  [TYPE_SUCCESS]: {
    title: "Success!",
    content: "",
    icon: <i className="fa fa-check" />,
  },
};

/**
 * Top banner.
 *
 * @author Jing Wang <jing@crowdspring.com>
 * @author Nathan Ozelim <nathan@startupfoundry.com>
 */
const Banner = ({ hide, classes, title, content, showing, type, icon }) => {
  const defaultTitle = idx(DEFAULTS, (_) => _[type].title);
  const defaultContent = idx(DEFAULTS, (_) => _[type].content);
  const defaultIcon = idx(DEFAULTS, (_) => _[type].icon);

  return (
    <ReactCSSTransitionGroup
      transitionName="banner-transition"
      transitionEnterTimeout={250}
      transitionLeaveTimeout={250}
    >
      {showing && (
        <div className={classnames("banner", type, classes)}>
          <button type="button" className="close" onClick={hide}>
            <i className="fa fa-times-circle" />
          </button>
          <div className="row">
            <div className="columns small-3 medium-2 banner-icon">
              {icon || defaultIcon}
            </div>
            <div className="columns small-12 medium-13 banner-body">
              <h3>{title || defaultTitle}</h3>
              <div>
                <span className="content">{content || defaultContent}</span>
                {/* Show message to refresh the page only if no error content is present */}
                {type === TYPE_ERROR && !content && (
                  <span>
                    {" "}
                    Please{" "}
                    <button type="button" className="refresh" onClick={refresh}>
                      refresh the page
                    </button>
                    .
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </ReactCSSTransitionGroup>
  );
};

Banner.propTypes = {
  hide: PropTypes.func.isRequired,
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  showing: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.string,
  icon: PropTypes.node,
};

export default Banner;
