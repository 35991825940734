import request from "axios";
import queryString from "query-string";
import { DEFAULT_PAGINATION_PAGE_SIZE } from "common/pager/constants";

/* ACTIONS
================================================================================================ */
const CLEAR_PARENT_PAGE = "common/item_navigation/CLEAR_PARENT_PAGE";

const FETCH_PARENT_PAGE = "common/item_navigation/FETCH_PARENT_PAGE";
const FETCH_PARENT_PAGE_SUCCESS =
  "common/item_navigation/FETCH_PARENT_PAGE_SUCCESS";

/* HELPERS
================================================================================================ */
export const getItemUrl = ({ baseUrl, listQuery, currentPage, list, item }) => {
  const itemIdx =
    DEFAULT_PAGINATION_PAGE_SIZE * (currentPage - 1) +
    list.findIndex((i) => i.id === item.id);

  return `${baseUrl}?list_query=${encodeURIComponent(
    listQuery
  )}&idx=${itemIdx}`;
};

const processParentPageData = (action) => {
  const updatedState = {
    previous: null,
    next: null,
    current: null,
    totalCount: 0,
  };
  const currentRelativeIdx = action.payload.results.findIndex(
    (i) => i.id === parseInt(action.id)
  );

  if (currentRelativeIdx < 0) {
    return updatedState;
  }

  updatedState.totalCount = action.payload.count;

  updatedState.current = {
    idx: action.offset + currentRelativeIdx,
  };

  if (currentRelativeIdx > 0) {
    updatedState.previous = {
      id: action.payload.results[currentRelativeIdx - 1].id,
      idx: updatedState.current.idx - 1,
    };
  }

  if (currentRelativeIdx < action.payload.results.length - 1) {
    updatedState.next = {
      id: action.payload.results[currentRelativeIdx + 1].id,
      idx: updatedState.current.idx + 1,
    };
  }

  return updatedState;
};

/* INITIAL STATES
================================================================================================ */
const initialState = {
  previous: null,
  next: null,
  current: null,
  totalCount: 0,
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PARENT_PAGE_SUCCESS:
      return {
        ...state,
        ...processParentPageData(action),
      };
    case CLEAR_PARENT_PAGE:
      return {
        ...state,
        previous: null,
        next: null,
        current: null,
        totalCount: 0,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const clearParentPage = () => (dispatch) =>
  dispatch({
    type: CLEAR_PARENT_PAGE,
  });

export const fetchParentPage =
  (id, itemIdx, listQuery, apiUrl) => (dispatch) => {
    /* `itemIdx` is just an estimate of where item with ID `id` may be found within
     the list. That's why we use the position found in `processParentPageData()` instead and
     use a page as large as possible below. */
    const offset = Math.max(itemIdx - DEFAULT_PAGINATION_PAGE_SIZE / 2, 0);
    const limit = DEFAULT_PAGINATION_PAGE_SIZE;
    const query = queryString.stringify({
      ...listQuery,
      offset,
      limit,
    });

    return dispatch({
      type: FETCH_PARENT_PAGE,
      promise: request.get(`${apiUrl}?${query}`),
      id,
      offset,
    });
  };
