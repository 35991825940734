import React from "react";
import PropTypes from "prop-types";
import { browserHistory } from "react-router";
import ModalDialog from "common/components/modal/modal_dialog";
import SelectPackageSubcat from "landing_pages/containers/select_package_subcat";

const SelectPackageSubcatModal = ({ showing, onClose }) => {
  const closeAndGoBack = () => {
    onClose();
    browserHistory.goBack();
  };

  return (
    <ModalDialog
      modalOpen={showing}
      dialogClasses="select-package-subcat-modal marketing"
      contentClasses="wide"
      modalHeader="Before you continue..."
      closeAction={closeAndGoBack}
      showClose={true}
      closeOnBackgroundClick={false}
    >
      <SelectPackageSubcat onLinkClick={onClose} replace={true} />
    </ModalDialog>
  );
};

SelectPackageSubcatModal.propTypes = {
  showing: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SelectPackageSubcatModal;
