import request from "axios";
import union from "lodash/union";
import without from "lodash/without";
import { fetchProject } from "project/ducks/project";
import { updateUserLocal } from "common/ducks/user";

export const FETCH_WATCH = "crowdspring/watchlist/FETCH_WATCH";
export const FETCH_WATCH_REQUEST = "crowdspring/watchlist/FETCH_WATCH_REQUEST";
export const FETCH_WATCH_FAILURE = "crowdspring/watchlist/FETCH_WATCH_FAILURE";
export const FETCH_WATCH_SUCCESS = "crowdspring/watchlist/FETCH_WATCH_SUCCESS";

export const FETCH_UNWATCH = "crowdspring/watchlist/FETCH_UNWATCH";
export const FETCH_UNWATCH_REQUEST =
  "crowdspring/watchlist/FETCH_UNWATCH_REQUEST";
export const FETCH_UNWATCH_FAILURE =
  "crowdspring/watchlist/FETCH_UNWATCH_FAILURE";
export const FETCH_UNWATCH_SUCCESS =
  "crowdspring/watchlist/FETCH_UNWATCH_SUCCESS";

export const watch = (projectId) => (dispatch, getState) => {
  const user = getState().user.profile_data;

  const promise = request({
    method: "get",
    url: `/api/v1/projects/${projectId}/watch/`,
  });

  promise.then(() => {
    /* fetch project with updated watchers count */
    dispatch(fetchProject(projectId));
    dispatch(
      updateUserLocal({
        ...user,
        watchlist_ids: union(user.watchlist_ids || [], [parseInt(projectId)]),
      })
    );
  });

  return dispatch({
    type: FETCH_WATCH,
    promise,
    payload: projectId,
  });
};

export const unwatch = (projectId) => (dispatch, getState) => {
  const user = getState().user.profile_data;

  const promise = request({
    method: "get",
    url: `/api/v1/projects/${projectId}/unwatch/`,
  });

  promise.then(() => {
    /* fetch project with updated watchers count */
    dispatch(fetchProject(projectId));
    dispatch(
      updateUserLocal({
        ...user,
        watchlist_ids: without(user.watchlist_ids || [], parseInt(projectId)),
      })
    );
  });

  dispatch({
    type: FETCH_UNWATCH,
    promise,
    payload: projectId,
  });

  return promise;
};
