import { connect } from "react-redux";
import { canReserveElite } from "project/utils";
import ReserveButton from "project/components/reserve_button";

export function mapStateToProps(state) {
  return {
    canReserve: canReserveElite(state),
  };
}

export default connect(mapStateToProps)(ReserveButton);
