export const ADD_FORM_NAME = "AddPresentationForm";
export const EDIT_FORM_NAME = "EditPresentationForm";
export const EDIT_REVISIONS_FORM_NAME = "EditRevisionsForm";
export const ADD_PARTICIPANTS_FORM_NAME = "AddParticipantsForm";
export const MAX_SELECTED_REVISIONS = 12;
export const WEIGHT_OPTIONS = [
  {
    field: 1,
    label: "Less",
  },
  {
    field: 2,
    label: "Normal",
  },
  {
    field: 3,
    label: "More",
  },
];
export const WEIGHT_NEUTRAL = WEIGHT_OPTIONS.find(
  (opt) => opt.label === "Normal"
).field;
export const STANDARD_PRIMARY_COLOR = "#BDBEBF";
export const ENTRIES_RESOURCE_NAME = "presentation_entries";
