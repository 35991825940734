import { connect } from "react-redux";
import { withRouter } from "react-router";
import trailingSlashIt from "trailing-slash-it";
import HeaderTitle from "header/components/title";

const mapStateToProps = (state, { location }) => {
  const pathname = trailingSlashIt(location.pathname);
  const isTitleMain =
    pathname.endsWith("/help/") || pathname.endsWith("/help/contact/");

  return {
    title: "We're here for you",
    isTitleMain,
  };
};

export default withRouter(connect(mapStateToProps)(HeaderTitle));
