import request from "axios";
import { setBannerError } from "error";
import { updateUserLocal } from "common/ducks/user";

/* ACTIONS
================================================================================================ */
const ENABLE_START = "account/two_factor_auth/ENABLE_START";
const ENABLE_START_SUCCESS = "account/two_factor_auth/ENABLE_START_SUCCESS";

const ENABLE_COMPLETE = "account/two_factor_auth/ENABLE_COMPLETE";
const ENABLE_COMPLETE_SUCCESS =
  "account/two_factor_auth/ENABLE_COMPLETE_SUCCESS";

const ENABLE_CANCEL = "account/two_factor_auth/ENABLE_CANCEL";

/* HELPERS
================================================================================================ */

/* INITIAL STATES
================================================================================================ */
const initialState = {
  enableData: null,
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ENABLE_START_SUCCESS:
      return {
        ...state,
        enableData: action.payload,
      };
    case ENABLE_COMPLETE_SUCCESS:
    case ENABLE_CANCEL:
      return {
        ...state,
        enableData: null,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const enableStart = (userId) => (dispatch) => {
  const promise = request.patch(
    `/api/v1/users/${userId}/two_factor_auth/enable_start/`
  );

  promise.catch((err) => {
    const errorMsg = err.data ? JSON.stringify(err.data) : "Unknown error.";
    dispatch(setBannerError("Error enabling 2FA", errorMsg));
  });

  return dispatch({
    type: ENABLE_START,
    promise,
  });
};

export const enableComplete = (userId, data) => (dispatch) => {
  const promise = request.patch(
    `/api/v1/users/${userId}/two_factor_auth/enable_complete/`,
    data
  );

  promise.then((res) => {
    /* update local user fields related to 2FA */
    dispatch(updateUserLocal(res.data));
  });

  dispatch({
    type: ENABLE_COMPLETE,
    promise,
  });

  return promise;
};

export const enableCancel = () => (dispatch) =>
  dispatch({
    type: ENABLE_CANCEL,
  });

export const disable = (userId) => (dispatch) => {
  const promise = request.patch(
    `/api/v1/users/${userId}/two_factor_auth/disable/`
  );

  promise
    .then((res) => {
      /* update local user fields related to 2FA */
      dispatch(updateUserLocal(res.data));
    })
    .catch((err) => {
      const errorMsg = err.data ? JSON.stringify(err.data) : "Unknown error.";
      dispatch(setBannerError("Error disabling 2FA", errorMsg));
    });

  return promise;
};
