import request from "axios";
import idx from "idx";
import pick from "lodash/pick";
import isEqual from "lodash/isEqual";
import rangeRight from "lodash/rangeRight";
import sum from "lodash/sum";
import moment from "moment";
import { setBannerError } from "error";

/* ACTIONS
================================================================================================ */
const TOGGLE_EMAIL_EDITOR = "email_automations/TOGGLE_EMAIL_EDITOR";

const FETCH_LEAD_COUNT = "email_automations/FETCH_LEAD_COUNT";
const FETCH_LEAD_COUNT_REQUEST = "email_automations/FETCH_LEAD_COUNT_REQUEST";
const FETCH_LEAD_COUNT_SUCCESS = "email_automations/FETCH_LEAD_COUNT_SUCCESS";

const SEND_TEST_EMAIL = "email_automations/SEND_TEST_EMAIL";

const FETCH_LEAD_QUERY_PARTS = "email_automations/FETCH_LEAD_QUERY_PARTS";
const FETCH_LEAD_QUERY_PARTS_SUCCESS =
  "email_automations/FETCH_LEAD_QUERY_PARTS_SUCCESS";

const TOGGLE_HELP = "email_automations/TOGGLE_HELP";

/* HELPERS
================================================================================================ */
export const unsubscribeRequest = (data) =>
  request.patch("/api/v1/non_transactional_email/unsubscribe/", data);

export const confirmSubscriptionRequest = (id) =>
  request.patch(`/api/v1/subscription_confirmations/${id}/confirm/`);

export const getQueryPartIdSets = (partBreakdownSets, allQueryParts) => {
  return partBreakdownSets.map((breakDowns) =>
    breakDowns
      .map(fromBreakdownToQueryPart)
      .map((slug) => allQueryParts.find((p) => p.slug === slug).id)
  );
};

export const saveEntryConditions = (current, updated, allQueryParts) => {
  /* no need to perform further requests if nothing has changed */
  if (isEqual(current, updated)) {
    return Promise.resolve(current.map((c) => c.id));
  }

  /* create new conditions keeping track of their IDs */
  let newConditionIds = [];
  const promise = updated.reduce(
    (acc, c) =>
      acc.then(() => {
        const queryPartIds = c.lead_query_parts
          .map(fromBreakdownToQueryPart)
          .map((slug) => allQueryParts.find((p) => p.slug === slug).id);
        const createPromise = request.post("/api/v1/bb/entry_conditions/", {
          lead_query_parts: queryPartIds,
        });
        createPromise.then(
          (res) => (newConditionIds = newConditionIds.concat(res.data.id))
        );
        return createPromise;
      }),
    Promise.resolve()
  );

  return promise.then(() => newConditionIds);
};

const QP_BREAKDOWN_MAP = {
  "user-is": (slug) => {
    const userIs = slug.split("user-is-")[1];

    if (userIs.includes("lazy-registered")) {
      return { criteria: "registrationType", registrationType: slug };
    }

    if (userIs.includes("email-validated")) {
      return { criteria: "emailValidation", emailValidation: slug };
    }

    if (userIs.includes("allowed-to-participate")) {
      return { criteria: "participationStatus", participationStatus: slug };
    }

    if (userIs.includes("available-for-1-to-1")) {
      return { criteria: "otoStatus", otoStatus: slug };
    }

    if (["client", "creative"].includes(userIs)) {
      return { criteria: "type", type: userIs };
    }

    return { criteria: "status", status: userIs };
  },
  "user-date-joined-gtenow": (slug) => ({
    criteria: "registrationDate",
    registrationDate: slug,
  }),
  "user-date-joined-ltnow": (slug) => ({
    criteria: "registrationDate",
    registrationDate: slug,
  }),
  "user-mksg": (slug) => ({
    criteria: "segment",
    segment: slug.split("user-mksg-")[1],
  }),
  "user-converted-from": (slug) => ({
    criteria: "conversionSource",
    conversionSource: slug.split("user-converted-from-")[1],
  }),
  "user-country-tier": (slug) => ({
    criteria: "userCountryTier",
    userCountryTier: slug.split("user-country-tier-")[1],
  }),
  "user-prtc-creative": (slug) => ({
    criteria: "verificationStatus",
    verificationStatus: slug.split("user-prtc-")[1],
  }),
  "user-prtc-no-successful-creative": (slug) => ({
    criteria: "verificationStatus",
    verificationStatus: slug.split("user-prtc-")[1],
  }),
  "user-prtc-permitted-subcat": (slug) => ({
    criteria: "approvedSubcat",
    subCategory: slug.split("user-prtc-permitted-subcat-")[1],
  }),
  "user-prtc-gte1": (slug) => ({
    criteria: "referrals",
    referrals: slug.split("user-prtc-")[1],
  }),
  "user-rep": (slug) => ({
    criteria: "reputation",
    reputation: slug.split("user-rep-")[1],
  }),
  /* must come before `proj-drft`, otherwise it will never be matched */
  "prj-drft-most-recent": (slug) => ({
    criteria: "mostRecentDraftAt",
    mostRecentDraftAt: slug.split("prj-drft-most-recent-")[1],
  }),
  "prj-drft": (slug) => {
    const number = slug.split("-")[2];
    let countryTier, draftQualification;

    if (slug.includes("-atier-")) {
      countryTier = "atier";
    } else if (slug.includes("-btier-")) {
      countryTier = "btier";
    }

    if (slug.includes("-unq-")) {
      draftQualification = "unq";
    } else if (slug.includes("-qual-")) {
      draftQualification = "qual";
    }

    let toRemoveFromSlug = ["prj-drft", `-${number}`];
    if (countryTier) {
      toRemoveFromSlug = toRemoveFromSlug.concat(`-${countryTier}`);
    }
    if (draftQualification) {
      toRemoveFromSlug = toRemoveFromSlug.concat(`-${draftQualification}`);
    }
    let slugRemainder = slug;
    for (let s of toRemoveFromSlug) {
      slugRemainder = slugRemainder.replace(s, "");
    }

    return {
      criteria: "drafts",
      "number0or1+": slug.split("-")[2],
      subCategory: slugRemainder ? slugRemainder.slice(1) : undefined,
      countryTier,
      draftQualification,
    };
  },
  "prj-open": (slug) => ({
    criteria: "openProjects",
    "number0or1+": slug.split("prj-open-")[1],
  }),
  "prj-clos": (slug) => ({
    criteria: "closedProjects",
    "number1+": slug.split("prj-clos-")[1],
  }),
  /* must come before `proj-awrd`, otherwise it will never be matched */
  "prj-awrd-gte1-": (slug) => ({
    criteria: "unawardedProjects",
    numberUnawarded: slug,
  }),
  "prj-awrd": (slug) => ({
    criteria: "awardedProjects",
    "number1+": slug.split("prj-awrd-")[1],
  }),
  "prj-cmpl": (slug) => ({
    criteria: "completedProjects",
    "number1+": slug.split("prj-cmpl-")[1],
  }),
  /* must come before `proj-post` and `prj-post-most-recent-`, otherwise it will never be matched */
  "prj-post-most-recent-ltnow": (slug) => ({
    criteria: "mostRecentProjectAt",
    mostRecentProjectAt: slug,
  }),
  /* must come before `proj-post` and `prj-post-most-recent-`, otherwise it will never be matched */
  "prj-post-most-recent-eqnow": (slug) => ({
    criteria: "mostRecentProjectAt",
    mostRecentProjectAt: slug,
  }),
  /* must come before `proj-post`, otherwise it will never be matched */
  "prj-post-most-recent-": (slug) => {
    for (let timeSubstr of ["-eqnow-", "-ltnow-"]) {
      if (slug.includes(timeSubstr)) {
        return {
          criteria: "mostRecentSubcatProjectAt",
          mostRecentSubcatProjectAt: timeSubstr + slug.split(timeSubstr)[1],
          subCategory: slug
            .split(timeSubstr)[0]
            .split("prj-post-most-recent-")[1],
        };
      }
    }

    return {
      criteria: "mostRecentProjectSubcat",
      subCategory: slug.split("prj-post-most-recent-")[1],
    };
  },
  "prj-post": (slug) => ({
    criteria: "postedProjects",
    numberPosted: slug.includes("-any-type-")
      ? slug.split("-any-type-")[0] + "-any-type-"
      : slug,
    subCategory: slug.includes("-any-type-")
      ? slug.split("-any-type-")[1]
      : undefined,
  }),
  "prj-rfnd": (slug) => ({
    criteria: "refundedProjects",
    "number1+": slug.split("prj-rfnd-")[1],
  }),
};
export const fromQueryPartToBreakdown = (slug) => {
  for (let slugBeg in QP_BREAKDOWN_MAP) {
    if (slug.startsWith(slugBeg)) {
      return QP_BREAKDOWN_MAP[slugBeg](slug);
    }
  }
  return {};
};

const BREAKDOWN_QP_MAP = (breakDown) => {
  let draftsSlug = `prj-drft-${breakDown["number0or1+"]}`;
  for (let f of ["countryTier", "draftQualification", "subCategory"]) {
    if (breakDown[f]) {
      draftsSlug = draftsSlug + `-${breakDown[f]}`;
    }
  }

  let postedProjectsSlug = breakDown.numberPosted || "";
  if (postedProjectsSlug.includes("-any-type-")) {
    postedProjectsSlug = postedProjectsSlug + breakDown.subCategory;
  }

  return {
    status: `user-is-${breakDown.status}`,
    type: `user-is-${breakDown.type}`,
    registrationDate: breakDown.registrationDate,
    registrationType: breakDown.registrationType,
    emailValidation: breakDown.emailValidation,
    participationStatus: breakDown.participationStatus,
    otoStatus: breakDown.otoStatus,
    segment: `user-mksg-${breakDown.segment}`,
    conversionSource: `user-converted-from-${breakDown.conversionSource}`,
    userCountryTier: `user-country-tier-${breakDown.userCountryTier}`,
    verificationStatus: `user-prtc-${breakDown.verificationStatus}`,
    approvedSubcat: `user-prtc-permitted-subcat-${breakDown.subCategory}`,
    referrals: `user-prtc-${breakDown.referrals}`,
    reputation: `user-rep-${breakDown.reputation}`,
    drafts: draftsSlug,
    openProjects: `prj-open-${breakDown["number0or1+"]}`,
    closedProjects: `prj-clos-${breakDown["number1+"]}`,
    awardedProjects: `prj-awrd-${breakDown["number1+"]}`,
    completedProjects: `prj-cmpl-${breakDown["number1+"]}`,
    unawardedProjects: breakDown.numberUnawarded,
    postedProjects: postedProjectsSlug,
    refundedProjects: `prj-rfnd-${breakDown["number1+"]}`,
    mostRecentProjectAt: breakDown.mostRecentProjectAt,
    mostRecentSubcatProjectAt: `prj-post-most-recent-${breakDown.subCategory}${breakDown.mostRecentSubcatProjectAt}`,
    mostRecentProjectSubcat: `prj-post-most-recent-${breakDown.subCategory}`,
    mostRecentDraftAt: `prj-drft-most-recent-${breakDown.mostRecentDraftAt}`,
  };
};
const fromBreakdownToQueryPart = (breakDown) => {
  return BREAKDOWN_QP_MAP(breakDown)[breakDown.criteria];
};

export const formatMetrics = (payload, period, labels = {}) => {
  const dates = rangeRight(period).map((n) => moment().subtract(n, "days"));
  const series = dates.map((date) => {
    const dataForDate = Object.keys(payload).reduce(
      (acc, metric) => ({
        ...acc,
        [labels[metric] || metric]:
          payload[metric][date.format("YYYY-MM-DD")] || 0,
      }),
      {}
    );

    return {
      date: date.format("MMM D"),
      ...dataForDate,
    };
  });

  const totals = Object.keys(payload).reduce(
    (acc, metric) => ({
      ...acc,
      [labels[metric] || metric]: sum(
        Object.values(payload[metric])
      ).toLocaleString(),
    }),
    {}
  );

  return { series, totals };
};

/* INITIAL STATES
================================================================================================ */
const initialState = {
  emailInfo: null,
  probableLeadCount: null,
  leadQueryParts: [],
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_EMAIL_EDITOR:
      return {
        ...state,
        emailInfo: action.info,
      };
    case FETCH_LEAD_COUNT_REQUEST:
      return {
        ...state,
        probableLeadCount: null,
      };
    case FETCH_LEAD_COUNT_SUCCESS:
      return {
        ...state,
        probableLeadCount: action.payload,
      };
    case FETCH_LEAD_QUERY_PARTS_SUCCESS:
      return {
        ...state,
        leadQueryParts: action.payload,
      };
    case TOGGLE_HELP:
      return {
        ...state,
        helpId: action.id,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const toggleEmailEditor = (info) => (dispatch) => {
  return dispatch({
    type: TOGGLE_EMAIL_EDITOR,
    info,
  });
};

export const fetchProbableLeadCount =
  (partBreakdownSets, unsubscribeStrategy, includeBlockedEmails) =>
  (dispatch, getState) => {
    const { leadQueryParts } = getState().emailAutomations.common;
    const partSets = getQueryPartIdSets(partBreakdownSets, leadQueryParts);

    return dispatch({
      type: FETCH_LEAD_COUNT,
      promise: request.put(`/api/v1/bb/lead_query_parts/count/`, {
        part_sets: partSets,
        unsubscribe_strategy: unsubscribeStrategy,
        include_blocked_emails: includeBlockedEmails,
      }),
    });
  };

export const sendTestEmail =
  (formData, emails, extraContext = "{}") =>
  (dispatch) => {
    /* make sure required fields are present */
    if (!(formData.subject && formData.from_email && formData.from_name)) {
      dispatch(
        setBannerError(
          "Error sending test email",
          'At least "Subject", "Sender email" and "Sender name" must be provided.'
        )
      );
      return Promise.reject();
    }

    const data = {
      emails: (emails || "").split("\n"),
      extra_context: JSON.parse(extraContext),
      ...pick(
        formData,
        "subject",
        "preheader",
        "text_content",
        "from_name",
        "from_email",
        "reply_to",
        "unsubscribe_strategy"
      ),
      html_content: idx(formData, (_) => _.html_data.html) || "",
      extra_link_attributes: {
        ...pick(formData, "utm_source", "utm_medium", "utm_campaign"),
      },
    };

    const promise = request.post(
      "/api/v1/non_transactional_email/send_test_email/",
      data
    );

    promise.catch((err) => {
      const errorMsg =
        idx(err, (_) => _.data.message) ||
        "Please make sure there are no formatting errors.";
      dispatch(setBannerError("Error sending test email", errorMsg));
    });

    dispatch({
      type: SEND_TEST_EMAIL,
      promise,
    });

    return promise;
  };

export const fetchLeadQueryPartsIfNeeded = () => (dispatch, getState) => {
  if (getState().emailAutomations.common.leadQueryParts.length > 0) {
    return Promise.resolve();
  }

  const promise = request.get("/api/v1/bb/lead_query_parts/");

  dispatch({
    type: FETCH_LEAD_QUERY_PARTS,
    promise,
  });

  return promise;
};

export const toggleHelp = (id) => (dispatch) => {
  return dispatch({
    type: TOGGLE_HELP,
    id,
  });
};
