import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import HeaderText from "header/components/title";
import Breadcrumbs from "marketing/components/breadcrumbs";

export const DEFAULT_HEADER_TEXT = "Thousands of projects just like yours.";
export const MEET_CREATIVES = "Meet the world's largest creative team.";
export const MEET_COLLEAGUES = "Meet your fellow creatives.";

export function mapStateToProps({ header, user }) {
  const exploringCreatives = header.content === "explore_creatives";
  const userType = user.profile_data
    ? user.profile_data.user_type
    : "anonymous";
  const creativesHeader =
    userType === "creative" ? MEET_COLLEAGUES : MEET_CREATIVES;
  const showStartProject = user.logged_in === false || userType === "client";
  const cta = showStartProject
    ? { url: "/start-a-project/", label: "Get started" }
    : null;
  const preTitle = (
    <Breadcrumbs
      items={[
        {
          title: "Explore " + (exploringCreatives ? "creatives" : "projects"),
          url: "/explore/" + (exploringCreatives ? "creatives/" : ""),
        },
      ]}
    />
  );

  return {
    title: exploringCreatives ? creativesHeader : DEFAULT_HEADER_TEXT,
    isTitleMain: true,
    cta,
    preTitle,
  };
}

export default connect(mapStateToProps)(HeaderText);
