import React from "react";
import PropTypes from "prop-types";

/**
 * Displays a thumbnail (relies on CSS background-image as opposed to an image tag)
 * with optional children components.
 *
 * @author Nathan Ozelim <nathan@startupfoundry.com>
 */
const Thumbnail = ({
  image,
  onClick,
  children,
  className = "thumbnail",
  dataTip,
  dataFor,
  style,
}) => {
  const updatedStyle = image
    ? { ...style, backgroundImage: `url(${image})` }
    : style;

  return (
    <div
      {...{ className, onClick, style: updatedStyle }}
      data-tip={dataTip}
      data-for={dataFor}
    >
      {children}
    </div>
  );
};

Thumbnail.propTypes = {
  image: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default Thumbnail;
