import React, { Component } from "react";
import PropTypes from "prop-types";
import ModalDialog from "common/components/modal/modal_dialog";
import { ColorPicker as ReactColorPicker } from "@vtaits/react-color-picker";
import { TextInputField } from "common/components/form_fields";

export default class ColorPicker extends Component {
  constructor(props) {
    super(props);

    this.state = { showingPicker: false };
    this.togglePicker = this.togglePicker.bind(this);
  }

  togglePicker(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.setState({ showingPicker: !this.state.showingPicker });
  }

  render() {
    const { value, onChange, colorLabel = "color" } = this.props;
    const isColorValid = /^#[0-9A-F]{6}$/i.test(value || "");

    return (
      <div className="color-picker">
        <div className="color-wrapper">
          {value && isColorValid && (
            <div className="color-preview" style={{ background: value }} />
          )}
          <button
            className="btn btn-text btn-with-icon btn-md"
            onClick={this.togglePicker}
          >
            <i className="fa fa-eyedropper" /> Pick {colorLabel}
          </button>
        </div>
        {/* No `closeAction` is provided because we want to force the user to pick a valid color
            before leaving the modal. */}
        <ModalDialog
          modalOpen={this.state.showingPicker}
          modalHeader={`Pick a ${colorLabel}`}
          dialogClasses="color-picker-modal"
          onPrimary={this.togglePicker}
          primaryLabel="Done"
          primaryDisabled={!isColorValid}
        >
          <ReactColorPicker
            value={value}
            onDrag={onChange}
            saturationWidth={200}
            saturationHeight={200}
          />
          <TextInputField
            meta={{}}
            label="HEX"
            input={{
              value,
              name: "hex",
              onChange: (e) => onChange(e.target.value),
              onKeyPress: (e) =>
                e.key === "Enter" ? e.preventDefault() : undefined,
            }}
          />
        </ModalDialog>
      </div>
    );
  }
}

ColorPicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  colorLabel: PropTypes.string,
};
