import request from "axios";
import idx from "idx";
import { formatChoices } from "common/ducks/user";

/* ACTIONS
================================================================================================ */
const FETCH_FONTS = "crowdspring/contract/FETCH_FONTS";
const FETCH_FONTS_SUCCESS = "crowdspring/contract/FETCH_FONTS_SUCCESS";

const SIGN_CONTRACT = "crowdspring/contract/SIGN_CONTRACT";
const SIGN_CONTRACT_REQUEST = "crowdspring/contract/SIGN_CONTRACT_REQUEST";
const SIGN_CONTRACT_SUCCESS = "crowdspring/contract/SIGN_CONTRACT_SUCCESS";
const SIGN_CONTRACT_FAILURE = "crowdspring/contract/SIGN_CONTRACT_FAILURE";

const FETCH_CUSTOM_CONTRACTS = "crowdspring/contract/FETCH_CUSTOM_CONTRACTS";
const FETCH_CUSTOM_CONTRACTS_SUCCESS =
  "crowdspring/contract/FETCH_CUSTOM_CONTRACTS_SUCCESS";

/* HELPERS
================================================================================================ */

/* Create custom contract PDF */
export const createCustomContractRequest = (projectId, data) => {
  return request.post(`/api/v1/projects/${projectId}/custom-contracts/`, data);
};
/* Delete custom contract PDF */
export const deleteCustomContractRequest = (projectId, id) => {
  return request.delete(
    `/api/v1/projects/${projectId}/custom-contracts/${id}/`
  );
};

/* REDUCERS
 ================================================================================================ */
const initialState = {
  isSigning: false,
  error: "",
  fonts: [],
  customContracts: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SIGN_CONTRACT_REQUEST:
      return {
        ...state,
        isSigning: true,
        error: "",
      };
    case SIGN_CONTRACT_SUCCESS:
      return {
        ...state,
        isSigning: false,
      };
    case SIGN_CONTRACT_FAILURE:
      return {
        ...state,
        isSigning: false,
        error:
          idx(action, (_) => _.error.data.message) ||
          idx(action, (_) => _.error.data.detail) ||
          true,
      };
    case FETCH_FONTS_SUCCESS:
      return {
        ...state,
        fonts: formatChoices(action.payload),
      };
    case FETCH_CUSTOM_CONTRACTS_SUCCESS:
      return {
        ...state,
        customContracts: action.payload,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const fetchFontsIfNeeded = () => (dispatch, getState) => {
  const state = getState();

  if (state.contract.fonts.length === 0) {
    return dispatch({
      type: FETCH_FONTS,
      promise: request({ method: "get", url: "/api/v1/contracts/fonts/" }),
    });
  }

  return Promise.resolve();
};

export const sign =
  (contractId, signatureData, onSuccess, customRequest) => (dispatch) => {
    /* use custom request if provided, passing in signature data */
    const promise = customRequest
      ? customRequest(signatureData)
      : request.post(`/api/v1/contracts/${contractId}/sign/`, signatureData);
    promise.then(onSuccess);
    return dispatch({
      type: SIGN_CONTRACT,
      promise: promise,
    });
  };

/* Fetch custom contract PDFs for a given project */
export const fetchCustomContracts = (projectId) => (dispatch) =>
  projectId
    ? dispatch({
        type: FETCH_CUSTOM_CONTRACTS,
        promise: request.get(`/api/v1/projects/${projectId}/custom-contracts/`),
      })
    : Promise.resolve();
