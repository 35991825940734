import React from "react";
import PropTypes from "prop-types";
import idx from "idx";
import partial from "lodash/partial";

const MAP = {
  image: true,
  name: false,
};

export const getDefaultGrouping = () => true;

const GroupingSelector = ({ grouped, entryType, onChange }) => {
  const canChange = idx(MAP, (_) => _[entryType]);

  if (!canChange) {
    return null;
  }

  return (
    <div className="controls-group grouping-control">
      <h5>Revisions</h5>
      <div className="checkbox">
        <input
          id="group-revisions"
          type="checkbox"
          className="form-control"
          checked={grouped}
          onChange={(e) => onChange(e.target.checked)}
        />
        <label htmlFor="group-revisions" className="form-label">
          Group revisions
        </label>
      </div>
    </div>
  );
};

GroupingSelector.propTypes = {
  grouped: PropTypes.bool,
  entryType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default GroupingSelector;
