import React from "react";
import PropTypes from "prop-types";
import Icon from "common/components/svg_icon";

/**
 * Component rendering additional explore header buttons
 * @author Kuba Siemiątkowski <kuba@crowdspring.com>
 * @author Jing Wang <jing@crowdspring.com>
 */
const HeaderButtons = ({ toggleRightDrawer }) => (
  <div className="explore-header-controls">
    <div className="hide-for-large button-wrapper">
      <button
        type="button"
        onClick={toggleRightDrawer}
        className="btn btn-icon-only btn-filter"
      >
        <Icon extraClasses="svg-icon-lg filter" icon="filter" />
      </button>
    </div>
  </div>
);

HeaderButtons.propTypes = {
  toggleRightDrawer: PropTypes.func.isRequired,
};

export default HeaderButtons;
