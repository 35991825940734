import React from "react";
import PropTypes from "prop-types";
import omit from "lodash/omit";
import moment from "moment";
import DatePicker from "react-datepicker";
import { FormGroup } from "common/components/form_fields";

const DATE_PICKER_DATE_FORMAT = "MM/DD/YYYY";
const DatePickerField = (field) => {
  const { input } = field;

  return (
    <FormGroup field={field}>
      <DatePicker
        {...omit(input, "value")}
        onBlur={
          /* pass no arguments to prevent setting invalid values */
          (e) => input.onBlur()
        }
        dateFormat={DATE_PICKER_DATE_FORMAT}
        onChange={(e) => input.onChange(e ? e.format() : "")}
        selected={
          /* `input.value` must be either missing or a valid timestamp */
          input.value ? moment(input.value) : null
        }
      />
    </FormGroup>
  );
};

export default DatePickerField;
