import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";

const PHONE = {
  brandStudio: "",
  brandWizard: "",
  default: "+1.877.887.7442",
};
const EMAIL = {
  brandStudio: "/help/contact/?category=brand_studio",
  brandWizard: "/help/contact/?category=brand_studio",
  default: "/help/contact/",
};

/**
 * Drawer component sliding from top with contact info
 * @author Kuba Siemiątkowski <kuba@crowdspring.com>
 */
const HelpDrawer = ({ desktop, content }) => {
  const phone =
    PHONE[content] === undefined ? PHONE["default"] : PHONE[content];
  const email = EMAIL[content] || EMAIL["default"];

  if (desktop) {
    return (
      <div className="help-drawer help-drawer-desktop">
        <p>
          Questions?{" "}
          {!!phone && (
            <span className="tel-wrapper">
              <a className="tel" href={`tel:${phone}`}>
                {phone}
              </a>{" "}
              or{" "}
            </span>
          )}
          <Link to={email} target="_blank">
            Email us
          </Link>
        </p>
      </div>
    );
  }

  return (
    <div className="help-drawer help-drawer-mobile">
      <div>Questions?</div>
      {!!phone && (
        <div className="tel-wrapper">
          <a href={`tel:${phone}`}>{phone}</a>
        </div>
      )}
      <div>
        <a href={email} target="_blank">
          Email us
        </a>
      </div>
    </div>
  );
};

HelpDrawer.propTypes = {
  desktop: PropTypes.bool,
  content: PropTypes.string,
};

export default HelpDrawer;
