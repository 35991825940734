import { connect } from "react-redux";
import { REFERRAL_SOURCE_BS } from "marketing/constants";
import ReferAFriendHeader from "marketing/containers/refer_a_friend_header";

const mapStateToProps = () => {
  return {
    source: REFERRAL_SOURCE_BS,
  };
};

export default connect(mapStateToProps)(ReferAFriendHeader);
