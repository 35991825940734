import React, { Component } from "react";
import PropTypes from "prop-types";
import upperFirst from "lodash/upperFirst";
import isEqual from "lodash/isEqual";
import { TextInputField } from "common/components/form_fields";
import DatePickerField from "common/components/form_fields/date_picker";

/**
 * Range filter field.
 *
 * @author Nathan Ozelim <nathan@startupfoundry.com>
 */
export default class Range extends Component {
  constructor(props) {
    super(props);

    /* this.props.value reflects this.state when inputs are blurred */
    this.state = props.value;
    this.updateValue = this.updateValue.bind(this);
  }

  componentDidUpdate(prevProps) {
    /* update this.state to make sure it reflects this.props.value in case
       it was changed outside of this component */
    if (!isEqual(prevProps.value, this.props.value)) {
      this.setState(this.props.value);
    }
  }

  updateValue() {
    this.props.onChange(this.state);
  }

  renderField(key) {
    const { field, fieldLabel, date } = this.props;
    const defaultFieldLabel = date ? "date" : "amount";
    const commonInputProps = {
      name: `filter_${field}_${key}`,
      value: this.state[key],
      onBlur: this.updateValue,
    };

    return (
      <div>
        {key === "start" ? (
          <h6>{upperFirst(fieldLabel || defaultFieldLabel)} between:</h6>
        ) : (
          <h6>and:</h6>
        )}

        {date ? (
          <DatePickerField
            meta={{}}
            timestamp={true}
            input={{
              ...commonInputProps,
              /* make sure empty values are always represented as `undefined` */
              onChange: (value) =>
                this.setState({ ...this.state, [key]: value || undefined }),
            }}
          />
        ) : (
          <TextInputField
            meta={{}}
            input={{
              ...commonInputProps,
              /* make sure empty values are always represented as `undefined` */
              onChange: (e) =>
                this.setState({
                  ...this.state,
                  [key]: e.target.value || undefined,
                }),
            }}
          />
        )}
      </div>
    );
  }

  render() {
    return (
      <div className="range-filter">
        {this.renderField("start")}
        {this.renderField("end")}
      </div>
    );
  }
}

Range.propTypes = {
  onChange: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  fieldLabel: PropTypes.string,
  date: PropTypes.bool,
};
