import { connect } from "react-redux";
import { canClose } from "project/utils";
import HeaderDeadline from "project/components/header_deadline";

const mapStateToProps = (state) => {
  const { user } = state;

  return {
    canClose: canClose(state),
    user: user.profile_data,
  };
};

export default connect(mapStateToProps)(HeaderDeadline);
