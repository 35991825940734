export const DISMISSED_FG_BANNERS_COOKIE = "dismissed_fg_banners";

export const ELITE_SPOTS = 5;
export const ELITE_SPOTS_TEXT = "five";
export const ELITE_TIPPED_SPOTS_TEXT = "four";

/* not enforced on the backend, just a soft deadline */
export const DAYS_TO_AWARD = 7;

export const PROJECT_EXTENSION = 7;

export const MAX_ROUND_FINALISTS = 5;

export const FINAL_ROUND_DURATION = 3;

export const FINAL_ROUND_EXTENSION = 2;

export const FINAL_ROUND_FAQ = (isCreative) =>
  isCreative
    ? "/help/working-in-projects/what-is-a-finalist-round-for-creatives/"
    : "/help/managing-project/what-is-a-finalist-round/";

export const PARENT_ENTRIES_RESOURCE_NAME = "parent_entries";

export const PROJECT_STATUS_OPTIONS = [
  { field: "draft", label: "Draft" },
  { field: "1_1_negotiating", label: "Negotiating" },
  { field: "1_1_ready", label: "Awaiting Payment" },
  { field: "open", label: "Open" },
  { field: "closed", label: "Closed" },
  { field: "finalist_round", label: "Finalist Round" },
  { field: "finalist_closed", label: "Finalist Round Closed" },
  { field: "awarded", label: "Wrap-up" },
  { field: "completed", label: "Completed" },
];

export const MIN_PORTFOLIO_ITEMS_PER_PROJECT = {
  elite: 12,
  platinum: 9,
  other: 6,
};
