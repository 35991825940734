import idx from "idx";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Navigation from "header/components/navigation";
import { withRouter } from "react-router";

export function mapStateToProps(state) {
  return {
    links: idx(state, (_) => _.header.topNavigation),
    shouldShow: idx(state, (_) => _.header.showNav),
    isLoggedIn: idx(state, (_) => _.user.logged_in),
  };
}

export default withRouter(connect(mapStateToProps)(Navigation));
