/* eslint jsx-a11y/no-static-element-interactions: 0 */
import PropTypes from "prop-types";
// NOTE: turn off above linting rule because we want the background to be clickable
import React, { Component } from "react";
import classnames from "classnames";
import Icon from "common/components/svg_icon";

class Modal extends Component {
  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { id, register } = this.props;
    register(id);
  }

  componentWillUnmount() {
    const { id, unregister } = this.props;
    unregister(id);
  }

  onToggle() {
    const { toggleModal, id } = this.props;
    toggleModal(id);
  }

  render() {
    const { className, header, show, children } = this.props;

    if (!show) {
      return null;
    }

    const dialogClass = classnames("modal-dialog", className);

    return (
      <div className={dialogClass}>
        <div
          role="button"
          className="modal-background"
          onClick={this.onToggle}
        />
        <div className="modal-content-container">
          <div className="modal-header">
            <h5>{header}</h5>
            <button
              className="btn btn-icon-only close-modal"
              onClick={this.onToggle}
            >
              <Icon icon="close" extraClasses="svg-icon-lg" />
            </button>
          </div>
          <div className="modal-content">{children}</div>
        </div>
      </div>
    );
  }
}

Modal.defaultProps = {
  header: "Modal Header",
  children: "Modal Body",
  className: "",
  show: false,
};

Modal.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  header: PropTypes.string,
  show: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,

  register: PropTypes.func.isRequired,
  unregister: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default Modal;
