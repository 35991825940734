import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";

const Attribute = ({ to, text = "by" }) => {
  const profileURL = `/users/${to}`;

  return (
    <div className="attribution">
      {text} <Link to={profileURL}>{to}</Link>
    </div>
  );
};

export default Attribute;
