import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const PRELOAD_PLACEHOLDER =
  "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";

const BASE_URL = "https://crowdspring.imgix.net/marketing/";
const DEFAULT_PARAMS = "auto=format";

const Image = ({ src, widths, className, alt, maxWidth, style }) => {
  let srcset = undefined,
    defaultSrc = src;

  if (widths) {
    srcset = "";

    /* widths in ascending order */
    [...widths]
      .sort((a, b) => a - b)
      .forEach((width) => {
        const srcForWidth = `${BASE_URL}${src}?${DEFAULT_PARAMS}&w=${width}`;
        srcset += `${srcForWidth} ${width}w,`;
        defaultSrc = srcForWidth;
      });
  }

  return (
    <img
      className={classnames("lazyload", className)}
      alt={alt}
      src={PRELOAD_PLACEHOLDER}
      data-src={defaultSrc}
      data-srcset={srcset}
      data-sizes="auto"
      style={{ ...style, maxWidth }}
    />
  );
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  widths: PropTypes.array,
  className: PropTypes.string,
  alt: PropTypes.string,
  maxWidth: PropTypes.string,
  style: PropTypes.object,
};

export default Image;
