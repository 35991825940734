import request from "axios";
import { v4 } from "uuid";
import { browserHistory } from "react-router";
import { ADMIN_ROOT } from "common/variables";
import { getAPIQueryString } from "common/utils";
import { savePageCount } from "common/pager/ducks";
import {
  GLOBAL_BLOCKED_EMAILS_RESOURCE_NAME,
  UNSUBSCRIBED_EMAILS_RESOURCE_NAME,
  EMAIL_MX_VALIDATIONS_RESOURCE_NAME,
} from "admin/constants";
import { setBannerError } from "error";

/* ACTIONS
================================================================================================ */
const FETCH_GLOBAL = "admin/blocked_emails/FETCH_GLOBAL";
const FETCH_GLOBAL_REQUEST = "admin/blocked_emails/FETCH_GLOBAL_REQUEST";
const FETCH_GLOBAL_SUCCESS = "admin/blocked_emails/FETCH_GLOBAL_SUCCESS";

const FETCH_UNSUBSCRIBED = "admin/blocked_emails/FETCH_UNSUBSCRIBED";
const FETCH_UNSUBSCRIBED_REQUEST =
  "admin/blocked_emails/FETCH_UNSUBSCRIBED_REQUEST";
const FETCH_UNSUBSCRIBED_SUCCESS =
  "admin/blocked_emails/FETCH_UNSUBSCRIBED_SUCCESS";

const FETCH_EMAIL_MX_VALIDATIONS =
  "admin/blocked_emails/FETCH_EMAIL_MX_VALIDATIONS";
const FETCH_EMAIL_MX_VALIDATIONS_REQUEST =
  "admin/blocked_emails/FETCH_EMAIL_MX_VALIDATIONS_REQUEST";
const FETCH_EMAIL_MX_VALIDATIONS_SUCCESS =
  "admin/blocked_emails/FETCH_EMAIL_MX_VALIDATIONS_SUCCESS";

/* HELPERS
================================================================================================ */

/* INITIAL STATES
================================================================================================ */
const initialState = {
  global: null,
  globalCount: 0,
  unsubscribed: null,
  unsubscribedCount: 0,
  emailMxValidations: null,
  emailMxValidationsCount: 0,
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  const { payload } = action;

  switch (action.type) {
    case FETCH_GLOBAL_REQUEST:
      return {
        ...state,
        globalRequestId: action.requestId,
        global: null,
      };
    case FETCH_GLOBAL_SUCCESS:
      /* avoid wrong results due to parallel requests */
      if (action.requestId !== state.globalRequestId) {
        return state;
      }

      return {
        ...state,
        global: payload.results,
        globalCount: payload.count,
      };
    case FETCH_UNSUBSCRIBED_REQUEST:
      return {
        ...state,
        unsubscribedRequestId: action.requestId,
        unsubscribed: null,
      };
    case FETCH_UNSUBSCRIBED_SUCCESS:
      /* avoid wrong results due to parallel requests */
      if (action.requestId !== state.unsubscribedRequestId) {
        return state;
      }

      return {
        ...state,
        unsubscribed: payload.results,
        unsubscribedCount: payload.count,
      };
    case FETCH_EMAIL_MX_VALIDATIONS_REQUEST:
      return {
        ...state,
        emailMxValidationsRequestId: action.requestId,
        emailMxValidations: null,
      };
    case FETCH_EMAIL_MX_VALIDATIONS_SUCCESS:
      /* avoid wrong results due to parallel requests */
      if (action.requestId !== state.emailMxValidationsRequestId) {
        return state;
      }

      return {
        ...state,
        emailMxValidations: payload.results,
        emailMxValidationsCount: payload.count,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const fetchGlobalBlockedEmails = (query) => (dispatch, getState) => {
  const queryStr = getAPIQueryString(
    GLOBAL_BLOCKED_EMAILS_RESOURCE_NAME,
    query,
    {
      extraAPIQuery: { status: "blocked" },
    }
  );
  const promise = request.get(`/api/v1/email_targets/?${queryStr}`);
  const requestId = v4();

  /* save page count in pager state */
  promise.then((res) => {
    /* avoid wrong results due to parallel requests */
    if (getState().admin.blockedEmails.globalRequestId !== requestId) {
      return;
    }
    dispatch(savePageCount(GLOBAL_BLOCKED_EMAILS_RESOURCE_NAME, res));
  });

  return dispatch({
    type: FETCH_GLOBAL,
    requestId,
    promise,
  });
};

export const globalBlock = (data) => (dispatch) => {
  const promise = request.post("/api/v1/email_targets/block/", data);

  promise
    .then(() => {
      browserHistory.push(`${ADMIN_ROOT}/people/blocked-emails/global/`);
    })
    .catch((err) => {
      const errorMsg = err.data ? JSON.stringify(err.data) : "Unknown error.";
      dispatch(setBannerError("Error blocking emails", errorMsg));
    });

  return promise;
};

export const fetchUnsubscribedEmails = (query) => (dispatch, getState) => {
  const queryStr = getAPIQueryString(UNSUBSCRIBED_EMAILS_RESOURCE_NAME, query);
  const promise = request.get(`/api/v1/unsubscribe_list_members/?${queryStr}`);
  const requestId = v4();

  /* save page count in pager state */
  promise.then((res) => {
    /* avoid wrong results due to parallel requests */
    if (getState().admin.blockedEmails.unsubscribedRequestId !== requestId) {
      return;
    }
    dispatch(savePageCount(UNSUBSCRIBED_EMAILS_RESOURCE_NAME, res));
  });

  return dispatch({
    type: FETCH_UNSUBSCRIBED,
    requestId,
    promise,
  });
};

export const unsubscribe = (data) => (dispatch) => {
  const promise = request.post(
    "/api/v1/unsubscribe_list_members/bulk_create/",
    data
  );

  promise
    .then(() => {
      browserHistory.push(
        `${ADMIN_ROOT}/people/blocked-emails/unsubscribe-lists/`
      );
    })
    .catch((err) => {
      const errorMsg = err.data ? JSON.stringify(err.data) : "Unknown error.";
      dispatch(setBannerError("Error unsubscribing emails", errorMsg));
    });

  return promise;
};

export const fetchEmailMxValidations = (query) => (dispatch, getState) => {
  const queryStr = getAPIQueryString(EMAIL_MX_VALIDATIONS_RESOURCE_NAME, query);
  const promise = request.get(`/api/v1/email_mx_validations/?${queryStr}`);
  const requestId = v4();

  /* save page count in pager state */
  promise.then((res) => {
    /* avoid wrong results due to parallel requests */
    if (
      getState().admin.blockedEmails.emailMxValidationsRequestId !== requestId
    ) {
      return;
    }
    dispatch(savePageCount(EMAIL_MX_VALIDATIONS_RESOURCE_NAME, res));
  });

  return dispatch({
    type: FETCH_EMAIL_MX_VALIDATIONS,
    requestId,
    promise,
  });
};
