import request from "axios";
import cookie from "react-cookie";
import sortBy from "lodash/sortBy";
import maxBy from "lodash/maxBy";
import moment from "moment";

/* ACTIONS
================================================================================================ */
const FETCH_SUCCESS = "crowdspring/announcements/FETCH_SUCCESS";

const DISMISS = "crowdspring/announcements/DISMISS";

/* HELPERS
================================================================================================ */
const dismissedCookieName = (item) => `announcement_${item.id}_dismissed`;

const formatItems = (payload) => {
  /* most important items are last */
  return sortBy(
    payload.map((item) => ({
      ...item,
      showing: item.is_dismissable
        ? !cookie.load(dismissedCookieName(item))
        : true,
    })),
    "priority"
  );
};

const markItemAsDismissed = (list, item) =>
  list.map((i) =>
    i.id === item.id && i.type === item.type ? { ...i, showing: false } : i
  );

/* INITIAL STATES
================================================================================================ */
const initialState = {
  items: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SUCCESS:
      return {
        ...state,
        items: formatItems(action.payload),
      };
    case DISMISS:
      return {
        ...state,
        items: markItemAsDismissed(state.items, action.item),
      };
    default:
      return state;
  }
}

export const fetch = () => (dispatch, getState) => {
  const state = getState();
  const user = state.user.profile_data;

  /* fetch announcements, adding a "fake" announcement related to emails not
     being delivered to the current user (if that's the case) */
  request.get("/api/v1/users/announcements/").then((res1) => {
    const storeAnnouncements = (payload) => {
      dispatch({
        type: FETCH_SUCCESS,
        payload,
      });
    };

    if (user.id && !user.is_staff) {
      request
        .get(`/api/v1/users/${user.id}/deliverability/`)
        .then((res2) => {
          if (
            res2.data.status === "blocked" &&
            res2.data.blocked_transactional
          ) {
            const deliverabilityAnnouncement = {
              id: "email_deliverability_announcement",
              /* add with maximum priority */
              priority: (maxBy(res1.data, "priority") || 0) + 1,
              level: "err",
              title: "Action required",
              body: `
<p>
  Oh no! Our emails to you are bouncing and you're not receiving them.
  To fix this, please be sure to <a href="/help/creative-account/whitelist-crowdspring-domain-email/">
  whitelist our domain</a> and check to <a href="/account/info/">be sure your email is accurate</a>.
  If you need help, <a href="/help/contact/">contact our team</a>.
</p>
`,
            };
            storeAnnouncements([...res1.data, deliverabilityAnnouncement]);
          } else {
            storeAnnouncements(res1.data);
          }
        })
        .catch(() => storeAnnouncements(res1.data));
    } else {
      storeAnnouncements(res1.data);
    }
  });
};

export const dismiss =
  (item, save = true) =>
  (dispatch) => {
    /* "dismissable" announcements are dismissed just once */
    if (item.is_dismissable && save) {
      cookie.save(dismissedCookieName(item), true, {
        path: "/",
        expires: moment("2100-12-12").toDate(),
      });
    }

    return dispatch({
      type: DISMISS,
      item,
    });
  };
