import List from "notifications/components/list";
import { connect } from "react-redux";

export function mapStateToProps({ user, notifications }) {
  const notificationsList = user.logged_in ? notifications.data.results : [];

  return {
    notificationsList,
  };
}

export default connect(mapStateToProps)(List);
