import { connect } from "react-redux";
import sortBy from "lodash/sortBy";
import ProjectsControls from "explore/components/projects_controls";
import {
  PROJECTS_SORTING_OPTIONS,
  DEFAULT_PROJECTS_SORTING,
  PROJECTS_STATUS_OPTIONS,
  PROJECTS_TIME_LEFT_OPTIONS,
  PROJECTS_ASSURED_OPTIONS,
  PROJECTS_PACKAGE_OPTIONS,
  PROJECTS_GALLERY_OPTIONS,
  PROJECTS_AWARD_OPTIONS,
} from "explore/constants";
import { excludeDeprecated } from "common/ducks/categories";

export const mapStateToProps = ({ user, categories, industry }) => {
  const cats = excludeDeprecated(categories.data).filter(
    (cat) => !cat.is_one_to_one
  );
  const subcategoryOptions = sortBy(cats, "name").reduce((acc, cat) => {
    return acc.concat(
      sortBy(
        cat.subcategories.map((subcat) => {
          return {
            field: subcat.id.toString(),
            value: subcat.id.toString(),
            label: subcat.name,
            group: cat.name,
          };
        }),
        "label"
      )
    );
  }, []);

  return {
    sortingOptions: PROJECTS_SORTING_OPTIONS,
    defaultSorting: DEFAULT_PROJECTS_SORTING(user),
    industryOptions: sortBy(
      industry.data.map((ind) => ({ value: ind.slug, label: ind.name })),
      "label"
    ),
    statusOptions: PROJECTS_STATUS_OPTIONS,
    timeLeftOptions: PROJECTS_TIME_LEFT_OPTIONS,
    assuredOptions: PROJECTS_ASSURED_OPTIONS,
    packageOptions: PROJECTS_PACKAGE_OPTIONS,
    galleryOptions: PROJECTS_GALLERY_OPTIONS,
    awardsOptions: PROJECTS_AWARD_OPTIONS,
    subcategoryOptions,
  };
};

export default connect(mapStateToProps)(ProjectsControls);
