import React from "react";
import PropTypes from "prop-types";
import {
  TYPE_SELECT,
  TYPE_CHECKBOXES,
  TYPE_RANGE_SELECT,
} from "common/filter/constants";
import { PROJECTS_RESOURCE_NAME } from "explore/constants";
import Searcher from "common/searcher/containers";
import SorterSelect from "common/sorter/components/select";
import Filter from "common/filter/containers";

const ProjectsControls = ({
  sortingOptions,
  defaultSorting,
  subcategoryOptions,
  industryOptions,
  statusOptions,
  timeLeftOptions,
  assuredOptions,
  packageOptions,
  galleryOptions,
  awardsOptions,
  skipQuerySave,
}) => (
  <aside className="sidebar sidebar-filter">
    <div className="controls-group">
      <Searcher resourceName={PROJECTS_RESOURCE_NAME} />
    </div>
    <div className="controls-group">
      <div className="group-title">Sort</div>
      <SorterSelect
        resourceName={PROJECTS_RESOURCE_NAME}
        options={sortingOptions}
        defaultValue={defaultSorting}
      />
    </div>
    <div className="controls-group">
      <div className="group-title">Industry</div>
      <Filter
        field="industry"
        type={TYPE_SELECT}
        resourceName={PROJECTS_RESOURCE_NAME}
        options={industryOptions}
        skipQuerySave={skipQuerySave}
      />
    </div>
    <div className="controls-group">
      <div className="group-title">Time Left</div>
      <Filter
        field="deadline_remaining__lte"
        type={TYPE_SELECT}
        skipQuerySave={skipQuerySave}
        resourceName={PROJECTS_RESOURCE_NAME}
        options={timeLeftOptions}
      />
    </div>
    <div className="controls-group">
      <div className="group-title">Award</div>
      <Filter
        field="total_awards_and_tips"
        type={TYPE_RANGE_SELECT}
        resourceName={PROJECTS_RESOURCE_NAME}
        options={awardsOptions}
        skipQuerySave={skipQuerySave}
      />
    </div>
    <div className="controls-group">
      <div className="group-title">Category</div>
      <Filter
        field="subcategory"
        type={TYPE_CHECKBOXES}
        resourceName={PROJECTS_RESOURCE_NAME}
        options={subcategoryOptions}
        skipQuerySave={skipQuerySave}
      />
    </div>
    <div className="controls-group">
      <div className="group-title">Status</div>
      <Filter
        field="status"
        type={TYPE_CHECKBOXES}
        resourceName={PROJECTS_RESOURCE_NAME}
        options={statusOptions}
        skipQuerySave={skipQuerySave}
      />
    </div>
    <div className="controls-group">
      <div className="group-title">Type</div>
      <Filter
        field="assured"
        type={TYPE_CHECKBOXES}
        resourceName={PROJECTS_RESOURCE_NAME}
        options={assuredOptions}
        skipQuerySave={skipQuerySave}
      />
      <Filter
        field="package_name"
        type={TYPE_CHECKBOXES}
        resourceName={PROJECTS_RESOURCE_NAME}
        options={packageOptions}
        skipQuerySave={skipQuerySave}
      />
    </div>
    <div className="controls-group">
      <div className="group-title">Gallery</div>
      <Filter
        field="private_gallery"
        type={TYPE_CHECKBOXES}
        resourceName={PROJECTS_RESOURCE_NAME}
        options={galleryOptions}
        skipQuerySave={skipQuerySave}
      />
    </div>
  </aside>
);

export default ProjectsControls;
