import request from "axios";
import { getProjectIdFromUrl } from "project/utils";

export const FETCH_PAGE = "crowdspring/landing_pages/FETCH_PAGE";
export const FETCH_PAGE_REQUEST =
  "crowdspring/landing_pages/FETCH_PAGE_REQUEST";
export const FETCH_PAGE_FAILURE =
  "crowdspring/landing_pages/FETCH_PAGE_FAILURE";
export const FETCH_PAGE_SUCCESS =
  "crowdspring/landing_pages/FETCH_PAGE_SUCCESS";

export const fetchPageIfNeeded = (params) => (dispatch, getState) => {
  const { pageSlug, subcategory } = params;
  /* page already fetched */
  const { landing_page } = getState().landing_pages;
  const hasProjectId = !!getProjectIdFromUrl(params);
  if (
    (landing_page && landing_page.url_stem === pageSlug) ||
    hasProjectId ||
    subcategory === "assets"
  ) {
    return Promise.resolve();
  }

  const opts = {
    method: "get",
    url: `/api/v1/landing-pages/${pageSlug}/`,
  };

  return dispatch({
    type: FETCH_PAGE,
    promise: request(opts),
  });
};

export const INITIAL_STATE = {
  isLoading: false,
  data: null,
  landing_page: null,
  error: null,
  pageBlockExperiments: [],
  pageExperiments: [],
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_PAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
        landing_page: null,
        error: null,
      };
    case FETCH_PAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        landing_page: action.payload,
        error: null,
      };
    case FETCH_PAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        landing_page: null,
      };
    default:
      return state;
  }
}
