import React from "react";
import PropTypes from "prop-types";
import partial from "lodash/partial";

const Trigger = ({ onClick, children, className, trigger, wrapper }) => {
  const clicked = partial(onClick, trigger);

  if (wrapper === "span") {
    return (
      <span className={className} onClick={clicked}>
        {children}
      </span>
    );
  }

  return (
    <button className={className} onClick={clicked}>
      {children}
    </button>
  );
};

Trigger.propTypes = {
  trigger: PropTypes.string.isRequired, // the id for the modal to trigger
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  wrapper: PropTypes.string,
  children: PropTypes.any,
};

export default Trigger;
