import { combineReducers } from "redux";
import PropTypes from "prop-types";
import ProjectsReducer from "explore/ducks/projects";
import CreativesReducer from "explore/ducks/creatives";

const RootReducer = combineReducers({
  projects: ProjectsReducer,
  creatives: CreativesReducer,
});

export default RootReducer;
