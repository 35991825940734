import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  toggleModal,
  registerModal,
  unregisterModal,
} from "common/modal/ducks";
import Modal from "common/modal/components";

export function mapStateToProps({ modal }, { id }) {
  const data = modal.find((singleModal) => id === singleModal.id) || {};
  return data;
}

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      register: registerModal,
      unregister: unregisterModal,
      toggleModal,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
