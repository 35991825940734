import uniqBy from "lodash/uniqBy";

/* ACTIONS
================================================================================================ */
const SET_SELECTED_ENTRIES = "admin/SET_SELECTED_ENTRIES";

/* HELPERS
================================================================================================ */

/* INITIAL STATES
================================================================================================ */
const initialState = {
  selectedEntries: [],
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SELECTED_ENTRIES:
      return {
        ...state,
        selectedEntries: uniqBy(action.payload, "id"),
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const setSelectedEntries = (payload) => (dispatch) => {
  return dispatch({
    type: SET_SELECTED_ENTRIES,
    payload,
  });
};
