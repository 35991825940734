import { connect } from "react-redux";
import HelpDrawer from "common/components/base_ui/help_drawer";

const mapStateToProps = ({ header }) => {
  const { content } = header;

  return {
    content,
  };
};

export default connect(mapStateToProps)(HelpDrawer);
