import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Link } from "react-router";
import { v4 } from "uuid";
import Tab from "common/components/navigation_tab";
import { isUrlActive } from "common/utils";
import NavigationDropdown from "common/components/navigation_dropdown";

/**
 * TabbedNavigation - component for displaying tabs/responsive dropdown component.
 * @author Kuba Siemiątkowski <kuba@crowdspring.com>
 * @author Jing Wang <jing@crowdspring.com>
 */
export default class TabbedNavigation extends Component {
  formatUrl(url) {
    /* check if the url contains any named params and get them from router */
    const { router } = this.props;
    const namedParams = url.match(/\(:\w+\)/);
    let newUrl = url;

    if (namedParams) {
      const param = namedParams[0].replace(/\W/g, "");
      const paramValue = router.params[param];

      if (param) {
        newUrl = url.replace(namedParams[0], paramValue);
      }
    }

    return newUrl;
  }

  getLinksInfo(links) {
    const { router } = this.props;

    /* augment list of links with information used to properly render tabs/dropdown */
    return links.map((link) => {
      let url = "";
      let isActive = false;
      let subItems = null;

      /* tab has no sub-items */
      if (!link.subItems) {
        url = this.formatUrl(link.url);
        isActive =
          link.active !== undefined
            ? link.active
            : isUrlActive(
                url,
                router.location.pathname,
                link.matchExact,
                link.regex
              );
        /* tab has sub-items */
      } else {
        subItems = [];
        link.subItems.map((item) => {
          const itemUrl = this.formatUrl(item.url);
          const isItemActive =
            item.active !== undefined
              ? item.active
              : isUrlActive(
                  itemUrl,
                  router.location.pathname,
                  item.matchExact,
                  item.regex
                );

          /* if one of the sub-items is active, make the tab active as a consequence */
          if (isItemActive) {
            isActive = true;
          }

          subItems.push({
            component: item.component,
            url: itemUrl,
            isActive: isItemActive,
          });
        });
      }

      return {
        title: link.name,
        onClick: link.onClick,
        isActive,
        url,
        subItems,
      };
    });
  }

  renderTabs(linksInfo) {
    return (
      <ul className="header-tabs dropdown">
        {linksInfo.map(({ url, title, isActive, subItems, onClick }) => (
          <Tab
            key={v4()}
            to={url}
            title={title}
            isActive={isActive}
            subItems={subItems}
            onClick={onClick}
          />
        ))}
      </ul>
    );
  }

  renderDropdown(linksInfo) {
    const { rightDropdown } = this.props;
    const activeLink = linksInfo.find((l) => l.isActive);

    return (
      <div className="more-dropdown-wrapper">
        {!!activeLink && <div className="active-link">{activeLink.title}</div>}
        <NavigationDropdown links={linksInfo} showMore={true} />
      </div>
    );
  }

  render() {
    const { tabs, rightDropdown, dropdownOnly, extraClasses } = this.props;

    if (!tabs || !tabs.links || !tabs.links.length) {
      return null;
    }

    const linksInfo = this.getLinksInfo(tabs.links);

    return (
      <div className={classnames("tabbed-navigation", extraClasses)}>
        <div className={classnames({ "hide-for-large": !dropdownOnly })}>
          {this.renderDropdown(linksInfo)}
        </div>
        {!dropdownOnly && (
          <div className="tab-wrapper tabs show-for-large">
            {this.renderTabs(linksInfo)}
          </div>
        )}
      </div>
    );
  }
}

TabbedNavigation.propTypes = {
  router: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
  tabs: PropTypes.shape({
    links: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        name: PropTypes.string,
        subItems: PropTypes.array,
      })
    ),
  }),
  rightDropdown: PropTypes.bool,
  dropdownOnly: PropTypes.bool,
  extraClasses: PropTypes.string,
};
