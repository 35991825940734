import { connect } from "react-redux";
import { withRouter } from "react-router";
import { setViewType, setGrouping } from "entries/ducks";
import { getProject } from "project/ducks/project";
import {
  isAtYourEntries,
  canViewRating,
  canViewNewTag,
  canViewFinalRoundFilter,
} from "entries/utils";
import Controls from "entries/components/controls";

const mapStateToProps = (state, { routes }) => {
  const { viewType, grouped } = state.entries;
  const user = state.user.profile_data;
  const project = getProject(state);
  const atYourEntries = isAtYourEntries({ routes });

  return {
    canViewRating: canViewRating({ user, project, atYourEntries }),
    canViewNewTag: canViewNewTag({ user, project }),
    canViewFinalRoundFilter: canViewFinalRoundFilter({ user, project }),
    atYourEntries,
    project,
    viewType,
    grouped,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setViewType,
    setGrouping,
  })(Controls)
);
