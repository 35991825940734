import { connect } from "react-redux";
import { withRouter } from "react-router";
import trailingSlashIt from "trailing-slash-it";
import { getProject } from "project/ducks/project";
import { getSubCategory } from "common/ducks/categories";
import { getProjectUrl } from "project/utils";
import Breadcrumbs from "common/components/breadcrumbs";

const mapStateToProps = (state, { location }) => {
  const pathname = trailingSlashIt(location.pathname);
  const project = getProject(state);
  const subCategory = getSubCategory(state.categories, project.sub_category);

  if (!subCategory) {
    return {
      items: [],
    };
  }

  let items = [
    {
      title: "Home",
      url: "/",
    },
  ];

  if (!project.is_one_to_one) {
    items = items.concat([
      {
        title: subCategory.name,
        url: subCategory.deprecated ? "/categories/" : `/${subCategory.slug}/`,
      },
      {
        title: `${subCategory.name} Projects`,
        url: `/explore/?explore_projects_filter_checkboxes_sub_category=${subCategory.id}`,
      },
    ]);
  }

  items = items.concat([
    {
      title: project.title,
      url: getProjectUrl(project, subCategory.slug),
    },
  ]);

  let tabTitle;
  if (pathname.endsWith("your-entries/")) {
    tabTitle = "Your Entries";
  } else if (pathname.endsWith("entries/")) {
    tabTitle = "Entries";
  } else if (pathname.endsWith("tour/")) {
    tabTitle = "Tour";
  } else if (pathname.includes("settings/")) {
    tabTitle = "Settings";
  } else if (pathname.includes("wrapup")) {
    tabTitle = "Wrap-up";
  } else if (pathname.includes("focus-groups")) {
    tabTitle = "Focus Groups";
  } else if (pathname.includes("presentations")) {
    tabTitle = "Presentations";
  } else {
    tabTitle = "Brief";
  }

  items.push({ title: tabTitle, url: pathname });

  return {
    items,
  };
};

export default withRouter(connect(mapStateToProps)(Breadcrumbs));
