import React, { Component } from "react";
import PropTypes from "prop-types";
import { getProjectUrl } from "project/utils";
import { getSubcategorySlug } from "common/ducks/categories";

export default class ProjectRedirect extends Component {
  constructor(props) {
    super(props);
    this.redirectIfNeeded = this.redirectIfNeeded.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.redirectIfNeeded(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.redirectIfNeeded(nextProps);
  }

  redirectIfNeeded({ server, project, categories }) {
    if (!server.res || !server.req || !server.res.redirect) {
      return;
    }

    const subcategory = getSubcategorySlug(categories, project.sub_category);
    const currentUrl = server.req.url;
    const projectUrl = getProjectUrl(project, subcategory);

    if (!categories || !project || currentUrl.indexOf(projectUrl) !== -1) {
      return;
    }

    const projectId = project.id.toString();
    const remainingIndex = currentUrl.indexOf(projectId) + projectId.length;
    const append = currentUrl.slice(remainingIndex);
    const newUrl = getProjectUrl(project, subcategory, append);

    server.res.redirect(301, newUrl);
  }

  render() {
    return <span />;
  }
}
