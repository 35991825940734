import request from "axios";
import PropTypes from "prop-types";
import reverse from "lodash/reverse";
import partial from "lodash/partial";
import sortBy from "lodash/sortBy";
import { ENTRIES_RESOURCE_NAME } from "presentations/constants";
import { getAPIQueryString } from "common/utils";
import { savePageCount } from "common/pager/ducks";

/* ACTION CONSTANTS
================================================================================================ */
const FETCH_ALL = "crowdspring/presentations/FETCH_ALL";
const FETCH_ALL_SUCCESS = "crowdspring/presentations/FETCH_ALL_SUCCESS";

const FETCH = "crowdspring/presentations/FETCH";
const FETCH_SUCCESS = "crowdspring/presentations/FETCH_SUCCESS";

const FETCH_REVISIONS = "crowdspring/presentations/FETCH_REVISIONS";
const FETCH_REVISIONS_SUCCESS =
  "crowdspring/presentations/FETCH_REVISIONS_SUCCESS";

const FETCH_PARTICIPANTS = "crowdspring/presentations/FETCH_PARTICIPANTS";
const FETCH_PARTICIPANTS_SUCCESS =
  "crowdspring/presentations/FETCH_PARTICIPANTS_SUCCESS";

const UPDATE = "crowdspring/presentations/UPDATE";
const UPDATE_SUCCESS = "crowdspring/presentations/UPDATE_SUCCESS";

const UPDATE_PARTICIPANT = "crowdspring/presentations/UPDATE_PARTICIPANT";
const UPDATE_PARTICIPANT_SUCCESS =
  "crowdspring/presentations/UPDATE_PARTICIPANT_SUCCESS";

const REMOVE_PARTICIPANT = "crowdspring/presentations/REMOVE_PARTICIPANT";
const REMOVE_PARTICIPANT_SUCCESS =
  "crowdspring/presentations/REMOVE_PARTICIPANT_SUCCESS";

const RESEND_INVITE = "crowdspring/presentations/RESEND_INVITE";
const RESEND_INVITE_SUCCESS = "crowdspring/presentations/RESEND_INVITE_SUCCESS";

const FETCH_REVISION_COMMENTS =
  "crowdspring/presentations/FETCH_REVISION_COMMENTS";
const FETCH_REVISION_COMMENTS_SUCCESS =
  "crowdspring/presentations/FETCH_REVISION_COMMENTS_SUCCESS";

const TOGGLE_ADD_MODAL = "crowdspring/presentations/TOGGLE_ADD_MODAL";
const CHANGE_ADD_STEP = "crowdspring/presentations/CHANGE_ADD_STEP";

const TOGGLE_EDIT_REVISIONS_MODAL =
  "crowdspring/presentations/TOGGLE_EDIT_REVISIONS_MODAL";

const TOGGLE_ADD_PARTICIPANTS_MODAL =
  "crowdspring/presentations/TOGGLE_ADD_PARTICIPANTS_MODAL";

const SET_HOVERED_REVISION_COMMENT =
  "crowdspring/presentations/SET_HOVERED_REVISION_COMMENT";

const FETCH_ALL_ENTRIES = "crowdspring/presentations/FETCH_ALL_ENTRIES";
const FETCH_ALL_ENTRIES_REQUEST =
  "crowdspring/presentations/FETCH_ALL_ENTRIES_REQUEST";
const FETCH_ALL_ENTRIES_SUCCESS =
  "crowdspring/presentations/FETCH_ALL_ENTRIES_SUCCESS";

const CLEAR_ALL_ENTRIES = "crowdspring/presentations/CLEAR_ALL_ENTRIES";

const SET_ALL_ENTRIES_GROUPING =
  "crowdspring/presentations/SET_ALL_ENTRIES_GROUPING";

/* INITIAL STATES
================================================================================================ */
export const initialState = {
  presentations: [],
  currentPresentation: null,
  currentParticipants: [],
  currentRevisions: [],
  showingAddModal: false,
  addStep: 1,
  showingEditRevisionsModal: false,
  showingAddParticipantsModal: false,
  hoveredRevisionCommentId: null,
  revisionComments: [],
  allEntries: {
    list: [],
    isFetching: false,
    grouped: false,
  },
};

/* HELPERS
================================================================================================ */
export const createRequest = (projectId, data) => {
  return request.post("/api/v1/presentations/", {
    project: projectId,
    ...data,
  });
};

export const addParticipantRequest = (presentationId, data) => {
  return request.post(
    `/api/v1/presentations/${presentationId}/participants/`,
    data
  );
};

export const addRevisionRequest = (presentationId, data) => {
  return request.post(
    `/api/v1/presentations/${presentationId}/image-revisions/`,
    data
  );
};

export const removeRevisionRequest = (presentationId, revisionId) => {
  return request.delete(
    `/api/v1/presentations/${presentationId}/image-revisions/${revisionId}/`
  );
};

export const getAllEntries = (state) => {
  const { list, grouped } = state.project.presentations.allEntries;

  if (grouped) {
    return list;
  }

  /* if not grouped, return an entry copy for each revision */
  return list.reduce((acc, entry) => {
    entry.revisions.forEach(
      (rev) => (acc = acc.concat({ ...entry, revisions: [rev] }))
    );
    return acc;
  }, []);
};

/* REDUCERS
================================================================================================ */
const reducer = (state = initialState, action) => {
  let participants, idx;

  switch (action.type) {
    case FETCH_ALL_SUCCESS:
      return {
        ...state,
        presentations: action.payload,
      };
    case TOGGLE_ADD_MODAL:
      return {
        ...state,
        showingAddModal: !state.showingAddModal,
        addStep: 1,
      };
    case CHANGE_ADD_STEP:
      return {
        ...state,
        addStep: action.payload,
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        currentPresentation: action.payload,
      };
    case FETCH_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        currentParticipants: action.payload,
      };
    case FETCH_REVISIONS_SUCCESS:
      return {
        ...state,
        currentRevisions: action.payload,
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        currentPresentation: action.payload,
      };
    case TOGGLE_EDIT_REVISIONS_MODAL:
      return {
        ...state,
        showingEditRevisionsModal: !state.showingEditRevisionsModal,
      };
    case UPDATE_PARTICIPANT_SUCCESS:
      participants = [...state.currentParticipants];
      idx = participants.findIndex((p) => p.id === action.payload.id);
      participants[idx] = { ...participants[idx], ...action.payload };

      return {
        ...state,
        currentParticipants: participants,
      };
    case REMOVE_PARTICIPANT_SUCCESS:
      participants = [...state.currentParticipants];
      idx = participants.findIndex((p) => p.id === action.participantId);
      participants.splice(idx, 1);

      return {
        ...state,
        currentParticipants: participants,
      };
    case RESEND_INVITE_SUCCESS:
      participants = [...state.currentParticipants];
      idx = participants.findIndex((p) => p.id === action.participantId);
      participants[idx] = { ...participants[idx], inviteResent: true };

      return {
        ...state,
        currentParticipants: participants,
      };
    case TOGGLE_ADD_PARTICIPANTS_MODAL:
      return {
        ...state,
        showingAddParticipantsModal: !state.showingAddParticipantsModal,
      };
    case SET_HOVERED_REVISION_COMMENT:
      return {
        ...state,
        hoveredRevisionCommentId: action.payload,
      };
    case FETCH_REVISION_COMMENTS_SUCCESS:
      return {
        ...state,
        /* by default, comments are sorted from oldest to newest, but we want it
           the other way around */
        revisionComments: reverse(action.payload),
      };
    case FETCH_ALL_ENTRIES_REQUEST:
      return {
        ...state,
        allEntries: {
          ...state.allEntries,
          isFetching: true,
          allQueryStr: action.queryStr,
        },
      };
    case FETCH_ALL_ENTRIES_SUCCESS:
      /* handle race conditions by only updating the state with the result of the
         last request made */
      if (state.allEntries.allQueryStr !== action.queryStr) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        allEntries: {
          ...state.allEntries,
          isFetching: false,
          list: action.payload.results,
        },
      };
    case CLEAR_ALL_ENTRIES:
      return {
        ...state,
        allEntries: {
          ...state.allEntries,
          list: [],
        },
      };
    case SET_ALL_ENTRIES_GROUPING:
      return {
        ...state,
        allEntries: {
          ...state.allEntries,
          grouped: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;

/* ACTION CREATORS
================================================================================================ */
export const fetchAll = (projectId) => (dispatch) => {
  return dispatch({
    type: FETCH_ALL,
    promise: request.get(`/api/v1/presentations/?project=${projectId}`),
  });
};

export const fetch = (presentationId) => (dispatch) => {
  return dispatch({
    type: FETCH,
    promise: request.get(`/api/v1/presentations/${presentationId}/`),
  });
};

export const fetchParticipants = (presentationId) => (dispatch) => {
  return dispatch({
    type: FETCH_PARTICIPANTS,
    promise: request.get(
      `/api/v1/presentations/${presentationId}/participants/`
    ),
  });
};

export const fetchRevisions = (presentationId) => (dispatch) => {
  return dispatch({
    type: FETCH_REVISIONS,
    promise: request.get(
      `/api/v1/presentations/${presentationId}/image-revisions/`
    ),
  });
};

export const update = (presentationId, data) => (dispatch) => {
  const promise = request.patch(
    `/api/v1/presentations/${presentationId}/`,
    data
  );
  dispatch({
    type: UPDATE,
    promise,
  });
  /* return the promise because this function will be called in a redux-form submission */
  return promise;
};

export const updateParticipant =
  (presentationId, participantId, data) => (dispatch) => {
    return dispatch({
      type: UPDATE_PARTICIPANT,
      promise: request.patch(
        `/api/v1/presentations/${presentationId}/participants/${participantId}/`,
        data
      ),
    });
  };

export const removeParticipant =
  (presentationId, participantId, data) => (dispatch) => {
    return dispatch({
      type: REMOVE_PARTICIPANT,
      participantId: participantId,
      promise: request.delete(
        `/api/v1/presentations/${presentationId}/participants/${participantId}/`
      ),
    });
  };

export const resendInvite = (presentationId, participantId) => (dispatch) => {
  return dispatch({
    type: RESEND_INVITE,
    participantId: participantId,
    promise: request.patch(
      `/api/v1/presentations/${presentationId}/participants/${participantId}/resend_invite/`
    ),
  });
};

export const fetchRevisionComments =
  (presentationId, revisionId) => (dispatch, getState) => {
    const url = `/api/v1/presentations/${presentationId}/image-revisions/${revisionId}/comments/`;
    return dispatch({
      type: FETCH_REVISION_COMMENTS,
      promise: request.get(url),
    });
  };

export const toggleAddModal = () => {
  return {
    type: TOGGLE_ADD_MODAL,
  };
};

export const changeAddStep = (step) => {
  return {
    type: CHANGE_ADD_STEP,
    payload: step,
  };
};

export const toggleEditRevisionsModal = () => {
  return {
    type: TOGGLE_EDIT_REVISIONS_MODAL,
  };
};

export const toggleAddParticipantsModal = () => {
  return {
    type: TOGGLE_ADD_PARTICIPANTS_MODAL,
  };
};

export const setHoveredRevisionComment = (id) => {
  return {
    type: SET_HOVERED_REVISION_COMMENT,
    payload: id,
  };
};

export const fetchAllEntries =
  (projectId, presentationId, query) => (dispatch) => {
    const extraAPIQuery = presentationId
      ? {
          for_presentation: presentationId,
        }
      : {
          entry_eliminated: false,
          entry_withdrawn: false,
        };
    const queryStr = getAPIQueryString(ENTRIES_RESOURCE_NAME, query, {
      extraAPIQuery,
    });
    const promise = request.get(
      `/api/v1/projects/${projectId}/entries/?${queryStr}`
    );

    /* save page count in pager state */
    promise.then((res) => dispatch(savePageCount(ENTRIES_RESOURCE_NAME, res)));

    return dispatch({
      type: FETCH_ALL_ENTRIES,
      queryStr,
      promise,
    });
  };

export const clearAllEntries = () => {
  return {
    type: CLEAR_ALL_ENTRIES,
  };
};

export const setAllEntriesGrouping = (grouped) => {
  return {
    type: SET_ALL_ENTRIES_GROUPING,
    payload: grouped,
  };
};
