import ErrorPage from "error/containers";
import { setBannerError, setUnhandledError } from "error/ducks";
import get404Html from "error/components/404";
import get500Html from "error/components/500";
import get403Html from "error/components/403";

export {
  setBannerError,
  setUnhandledError,
  get404Html,
  get500Html,
  get403Html,
};
export default ErrorPage;
