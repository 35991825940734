import request from "axios";
import idx from "idx";
import pick from "lodash/pick";
import moment from "moment";
import { DATETIME_FORMAT } from "common/validators";
import { setBannerError } from "error";
import { showBanner, TYPE_SUCCESS } from "common/ducks/banner";
import {
  saveEntryConditions,
  fromQueryPartToBreakdown,
} from "email_automations/ducks/common";

/* ACTIONS
================================================================================================ */
const FETCH_ALL = "announcements_admin/FETCH_ALL";
const FETCH_ALL_SUCCESS = "announcements_admin/FETCH_ALL_SUCCESS";

const FETCH_ALL_FOR_USER = "announcements_admin/FETCH_ALL_FOR_USER";
const FETCH_ALL_FOR_USER_SUCCESS =
  "announcements_admin/FETCH_ALL_FOR_USER_SUCCESS";

const UPDATE_PRIORITIES = "announcements_admin/UPDATE_PRIORITIES";

const PERSIST_PRIORITIES = "announcements_admin/PERSIST_PRIORITIES";
const PERSIST_PRIORITIES_SUCCESS =
  "announcements_admin/PERSIST_PRIORITIES_SUCCESS";

const FETCH = "announcements_admin/FETCH";
const FETCH_REQUEST = "announcements_admin/FETCH_REQUEST";
const FETCH_SUCCESS = "announcements_admin/FETCH_SUCCESS";

/* HELPERS
================================================================================================ */
export const createRequest = () => request.post("/api/v1/announcements/");

export const cloneRequest = (id) =>
  request.post(`/api/v1/announcements/${id}/clone/`);

export const deleteRequest = (id) =>
  request.delete(`/api/v1/announcements/${id}/`);

const formatAnnouncement = (payload) => {
  return {
    ...payload,
    display_attr_conditions: payload.display_attr_conditions.map((c) => ({
      ...c,
      lead_query_parts: c.lead_query_parts.map(fromQueryPartToBreakdown),
    })),
  };
};

/* INITIAL STATES
================================================================================================ */
const initialState = {
  all: [],
  allForUser: [],
  isFetchingSingle: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_SUCCESS:
      return {
        ...state,
        all: action.payload,
      };
    case FETCH_ALL_FOR_USER_SUCCESS:
      return {
        ...state,
        allForUser: action.payload,
      };
    case UPDATE_PRIORITIES:
      return {
        ...state,
        all: action.payload,
      };
    case PERSIST_PRIORITIES_SUCCESS:
      return {
        ...state,
        /* update only `last_action_*` fields to avoid race conditions with `UPDATE_PRIORITIES` */
        all: state.all.map((announcement) => {
          const updatedAnnouncement =
            action.payload.find(({ id }) => id === announcement.id) || {};
          return {
            ...announcement,
            ...pick(updatedAnnouncement, [
              "last_action",
              "last_action_at",
              "last_action_by",
            ]),
          };
        }),
      };
    case FETCH_REQUEST:
      return {
        ...state,
        isFetchingSingle: true,
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        announcement: formatAnnouncement(action.payload),
        isFetchingSingle: false,
      };
    default:
      return state;
  }
}

export const fetchAll = () => (dispatch) => {
  return dispatch({
    type: FETCH_ALL,
    promise: request.get("/api/v1/announcements/"),
  });
};

export const fetchAllForUser = (userId) => (dispatch) => {
  return dispatch({
    type: FETCH_ALL_FOR_USER,
    promise: request.get(`/api/v1/users/${userId}/announcements_for/`),
  });
};

export const fetch = (id) => (dispatch) => {
  return dispatch({
    type: FETCH,
    promise: request.get(`/api/v1/announcements/${id}/`),
  });
};

export const update = (id, data) => (dispatch, getState) => {
  const state = getState();
  const { leadQueryParts } = state.emailAutomations.common;
  const { announcement } = state.announcementsAdmin;
  const updatedEntryConditions = data.target_authenticated
    ? data.display_attr_conditions
    : [];

  const promise = saveEntryConditions(
    announcement.display_attr_conditions,
    updatedEntryConditions,
    leadQueryParts
  ).then((newConditionIds) => {
    return request.patch(`/api/v1/announcements/${id}/`, {
      ...data,
      activate_at: moment(data.activate_at, DATETIME_FORMAT).format(),
      deactivate_at: moment(data.deactivate_at, DATETIME_FORMAT).format(),
      display_attr_conditions: newConditionIds,
    });
  });

  promise
    .then(() => {
      dispatch(
        showBanner(
          "Success",
          "Announcement successfully saved.",
          undefined,
          TYPE_SUCCESS
        )
      );
      dispatch(fetch(id));
    })
    .catch((err) => {
      const errorMsg =
        idx(err, (_) => _.data.message) ||
        "Make sure the data you entered is correct.";
      dispatch(setBannerError("Error saving announcement", errorMsg));
    });

  return promise;
};

export const updatePriorities = (collection) => (dispatch) => {
  const updatedAll = collection.map((i) => i.value);
  /* higher priorities come first */
  const updatedIds = updatedAll.map((a) => a.id).reverse();

  dispatch({
    type: UPDATE_PRIORITIES,
    payload: updatedAll,
  });

  /* wait 2s to send updates to the backend */
  if (window.prioritiesTimer) {
    clearTimeout(window.prioritiesTimer);
  }
  window.prioritiesTimer = setTimeout(() => {
    const promise = request.patch("/api/v1/announcements/update_priorities/", {
      ids: updatedIds,
    });

    promise.catch(() =>
      dispatch(setBannerError("Error updating priorities", "Please try again."))
    );

    dispatch({
      type: PERSIST_PRIORITIES,
      promise,
    });
  }, 2000);
};
