import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import last from "lodash/last";
import { requestAccess } from "project/ducks/ndas";
import { toggleRightDrawer } from "header/ducks";
import { getProject } from "project/ducks/project";
import {
  canRequestProjectAccess,
  canWatch,
  showPromoContent,
} from "project/utils";
import HeaderButtons from "project/components/header_buttons";

const mapStateToProps = (state, { routes }) => {
  const lastRoute = last(routes);
  const user = state.user.profile_data;
  const project = getProject(state);

  return {
    showFilters:
      lastRoute.name === "entries" || lastRoute.name === "your-entries",
    canRequestAccess: canRequestProjectAccess(state),
    canWatch: canWatch(state),
    showPromoContent: showPromoContent({ user, project, routes }),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleRightDrawer,
      requestAccess,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderButtons)
);
