import idx from "idx";
import {
  PAP14_URL_REGEXP,
  PROJECT_URL_REGEXP,
  EXPLORE_PROJECTS_URL_REGEXP,
  EXPLORE_CREATIVES_URL_REGEXP,
  PROJECT_ENTRIES_URL_REGEXP,
  PROJECT_WRAPUP_URL_REGEXP,
  PROJECT_FOCUS_GROUP_URL_REGEXP,
  PROJECT_SETTINGS_URL_REGEXP,
  PROJECT_YOUR_ENTRIES_URL_REGEXP,
  PROJECT_PRESENTATION_URL_REGEXP,
  ADMIN_ROOT,
} from "common/variables";
import { isUrlActive } from "common/utils";

/* Tab Link Constants
 ================================================================================================ */
const topNavigation = {
  projects: {
    name: "Your Projects",
    url: "/your-projects/",
    mobileNav: "left",
  },
  explore: {
    name: "Explore",
    url: "/explore/",
    mobileNav: "left",
  },
  categories: {
    name: "Project Categories",
    url: "/start-a-project/",
    mobileNav: "left",
  },
  externalCategories: {
    name: "Categories",
    url: "/categories/",
    mobileNav: "left",
  },
  help: {
    name: "Get Help",
    url: "/help/",
    mobileNav: "left",
  },
  account: {
    name: "Account",
    url: "/account/info/",
    mobileNav: "right",
  },
  logout: {
    name: "Logout",
    url: "/logout/",
    mobileNav: "right",
  },
  how: {
    name: "How it Works",
    url: "/how-it-works/",
    mobileNav: "left",
    topOnly: true,
  },
  testimonials: {
    name: "Reviews",
    url: "/reviews/",
    mobileNav: "left",
    topOnly: true,
  },
  pricing: {
    name: "Pricing",
    url: "/pricing/",
    mobileNav: "left",
    topOnly: true,
  },
  brandStudioHome: {
    name: "Brand Studio",
    url: "/brand-studio/brands/",
    mobileNav: "left",
  },
  join: {
    name: "Join",
    url: "/register/",
    right: true,
    mobileNav: "left",
  },
  login: {
    name: "Login",
    url: "/login/",
    right: true,
  },
  home: {
    name: "Home",
    url: "/",
    topOnly: true,
  },
  legal: {
    name: "Legal",
    url: "/legal/",
    topOnly: false,
  },
  startProject: {
    name: "Start a Project",
    url: "/start-a-project/",
    navOnly: true,
    mobileNav: "left",
    clientOnly: true,
  },
  whyCs: {
    name: "Why crowdspring",
    subItems: [
      {
        name: "Trust and Security",
        url: "/trust-and-security/",
      },
      {
        name: "Guarantee",
        url: "/guarantee/",
      },
      {
        name: "Case Studies",
        url: "/case-studies/",
      },
    ],
    mobileNav: "left",
    topOnly: true,
  },
};

export const accountTabs = {
  links: [
    {
      url: "/account/info/",
      name: "Account Info",
    },
    {
      url: "/account/id-verification/",
      name: "Verification",
      creativeOnly: true,
    },
    {
      url: "/account/notifications/",
      name: "Notifications",
    },
    {
      url: "/account/billing/",
      name: "Billing",
      clientOnly: true,
    },
    {
      url: "/account/payments/",
      name: "Payments",
      creativeOnly: true,
    },
    {
      url: "/refer-a-friend/",
      name: "Refer a Friend",
      clientOnly: true,
    },
  ],
};

export const exploreTabs = {
  links: [
    {
      url: "/explore/",
      name: "Projects",
      matchExact: true,
    },
    {
      url: "/explore/creatives/",
      name: "Creatives",
    },
  ],
};

export const papTabs = {
  links: [
    {
      url: "/start-a-project/",
      name: "All Categories",
      matchExact: true,
    },
    {
      url: "/start-a-project/popular/",
      name: "Popular",
      matchExact: true,
    },
  ],
};

export const helpTabs = {
  links: [
    {
      url: "/help/",
      name: "Help",
      regex: /^\/help\/(?!.*guides)(?!.*contact)/,
    },
    {
      url: "/help/guides/",
      name: "Guides",
      regex: /^\/help\/(guides)+/,
    },
    {
      url: "/help/contact/",
      name: "Contact",
      regex: /^\/help\/(contact)+/,
    },
  ],
};

export const legalTabs = {
  links: [
    {
      url: "/user-agreement/",
      name: "User Agreement",
    },
    {
      url: "/privacy-policy/",
      name: "Privacy Policy",
    },
    {
      url: "/copyright-policy/",
      name: "Copyright Policy",
    },
    {
      url: "/standards-of-conduct-client/",
      name: "Client Standard of Conduct",
    },
    {
      url: "/standards-of-conduct-creative/",
      name: "Creative Standard of Conduct",
    },
  ],
};

export const productUpdatesTabs = {
  links: [
    {
      url: "/whats-new/creative/",
      name: "Creative",
    },
    {
      url: "/whats-new/client/",
      name: "Client",
    },
  ],
};

export const categoriesTabs = {
  links: [
    {
      url: "/categories/",
      name: "All Categories",
      matchExact: true,
    },
    {
      url: "/categories/popular/",
      name: "Popular",
      matchExact: true,
    },
  ],
};

/* Project tabs are custom and defined elsewhere. This list here for
   the **sole** purpose of having `state.tabContent` set properly. */
const customProjectTabs = {
  links: [
    {
      url: PROJECT_ENTRIES_URL_REGEXP,
      name: "Entries",
    },
    {
      url: PROJECT_WRAPUP_URL_REGEXP,
      name: "Wrapups",
    },
    {
      url: PROJECT_FOCUS_GROUP_URL_REGEXP,
      name: "Focus Groups",
    },
    {
      url: PROJECT_PRESENTATION_URL_REGEXP,
      name: "Presentations",
    },
    {
      url: PROJECT_SETTINGS_URL_REGEXP,
      name: "Settings",
    },
    {
      url: PROJECT_YOUR_ENTRIES_URL_REGEXP,
      name: "Your Entries",
    },
  ],
};

const externalTopLinks = [
  topNavigation.whyCs,
  topNavigation.externalCategories,
  topNavigation.explore,
  topNavigation.how,
  topNavigation.testimonials,
  topNavigation.pricing,
  topNavigation.login,
  topNavigation.join,
];

const authedTopLinks = [
  topNavigation.projects,
  topNavigation.startProject,
  topNavigation.explore,
  topNavigation.help,
  topNavigation.account,
  topNavigation.logout,
];

const authedClientTopLinks = [
  topNavigation.projects,
  topNavigation.startProject,
  topNavigation.brandStudioHome,
  topNavigation.explore,
  topNavigation.externalCategories,
  topNavigation.pricing,
  topNavigation.help,
  topNavigation.account,
  topNavigation.logout,
];

/* ACTIONS
 ================================================================================================ */
export const URL_CHANGE = "crowdspring/header/URL_CHANGE";
const TOGGLE_HELP_DRAWER = "crowdspring/header/TOGGLE_HELP_DRAWER";
const TOGGLE_LEFT_SIDE_NAV = "crowdspring/header/TOGGLE_LEFT_SIDE_NAV";
const TOGGLE_RIGHT_SIDE_NAV = "crowdspring/header/TOGGLE_RIGHT_SIDE_NAV";
const TOGGLE_RIGHT_DRAWER = "crowdspring/header/TOGGLE_RIGHT_DRAWER";
const TOGGLE_COLLAPSED = "crowdspring/header/TOGGLE_COLLAPSED";

/* INITIAL STATE
 ================================================================================================ */
export const initialState = {
  showNav: true,
  showUser: true,
  showHelpDrawer: false,
  showLeftSideNav: false,
  showRightSideNav: false,
  showRightDrawer: false,
  content: null,
  helpDrawerVisible: false,
  topNavigation: [],
  tabsNavigation: null,
  isCollapsed: false,
  isMarketing: false,
};

/* HELPERS
 ================================================================================================ */
export const getHeaderAvatar = ({ user }) => {
  return idx(user, (_) => _.profile_data.avatar);
};

export const getHeaderState = (currentState, url, user) => {
  const authenticated = user ? user.logged_in : false;
  const state = {
    ...initialState,
    showRightDrawer: currentState.showRightDrawer,
  };
  state.tabContent = undefined;
  state.showUser = authenticated;
  const showFullLinks =
    authenticated && !url.match(/start-a-project/) && !url.match(/logout/);
  const topLinks = showFullLinks
    ? user.profile_data.is_client
      ? authedClientTopLinks
      : authedTopLinks
    : externalTopLinks;

  // Tabs
  let tabsLinks = null;

  // Custom Tabs defined elsewhere, but listed here so that `state.tabContent` is set properly
  let customTabsLinks = { links: [] };

  const isAboutPage =
    url.match(/^\/about-us/) ||
    url.match(/^\/jobs/) ||
    url.match(/^\/press/) ||
    url.match(/^\/partners$/) ||
    url.match(/^\/partners\/$/) ||
    url.match(/^\/gives-back/);
  const isLegalPage =
    url.match(/^\/user-agreement/) ||
    url.match(/^\/privacy-policy/) ||
    url.match(/^\/copyright-policy/) ||
    url.match(/^\/standards-of-conduct/);
  const isGoodbyeForums = url.match(/^\/goodbye-forums/);
  const isGoodbyePMs = url.match(/^\/goodbye-privatemessages/);

  // build links for tabs
  if (url.startsWith(ADMIN_ROOT)) {
    state.content = "admin";
  } else if (
    url.match(/start-a-project\/start-1-1/) ||
    url.match(/^\/select-1-1-creatives/)
  ) {
    state.showHelpDrawer = true;
    state.showNav = false;
    state.showUser = false;
    state.content = "oto_specific_views";
  } else if (url.match(/start-a-project/)) {
    state.showHelpDrawer = true;
    state.showNav = false;
    state.showUser = false;

    /* PAP14 */
    if (url.match(PAP14_URL_REGEXP)) {
      state.content = "pap14";
      /* PAP0 */
    } else {
      state.content = "pap0";
      tabsLinks = papTabs;
    }
  } else if (url.match(/^(?:\/focus-group-participate)/)) {
    state.content = "focus_groups_voting";
  } else if (url.match(/^\/refer-a-friend/)) {
    state.content = "refer_a_friend";
  } else if (url.match(/^\/collaboration-invite\//)) {
    state.content = "collaboration_invite";
  } else if (url.match(/^\/whats-new\/[client|creative]/)) {
    tabsLinks = productUpdatesTabs;
    state.content = "product_updates";
  } else if (url.match(/.+\/unsubscribe\/.+/)) {
    state.content = "email_automations_unsubscribe";
  } else if (url.match(/^\/confirm-subscription\//)) {
    state.content = "confirm_subscription";
  } else if (url.match(/^\/partner\/promo/)) {
    state.content = "discount_code";
  } else if (url.match(/^\/account\//)) {
    if (url.match(/account\/deleted/)) {
      state.content = "account_deleted";
    } else if (url.match(/account\/suspended/)) {
      state.content = "account_suspended";
    } else {
      state.content = "account";
      tabsLinks = accountTabs;
    }
  } else if (url.match(PROJECT_URL_REGEXP)) {
    state.content = "project";
    state.tabContent = "brief";
    customTabsLinks = customProjectTabs;
  } else if (url.match(EXPLORE_CREATIVES_URL_REGEXP)) {
    tabsLinks = exploreTabs;
    state.content = "explore_creatives";
  } else if (url.match(EXPLORE_PROJECTS_URL_REGEXP)) {
    tabsLinks = exploreTabs;
    state.content = "explore_projects";
  } else if (url.match(/your-projects/)) {
    state.content = "your_projects";
  } else if (url.match(/users\//)) {
    state.content = "profile";
  } else if (url.match(/login/)) {
    state.content = "login";
    tabsLinks = null;
  } else if (url.match(/notifications/)) {
    state.content = "notifications";
  } else if (
    url.match(/error/) ||
    url.match(/404/) ||
    url.match(/forbidden-private-project/) ||
    url.match(/project-down-for-nap/) ||
    url.match(/clients-only-page/) ||
    url.match(/creatives-only-page/)
  ) {
    state.content = "error";
    tabsLinks = null;
  } else if (url.match(/inactive-user/)) {
    state.content = "inactive_user";
    tabsLinks = null;
  } else if (url.match(/help\/contact\/consultation/)) {
    state.content = "consultation";
    tabsLinks = null;
    state.topOnly = true;
  } else if (url.match(/help/) && !url.match(/squadhelp/)) {
    state.content = "help";
    tabsLinks = helpTabs;
  } else if (url.match(/register\/continue/)) {
    state.content = "register_continue";
    state.topOnly = true;
    state.showHelpDrawer = true;
    state.showNav = false;
    state.showUser = false;
  } else if (url.match(/register[/]*$/)) {
    state.content = "register";
    state.showNav = false;
    state.showUser = false;
  } else if (url.match(/reset-password/)) {
    state.content = "password_reset";
  } else if (isAboutPage) {
    state.content = "about";
    state.isMarketing = true;
  } else if (url.match(/^\/affiliates/)) {
    state.content = "affiliates";
    state.isMarketing = true;
    state.topOnly = true;
  } else if (url.match(/^\/how-it-works/)) {
    state.content = "how";
    state.isMarketing = true;
  } else if (url.match(/^\/case-studies/)) {
    state.content = "case_studies";
    state.isMarketing = true;
    state.topOnly = true;
  } else if (url.match(/^\/books/)) {
    state.content = "books";
    state.isMarketing = true;
    state.topOnly = true;
  } else if (url.match(/^\/guides/)) {
    state.content = "guides";
    state.isMarketing = true;
    state.topOnly = true;
  } else if (url.match(/^\/webinars/)) {
    state.content = "webinars";
    state.isMarketing = true;
    state.topOnly = true;
  } else if (url.match(/^\/quizzes/)) {
    state.content = "lead_magnets";
    state.isMarketing = true;
    state.topOnly = true;
  } else if (url.match(/^\/reviews/)) {
    state.content = "testimonials";
    state.isMarketing = true;
  } else if (url.match(/^\/pricing/)) {
    state.content = "pricing";
    state.isMarketing = true;
  } else if (url.match(/^\/brand-studio/)) {
    if (url.match(/^\/brand-studio\/brand\/\d+\/design\//)) {
      state.content = "designEditor";
    } else if (url.match(/^\/brand-studio\/brand\/\d+\/wizard/)) {
      state.content = "brandWizard";
      state.showHelpDrawer = true;
      state.showNav = false;
      state.showUser = false;
    } else if (url.match(/^\/brand-studio\/brand\/\d+/)) {
      state.content = "brand";
    } else if (url.match(/^\/brand-studio\/brands/)) {
      state.content = "brandStudioHome";
    } else if (url.match(/^\/brand-studio\/refer-a-friend/)) {
      state.content = "brandStudioRefer";
    } else {
      state.content = "brandStudio";
      state.showHelpDrawer = true;
      state.showNav = false;
      state.showUser = false;
    }
  } else if (url === "/" || url === "") {
    state.content = "home";
    state.isMarketing = true;
  } else if (url.match(/^\/categories/)) {
    state.content = "externalCategories";
    state.isMarketing = true;
    tabsLinks = categoriesTabs;
  } else if (isLegalPage) {
    state.content = "legal";
    tabsLinks = legalTabs;
    state.topOnly = false;
    state.isMarketing = true;
  } else if (isGoodbyeForums) {
    state.content = "goodbye_forums";
    state.isMarketing = true;
  } else if (isGoodbyePMs) {
    state.content = "goodbye_pms";
    state.isMarketing = true;
  } else if (url.match(/enable-payoneer/)) {
    state.content = "enable_payoneer";
  } else if (url.match(/verify/)) {
    state.content = "verify_email";
  } else if (url.match(/validate\/id/)) {
    state.content = "validate_id";
  } else {
    state.content = "landing_page";
    state.topOnly = true;
  }

  /* remove creative-only links if user is not one */
  tabsLinks = tabsLinks
    ? {
        ...tabsLinks,
        links: tabsLinks.links.filter(
          (l) => !user || user.profile_data.is_creative || !l.creativeOnly
        ),
      }
    : tabsLinks;

  /* remove client-only links if user is not one */
  tabsLinks = tabsLinks
    ? {
        ...tabsLinks,
        links: tabsLinks.links.filter(
          (l) => !user || user.profile_data.is_client || !l.clientOnly
        ),
      }
    : tabsLinks;

  /* store tab name in the state if not already */
  let activeTabLink = null;
  /* look for tab link in default tabs... */
  activeTabLink = tabsLinks
    ? tabsLinks.links.find((l) =>
        isUrlActive(l.url, url, l.matchExact, l.regex)
      )
    : null;
  /* and then in custom tabs */
  activeTabLink =
    activeTabLink || customTabsLinks.links.find((l) => url.match(l.url));
  if (activeTabLink) {
    state.tabContent = activeTabLink.name.replace(" ", "_").toLowerCase();
  }

  /* filter top links based on user type */
  state.topNavigation = topLinks.filter((l) => {
    if (!user || !user.profile_data) {
      return !l.clientOnly && !l.creativeOnly;
    }
    if (!user.profile_data.is_client) {
      return !l.clientOnly;
    }
    if (!user.profile_data.is_creative) {
      return !l.creativeOnly;
    }
    return true;
  });

  state.tabsNavigation = tabsLinks;
  state.topOnly =
    state.topOnly ||
    (topNavigation[state.content] && topNavigation[state.content].topOnly);

  // Others
  return state;
};

/* REDUCERS
 ================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case URL_CHANGE:
      return {
        ...state,
        ...getHeaderState(state, action.payload.pathname, action.payload.user),
      };
    case TOGGLE_COLLAPSED:
      return {
        ...state,
        isCollapsed: !state.isCollapsed,
      };
    case TOGGLE_HELP_DRAWER:
      return {
        ...state,
        helpDrawerVisible: !state.helpDrawerVisible,
      };
    case TOGGLE_LEFT_SIDE_NAV:
      return {
        ...state,
        helpDrawerVisible: false,
        showLeftSideNav: !state.showLeftSideNav,
        showRightSideNav: false,
        showRightDrawer: false,
      };
    case TOGGLE_RIGHT_SIDE_NAV:
      return {
        ...state,
        helpDrawerVisible: false,
        showRightSideNav: !state.showRightSideNav,
        showLeftSideNav: false,
        showRightDrawer: false,
      };
    case TOGGLE_RIGHT_DRAWER:
      return {
        ...state,
        helpDrawerVisible: false,
        showLeftSideNav: false,
        showRightSideNav: false,
        showRightDrawer: !state.showRightDrawer,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const toggleCollapsed = () => ({ type: TOGGLE_COLLAPSED });

export const toggleHelpDrawer = () => ({ type: TOGGLE_HELP_DRAWER });

export const toggleLeftSideNav = () => ({ type: TOGGLE_LEFT_SIDE_NAV });

export const toggleRightSideNav = () => ({ type: TOGGLE_RIGHT_SIDE_NAV });

export const toggleRightDrawer = () => ({ type: TOGGLE_RIGHT_DRAWER });
