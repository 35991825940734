import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import isEqual from "lodash/isEqual";
import classnames from "classnames";
import Tooltip from "react-tooltip";
import * as Sentry from "@sentry/browser";
import request from "axios";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import { ADMIN_REGEX } from "common/variables";
import Header from "header/containers";
import Footer from "common/components/base_ui/footer";
import {
  LeftSideNavigation,
  RightSideNavigation,
} from "common/components/base_ui/side_navigation";
import HelpDrawer from "common/containers/help_drawer";
import RightDrawer from "common/containers/right_drawer";
import {
  toggleRightDrawer,
  toggleRightSideNav,
  getHeaderAvatar,
} from "header/ducks";
import NotificationsDrawer from "notifications/containers/drawer";
import { Meta } from "common/components/meta";
import { fetchCategoriesIfNeeded } from "common/ducks/categories";
import Banner from "common/containers/banner";
import GlobalModals from "common/components/modal/globals";
import { handleIosModalsOverlap, scrollToPageTop } from "common/utils";
import Announcements from "announcements/containers";
import { showBanner, hideBanner } from "common/ducks/banner";
import {
  getPendingDiscountCode,
  getStoredDiscountCode,
} from "pap/ducks/pricing";
import { getBannerContents } from "common/components/discount_code";
import { fetchIpInfoIfNeeded } from "common/ducks/user";
import { setAdWordsCustomParameters } from "common/tracking";
import { fetchTitleWords } from "admin/ducks/projects";
import ErrorBoundary from "error/components/boundary";
import "assets/scss/main.scss";

/**
 * Main App component rendering the application
 * @author Kuba Siemiątkowski <kuba@crowdspring.com>
 */

class App extends Component {
  constructor(props) {
    super(props);

    this.showUnusedDiscountBanner = this.showUnusedDiscountBanner.bind(this);
  }

  componentDidMount() {
    const { userProfile, fetchIpInfoIfNeeded } = this.props;

    /* identify user in case an error is reported to Sentry */
    if (userProfile.id) {
      Sentry.setUser({
        email: userProfile.email,
        username: userProfile.username,
        id: userProfile.id,
      });
    }

    handleIosModalsOverlap();

    window.addEventListener("scroll", this.hideTooltips.bind(this), true);

    this.showUnusedDiscountBanner();

    const adwordsProfileInfo = [
      "projects_owned_draft",
      "projects_owned_active",
      "projects_owned_awarded",
      "projects_owned_open",
      "projects_owned_posted",
    ].reduce((acc, f) => ({ ...acc, [f]: userProfile[f] || 0 }), {});

    fetchIpInfoIfNeeded().then(({ data }) =>
      setAdWordsCustomParameters({
        ...adwordsProfileInfo,
        ...data,
      })
    );
  }

  showUnusedDiscountBanner() {
    const { userProfile, showBanner, hideBanner } = this.props;
    let title, content, icon;

    if (userProfile.id) {
      const storedCode = getStoredDiscountCode(userProfile);
      if (storedCode && !userProfile.is_staff) {
        request.get("/api/v1/projects/mine/?status=draft").then(({ data }) => {
          ({ title, content, icon } = getBannerContents(storedCode, "unused", {
            draft: data.results[0],
            hideBanner,
          }));

          /* authenticated user with stored discount, with or without drafts */
          showBanner(title, content, icon);
        });
      }
    } else {
      const pendingCodeSlug = getPendingDiscountCode();

      if (pendingCodeSlug) {
        request
          .get(`/api/v1/discount-codes/${pendingCodeSlug}/`)
          .then(({ data }) => {
            ({ title, content, icon } = getBannerContents(data, "unused", {
              hideBanner,
            }));

            /* unauthenticated user with pending discount */
            showBanner(title, content, icon);
          });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location } = this.props;
    const hasPathChanged = location.pathname !== nextProps.location.pathname;
    const hasQueryChanged = !isEqual(location.query, nextProps.location.query);
    const keepScroll =
      nextProps.location.query.keep_scroll_on_url_change === "true";

    if ((hasPathChanged || hasQueryChanged) && !keepScroll) {
      scrollToPageTop();
    }
  }

  hideTooltips() {
    Tooltip.hide();
  }

  render() {
    const {
      header,
      userProfile,
      userAvatar,
      unseenNotificationsCount,
      toggleRightNav,
      location,
      children,
      toggleRightDrawer,
    } = this.props;

    if (location.pathname.match(ADMIN_REGEX)) {
      return (
        <div className={classnames("l-app", header.content, header.tabContent)}>
          {children}
        </div>
      );
    }

    const userClassnames = [];
    if (userProfile.id) {
      if (userProfile.is_client) {
        userClassnames.push("as-client");
      }
      if (userProfile.is_creative) {
        userClassnames.push("as-creative");
      }
    } else {
      userClassnames.push("as-anonymous");
    }

    return (
      <ErrorBoundary>
        <div
          className={classnames(
            "l-app",
            { marketing: header.isMarketing },
            header.content,
            header.tabContent,
            ...userClassnames
          )}
        >
          {/* The component below makes sure that the default helmconfig definitions are loaded
              for every route. Definitions passed to nested <Meta> components will override
              the parent ones. */}
          <Meta />

          <div
            className={classnames("side-drawer-container left", {
              visible: header.showLeftSideNav,
            })}
          >
            <LeftSideNavigation links={header.topNavigation} />
          </div>
          <div
            className={classnames("side-drawer-container right right-nav", {
              visible: header.showRightSideNav,
            })}
          >
            <RightSideNavigation
              links={header.topNavigation}
              {...{
                userProfile,
                userAvatar,
                unseenNotificationsCount,
                toggleRightNav,
              }}
            />
          </div>

          <div
            className={classnames("top-drawer-container", {
              visible: header.helpDrawerVisible,
            })}
          >
            <HelpDrawer />
          </div>

          <NotificationsDrawer />

          <div
            id="app-scroller"
            className={classnames("l-site-wrap scrolled", {
              left: header.showLeftSideNav,
              down: header.helpDrawerVisible,
              right: header.showRightDrawer,
              expanded: header.isCollapsed,
            })}
          >
            <Banner />

            <Announcements />

            {header.showRightDrawer && (
              <div
                className="drawer-overlay"
                role="button"
                onClick={toggleRightDrawer}
              />
            )}

            {header.showRightSideNav && (
              <div
                className="drawer-overlay hide-for-large"
                role="button"
                onClick={toggleRightNav}
              />
            )}

            {!header.isCollapsed && <Header header={header} />}

            <div
              className="content-and-footer-wrapper"
              id="content_and_footer_wrapper"
            >
              {children}
              <Footer />
              <GlobalModals />
            </div>
          </div>

          <div
            className={classnames("row side-drawer-container right", {
              visible: header.showRightDrawer,
            })}
          >
            <RightDrawer />
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}

App.propTypes = {
  children: PropTypes.node,
  header: PropTypes.object,
  toggleRightDrawer: PropTypes.func,
  userAvatar: PropTypes.string,
  userProfile: PropTypes.object,
  unseenNotificationsCount: PropTypes.number,
  toggleRightNav: PropTypes.func,
  location: PropTypes.object,
  showBanner: PropTypes.func.isRequired,
  hideBanner: PropTypes.func.isRequired,
  fetchIpInfoIfNeeded: PropTypes.func.isRequired,
};

App.need = [fetchCategoriesIfNeeded, fetchTitleWords];

function mapStateToProps(state) {
  return {
    header: state.header,
    userAvatar: getHeaderAvatar(state),
    userProfile: state.user.profile_data,
    unseenNotificationsCount: (state.notifications.data.results || []).filter(
      (n) => !n.seen
    ).length,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    showBanner,
    hideBanner,
    toggleRightDrawer,
    toggleRightNav: toggleRightSideNav,
    fetchIpInfoIfNeeded,
  })(App)
);
