import request from "axios";
import { reset } from "redux-form";
import idx from "idx";
import queryString from "query-string";
import range from "lodash/range";
import pick from "lodash/pick";
import omit from "lodash/omit";
import sortBy from "lodash/sortBy";
import isEqual from "lodash/isEqual";
import uniq from "lodash/uniq";
import moment from "moment";
import fileDownload from "js-file-download";
import { showBanner, TYPE_SUCCESS } from "common/ducks/banner";
import { setBannerError } from "error";
import {
  AUTOMATIONS_RESOURCE_NAME,
  GROUPS_RESOURCE_NAME,
  AUTOMATION_FORM_NAME,
  LEADS_RESOURCE_NAME,
  GROUPS_PER_AUTOMATION,
} from "email_automations/constants";
import { getAPIQueryString, getItemsFromString } from "common/utils";
import { savePageCount } from "common/pager/ducks";
import { DATETIME_FORMAT } from "common/validators";
import {
  sendTestEmail as sendTestEmailCommon,
  fromQueryPartToBreakdown,
  getQueryPartIdSets,
} from "email_automations/ducks/common";
import { getAutomationEmailName } from "email_automations/utils";

/* ACTIONS
================================================================================================ */
const FETCH_TOTALS = "bumblebee/FETCH_TOTALS";
const FETCH_TOTALS_SUCCESS = "bumblebee/FETCH_TOTALS_SUCCESS";

const FETCH_GROUP_TOTALS = "bumblebee/FETCH_GROUP_TOTALS";
const FETCH_GROUP_TOTALS_SUCCESS = "bumblebee/FETCH_GROUP_TOTALS_SUCCESS";

const FETCH_AUTOMATIONS = "bumblebee/FETCH_AUTOMATIONS";
const FETCH_AUTOMATIONS_REQUEST = "bumblebee/FETCH_AUTOMATIONS_REQUEST";
const FETCH_AUTOMATIONS_SUCCESS = "bumblebee/FETCH_AUTOMATIONS_SUCCESS";

const FETCH_GROUP = "bumblebee/FETCH_GROUP";
const FETCH_GROUP_REQUEST = "bumblebee/FETCH_GROUP_REQUEST";
const FETCH_GROUP_SUCCESS = "bumblebee/FETCH_GROUP_SUCCESS";

const PAUSE_AUTOMATION = "bumblebee/PAUSE_AUTOMATION";
const PAUSE_AUTOMATION_SUCCESS = "bumblebee/PAUSE_AUTOMATION_SUCCESS";

const RESUME_AUTOMATION = "bumblebee/RESUME_AUTOMATION";
const RESUME_AUTOMATION_SUCCESS = "bumblebee/RESUME_AUTOMATION_SUCCESS";

const SET_LIVE_AUTOMATION = "bumblebee/SET_LIVE_AUTOMATION";
const SET_LIVE_AUTOMATION_SUCCESS = "bumblebee/SET_LIVE_AUTOMATION_SUCCESS";

const END_AUTOMATION = "bumblebee/END_AUTOMATION";
const END_AUTOMATION_SUCCESS = "bumblebee/END_AUTOMATION_SUCCESS";

const SOFT_END_AUTOMATION = "bumblebee/SOFT_END_AUTOMATION";
const SOFT_END_AUTOMATION_SUCCESS = "bumblebee/SOFT_END_AUTOMATION_SUCCESS";

const FETCH_GROUPS = "bumblebee/FETCH_GROUPS";
const FETCH_GROUPS_REQUEST = "bumblebee/FETCH_GROUPS_REQUEST";
const FETCH_GROUPS_SUCCESS = "bumblebee/FETCH_GROUPS_SUCCESS";

const PAUSE_GROUP = "bumblebee/PAUSE_GROUP";
const PAUSE_GROUP_SUCCESS = "bumblebee/PAUSE_GROUP_SUCCESS";

const RESUME_GROUP = "bumblebee/RESUME_GROUP";
const RESUME_GROUP_SUCCESS = "bumblebee/RESUME_GROUP_SUCCESS";

const END_GROUP = "bumblebee/END_GROUP";
const END_GROUP_SUCCESS = "bumblebee/END_GROUP_SUCCESS";

const SOFT_END_GROUP = "bumblebee/SOFT_END_GROUP";
const SOFT_END_GROUP_SUCCESS = "bumblebee/SOFT_END_GROUP_SUCCESS";

const SAVE_GROUP = "bumblebee/SAVE_GROUP";

const FETCH_AUTOMATION = "bumblebee/FETCH_AUTOMATION";
const FETCH_AUTOMATION_REQUEST = "bumblebee/FETCH_AUTOMATION_REQUEST";
const FETCH_AUTOMATION_SUCCESS = "bumblebee/FETCH_AUTOMATION_SUCCESS";

const FETCH_LEADS = "bumblebee/FETCH_LEADS";
const FETCH_LEADS_REQUEST = "bumblebee/FETCH_LEADS_REQUEST";
const FETCH_LEADS_SUCCESS = "bumblebee/FETCH_LEADS_SUCCESS";

const SAVE_AUTOMATION = "bumblebee/SAVE_AUTOMATION";
const SAVE_AUTOMATION_REQUEST = "bumblebee/SAVE_AUTOMATION_REQUEST";
const SAVE_AUTOMATION_SUCCESS = "bumblebee/SAVE_AUTOMATION_SUCCESS";
const SAVE_AUTOMATION_FAILURE = "bumblebee/SAVE_AUTOMATION_FAILURE";

const FETCH_ENTRY_EVENTS = "bumblebee/FETCH_ENTRY_EVENTS";
const FETCH_ENTRY_EVENTS_REQUEST = "bumblebee/FETCH_ENTRY_EVENTS_REQUEST";
const FETCH_ENTRY_EVENTS_SUCCESS = "bumblebee/FETCH_ENTRY_EVENTS_SUCCESS";

const SET_LEADS_CSV_INFO = "bumblebee/SET_LEADS_CSV_INFO";

const FETCH_ALL_GROUPS = "bumblebee/FETCH_ALL_GROUPS";
const FETCH_ALL_GROUPS_SUCCESS = "bumblebee/FETCH_ALL_GROUPS_SUCCESS";

const SET_SINGLE_LEAD_INFO = "bumblebee/SET_SINGLE_LEAD_INFO";

/* HELPERS
================================================================================================ */
const fromAutomationEventToBreakdown = (slug) => {
  if (slug.startsWith("project-draft") && !slug.includes("h-")) {
    return {
      action: "project-draft",
      countryTier: slug.split("-")[2],
      draftQualification: slug.split("-")[3],
      subCategory: slug.split("created-")[1],
    };
  }

  if (slug.startsWith("wrapup") && slug !== "wrapup-survey-crowdspring") {
    return {
      action: "wrapup",
      subCategory: slug.split("wrapup-")[1].split("-final-files-first-dl")[0],
    };
  }

  if (slug.startsWith("entry")) {
    return {
      action: "winner-awarded",
      subCategory: slug.split("entry-")[1].split("-awarded-winner")[0],
    };
  }

  if (slug.startsWith("project-")) {
    const breakDown = {
      status: slug.split("-")[1],
      interval: slug.split("-")[2],
    };

    const rest = slug.split(`${breakDown.interval}-`)[1];
    if (["image", "name"].includes(rest)) {
      breakDown["action"] = "projectStatusEntryType";
      breakDown["entryType"] = rest;
    } else {
      breakDown["action"] = "projectStatusSubCat";
      breakDown["subCategory"] = rest;
    }

    return breakDown;
  }

  return { action: slug };
};

const fromBreakdownToAutomationEvent = (breakDown) => {
  const {
    action,
    countryTier,
    draftQualification,
    subCategory,
    status,
    interval,
    entryType,
  } = breakDown;

  if (action === "project-draft") {
    return `${action}-${countryTier}-${draftQualification}-created-${subCategory}`;
  }

  if (action === "wrapup") {
    return `${action}-${subCategory}-final-files-first-dl`;
  }

  if (action === "winner-awarded") {
    return `entry-${subCategory}-awarded-winner`;
  }

  if (["projectStatusEntryType", "projectStatusSubCat"].includes(action)) {
    return (
      `project-${status}-${interval}-` +
      (action === "projectStatusEntryType" ? entryType : subCategory)
    );
  }

  return action;
};

const formatAutomation = (payload) => {
  return {
    ...payload,
    emails: fromEmailsToFormData(payload),
    entry_events: payload.entry_events.map((e) =>
      fromAutomationEventToBreakdown(e.slug)
    ),
    entry_event_conditions: payload.entry_event_conditions.map((c) => ({
      ...c,
      lead_query_parts: c.lead_query_parts.map(fromQueryPartToBreakdown),
    })),
    entry_attr_conditions: payload.entry_attr_conditions.map((c) => ({
      ...c,
      lead_query_parts: c.lead_query_parts.map(fromQueryPartToBreakdown),
    })),
  };
};

const formatField = (key, value) => {
  let keyTxt = "";

  if (["id"].includes(key)) {
    return "";
  } else if (key === "emails") {
    value.forEach((e, i) => {
      e.variations.forEach((v, j) => {
        keyTxt +=
          getAutomationEmailName(i, j, e.variations).toUpperCase() + "\n";

        Object.keys(v).forEach((k) => {
          if (
            [
              "created_at",
              "updated_at",
              "meta",
              "preheader",
              "variation_allocation",
              "variation_number",
              "automation_email",
              "html_design",
              "html_content",
              "html_data",
              "id",
            ].includes(k) ||
            k.startsWith("count_")
          ) {
            return;
          }

          keyTxt += "  " + k.replaceAll("_", " ").toUpperCase() + "\n";
          keyTxt += "  " + JSON.stringify(v[k]) + "\n\n";
        });
      });
    });
  } else {
    keyTxt +=
      key.replaceAll("_", " ").toUpperCase() +
      "\n" +
      JSON.stringify(value, null, 2) +
      "\n\n";
  }

  return keyTxt;
};
const formatAutomationAsTxt = (formData) => {
  let txt = "";
  Object.keys(formData).map((f) => {
    txt += formatField(f, formData[f]);
  });

  return txt;
};

export const cloneAutomationRequest = (id) =>
  request.post(`/api/v1/bb/automations/${id}/clone/`);

export const deleteAutomationRequest = (id) =>
  request.delete(`/api/v1/bb/automations/${id}/`);

export const createAutomationRequest = () =>
  request.post("/api/v1/bb/automations/");

export const cloneGroupRequest = (id) =>
  request.post(`/api/v1/bb/automation_groups/${id}/clone/`);

export const deleteGroupRequest = (id) =>
  request.delete(`/api/v1/bb/automation_groups/${id}/`);

export const createGroupRequest = () =>
  request.post("/api/v1/bb/automation_groups/");

export const getPriorAutomationIds = (data) => {
  return getItemsFromString(data.entry_prior_automation_ids);
};

export const uploadLeadsRequest = (automationId, data) => {
  return request.post(
    `/api/v1/bb/automations/${automationId}/leads/upload_csv/`,
    {
      csv_string: data.file,
    }
  );
};

export const fromFormDataToEmails = (formData) =>
  formData.emails.map((email, i) => ({
    ...email,
    order: i + 1,
    variations: email.variations.map((variation, j) => ({
      ...omit(variation, "html_data"),
      variation_number: j + 1,
      variation_allocation: ((1 / email.variations.length) * 100).toFixed(2),
      html_content: idx(variation, (_) => _.html_data.html) || "",
      html_design: idx(variation, (_) => _.html_data.design) || "",
      scheduled_for: variation.fixedTime
        ? moment(variation.scheduled_for, DATETIME_FORMAT).format()
        : null,
      waiting_period_hours: variation.fixedTime
        ? null
        : variation.waiting_period_hours,
    })),
  }));

export const fromEmailsToFormData = (parent) =>
  sortBy(parent.emails, "order").map((e) => ({
    ...e,
    variations: sortBy(e.variations, "variation_number").map((v) => ({
      ...v,
      html_data: v.html_design
        ? { design: v.html_design, html: v.html_content }
        : undefined,
      fixedTime: !!v.scheduled_for,
      scheduled_for: v.scheduled_for
        ? moment(v.scheduled_for).format(DATETIME_FORMAT)
        : "",
    })),
  }));

/* INITIAL STATES
================================================================================================ */
const initialState = {
  totals: {},
  groupTotals: {},
  automations: [],
  automationCount: 0,
  isFetching: false,
  isFetchingSingle: false,
  isFetchingEntryEvents: false,
  groups: [],
  groupCount: 0,
  entryEvents: [],
  isSavingAutomation: false,
  leads: [],
  leadCount: 0,
  leadsCsvInfo: {},
  allGroups: [],
  singleLeadInfo: { extra_context: "{}" },
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  let fieldsOfInterest;

  switch (action.type) {
    case FETCH_TOTALS_SUCCESS:
      return {
        ...state,
        totals: {
          ...action.payload,
          complete: action.payload.complete + action.payload.soft_complete,
        },
      };
    case FETCH_GROUP_TOTALS_SUCCESS:
      return {
        ...state,
        groupTotals: {
          ...action.payload,
          complete: action.payload.complete + action.payload.soft_complete,
        },
      };
    case FETCH_AUTOMATIONS_REQUEST:
    case FETCH_GROUPS_REQUEST:
    case FETCH_LEADS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_GROUP_REQUEST:
    case FETCH_AUTOMATION_REQUEST:
      return {
        ...state,
        isFetchingSingle: true,
      };
    case FETCH_AUTOMATIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        automations: action.payload.results,
        automationCount: action.payload.count,
      };
    case FETCH_GROUP_SUCCESS:
      return {
        ...state,
        isFetchingSingle: false,
        group: action.payload,
      };
    case PAUSE_AUTOMATION_SUCCESS:
    case RESUME_AUTOMATION_SUCCESS:
    case SET_LIVE_AUTOMATION_SUCCESS:
    case END_AUTOMATION_SUCCESS:
    case SOFT_END_AUTOMATION_SUCCESS:
      fieldsOfInterest = pick(
        action.payload,
        "id",
        "status",
        "last_action",
        "last_action_at",
        "last_action_by",
        "last_launched_at"
      );

      return {
        ...state,
        automations: state.automations.map((a) =>
          a.id === fieldsOfInterest.id ? { ...a, ...fieldsOfInterest } : a
        ),
        automation:
          idx(state, (_) => _.automation.id) === fieldsOfInterest.id
            ? { ...state.automation, ...fieldsOfInterest }
            : state.automation,
      };
    case FETCH_GROUPS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        groups: action.payload.results,
        groupCount: action.payload.count,
      };
    case PAUSE_GROUP_SUCCESS:
    case RESUME_GROUP_SUCCESS:
    case END_GROUP_SUCCESS:
    case SOFT_END_GROUP_SUCCESS:
      fieldsOfInterest = pick(
        action.payload,
        "id",
        "status",
        "last_action",
        "last_action_at",
        "last_action_by"
      );
      return {
        ...state,
        groups: state.groups.map((g) =>
          g.id === fieldsOfInterest.id ? { ...g, ...fieldsOfInterest } : g
        ),
        group:
          idx(state, (_) => _.group.id) === fieldsOfInterest.id
            ? { ...state.group, ...fieldsOfInterest }
            : state.group,
      };
    case FETCH_AUTOMATION_SUCCESS:
      return {
        ...state,
        isFetchingSingle: false,
        automation: formatAutomation(action.payload),
      };
    case FETCH_ENTRY_EVENTS_REQUEST:
      return {
        ...state,
        isFetchingEntryEvents: true,
      };
    case FETCH_ENTRY_EVENTS_SUCCESS:
      return {
        ...state,
        isFetchingEntryEvents: false,
        entryEvents: action.payload,
      };
    case SAVE_AUTOMATION_REQUEST:
      return {
        ...state,
        isSavingAutomation: true,
      };
    case SAVE_AUTOMATION_FAILURE:
    case SAVE_AUTOMATION_SUCCESS:
      return {
        ...state,
        isSavingAutomation: false,
      };
    case FETCH_LEADS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        leads: action.payload.results,
        leadCount: action.payload.count,
      };
    case SET_LEADS_CSV_INFO:
      return {
        ...state,
        leadsCsvInfo: action.payload,
      };
    case FETCH_ALL_GROUPS_SUCCESS:
      return {
        ...state,
        allGroups: action.payload,
      };
    case SET_SINGLE_LEAD_INFO:
      return {
        ...state,
        singleLeadInfo: action.payload,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const fetchTotals = (groupId) => (dispatch) => {
  const queryStr = queryString.stringify({ group_id: groupId });

  return dispatch({
    type: FETCH_TOTALS,
    promise: request.get(`/api/v1/bb/automations/totals/?${queryStr}`),
  });
};

export const fetchGroupTotals = () => (dispatch) => {
  return dispatch({
    type: FETCH_GROUP_TOTALS,
    promise: request.get("/api/v1/bb/automation_groups/totals/"),
  });
};

export const fetchAutomations = (query, status, groupId) => (dispatch) => {
  let statusVal;
  if (status !== "all") {
    if (status === "complete") {
      statusVal = ["complete", "soft_complete"];
    } else if (status === "important") {
      statusVal = "live";
    } else {
      statusVal = status;
    }
  }

  const extraAPIQuery = {
    groups: groupId ? [groupId] : undefined,
    status: statusVal,
    important: status === "important" ? true : undefined,
  };
  const queryStr = getAPIQueryString(AUTOMATIONS_RESOURCE_NAME, query, {
    extraAPIQuery,
  });
  const promise = request.get(`/api/v1/bb/automations/?${queryStr}`);

  /* save page count in pager state */
  promise.then((res) =>
    dispatch(savePageCount(AUTOMATIONS_RESOURCE_NAME, res))
  );

  return dispatch({
    type: FETCH_AUTOMATIONS,
    promise,
  });
};

export const fetchGroup = (id) => (dispatch) => {
  return dispatch({
    type: FETCH_GROUP,
    promise: request.get(`/api/v1/bb/automation_groups/${id}/`),
  });
};

export const pauseAutomation = (id) => (dispatch) => {
  const promise = request.patch(`/api/v1/bb/automations/${id}/pause/`);

  dispatch({
    type: PAUSE_AUTOMATION,
    promise,
  });

  return promise;
};

export const resumeAutomation = (id) => (dispatch) => {
  const promise = request.patch(`/api/v1/bb/automations/${id}/resume/`);

  dispatch({
    type: RESUME_AUTOMATION,
    promise,
  });

  return promise;
};

export const setLiveAutomation = (id) => (dispatch) => {
  const promise = request.patch(`/api/v1/bb/automations/${id}/launch/`);

  promise.catch((err) => {
    const errorMsg = err.data ? JSON.stringify(err.data) : "Unknown error.";
    dispatch(setBannerError("Error setting automation live", errorMsg));
  });

  dispatch({
    type: SET_LIVE_AUTOMATION,
    promise,
  });

  return promise;
};

export const endAutomation = (id) => (dispatch) => {
  const promise = request.patch(`/api/v1/bb/automations/${id}/complete/`);

  dispatch({
    type: END_AUTOMATION,
    promise,
  });

  return promise;
};

export const softEndAutomation = (id) => (dispatch) => {
  const promise = request.patch(`/api/v1/bb/automations/${id}/soft_complete/`);

  dispatch({
    type: SOFT_END_AUTOMATION,
    promise,
  });

  return promise;
};

export const fetchGroups = (query, status) => (dispatch) => {
  let statusVal;
  if (status !== "all") {
    if (status === "complete") {
      statusVal = ["complete", "soft_complete"];
    } else {
      statusVal = status;
    }
  }
  const extraAPIQuery = {
    status: statusVal,
  };

  const queryStr = getAPIQueryString(GROUPS_RESOURCE_NAME, query, {
    extraAPIQuery,
  });
  const promise = request.get(`/api/v1/bb/automation_groups/?${queryStr}`);

  /* save page count in pager state */
  promise.then((res) => dispatch(savePageCount(GROUPS_RESOURCE_NAME, res)));

  return dispatch({
    type: FETCH_GROUPS,
    promise,
  });
};

export const pauseGroup = (id) => (dispatch) => {
  const promise = request.patch(`/api/v1/bb/automation_groups/${id}/pause/`);

  dispatch({
    type: PAUSE_GROUP,
    promise,
  });

  return promise;
};

export const resumeGroup = (id) => (dispatch) => {
  const promise = request.patch(`/api/v1/bb/automation_groups/${id}/resume/`);

  dispatch({
    type: RESUME_GROUP,
    promise,
  });

  return promise;
};

export const endGroup = (id) => (dispatch) => {
  const promise = request.patch(`/api/v1/bb/automation_groups/${id}/complete/`);

  dispatch({
    type: END_GROUP,
    promise,
  });

  return promise;
};

export const softEndGroup = (id) => (dispatch) => {
  const promise = request.patch(
    `/api/v1/bb/automation_groups/${id}/soft_complete/`
  );

  dispatch({
    type: SOFT_END_GROUP,
    promise,
  });

  return promise;
};

export const saveGroup = (id, data) => (dispatch) => {
  const promise = request.patch(`/api/v1/bb/automation_groups/${id}/`, data);

  promise
    .then(() => {
      dispatch(
        showBanner(
          "Success",
          "Automation group successfully saved.",
          undefined,
          TYPE_SUCCESS,
          null,
          3000
        )
      );
    })
    .catch((err) => {
      const errorMsg = err.data
        ? JSON.stringify(err.data)
        : "Make sure the data you entered is correct.";
      dispatch(setBannerError("Error saving automation group", errorMsg));
    });

  return dispatch({
    type: SAVE_GROUP,
    promise,
  });
};

export const fetchAutomation = (id) => (dispatch) => {
  return dispatch({
    type: FETCH_AUTOMATION,
    promise: request.get(`/api/v1/bb/automations/${id}/`),
  });
};

export const sendTestEmail =
  (formName, emailIdx, variationIdx, emails, extraContext) =>
  (dispatch, getState) => {
    const formValues = getState().form[formName].values;
    const formData = {
      ...formValues,
      ...formValues.emails[emailIdx].variations[variationIdx],
    };
    return dispatch(sendTestEmailCommon(formData, emails, extraContext));
  };

export const saveAutomation =
  (id, showSuccessBanner = true) =>
  (dispatch, getState) => {
    const state = getState();
    const { leadQueryParts } = state.emailAutomations.common;
    const { automation, entryEvents } = state.emailAutomations.bumblebee;
    const formData = state.form[AUTOMATION_FORM_NAME].values || {};
    const externalLeadTags = (formData.entry_external_lead_tags || "").trim();

    const entryAttrConditions = isEqual(
      automation.entry_attr_conditions,
      formData.entry_attr_conditions
    )
      ? undefined
      : getQueryPartIdSets(
          formData.entry_attr_conditions.map((c) => c.lead_query_parts),
          leadQueryParts
        );

    const entryEventConditions = isEqual(
      automation.entry_event_conditions,
      formData.entry_event_conditions
    )
      ? undefined
      : getQueryPartIdSets(
          formData.entry_event_conditions.map((c) => c.lead_query_parts),
          leadQueryParts
        );

    const getEvent = (slug) => entryEvents.find((e) => e.slug === slug);
    const currentEvents = automation.entry_events
      .map(fromBreakdownToAutomationEvent)
      .map((slug) => getEvent(slug).id);
    const updatedEvents = formData.entry_events
      .map(fromBreakdownToAutomationEvent)
      .map((slug) => getEvent(slug).id);

    let automationData = {
      ...pick(
        formData,
        "name",
        "periodically_check_for_new_leads",
        "unsubscribe_strategy",
        "exit_if_entry_condition_not_met",
        "type",
        "goal_1",
        "goal_2",
        "goal_3",
        "goal_4",
        "limited",
        "entry_prior_automation_waiting_days",
        "important",
        "entry_contact_list",
        "use_past_contacts",
        "use_new_contacts",
        "days_before_reentry",
        "outgoing_contact_list",
        "frequent_leads_expected"
      ),
      entry_contact_list_filters: JSON.parse(
        formData.entry_contact_list_filters
      ),
      groups: uniq(
        range(1, GROUPS_PER_AUTOMATION + 1)
          .map((i) => formData[`group_${i}`])
          .filter((g) => !!g)
      ),
      entry_external_lead_tags: externalLeadTags ? [externalLeadTags] : [],
      extra_link_attributes: pick(
        formData,
        "utm_source",
        "utm_medium",
        "utm_campaign"
      ),
      entry_prior_automation_ids: getPriorAutomationIds(formData),
      entry_attr_conditions: entryAttrConditions,
      entry_event_conditions: entryEventConditions,
      emails: isEqual(automation.emails, formData.emails)
        ? undefined
        : fromFormDataToEmails(formData),
      entry_events: isEqual(currentEvents, updatedEvents)
        ? undefined
        : updatedEvents,
    };

    if (automation.status !== "draft") {
      automationData = pick(automationData, [
        "name",
        "emails",
        "entry_prior_automation_ids",
        "entry_prior_automation_waiting_days",
        "important",
        "groups",
        "outgoing_contact_list",
        "frequent_leads_expected",
      ]);
    }

    const promise = request.patch(
      `/api/v1/bb/automations/${id}/`,
      automationData
    );

    promise
      .then(() => {
        if (showSuccessBanner) {
          dispatch(
            showBanner(
              "Success",
              "Automation successfully saved.",
              undefined,
              TYPE_SUCCESS,
              null,
              3000
            )
          );
        }
        /* reset form so that admin notes are cleared */
        dispatch(reset(AUTOMATION_FORM_NAME));
        dispatch(fetchAutomation(id));
      })
      .catch((err) => {
        const errorMsg = err.data
          ? JSON.stringify(err.data)
          : "Make sure the data you entered is correct.";
        dispatch(setBannerError("Error saving automation", errorMsg));
      });

    dispatch({
      type: SAVE_AUTOMATION,
      promise,
    });

    return promise;
  };

export const fetchEntryEvents = () => (dispatch) => {
  return dispatch({
    type: FETCH_ENTRY_EVENTS,
    promise: request.get("/api/v1/bb/entry_events/"),
  });
};

export const fetchLeads = (automationId, query) => (dispatch) => {
  const queryStr = getAPIQueryString(LEADS_RESOURCE_NAME, query);
  const promise = request.get(
    `/api/v1/bb/automations/${automationId || "all"}/leads/?${queryStr}`
  );

  /* save page count in pager state */
  promise.then((res) => dispatch(savePageCount(LEADS_RESOURCE_NAME, res)));

  return dispatch({
    type: FETCH_LEADS,
    promise,
  });
};

export const exportAsTxt = () => (dispatch, getState) => {
  const state = getState();
  const formData = state.form[AUTOMATION_FORM_NAME].values || {};
  const blob = new Blob([formatAutomationAsTxt(formData)], {
    type: "text/plain;charset=utf-8",
  });
  fileDownload(blob, "automation_export.txt");
};

export const setLeadsCsvInfo = (info) => (dispatch) => {
  return dispatch({
    type: SET_LEADS_CSV_INFO,
    payload: info,
  });
};

export const exportLeadsAsCsv = (automationId) => (dispatch, getState) => {
  const { leadsCsvInfo } = getState().emailAutomations.bumblebee;
  const promise = request.post(
    `/api/v1/bb/automations/${automationId}/leads/export_csv/`,
    leadsCsvInfo
  );

  promise.catch((err) => {
    const errorMsg = err.data ? JSON.stringify(err.data) : "Unknown error.";
    dispatch(setBannerError("Error exporting leads as CSV", errorMsg));
  });

  return promise;
};

export const fetchAllGroups = () => (dispatch) => {
  return dispatch({
    type: FETCH_ALL_GROUPS,
    promise: request.get("/api/v1/bb/automation_groups/all/"),
  });
};

export const setSingleLeadInfo = (info) => (dispatch) => {
  return dispatch({
    type: SET_SINGLE_LEAD_INFO,
    payload: info,
  });
};

export const addSingleLead = (automationId) => (dispatch, getState) => {
  const { singleLeadInfo } = getState().emailAutomations.bumblebee;
  const promise = request.post(
    `/api/v1/bb/automations/${automationId}/leads/`,
    {
      ...singleLeadInfo,
      extra_context: JSON.parse(singleLeadInfo.extra_context),
    }
  );

  promise
    .then(() => {
      dispatch(
        showBanner(
          "Success",
          "Lead succesfully added.",
          undefined,
          TYPE_SUCCESS,
          null,
          3000
        )
      );
    })
    .catch((err) => {
      const errorMsg = err.data ? JSON.stringify(err.data) : "Unknown error.";
      dispatch(setBannerError("Error adding lead", errorMsg));
    });

  return promise;
};
