/* ACTION CONSTANTS
================================================================================================ */
const SAVE_LAST_QUERY = "crowdspring/searcher/SAVE_LAST_QUERY";

/* INITIAL STATES
================================================================================================ */
export const initialState = {
  lastQueries: {},
};

/* REDUCERS
================================================================================================ */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_LAST_QUERY:
      return {
        ...state,
        lastQueries: {
          ...state.lastQueries,
          [action.resourceName]: action.query,
        },
      };
    default:
      return state;
  }
};

export default reducer;

/* ACTION CREATORS
================================================================================================ */

/* Save last query dict produced by a given searcher.
 * Used only for the purpose of keeping a history in case the same query needs to be restored after
 * one or more navigation changes occur. */
export const saveLastQuery = (resourceName, query) => {
  return {
    type: SAVE_LAST_QUERY,
    resourceName,
    query,
  };
};
