import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toggleSelectLogoSubcat } from "pap/ducks/workflow";
import SelectLogoSubcatModal from "pap/components/common/select_logo_subcat_modal";

const mapStateToProps = ({ pap, categories }) => {
  return {
    showing: pap.workflow.showingSelectLogoSubcat,
    categories,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    onClose: toggleSelectLogoSubcat,
  })(SelectLogoSubcatModal)
);
