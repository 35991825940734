import request from "axios";
import fileDownload from "js-file-download";
import { PAGINATION_PAGE_SIZE } from "common/variables";
import { getPaginationValues } from "common/utils";

/* ACTIONS
================================================================================================ */
export const FETCH = "crowdspring/admin/FETCH_BATCH";
export const FETCH_REQUEST = "crowdspring/admin/FETCH_BATCH_REQUEST";
export const FETCH_SUCCESS = "crowdspring/admin/FETCH_BATCH_SUCCESS";
export const FETCH_FAILURE = "crowdspring/admin/FETCH_BATCH_FAILURE";

export const CHECK = "crowdspring/admin/CHECK_BATCH";
export const CHECK_REQUEST = "crowdspring/admin/CHECK_BATCH_REQUEST";
export const CHECK_SUCCESS = "crowdspring/admin/CHECK_BATCH_SUCCESS";
export const CHECK_FAILURE = "crowdspring/admin/CHECK_BATCH_FAILURE";

export const ADD = "crowdspring/admin/ADD_BATCH";
export const ADD_REQUEST = "crowdspring/admin/ADD_BATCH_REQUEST";
export const ADD_SUCCESS = "crowdspring/admin/ADD_BATCH_SUCCESS";
export const ADD_FAILURE = "crowdspring/admin/ADD_BATCH_FAILURE";

export const MARK = "crowdspring/admin/MARK_BATCH";
export const MARK_REQUEST = "crowdspring/admin/MARK_BATCH_REQUEST";
export const MARK_SUCCESS = "crowdspring/admin/MARK_BATCH_SUCCESS";
export const MARK_FAILURE = "crowdspring/admin/MARK_BATCH_FAILURE";

export const DOWNLOAD = "crowdspring/admin/DOWNLOAD";

/* INITIAL STATES
================================================================================================ */
export const initialState = {
  isFetching: false,
  isError: false,
  batches: [],
  pageCount: PAGINATION_PAGE_SIZE,
  currentPage: 1,
  batchCount: 0,
  canAdd: false,
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CHECK_SUCCESS:
      return {
        ...state,
        canAdd: action.payload === "true",
      };
    case FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_SUCCESS:
      const data = action.payload;
      const { currentPage, pageCount } = getPaginationValues(data);
      return {
        ...state,
        currentPage,
        pageCount,
        batchCount: data.count,
        isFetching: false,
        batches: data.results,
      };
    case ADD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        batches: [...state.batches, action.payload],
      };
    default:
      return state;
  }
}

export const fetch = (o) => (dispatch, getState) => {
  const { currentPage } = getState().admin.batches;
  const offset = o !== undefined ? o : PAGINATION_PAGE_SIZE * (currentPage - 1);

  const url = `/api/v1/radmin/payout/batches/?limit=${PAGINATION_PAGE_SIZE}&offset=${offset}`;

  return dispatch({
    type: FETCH,
    promise: request.get(url),
  });
};

export const add = () => (dispatch) =>
  request
    .get("/api/v1/radmin/payout/batches/create_batches/", {
      responseType: "blob",
    })
    .then((res) => {
      if (res.data && res.data.size) {
        const filename = `batches-${Date.now()}.zip`;
        fileDownload(res.data, filename);
      }
    })
    .then(() => dispatch(fetch()));

export const markAsPaid = (id) => (dispatch) => {
  const url = `/api/v1/radmin/payout/batches/${id}/mark_paid/`;

  return dispatch({
    type: MARK,
    promise: request.post(url).then(() => dispatch(fetch())),
  });
};

export const fetchSpreadsheet = (id) => (dispatch) =>
  request
    .get(`/api/v1/radmin/payout/batches/${id}/payout_spreadsheet/`, {
      responseType: "blob",
    })
    .then((res) => {
      if (res.data && res.data.size) {
        const filename = res.headers["content-disposition"]
          .split("attachment; filename=")[1]
          .replaceAll('"', "");
        fileDownload(res.data, filename);
      }
    })
    .then(() => dispatch(() => fetch()));

export const checkBatch = () => (dispatch) =>
  dispatch({
    type: CHECK,
    promise: request.get("/api/v1/radmin/payout/batches/can_create_batches/"),
  });
