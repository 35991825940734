import { connect } from "react-redux";
import { withRouter } from "react-router";
import { saveLastQuery } from "common/searcher/ducks";
import { getValueFromQuery } from "common/searcher/utils";
import Searcher from "common/searcher/components";

export const mapStateToProps = (state, { resourceName, location }) => {
  return {
    initialValue: getValueFromQuery(resourceName, location.query),
  };
};

export default withRouter(
  connect(mapStateToProps, { saveLastQuery })(Searcher)
);
