import React from "react";
import PropTypes from "prop-types";
import idx from "idx";
import { unTrailingSlashIt } from "trailing-slash-it";
import TabbedNavigation from "common/components/tabbed_navigation";

const clientLinks = [
  {
    url: "/your-projects/client/drafts/",
    name: "Drafts",
  },
  {
    url: "/your-projects/client/active/",
    name: "Active",
  },
  {
    url: "/your-projects/client/wrapup/",
    name: "In wrap-up",
  },
  {
    url: "/your-projects/client/complete/",
    name: "Completed",
  },
];

const creativeLinks = [
  {
    url: "/your-projects/creative/watchlist/",
    name: "Watchlist",
  },
  {
    url: "/your-projects/creative/participating/",
    name: "Participating",
  },
  {
    url: "/your-projects/creative/wrapup/",
    name: "In wrap-up",
  },
  {
    url: "/your-projects/creative/awarded/",
    name: "Awarded",
  },
  {
    url: "/your-projects/creative/history/",
    name: "History",
  },
];

const ProjectTypeTabs = ({ counts, params, router, push }) => {
  const isOnCreative = params.userType === "creative";
  const links = (isOnCreative ? creativeLinks : clientLinks).map((link) => {
    const tab = unTrailingSlashIt(link.url).split("/").slice(-1)[0];
    const count = idx(counts, (_) => _[params.userType][tab]) || 0;

    return {
      ...link,
      name: `${link.name} (${count})`,
    };
  });

  return <TabbedNavigation tabs={{ links }} router={router} push={push} />;
};

export default ProjectTypeTabs;
