import React, { Component } from "react";
import PropTypes from "prop-types";
import { CREATIVES_RESOURCE_NAME } from "explore/constants";
import { TYPE_CHECKBOXES } from "common/filter/constants";
import Searcher from "common/searcher/containers";
import SorterSelect from "common/sorter/components/select";
import Filter from "common/filter/containers";

export default class CreativesControls extends Component {
  componentDidMount() {
    this.props.fetchCountriesIfNeeded();
  }

  render() {
    const {
      sortingOptions,
      defaultSorting,
      subcategoryOptions,
      countryOptions,
      eliteOptions,
      platinumOptions,
      languageOptions,
      skipQuerySave,
    } = this.props;

    return (
      <aside className="sidebar sidebar-filter">
        <div className="controls-group">
          <Searcher resourceName={CREATIVES_RESOURCE_NAME} />
        </div>
        <div className="controls-group">
          <div className="group-title">Sort</div>
          <SorterSelect
            resourceName={CREATIVES_RESOURCE_NAME}
            options={sortingOptions}
            defaultValue={defaultSorting}
          />
        </div>
        <div className="controls-group">
          <div className="group-title">Specialization</div>
          <Filter
            field="permitted_sub_category_ids"
            type={TYPE_CHECKBOXES}
            resourceName={CREATIVES_RESOURCE_NAME}
            options={subcategoryOptions}
            skipQuerySave={skipQuerySave}
          />
        </div>
        <div className="controls-group">
          <div className="group-title">Creative Level</div>
          <Filter
            field="is_platinum"
            type={TYPE_CHECKBOXES}
            resourceName={CREATIVES_RESOURCE_NAME}
            options={platinumOptions}
            skipQuerySave={skipQuerySave}
          />
          <Filter
            field="is_elite"
            type={TYPE_CHECKBOXES}
            resourceName={CREATIVES_RESOURCE_NAME}
            options={eliteOptions}
            skipQuerySave={skipQuerySave}
          />
        </div>
        <div className="controls-group">
          <div className="group-title">Country</div>
          <Filter
            field="country"
            type={TYPE_CHECKBOXES}
            resourceName={CREATIVES_RESOURCE_NAME}
            options={countryOptions}
            skipQuerySave={skipQuerySave}
            collapsableAfter={5}
          />
        </div>
        <div className="controls-group">
          <div className="group-title">Language</div>
          <Filter
            field="all_langs"
            type={TYPE_CHECKBOXES}
            resourceName={CREATIVES_RESOURCE_NAME}
            options={languageOptions}
            skipQuerySave={skipQuerySave}
            collapsableAfter={5}
          />
        </div>
      </aside>
    );
  }
}
