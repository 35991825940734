/* shared by "your entries" and "available entries (new portfolio item)" */
export const IMAGE_ENTRIES_RESOURCE_NAME = "image_entries";

/* shared by "your entries" and "available entries (new portfolio item)" */
export const NAME_ENTRIES_RESOURCE_NAME = "name_entries";

export const TESTIMONIALS_RESOURCE_NAME = "testimonials";

export const PROJECTS_RESOURCE_NAME = "projects";

export const PORTFOLIO_IMAGES_RESOURCE_NAME = "portfolio_images";
export const PORTFOLIO_IMAGES_ITEM_NAME = "design";

export const PORTFOLIO_NAMES_RESOURCE_NAME = "portfolio_names";
export const PORTFOLIO_NAMES_ITEM_NAME = "name";

export const PORTFOLIO_ENTRIES_PAGE_SIZE = 6;
