import range from "lodash/range";
import sortBy from "lodash/sortBy";
import flatten from "lodash/flatten";
import { MARKETING_SEGMENTS } from "common/variables";
import {
  GOAL_OPTIONS,
  PRJ_STATUS_CHANGED_EVENT_INTERVALS,
} from "email_automations/constants";
import { PROJECT_STATUS_OPTIONS } from "project/constants";

const EMAIL_VARIATION_NAMES = ["A", "B", "C", "D", "E", "F"];
export const getAutomationEmailName = (emailIdx, variationIdx, variations) =>
  `Email ${emailIdx + 1}${
    variations.length > 1 ? EMAIL_VARIATION_NAMES[variationIdx] : ""
  }`;

const ALL_ENTRY_EVENT_GROUPS = [
  {
    label: "Action",
    name: "action",
    options: sortBy(
      [
        { field: "user-registers", label: "User registers" },
        { field: "focus-group-launched", label: "User launches a focus group" },
        {
          field: "presentation-launched",
          label: "User launches a presentation",
        },
        { field: "project-draft", label: "User creates a draft" },
        {
          field: "wrapup",
          label: "User downloads wrap-up final files for the first time",
        },
        { field: "winner-awarded", label: "User awards a winner entry" },
        {
          field: "wrapup-survey-crowdspring",
          label: "User rates crowdspring 5 stars",
        },
        {
          field: "projectStatusEntryType",
          label: "Project stays in status (by entry type)",
        },
        {
          field: "projectStatusSubCat",
          label: "Project stays in status (by subcategory)",
        },
      ],
      (o) => o.label
    ),
  },
  {
    label: "Country tier",
    name: "countryTier",
    type: "draftCountryQualification",
  },
  {
    label: "Draft qualification",
    name: "draftQualification",
    type: "draftQualification",
  },
  {
    label: "Sub-category",
    name: "subCategory",
    type: "subCategory",
  },
  {
    label: "Entry type",
    name: "entryType",
    options: [
      { field: "image", label: "Image" },
      { field: "name", label: "Name" },
    ],
  },
  {
    label: "Status",
    name: "status",
    options: PROJECT_STATUS_OPTIONS,
  },
  {
    label: "For how many hours?",
    name: "interval",
    options: PRJ_STATUS_CHANGED_EVENT_INTERVALS,
  },
];
export const getEntryEventGroups = (value) => {
  if (value["action"] === "project-draft") {
    return ALL_ENTRY_EVENT_GROUPS.filter((g) =>
      ["action", "countryTier", "draftQualification", "subCategory"].includes(
        g.name
      )
    );
  }

  if (["wrapup", "winner-awarded"].includes(value["action"])) {
    return ALL_ENTRY_EVENT_GROUPS.filter((g) =>
      ["action", "subCategory"].includes(g.name)
    );
  }

  if (
    ["projectStatusEntryType", "projectStatusSubCat"].includes(value["action"])
  ) {
    const groupNames = ["action", "status", "interval"].concat(
      value["action"] === "projectStatusEntryType"
        ? ["entryType"]
        : ["subCategory"]
    );

    return ALL_ENTRY_EVENT_GROUPS.filter((g) => groupNames.includes(g.name));
  }

  return ALL_ENTRY_EVENT_GROUPS.slice(0, 1);
};

const ALL_QUERY_PART_GROUPS = [
  {
    label: "Criteria",
    name: "criteria",
    options: sortBy(
      [
        { field: "status", label: "Status" },
        { field: "type", label: "Type" },
        { field: "registrationDate", label: "Registration date" },
        { field: "registrationType", label: "Registration type" },
        { field: "emailValidation", label: "Email validation" },
        { field: "participationStatus", label: "Participation status" },
        { field: "otoStatus", label: "1-to-1 status" },
        { field: "segment", label: "Marketing segment" },
        { field: "conversionSource", label: "Conversion source" },
        { field: "userCountryTier", label: "Country tier" },
        { field: "verificationStatus", label: "Verification status" },
        { field: "approvedSubcat", label: "Approved sub-category" },
        { field: "referrals", label: "Referrals" },
        { field: "reputation", label: "Reputation" },
        { field: "drafts", label: "Drafts" },
        { field: "openProjects", label: "Open projects" },
        { field: "closedProjects", label: "Closed projects" },
        { field: "awardedProjects", label: "Awarded projects" },
        { field: "completedProjects", label: "Completed projects" },
        { field: "unawardedProjects", label: "Un-awarded projects" },
        { field: "postedProjects", label: "Posted projects" },
        { field: "refundedProjects", label: "Refunded projects" },
        { field: "mostRecentProjectAt", label: "Time of last project" },
        {
          field: "mostRecentProjectSubcat",
          label: "Sub-category of last project",
        },
        {
          field: "mostRecentSubcatProjectAt",
          label: "Time of last sub-category project",
        },
        { field: "mostRecentDraftAt", label: "Time of last draft" },
      ],
      (o) => o.label
    ),
  },
  {
    label: "Status",
    name: "status",
    options: [
      { field: "active", label: "Active" },
      { field: "inactive", label: "Inactive" },
    ],
  },
  {
    label: "Type",
    name: "type",
    options: [
      { field: "client", label: "Client" },
      { field: "creative", label: "Creative" },
    ],
  },
  {
    label: "Registered...",
    name: "registrationDate",
    options: flatten(
      range(1, 13).map((n) => [
        {
          field: `user-date-joined-gtenow-${n}md`,
          label: `less than ${n} month${n > 1 ? "s" : ""} ago`,
        },
        {
          field: `user-date-joined-ltnow-${n}md`,
          label: `more than ${n} month${n > 1 ? "s" : ""} ago`,
        },
      ])
    ),
  },
  {
    label: "Lazy-registered?",
    name: "registrationType",
    options: [
      { field: "user-is-lazy-registered", label: "Yes" },
      { field: "user-is-not-lazy-registered", label: "No" },
    ],
  },
  {
    label: "Email validated?",
    name: "emailValidation",
    options: [
      { field: "user-is-email-validated", label: "Yes" },
      { field: "user-is-not-email-validated", label: "No" },
    ],
  },
  {
    label: "Can participate?",
    name: "participationStatus",
    options: [
      { field: "user-is-allowed-to-participate", label: "Yes" },
      { field: "user-is-not-allowed-to-participate", label: "No" },
    ],
  },
  {
    label: "Available for 1-to-1 projects?",
    name: "otoStatus",
    options: [
      { field: "user-is-available-for-1-to-1", label: "Yes" },
      { field: "user-is-not-available-for-1-to-1", label: "No" },
    ],
  },
  {
    label: "Marketing segment",
    name: "segment",
    options: MARKETING_SEGMENTS,
  },
  {
    label: "Conversion source",
    name: "conversionSource",
    options: [
      { field: "brand_studio", label: "Brand Studio" },
      { field: "collaboration_invite", label: "Collaboration invite" },
      { field: "focus_group_voter", label: "Focus group voter" },
    ],
  },
  {
    label: "Country tier",
    name: "userCountryTier",
    options: [
      { field: "a", label: "A-tier" },
      { field: "b", label: "B-tier" },
    ],
  },
  {
    label: "Verification status",
    name: "verificationStatus",
    options: [
      {
        field: "creative-verification-successful",
        label: "Verification completed",
      },
      {
        field: "creative-verification-in-progress",
        label: "Verification in progress",
      },
      { field: "no-successful-creative-verification", label: "Not verified" },
    ],
  },
  {
    label: "Referrals",
    name: "referrals",
    options: [
      { field: "gte1-referrals-sent", label: "1+ referrals sent" },
      {
        field: "gte1-5star-recommend-cs",
        label: "1+ 'would you recommend crowdspring' *****",
      },
    ],
  },
  {
    label: "Reputation",
    name: "reputation",
    options: [
      { field: "eq100", label: "100" },
      ...range(95, 65, -5).map((n) => ({ field: `gte${n}`, label: `>= ${n}` })),
      ...range(100, 65, -5).map((n) => ({
        field: `lte${n}`,
        label: `<= ${n}`,
      })),
    ],
  },
  {
    label: "Number",
    name: "number0or1+",
    options: [
      { field: "eq0", label: "0" },
      { field: "gte1", label: "1+" },
    ],
  },
  {
    label: "Country tier",
    name: "countryTier",
    type: "draftCountryQualification",
  },
  {
    label: "Draft qualification",
    name: "draftQualification",
    type: "draftQualification",
  },
  {
    label: "Sub-category",
    name: "subCategory",
    type: "subCategory",
  },
  {
    label: "Number",
    name: "number1+",
    options: [{ field: "gte1", label: "1+" }],
  },
  {
    label: "Number",
    name: "numberUnawarded",
    options: [
      {
        field: "prj-awrd-gte1-unawarded-no-focusgroup",
        label: "1+ without focus group",
      },
      {
        field: "prj-awrd-gte1-unawarded-no-presentation",
        label: "1+ without presentation",
      },
      {
        field: "prj-awrd-gte1-unawarded-no-collaboration",
        label: "1+ without collaboration",
      },
    ],
  },
  {
    label: "Number",
    name: "numberPosted",
    options: [
      { field: "prj-post-total-eq0", label: "0 (new client)" },
      { field: "prj-post-gte1", label: "1+ (repeat client)" },
      ...range(1, 11).map((n) => ({
        field: `prj-post-total-eq${n}`,
        label: n,
      })),
      ...range(10, 1, -1).map((n) => ({
        field: `prj-post-total-lte${n}`,
        label: `<= ${n}`,
      })),
      { field: "prj-post-gte1-any-type-", label: "1+ in..." },
      { field: "prj-post-eq0-any-type-", label: "0 in..." },
    ],
  },
  {
    label: "Posted...",
    name: "mostRecentProjectAt",
    options: range(3, 25, 3)
      .map((n) => ({
        field: `prj-post-most-recent-eqnow-${n}md`,
        label: `${n} months ago`,
      }))
      .concat(
        range(3, 25, 3).map((n) => ({
          field: `prj-post-most-recent-ltnow-${n}md`,
          label: `more than ${n} months ago`,
        }))
      ),
  },
  {
    label: "Posted...",
    name: "mostRecentSubcatProjectAt",
    options: range(3, 25, 3)
      .map((n) => ({
        field: `-eqnow-${n}md`,
        label: `${n} months ago`,
      }))
      .concat(
        range(3, 25, 3).map((n) => ({
          field: `-ltnow-${n}md`,
          label: `more than ${n} months ago`,
        }))
      ),
  },
  {
    label: "Created...",
    name: "mostRecentDraftAt",
    options: range(3, 13, 3)
      .map((n) => ({
        field: `eqnow-${n}md`,
        label: `${n} months ago`,
      }))
      .concat(
        range(3, 13, 3).map((n) => ({
          field: `gtnow-${n}md`,
          label: `less than ${n} months ago`,
        }))
      )
      .concat(
        range(3, 13, 3).map((n) => ({
          field: `ltnow-${n}md`,
          label: `more than ${n} months ago`,
        }))
      ),
  },
];
export const getQueryPartGroups = (value) => {
  let groups = [ALL_QUERY_PART_GROUPS.find((g) => g.name === "criteria")];

  if (!value.criteria) {
    return groups;
  }

  if (
    [
      "status",
      "type",
      "registrationDate",
      "registrationType",
      "verificationStatus",
      "emailValidation",
      "participationStatus",
      "otoStatus",
      "segment",
      "referrals",
      "reputation",
      "mostRecentProjectAt",
      "userCountryTier",
      "conversionSource",
      "mostRecentDraftAt",
    ].includes(value.criteria)
  ) {
    return [
      ...groups,
      ALL_QUERY_PART_GROUPS.find((g) => g.name === value.criteria),
    ];
  }

  if (["approvedSubcat", "mostRecentProjectSubcat"].includes(value.criteria)) {
    return [
      ...groups,
      ALL_QUERY_PART_GROUPS.find((g) => g.name === "subCategory"),
    ];
  }

  if (value.criteria === "mostRecentSubcatProjectAt") {
    return [
      ...groups,
      ALL_QUERY_PART_GROUPS.find((g) => g.name === "subCategory"),
      ALL_QUERY_PART_GROUPS.find((g) => g.name === value.criteria),
    ];
  }

  if (
    [
      "closedProjects",
      "awardedProjects",
      "completedProjects",
      "refundedProjects",
    ].includes(value.criteria)
  ) {
    return [
      ...groups,
      ALL_QUERY_PART_GROUPS.find((g) => g.name === "number1+"),
    ];
  }

  if (["openProjects"].includes(value.criteria)) {
    return [
      ...groups,
      ALL_QUERY_PART_GROUPS.find((g) => g.name === "number0or1+"),
    ];
  }

  if (value.criteria === "drafts") {
    return [
      ...groups,
      ALL_QUERY_PART_GROUPS.find((g) => g.name === "number0or1+"),
      ALL_QUERY_PART_GROUPS.find((g) => g.name === "countryTier"),
      ALL_QUERY_PART_GROUPS.find((g) => g.name === "draftQualification"),
      ALL_QUERY_PART_GROUPS.find((g) => g.name === "subCategory"),
    ];
  }

  if (value.criteria === "unawardedProjects") {
    return [
      ...groups,
      ALL_QUERY_PART_GROUPS.find((g) => g.name === "numberUnawarded"),
    ];
  }

  if (value.criteria === "postedProjects") {
    return [
      ...groups,
      ALL_QUERY_PART_GROUPS.find((g) => g.name === "numberPosted"),
      ...((value.numberPosted || "").includes("-any-type-")
        ? [ALL_QUERY_PART_GROUPS.find((g) => g.name === "subCategory")]
        : []),
    ];
  }

  return [];
};

export const getGoalDescription = (goal) => {
  const option = GOAL_OPTIONS.find((o) => o.field === goal);
  return option ? option.label : "";
};

export const getStatusLabel = (obj) => {
  if (!obj) {
    return "";
  }

  if (obj.status === "complete") {
    return "ended";
  }

  if (obj.status === "soft_complete") {
    return "soft ended";
  }

  return obj.status;
};
