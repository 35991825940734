import request from "axios";
import { PROJECT_ENTRIES_RESOURCE_NAME } from "admin/constants";
import { getAPIQueryString } from "common/utils";
import { savePageCount } from "common/pager/ducks";
import { setBannerError } from "error";
import { setSelectedEntries } from "admin/ducks/entries";

/* ACTIONS
================================================================================================ */
const FETCH_ALL = "admin/FETCH_PROJECT_ENTRIES";
const FETCH_ALL_REQUEST = "admin/FETCH_PROJECT_ENTRIES_REQUEST";
const FETCH_ALL_SUCCESS = "admin/FETCH_PROJECT_ENTRIES_SUCCESS";

const CLEAR_ALL = "admin/CLEAR_PROJECT_ENTRIES";

const SET_VIEW_TYPE = "admin/SET_VIEW_TYPE";

const SET_GROUPING = "admin/SET_GROUPING";

const WITHDRAW = "admin/WITHDRAW_PROJECT_ENTRIES";
export const WITHDRAW_SUCCESS = "admin/WITHDRAW_PROJECT_ENTRIES_SUCCESS";

const UNWITHDRAW = "admin/UNWITHDRAW_PROJECT_ENTRIES";
export const UNWITHDRAW_SUCCESS = "admin/UNWITHDRAW_PROJECT_ENTRIES_SUCCESS";

const REMOVE_CREATIVE = "admin/REMOVE_CREATIVE";
export const REMOVE_CREATIVE_SUCCESS = "admin/REMOVE_CREATIVE_SUCCESS";

const HIDE = "admin/HIDE_PROJECT_ENTRY";
export const HIDE_SUCCESS = "admin/HIDE_PROJECT_ENTRY_SUCCESS";

const UNHIDE = "admin/UNHIDE_PROJECT_ENTRY";
export const UNHIDE_SUCCESS = "admin/UNHIDE_PROJECT_ENTRY_SUCCESS";

/* HELPERS
================================================================================================ */
export const getEntries = (state) => {
  const { list, grouped } = state.admin.projectEntries;
  if (grouped) {
    return list;
  }
  /* if not grouped, return an entry copy for each revision */
  return list.reduce((acc, entry) => {
    entry.revisions.forEach(
      (rev) =>
        (acc = acc.concat({ ...entry, key: `rev_${rev.id}`, revisions: [rev] }))
    );
    return acc;
  }, []);
};

/* INITIAL STATES
================================================================================================ */
export const initialState = {
  list: [],
  isFetching: false,
  grouped: false,
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_ALL_SUCCESS:
      return {
        ...state,
        list: action.payload.results,
        isFetching: false,
      };
    case CLEAR_ALL:
      return {
        ...state,
        list: [],
      };
    case SET_VIEW_TYPE:
      return {
        ...state,
        viewType: action.payload,
      };
    case SET_GROUPING:
      return {
        ...state,
        grouped: action.payload,
      };
    case WITHDRAW_SUCCESS:
    case REMOVE_CREATIVE_SUCCESS:
      return {
        ...state,
        list: state.list.map((entry) =>
          action.payload.includes(entry.id)
            ? { ...entry, withdrawn: true, withdrawn_by_admin: true }
            : entry
        ),
      };
    case UNWITHDRAW_SUCCESS:
      return {
        ...state,
        list: state.list.map((entry) =>
          action.payload.includes(entry.id)
            ? { ...entry, withdrawn: false, withdrawn_by_admin: false }
            : entry
        ),
      };
    case HIDE_SUCCESS:
      return {
        ...state,
        list: state.list.map((entry) =>
          action.id === entry.id ? { ...entry, hidden: true } : entry
        ),
      };
    case UNHIDE_SUCCESS:
      return {
        ...state,
        list: state.list.map((entry) =>
          action.id === entry.id ? { ...entry, hidden: false } : entry
        ),
      };
    default:
      return state;
  }
}

export const fetchAll = (projectId, query) => (dispatch) => {
  const queryStr = getAPIQueryString(PROJECT_ENTRIES_RESOURCE_NAME, query);
  const promise = request.get(
    `/api/v1/projects/${projectId}/entries/?${queryStr}`
  );

  /* save page count in pager state */
  promise.then((res) =>
    dispatch(savePageCount(PROJECT_ENTRIES_RESOURCE_NAME, res))
  );

  return dispatch({
    type: FETCH_ALL,
    promise,
  });
};

export const clearAll = () => {
  return {
    type: CLEAR_ALL,
  };
};

export const setViewType = (type) => {
  return {
    type: SET_VIEW_TYPE,
    payload: type,
  };
};

export const setGrouping = (grouped) => {
  return {
    type: SET_GROUPING,
    payload: grouped,
  };
};

export const withdrawSelected = (data) => (dispatch, getState) => {
  const { selectedEntries } = getState().admin.entries;
  const entriesByCreative = selectedEntries.reduce((acc, entry) => {
    const creativeId = entry.author;
    if (!acc[creativeId]) {
      acc[creativeId] = [];
    }
    acc[creativeId].push(entry.id);
    return acc;
  }, {});

  /* one withdraw request per creative */
  for (const creativeId in entriesByCreative) {
    dispatch(
      withdraw(creativeId, {
        ...data,
        entries: entriesByCreative[creativeId].join(","),
      })
    );
  }

  dispatch(setSelectedEntries([]));

  return Promise.resolve();
};

export const withdraw = (creativeId, data) => (dispatch) => {
  const promise = request.put(
    `/api/v1/radmin/users/${creativeId}/withdraw_entries/`,
    data
  );

  promise.catch((err) => {
    const errorMsg = err.data ? JSON.stringify(err.data) : "Unknown error.";
    dispatch(setBannerError("Error withdrawing entries", errorMsg));
  });

  dispatch({
    type: WITHDRAW,
    promise,
  });

  return promise;
};

export const unwithdraw = (creativeId, data) => (dispatch) => {
  const promise = request.put(
    `/api/v1/radmin/users/${creativeId}/unwithdraw_entries/`,
    data
  );

  promise.catch((err) => {
    const errorMsg = err.data ? JSON.stringify(err.data) : "Unknown error.";
    dispatch(setBannerError("Error un-withdrawing entries", errorMsg));
  });

  dispatch({
    type: UNWITHDRAW,
    promise,
  });

  return promise;
};

export const removeCreative = (creativeId, data) => (dispatch) => {
  const promise = request.put(
    `/api/v1/radmin/users/${creativeId}/remove_creative/`,
    data
  );

  promise.catch((err) => {
    const errorMsg = err.data ? JSON.stringify(err.data) : "Unknown error.";
    dispatch(setBannerError("Error removing creative", errorMsg));
  });

  dispatch({
    type: REMOVE_CREATIVE,
    promise,
  });

  return promise;
};

export const hide = (creativeId, data) => (dispatch) => {
  const promise = request.put(
    `/api/v1/radmin/users/${creativeId}/hide_entry/`,
    data
  );

  promise.catch((err) => {
    const errorMsg = err.data ? JSON.stringify(err.data) : "Unknown error.";
    dispatch(setBannerError("Error hiding entry", errorMsg));
  });

  dispatch({
    type: HIDE,
    id: data.entry,
    promise,
  });

  return promise;
};

export const unhide = (creativeId, data) => (dispatch) => {
  const promise = request.put(
    `/api/v1/radmin/users/${creativeId}/unhide_entry/`,
    data
  );

  promise.catch((err) => {
    const errorMsg = err.data ? JSON.stringify(err.data) : "Unknown error.";
    dispatch(setBannerError("Error un-hiding entry", errorMsg));
  });

  dispatch({
    type: UNHIDE,
    id: data.entry,
    promise,
  });

  return promise;
};
