import idx from "idx";
import PropTypes from "prop-types";
import request from "axios";
import some from "lodash/some";
import {
  getProjectEntries,
  getProjectEntriesState,
  dismissFeedback,
} from "project/ducks/entries";
import * as commonImports from "project/ducks/common";
import { setBannerError } from "error";

/* number of seconds before "feedback sent" notice is dismissed */
const SHOW_FEEDBACK_SENT_FOR = 3;

/* HELPERS
================================================================================================ */
export const getProjectRevisions = (state) =>
  getProjectEntriesState(state).revisionsById;
export const getRevisionsComments = (state) =>
  getProjectEntriesState(state).commentsByRevision;

/* REDUCERS
================================================================================================ */
export default function revisionsReducer(state, action) {
  let revisions;
  let revision;
  let entry;
  let entryObject;
  let revisionObject;
  let existingComments;
  let existingEntry;
  let payload;
  let revisionId;

  switch (action.type) {
    case commonImports.SCORE_REVISION_SUCCESS:
      revision = action.payload;
      revisionObject = {};
      revisionObject[revision.id] = {
        ...state.revisionsById[revision.id],
        score: revision.score,
        new: false,
      };

      return {
        ...state,
        isError: false,
        revisionsById: { ...state.revisionsById, ...revisionObject },
      };
    case commonImports.SCORE_REVISION_FAILURE:
      return {
        ...state,
        isError: true,
      };
    case commonImports.FETCH_COMMENTS_SUCCESS:
      payload = action.payload;
      revisionObject = {};
      if (payload[0]) {
        /* store comments with older ones first */
        revisionObject[payload[0].revision] = payload;
      }

      return {
        ...state,
        commentsByRevision: {
          ...state.commentsByRevision,
          ...revisionObject,
        },
      };
    case commonImports.FETCH_COMMENTS_FAILURE:
      return {
        ...state,
        isError: true,
      };
    case commonImports.ADD_COMMENT_SUCCESS:
      payload = action.payload;
      revisionId = payload.revision;
      revisionObject = {};
      existingComments = [];

      if (revisionId in state.commentsByRevision) {
        existingComments = state.commentsByRevision[revisionId];
      }
      revisionObject[revisionId] = [...existingComments, payload];

      return {
        ...state,
        commentsByRevision: {
          ...state.commentsByRevision,
          ...revisionObject,
        },
      };
    case commonImports.ADD_COMMENT_FAILURE:
      return {
        ...state,
        isError: true,
      };
    case commonImports.ADD_COMMENT_REPLY_SUCCESS:
      payload = action.payload;
      revisionId = payload.revision;
      revisionObject = {};
      existingComments = [...(state.commentsByRevision[revisionId] || [])];

      existingComments.forEach((comment) => {
        if (comment.id === payload.reply_to) {
          comment.replies.push(payload);
        }
      });

      revisionObject[revisionId] = existingComments;

      return {
        ...state,
        commentsByRevision: {
          ...state.commentsByRevision,
          ...revisionObject,
        },
      };
    case commonImports.ADD_COMMENT_REPLY_FAILURE:
      return {
        ...state,
        isError: true,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
================================================================================================ */
export function fetchComments(entryType, revisionId) {
  return {
    type: commonImports.FETCH_COMMENTS,
    promise: request.get(
      `/api/v1/project-${entryType}-entry-revisions/${revisionId}/comments/`
    ),
  };
}

export const addComment = (entryType, revisionId, formData) => (dispatch) => {
  const promise = request.post(
    `/api/v1/project-${entryType}-entry-revisions/${revisionId}/comments/`,
    { ...formData }
  );
  dispatch({
    type: commonImports.ADD_COMMENT,
    promise,
  });
  return promise;
};

export const addCommentReply = (entryType, id, formData) => (dispatch) => {
  const promise = request.post(
    `/api/v1/project-${entryType}-entry-revisions/${id}/comments/`,
    { ...formData }
  );
  dispatch({
    type: commonImports.ADD_COMMENT_REPLY,
    promise,
  });
  return promise;
};

export function addInPlaceComment(entryType, id, formData) {
  return {
    type: commonImports.ADD_COMMENT,
    promise: request.post(
      `/api/v1/project-${entryType}-entry-revisions/${id}/comments/`,
      { ...formData }
    ),
  };
}

export const scoreRevision = (entryType, id, score) => (dispatch) => {
  dispatch({ type: commonImports.SCORE_REVISION, payload: { id } });
  const url = `/api/v1/project-${entryType}-entry-revisions/${id}/score/`;
  return request
    .patch(url, { score })
    .then((response) => {
      /* fetch updated comments */
      dispatch(fetchComments(entryType, id));

      dispatch({
        type: commonImports.SCORE_REVISION_SUCCESS,
        payload: response.data,
      });
    })
    .catch(() =>
      dispatch(
        setBannerError(
          "Scoring Error",
          "There was an error scoring your revision"
        )
      )
    );
};

export const sendFeedback = (feedback) => (dispatch, getState) => {
  const state = getState();
  const { scoredRevision } = getProjectEntriesState(getState());
  const entry = idx(getProjectRevisions(state), (_) => _[scoredRevision].entry);
  const { entry_type } = getProjectEntries(state)[entry];
  const isEmpty = !some(Object.values(feedback), (v) =>
    typeof v === "string" ? !!v.trim() : !!v
  );
  const url = `/api/v1/project-${entry_type}-entry-revisions/${scoredRevision}/feedback/`;
  const promise = isEmpty
    ? Promise.resolve()
    : request.patch(url, { feedback });

  dispatch({ type: commonImports.REVISION_FEEDBACK });

  return promise
    .then(() => {
      /* dismiss 'feedback sent' notice after a given time */
      setTimeout(
        () => dispatch(dismissFeedback()),
        SHOW_FEEDBACK_SENT_FOR * 1000
      );

      /* fetch updated comments */
      dispatch(fetchComments(entry_type, scoredRevision));

      return dispatch({ type: commonImports.REVISION_FEEDBACK_SUCCESS });
    })
    .catch(() =>
      dispatch(
        setBannerError(
          "Feedback Error",
          "There was an error saving your feedback for this revision"
        )
      )
    );
};
