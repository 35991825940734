import React from "react";
import idx from "idx";
import {
  PLATINUM_ONLY_DESIGNERS,
  UNLIMITED_COLLABORATION,
  NDAS,
  SEARCH_EXCLUSION,
  PRIVATE_PROJECT,
  AWARD_ASSURED,
  DURATION_GROUP,
  PROMOTION_GROUP,
  LEGACY_PLATINUM_ONLY_DESIGNERS,
  LEGACY_UNLIMITED_COLLABORATION,
  LEGACY_NDAS,
  LEGACY_SEARCH_EXCLUSION,
  LEGACY_PRIVATE_GALLERY,
  LEGACY_DURATION,
  LEGACY_STARTER_PROMOTION,
  LEGACY_BASIC_PROMOTION,
  LEGACY_ADVANCED_PROMOTION,
  LEGACY_ULTIMATE_PROMOTION,
  LEGACY_TWITTER_PROMOTION,
  NDAS_MANUAL_APPROVAL,
  UNLIMITED_PRESENTATIONS,
  UNLIMITED_FOCUS_GROUPS,
  ONE_FOCUS_GROUP,
  PRIVACY_BUNDLE,
  PLATINUM_ONLY_CREATIVES,
  PERSONAL_CONSULTANT,
} from "pap/constants";
import NDAPreviewButton from "pap/containers/nda_preview_button";

/* HELPERS
================================================================================================ */

/* Determine "details" section steps based on app state and params */
export const getDetailsSteps = ({
  pap: {
    workflow: { lazyRegistering },
    project: { projectData },
  },
}) => {
  const filters = {
    showForAnonymous: lazyRegistering,
    showFor1to1: !!projectData && projectData.is_one_to_one,
  };

  let steps = ALL_DETAILS_STEPS;

  /* filter steps based on filters defined above */
  Object.keys(filters).map((k) => {
    steps = steps.filter((step) =>
      filters[k] ? ["only", "yes"].includes(step[k]) : step[k] != "only"
    );
  });

  return steps;
};

/* ACTIONS
================================================================================================ */

const CHANGE_STEP = "pap/CHANGE_STEP";
const CHANGE_SECTION = "pap/CHANGE_SECTION";
const EDIT_REVIEW_SECTION = "pap/EDIT_REVIEW_SECTION";
const CANCEL_EDIT_REVIEW_SECTION = "pap/CANCEL_EDIT_REVIEW_SECTION";
const FETCH_PROJECT_DATA = "pap/FETCH_PROJECT";
const DISPLAY_FULL_SCREEN = "pap/DISPLAY_FULL_SCREEN";
const HIDE_FULL_SCREEN = "pap/HIDE_FULL_SCREEN";
const SET_LAZY_REGISTERING = "pap/SET_LAZY_REGISTERING";
const TOGGLE_AGENCY_QUESTION = "pap/TOGGLE_AGENCY_QUESTION";
const TOGGLE_CONTRACT_PREVIEW = "pap/TOGGLE_CONTRACT_PREVIEW";
const TOGGLE_NDA_PREVIEW = "pap/TOGGLE_NDA_PREVIEW";
const TOGGLE_ELITE_CONFIRMATION = "pap/TOGGLE_ELITE_CONFIRMATION";
const TOGGLE_LOGIN = "pap/TOGGLE_LOGIN";
const TOGGLE_SELECT_PACKAGE_SUBCAT = "pap/TOGGLE_SELECT_PACKAGE_SUBCAT";
const TOGGLE_SELECT_LOGO_SUBCAT = "pap/TOGGLE_SELECT_LOGO_SUBCAT";

export const RESET_PAP = "pap/RESET";

/* ACTION CREATORS
 ================================================================================================ */

export function displayFullScreen(screenName, extraData) {
  return {
    type: DISPLAY_FULL_SCREEN,
    payload: {
      name: screenName,
      extraData: extraData,
    },
  };
}

export function hideFullScreen() {
  return {
    type: HIDE_FULL_SCREEN,
    payload: {},
  };
}

export function changeStep(newStepIndex) {
  return {
    type: CHANGE_STEP,
    payload: {
      newStepIndex: newStepIndex,
    },
  };
}

export function changeSection(newSectionName) {
  return {
    type: CHANGE_SECTION,
    payload: {
      newSectionName: newSectionName,
    },
  };
}

export function resetPAP() {
  return {
    type: RESET_PAP,
    payload: {},
  };
}

export function editReviewSection(sectionType, sectionData, value) {
  return {
    type: EDIT_REVIEW_SECTION,
    payload: {
      type: sectionType,
      data: sectionData,
      value: value,
    },
  };
}

export function cancelEditReviewSection() {
  return {
    type: CANCEL_EDIT_REVIEW_SECTION,
    payload: null,
  };
}

export function retrieveProjectData(projectData) {
  return {
    type: FETCH_PROJECT_DATA,
    payload: {
      projectData: projectData,
    },
  };
}

export function setLazyRegistering(value) {
  return {
    type: SET_LAZY_REGISTERING,
    payload: value,
  };
}

export function toggleAgencyQuestion(value) {
  return {
    type: TOGGLE_AGENCY_QUESTION,
    payload: value,
  };
}

export function toggleContractPreview() {
  return {
    type: TOGGLE_CONTRACT_PREVIEW,
  };
}

export function toggleNDAPreview(opts) {
  return {
    type: TOGGLE_NDA_PREVIEW,
    opts,
  };
}

export function toggleEliteConfirmation(onConfirm) {
  return {
    type: TOGGLE_ELITE_CONFIRMATION,
    payload: onConfirm,
  };
}

export function toggleLogin() {
  return {
    type: TOGGLE_LOGIN,
  };
}

export function toggleSelectPackageSubcat() {
  return {
    type: TOGGLE_SELECT_PACKAGE_SUBCAT,
  };
}

export const toggleSelectLogoSubcat = () => {
  return {
    type: TOGGLE_SELECT_LOGO_SUBCAT,
  };
};

/* INITIAL STATE
================================================================================================ */
const INITIAL_STATE = {
  sections: [
    { slug: "project-details", title: "Details" },
    { slug: "brief", title: "Brief" },
    { slug: "review", title: "Review" },
    { slug: "payment", title: "Payment" },
  ],
  fullScreen: null,
  editReviewSection: null,
  currentSection: null,
  currentStepIndex: 0,
  briefSteps: null,
  lazyRegistering: false,
  showingContractPreview: false,
  showingEliteConfirmation: false,
  showingLogin: false,
  showingSelectPackageSubcat: false,
  showingSelectLogoSubcat: false,
};

/*
 * Definitions of package options the user can customize, include the step where it
 * should be placed.
 */
export const PACKAGE_ITEMS = [
  /* Project Options step */
  {
    name: PLATINUM_ONLY_DESIGNERS,
    fieldName: "platinum_only",
    step: "project_options",
  },
  {
    name: PLATINUM_ONLY_CREATIVES,
    fieldName: "platinum_only",
    step: "project_options",
  },
  {
    name: PERSONAL_CONSULTANT,
    fieldName: "personal_consultant",
    step: "project_options",
  },
  {
    name: UNLIMITED_COLLABORATION,
    fieldName: "unlimited_collaboration",
    step: "project_options",
  },
  {
    name: DURATION_GROUP,
    fieldName: "duration_feature_id",
    step: "project_options",
    isGroup: true,
    getGroupDescription: (includedValue, features) => {
      const hasConsultantFeature = !!(features || []).find(
        (f) => f.feature.name === PERSONAL_CONSULTANT
      );

      return (
        <span>
          A standard project runs for {includedValue} days. Need it faster than{" "}
          {includedValue} days?
          <br />{" "}
          {hasConsultantFeature && (
            <span className="text-highlight">
              For best results in Express Projects, we recommend a Personal
              Project Consultant (see above).
            </span>
          )}
        </span>
      );
    },
    groupLabel: "Express Project",
    ignoreOnSettings: true,
  },
  {
    name: ONE_FOCUS_GROUP,
    fieldName: "one_focus_group",
    step: "project_options",
  },
  {
    name: UNLIMITED_FOCUS_GROUPS,
    fieldName: "unlimited_focus_groups",
    step: "project_options",
  },
  {
    name: UNLIMITED_PRESENTATIONS,
    fieldName: "unlimited_presentations",
    step: "project_options",
  },

  /* Promotion Options step */
  {
    name: PROMOTION_GROUP,
    fieldName: "promotion_feature_id",
    step: "promotion_options",
    isGroup: true,
  },

  /* Protection Options step */
  {
    name: NDAS,
    fieldName: "ndas",
    step: "protection_options",
    displayName: "Non-disclosure agreements (NDAs)",
    projectFields: [
      {
        name: "nda_auto_approve",
        defaultValue: true,
        type: "bool",
        label: "Auto approve NDA requests",
        description:
          "Signed requests to participate in the project will be automatically approved.",
        shouldShow: (feature) =>
          !!feature.provides.find((p) => p.code === NDAS_MANUAL_APPROVAL),
      },
      {
        name: "nda_portfolio_use_allowed",
        defaultValue: true,
        type: "bool",
        label: "Allow work re-use in creative's portfolio",
        description:
          "Creatives can re-use submitted entries in their portfolios.",
      },
      {
        name: "description",
        type: "brief_question",
        label: "Summarize your project (at least 50 characters)",
        description:
          "Please provide a 2-3 sentence summary of your project. This will be publicly visible, so don't share anything confidential.",
        minCharCount: 50,
        validate: (v) =>
          !v || v.length < 50
            ? "At least 50 characters are required."
            : undefined,
      },
    ],
    extraComponent: NDAPreviewButton,
  },
  { name: PRIVATE_PROJECT, fieldName: "private", step: "protection_options" },
  {
    name: SEARCH_EXCLUSION,
    fieldName: "search_exclusion",
    step: "protection_options",
  },
  {
    name: PRIVACY_BUNDLE,
    fieldName: "privacy_bundle",
    step: "protection_options",
    bundles: ["ndas", "private", "search_exclusion"],
    projectFields: [
      {
        name: "nda_auto_approve",
        defaultValue: true,
        type: "bool",
        label: "Auto approve NDA requests",
        description:
          "Signed requests to participate in the project will be automatically approved.",
        shouldShow: (feature) =>
          !!feature.provides.find((p) => p.code === NDAS_MANUAL_APPROVAL),
      },
      {
        name: "nda_portfolio_use_allowed",
        defaultValue: true,
        type: "bool",
        label: "Allow work re-use in creative's portfolio",
        description:
          "Creatives can re-use submitted entries in their portfolios.",
      },
      {
        name: "description",
        type: "brief_question",
        label: "Summarize your project (at least 50 characters)",
        description:
          "Please provide a 2-3 sentence summary of your project. This will be publicly visible, so don't share anything confidential.",
        minCharCount: 50,
        validate: (v) =>
          !v || v.length < 50
            ? "At least 50 characters are required."
            : undefined,
      },
    ],
  },

  /* Award Options step */
  {
    name: AWARD_ASSURED,
    fieldName: "assured",
    step: "award_options",
    disclaimer: "Selecting this option waives your right to request a refund.",
    ignoreOnSettings: true,
    recommended: true,
  },
  { fieldName: "included_awards", step: "award_options" },
  { fieldName: "extra_awards", step: "award_options" },
];

/*
 * Definitions of LEGACY package options.
 * These are used ONLY in the read-only features list presented for legacy projects
 * under settings.
 */
export const LEGACY_PACKAGE_ITEMS = [
  /* Project Options step */
  { name: LEGACY_PLATINUM_ONLY_DESIGNERS, step: "project_options" },
  { name: LEGACY_UNLIMITED_COLLABORATION, step: "project_options" },
  { name: LEGACY_DURATION, step: "project_options" },

  /* Promotion Options step */
  { name: LEGACY_STARTER_PROMOTION, step: "promotion_options" },
  { name: LEGACY_BASIC_PROMOTION, step: "promotion_options" },
  { name: LEGACY_ADVANCED_PROMOTION, step: "promotion_options" },
  { name: LEGACY_ULTIMATE_PROMOTION, step: "promotion_options" },
  { name: LEGACY_TWITTER_PROMOTION, step: "promotion_options" },

  /* Protection Options step */
  { name: LEGACY_NDAS, step: "promotion_options" },
  { name: LEGACY_SEARCH_EXCLUSION, step: "promotion_options" },
  { name: LEGACY_PRIVATE_GALLERY, step: "promotion_options" },
];

export const LAZY_REGISTRATION_STEP = {
  name: "create-account",
  label: "Save Your Progress",
  type: "lazy-registration",
  validations: ["lazyRegistration"],
  description:
    "We are about to ask questions to help create your project brief and because we value your time, we want to save your progress at each step.",
  help: "",
  showForAnonymous: "only",
  showFor1to1: "yes",
};

export const LAZY_REGISTRATION_STEP_BRIEF_ALL = {
  name: "create-account",
  label: "Enter your email and we'll automatically save your progress.",
  type: "lazy-registration",
  validations: ["lazyRegistration"],
  description: "We value your time and privacy.",
  help: "",
  showForAnonymous: "only",
  showFor1to1: "yes",
};

export const AGENCY_STEP = {
  name: "is_agency",
  label: "Are you a marketing or design agency?",
  type: "select",
  options: [
    { field: "yes", label: "Yes" },
    { field: "no", label: "No" },
  ],
  validations: ["required"],
  description: "",
  help: "",
};

/*
 * List of all possible steps to be shown during the "details" section.
 *
 * The following attributes control whether a step should be show for a particular app state:
 * - showForAnonymous: whether step should be presented to anonymous users
 * - showFor1to1: whether step should be presented for 1-to-1 projects
 * The values for these attributes can be either of:
 * - yes: always present step if condition is met
 * - no: never present step if condition is met
 * - only: only present step if condition is met
 */
export const ALL_DETAILS_STEPS = [
  {
    name: "package",
    label: ({ subCategory }) =>
      `Select a package for your ${
        idx(subCategory, (_) => _.name) || ""
      } project`,
    answer_label: "Package",
    type: "packages",
    validations: ["required"],
    help: null,
    showForAnonymous: "yes",
    showFor1to1: "no",
  },
  {
    name: "award_options",
    label: "Award options",
    type: "award_options",
    validations: ["awards"],
    description: "Adjust your award or add more awards.",
    help: null,
    showForAnonymous: "yes",
    showFor1to1: "no",
  },
  {
    name: "project_options",
    label: "Project options",
    type: "project_options",
    validations: [],
    description: "Customize your project with advanced tools and options.",
    help: null,
    showForAnonymous: "yes",
    showFor1to1: "yes",
  },
  {
    name: "promotion_options",
    label: "Promote your project",
    type: "promotion_options",
    validations: [],
    description: "Over-exposure? No such thing.",
    help: null,
    showForAnonymous: "yes",
    showFor1to1: "no",
  },
  {
    name: "protection_options",
    label: "Protect your project",
    type: "protection_options",
    validations: ["featureProjectFields"],
    description:
      "Does your project include sensitive information? Protect it with these options.",
    help: null,
    showForAnonymous: "yes",
    showFor1to1: "yes",
  },
  {
    name: "contract",
    label: "Choose your contract",
    type: "contract",
    validations: ["contract"],
    description:
      "The contract will be between you and the project winner and \
                    will give you full intellectual property rights to the work they create for you.",
    help: null,
    showForAnonymous: "yes",
    showFor1to1: "yes",
  },
  {
    name: "title",
    label: "Give your project a title",
    answer_label: "Project title",
    type: "text",
    value: "",
    validations: ["required", "projectTitle"],
    description:
      "The public title of your project is very important. It's the first thing creatives will see. And, a catchy title will attract more participants.",
    help: "",
    showForAnonymous: "yes",
    showFor1to1: "yes",
    extraHelp: (
      <div className="row">
        <div className="columns small-16 medium-5">
          Examples of good titles:
        </div>
        <div className="columns small-16 medium-11">
          <div>Custom logo design for a hip new bar in Los Angeles</div>
          <div>Create a unique logo for a hot data science startup</div>
          <div>
            Design a custom landing page for an award-winning HR company
          </div>
          <div>
            Create a custom illustration promoting migrants' rights as human
            rights
          </div>
        </div>
      </div>
    ),
  },
  {
    name: "budget",
    label: "Budget",
    answer_label: "Budget",
    type: "budget",
    validations: ["budget"],
    description: "",
    help: null,
    showForAnonymous: "yes",
    showFor1to1: "only",
  },
];

/* Review sections are made of 1 or more PAP steps. */
export const DETAILS_REVIEW_SECTIONS = [
  /* "title" step as listed above */
  {
    name: "title",
    type: "text",
    answer_label: "Project title",
    stepsNames: ["title"],
  },

  /* "cost" section that aggregates steps related to package options */
  {
    name: "cost",
    type: "cost",
    answer_label: "Package & Options",
    stepsNames: [
      "package",
      "budget",
      "award_options",
      "project_options",
      "promotion_options",
      "protection_options",
    ],
  },

  /* "contract" step as listed above */
  {
    name: "contract",
    type: "contract",
    answer_label: "Contract",
    stepsNames: ["contract"],
  },
];

/**
 * Details steps - we can figure out immediately - the only difference is if the user is logged-in
 * or not.
 *
 * Brief steps - we pull. We save the max step - if the max step is not defined or smaller
 * than current we redirect to the beginning.
 *

/* REDUCERS
================================================================================================ */
export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RESET_PAP:
      return { ...INITIAL_STATE };
    case CHANGE_STEP:
      return { ...state, currentStepIndex: action.payload.newStepIndex };
    case CHANGE_SECTION:
      if (
        state.currentSection === "brief" &&
        action.payload.newSectionName === "project-details"
      ) {
        return {
          ...state,
          currentSection: "project-details",
          currentStepIndex: 2,
        };
      } else {
        return {
          ...state,
          currentSection: action.payload.newSectionName,
          currentStepIndex: 0,
        };
      }
    case EDIT_REVIEW_SECTION:
      return { ...state, editReviewSection: action.payload };
    case CANCEL_EDIT_REVIEW_SECTION:
      return { ...state, editReviewSection: null };
    case DISPLAY_FULL_SCREEN:
      return { ...state, fullScreen: action.payload };
    case HIDE_FULL_SCREEN:
      return { ...state, fullScreen: null };
    case SET_LAZY_REGISTERING:
      return { ...state, lazyRegistering: action.payload };
    case TOGGLE_AGENCY_QUESTION:
      return { ...state, showingAgencyQuestion: action.payload };
    case TOGGLE_CONTRACT_PREVIEW:
      return {
        ...state,
        showingContractPreview: !state.showingContractPreview,
      };
    case TOGGLE_NDA_PREVIEW:
      return {
        ...state,
        showingNDAPreview: !state.showingNDAPreview,
        ndaPreviewOptions: action.opts,
      };
    case TOGGLE_ELITE_CONFIRMATION:
      return {
        ...state,
        showingEliteConfirmation: !state.showingEliteConfirmation,
        onEliteConfirm: action.payload,
      };
    case TOGGLE_LOGIN:
      return { ...state, showingLogin: !state.showingLogin };
    case TOGGLE_SELECT_PACKAGE_SUBCAT:
      return {
        ...state,
        showingSelectPackageSubcat: !state.showingSelectPackageSubcat,
      };
    case TOGGLE_SELECT_LOGO_SUBCAT:
      return {
        ...state,
        showingSelectLogoSubcat: !state.showingSelectLogoSubcat,
      };
    default:
      return state;
  }
}
