import React, { Component } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import { getQueryFromValue } from "common/searcher/utils";
import { getQueryFromValue as getPagerQueryFromValue } from "common/pager/utils";
import Form from "common/searcher/components/form";

/**
 * Re-usable searcher component.
 * The search value is always read/updated from/to the URL query parameters,
 * but updates happen only the form is submitted.
 *
 * @author Nathan Ozelim <nathan@startupfoundry.com>
 */
export default class Searcher extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    /* save initial search query */
    const { resourceName, saveLastQuery, initialValue } = this.props;
    const searcherQuery = getQueryFromValue(resourceName, initialValue);
    saveLastQuery(resourceName, searcherQuery);
  }

  componentDidUpdate(prevProps) {
    const { resourceName, saveLastQuery, initialValue } = this.props;

    /* save query when parameters are updated */
    if (!isEqual(prevProps.initialValue, initialValue)) {
      const searcherQuery = getQueryFromValue(resourceName, initialValue);
      saveLastQuery(resourceName, searcherQuery);
    }
  }

  handleSubmit(formData) {
    const value = formData.search;
    const { router, resourceName, pathname } = this.props;
    const currentLocation = router.getCurrentLocation();
    const searcherQuery = getQueryFromValue(resourceName, value);
    /* clear page when updating search */
    const pagerQuery = getPagerQueryFromValue(resourceName, "");
    /* if a `pathname` is provided, clear query except search part */
    const updatedQuery = pathname
      ? searcherQuery
      : {
          ...currentLocation.query,
          ...searcherQuery,
          ...pagerQuery,
        };

    /* update URL with new query parameters */
    router.push({
      ...currentLocation,
      pathname: pathname || currentLocation.pathname,
      query: updatedQuery,
    });
  }

  render() {
    const { resourceName, initialValue, label } = this.props;

    return (
      <div className="searcher">
        <Form
          form={`${resourceName}_search_form`}
          initialValues={{ search: initialValue }}
          onSubmit={this.handleSubmit}
          label={label}
        />
      </div>
    );
  }
}

Searcher.propTypes = {
  initialValue: PropTypes.string,
  label: PropTypes.string,
  router: PropTypes.object.isRequired,
  resourceName: PropTypes.string.isRequired,
  saveLastQuery: PropTypes.func.isRequired,
  pathname: PropTypes.string,
};
