import React from "react";
import PropTypes from "prop-types";
import Tooltip from "react-tooltip";
import classnames from "classnames";
import { isMobile } from "common/utils";

const DESCRIPTIONS = {
  "give-back":
    "Give Back is our way of helping worthy non-profit and charitable causes when those organizations need design help but cannot afford it. Both crowdspring and all participating creatives waive their fees.",
  featured:
    "The client in this project purchased an optional promotion package which makes their project more visible to the crowdspring community and typically attracts more participants and entries.",
  elite:
    "Elite projects allow clients to work with 5 of the very best designers on crowdspring (those with a reputation of 90 and above).",
  platinum:
    "This project is only for our top level creatives (reputation of 80 to 100). Clients in Platinum projects receive premium entries from our best creatives",
  "nda-required":
    "When a client requires an non-disclosure agreement (NDA) in a project, creatives will have access to a limited public project description created by the client until they sign the NDA. After signing the NDA, they will gain access to the full project brief and be permitted to submit entries.",
  blind:
    "Some clients want to protect the privacy of their new product or service. We offer them the option of making their project galleries completely private to hide the entries from prying eyes.",
  hidden:
    "We sometimes have to put a project down for a nap (hide it) when we have to clarify something with the client.",
  assured:
    "By assuring their project, a client waives their right to a refund and promises to award a winner.",
  "fast-tracked":
    "Fast-tracked projects last 1, 2, or 3 days, not including the optional Finalist Round.",
};

const ProjectTags = ({ project, className, showColors, showTooltips }) => {
  const isBlind = project.private_gallery;
  const isNDA = project.nda_required;
  const isPlatinum = project.platinum_only;
  const isElite = project.elite_only;
  const getTooltipId = (name) => `tooltip-${name}-${project.id}`;
  const mobile = isMobile();

  const tagClasses = showColors
    ? "tag tag-sm tag-highlight"
    : "tag tag-sm tag-default";

  return (
    <div className={className}>
      {project.give_back && (
        <span
          className={classnames(tagClasses, "give-back")}
          data-tip={true}
          data-for={getTooltipId("give-back")}
          data-event={mobile ? "click" : undefined}
        >
          Give Back
        </span>
      )}

      {project.featured && (
        <span
          className={classnames(tagClasses, "featured")}
          data-tip={true}
          data-for={getTooltipId("featured")}
          data-event={mobile ? "click" : undefined}
        >
          Featured
        </span>
      )}

      {isElite && (
        <span
          className={classnames(tagClasses, "elite")}
          data-tip={true}
          data-for={getTooltipId("elite")}
          data-event={mobile ? "click" : undefined}
        >
          Elite
        </span>
      )}

      {isPlatinum && (
        <span
          className={classnames(tagClasses, "platinum")}
          data-tip={true}
          data-for={getTooltipId("platinum")}
          data-event={mobile ? "click" : undefined}
        >
          Platinum
        </span>
      )}

      {isNDA && (
        <span
          className={classnames(tagClasses, "nda")}
          data-tip={true}
          data-for={getTooltipId("nda-required")}
          data-event={mobile ? "click" : undefined}
        >
          <i className="fa fa-lock" /> NDA
        </span>
      )}

      {isBlind && (
        <span
          className={classnames(tagClasses, "blind")}
          data-tip={true}
          data-for={getTooltipId("blind")}
          data-event={mobile ? "click" : undefined}
        >
          <i className="fa fa-eye-slash" /> Blind
        </span>
      )}

      {!project.visible && (
        <span
          className={classnames(tagClasses, "hidden")}
          data-tip={true}
          data-for={getTooltipId("hidden")}
          data-event={mobile ? "click" : undefined}
        >
          <i className="fa fa-eye-slash" /> Hidden
        </span>
      )}

      {project.assured && (
        <span
          className={classnames(tagClasses, "assured")}
          data-tip={true}
          data-for={getTooltipId("assured")}
          data-event={mobile ? "click" : undefined}
        >
          Assured
        </span>
      )}

      {project.fast_tracked && (
        <span
          className={classnames(tagClasses, "fast-tracked")}
          data-tip={true}
          data-for={getTooltipId("fast-tracked")}
          data-event={mobile ? "click" : undefined}
        >
          Fast-tracked
        </span>
      )}

      {showTooltips &&
        Object.keys(DESCRIPTIONS).map((name) => (
          <Tooltip
            key={name}
            class="tooltip"
            effect="solid"
            place="bottom"
            id={getTooltipId(name)}
          >
            {DESCRIPTIONS[name]}
          </Tooltip>
        ))}
    </div>
  );
};

ProjectTags.defaultProps = {
  showColors: true,
};

ProjectTags.propTypes = {
  project: PropTypes.object.isRequired,
  className: PropTypes.string,
  showColors: PropTypes.bool,
  showTooltips: PropTypes.bool,
};

export default ProjectTags;
