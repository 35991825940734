import { connect } from "react-redux";
import EnablePayoneerHeader from "account/components/payout/enable_payoneer_header";

const mapStateToProps = ({ payout }) => {
  return {
    enabled: payout.payoneerEnabled,
  };
};

export default connect(mapStateToProps)(EnablePayoneerHeader);
