import React, { Component } from "react";
import PropTypes from "prop-types";

export default class Checkbox extends Component {
  constructor(props) {
    super(props);
    this.changed = this.changed.bind(this);
    this.clicked = this.clicked.bind(this);
  }

  clicked(e) {
    e.stopPropagation();
  }

  changed(e) {
    this.props.onChange();
  }

  render() {
    const { selected, notificationId } = this.props;
    return (
      <div
        className="checkbox notification-checkbox hide-for-small-only"
        onClick={this.clicked}
      >
        <input
          id={`checkbox-${notificationId}`}
          type="checkbox"
          onChange={this.changed}
          checked={selected}
        />
        <label htmlFor={`checkbox-${notificationId}`} className="form-label" />
      </div>
    );
  }
}

Checkbox.propTypes = {
  selected: PropTypes.bool,
  notificationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
};
