import request from "axios";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";
import moment from "moment";

/* ACTIONS
================================================================================================ */
const FETCH_MESSAGES = "crowdspring/message/FETCH";
const FETCH_MESSAGES_REQUEST = "crowdspring/message/FETCH_REQUEST";
const FETCH_MESSAGES_SUCCESS = "crowdspring/message/FETCH_SUCCESS";
const FETCH_MESSAGES_FAILURE = "crowdspring/message/FETCH_FAILURE";

/* HELPERS
================================================================================================ */

/* Create a new message */
export const createMessageRequest = (
  streamId,
  data,
  wrapup,
  project,
  negotiation
) => {
  let reqData, resource;

  if (wrapup) {
    reqData = { ...data, wrapup: wrapup.award.id };
    resource = "wrapup";
  } else if (negotiation) {
    reqData = { ...data, negotiation: negotiation.id };
    resource = "negotiation";
  } else {
    reqData = { ...data, project: project.id };
    resource = "project";
  }

  return request.post(
    `/api/v1/messages/${streamId}/create_${resource}_message/`,
    reqData
  );
};

/* Group messages that by thread */
const groupByThread = (messages) => {
  const threads = [];
  const msgThreadMap = {};

  /* go through the messages in reverse order (older first) so that threads can be built */
  reverse(messages).map((msg) => {
    /* message will belong to same thread as the one it's replying to */
    let threadIdx = msgThreadMap[msg["reply_to"]];

    /* if there is no reply-to message or it was deleted, `threadIdx` won't be defined,
       so just add a new thread to the list */
    if (threadIdx === undefined) {
      threads.push([]);
      threadIdx = threads.length - 1;
    }

    msgThreadMap[msg.id] = threadIdx;

    /* newer messages must come last in the thread */
    threads[threadIdx] = threads[threadIdx].concat([msg]);
  });

  /* return sorted threads such that threads with most recent updates come first */
  return sortBy(threads, (t) => -moment(t.slice(-1)[0]["created_at"]).toDate());
};

/* REDUCERS
 ================================================================================================ */
const initialState = {
  streams: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_MESSAGES_REQUEST:
      return {
        ...state,
        streams: {
          ...state.streams,
          [action.streamId]: {
            fetching: true,
          },
        },
      };
    case FETCH_MESSAGES_FAILURE:
      return {
        ...state,
        streams: {
          ...state.streams,
          [action.streamId]: {
            fetching: false,
            threads: [],
          },
        },
      };
    case FETCH_MESSAGES_SUCCESS:
      return {
        ...state,
        streams: {
          ...state.streams,
          [action.streamId]: {
            fetching: false,
            threads: groupByThread(action.payload.messages),
          },
        },
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const fetchMessages =
  ({ streamId, project, wrapup, wrapupStep, negotiation }) =>
  (dispatch) => {
    let url;

    if (wrapup) {
      url = `/api/v1/wrapups/${wrapup.award.id}/messages/?step=${wrapupStep}`;
    } else if (negotiation) {
      url = `/api/v1/projects/${negotiation.project}/one_to_one_negotiations/${negotiation.id}/messages/`;
    } else if (project) {
      url = `/api/v1/projects/${project.id}/messages/`;
    }

    if (!url) {
      console.warn("No valid object provided to fetchMessages()");
      return Promise.resolve();
    }

    return dispatch({
      type: FETCH_MESSAGES,
      promise: request.get(url),
      streamId,
    });
  };

export const updateMessage =
  ({ streamId, id, data, wrapup, wrapupStep, negotiation }) =>
  (dispatch) => {
    const promise = request.patch("/api/v1/messages/edit_message/", {
      message: id,
      ...data,
    });

    promise.then(() => {
      dispatch(fetchMessages({ wrapup, wrapupStep, streamId, negotiation }));
    });

    return promise;
  };

export const hideMessage =
  ({ streamId, id, wrapup, wrapupStep, negotiation }) =>
  (dispatch) => {
    const promise = request.patch("/api/v1/messages/hide_message/", {
      message: id,
    });

    promise.then(() =>
      dispatch(fetchMessages({ wrapup, wrapupStep, streamId, negotiation }))
    );

    return promise;
  };

export const unhideMessage =
  ({ streamId, id, wrapup, wrapupStep, negotiation }) =>
  (dispatch) => {
    const promise = request.patch("/api/v1/messages/unhide_message/", {
      message: id,
    });

    promise.then(() =>
      dispatch(fetchMessages({ wrapup, wrapupStep, streamId, negotiation }))
    );

    return promise;
  };
