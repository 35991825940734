import request from "axios";
import pick from "lodash/pick";
import idx from "idx";
import uniqBy from "lodash/uniqBy";
import fileDownload from "js-file-download";
import { browserHistory } from "react-router";
import { showBanner, TYPE_SUCCESS } from "common/ducks/banner";
import { setBannerError } from "error";
import {
  CONTACT_LISTS_RESOURCE_NAME,
  CONTACTS_RESOURCE_NAME,
} from "email_automations/constants";
import { getAPIQueryString } from "common/utils";
import { savePageCount } from "common/pager/ducks";

/* ACTIONS
================================================================================================ */
const FETCH_LISTS = "contacts/FETCH_LISTS";
const FETCH_LISTS_REQUEST = "contacts/FETCH_LISTS_REQUEST";
const FETCH_LISTS_SUCCESS = "contacts/FETCH_LISTS_SUCCESS";

const FETCH_ALL_LISTS = "contacts/FETCH_ALL_LISTS";
const FETCH_ALL_LISTS_REQUEST = "contacts/FETCH_ALL_LISTS_REQUEST";
const FETCH_ALL_LISTS_SUCCESS = "contacts/FETCH_ALL_LISTS_SUCCESS";

const FETCH_LIST = "contacts/FETCH_LIST";
const FETCH_LIST_REQUEST = "contacts/FETCH_LIST_REQUEST";
const FETCH_LIST_SUCCESS = "contacts/FETCH_LIST_SUCCESS";

const SAVE_LIST = "contacts/SAVE_LIST";
const SAVE_LIST_SUCCESS = "contacts/SAVE_LIST_SUCCESS";

const FETCH_CONTACTS = "contacts/FETCH_CONTACTS";
const FETCH_CONTACTS_REQUEST = "contacts/FETCH_CONTACTS_REQUEST";
const FETCH_CONTACTS_SUCCESS = "contacts/FETCH_CONTACTS_SUCCESS";

const FETCH_CONTACT = "contacts/FETCH_CONTACT";
const FETCH_CONTACT_REQUEST = "contacts/FETCH_CONTACT_REQUEST";
const FETCH_CONTACT_SUCCESS = "contacts/FETCH_CONTACT_SUCCESS";

const SAVE_CONTACT = "contacts/SAVE_CONTACT";
const SAVE_CONTACT_SUCCESS = "contacts/SAVE_CONTACT_SUCCESS";

const SET_SELECTED_CONTACTS = "contacts/SET_SELECTED_CONTACTS";

/* HELPERS
================================================================================================ */
export const createListRequest = () => request.post("/api/v1/contact_lists/");

export const uploadContactsRequest = (listId, data) => {
  return request.post(`/api/v1/contact_lists/${listId}/contacts/upload_csv/`, {
    csv_string: data.file,
  });
};

export const createContactRequest = (listId) => {
  return request.post(`/api/v1/contact_lists/${listId}/contacts/`, {
    list: listId,
    /* generate a temporary, unique email */
    email: `${new Date().getTime()}@example.com`,
  });
};

export const exportContactsRequest = (listId) => {
  const promise = request.post(
    `/api/v1/contact_lists/${listId}/contacts/export_csv/`
  );

  promise.then((res) => {
    const filename = res.headers["content-disposition"]
      .split("attachment; filename=")[1]
      .replaceAll("'", "");
    fileDownload(res.data, filename);
  });

  return promise;
};

/* INITIAL STATES
================================================================================================ */
const initialState = {
  lists: null,
  listCount: 0,
  allLists: null,
  list: null,
  contacts: null,
  contactCount: 0,
  contact: null,
  selectedContacts: [],
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_LISTS_REQUEST:
      return {
        ...state,
        lists: null,
      };
    case FETCH_LISTS_SUCCESS:
      return {
        ...state,
        lists: action.payload.results,
        listCount: action.payload.count,
      };
    case FETCH_ALL_LISTS_REQUEST:
      return {
        ...state,
        allLists: null,
      };
    case FETCH_ALL_LISTS_SUCCESS:
      return {
        ...state,
        allLists: action.payload,
      };
    case FETCH_LIST_REQUEST:
      return {
        ...state,
        list: null,
      };
    case FETCH_LIST_SUCCESS:
    case SAVE_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload,
      };
    case FETCH_CONTACTS_REQUEST:
      return {
        ...state,
        contacts: null,
      };
    case FETCH_CONTACTS_SUCCESS:
      return {
        ...state,
        contacts: action.payload.results,
        contactCount: action.payload.count,
      };
    case FETCH_CONTACT_REQUEST:
      return {
        ...state,
        contact: null,
      };
    case FETCH_CONTACT_SUCCESS:
    case SAVE_CONTACT_SUCCESS:
      return {
        ...state,
        contact: action.payload,
      };
    case SET_SELECTED_CONTACTS:
      return {
        ...state,
        selectedContacts: uniqBy(action.payload, "id"),
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const fetchLists = (query) => (dispatch) => {
  const queryStr = getAPIQueryString(CONTACT_LISTS_RESOURCE_NAME, query);
  const promise = request.get(`/api/v1/contact_lists/?${queryStr}`);

  /* save page count in pager state */
  promise.then((res) =>
    dispatch(savePageCount(CONTACT_LISTS_RESOURCE_NAME, res))
  );

  return dispatch({
    type: FETCH_LISTS,
    promise,
  });
};

export const fetchAllLists = () => (dispatch) => {
  return dispatch({
    type: FETCH_ALL_LISTS,
    promise: request.get("/api/v1/contact_lists/all/"),
  });
};

export const fetchList = (id) => (dispatch) => {
  const promise = request.get(`/api/v1/contact_lists/${id}/`);

  promise.catch((err) => {
    if (err.status === 404) {
      browserHistory.replace("/404/");
    }
  });

  dispatch({
    type: FETCH_LIST,
    promise,
  });

  return promise;
};

export const saveList = (id, formData) => (dispatch) => {
  const data = {
    ...pick(
      formData,
      "archived",
      "confirm_subscription",
      "important",
      "name",
      "send_welcome_email",
      "subscription_confirmation_from_email",
      "subscription_confirmation_from_name",
      "subscription_confirmation_reply_to",
      "subscription_confirmation_subject",
      "subscription_confirmation_text_content",
      "welcome_subject",
      "welcome_from_email",
      "welcome_from_name",
      "welcome_reply_to",
      "welcome_text_content"
    ),
    subscription_confirmation_html_content:
      idx(formData, (_) => _.subscription_confirmation_html_data.html) || "",
    subscription_confirmation_html_design:
      idx(formData, (_) => _.subscription_confirmation_html_data.design) || "",
    welcome_html_content: idx(formData, (_) => _.welcome_html_data.html) || "",
    welcome_html_design: idx(formData, (_) => _.welcome_html_data.design) || "",
  };

  const promise = request.patch(`/api/v1/contact_lists/${id}/`, data);

  promise
    .then(() => {
      dispatch(
        showBanner(
          "Success",
          "Contact list successfully saved.",
          undefined,
          TYPE_SUCCESS,
          null,
          3000
        )
      );
    })
    .catch((err) => {
      const errorMsg = err.data
        ? JSON.stringify(err.data)
        : "Make sure the data you entered is correct.";
      dispatch(setBannerError("Error saving contact list", errorMsg));
    });

  dispatch({
    type: SAVE_LIST,
    promise,
  });

  return promise;
};

export const deleteList = (id) => (dispatch) => {
  const promise = request.delete(`/api/v1/contact_lists/${id}/`);

  promise.catch((err) => {
    const errorMsg = err.data ? JSON.stringify(err.data) : "Unkown error.";
    dispatch(setBannerError("Error deleting contact list", errorMsg));
  });

  return promise;
};

export const fetchContacts = (listId, query) => (dispatch) => {
  const queryStr = getAPIQueryString(CONTACTS_RESOURCE_NAME, query);
  const promise = request.get(
    `/api/v1/contact_lists/${listId || "all"}/contacts/?${queryStr}`
  );

  /* save page count in pager state */
  promise.then((res) => dispatch(savePageCount(CONTACTS_RESOURCE_NAME, res)));

  return dispatch({
    type: FETCH_CONTACTS,
    promise,
  });
};

export const fetchContact = (listId, id) => (dispatch) => {
  return dispatch({
    type: FETCH_CONTACT,
    promise: request.get(`/api/v1/contact_lists/${listId}/contacts/${id}/`),
  });
};

export const saveContact = (listId, id, formData) => (dispatch) => {
  const data = {
    ...formData,
    extra_context: JSON.parse(formData.extra_context),
  };
  const promise = request.patch(
    `/api/v1/contact_lists/${listId}/contacts/${id}/`,
    data
  );

  promise
    .then(() => {
      dispatch(
        showBanner(
          "Success",
          "Contact successfully saved.",
          undefined,
          TYPE_SUCCESS,
          null,
          3000
        )
      );
    })
    .catch((err) => {
      const errorMsg = err.data
        ? JSON.stringify(err.data)
        : "Make sure the data you entered is correct.";
      dispatch(setBannerError("Error saving contact", errorMsg));
    });

  dispatch({
    type: SAVE_CONTACT,
    promise,
  });

  return promise;
};

export const deleteContact = (listId, id) => (dispatch) => {
  const promise = request.delete(
    `/api/v1/contact_lists/${listId}/contacts/${id}/`
  );

  promise.catch((err) => {
    const errorMsg = err.data ? JSON.stringify(err.data) : "Unkown error.";
    dispatch(setBannerError("Error deleting contact", errorMsg));
  });

  return promise;
};

export const setSelectedContacts = (payload) => (dispatch) => {
  return dispatch({
    type: SET_SELECTED_CONTACTS,
    payload,
  });
};

export const performContactBulkAction =
  (listId, action, data, onSuccess) => (dispatch, getState) => {
    const { selectedContacts } = getState().emailAutomations.contacts;

    const promise = Promise.all(
      selectedContacts.map((c) =>
        dispatch(deleteContact(listId || "all", c.id))
      )
    );

    promise.then(() => {
      dispatch(setSelectedContacts([]));
      dispatch(onSuccess());
    });

    return promise;
  };
