import request from "axios";
import axios from "axios";
import pick from "lodash/pick";
import omit from "lodash/omit";
import { scrollToPageTop } from "common/utils";

/* ACTIONS
 ================================================================================================ */
const GO_TO_SUBSTEP = "creative_registration/GO_TO_SUBSTEP";

const UPLOAD_ID_PHOTO = "creative_registration/UPLOAD_ID_PHOTO";
const UPLOAD_ID_PHOTO_REQUEST = "creative_registration/UPLOAD_ID_PHOTO_REQUEST";
const UPLOAD_ID_PHOTO_SUCCESS = "creative_registration/UPLOAD_ID_PHOTO_SUCCESS";
const UPLOAD_ID_PHOTO_FAILURE = "creative_registration/UPLOAD_ID_PHOTO_FAILURE";

const FETCH_ID_PHOTO = "creative_registration/FETCH_ID_PHOTO";
const FETCH_ID_PHOTO_REQUEST = "creative_registration/FETCH_ID_PHOTO_REQUEST";
const FETCH_ID_PHOTO_SUCCESS = "creative_registration/FETCH_ID_PHOTO_SUCCESS";
const FETCH_ID_PHOTO_FAILURE = "creative_registration/FETCH_ID_PHOTO_FAILURE";

const UPLOAD_IMAGE_SAMPLES = "creative_registration/UPLOAD_IMAGE_SAMPLES";
const UPLOAD_IMAGE_SAMPLES_REQUEST =
  "creative_registration/UPLOAD_IMAGE_SAMPLES_REQUEST";
const UPLOAD_IMAGE_SAMPLES_SUCCESS =
  "creative_registration/UPLOAD_IMAGE_SAMPLES_SUCCESS";
const UPLOAD_IMAGE_SAMPLES_FAILURE =
  "creative_registration/UPLOAD_IMAGE_SAMPLES_FAILURE";

const FETCH_IMAGE_SAMPLES = "creative_registration/FETCH_IMAGE_SAMPLES";
const FETCH_IMAGE_SAMPLES_SUCCESS =
  "creative_registration/FETCH_IMAGE_SAMPLES_SUCCESS";

const FETCH_WRITING_SAMPLES = "creative_registration/FETCH_WRITING_SAMPLES";
const FETCH_WRITING_SAMPLES_SUCCESS =
  "creative_registration/FETCH_WRITING_SAMPLES_SUCCESS";

const UPDATE_ID_PHOTO_LOCAL = "creative_registration/UPDATE_ID_PHOTO_LOCAL";

const REENABLE_ID_PHOTO_UPLOAD =
  "creative_registration/REENABLE_ID_PHOTO_UPLOAD";

const UPDATE_SAMPLES_LOCAL = "creative_registration/UPDATE_SAMPLES_LOCAL";

const DELETE_WORK_SAMPLES = "creative_registration/DELETE_WORK_SAMPLES";
const DELETE_WORK_SAMPLES_SUCCESS =
  "creative_registration/DELETE_WORK_SAMPLES_SUCCESS";

const UPDATE_AVATAR_LOCAL = "creative_registration/UPDATE_AVATAR_LOCAL";

const FETCH_SAMPLE_GROUPS = "creative_registration/FETCH_SAMPLE_GROUPS";
const FETCH_SAMPLE_GROUPS_SUCCESS =
  "creative_registration/FETCH_SAMPLE_GROUPS_SUCCESS";

const SAVE_INVITE_HASH = "creative_registration/SAVE_INVITE_HASH";

const SET_VALIDATING_ID = "creative_registration/SET_VALIDATING_ID";

/* INITIAL STATE
 ================================================================================================ */
const initialState = {
  substep: "settings",
  reviewing: false,
  workSamples: {},
  workSamplesMeta: {},
  avatar: "",
  idPhoto: null,
  selfie: null,
  idVerificationSubmmitedAt: null,
  idPhotoApproved: null,
  selfieApproved: null,
  sampleGroups: [],
  validatingId: false,
};

/* HELPERS
 ================================================================================================ */
const SAMPLE_FIELDS = [
  "sample_1",
  "sample_2",
  "sample_3",
  "favorite_company_name",
  "favorite_tagline",
  "naming_experience",
  "naming_process",
  "why_crowdspring",
];

export const submitWritingSamplesRequest = (userId, formData) =>
  request.post(`/api/v1/users/${userId}/submit_writing_samples/`, formData);

export const submitRegistration = (userId) =>
  request.post(`/api/v1/users/${userId}/submit_registration/`);

export const reopenRegistrationRequest = (userId) =>
  request.post(`/api/v1/users/${userId}/reopen_registration/`);

export const fetchIdValidationUrlRequest = (userId) =>
  request.get(`/api/v1/users/${userId}/id_validation_url/`);

/* Format image/writing/etc samples to the format used in the store */
const formatSamples = (payload, hideRejected) => {
  return payload.reduce(
    (acc, s) => ({
      ...acc,
      [s.subcategory_group_id]:
        hideRejected && (s.approved === false || s.resubmission_requested)
          ? {}
          : pick(s, SAMPLE_FIELDS),
    }),
    {}
  );
};

/* Format image/writing/etc samples metadata to the format used in the store */
const formatSamplesMeta = (payload) => {
  return payload.reduce(
    (acc, s) => ({
      ...acc,
      [s.subcategory_group_id]: omit(s, SAMPLE_FIELDS),
    }),
    {}
  );
};

/* REDUCERS
 ================================================================================================ */
export default function reducer(state = initialState, action) {
  let data, substep, group, content;

  switch (action.type) {
    case GO_TO_SUBSTEP:
      substep = action.payload;
      return {
        ...state,
        substep,
        /* once user gets to the review step, `reviewing` will always be true */
        reviewing: state.reviewing || substep == "review",
      };
    case UPDATE_ID_PHOTO_LOCAL:
      return {
        ...state,
        idPhoto: action.data.idPhoto || state.idPhoto,
        selfie: action.data.selfie || state.selfie,
      };
    case UPDATE_SAMPLES_LOCAL:
      ({ group, content } = action.data);

      return {
        ...state,
        workSamples: {
          ...state.workSamples,
          [group]: content,
        },
      };
    case FETCH_ID_PHOTO_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case FETCH_ID_PHOTO_SUCCESS:
      data = action.payload;

      return {
        ...state,
        idVerificationStatus: data.status,
        idVerificationSubmmitedAt: data.updated_at,
        registrationSubmittedAt: data.date_last_submitted,
        registrationReopenedAt: data.reopened_at,
        idValidated: data.id_validated,
        idValidationRejectionNote: data.id_validation_rejection_note,
        idPhoto: data.photo_id,
        idPhotoApproved: data.photo_id_approved,
        selfie: data.photograph,
        selfieApproved: data.photograph_approved,
        fetching: false,
      };
    case FETCH_ID_PHOTO_FAILURE:
      /* no need to set a specific error field here, since the absence of photo ID
         verification information will be considered an error */
      return {
        ...state,
        fetching: false,
      };
    case UPLOAD_ID_PHOTO_REQUEST:
      return {
        ...state,
        uploadingId: true,
        error: null,
      };
    case UPLOAD_ID_PHOTO_SUCCESS:
      return {
        ...state,
        idUploaded: true,
        uploadingId: false,
        error: null,
      };
    case UPLOAD_ID_PHOTO_FAILURE:
      return {
        ...state,
        uploadingId: false,
        error: "Failed to upload photo. Please try again.",
      };
    case REENABLE_ID_PHOTO_UPLOAD:
      return {
        ...state,
        idUploaded: false,
      };
    case UPLOAD_IMAGE_SAMPLES_REQUEST:
      return {
        ...state,
        uploadingSamples: true,
        error: null,
      };
    case UPLOAD_IMAGE_SAMPLES_SUCCESS:
      ({ group, content } = action.data);
      return {
        ...state,
        samplesUploaded: true,
        uploadingSamples: false,
        error: null,
        workSamples: {
          ...state.workSamples,
          [group]: content,
        },
      };
    case UPLOAD_IMAGE_SAMPLES_FAILURE:
      return {
        ...state,
        uploadingSamples: false,
        error: "Failed to upload samples. Please try again.",
      };
    case DELETE_WORK_SAMPLES_SUCCESS:
      ({ group } = action.data);
      return {
        ...state,
        workSamples: {
          ...state.workSamples,
          [group]: {},
        },
      };
    case FETCH_IMAGE_SAMPLES_SUCCESS:
      return {
        ...state,
        workSamples: {
          ...state.workSamples,
          ...formatSamples(action.payload, action.hideRejected),
        },
        workSamplesMeta: {
          ...state.workSamplesMeta,
          ...formatSamplesMeta(action.payload),
        },
      };
    case FETCH_WRITING_SAMPLES_SUCCESS:
      return {
        ...state,
        workSamples: {
          ...state.workSamples,
          ...formatSamples(action.payload, action.hideRejected),
        },
        workSamplesMeta: {
          ...state.workSamplesMeta,
          ...formatSamplesMeta(action.payload),
        },
      };
    case UPDATE_AVATAR_LOCAL:
      return {
        ...state,
        avatar: action.data,
      };
    case SET_VALIDATING_ID:
      return {
        ...state,
        substep: "verify",
        validatingId: action.value,
      };
    case FETCH_SAMPLE_GROUPS_SUCCESS:
      return {
        ...state,
        /* exclude 1-to-1 sub-categories */
        sampleGroups: action.payload.map((g) => ({
          ...g,
          subcategories: g.subcategories.filter(
            (s) => !s.name.startsWith("1-to-1")
          ),
        })),
      };
    case SAVE_INVITE_HASH:
      return {
        ...state,
        inviteHash: action.hash,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const goToSubstep = (id) => (dispatch) => {
  scrollToPageTop();

  return dispatch({ type: GO_TO_SUBSTEP, payload: id });
};

/* Upload ID photo and selfie. */
export const uploadIdPhoto = () => (dispatch, getState) => {
  const user = getState().user;
  const { selfie, idPhoto } = getState().creativeRegistration;
  const userId = user.profile_data.id;

  /* if base64 images are provided, format them according to the format expected in the server,
     otherwise set it to the empty string (i.e. image hasn't changed) */
  const data = {
    photograph: selfie.startsWith("data") ? selfie.split(",").pop() : "",
    photo_id: idPhoto.startsWith("data") ? idPhoto.split(",").pop() : "",
  };

  return dispatch({
    type: UPLOAD_ID_PHOTO,
    promise: request.post(
      `/api/v1/users/${userId}/submit_id_validation/`,
      data
    ),
  });
};

/* Update ID photo and selfie locally */
export const updateIdPhotoLocal = (idPhoto, selfie) => (dispatch) =>
  dispatch({
    type: UPDATE_ID_PHOTO_LOCAL,
    data: { idPhoto, selfie },
  });

/* Allow ID photo and selfie to be uploaded again */
export const reenableIdPhotoUpload = () => (dispatch) =>
  dispatch({
    type: REENABLE_ID_PHOTO_UPLOAD,
  });

/* Update group work samples locally */
export const updateWorkSamplesLocal = (group, content) => (dispatch) =>
  dispatch({
    type: UPDATE_SAMPLES_LOCAL,
    data: { group, content },
  });

/* Upload image work samples for group. */
export const uploadImageSamples =
  (group, samples, onSuccess) => (dispatch, getState) => {
    const user = getState().user;
    const userId = user.profile_data.id;
    let data = {
      subcategory_group_id: group.id,
    };

    /* if base64 images are provided, format them according to the format expected in the server,
     otherwise set it to the empty string (i.e. image hasn't changed) */
    Object.keys(samples).map((k) => {
      data[k] =
        samples[k].startsWith("/media") || samples[k].startsWith("http")
          ? ""
          : samples[k].split(",").pop();
    });

    const promise = request.post(
      `/api/v1/users/${userId}/submit_image_samples/`,
      data
    );

    promise.then(onSuccess);

    return dispatch({
      type: UPLOAD_IMAGE_SAMPLES,
      data: { group: group.id, content: samples },
      promise,
    });
  };

/* Delete image work samples for group. */
export const deleteImageSamples = (group) => (dispatch, getState) => {
  const user = getState().user;
  const userId = user.profile_data.id;

  const promise = axios({
    method: "delete",
    url: `/api/v1/users/${userId}/delete_image_samples/`,
    data: { subcategory_group_id: group.id },
  });

  return dispatch({
    type: DELETE_WORK_SAMPLES,
    promise: promise,
    data: { group: group.id },
  });
};

/* Delete writing samples for group. */
export const deleteWritingSamples = (group) => (dispatch, getState) => {
  const user = getState().user;
  const userId = user.profile_data.id;

  const promise = axios({
    method: "delete",
    url: `/api/v1/users/${userId}/delete_writing_samples/`,
    data: { subcategory_group_id: group.id },
  });

  return dispatch({
    type: DELETE_WORK_SAMPLES,
    promise: promise,
    data: { group: group.id },
  });
};

/* Update avatar locally */
export const updateAvatarLocal = (avatar) => (dispatch) =>
  dispatch({
    type: UPDATE_AVATAR_LOCAL,
    data: avatar,
  });

/* Fetch photo ID verification if needed */
export const fetchIdPhotoIfNeeded =
  (force = false) =>
  (dispatch, getState) => {
    const state = getState();
    const { idPhoto, idVerificationStatus } = state.creativeRegistration;

    if (!idPhoto || force) {
      const user = state.user;
      const userId = user.profile_data.id;
      const promise = request({
        method: "get",
        url: `/api/v1/users/${userId}/get_id_validation/`,
      });

      dispatch({
        type: FETCH_ID_PHOTO,
        promise: promise,
      });

      return promise.then(({ data }) => data.status);
    }

    return Promise.resolve(idVerificationStatus);
  };

/* Fetch image samples */
export const fetchImageSamples = (hideRejected) => (dispatch, getState) => {
  const state = getState();
  const user = state.user;
  const userId = user.profile_data.id;

  return dispatch({
    type: FETCH_IMAGE_SAMPLES,
    promise: request({
      method: "get",
      url: `/api/v1/users/${userId}/get_image_samples/`,
    }),
    hideRejected,
  });
};

/* Fetch writing samples */
export const fetchWritingSamples = (hideRejected) => (dispatch, getState) => {
  const state = getState();
  const user = state.user;
  const userId = user.profile_data.id;
  return dispatch({
    type: FETCH_WRITING_SAMPLES,
    promise: request({
      method: "get",
      url: `/api/v1/users/${userId}/get_writing_samples/`,
    }),
    hideRejected,
  });
};

export const setValidatingId = (value) => (dispatch) =>
  dispatch({
    type: SET_VALIDATING_ID,
    value,
  });

export const fetchSampleGroups = () => (dispatch) => {
  return dispatch({
    type: FETCH_SAMPLE_GROUPS,
    promise: request.get("/api/v1/worksample-groups/"),
  });
};

export const saveInviteHash = (hash) => (dispatch) =>
  dispatch({
    type: SAVE_INVITE_HASH,
    hash,
  });
