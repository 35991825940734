import request from "axios";
import pick from "lodash/pick";
import { setBannerError } from "error";
import { getProject, fetchProject } from "project/ducks/project";
import { updateUser } from "common/ducks/user";
import { VOTE_SUCCESS } from "focus_groups/ducks/voting";

/* ACTIONS
================================================================================================ */
const FETCH_ALL = "focus_groups/common/FETCH_ALL";
const FETCH_ALL_REQUEST = "focus_groups/common/FETCH_ALL_REQUEST";
const FETCH_ALL_SUCCESS = "focus_groups/common/FETCH_ALL_SUCCESS";

const FETCH = "focus_groups/common/FETCH";
const FETCH_REQUEST = "focus_groups/common/FETCH_REQUEST";
const FETCH_SUCCESS = "focus_groups/common/FETCH_SUCCESS";

const UPDATE = "focus_groups/common/UPDATE";
const UPDATE_SUCCESS = "focus_groups/common/UPDATE_SUCCESS";

const FETCH_PARTICIPANTS = "focus_groups/common/FETCH_PARTICIPANTS";
const FETCH_PARTICIPANTS_SUCCESS =
  "focus_groups/common/FETCH_PARTICIPANTS_SUCCESS";

const FETCH_REVISIONS = "focus_groups/common/FETCH_REVISIONS";
const FETCH_REVISIONS_SUCCESS = "focus_groups/common/FETCH_REVISIONS_SUCCESS";

/* HELPERS
================================================================================================ */
const getRequestConfig = ({ participantKey, anonymousKey }) => {
  if (participantKey) {
    return { headers: { "X-Participant-Key": participantKey } };
  }

  if (anonymousKey) {
    return { headers: { "X-Anonymous-Key": anonymousKey } };
  }
};

/* INITIAL STATES
================================================================================================ */
const initialState = {
  isFetching: false,
  all: [],
  participants: {},
  revisions: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_ALL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        all: action.payload,
      };
    case FETCH_REQUEST:
      return {
        ...state,
        current: null,
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        current: action.payload,
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        current: action.payload,
        all: state.all.map((g) =>
          g.id === action.payload.id ? action.payload : g
        ),
      };
    case FETCH_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        participants: {
          ...state.participants,
          [action.groupId]: action.payload,
        },
      };
    case FETCH_REVISIONS_SUCCESS:
      return {
        ...state,
        revisions: {
          ...state.revisions,
          [action.groupId]: action.payload,
        },
      };
    case VOTE_SUCCESS:
      return {
        ...state,
        revisions: {
          ...state.revisions,
          [action.groupId]: (state.revisions[action.groupId] || []).map((r) =>
            r.id === action.payload.id ? action.payload : r
          ),
        },
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const fetchAll = (projectId) => (dispatch) => {
  const promise = request.get(`/api/v1/focus-groups/?project=${projectId}`);

  dispatch({
    type: FETCH_ALL,
    promise,
  });

  return promise;
};

export const fetch = (id, participantKey, anonymousKey) => (dispatch) => {
  const config = getRequestConfig({ participantKey, anonymousKey });

  return dispatch({
    type: FETCH,
    promise: request.get(`/api/v1/focus-groups/${id}/`, config),
  });
};

export const create = (data) => (dispatch, getState) => {
  const state = getState();
  const project = getProject(state);
  const user = state.user.profile_data;
  const { selected } = state.focusGroups.entries;
  const promise = request.post("/api/v1/focus-groups/", {
    ...data,
    project: project.id,
  });

  promise.catch(() => {
    dispatch(setBannerError("Error creating focus group", "Please try again."));
  });

  dispatch(
    updateUser({ id: user.id, ...pick(data, ["first_name", "last_name"]) })
  );

  promise.then((res) => {
    selected.forEach((e) =>
      request.post(`/api/v1/focus-groups/${res.data.id}/image-revisions/`, {
        revision: e.revisions[0].id,
      })
    );

    /* fetch project with possibly updated `can_create_focus_group` field */
    dispatch(fetchProject(project.id));

    dispatch(fetchAll(project.id));
  });

  return promise;
};

export const update = (id, data, hideErrorBanner) => (dispatch) => {
  const promise = request.patch(`/api/v1/focus-groups/${id}/`, data);

  if (!hideErrorBanner) {
    promise.catch(() => {
      dispatch(
        setBannerError("Error updating focus group", "Please try again.")
      );
    });
  }

  dispatch({
    type: UPDATE,
    promise,
  });

  return promise;
};

export const createParticipants = (id, data) => {
  const promise = request.post(
    `/api/v1/focus-groups/${id}/participants/batch_create/`,
    data
  );

  promise.catch(() => {
    dispatch(setBannerError("Error adding participants", "Please try again."));
  });

  return promise;
};

export const fetchParticipants = (groupId) => (dispatch) => {
  return dispatch({
    type: FETCH_PARTICIPANTS,
    promise: request.get(`/api/v1/focus-groups/${groupId}/participants/`),
    groupId,
  });
};

export const fetchRevisions =
  (groupId, participantKey, anonymousKey) => (dispatch) => {
    const config = getRequestConfig({ participantKey, anonymousKey });

    return dispatch({
      type: FETCH_REVISIONS,
      promise: request.get(
        `/api/v1/focus-groups/${groupId}/image-revisions/`,
        config
      ),
      groupId,
    });
  };

export const updateParticipants = (id, current, updated) => (dispatch) => {
  const toRemove = [],
    toUpdate = [];

  current.forEach((participant) => {
    const updatedParticipant = updated.find((p) => p.id === participant.id);

    if (!updatedParticipant) {
      toRemove.push(participant);
    } else if (
      parseInt(updatedParticipant.weight) !== parseInt(participant.weight)
    ) {
      toUpdate.push(updatedParticipant);
    }
  });

  let promises = toRemove.reduce(
    (acc, p) =>
      acc.then(() =>
        request.delete(`/api/v1/focus-groups/${id}/participants/${p.id}/`)
      ),
    Promise.resolve()
  );

  promises = toUpdate.reduce(
    (acc, p) =>
      acc.then(() =>
        request.patch(`/api/v1/focus-groups/${id}/participants/${p.id}/`, p)
      ),
    promises
  );

  promises.then(() =>
    dispatch({
      type: FETCH_PARTICIPANTS_SUCCESS,
      payload: updated,
      id: id,
    })
  );

  return promises;
};
