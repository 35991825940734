import React from "react";
import PropTypes from "prop-types";

export default function Money({ value = 0, className, showDecimals }) {
  const fixedNum = showDecimals ? 2 : 0;
  const numericValue = Number(value);
  const absValue = Math.abs(numericValue)
    .toFixed(fixedNum)
    .replace(/./g, (c, i, a) =>
      i && c !== "." && (a.length - i) % 3 === 0 ? `,${c}` : c
    );

  return (
    <span className={className}>
      {numericValue < 0 ? "-" : ""}
      <span className="dollar-sign">$</span>
      {absValue}
    </span>
  );
}

Money.defaultProps = {
  value: 0,
  className: "",
  showDecimals: true,
};

Money.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.any,
  showDecimals: PropTypes.bool,
};
