import React from "react";
import PropTypes from "prop-types";
import ErrorPage from "error/components/";
import { Provider } from "react-redux";
import { renderToString } from "react-dom/server";
import App from "common/containers/app";
import { RouterContext } from "react-router";

const Server403 = () => {
  const mockRouter = { location: { pathname: "403" } };
  return (
    <App>
      <ErrorPage router={mockRouter} />
    </App>
  );
};

const get403Html = (store, props) =>
  renderToString(
    <Provider store={store}>
      <RouterContext {...props} components={[Server403]} />
    </Provider>
  );

export default get403Html;
