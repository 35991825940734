import request from "axios";
import moment from "moment";
import { setBannerError } from "error";

/* ACTIONS
================================================================================================ */
const UPDATE_HISTORY_PAGE = "crowdspring/payout/UPDATE_HISTORY_PAGE";

const FETCH_REQUESTED = "crowdspring/payout/FETCH_REQUESTED";
const FETCH_REQUESTED_SUCCESS = "crowdspring/payout/FETCH_REQUESTED_SUCCESS";

const FETCH_HISTORY_PAGE = "crowdspring/payout/FETCH_HISTORY_PAGE";
const FETCH_HISTORY_PAGE_SUCCESS =
  "crowdspring/payout/FETCH_HISTORY_PAGE_SUCCESS";

const FETCH_PENDING_AWARDS = "crowdspring/payout/FETCH_PENDING_AWARDS";
const FETCH_PENDING_AWARDS_SUCCESS =
  "crowdspring/payout/FETCH_PENDING_AWARDS_SUCCESS";

const FETCH_ACCOUNTS = "crowdspring/payout/FETCH_ACCOUNTS";
const FETCH_ACCOUNTS_SUCCESS = "crowdspring/payout/FETCH_ACCOUNTS_SUCCESS";

const MAKE_ACCOUNT_DEFAULT = "crowdspring/payout/MAKE_ACCOUNT_DEFAULT";
const MAKE_ACCOUNT_DEFAULT_SUCCESS =
  "crowdspring/payout/MAKE_ACCOUNT_DEFAULT_SUCCESS";

const DELETE_ACCOUNT = "crowdspring/payout/DELETE_ACCOUNT";
const DELETE_ACCOUNT_SUCCESS = "crowdspring/payout/DELETE_ACCOUNT_SUCCESS";

const ENABLE_PAYONEER = "crowdspring/payout/ENABLE_PAYONEER";
const ENABLE_PAYONEER_SUCCESS = "crowdspring/payout/ENABLE_PAYONEER_SUCCESS";
const ENABLE_PAYONEER_FAILURE = "crowdspring/payout/ENABLE_PAYONEER_FAILURE";

/* HELPERS
================================================================================================ */
const HISTORY_PAGE_SIZE = 5;

export const sumAwards = (awards) => {
  return awards.reduce((sum, award) => sum + parseFloat(award.amount), 0);
};

export const filterAvailableAwards = (awards) => {
  return (awards || []).filter(
    (award) =>
      award.status === "paid" &&
      (!award.available_after || moment().isAfter(award.available_after))
  );
};

/* Payments are made on Mondays, Wednesdays and Fridays. */
export const getNextPaymentDate = () => {
  const today = moment();
  const nextDates = [];
  ["Monday", "Wednesday", "Friday"].forEach((label) => {
    const date = today.clone().day(label);
    /* if past already, consider week day on next week */
    nextDates.push(
      date.isAfter(today, "day") ? date : date.clone().add(7, "day")
    );
  });
  return moment.min(nextDates).format("MM/DD/YYYY");
};

export const requestPaymentRequest = (userId, awards) => {
  const awardsData = awards.reduce((list, award) => {
    return list.concat([
      {
        uuid: award.paid_uuid,
        payment_type: award.payment_type,
        project: award.project.id,
      },
    ]);
  }, []);
  return request.post(`/api/v1/users/${userId}/payout/requests/`, {
    awards: awardsData,
  });
};

export const addPaypalRequest = (userId, data) => {
  return request.post(
    `/api/v1/users/${userId}/payout/bindings/add_paypal/`,
    data
  );
};

export const addPayoneerRequest = (userId) => {
  return request.post(`/api/v1/users/${userId}/payout/bindings/add_payoneer/`);
};

export const updatePaypalRequest = (userId, data) => {
  return request.patch(
    `/api/v1/users/${userId}/payout/bindings/update_paypal/`,
    data
  );
};

/* REDUCERS
 ================================================================================================ */
const initialState = {
  history: {
    pages: [],
    currentPage: 1,
    pageCount: 0,
  },
  requested: null,
  pendingAwards: null,
  accounts: null,
  payoneerEnabled: undefined,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_HISTORY_PAGE:
      return {
        ...state,
        history: {
          ...state.history,
          currentPage: parseInt(action.page),
        },
      };
    case FETCH_REQUESTED_SUCCESS:
      return {
        ...state,
        requested: action.payload,
      };
    case FETCH_HISTORY_PAGE_SUCCESS:
      const { history } = state;
      const pages = [...history.pages];
      pages[history.currentPage - 1] = action.payload.results;

      return {
        ...state,
        history: {
          ...history,
          pages,
          pageCount: Math.ceil(action.payload.count / HISTORY_PAGE_SIZE),
        },
      };
    case FETCH_PENDING_AWARDS_SUCCESS:
      return {
        ...state,
        pendingAwards: action.payload,
      };
    case FETCH_ACCOUNTS_SUCCESS:
      return {
        ...state,
        accounts: action.payload,
      };
    case ENABLE_PAYONEER_SUCCESS:
      return {
        ...state,
        payoneerEnabled: true,
      };
    case ENABLE_PAYONEER_FAILURE:
      return {
        ...state,
        payoneerEnabled: false,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const updateHistoryPage = (page) => (dispatch, getState) => {
  const state = getState();
  const { pages } = state.payout.history;

  dispatch({
    type: UPDATE_HISTORY_PAGE,
    page,
  });

  /* fetch page if not already present */
  dispatch(fetchHistoryPageIfNeeded(page));
};

export const fetchHistoryPageIfNeeded = (page) => (dispatch, getState) => {
  const { user, payout } = getState();
  const userId = user.profile_data.id;
  const offset = (page - 1) * HISTORY_PAGE_SIZE;
  const limit = HISTORY_PAGE_SIZE;

  if (!payout.history.pages[page - 1]) {
    return dispatch({
      type: FETCH_HISTORY_PAGE,
      promise: request.get(
        `/api/v1/users/${userId}/payout/requests/completed/?limit=${limit}&offset=${offset}`
      ),
    });
  }
};

export const fetchRequestedIfNeeded = (force) => (dispatch, getState) => {
  const { user, payout } = getState();
  const userId = user.profile_data.id;

  if (!payout.requested || force) {
    return dispatch({
      type: FETCH_REQUESTED,
      promise: request.get(
        `/api/v1/users/${userId}/payout/requests/in_progress/`
      ),
    });
  }

  return Promise.resolve();
};

export const fetchPendingAwardsIfNeeded = (force) => (dispatch, getState) => {
  const { user, payout } = getState();
  const userId = user.profile_data.id;

  if (!payout.pendingAwards || force) {
    return dispatch({
      type: FETCH_PENDING_AWARDS,
      promise: request.get(
        `/api/v1/users/${userId}/payout/unrequested-payments/`
      ),
    });
  }

  return Promise.resolve();
};

export const makeAccountDefault =
  (account, onSuccess) => (dispatch, getState) => {
    const { user } = getState();
    const userId = user.profile_data.id;

    const promise = request.patch(
      `/api/v1/users/${userId}/payout/bindings/make_default/`,
      { binding: account.system }
    );

    promise
      .then(() => {
        onSuccess();
        /* fetch updated accounts */
        dispatch(fetchAccountsIfNeeded(true));
      })
      .catch(() => {
        dispatch(
          setBannerError("Error updating default account", "Please try again")
        );
      });

    return dispatch({
      type: MAKE_ACCOUNT_DEFAULT,
      promise: promise,
    });
  };

export const deleteAccount = (account, onSuccess) => (dispatch, getState) => {
  const { user } = getState();
  const userId = user.profile_data.id;

  const promise = request.put(
    `/api/v1/users/${userId}/payout/bindings/delete/`,
    { binding: account.system }
  );

  promise
    .then(() => {
      onSuccess();
      /* fetch updated accounts */
      dispatch(fetchAccountsIfNeeded(true));
    })
    .catch(() => {
      dispatch(setBannerError("Error deleting account", "Please try again"));
    });

  return dispatch({
    type: DELETE_ACCOUNT,
    promise: promise,
  });
};

export const fetchAccountsIfNeeded = (force) => (dispatch, getState) => {
  const { user, payout } = getState();
  const userId = user.profile_data.id;

  if (!payout.accounts || force) {
    const promise = request.get(`/api/v1/users/${userId}/payout/bindings/`);
    dispatch({
      type: FETCH_ACCOUNTS,
      promise,
    });
    return promise;
  }

  return Promise.resolve({ data: payout.accounts });
};

export const enablePayoneer = () => (dispatch, getState) => {
  const { user } = getState();
  const userId = user.profile_data.id;

  return dispatch({
    type: ENABLE_PAYONEER,
    promise: request.put(
      `/api/v1/users/${userId}/payout/bindings/enable_payoneer/`
    ),
  });
};
