import React from "react";
import { connect } from "react-redux";
import HeaderTitle from "header/components/title";
import {
  REFERRAL_MONEY_DISCOUNT,
  REFERRAL_FEATURE_DISCOUNT,
  REFERRAL_SOURCE_BS,
} from "marketing/constants";

const TITLE = {
  default: "Share crowdspring with a friend",
  [REFERRAL_SOURCE_BS]: "Tell friends about Brand Studio",
};

const SUB_TITLE = {
  default: (
    <span>
      You and your friend both get {REFERRAL_MONEY_DISCOUNT.default} off a
      future project
      <br /> plus our {REFERRAL_FEATURE_DISCOUNT.default} free.
    </span>
  ),
  [REFERRAL_SOURCE_BS]: (
    <span>
      Your friends get access to free branded templates
      <br />
      for their businesses and you unlock a social media
      <br />
      power pack (more free branded templates) for your
      <br />
      business.
    </span>
  ),
};

const mapStateToProps = (state, { source }) => {
  return {
    title: TITLE[source] || TITLE.default,
    subTitle: SUB_TITLE[source] || SUB_TITLE.default,
  };
};

export default connect(mapStateToProps)(HeaderTitle);
