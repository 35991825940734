import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const WatchButton = ({ watch, unwatch, classes, projectId, project, user }) => {
  if (!user.id) {
    return null;
  }

  const isSelected = (user.watchlist_ids || []).includes(parseInt(projectId));
  const classNames = classnames(classes, { selected: isSelected });
  const watchText = isSelected ? "Unwatch" : "Watch";
  const watchAction = isSelected ? unwatch : watch;

  return (
    <button type="button" className={classNames} onClick={watchAction}>
      <i className="fa fa-fw fa-eye" aria-hidden="true" /> {watchText} (
      {project.watchers_count})
    </button>
  );
};

WatchButton.propTypes = {
  watch: PropTypes.func.isRequired,
  unwatch: PropTypes.func.isRequired,
  classes: PropTypes.string,
  projectId: PropTypes.number.isRequired,
  project: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default WatchButton;
