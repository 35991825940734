import React, { Component } from "react";
import PropTypes from "prop-types";
import CopyToClipboard from "react-copy-to-clipboard";

export default class CopyTextButton extends Component {
  constructor(props) {
    super(props);

    this.state = { copied: false };

    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleBtnClick = this.handleBtnClick.bind(this);
  }

  handleSuccess() {
    this.setState({ copied: true });
    /* revert back to original state after some seconds */
    setTimeout(() => this.setState({ copied: false }), 3000);
  }

  handleBtnClick() {
    const { elementId } = this.props;

    /* if `this.props.elementId` is not provided, `this.props.text` is copied by `CopyToClipboard` */
    if (!elementId) {
      return;
    }

    const range = document.createRange();
    range.selectNodeContents(document.getElementById(elementId));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    /* although obsolete, this is the only way to get the **rendered** text to the clipboard */
    document.execCommand("copy");
    window.getSelection().removeAllRanges();

    this.handleSuccess();
  }

  render() {
    const { text, className, hideIcon, label, icon, elementId } = this.props;
    const { copied } = this.state;
    const btn = (
      <button onClick={this.handleBtnClick} className={className}>
        {!hideIcon && (
          <i className={`fa fa-fw fa-${copied ? "check" : icon || "copy"}`} />
        )}{" "}
        {copied ? "Copied!" : label || "Copy"}
      </button>
    );

    return elementId ? (
      btn
    ) : (
      <CopyToClipboard text={text} onCopy={this.handleSuccess}>
        {btn}
      </CopyToClipboard>
    );
  }
}

CopyTextButton.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  hideIcon: PropTypes.bool,
  elementId: PropTypes.string,
};
