import React, { Component } from "react";
import PropTypes from "prop-types";
import onClickOutside from "react-onclickoutside";
import { TABLET_BREAKPOINT_PX } from "common/variables";
import Notifications from "header/containers/notifications";
import classnames from "classnames";
import Avatar from "common/components/avatar";
import CommonRightNavLinks from "header/containers/right_nav_links";

/* Simple wrapping around `RightNavLinks` to allow for toggling on click outside */
const RightNavLinks = onClickOutside(
  class _ extends Component {
    handleClickOutside() {
      /* restrict the click outside behaviour for desktop, since on mobile this component
       is hidden */
      if (window.innerWidth >= TABLET_BREAKPOINT_PX) {
        this.props.toggle();
      }
    }

    render() {
      return <CommonRightNavLinks classes={this.props.classes} />;
    }
  }
);

/**
 * User info component displayed in the header
 * @author Kuba Siemiątkowski <kuba@crowdspring.com>
 */
const ProfileInfo = ({
  shouldShow,
  user,
  avatarImage,
  toggleRightSideNav,
  showRightSideNav,
}) => {
  if (!shouldShow) {
    return null;
  }

  return (
    <div className="columns medium-4">
      <div className="user-tools">
        <Notifications />
        <Avatar
          id="avatar-button"
          extraClasses={classnames("avatar-sm avatar-btn", {
            active: showRightSideNav,
          })}
          user={{ ...user, avatar: avatarImage }}
          onClick={toggleRightSideNav}
        />
        {showRightSideNav && (
          <RightNavLinks
            classes="show-for-large avatar-dropdown"
            toggle={toggleRightSideNav}
            outsideClickIgnoreClass="avatar-btn"
          />
        )}
      </div>
    </div>
  );
};

ProfileInfo.propTypes = {
  shouldShow: PropTypes.bool,
  avatarImage: PropTypes.string,
  user: PropTypes.object,
  toggleRightSideNav: PropTypes.func,
  showRightSideNav: PropTypes.bool,
};

export default ProfileInfo;
