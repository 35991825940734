import request from "axios";
import debounce from "lodash/debounce";
import { getAPIQueryString } from "common/utils";
import { savePageCount } from "common/pager/ducks";
import { TERMS_RESOURCE_NAME } from "searched_terms/constants";

/* ACTIONS
================================================================================================ */
const FETCH_TERMS = "searched_terms/FETCH_TERMS";
const FETCH_TERMS_REQUEST = "searched_terms/FETCH_TERMS_REQUEST";
const FETCH_TERMS_SUCCESS = "searched_terms/FETCH_TERMS_SUCCESS";

/* HELPERS
================================================================================================ */
export const createTermRequest = debounce(({ content, context }) => {
  if ((content || "").trim().length >= 2) {
    return request.post("/api/v1/searched_terms/", { content, context });
  }

  return undefined;
}, 1000);

/* INITIAL STATES
================================================================================================ */
const initialState = {
  terms: null,
  termCount: 0,
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TERMS_REQUEST:
      return {
        ...state,
        terms: null,
      };
    case FETCH_TERMS_SUCCESS:
      return {
        ...state,
        terms: action.payload.results,
        termCount: action.payload.count,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const fetchTerms = (query) => (dispatch) => {
  const queryStr = getAPIQueryString(TERMS_RESOURCE_NAME, query);
  const promise = request.get(`/api/v1/searched_terms/?${queryStr}`);

  /* save page count in pager state */
  promise.then((res) => dispatch(savePageCount(TERMS_RESOURCE_NAME, res)));

  return dispatch({
    type: FETCH_TERMS,
    promise,
  });
};
