import React from "react";
import PropTypes from "prop-types";
import idx from "idx";
import partial from "lodash/partial";
import classnames from "classnames";

const MAP = {
  image: ["grid", "list"],
  name: ["list", "dense"],
};

const CLASSES_MAP = {
  grid: "fa fa-th-large",
  list: "fa fa-bars",
  dense: "fa fa-list",
};

export const getDefaultViewType = (entryType) =>
  idx(MAP, (_) => _[entryType][0]);

const ViewTypeSelector = ({ viewType, entryType, onChange }) => {
  const options = idx(MAP, (_) => _[entryType]);

  if (!options) {
    return null;
  }

  return (
    <div className="controls-group view-selector">
      <h5 className="filter-name">View</h5>
      <div className="view-mode">
        {options.map((opt, i) => (
          <button
            key={i}
            className={classnames({ selected: opt === viewType })}
            onClick={partial(onChange, opt)}
          >
            <i className={CLASSES_MAP[opt]} />
          </button>
        ))}
      </div>
    </div>
  );
};

ViewTypeSelector.propTypes = {
  viewType: PropTypes.string,
  entryType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ViewTypeSelector;
