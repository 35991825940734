import { connect } from "react-redux";
import {
  toggle as toggleEntrySubmission,
  toggleEntrySelection,
} from "project/ducks/entry_submission";
import {
  getParticipationForbiddenReason,
  canSubmitRevision,
} from "project/utils";
import { getProject } from "project/ducks/project";
import SubmitDropdown from "project/components/entry_submission/submit_dropdown";

const mapStateToProps = (state) => {
  const user = state.user.profile_data;
  const project = getProject(state);

  return {
    canParticipate: !getParticipationForbiddenReason(state),
    canSubmitRevision: canSubmitRevision({ state, project, user }),
  };
};

export default connect(mapStateToProps, {
  toggleEntrySelection,
  toggleEntrySubmission,
})(SubmitDropdown);
