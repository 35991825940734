import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import { v4 } from "uuid";
import classnames from "classnames";
import some from "lodash/some";
import Dropdown from "common/components/dropdown_menu";
import { isUrlActive } from "common/utils";

export default class Navigation extends Component {
  constructor(props) {
    super(props);

    this.renderLink = this.renderLink.bind(this);
  }

  renderInactiveContent(link) {
    const { location = { pathname: "" } } = this.props;
    const { pathname } = location;

    if (!link.subItems) {
      return (
        <Link rel={link.rel} to={link.url}>
          {link.name}
        </Link>
      );
    }

    const dropdownBtns = [
      {
        show: true,
        url: link.url,
        label: link.name,
        action: () => {},
        toggleOnClick: true,
        closeOnClick: false,
        extraClasses: some(link.subItems, (item) =>
          isUrlActive(item.url, pathname, true)
        )
          ? "active"
          : "",
      },
    ].concat(
      link.subItems.map((item, i) => ({
        show: true,
        url: item.url,
        label: item.name,
        extraClasses: isUrlActive(item.url, pathname, true) ? "active" : "",
      }))
    );

    return <Dropdown showFirst={true} buttons={dropdownBtns} />;
  }

  renderLink(link) {
    const { location = { pathname: "" }, isLoggedIn = false } = this.props;
    const { pathname } = location;
    const active = pathname.startsWith(link.url);
    const classes = classnames("nav-item", {
      active: active,
      right: link.right,
      hide: link.navOnly || (link.mobileNav === "right" && isLoggedIn),
    });

    return (
      <li key={v4()} className={classes}>
        {active ? (
          <span className="active">{link.name}</span>
        ) : (
          this.renderInactiveContent(link)
        )}
      </li>
    );
  }

  render() {
    const { shouldShow, links, isLoggedIn = false } = this.props;

    if (!shouldShow) {
      return null;
    }

    const wrapperClasses = classnames("columns", {
      "large-11 show-for-large": isLoggedIn,
      "large-13": !isLoggedIn,
    });

    return (
      <div className={wrapperClasses}>
        <div className="top-navigation">
          <ul>{links.map(this.renderLink)}</ul>
        </div>

        {!isLoggedIn && (
          <div className="login-nav text-right hide-for-large">
            <Link to="/login">Login</Link>
          </div>
        )}
      </div>
    );
  }
}

Navigation.propTypes = {
  shouldShow: PropTypes.bool,
  links: PropTypes.array.isRequired,
  location: PropTypes.object,
  isLoggedIn: PropTypes.bool,
};
