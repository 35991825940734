import idx from "idx";
import request from "axios";
import { browserHistory } from "react-router";
import { createAction } from "redux-actions";
import { getProject } from "project/ducks/project";
import { FETCH_SUCCESS as FETCH_PROJECT_SUCCESS } from "project/ducks/common";
import { TIP_SUCCESS, ADVANCE_OFFER_NEGOTIATION_SUCCESS } from "entries/ducks";
import * as commonImports from "project/ducks/common";

/* ACTIONS
================================================================================================ */
export const FETCH_SINGLE = "crowdspring/single-entry/FETCH";
export const FETCH_SINGLE_REQUEST = "crowdspring/single-entry/FETCH_REQUEST";
export const FETCH_SINGLE_SUCCESS = "crowdspring/single-entry/FETCH_SUCCESS";

export const UPDATE_REVISION = "crowdspring/single-entry/update-revision";

export const updateRevision = createAction(UPDATE_REVISION);

/* HELPERS
================================================================================================ */
export const getEntryIds = (state, id) => {
  const { allIds } = state.entries;

  if (allIds.includes(parseInt(id))) {
    return allIds;
  }
  /* sort `project.entry_ids` in the order they are shown in the gallery by default */
  return (idx(getProject(state), (_) => _.entry_ids) || []).slice().reverse();
};

const updateEntryFields = (state, action, fields) => {
  let entriesById = { ...state.entriesById };

  if (entriesById[action.payload.id]) {
    fields.forEach((field) => {
      entriesById = {
        ...entriesById,
        [action.payload.id]: {
          ...entriesById[action.payload.id],
          [field]: action.payload[field],
        },
      };
    });
  }

  return entriesById;
};

/* INITIAL STATES
================================================================================================ */
export const INITIAL_STATE = {
  isLoading: true,
  entriesById: {},
  currentRevisionId: null,
};

/* REDUCERS
================================================================================================ */
export default function singleEntryReducer(state = INITIAL_STATE, action) {
  let newEntriesById;

  switch (action.type) {
    case FETCH_PROJECT_SUCCESS: {
      return {
        ...state,
        currentRevisionId: null,
      };
    }
    case FETCH_SINGLE_REQUEST: {
      return {
        ...state,
        currentRevisionId: null,
        isLoading: true,
      };
    }
    case FETCH_SINGLE_SUCCESS: {
      newEntriesById = { ...state.entriesById };
      newEntriesById[action.payload.id] = action.payload;

      return {
        ...state,
        isLoading: false,
        currentRevisionId: action.currentRevisionId || null,
        entriesById: newEntriesById,
      };
    }
    case UPDATE_REVISION: {
      return {
        ...state,
        currentRevisionId: action.payload,
      };
    }
    case commonImports.SCORE_REVISION_SUCCESS:
      newEntriesById = { ...state.entriesById };

      Object.keys(newEntriesById).forEach((entryId) => {
        const { revisions } = newEntriesById[entryId];
        const revisionIdx = revisions.findIndex(
          (rev) => rev.id === action.payload.id
        );
        if (revisionIdx !== -1) {
          revisions[revisionIdx] = {
            ...revisions[revisionIdx],
            score: action.payload.score,
          };
        }
      });

      return {
        ...state,
        entriesById: newEntriesById,
      };
    case TIP_SUCCESS:
      return {
        ...state,
        entriesById: updateEntryFields(state, action, ["tip_value"]),
      };
    case ADVANCE_OFFER_NEGOTIATION_SUCCESS:
      return {
        ...state,
        entriesById: updateEntryFields(state, action, ["offer_value"]),
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const fetchSingleEntry =
  (projectId, id, currentRevisionId) => (dispatch) => {
    const promise = request.get(`/api/v1/projects/${projectId}/entries/${id}/`);

    promise.catch(() => browserHistory.replace("/404/"));

    return dispatch({
      type: FETCH_SINGLE,
      promise,
      currentRevisionId,
    });
  };
