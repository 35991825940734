import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import partial from "lodash/partial";
import idx from "idx";
import { requestAccess } from "project/ducks/ndas";
import {
  toggle as toggleEntrySubmission,
  toggleEntrySelection,
} from "project/ducks/entry_submission";
import {
  canRequestProjectAccess,
  canWatch,
  canExtendDeadline,
  getProjectIdFromUrl,
  canUpgrade,
  canDownloadInvoice,
  canAddAwards,
  canShare,
  canReportProjectViolation,
  getParticipationForbiddenReason,
  canReserveElite,
  canRequestEarlyClose,
  canSubmitRevision,
} from "project/utils";
import { shareLink } from "common/utils";
import ActionsMenu from "project/components/header_actions_menu";
import {
  toggleViolationModal,
  getInvoice,
  getProject,
} from "project/ducks/project";
import { toggleAwardsModal } from "project/ducks/awards";
import { toggleFeaturesModal, toggleExtendModal } from "project/ducks/features";

const mapStateToProps = (state, { params }) => {
  const project = getProject(state);
  const user = state.user.profile_data;

  return {
    canRequestAccess: canRequestProjectAccess(state),
    canParticipate: !getParticipationForbiddenReason(state),
    canSubmitRevision: canSubmitRevision({ state, project, user }),
    canWatch: canWatch(state),
    canExtend: canExtendDeadline(state, true),
    canUpgrade: canUpgrade(state),
    canDownloadInvoice: canDownloadInvoice(state),
    canAddAwards: canAddAwards(state),
    canShare: canShare(state),
    canReportProjectViolation: canReportProjectViolation(state),
    canReserveElite: canReserveElite(state),
    canRequestEarlyClose: canRequestEarlyClose(state),
    projectId: getProjectIdFromUrl(params),
    invoicePdf: idx(getInvoice(state), (_) => _.pdf),
  };
};

const mapDispatchToProps = (dispatch, { params }) => {
  const actions = bindActionCreators(
    {
      toggleAwardsModal,
      toggleFeaturesModal,
      toggleExtendModal,
      requestAccess,
      toggleEntrySubmission,
      toggleEntrySelection,
      toggleViolationModal: partial(toggleViolationModal, {
        type: "project",
        id: getProjectIdFromUrl(params),
      }),
    },
    dispatch
  );

  /* @TODO: grab shortened URL for this project */
  const link =
    typeof window !== "undefined"
      ? `${window.location.origin}/${params.subcategory}/${params.pageSlug}`
      : "";

  return {
    ...actions,
    share: partial(shareLink, link),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ActionsMenu)
);
