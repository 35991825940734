import Drawer from "notifications/components/drawer";
import { bindActionCreators } from "redux";
import { toggleDrawer, notificationAction } from "notifications/ducks";
import { connect } from "react-redux";
import partial from "lodash/partial";

export function mapStateToProps({ user, notifications }) {
  const userId = user.logged_in ? user.profile_data.id : null;
  const { showDrawer } = notifications;
  const notificationsList = user.logged_in ? notifications.data.results : [];
  const ids = notificationsList
    ? notificationsList.filter(({ seen }) => !seen).map(({ uuid }) => uuid)
    : [];

  return {
    hide: !user.logged_in,
    showDrawer,
    userId,
    notificationIds: ids,
  };
}

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleDrawer,
      markAllAsSeen: partial(notificationAction, "see"),
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Drawer);
