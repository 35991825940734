import React, { Component } from "react";
import PropTypes from "prop-types";
import { hasAPIQueryStringChanged } from "common/utils";
import { BRANDS_RESOURCE_NAME } from "brand_studio/constants";
import TabbedNavigation from "common/components/tabbed_navigation";

export default class HeaderTabs extends Component {
  componentDidMount() {
    const { router, fetch } = this.props;

    fetch().then((res) => {
      if (res.data.count === 0) {
        /* user has no brands yet, send them to "new brand" page */
        router.replace("/brand-studio/");
      }
    });
  }

  componentDidUpdate(prevProps) {
    /* re-fetch resource if filter/sorting/search/etc query parameters have changed */
    if (hasAPIQueryStringChanged(BRANDS_RESOURCE_NAME, prevProps, this.props)) {
      this.props.fetch();
    }
  }

  componentWillUnmount() {
    this.props.clear();
  }

  render() {
    return <TabbedNavigation {...this.props} />;
  }
}

HeaderTabs.propTypes = {
  fetch: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
};
