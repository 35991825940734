export const WIZARD_FONTS_COUNT = 2;

export const WIZARD_COLORS_COUNT = 2;

export const POLOTNO_KEY = "aKWHd_gKdrw9Lnh9BrF3";

export const TEMPLATES_RESOURCE_NAME = "templates";

export const DESIGNS_RESOURCE_NAME = "designs";

export const DESIGN_CATEGORY_OPTIONS = [
  { field: "brand_identity", label: "Brand identity" },
  { field: "marketing", label: "Marketing" },
  { field: "social_media", label: "Social media" },
];

export const BRANDS_RESOURCE_NAME = "brands";

export const BRAND_LOGO_PLACEHOLDER =
  "https://crowdspring3-assets.s3.amazonaws.com/marketing/default-thumb.jpeg";

export const TOUR_DEFINITIONS = {
  [TEMPLATES_RESOURCE_NAME]: {
    id: "brandTemplates",
    steps: [
      {
        beforeShowPromise: () => {
          return new Promise((resolve) => {
            /* wait for any re-renders triggered when toggling the tour, otherwise the target element
             may get lost */
            setTimeout(resolve, 500);
          });
        },
        attachTo: { element: "#tab_templates" },
        modalOverlayOpeningPadding: 0,
        title: "Templates",
        text: `
        Your original branded templates are always available in the TEMPLATES tab. When
        you customize any original branded template, we save your custom design in the
        DESIGNS tab.
      `,
      },
      {
        attachTo: { element: "#tab_designs" },
        modalOverlayOpeningPadding: 0,
        title: "Designs",
        text: `
        Your custom designs are automatically saved in the DESIGNS tab. Want to start over?
        Your original, unmodified templates are always available in the TEMPLATES tab.
      `,
      },
      {
        attachTo: { element: "#tab_brand_library" },
        modalOverlayOpeningPadding: 0,
        title: "Brand Library",
        text: `
        Need to make changes? Rename your brand, update your logo, change fonts and colors,
        or pick a different design style in the BRAND LIBRARY tab.
      `,
      },
      {
        attachTo: { element: "#tab_brands" },
        modalOverlayOpeningPadding: 0,
        title: "Brands",
        text: `
        Need branded templates for more than one brand? You can find your brands on the
        BRANDS tab.
      `,
      },
      {
        attachTo: { element: "#controls_group_category" },
        title: "Category",
        text: `
        Searching for a specific template? You can filter templates by category. From time
        to time, we add new branded templates and when we do, these will be immediately
        available for you.
      `,
      },
      {
        attachTo: { element: `#preview_${TEMPLATES_RESOURCE_NAME}_0` },
        title: "Branded Template",
        text: `
        This is an original branded template. Click any branded template to customize it.
        We'll automatically save your customized branded template in the DESIGNS tab. So,
        you can create custom designs and also access your original branded templates.
      `,
      },
    ],
  },
};

export const DISMISSED_REFER_BANNER_COOKIE = "dismissed_refer_banner";
