import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import { v4 } from "uuid";
import Question from "help/components/question";

const FaqList = ({ data, showMore, moreUrl }) => {
  return (
    <div className="faq-list">
      <div className="row">
        {data.map((faq) => (
          <div key={v4()} className="small-16 columns faq">
            <Question {...faq} />
          </div>
        ))}
      </div>
      {showMore && (
        <div className="row">
          <div className="small-16 columns">
            More questions?{" "}
            <Link target="_blank" to={moreUrl || "/help/"}>
              More answers <i className="fa fa-long-arrow-right" />
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

FaqList.propTypes = {
  data: PropTypes.array.isRequired,
  showMore: PropTypes.bool,
  moreUrl: PropTypes.string,
};

export default FaqList;
