import React, { Component } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import pick from "lodash/pick";
import classnames from "classnames";

const ICONS = {
  arrowRight: () => import("assets/images/icons/icon_arrow_right.svg"),
  arrowLeft: () => import("assets/images/icons/icon_arrow_left.svg"),
  close: () => import("assets/images/icons/icon_close.svg"),
  comments: () => import("assets/images/icons/icon_comments.svg"),
  "feedback-good": () => import("assets/images/icons/icon_feedback_good.svg"),
  "feedback-bad": () => import("assets/images/icons/icon_feedback_bad.svg"),
  "feedback-meh": () => import("assets/images/icons/icon_feedback_meh.svg"),
  filter: () => import("assets/images/icons/icon_filter.svg"),
  fullscreen: () => import("assets/images/icons/icon_fullscreen.svg"),
  menu: () => import("assets/images/icons/icon_menu.svg"),
  "nav-menu": () => import("assets/images/icons/icon_nav-menu.svg"),
  versions: () => import("assets/images/icons/icon_versions.svg"),
  notifications: () => import("assets/images/icons/icon_notifications.svg"),
  send: () => import("assets/images/icons/icon_send.svg"),
  zoomIn: () => import("assets/images/icons/icon_zoom-in.svg"),
  zoomOut: () => import("assets/images/icons/icon_zoom-out.svg"),
  trash: () => import("assets/images/icons/icon_trash.svg"),
  download: () => import("assets/images/icons/icon_download.svg"),
};

export default class Icon extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  loadIcon() {
    const { icon, loadFn } = this.props;

    (icon ? ICONS[icon]() : loadFn()).then((m) => {
      if (!this._mounted) {
        return;
      }
      this.setState({ icon: m.default });
    });
  }

  componentDidMount() {
    this.loadIcon();
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  componentDidUpdate(prevProps) {
    const propsToCompare = ["icon", "loadFn"];
    if (
      !isEqual(
        pick(prevProps, propsToCompare),
        pick(this.props, propsToCompare)
      )
    ) {
      this.loadIcon();
    }
  }

  render() {
    const { icon } = this.state;
    const { className, extraClasses } = this.props;

    return (
      <i
        className={classnames(className || "svg-icon", extraClasses)}
        dangerouslySetInnerHTML={{ __html: icon }}
        aria-hidden="true"
      />
    );
  }
}

Icon.propTypes = {
  icon: PropTypes.string,
  className: PropTypes.string,
  extraClasses: PropTypes.string,
};
