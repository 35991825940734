import React from "react";
import PropTypes from "prop-types";
import ViewTypeSelector from "common/components/entry_gallery/view_type_selector";
import GroupingSelector from "common/components/entry_gallery/grouping_selector";
import SorterSelect from "common/sorter/components/select";
import Filter from "common/filter/containers";
import { TYPE_CHECKBOXES } from "common/filter/constants";
import {
  getSortingOptions,
  SCORE_OPTIONS,
  ELIMINATED_OPTIONS,
  WITHDRAWN_OPTIONS,
  ACTIVE_OPTIONS,
} from "entries/constants";

const Controls = ({
  resourceName,
  entryType,
  viewType,
  setViewType,
  setGrouping,
  grouped,
}) => {
  const sortingOptions = getSortingOptions(entryType);

  return (
    <div className="project-gallery-controls sidebar sidebar-filter">
      <ViewTypeSelector
        entryType={entryType}
        viewType={viewType}
        onChange={setViewType}
      />
      <GroupingSelector
        entryType={entryType}
        grouped={grouped}
        onChange={setGrouping}
      />
      <div className="controls-group">
        <h5 className="sorting-name">Sort</h5>
        <SorterSelect
          resourceName={resourceName}
          options={sortingOptions}
          defaultValue="-created_at"
        />
      </div>
      <div className="controls-group">
        <h5 className="sorting-name">Filters</h5>
        <Filter
          field="score"
          type={TYPE_CHECKBOXES}
          resourceName={resourceName}
          options={SCORE_OPTIONS}
        />
        <Filter
          field="entry_eliminated"
          type={TYPE_CHECKBOXES}
          resourceName={resourceName}
          options={ELIMINATED_OPTIONS}
        />
        <Filter
          field="entry_withdrawn"
          type={TYPE_CHECKBOXES}
          resourceName={resourceName}
          options={WITHDRAWN_OPTIONS}
        />
        {entryType === "name" && (
          <Filter
            field="active"
            type={TYPE_CHECKBOXES}
            resourceName={resourceName}
            options={ACTIVE_OPTIONS}
          />
        )}
      </div>
    </div>
  );
};

Controls.propTypes = {
  resourceName: PropTypes.string.isRequired,
  entryType: PropTypes.string.isRequired,
  viewType: PropTypes.string,
  setViewType: PropTypes.func.isRequired,
  setGrouping: PropTypes.func.isRequired,
  grouped: PropTypes.bool,
};

export default Controls;
