import React from "react";
import PropTypes from "prop-types";
import idx from "idx";
import classnames from "classnames";

/**
 * User avatar.
 *
 * @author Nathan Ozelim <nathan@startupfoundry.com>
 */

const Avatar = ({ user, onClick, extraClasses = "", id }) => {
  let classes = "avatar";
  let content = null;

  if (user && user.avatar) {
    content = <img src={user.avatar} alt="User Avatar" />;
  } else if (user && user.first_name && user.last_name) {
    content = (
      <span>
        {user.first_name[0]}
        {user.last_name[0]}
      </span>
    );
    classes += " avatar-text";
  } else if (user && user.username) {
    content = <span>{user.username.slice(0, 2)}</span>;
    classes += " avatar-text";
  } else {
    classes += " avatar-border";
  }

  return (
    <div
      className={classnames(classes, extraClasses)}
      onClick={onClick}
      id={id}
      title={idx(user, (_) => _.username) || undefined}
    >
      {content}
    </div>
  );
};

Avatar.propTypes = {
  user: PropTypes.object,
  onClick: PropTypes.func,
  extraClasses: PropTypes.string,
  id: PropTypes.string,
};

export default Avatar;
