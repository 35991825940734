import { connect } from "react-redux";
import { getProject } from "project/ducks/project";
import { getProjectUrl } from "project/utils";
import { getSubcategorySlug } from "common/ducks/categories";
import CopyTextButton from "common/components/copy_text_button";

const mapStateToProps = (state) => {
  const project = getProject(state) || {};
  const subcategory = getSubcategorySlug(
    state.categories,
    project.sub_category
  );
  /* @TODO: grab shortened URL for this project */
  const projectUrl = getProjectUrl(project, subcategory);

  return {
    text: `${window.location.origin}${projectUrl}`,
    label: "Copy link",
    icon: "link",
  };
};

export default connect(mapStateToProps)(CopyTextButton);
