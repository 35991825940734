import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import idx from "idx";
import Logo from "header/containers/logo";
import Navigation from "header/containers/navigation";
import HeaderTabs from "header/containers/header_tabs";
import HeaderProfileInfo from "header/containers/profile_info";
import HelpDrawerButton from "header/containers/help_drawer_button";

import AccountHeaderTitle from "account/components/header_title";
import EnablePayoneerHeaderTitle from "account/containers/payout/enable_payoneer_header";
import LoginHeaderTitle from "login/components/header";
import PasswordResetTitle from "login/components/password_reset_header";
import RegistrationHeaderTitle from "registration/common/components/header";
import ProjectHeaderTitle from "project/containers/header_title";
import InactiveUserTitle from "profile/containers/inactive_user_title";
import Pap0HeaderTitle from "pap0/components/header_title";
import ExploreHeaderTitle from "explore/containers/header_title";
import ErrorHeaderTitle from "error/containers/header";
import YourProjectsTitle from "your_projects/containers/header";
import HelpTitle from "help/containers/header_title";
import FocusGroupsVotingTitle from "focus_groups/containers/voting/header_title";
import NotificationsTitle from "notifications/components/header_title";
import LegalHeaderTitle from "marketing/legal_header";
import Deprecations from "marketing/deprecations";
import AboutHeaderTitle from "marketing/about/title";
import VerifyEmailHeader from "common/containers/header_verify_email";
import ReferAFriendHeader from "marketing/containers/refer_a_friend_header";
import CollaborationInviteHeader from "project/containers/accept_collaboration_invite_header";
import ProductUpdatesHeader from "product_updates/containers/header";
import EmailAutomationsUnsubsHeader from "email_automations/containers/unsubscribe_header";
import CategoriesHeaderTitle from "marketing/components/categories_header_title";
import OtOHeaderTitle from "one_to_one/containers/header_title";
import BrandHeaderTitle from "brand_studio/containers/brand/header_title";
import BrandStudioHomeHeader from "brand_studio/containers/home/header_title";
import BrandStudioReferHeader from "brand_studio/containers/refer_a_friend_header";
import DesignEditorHeaderTitle from "brand_studio/containers/editor/header_title";
import ConfirmSubscriptionHeader from "email_automations/containers/confirm_subscription_header";
import AccountDeletedHeader from "account/containers/header_account_deleted";
import AccountSuspendedHeader from "account/containers/header_account_suspended";
import ValidateIdHeader from "registration/creative/containers/header_validate_id";

import ExploreHeaderButtons from "explore/containers/header_buttons";
import ProjectHeaderButtons from "project/containers/header_buttons";
import YourProjectsHeaderButtons from "your_projects/containers/header_buttons";
import BrandHeaderButtons from "brand_studio/containers/brand/header_buttons";

import YourProjectsHeaderTabs from "your_projects/containers/header_tabs";
import ProjectHeaderTabs from "project/containers/header_tabs";
import AboutHeaderTabs from "marketing/about/tabs";
import BrandHeaderTabs from "brand_studio/containers/brand/header_tabs";
import BrandStudioHomeHeaderTabs from "brand_studio/containers/home/header_tabs";
import Icon from "common/components/svg_icon";

/**
 * Page Header
 *
 * Page header has 3 customizable components:
 * 1. Title/Heading
 * 2. Tabs
 * 3. Actions/Buttons
 *
 * To load custom components, set header.content in header/duck.js, import them here
 * and add them to the respective Titles/Actions/Tabs maps below.  See above components
 * for examples.
 *
 */

export const Titles = {
  account: AccountHeaderTitle,
  enable_payoneer: EnablePayoneerHeaderTitle,
  login: LoginHeaderTitle,
  register: RegistrationHeaderTitle,
  project: ProjectHeaderTitle,
  pap0: Pap0HeaderTitle,
  explore_creatives: ExploreHeaderTitle,
  explore_projects: ExploreHeaderTitle,
  error: ErrorHeaderTitle,
  inactive_user: InactiveUserTitle,
  your_projects: YourProjectsTitle,
  help: HelpTitle,
  focus_groups_voting: FocusGroupsVotingTitle,
  password_reset: PasswordResetTitle,
  about: AboutHeaderTitle,
  notifications: NotificationsTitle,
  legal: LegalHeaderTitle,
  goodbye_forums: Deprecations.ForumsHeaderTitle,
  goodbye_pms: Deprecations.PMsHeaderTitle,
  verify_email: VerifyEmailHeader,
  refer_a_friend: ReferAFriendHeader,
  collaboration_invite: CollaborationInviteHeader,
  product_updates: ProductUpdatesHeader,
  email_automations_unsubscribe: EmailAutomationsUnsubsHeader,
  externalCategories: CategoriesHeaderTitle,
  oto_specific_views: OtOHeaderTitle,
  brand: BrandHeaderTitle,
  brandStudioHome: BrandStudioHomeHeader,
  brandStudioRefer: BrandStudioReferHeader,
  designEditor: DesignEditorHeaderTitle,
  confirm_subscription: ConfirmSubscriptionHeader,
  account_deleted: AccountDeletedHeader,
  account_suspended: AccountSuspendedHeader,
  validate_id: ValidateIdHeader,
};

export const Actions = {
  explore_creatives: ExploreHeaderButtons,
  explore_projects: ExploreHeaderButtons,
  project: ProjectHeaderButtons,
  your_projects: YourProjectsHeaderButtons,
  brand: BrandHeaderButtons,
};

export const Tabs = {
  brand: BrandHeaderTabs,
  brandStudioHome: BrandStudioHomeHeaderTabs,
  brandStudioRefer: BrandStudioHomeHeaderTabs,
  project: ProjectHeaderTabs,
  your_projects: YourProjectsHeaderTabs,
  about: AboutHeaderTabs,
};

const NAVIGATION_CLASSES = {
  project: "small-10 large-8",
  your_projects: "small-9 large-8",
  pap0: "small-10 large-8",
  brand: "small-11 large-8",
  brandStudioHome: "small-11 large-8",
  account: "small-10 large-8",
  externalCategories: "small-11 large-8",
};

const CONTROLS_CLASSES = {
  project: "small-6 large-8",
  your_projects: "small-7 large-8",
  pap0: "small-6 large-8",
  brand: "small-5 large-8",
  brandStudioHome: "small-5 large-8",
  account: "small-6 large-8",
  externalCategories: "small-5 large-8",
};

const MOBILE_COLLAPSE_THRESHOLD = 50;

/**
 * Main header component
 * @author Kuba Siemiątkowski <kuba@crowdspring.com>
 * @author Jing Wang <jing@crowdspring.com>
 */
export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      offset: 0,
    };
  }

  componentDidMount() {
    if (!this.props.header.isCollapsed) {
      window.addEventListener("scroll", this.handleScroll.bind(this), true);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll.bind(this));
  }

  handleScroll() {
    const contentEl = document.getElementById("content_and_footer_wrapper");
    const headerMiddleEl = idx(
      document.getElementsByClassName("header-middle"),
      (_) => _[0]
    );
    const headerTopEl = idx(
      document.getElementsByClassName("header-top"),
      (_) => _[0]
    );

    if (!contentEl || !headerMiddleEl || !headerTopEl) {
      console.warn(
        "Missing one or more of 'content_and_footer_wrapper', 'header-middle' and 'header-top' elements"
      );
      return;
    }

    /* IMPORTANT: the threshold has to be higher than the highest possible collapsable header
       portion (i.e. the heights of `headerMiddleEl` and `headerTopEl` combined) otherwise it can
       lead to flickering under certain circunstances. */
    const threshold =
      MOBILE_COLLAPSE_THRESHOLD +
      headerMiddleEl.offsetHeight +
      headerTopEl.offsetHeight;
    if (contentEl.scrollTop >= threshold && !this.state.collapsed) {
      this.setState({ collapsed: true });
    }
    if (contentEl.scrollTop < threshold && this.state.collapsed) {
      this.setState({ collapsed: false });
    }
  }

  render() {
    const { header } = this.props;
    const headerClasses = classnames("l-header", {
      collapsed: this.state.collapsed,
      "top-only": header.topOnly,
      "pap-header": ["pap0", "pap14", "oto_specific_views"].includes(
        header.content
      ),
      "show-avatar-menu": header.showRightSideNav,
    });

    const HeaderActions = Actions[header.content];
    const TitleComponent = Titles[header.content];
    const CustomTabs = Tabs[header.content];

    const navigationClasses =
      NAVIGATION_CLASSES[header.content] || "small-8 large-8";
    const controlsClasses =
      CONTROLS_CLASSES[header.content] || "small-8 large-8";

    return (
      <header id="app-header" className={headerClasses}>
        <div className="header-top">
          <div className="row">
            {header.showNav && (
              <div className="small-4 columns hide-for-large">
                <span
                  id="nav-trigger"
                  className="nav-trigger"
                  onClick={this.props.toggleLeftSideNav}
                >
                  <Icon
                    icon="nav-menu"
                    extraClasses={classnames("svg-icon-lg", "nav-menu", {
                      active: header.showLeftSideNav,
                    })}
                  />
                </span>
              </div>
            )}

            <Logo />

            <Navigation />

            <HelpDrawerButton
              shouldShow={header.showHelpDrawer}
              helpDrawerVisible={header.helpDrawerVisible}
              toggleHelpDrawer={this.props.toggleHelpDrawer}
            />

            <HeaderProfileInfo />
          </div>
        </div>
        {!!TitleComponent && (
          <div className="header-middle">
            <TitleComponent />
          </div>
        )}
        <div className="header-bottom">
          <div className="row">
            <div
              className={classnames(
                "column header-tabs-container",
                navigationClasses
              )}
            >
              <div className="header-navigation">
                {CustomTabs ? <CustomTabs /> : <HeaderTabs />}
              </div>
            </div>
            <div className={classnames("column", controlsClasses)}>
              <div className="header-controls">
                {!!HeaderActions && <HeaderActions />}
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

/**
 * PropTypes.
 *
 * @property {func} toggleHelpDrawer - function to show/hide help drawer
 * @property {func} toggleLeftSideNav - function to show/hide left side nav
 * @property {object} header - header state set from header reducer
 *
 */
Header.propTypes = {
  toggleHelpDrawer: PropTypes.func,
  toggleLeftSideNav: PropTypes.func,
  header: PropTypes.object,
};
