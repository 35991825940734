import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Money from "common/components/money";

const ItemizedCost = ({ items, className, label, title }) => {
  const total = items.reduce((acc, item) => acc + item.value, 0);

  return (
    <div className={classnames("itemized-cost", className)}>
      {!!title && <h5>{title}</h5>}
      <label>{label || "Itemized cost"}</label>
      {items.map((item, i) => (
        <div key={i}>
          <Money value={item.value} />
          <span className="descr">{item.description}</span>
        </div>
      ))}
      <h4>
        <Money value={total} />
        <span className="descr">your cost</span>
      </h4>
    </div>
  );
};

ItemizedCost.propTypes = {
  items: PropTypes.array.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
};

export default ItemizedCost;
