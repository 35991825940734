import React, { Component } from "react";
import PropTypes from "prop-types";
import findLastIndex from "lodash/findLastIndex";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import Item from "announcements/components/item";

export default class Announcements extends Component {
  componentDidMount() {
    this.props.fetch();
  }

  render() {
    const { items, onDismiss } = this.props;
    const lastShowingIdx = findLastIndex(items, (i) => i.showing);
    const visibleItem = items[lastShowingIdx];

    return (
      <div className="announcements">
        <ReactCSSTransitionGroup
          transitionName="announcement-transition"
          transitionEnterTimeout={250}
          transitionLeaveTimeout={250}
        >
          {visibleItem && <Item item={visibleItem} onDismiss={onDismiss} />}
        </ReactCSSTransitionGroup>
      </div>
    );
  }
}

Announcements.propTypes = {
  items: PropTypes.array.isRequired,
  fetch: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};
