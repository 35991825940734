import { connect } from "react-redux";
import Logo from "header/components/logo";

export function mapStateToProps({ user }) {
  return {
    isLoggedIn: user.logged_in,
  };
}

export default connect(mapStateToProps)(Logo);
