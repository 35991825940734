import request from "axios";
import moment from "moment";
import cookie from "react-cookie";
import some from "lodash/some";
import isArray from "lodash/isArray";
import sortBy from "lodash/sortBy";
import { setBannerError } from "error";
import { getAwards } from "entries/ducks";
import { TOGGLE as TOGGLE_TOUR } from "common/ducks/tour";

/* ACTIONS
 ================================================================================================ */
const FETCH_WRAPUPS = "project/wrapup/FETCH_WRAPUPS";
const FETCH_WRAPUPS_SUCCESS = "project/wrapup/FETCH_WRAPUPS_SUCCESS";
const FETCH_WRAPUPS_FAILURE = "project/wrapup/FETCH_WRAPUPS_FAILURE";

const FETCH_WRAPUP_BUNDLES = "project/wrapup/FETCH_WRAPUP_BUNDLES";
const FETCH_WRAPUP_BUNDLES_SUCCESS =
  "project/wrapup/FETCH_WRAPUP_BUNDLES_SUCCESS";

const TOGGLE_BUNDLES_MODAL = "project/wrapup/TOGGLE_BUNDLES_MODAL";

const TOGGLE_APPROVE_MODAL = "project/wrapup/TOGGLE_APPROVE_MODAL";

const SET_TOUR_STEP = "project/wrapup/SET_TOUR_STEP";

/* INITIAL STATE
 ================================================================================================ */
const initialState = {
  wrapups: null,
  projectId: null,
  showingBundlesModal: false,
  showingApproveModal: false,
  tourStep: null,
  tourSubstep: null,
};

/* HELPERS
 ================================================================================================ */
export const getWrapups = (state) => {
  const awards = getAwards(state, false);

  /* determine wrap-up position based on award position */
  return sortBy(
    (state.project.wrapup.wrapups || []).map((w, i) => {
      const awardIdx = awards.findIndex((a) => a.uuid === w.award.uuid);

      return {
        ...w,
        award: {
          ...w.award,
          position: (awardIdx > -1 ? awardIdx : i) + 1,
        },
      };
    }),
    (w) => w.award.position
  );
};

export const getCurrentWrapup = (state, awardId) =>
  getWrapups(state).find((w) => w.award.id == awardId);

export const getHideTour = (wrapup) =>
  cookie.load(`hide_wrapup_tour_${wrapup.award.id}`);

export const setHideTour = (wrapup) => {
  cookie.save(`hide_wrapup_tour_${wrapup.award.id}`, true, {
    path: "/",
    expires: moment("2100-12-12").toDate(),
  });
};

export const isCollaborator = (wrapup, user) =>
  /* if not the client, the creative or an admin, then user must be a collaborator */
  !user.is_staff && ![wrapup.client.id, wrapup.creative.id].includes(user.id);

export const isFeedbackSubmitted = (
  {
    creative,
    client_survey_submitted_at,
    creative_survey_submitted_at,
    cs_survey_submitted_at,
    creative_testimonial_submitted_at,
  },
  user
) => {
  const isCreative = user.id === creative.id;
  return isCreative
    ? !!client_survey_submitted_at
    : some([
        creative_survey_submitted_at,
        cs_survey_submitted_at,
        creative_testimonial_submitted_at,
      ]);
};

export const advanceRequest = (awardId) =>
  request.get(`/api/v1/wrapups/${awardId}/advance/`);

export const createBundleRequest = (awardId) =>
  request.get(`/api/v1/wrapups/${awardId}/create_bundle/`);

export const uploadFilesRequest = (awardId, data) =>
  request.post(`/api/v1/wrapups/${awardId}/upload_files/`, data);

export const submitUserSurveyRequest = (awardId, data) =>
  request.post(`/api/v1/wrapups/${awardId}/survey_user/`, data);

export const submitCsSurveyRequest = (awardId, data) =>
  request.post(`/api/v1/wrapups/${awardId}/survey_crowdspring/`, data);

export const createTestimonialRequest = (awardId, data) =>
  request.post(`/api/v1/wrapups/${awardId}/create_testimonial/`, data);

export const signContractRequest = (awardId, signatureData) =>
  request.post(`/api/v1/wrapups/${awardId}/sign_contract/`, signatureData);

export const createSolicitationRequest = (awardId, data) =>
  request.put(`/api/v1/wrapups/${awardId}/solicitation/`, data);

/* Format bundle in a more convenient shape */
const formatBundle = (data) => {
  if (!data) {
    return null;
  }

  const id = Object.keys(data)[0];
  const bundle = Object.values(data)[0];

  const files = {};
  Object.keys(bundle).map((k) => {
    if (!["comment", "zip", "last_updated"].includes(k)) {
      files[k] = bundle[k];
    }
  });

  return {
    id,
    files,
    zip: bundle.zip,
    last_updated: bundle.last_updated,
    hasFiles: Object.keys(files).length > 0 && bundle.zip,
  };
};

/* Augment wrapup object identified by `awardId` with its bundles. */
const augmentWithBundles = (wrapups, awardId, bundles) =>
  wrapups.map((wrapup) => {
    if (wrapup.award.id !== awardId) {
      return wrapup;
    }

    /* make sure we have a list of final bundles (old wrap-ups have a single item) */
    const finalBundles = isArray(bundles.final)
      ? bundles.final
      : [bundles.final];

    return {
      ...wrapup,
      /* only bundles with files */
      proof_bundles: bundles.proofs
        .map(formatBundle)
        .filter((b) => b && b.hasFiles),
      final_bundles: finalBundles
        .map(formatBundle)
        .filter((b) => b && b.hasFiles),
    };
  });

/* Format wrapup in a more convenient shape */
const formatWrapup = (wrapup) => {
  /* 'sign' step is combined with subsequent one in the frontend */
  const steps = wrapup.process.filter((s) => s !== "sign");
  const stepIdx = Math.max(wrapup.step - 1, 0);

  return {
    can_attach_after_completed: wrapup.can_attach_after_completed,
    project_id: wrapup.project_id,
    project_url: wrapup.project_url,
    award: {
      id: wrapup.award_id,
      uuid: wrapup.award_uuid,
      value: wrapup.award_value,
      type: wrapup.award_type,
      created_at: wrapup.award_created_at,
    },
    steps: steps,
    stepIdx: stepIdx,
    backendSteps: wrapup.process,
    completion: stepIdx / steps.length,
    client: {
      id: wrapup.client_id,
      username: wrapup.client_username,
      avatar: wrapup.client_avatar,
    },
    creative: {
      id: wrapup.creative_id,
      username: wrapup.creative_username,
      avatar: wrapup.creative_avatar,
    },
    contract: {
      creative: wrapup.creative_id,
      creative_signature_created_at: wrapup.creative_signed_at,
      client: wrapup.client_id,
      client_signature_created_at: wrapup.client_signed_at,
      pdf: wrapup.contract_pdf,
      pdf_signed: wrapup.contract_signed,
    },
    deadline: wrapup.deadline,
    deadline_before_admin_extended: wrapup.deadline_before_admin_extended,
    entry: {
      id: wrapup.entry_id,
      entry_type: wrapup.entry_type,
      entry_number: wrapup.entry_number,
      thumbnail: wrapup.entry_thumbnails ? wrapup.entry_thumbnails[0] : null,
      name: wrapup.entry_name,
    },
    proof_message_stream: wrapup.proof_message_stream,
    final_message_stream: wrapup.final_message_stream,
    name_message_stream: wrapup.name_message_stream,
    post_wrapup_message_stream: wrapup.post_wrapup_message_stream,
    proofs_approved_at: wrapup.proofs_approved_at,
    final_approved_at: wrapup.final_approved_at,
    name_approved_at: wrapup.name_approved_at,
    client_survey_submitted_at: wrapup.client_survey_submitted_at,
    creative_survey_submitted_at: wrapup.creative_survey_submitted_at,
    cs_survey_submitted_at: wrapup.cs_survey_submitted_at,
    creative_testimonial_submitted_at: wrapup.creative_testimonial_submitted_at,
    proof_bundles: [],
    final_bundles: [],
  };
};

/* REDUCERS
 ================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_WRAPUPS_SUCCESS:
      return {
        ...state,
        projectId: action.projectId,
        wrapups: action.payload.map(formatWrapup),
      };
    case FETCH_WRAPUPS_FAILURE:
      return {
        ...state,
        projectId: null,
        wrapups: null,
      };
    case FETCH_WRAPUP_BUNDLES_SUCCESS:
      return {
        ...state,
        wrapups: augmentWithBundles(
          state.wrapups,
          action.awardId,
          action.payload
        ),
      };
    case TOGGLE_BUNDLES_MODAL:
      return {
        ...state,
        showingBundlesModal: !state.showingBundlesModal,
      };
    case TOGGLE_APPROVE_MODAL:
      return {
        ...state,
        showingApproveModal: !state.showingApproveModal,
      };
    case SET_TOUR_STEP:
      return {
        ...state,
        tourStep: action.step,
        tourSubstep: action.substep,
      };
    case TOGGLE_TOUR:
      return {
        ...state,
        tourStep: action.id ? state.tourStep : null,
        tourSubstep: action.id ? state.tourSubstep : null,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
const fetchBundles = (awardId) => (dispatch) =>
  dispatch({
    type: FETCH_WRAPUP_BUNDLES,
    promise: request({
      method: "get",
      url: `/api/v1/wrapups/${awardId}/current_bundles/`,
    }),
    awardId,
  });

export const fetchWrapupsIfNeeded =
  (projectId, force) => (dispatch, getState) => {
    const {
      project: { wrapup },
    } = getState();

    if (!wrapup.projectId || wrapup.projectId !== projectId || force) {
      const promise = request({
        method: "get",
        url: `/api/v1/wrapups/?project=${projectId}`,
      });

      /* fetch bundles for each of the wrapups */
      promise.then(({ data }) =>
        data.forEach((w) => dispatch(fetchBundles(w.award_id)))
      );

      dispatch({
        type: FETCH_WRAPUPS,
        projectId: projectId,
        promise,
      });

      return promise;
    }

    return Promise.resolve({ data: wrapup.wrapups });
  };

export const deleteBundle =
  (wrapup, bundleId, backendStepIdx, onDone) => (dispatch) => {
    const promise = request.patch(
      `/api/v1/wrapups/${wrapup.award.id}/delete_bundle/`,
      {
        bundle_id: bundleId,
        step: backendStepIdx,
      }
    );

    promise
      .then(() => {
        dispatch(fetchBundles(wrapup.award.id));
        onDone();
      })
      .catch(() => {
        dispatch(setBannerError("Error deleting ZIP", "Please try again"));
      });

    return promise;
  };

export const toggleBundlesModal = () => ({
  type: TOGGLE_BUNDLES_MODAL,
});

export const toggleApproveModal = () => ({
  type: TOGGLE_APPROVE_MODAL,
});

export const setTourStep = (step, substep = null) => ({
  type: SET_TOUR_STEP,
  step,
  substep,
});
