import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export default class Question extends Component {
  constructor(props) {
    super(props);
    this.toggleQuestion = this.toggleQuestion.bind(this);
    this.state = { show: false };
  }

  toggleQuestion(e) {
    e.preventDefault();
    this.setState({ show: !this.state.show });
  }

  render() {
    const { question, answer } = this.props;
    const { show } = this.state;
    const classes = classnames("question", { show, hidden: !show });
    return (
      <div
        className={classes}
        itemScope={true}
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
      >
        <button onClick={this.toggleQuestion}>
          <h3 itemProp="name" className="question-title">
            {question}
          </h3>
          <i className="fa fa-plus" />
          <i className="fa fa-minus" />
        </button>
        <div
          itemScope={true}
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
        >
          <div
            itemProp="text"
            className={classnames("answer", { "faq-show": show })}
            dangerouslySetInnerHTML={{ __html: answer }}
          />
        </div>
      </div>
    );
  }
}

Question.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
};
