import request from "axios";
import { TESTIMONIALS_RESOURCE_NAME } from "profile/constants";
import { getAPIQueryString } from "common/utils";
import { savePageCount } from "common/pager/ducks";

/* ACTIONS
================================================================================================ */
const FETCH_ALL = "profile/testimonials/FETCH_ALL";
const FETCH_ALL_REQUEST = "profile/testimonials/FETCH_ALL_REQUEST";
const FETCH_ALL_SUCCESS = "profile/testimonials/FETCH_ALL_SUCCESS";

const FETCH_APPROVED_COUNT = "profile/testimonials/FETCH_APPROVED_COUNT";
const FETCH_APPROVED_COUNT_SUCCESS =
  "profile/testimonials/FETCH_APPROVED_COUNT_SUCCESS";

const APPROVE = "profile/testimonials/APPROVE";
const APPROVE_SUCCESS = "profile/testimonials/APPROVE_SUCCESS";

const REJECT = "profile/testimonials/REJECT";
const REJECT_SUCCESS = "profile/testimonials/REJECT_SUCCESS";

/* HELPERS
================================================================================================ */

/* INITIAL STATES
================================================================================================ */
const initialState = {
  list: [],
  isFetching: false,
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_ALL_SUCCESS:
      return {
        ...state,
        list: action.payload.results,
        count: action.payload.count,
        isFetching: false,
      };
    case FETCH_APPROVED_COUNT_SUCCESS:
      return {
        ...state,
        approvedCount: action.payload.count,
      };
    case APPROVE_SUCCESS:
      return {
        ...state,
        list: state.list.map((t) =>
          t.id !== action.payload.id ? t : { ...t, approved: true }
        ),
      };
    case REJECT_SUCCESS:
      return {
        ...state,
        list: state.list.map((t) =>
          t.id !== action.payload.id ? t : { ...t, approved: false }
        ),
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const fetchAll = (query) => (dispatch, getState) => {
  const { profile } = getState().profile.common;
  const queryStr = getAPIQueryString(TESTIMONIALS_RESOURCE_NAME, query);
  const promise = request.get(
    `/api/v1/users/${profile.id}/testimonials/?${queryStr}`
  );

  /* save page count in pager state */
  promise.then((res) =>
    dispatch(savePageCount(TESTIMONIALS_RESOURCE_NAME, res))
  );

  return dispatch({
    type: FETCH_ALL,
    promise,
  });
};

export const fetchApprovedCount = () => (dispatch, getState) => {
  const { profile } = getState().profile.common;

  return dispatch({
    type: FETCH_APPROVED_COUNT,
    promise: request.get(
      `/api/v1/users/${profile.id}/testimonials/?approved=true`
    ),
  });
};

export const approve = (id) => (dispatch, getState) => {
  const { profile } = getState().profile.common;

  const promise = request.put(
    `/api/v1/users/${profile.id}/testimonials/${id}/approve/`
  );

  promise.then(() => dispatch(fetchApprovedCount()));

  return dispatch({
    type: APPROVE,
    id,
    promise,
  });
};

export const reject = (id) => (dispatch, getState) => {
  const { profile } = getState().profile.common;

  const promise = request.put(
    `/api/v1/users/${profile.id}/testimonials/${id}/reject/`
  );

  promise.then(() => dispatch(fetchApprovedCount()));

  return dispatch({
    type: REJECT,
    id,
    promise,
  });
};
