import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { isCollaborator } from "project/utils";
import FaqList from "common/components/faqs";

const CLIENT_FAQS = [
  {
    question: "How does the Finalist Round work?",
    answer:
      "<p>The Finalist Round gives you an extra 3 days to work exclusively with up to 5 of your favorite designers. Designers can tweak their prior designs or submit new designs in the Finalist Round. You can provide feedback and score, just as you have been doing throughout the project.</p><p>The winner will receive 80% of the award and all finalists (including the winner), will split the remaining 20%. This creates a strong incentive for all finalists to do great work.</p> After the Finalist Round ends, you will be able to make offers on any non-winning design in your project (including designs submitted before the Finalist Round). And, you will be able to tip any designer to thank them for their hard work in the project.",
  },
  {
    question: "How do I end the Finalist Round?",
    answer:
      "The Finalist Round ends automatically 3 days after you start it. If you need more time, you will be able to extend the Finalist Round by an additional 2 days. And, if you feel you still need more time or you want to end the Finalist Round early, just <a href='/help/contact/' target='_blank'>contact our team</a>.",
  },
  {
    question: "Can I award multiple designs?",
    answer:
      "After the Finalist Round starts, you will not be able to increase your award or add new awards. But, after you award the project, you can make offers to purchase other designs created by any designer in your project, including the finalists.",
  },
];

const CREATIVE_FAQS = [
  {
    question: "How does the Finalist Round work?",
    answer:
      "The Finalist Round gives the client an extra 3 days to work exclusively with up to 5 of their favorite designers. Designers can tweak their prior designs or submit new designs in the Finalist Round.",
  },
  {
    question: "Are all finalists paid?",
    answer:
      "As a finalist, you are guaranteed to be paid. The winner will receive 80% of the award and all finalists (including the winner), will split the remaining 20% as guaranteed tips. Guaranteed tips for the project are available for withdrawal after the wrap-up in the project is completed.",
  },
  {
    question: "Can a client buy my design?",
    answer:
      "Yes! After the Finalist Round ends, the client will be able to make offers on any non-winning design in the project (including designs submitted before the Finalist Round).",
  },
];

const FinalRoundFaqs = ({ project, user, className }) => {
  let faqs, moreUrl;
  if (
    !project ||
    !user.id ||
    !["finalist_round", "finalist_closed"].includes(project.status)
  ) {
    faqs = null;
  } else if (project.client === user.id || isCollaborator(project)) {
    faqs = CLIENT_FAQS;
    moreUrl = "/help/managing-project/";
  } else if (project.finalist_ids.includes(user.id)) {
    faqs = CREATIVE_FAQS;
    moreUrl = "/help/working-in-projects/";
  }

  if (faqs) {
    return (
      <div className={classnames("final-round-faqs", className)}>
        <h3>Finalist Round FAQs</h3>
        <FaqList showMore={true} data={faqs} moreUrl={moreUrl} />
      </div>
    );
  }

  return null;
};

FinalRoundFaqs.propTypes = {
  project: PropTypes.object,
  user: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default FinalRoundFaqs;
