import React, { Component } from "react";
import PropTypes from "prop-types";
import ProgressBar from "project/components/wrapup/progress_bar";
import Money from "common/components/money";

/**
 * Wrapup tab sub-item.
 *
 * @author Nathan Ozelim <nathan@startupfoundry.com>
 */
const TabSubItem = ({ wrapup }) => {
  return (
    <div className="wrapup-tab-subitem">
      <div className="row collapse">
        <div className="small-3 columns wrapup-image hide-for-small-only">
          {wrapup.entry.entry_type === "image" ? (
            <img src={wrapup.entry.thumbnail} />
          ) : (
            <div className="entry-name">{wrapup.entry.name}</div>
          )}
        </div>
        <div className="small-10 columns wrapup-title">
          Award {wrapup.award.position}{" "}
          <span className="award-value">
            (<Money value={wrapup.award.value} showDecimals={false} />)
          </span>
        </div>
      </div>
    </div>
  );
};

export default TabSubItem;
