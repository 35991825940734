import React from "react";
import { ADMIN_ROOT } from "common/variables";

export const CAMPAIGNS_RESOURCE_NAME = "campaigns";

export const GROUPS_RESOURCE_NAME = "campaign_groups";

export const GOALS_PER_CAMPAIGN = 2;

export const GOAL_OPTIONS = [
  { field: "respond", label: "Domain responds" },
  { field: "publish_link", label: "Domain publishes our link" },
];

export const ASSIGNEES = [
  {
    field: "Erika",
    showAnalytics: true,
    color: "#BFEB46",
    joined: "2023-06-04",
  },
  { field: "Irvin", showAnalytics: true, color: "#566B61", left: "2023-09-08" },
  { field: "Jason" },
  { field: "Mary", showAnalytics: true, color: "#601282" },
  {
    field: "Rommel",
    showAnalytics: true,
    color: "#65566B",
    joined: "2023-06-04",
  },
  { field: "Ross" },
  {
    field: "Trisha",
    showAnalytics: true,
    color: "#EBB546",
    left: "2023-05-12",
  },
].map((a) => ({ ...a, label: a.field }));

export const GROUPS_PER_CAMPAIGN = 5;

export const BLOCKED_DOMAINS_RESOURCE_NAME = "blocked_domains";

export const BLOCKED_REASONS_RESOURCE_NAME = "blocked_reasons";

export const DOMAIN_LISTS_RESOURCE_NAME = "domain_lists";

export const DOMAIN_LISTS_URL = `${ADMIN_ROOT}/wasp/domain-lists/`;

export const SAVED_DOMAINS_RESOURCE_NAME = "saved_domains";

export const DOMAINS_RESOURCE_NAME = "domains";

const DOMAIN_CSV_FIELDS = [
  "anchor",
  "company_industry",
  "company_location",
  "company_name",
  "company_size",
  "domain_rating",
  "domain_traffic",
  "first_seen",
  "language",
  "last_seen",
  "page_title",
  "page_traffic",
  "page_url",
  "referring_domains",
  "target_url",
  "url",
  "url_rating",
];

export const DOMAIN_UPLOAD_DIRECTIONS = (
  <p>Valid fields are: {DOMAIN_CSV_FIELDS.join(", ")}.</p>
);

export const PROSPECT_EMAIL_STATUS_OPTS = [
  { field: "verified", label: "Verified" },
  { field: "not_verified", label: "Not verified" },
];

export const PROSPECT_SOURCE_OPTS = [
  { field: "snovio", label: "Snov.io" },
  { field: "hunter", label: "Hunter" },
  { field: "manual", label: "Manual" },
];

export const PERIODIC_TASKS = [
  { name: "Update Wasp counts", description: "We last updated all data" },
  { name: "Send Wasp messages", description: "We sent the most recent emails" },
];

export const BLOCKED_DOMAINS_NOTES_TYPE = "blocked_domains";

export const BLOCKED_REASONS_NOTES_TYPE = "blocked_reasons";

const BLOCKED_DOMAIN_CSV_FIELDS = ["domain_rating", "url"];

export const BLOCKED_DOMAIN_UPLOAD_DIRECTIONS = (
  <p>Valid fields are: {BLOCKED_DOMAIN_CSV_FIELDS.join(", ")}.</p>
);

export const BLOCKED_PAGE_SIZE = 10;

export const CAMPAIGN_BLOCKED_DOMAINS_RESOURCE_NAME =
  "campaign_blocked_domains";

const CAMPAIGN_BLOCKED_DOMAIN_CSV_FIELDS = ["url"];

export const CAMPAIGN_BLOCKED_DOMAIN_UPLOAD_DIRECTIONS = (
  <p>Valid fields are: {CAMPAIGN_BLOCKED_DOMAIN_CSV_FIELDS.join(", ")}.</p>
);

export const CAMPAIGN_EXCLUDED_DOMAINS_RESOURCE_NAME =
  "campaign_excluded_domains";

export const ANALYTICS_API_ENDPOINT = "/api/v1/campaigns/analytics/";

export const HIVE_ANCHOR_DOMAINS = [
  { field: "any", label: "All domains" },
  { field: "cs", label: "Only domains pointing to crowdspring.com" },
  { field: "bnz", label: "Only domains pointing to businessnamezone.com" },
];
