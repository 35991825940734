import NotificationTypes from "notifications/containers/types";

const MAPPING = {
  0: NotificationTypes.Invited,
  1: NotificationTypes.ProjectMatchesSpecialization,
  2: NotificationTypes.EntryScored,
  3: NotificationTypes.ClientCommentedEntry,
  4: NotificationTypes.EntryDeleted,
  5: NotificationTypes.RemovedFromProject,
  6: NotificationTypes.AwardedEntry,
  7: NotificationTypes.ClientUpdatedBrief,
  8: NotificationTypes.WatchedProjectEnding,
  9: NotificationTypes.WatchedProjectEnds,
  10: NotificationTypes.WatchedProjectAwarded,
  11: NotificationTypes.PaymentReceived,
  12: NotificationTypes.TipReceived,
  13: NotificationTypes.ApprovedNdaRequest,
  14: NotificationTypes.DeniedNdaRequest,
  15: NotificationTypes.ReceivedNdaRequest,
  16: NotificationTypes.ReceivedAutoApprovedNdaRequest,
  17: NotificationTypes.CreativeCommentedEntry,
  18: NotificationTypes.InvitedToCollaborate,
  19: NotificationTypes.UninvitedToCollaborate,
  20: NotificationTypes.AcceptedCollaborationInvite,
  21: NotificationTypes.CollaborationPermissionsUpdated,
  22: NotificationTypes.WrapupNudge,
  23: NotificationTypes.OneToOneClientRequestedQuote,
  24: NotificationTypes.OneToOneClientSentOffer,
  25: NotificationTypes.OneToOneClientSentCounterOffer,
  26: NotificationTypes.OneToOneClientDeclinedQuote,
  27: NotificationTypes.OneToOneClientAcceptedQuote,
  28: NotificationTypes.OneToOneCreativeSentQuote,
  29: NotificationTypes.OneToOneCreativeSentCounterQuote,
  30: NotificationTypes.OneToOneCreativeDeclinedOffer,
  31: NotificationTypes.OneToOneCreativeAcceptedOffer,
  32: NotificationTypes.SentEmailValidation,
  33: NotificationTypes.RequestedPasswordReset,
  34: NotificationTypes.RegistrationWelcome,
  35: NotificationTypes.CreativeSubmittedEntry,
  36: NotificationTypes.CreativeSubmittedRevision,
  39: NotificationTypes.WrapupStarted,
  40: NotificationTypes.WrapupClientSigned,
  41: NotificationTypes.WrapupCreativeSigned,
  42: NotificationTypes.WrapupProofsAdded,
  43: NotificationTypes.WrapupProofsApproved,
  44: NotificationTypes.WrapupFilesAdded,
  45: NotificationTypes.WrapupFilesApproved,
  46: NotificationTypes.WrapupNameApproved,
  47: NotificationTypes.WrapupSurveyEncourageCreative,
  48: NotificationTypes.WrapupSurveyEncourageClient,
  49: NotificationTypes.WrapupDue,
  50: NotificationTypes.MyProjectEndsIn48h,
  51: NotificationTypes.MyProjectEndsIn24h,
  52: NotificationTypes.MyProjectEndsIn4h,
  53: NotificationTypes.WorkSamplesApproved,
  54: NotificationTypes.WorkSamplesDenied,
  55: NotificationTypes.ParticipatingProjectEnding,
  56: NotificationTypes.ParticipatingProjectEnds,
  57: NotificationTypes.AccountDeleted,
  58: NotificationTypes.EntryOfferNegotiation,
  59: NotificationTypes.EntryOfferNegotiation,
  60: NotificationTypes.EntryOfferNegotiation,
  61: NotificationTypes.EntryOfferNegotiation,
  62: NotificationTypes.EntryOfferNegotiation,
  63: NotificationTypes.EntryOfferNegotiation,
  64: NotificationTypes.EntryOfferNegotiation,
  65: NotificationTypes.CreativeWasPaidOffer,
  69: NotificationTypes.ProjectPostedInWatchedSubcat,
  70: NotificationTypes.WrapupMessage,
  71: NotificationTypes.WrapupMessage,
  72: NotificationTypes.WrapupMessage,
  73: NotificationTypes.ProjectMessage,
  74: NotificationTypes.ParticipatedProjectAwarded,
  82: NotificationTypes.CollabCommentedEntry,
  85: NotificationTypes.PresentationFeedbackReceived,
  86: NotificationTypes.ProjectMessage,
  87: NotificationTypes.SelectedForEliteProject,
  88: NotificationTypes.RankedTipReceived,
  89: NotificationTypes.MoreNamesNeededAt80Pct,
  90: NotificationTypes.MoreNamesNeededAt50Pct,
  91: NotificationTypes.ProjectMessage,
  97: NotificationTypes.CreativeRegistrationProcessed,
  98: NotificationTypes.MoreLogoEntriesNeeded,
  99: NotificationTypes.QualityReviewWarning,
  100: NotificationTypes.QualityReviewDeactivate,
  101: NotificationTypes.QualityReviewGroupsRevoked,
  106: NotificationTypes.FinalRoundLaunchedParticipant,
  107: NotificationTypes.FinalRoundLaunchedFinalist,
  108: NotificationTypes.FinalRoundExtended,
  109: NotificationTypes.FinalRoundEndedFinalist,
  110: NotificationTypes.FinalRoundEndedClient,
  111: NotificationTypes.OneToOneNotSelected,
  112: NotificationTypes.NegotiationMessage,
  113: NotificationTypes.NegotiationMessage,
  114: NotificationTypes.NegotiationMessage,
  115: NotificationTypes.OneToOnePaid,
  116: NotificationTypes.TestimonialAdded,
  118: NotificationTypes.BSTemplatesUnlocked,
  119: NotificationTypes.BSReferAFriendPromotion,
};

export default MAPPING;
