/**
import PropTypes from 'prop-types';
 * Redux combined reducer for project page
 * @author Kuba Siemiątkowski <kuba@crowdspring.com>
 */

import { combineReducers } from "redux";
import ProjectPageReducer from "project/ducks/project";
import AwardsReducer from "project/ducks/awards";
import FeaturesReducer from "project/ducks/features";
import CollaboratorsReducer from "project/ducks/collaborators";
import PresentationReducer from "presentations/ducks";
import WrapupReducer from "project/ducks/wrapup";
import NDAsReducer from "project/ducks/ndas";
import CreativesReducer from "project/ducks/creatives";
import EliteReducer from "project/ducks/elite";
import EntrySubmissionReducer from "project/ducks/entry_submission";

const RootReducer = combineReducers({
  projectPage: ProjectPageReducer,
  awards: AwardsReducer,
  features: FeaturesReducer,
  collaborators: CollaboratorsReducer,
  presentations: PresentationReducer,
  wrapup: WrapupReducer,
  ndas: NDAsReducer,
  creatives: CreativesReducer,
  elite: EliteReducer,
  entrySubmission: EntrySubmissionReducer,
});

export default RootReducer;
