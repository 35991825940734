import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import HeaderButtons from "explore/components/header_buttons";
import { toggleRightDrawer } from "header/ducks";

export function mapStateToProps() {
  return {};
}

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleRightDrawer,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderButtons);
