import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toggleHelpDrawer, toggleLeftSideNav } from "header/ducks";
import Header from "header/components";
import { bindActionCreators } from "redux";

export function mapStateToProps({ header }) {
  return {
    header,
  };
}

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleHelpDrawer,
      toggleLeftSideNav,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
