import React from "react";
import PropTypes from "prop-types";
import TabbedNavigation from "common/components/tabbed_navigation";

const UserTypeTabs = ({ user, params, router, push, dropdownOnly }) => {
  if (!(user.is_client && user.is_creative)) {
    return null;
  }

  return (
    <TabbedNavigation
      tabs={{
        links: [
          {
            url: "/your-projects/client/",
            name: "As client",
          },
          {
            url: "/your-projects/creative/",
            name: "As creative",
          },
        ],
      }}
      router={router}
      push={push}
      dropdownOnly={dropdownOnly}
    />
  );
};

export default UserTypeTabs;
