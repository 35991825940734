import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import HeaderTitle from "header/components/title";
import Breadcrumbs from "marketing/components/breadcrumbs";
import Attribute from "marketing/common/attribute";

const CategoriesHeaderTitle = () => {
  return (
    <section className="block block-hero hero-1 hide-for-small-only">
      <div className="row">
        <div className="columns small-16 medium-11 large-8">
          <Breadcrumbs items={[{ title: "Categories", url: "/categories/" }]} />
          <div className="hero-container">
            <h1 className="hero-title">
              Whatever you need, we&apos;ve&nbsp;got&nbsp;it.
            </h1>
            <h2 className="hero-subtitle">
              We have 33 categories to handle your creative needs but, if you
              can’t find&nbsp;something that fits, talk&nbsp;to
              one&nbsp;of&nbsp;our&nbsp;experts.
            </h2>
            <div className="hero-actions">
              <Link to="/help/contact/consultation/" className="btn btn-text">
                Free design consultation{" "}
                <i className="fa fa-long-arrow-right" />
              </Link>
            </div>
          </div>
        </div>
        <div className="columns small-16 medium-5 large-8">
          <div className="artwork-container">
            <img
              alt="package graphics design for State Trooper Stache Wax by badaboom"
              className="hero-art"
              src="https://assets.crowdspring.com/marketing/crowdspring_marketing_categories_hero-1_stache-wax.png"
            />
            <Attribute to="badaboom" text="design by" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CategoriesHeaderTitle;
