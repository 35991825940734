import request from "axios";

export const SEARCH = "crowdspring/help/SEARCH";
export const SEARCH_REQUEST = "crowdspring/help/SEARCH_REQUEST";
export const SEARCH_FAILURE = "crowdspring/help/SEARCH_FAILURE";
export const SEARCH_SUCCESS = "crowdspring/help/SEARCH_SUCCESS";

export function searchHelp(searchText) {
  const opts = {
    method: "get",
    url: `/api/v1/articles/search/?query=${searchText}`,
  };
  return {
    type: SEARCH,
    promise: request(opts),
    payload: searchText,
  };
}

export const INITIAL_STATE = {
  isLoading: false,
  data: null,
  searchText: "",
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SEARCH:
      return {
        ...state,
        isLoading: true,
      };
    case SEARCH_REQUEST:
      return {
        ...state,
        searchText: action.payload,
        isLoading: true,
        data: null,
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    default:
      return state;
  }
}
