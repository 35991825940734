import request from "axios";
import moment from "moment";
import idx from "idx";
import pick from "lodash/pick";
import isEqual from "lodash/isEqual";
import {
  EMAILS_RESOURCE_NAME,
  SINGLE_EMAIL_FORM_NAME,
} from "email_automations/constants";
import { DATETIME_FORMAT } from "common/validators";
import { getAPIQueryString } from "common/utils";
import { savePageCount } from "common/pager/ducks";
import { showBanner, TYPE_SUCCESS } from "common/ducks/banner";
import { setBannerError } from "error";
import {
  sendTestEmail as sendTestEmailCommon,
  fromQueryPartToBreakdown,
  getQueryPartIdSets,
} from "email_automations/ducks/common";

/* ACTIONS
================================================================================================ */
const FETCH_TOTALS = "crowdspring/honeybee/FETCH_TOTALS";
const FETCH_TOTALS_SUCCESS = "crowdspring/honeybee/FETCH_TOTALS_SUCCESS";

const FETCH_EMAILS = "crowdspring/honeybee/FETCH_EMAILS";
const FETCH_EMAILS_REQUEST = "crowdspring/honeybee/FETCH_EMAILS_REQUEST";
const FETCH_EMAILS_SUCCESS = "crowdspring/honeybee/FETCH_EMAILS_SUCCESS";

const FETCH_EMAIL = "crowdspring/honeybee/FETCH_EMAIL";
const FETCH_EMAIL_REQUEST = "crowdspring/honeybee/FETCH_EMAIL_REQUEST";
const FETCH_EMAIL_SUCCESS = "crowdspring/honeybee/FETCH_EMAIL_SUCCESS";

const SEND_EMAIL = "crowdspring/honeybee/SEND_EMAIL";
const SEND_EMAIL_SUCCESS = "crowdspring/honeybee/SEND_EMAIL_SUCCESS";

const SAVE_EMAIL = "crowdspring/honeybee/SAVE_EMAIL";
const SAVE_EMAIL_REQUEST = "crowdspring/honeybee/SAVE_EMAIL_REQUEST";
const SAVE_EMAIL_SUCCESS = "crowdspring/honeybee/SAVE_EMAIL_SUCCESS";
const SAVE_EMAIL_FAILURE = "crowdspring/honeybee/SAVE_EMAIL_FAILURE";

/* HELPERS
================================================================================================ */
export const createEmailRequest = () =>
  request.post("/api/v1/hb/single_emails/");

export const cloneEmailRequest = (id) =>
  request.post(`/api/v1/hb/single_emails/${id}/clone/`);

export const deleteEmailRequest = (id) =>
  request.delete(`/api/v1/hb/single_emails/${id}/`);

const formatEmail = (payload) => ({
  ...payload,
  recipient_selection_conditions: payload.recipient_selection_conditions.map(
    (c) => ({
      ...c,
      lead_query_parts: c.lead_query_parts.map(fromQueryPartToBreakdown),
    })
  ),
});

/* INITIAL STATES
================================================================================================ */
const initialState = {
  totals: {},
  emails: [],
  emailCount: 0,
  isFetching: false,
  isSavingEmail: false,
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TOTALS_SUCCESS:
      return {
        ...state,
        totals: action.payload,
      };
    case FETCH_EMAIL_REQUEST:
    case FETCH_EMAILS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_EMAILS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        emails: action.payload.results,
        emailCount: action.payload.count,
      };
    case FETCH_EMAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        email: formatEmail(action.payload),
      };
    case SEND_EMAIL_SUCCESS:
      return {
        ...state,
        email: formatEmail(action.payload),
      };
    case SAVE_EMAIL_REQUEST:
      return {
        ...state,
        isSavingEmail: true,
      };
    case SAVE_EMAIL_FAILURE:
    case SAVE_EMAIL_SUCCESS:
      return {
        ...state,
        isSavingEmail: false,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const fetchTotals = () => (dispatch) => {
  return dispatch({
    type: FETCH_TOTALS,
    promise: request.get("/api/v1/hb/single_emails/counts_by_status/"),
  });
};

export const fetchEmails = (query, status) => (dispatch) => {
  const extraAPIQuery = status === "all" ? {} : { status };
  const queryStr = getAPIQueryString(EMAILS_RESOURCE_NAME, query, {
    extraAPIQuery,
  });
  const promise = request.get(`/api/v1/hb/single_emails/?${queryStr}`);

  /* save page count in pager state */
  promise.then((res) => dispatch(savePageCount(EMAILS_RESOURCE_NAME, res)));

  return dispatch({
    type: FETCH_EMAILS,
    promise,
  });
};

export const fetchEmail = (id) => (dispatch) => {
  return dispatch({
    type: FETCH_EMAIL,
    promise: request.get(`/api/v1/hb/single_emails/${id}/`),
  });
};

export const sendEmail = (id) => (dispatch) => {
  const promise = request.patch(`/api/v1/hb/single_emails/${id}/send/`);

  promise.catch((err) => {
    const errorMsg = idx(err, (_) => _.data.message) || "Unknown error";
    dispatch(setBannerError("Error sending single email", errorMsg));
  });

  dispatch({
    type: SEND_EMAIL,
    promise,
  });

  return promise;
};

export const saveEmail =
  (id, willSend = false) =>
  (dispatch, getState) => {
    const state = getState();
    const { leadQueryParts } = state.emailAutomations.common;
    const { email } = state.emailAutomations.honeybee;
    const formData = state.form[SINGLE_EMAIL_FORM_NAME].values || {};

    let recipientEmails = [];
    if (formData.recipients === "list" && formData.recipient_emails) {
      recipientEmails = formData.recipient_emails.split("\n");
    }

    const recipientContactList =
      formData.recipients === "contact_list"
        ? formData.recipient_contact_list
        : null;

    let scheduledFor = null;
    if (
      formData.time === "specific_datetime" &&
      moment(formData.scheduled_for, DATETIME_FORMAT).isValid()
    ) {
      scheduledFor = moment(formData.scheduled_for, DATETIME_FORMAT).format();
    }

    let selectionConditions = [];
    if (formData.recipients === "condition") {
      selectionConditions = undefined;
      if (
        !isEqual(
          email.recipient_selection_conditions,
          formData.recipient_selection_conditions
        )
      ) {
        selectionConditions = getQueryPartIdSets(
          formData.recipient_selection_conditions.map(
            (c) => c.lead_query_parts
          ),
          leadQueryParts
        );
      }
    }

    const data = {
      ...pick(
        formData,
        "name",
        "preheader",
        "subject",
        "from_email",
        "from_name",
        "reply_to",
        "text_content",
        "unsubscribe_strategy",
        "batch_size",
        "goal_1",
        "goal_2",
        "goal_3",
        "goal_4"
      ),
      extra_link_attributes: pick(
        formData,
        "utm_source",
        "utm_medium",
        "utm_campaign"
      ),
      recipient_emails: recipientEmails,
      recipient_contact_list: recipientContactList,
      recipient_contact_list_filters: recipientContactList
        ? JSON.parse(formData.recipient_contact_list_filters)
        : {},
      recipient_selection_conditions: selectionConditions,
      scheduled_for: scheduledFor,
      html_content: idx(formData, (_) => _.html_data.html) || "",
      html_design: idx(formData, (_) => _.html_data.design) || "",
    };

    const promise = request.patch(`/api/v1/hb/single_emails/${id}/`, data);

    promise
      .then(() => {
        /* don't show success banner or re-fetch if saving will be followed by sending */
        if (!willSend) {
          dispatch(
            showBanner(
              "Success",
              "Single email successfully saved.",
              undefined,
              TYPE_SUCCESS,
              null,
              3000
            )
          );
          dispatch(fetchEmail(id));
        }
      })
      .catch((err) => {
        const errorMsg = err.data
          ? JSON.stringify(err.data)
          : "Make sure the data you entered is correct.";
        dispatch(setBannerError("Error saving single email", errorMsg));
      });

    dispatch({
      type: SAVE_EMAIL,
      promise,
    });

    return promise;
  };

export const sendTestEmail =
  (_, { emails, extra_context }) =>
  (dispatch, getState) => {
    const formData = getState().form[SINGLE_EMAIL_FORM_NAME].values || {};
    return dispatch(sendTestEmailCommon(formData, emails, extra_context));
  };
