import React from "react";
import PropTypes from "prop-types";

const HeaderTitle = () => (
  <div className="hide-for-small-only">
    <div className="row header-middle-content pap-middle-content">
      <div className="medium-8 column header-middle-left">
        <h2 className="middle-left-title">Tell us what you need.</h2>
      </div>
      <div className="medium-8 column header-middle-right">
        <h4 className="middle-right-title">Delightfully simple, guaranteed.</h4>
      </div>
    </div>
  </div>
);

export default HeaderTitle;
