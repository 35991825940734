import { connect } from "react-redux";
import { toggleFinalRoundExtension, extendFinalRound } from "entries/ducks";
import { FINAL_ROUND_EXTENSION } from "project/constants";
import ConfirmationDialog from "common/components/modal/confirmation_dialog";

const mapStateToProps = ({ entries }) => {
  const { extendingFinalRound } = entries;

  return {
    dialogVisible: extendingFinalRound,
    dialogHeader: "Extend Finalist Round",
    dialogTitle: "Confirm extension",
    dialogMessage: `Are you sure you want to extend the Finalist Round for ${FINAL_ROUND_EXTENSION} more days?`,
  };
};

export default connect(mapStateToProps, {
  submitAction: extendFinalRound,
  toggleDialog: toggleFinalRoundExtension,
})(ConfirmationDialog);
