import { assureProjectRequest } from "pap/ducks/pricing";
import { getProject, updateProjectLocal } from "project/ducks/project";
import { setBannerError } from "error";

/* ACTION CONSTANTS
================================================================================================ */
const TOGGLE_AWARDS_MODAL = "crowdspring/project/TOGGLE_AWARDS_MODAL";

const TOGGLE_ASSURE_MODAL = "crowdspring/project/TOGGLE_ASSURE_MODAL";

const ASSURE_AWARD = "crowdspring/project/ASSURE_AWARD";

/* INITIAL STATES
================================================================================================ */
export const initialState = {
  showingAwardsModal: false,
  showingAssureModal: false,
};

/* HELPERS
================================================================================================ */
/* list of PAP steps in which awards can be edited */
export const PAP_STEPS = {
  award_options: "Award Options",
};

/* REDUCERS
================================================================================================ */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_AWARDS_MODAL:
      return {
        ...state,
        showingAwardsModal: !state.showingAwardsModal,
      };
    case TOGGLE_ASSURE_MODAL:
      return {
        ...state,
        showingAssureModal: !state.showingAssureModal,
      };
    default:
      return state;
  }
};

export default reducer;

/* ACTION CREATORS
================================================================================================ */
export const toggleAwardsModal = () => (dispatch) =>
  dispatch({
    type: TOGGLE_AWARDS_MODAL,
  });

export const toggleAssureModal = () => (dispatch) =>
  dispatch({
    type: TOGGLE_ASSURE_MODAL,
  });

/* Assure project awards */
export const assureAwards = () => (dispatch, getState) => {
  const project = getProject(getState());
  const promise = assureProjectRequest(true, project.id, project.features);

  /* close modal and update project locally if request succeeds, otherwise show error banner */
  promise
    .then(({ data }) => {
      dispatch(toggleAssureModal());
      dispatch(updateProjectLocal(data));
    })
    .catch(() =>
      dispatch(setBannerError("Error assuring project", "Please try again"))
    );

  return {
    type: ASSURE_AWARD,
    promise,
  };
};
