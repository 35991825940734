import React from "react";
import PropTypes from "prop-types";
import HelpDrawer from "common/containers/help_drawer";

/**
 * Button to toggle help drawer
 * @author Kuba Siemiątkowski <kuba@crowdspring.com>
 */
const HelpDrawerButton = ({
  helpDrawerVisible,
  shouldShow,
  toggleHelpDrawer,
  isLoggedIn,
}) => {
  if (!shouldShow) {
    return null;
  }

  const classes = isLoggedIn
    ? "columns small-4 small-offset-4 medium-8 medium-offset-0 large-15"
    : "columns small-4 small-offset-4 medium-8 medium-offset-0 large-13";

  return (
    <div className={classes}>
      <button
        type="button"
        className="btn btn-text btn-md show-for-small-only btn-help"
        onClick={toggleHelpDrawer}
      >
        {helpDrawerVisible ? "Close" : "Help"}
      </button>
      <HelpDrawer desktop={true} />
    </div>
  );
};

HelpDrawerButton.propTypes = {
  shouldShow: PropTypes.bool,
  helpDrawerVisible: PropTypes.bool,
  toggleHelpDrawer: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool,
};

export default HelpDrawerButton;
