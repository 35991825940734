import { connect } from "react-redux";
import ProfileInfo from "header/components/profile_info";
import { toggleRightSideNav, getHeaderAvatar } from "header/ducks";

export function mapStateToProps(state) {
  return {
    shouldShow: state.header.showUser && state.user.logged_in,
    avatarImage: getHeaderAvatar(state),
    user: state.user.profile_data,
    showRightSideNav: state.header.showRightSideNav,
  };
}

export default connect(mapStateToProps, {
  toggleRightSideNav,
})(ProfileInfo);
