import React from "react";
import PropTypes from "prop-types";
import HeaderTitle from "header/components/title";
import { withRouter } from "react-router";
import reduce from "lodash/reduce";

const TITLES = {
  "about-us": "We're crowdspring. Pleased to meet you.",
  "about-us/team": "Meet our crew.",
  jobs: "Join our crew.",
  press: "The opposite of fake news.",
  releases: "The opposite of fake news.",
  news: "The opposite of fake news.",
  partners: "We want to work with you.",
  "give-back": "Helping non-profits & charities since 2008.",
};

const Title = ({ routes }) => {
  const path = reduce(
    routes,
    (mem, route) => (route.path ? route.path : mem),
    "about-us"
  );
  const title = TITLES[path];
  return <HeaderTitle title={title} isTitleMain={true} />;
};

Title.propTypes = {
  routes: PropTypes.array.isRequired,
};

export default withRouter(Title);
