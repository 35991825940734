/**
 * Tracks a Google Analytics event in production and logs to the console elsewhere.
 *
 * Supports an optional callback, which will be called once the his makes it to GA
 * or after a timeout of 1 second is exceeded.
 */
const trackGoogle = (
  category,
  action,
  label = null,
  value = null,
  callback = null
) => {
  const gaOptions = {
    event_category: category,
  };

  if (label) {
    gaOptions["event_label"] = label;
  }

  if (value) {
    gaOptions["value"] = value;
  }

  if (callback) {
    gaOptions["event_callback"] = createFunctionWithTimeout(function () {
      callback();
    });
  }

  if (process.env.FRONTEND_ENV === "production") {
    if (window.gtag) {
      window.gtag("event", action, gaOptions);
    } else {
      console.log(
        "Google Analytics library not initialised properly when sending the following event: " +
          category +
          " / " +
          action
      );
    }
  } else {
    console.log("Google Analytics event: ", action, gaOptions);
  }
};

const setGoogleDimension = (dimension, value) => {
  if (process.env.FRONTEND_ENV === "production") {
    if (window.gtag) {
      window.gtag("set", { [dimension]: value });
    } else {
      console.log(
        "Google Analytics library not initialised properly when setting the dimension: " +
          dimension +
          " / " +
          value
      );
    }
  } else {
    console.log("Google Analytics dimension: ", dimension, value);
  }
};

export const loadAdroll = () => {
  if (process.env.FRONTEND_ENV !== "production") {
    console.log("Loading Adroll...");
    return;
  }

  if (window.__adroll_loaded) {
    return;
  }

  window.adroll_adv_id = "HMI5TBL3TVBWNDMOKSV7BY";
  window.adroll_pix_id = "IB4DSP5FN5EPHIH4TN4M2X";
  window.adroll_version = "2.0";
  (function (w, d, e, o, a) {
    w.__adroll_loaded = true;
    w.adroll = w.adroll || [];
    w.adroll.f = ["setProperties", "identify", "track"];
    var roundtripUrl =
      "https://s.adroll.com/j/" + w.adroll_adv_id + "/roundtrip.js";
    for (a = 0; a < w.adroll.f.length; a++) {
      w.adroll[w.adroll.f[a]] =
        w.adroll[w.adroll.f[a]] ||
        (function (n) {
          return function () {
            w.adroll.push([n, arguments]);
          };
        })(w.adroll.f[a]);
    }

    e = d.createElement("script");
    o = d.getElementsByTagName("script")[0];
    e.async = 1;
    e.src = roundtripUrl;
    o.parentNode.insertBefore(e, o);
  })(window, document);
  window.adroll.track("pageView");
};

const EVENT_AUDIENCE_MAP = {
  created_draft: "08e820cb",
  "created_draft_business-card-design": "fd8b6ea1",
  "created_draft_logo-design": "dd881e01",
  "created_draft_logo-and-business-card-design": "c7f7e675",
  "created_draft_logo-and-social-media-assets-design": "a9d75cd8",
  "created_draft_letterhead-and-stationery-design": "47f47a56",
  "created_draft_naming-a-business-or-product": "3ad95ba8",
  "created_draft_business-tagline": "9bc0e45f",
  "created_draft_package-graphics": "d9c51395",
  "created_draft_packaging-design": "8ae00a88",
  "created_draft_product-design": "affc52a0",
  "created_draft_website-design": "582b9ba9",

  posted_project: "1df4852c",
  "posted_project_business-card-design": "ca779a48",
  "posted_project_logo-design": "5af96c19",
  "posted_project_logo-and-business-card-design": "64f16180",
  "posted_project_logo-and-social-media-assets-design": "8d0e2a66",
  "posted_project_letterhead-and-stationery-design": "510dca83",
  "posted_project_naming-a-business-or-product": "b6b5a46e",
  "posted_project_business-tagline": "41d74385",
  "posted_project_package-graphics": "0ad88e4f",
  "posted_project_packaging-design": "1aba1bfd",
  "posted_project_product-design": "7410a1dd",
  "posted_project_website-design": "731d1e4f",
};
/* IMPORTANT: `loadAdroll` must be called first */
export const trackAdroll = (opts) => {
  const { eventName, conversionValue, extraData, project } = opts;

  if (!project) {
    console.warn("No project provided for Adroll event. Ignoring...");
    return;
  }

  if (!project.is_atier_country_draft) {
    console.info(
      "Project provided for Adroll event is not from a A-tier country. Ignoring..."
    );
    return;
  }

  if (!EVENT_AUDIENCE_MAP[eventName]) {
    console.warn("No Adroll audience for event:", eventName);
    return;
  }

  if (!window.__adroll) {
    console.log("Adroll event:", opts);
    return;
  }

  try {
    __adroll.record_user({
      adroll_segments: EVENT_AUDIENCE_MAP[eventName],
      adroll_conversion_value_in_dollars: conversionValue,
      adroll_custom_data: extraData,
    });
  } catch (err) {
    console.log("Adroll error:", err);
  }
};

export const trackTapfiliate = (...args) => {
  if (!window.tap) {
    console.log("Tapfiliate event", ...args);
    return;
  }

  window.tap(...args);
};

const trackAdWordsProjectPostedConversion = (projectId, value, callback) => {
  const adWordsOptions = {
    value: value,
    send_to: "AW-1042074590/BRswCLehzH0Q3pfz8AM",
    currency: "USD",
    transaction_id: projectId,
  };

  if (callback) {
    adWordsOptions["event_callback"] = callback;
  }

  if (process.env.FRONTEND_ENV === "production") {
    if (window.gtag) {
      window.gtag("event", "conversion", adWordsOptions);
    } else {
      console.log("Google AdWords tracking not initialised properly");
    }
  } else {
    console.log("Google AdWords event: ", adWordsOptions);
  }
};

export const setAdWordsCustomParameters = (params) => {
  if (!window.gtag) {
    console.log("Google AdWords custom parameters: ", params);
    return;
  }

  window.gtag("event", "page_view", {
    send_to: "AW-1042074590",
    ...params,
  });
};

/**
 * A function that that returns another function, which will call the provided
 * callback if called within 1 second. If that doesn't happen the callback is called
 * anyway.
 */
function createFunctionWithTimeout(callback, opt_timeout) {
  var called = false;
  function fn() {
    if (!called) {
      called = true;
      callback();
    }
  }
  setTimeout(fn, opt_timeout || 1000);
  return fn;
}

export { trackGoogle, setGoogleDimension, trackAdWordsProjectPostedConversion };
