import React, { Component } from "react";
import PropTypes from "prop-types";
import { getTimeLeftLabel } from "common/utils";

export default class DeadlineLabel extends Component {
  componentDidMount() {
    /* force component update every 60s so that the displayed time left is up-to-date */
    this.timer = setInterval(() => this.forceUpdate(), 60000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    const label = getTimeLeftLabel(this.props.deadline, {
      emptyLabel: "No Deadline Specified",
      append: "left",
    });

    return <span className="deadline-label">{label}</span>;
  }
}

DeadlineLabel.propTypes = {
  deadline: PropTypes.string,
};
