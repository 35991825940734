import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import Select from "react-virtualized-select";
import { FormGroup } from "common/components/form_fields";

const TaggingField = (props) => {
  const {
    input,
    label,
    value,
    options,
    clearable = true,
    useInputValue,
    resetValue,
    meta: { touched, error },
  } = props;

  return (
    <FormGroup field={{ ...props, formClasses: "tagging-field" }}>
      {!!label && (
        <label className="form-label" htmlFor={`field-id-${input.name}`}>
          <span className={cn({ "error-msg": !!error })}>{label}</span>
        </label>
      )}
      <div className="select-typable">
        <Select
          {...input}
          onBlur={() => (input.onBlur ? input.onBlur(value) : {})}
          options={options}
          value={useInputValue ? input.value : value}
          simpleValue={true}
          clearable={clearable}
          searchable={true}
          resetValue={resetValue}
        />
        {touched && !!error && <span className="error-msg">{error}</span>}
      </div>
    </FormGroup>
  );
};

TaggingField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array.isRequired,
  clearable: PropTypes.bool,
  useInputValue: PropTypes.bool,
  resetValue: PropTypes.bool,
  meta: PropTypes.object.isRequired,
};

export default TaggingField;
