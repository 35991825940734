import { connect } from "react-redux";
import { RightNavLinks } from "common/components/base_ui/side_navigation";

export function mapStateToProps(state) {
  return {
    links: state.header.topNavigation,
    userProfile: state.user.profile_data,
    unseenNotificationsCount: (state.notifications.data.results || []).filter(
      (n) => !n.seen
    ).length,
  };
}

export default connect(mapStateToProps)(RightNavLinks);
