import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import last from "lodash/last";
import upperFirst from "lodash/upperFirst";
import Breadcrumbs from "common/components/breadcrumbs";
import HeaderTitle from "header/components/title";

const mapStateToProps = ({ brandStudio }, { location, routes, params }) => {
  const { brand } = brandStudio;
  const routeName = last(routes).name;

  let title = "Loading...";
  if (brand) {
    title =
      routeName === "details" ? brand.name : `${brand.name}'s ${routeName}`;
  }

  const bcItems = [
    { title: "Brands", url: "/brand-studio/brands/" },
    {
      title: brand ? brand.name : "Loading...",
      url: `/brand-studio/brand/${params.id}/`,
    },
    { title: upperFirst(routeName), url: location.pathname },
  ];

  return {
    title,
    preTitle: <Breadcrumbs items={bcItems} />,
  };
};

export default withRouter(connect(mapStateToProps)(HeaderTitle));
