import { combineReducers } from "redux";
import commonReducer from "focus_groups/ducks/common";
import votingReducer from "focus_groups/ducks/voting";
import entriesReducer from "focus_groups/ducks/entries";

const RootReducer = combineReducers({
  common: commonReducer,
  voting: votingReducer,
  entries: entriesReducer,
});

export default RootReducer;
