import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const LoadingIndicator = ({ fullScreen = true, alone = false }) => (
  <div
    className={classnames("loading-indicator", {
      "full-screen": fullScreen,
      alone,
    })}
  >
    <div className="loading-indicator-animation"></div>
  </div>
);

const ProgressBar = (props) => {
  const sectionClass = classnames({
    "pap-progress": true,
    "section-details": props.currentSection === 1,
    "section-brief": props.currentSection === 2,
    "section-review": props.currentSection === 3,
    "section-payment": props.currentSection === 4,
    expanded: props.currentStep === 1,
  });

  return (
    <ul className={sectionClass}>
      <li>
        <span className="fa fa-list-ul"></span> Details
      </li>
      <li>
        <span className="fa fa-file-text-o"></span> Brief
      </li>
      <li>
        <span className="fa fa-check-square-o"></span> Review
      </li>
      <li>
        <span className="fa fa-credit-card"></span> Payment
      </li>
    </ul>
  );
};

ProgressBar.propTypes = {
  currentSection: PropTypes.number,
  currentStep: PropTypes.number,
};

export { ProgressBar, LoadingIndicator };
