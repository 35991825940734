import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Icon from "common/components/svg_icon";

/**
 * Simplest modal dialog element that can be reused all across the site.
 *
 * @author Kuba Siemiątkowski <kuba@crowdspring.com>
 */
export default class ModalDialog extends Component {
  renderAction(component, callback, label, classes, disabled) {
    if (component) {
      return component;
    }

    if (callback) {
      return (
        <button className={classes} onClick={callback} disabled={disabled}>
          {label}
        </button>
      );
    }

    return null;
  }

  renderActions() {
    const {
      /* props related to primary action */
      onPrimary,
      primaryComponent,
      primaryLabel,
      primaryDisabled,
      primaryClasses,
      /* props related to secondary action */
      onSecondary,
      secondaryComponent,
      secondaryLabel,
      secondaryDisabled,
      secondaryClasses,
    } = this.props;

    const primary = this.renderAction(
      primaryComponent,
      onPrimary,
      primaryLabel,
      primaryClasses || "btn btn-tertiary btn-block",
      primaryDisabled
    );
    const secondary = this.renderAction(
      secondaryComponent,
      onSecondary,
      secondaryLabel,
      secondaryClasses || "btn btn-text btn-block",
      secondaryDisabled
    );

    if (!(primary || secondary)) {
      return null;
    }

    return (
      <div className="modal-actions">
        {primary}
        {secondary}
      </div>
    );
  }

  render() {
    const {
      children,
      modalOpen,
      dialogClasses,
      contentClasses,
      modalHeader,
      closeAction,
      showClose,
      closeOnBackgroundClick,
    } = this.props;

    const dialogClass = classNames("modal-dialog", dialogClasses || "");
    const contentClass = classNames(
      "modal-content-container",
      contentClasses || ""
    );

    if (!modalOpen) {
      return null;
    }

    return (
      <div className={dialogClass}>
        <div
          className="modal-background"
          onClick={closeOnBackgroundClick ? closeAction : undefined}
        />
        <div className={contentClass}>
          {modalHeader && (
            <div className="modal-header">
              <h5>{modalHeader}</h5>
              {showClose && (
                <button
                  className="btn btn-icon-only close-modal"
                  onClick={closeAction}
                >
                  <Icon icon="close" extraClasses="svg-icon-lg" />
                </button>
              )}
            </div>
          )}

          <div className="modal-content">
            {children}
            {this.renderActions()}
          </div>
        </div>
      </div>
    );
  }
}

ModalDialog.defaultProps = {
  closeOnBackgroundClick: true,
};

ModalDialog.propTypes = {
  children: PropTypes.node,
  modalOpen: PropTypes.bool,
  showClose: PropTypes.bool,
  dialogClasses: PropTypes.string,
  contentClasses: PropTypes.string,
  modalHeader: PropTypes.string,
  closeAction: PropTypes.func,
  onPrimary: PropTypes.func,
  primaryLabel: PropTypes.string,
  primaryDisabled: PropTypes.bool,
  primaryClasses: PropTypes.string,
  primaryComponent: PropTypes.node,
  onSecondary: PropTypes.func,
  secondaryLabel: PropTypes.string,
  secondaryDisabled: PropTypes.bool,
  secondaryClasses: PropTypes.string,
  secondaryComponent: PropTypes.node,
  closeOnBackgroundClick: PropTypes.bool,
};
