import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import SorterSelect from "common/sorter/components/select";
import Filter from "common/filter/containers";
import ViewTypeSelector from "common/components/entry_gallery/view_type_selector";
import GroupingSelector from "common/components/entry_gallery/grouping_selector";
import { TYPE_CHECKBOXES } from "common/filter/constants";
import {
  getSortingOptions,
  SCORE_OPTIONS,
  ELIMINATED_OPTIONS,
  WITHDRAWN_OPTIONS,
  ENTRIES_RESOURCE_NAME,
  NEW_OPTIONS,
  FINAL_ROUND_OPTIONS,
} from "entries/constants";
import Finalists from "entries/containers/finalists";
import FinalRoundFaqs from "entries/containers/final_round_faqs";

const Controls = ({
  project,
  viewType,
  grouped,
  setViewType,
  setGrouping,
  atYourEntries,
  canViewRating,
  canViewNewTag,
  skipQuerySave,
  canViewFinalRoundFilter,
}) => {
  if (!project.id) {
    return null;
  }

  const sortingOptions = getSortingOptions(project.entry_type, canViewRating);

  return (
    <div className="project-gallery-controls sidebar sidebar-filter">
      <FinalRoundFaqs className="show-for-large" />

      {/* element must be rendered and hidden, because `saveLastQuery` must be called */}
      <div
        className={classnames("controls-group", {
          hide: !canViewFinalRoundFilter,
        })}
      >
        <h5 className="sorting-name">Finalist Round</h5>
        <Filter
          field="by_finalists_only"
          type={TYPE_CHECKBOXES}
          resourceName={ENTRIES_RESOURCE_NAME}
          options={FINAL_ROUND_OPTIONS}
          skipQuerySave={skipQuerySave}
        />
        <Finalists small={true} hidePlaceholders={true} />
      </div>
      <ViewTypeSelector
        entryType={project.entry_type}
        viewType={viewType}
        onChange={setViewType}
      />
      <div className="entries-controls-rest">
        <GroupingSelector
          entryType={project.entry_type}
          grouped={grouped}
          onChange={setGrouping}
        />
        <div className="controls-group">
          <h5 className="sorting-name">Sort</h5>
          <SorterSelect
            resourceName={ENTRIES_RESOURCE_NAME}
            options={sortingOptions}
            defaultValue="-created_at"
          />
        </div>
        <div className="controls-group">
          <h5 className="sorting-name">Filters</h5>
          {canViewRating && (
            <Filter
              field="score"
              type={TYPE_CHECKBOXES}
              resourceName={ENTRIES_RESOURCE_NAME}
              options={SCORE_OPTIONS}
              skipQuerySave={skipQuerySave}
            />
          )}
          {canViewNewTag && (
            <Filter
              field="new"
              type={TYPE_CHECKBOXES}
              resourceName={ENTRIES_RESOURCE_NAME}
              options={NEW_OPTIONS}
              skipQuerySave={skipQuerySave}
            />
          )}
          <Filter
            field="entry_eliminated"
            type={TYPE_CHECKBOXES}
            resourceName={ENTRIES_RESOURCE_NAME}
            options={ELIMINATED_OPTIONS}
            skipQuerySave={skipQuerySave}
          />
          <Filter
            field="entry_withdrawn"
            type={TYPE_CHECKBOXES}
            options={WITHDRAWN_OPTIONS}
            resourceName={ENTRIES_RESOURCE_NAME}
            skipQuerySave={skipQuerySave}
          />
        </div>
      </div>
    </div>
  );
};

Controls.propTypes = {
  project: PropTypes.object.isRequired,
  viewType: PropTypes.string,
  grouped: PropTypes.bool,
  atYourEntries: PropTypes.bool.isRequired,
  setViewType: PropTypes.func.isRequired,
  setGrouping: PropTypes.func.isRequired,
  canViewRating: PropTypes.bool.isRequired,
  canViewNewTag: PropTypes.bool.isRequired,
  canViewFinalRoundFilter: PropTypes.bool.isRequired,
  skipQuerySave: PropTypes.bool,
};

export default Controls;
