import React, { Component } from "react";
import PropTypes from "prop-types";
import { push } from "react-router-redux";
import idx from "idx";
import TabbedNavigation from "common/components/tabbed_navigation";
import WrapupTabSubItem from "project/components/wrapup/tab_sub_item";
import { getFGPromoBannerContent } from "project/utils";

export default class ProjectHeaderTabs extends Component {
  constructor(props) {
    super(props);

    this.fetchData = this.fetchData.bind(this);
    this.showFGPromoBanner = this.showFGPromoBanner.bind(this);
  }

  showFGPromoBanner() {
    const { project, showBanner, hideBanner } = this.props;
    const bannerContent = getFGPromoBannerContent(project, hideBanner);
    if (bannerContent) {
      const { title, content, icon, type, onHide } = bannerContent;
      showBanner(title, content, icon, type, onHide);
    }
  }

  fetchData() {
    const { isOwner, fetchFocusGroups, fetchPresentations } = this.props;

    /* only the owner has focus groups and presentations */
    if (isOwner) {
      fetchFocusGroups().then(({ data }) => {
        /* only show banner promoting focus groups if none was created */
        if (data.length === 0) {
          this.showFGPromoBanner();
        }
      });
      fetchPresentations();
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    const prevId = idx(prevProps, (_) => _.project.id);
    const curId = idx(this.props, (_) => _.project.id);
    if (prevId !== curId) {
      this.fetchData();
    }
  }

  render() {
    const {
      project,
      projectUrl,
      router,
      wrapups,
      isOwner,
      isCollaborator,
      isCreative,
      showEntries,
      presentations,
      showPromoContent,
      showTour,
    } = this.props;
    const hasPresentations = presentations.length > 0;
    const canCreatePresentations = project.presentations_allowed;
    const posted = !["1_1_negotiating", "1_1_ready"].includes(project.status);

    if (showPromoContent) {
      return null;
    }

    const links = [];

    /* brief tab: always present */
    links.push({
      url: `${projectUrl}`,
      matchExact: true,
      name: "Brief",
    });

    /* entries tab */
    if (posted && showEntries) {
      links.push({
        url: `${projectUrl}entries/`,
        matchExact: true,
        name: "Entries",
      });
    }

    /* "your entries" tab: creatives that are not the project owner or a collaborator */
    if (posted && isCreative && !isOwner && !isCollaborator) {
      links.push({
        url: `${projectUrl}your-entries/`,
        matchExact: true,
        name: "Your Entries",
      });
    }

    /* wrapups tab */
    if (
      (isOwner || isCollaborator) &&
      ["open", "closed", "finalist_round", "finalist_closed"].includes(
        project.status
      ) &&
      wrapups.length === 0
    ) {
      links.push({
        name: "Wrap-up",
        url: `${projectUrl}wrapup/intro/`,
        matchExact: true,
      });
    } else if (wrapups.length === 1) {
      links.push({
        name: "Wrap-up",
        url: `${projectUrl}wrapup/${wrapups[0].award.id}/`,
        matchExact: true,
      });
    } else if (wrapups.length > 1) {
      links.push({
        name: `Wrap-up (${wrapups.length})`,
        matchExact: true,
        subItems: wrapups.map((w) => {
          const url = `${projectUrl}wrapup/${w.award.id}/`;
          return {
            component: <WrapupTabSubItem wrapup={w} />,
            url,
          };
        }),
      });
    }

    if (
      posted &&
      isOwner &&
      (project.max_focus_groups_allowed === null ||
        project.max_focus_groups_allowed > 0)
    ) {
      links.push({
        url: `${projectUrl}focus-groups/`,
        name: "Focus Groups",
      });
    }

    if (posted && isOwner && project.entry_type === "image") {
      const presentationMenus = hasPresentations
        ? presentations.map((presentation) => ({
            component: (
              <span className="dropdown-item-button">{presentation.name}</span>
            ),
            url: `${projectUrl}presentations/${presentation.id}/`,
          }))
        : [];
      const presentationName = hasPresentations
        ? `Presentations (${presentations.length})`
        : "Presentations";

      if (hasPresentations && canCreatePresentations) {
        presentationMenus.push({
          component: (
            <span className="dropdown-item-button">
              <i className="fa fa-plus-circle" /> Create new
            </span>
          ),
          url: `${projectUrl}presentations/new/`,
        });
      }

      if (hasPresentations || canCreatePresentations) {
        links.push({
          url: `${projectUrl}presentations/new/`,
          matchExact: true,
          name: presentationName,
          subItems: hasPresentations ? [...presentationMenus] : null,
        });
      }
    }

    /* settings tab: only for owner */
    if (posted && isOwner) {
      links.push({
        url: `${projectUrl}settings/`,
        name: "Settings",
      });
    }

    if (project.is_one_to_one) {
      links.push({
        url: `${projectUrl}negotiation/`,
        name: "Negotiation",
      });
    }

    if (showTour) {
      links.push({
        url: `${projectUrl}tour/`,
        name: "Tour",
      });
    }

    return <TabbedNavigation router={router} tabs={{ links }} push={push} />;
  }
}

ProjectHeaderTabs.propTypes = {
  fetchFocusGroups: PropTypes.func.isRequired,
  project: PropTypes.object,
  projectUrl: PropTypes.string.isRequired,
  isOwner: PropTypes.bool,
  isCollaborator: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  showEntries: PropTypes.bool,
  router: PropTypes.any,
  wrapups: PropTypes.array,
  isCreative: PropTypes.bool,
  showBanner: PropTypes.func.isRequired,
  hideBanner: PropTypes.func.isRequired,
  showPromoContent: PropTypes.bool.isRequired,
  showTour: PropTypes.bool.isRequired,
};
