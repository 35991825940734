import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "react-router-redux";
import thunk from "redux-thunk";
import promise from "redux-promise";
import rootReducer from "common/ducks";
import promiseMiddleware from "api/promiseMiddleware";
import headerMiddleware from "api/header_middleware";
import createLogger from "redux-logger";

/*
 * @param {Object} initial state to bootstrap our stores with for server-side rendering
 * @param {History Object} a history object. We use `createMemoryHistory` for server-side rendering,
 *                          while using browserHistory for client-side
 *                          rendering.
 */
export default function configureStore(initialState, history) {
  const middleware = [
    thunk,
    promiseMiddleware,
    promise,
    headerMiddleware,
    routerMiddleware(history),
  ];
  let store;

  if (__DEVCLIENT__) {
    middleware.push(createLogger());
    store = createStore(
      rootReducer,
      initialState,
      compose(
        applyMiddleware(...middleware),
        typeof window === "object" &&
          typeof window.devToolsExtension !== "undefined"
          ? window.devToolsExtension()
          : (f) => f
      )
    );
  } else {
    store = createStore(
      rootReducer,
      initialState,
      compose(applyMiddleware(...middleware), (f) => f)
    );
  }

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("common/ducks", () => {
      const nextReducer = require("../common/ducks");
      store.replaceReducer(nextReducer);
    });
  }
  return store;
}
