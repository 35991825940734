import { GOAL_OPTIONS } from "wasp/constants";

export const getGoalDescription = (goal) => {
  const option = GOAL_OPTIONS.find((o) => o.field === goal);
  return option ? option.label : "";
};

export const getStatusLabel = (obj) => {
  if (!obj) {
    return "";
  }

  if (obj.status === "complete") {
    return "ended";
  }

  if (obj.status === "soft_complete") {
    return "soft ended";
  }

  return obj.status;
};

export const generateRandomDomain = () =>
  `replace-this-domain-${new Date().valueOf()}.com`;
