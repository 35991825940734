import idx from "idx";
import unionBy from "lodash/unionBy";
import intersectionBy from "lodash/intersectionBy";
import cloneDeep from "lodash/cloneDeep";
import {
  TYPE_CHECKBOXES,
  TYPE_SELECT,
  TYPE_RANGE,
  TYPE_RANGE_SELECT,
  TYPE_STRING,
  TYPE_TEXTAREA,
  RANGE_START_APPEND,
  RANGE_END_APPEND,
} from "common/filter/constants";

/* Get query parameter prefix for a given resource. */
const getFilterParamPrefix = (resourceName) => `${resourceName}_filter`;

/* Get query parameter name for a given filter.
   CAUTION: `getAllFiltersFromQuery()` defined below parses the string returned by this function,
   so remember to update it in case the format changes. */
const getFilterParamName = (resourceName, field, filterType, append = "") => {
  const prefix = getFilterParamPrefix(resourceName);
  return `${prefix}_${filterType}_${field}${append}`;
};

/* Get the value for a given filter from the query dict */
export const getValueFromQuery = (resourceName, field, filterType, query) => {
  const paramName = getFilterParamName(resourceName, field, filterType);
  const queryValue = idx(query, (_) => _[paramName]);

  /* Example: ['open', 'closed', 'awarded'] */
  if (filterType === TYPE_CHECKBOXES) {
    return queryValue ? queryValue.split(",") : [];
  }

  /* Example: '12' */
  if ([TYPE_SELECT, TYPE_RANGE_SELECT, TYPE_STRING].includes(filterType)) {
    return queryValue;
  }

  /* Example: { start: 500, end: 750 } */
  if (filterType === TYPE_RANGE) {
    const startParamName = getFilterParamName(
      resourceName,
      field,
      filterType,
      RANGE_START_APPEND
    );
    const endParamName = getFilterParamName(
      resourceName,
      field,
      filterType,
      RANGE_END_APPEND
    );
    return {
      start: idx(query, (_) => _[startParamName]),
      end: idx(query, (_) => _[endParamName]),
    };
  }

  /* Example: "value_1\nvalue_2\nvalue_3" */
  if (filterType === TYPE_TEXTAREA) {
    return queryValue ? queryValue.replace(/,/g, "\n") : "";
  }
};

/* Get the query dict given the filter value */
export const getQueryFromValue = (resourceName, field, filterType, value) => {
  const paramName = getFilterParamName(resourceName, field, filterType);

  /* Example: { project_filter_checkboxes_status: open,closed,awarded } */
  if (filterType === TYPE_CHECKBOXES) {
    return {
      [paramName]: value.length > 0 ? value.join(",") : undefined,
    };
  }

  /* Example: { project_filter_select_category: '12' } */
  if ([TYPE_SELECT, TYPE_RANGE_SELECT, TYPE_STRING].includes(filterType)) {
    return {
      [paramName]: value,
    };
  }

  /* Example: { project_filter_range_award__gte: '500', project_filter_range_award__lte: '750' } */
  if (filterType === TYPE_RANGE) {
    const startParamName = getFilterParamName(
      resourceName,
      field,
      filterType,
      RANGE_START_APPEND
    );
    const endParamName = getFilterParamName(
      resourceName,
      field,
      filterType,
      RANGE_END_APPEND
    );
    return {
      [startParamName]: value.start,
      [endParamName]: value.end,
    };
  }

  /* Example: { project_filter_textarea_status: open,closed,awarded } */
  if (filterType === TYPE_TEXTAREA) {
    return {
      [paramName]: value ? value.replace(/\n/g, ",") : undefined,
    };
  }
};

/* Get the API query dict for a given filter based on the (URL) query dict */
const getFieldAPIQuery = (resourceName, field, filterType, query) => {
  const value = getValueFromQuery(resourceName, field, filterType, query);

  /* Example: { category: '12' } */
  if ([TYPE_CHECKBOXES, TYPE_SELECT, TYPE_STRING].includes(filterType)) {
    return {
      [field]: value,
    };
  }

  /* Example: { award__gte: '500', award__lte: '750' } */
  if (filterType === TYPE_RANGE) {
    return {
      [`${field}${RANGE_START_APPEND}`]: value.start,
      [`${field}${RANGE_END_APPEND}`]: value.end,
    };
  }

  /* Example: { award__gte: '500', award__lte: '750' } */
  if (filterType === TYPE_RANGE_SELECT) {
    const [start, end] = (value || "").split("_");

    return {
      [`${field}${RANGE_START_APPEND}`]: start,
      [`${field}${RANGE_END_APPEND}`]: end,
    };
  }

  /* Example: { category: '12' } */
  if (filterType === TYPE_TEXTAREA) {
    return {
      [field]: value.split("\n"),
    };
  }
};

/* Get all query keys for a given resource from the query dict */
export const getAllKeysFromQuery = (resourceName, query) => {
  const prefix = getFilterParamPrefix(resourceName);
  return Object.keys(query).filter((paramName) => paramName.startsWith(prefix));
};

/* Get all filters for a given resource from the query dict */
export const getAllFiltersFromQuery = (resourceName, query) => {
  const keys = getAllKeysFromQuery(resourceName, query);
  const prefix = getFilterParamPrefix(resourceName);

  return keys.reduce((acc, paramName) => {
    /* parse `paramName` in order to extract `type` and `field` */
    const type = [
      TYPE_CHECKBOXES,
      TYPE_SELECT,
      TYPE_RANGE_SELECT,
      TYPE_RANGE,
      TYPE_STRING,
      TYPE_TEXTAREA,
    ].find((type) => paramName.includes(`${prefix}_${type}`));
    let field = paramName.replace(`${prefix}_${type}_`, "");
    if (type === TYPE_RANGE) {
      field = field.split("__")[0];
    }
    const value = getValueFromQuery(resourceName, field, type, query);
    return unionBy(acc, [{ field, type, value }], "field");
  }, []);
};

/* Get the API query dict for all resource filters based on the (URL) query dict */
export const getAPIQuery = (resourceName, query) => {
  const filters = getAllFiltersFromQuery(resourceName, query);
  return filters.reduce(
    (acc, filter) => ({
      ...acc,
      ...getFieldAPIQuery(resourceName, filter.field, filter.type, query),
    }),
    {}
  );
};

/* Apply query client-side on list of provided items */
export const applyClientQuery = (items, resourceName, query, opts = {}) => {
  let updatedItems = cloneDeep(items),
    perFilterItems = [];
  const apiQuery = getAPIQuery(resourceName, query);
  const equalityFunc = opts.equalityFunc || "id";

  /* TODO: only type `TYPE_CHECKBOXES` is currently supported */
  Object.keys(apiQuery).forEach((filter) => {
    perFilterItems = [];
    apiQuery[filter].forEach((value) => {
      const filterFunc =
        idx(opts, (_) => _.filterFunc[filter]) ||
        ((item) => item[filter] === value);
      perFilterItems = unionBy(
        perFilterItems,
        items.filter((item) => filterFunc(item, value)),
        equalityFunc
      );
    });
    updatedItems = intersectionBy(updatedItems, perFilterItems, equalityFunc);
  });

  return updatedItems;
};
