import request from "axios";
import reverse from "lodash/reverse";

/* ACTIONS
================================================================================================ */
const SEE_RESOURCE = "admin/common/SEE_RESOURCE";
const SEE_RESOURCE_SUCCESS = "admin/common/SEE_RESOURCE_SUCCESS";

const TOGGLE_ACTION_CONFIRMATION = "admin/common/TOGGLE_ACTION_CONFIRMATION";

const TOGGLE_ACTION_CONFIRMATION_BTN =
  "admin/common/TOGGLE_ACTION_CONFIRMATION_BTN";

/* HELPERS
================================================================================================ */
/* format stats in the way expected by the frontend */
export const formatStats = (payload) => {
  const current = payload[0];
  return Object.keys(current).reduce((acc, k) => {
    return {
      ...acc,
      [k]: {
        ...current[k],
        history: reverse(payload.slice(1).map((i) => (i[k] ? i[k].count : 0))),
      },
    };
  }, {});
};

/* INITIAL STATES
================================================================================================ */
const initialState = {
  resourceSeers: [],
  actionConfirmationInfo: null,
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SEE_RESOURCE_SUCCESS:
      return {
        ...state,
        resourceSeers: action.payload,
      };
    case TOGGLE_ACTION_CONFIRMATION:
      return {
        ...state,
        actionConfirmationInfo: action.info,
      };
    case TOGGLE_ACTION_CONFIRMATION_BTN:
      return {
        ...state,
        actionConfirmationInfo: {
          ...state.actionConfirmationInfo,
          primaryDisabled: !action.enabled,
        },
      };
    default:
      return state;
  }
}

export const seeResource = (name, id) => (dispatch) => {
  return dispatch({
    type: SEE_RESOURCE,
    promise: request.get(`/api/v1/${name}/${id}/seeing/`),
  });
};

export const toggleActionConfirmation = (info) => (dispatch) => {
  return dispatch({
    type: TOGGLE_ACTION_CONFIRMATION,
    info,
  });
};

export const toggleActionConfirmationBtn = (enabled) => (dispatch) => {
  return dispatch({
    type: TOGGLE_ACTION_CONFIRMATION_BTN,
    enabled,
  });
};
