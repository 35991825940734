import request from "axios";
import { browserHistory } from "react-router";

export const FETCH_ARTICLE = "crowdspring/help/FETCH_ARTICLE";
export const FETCH_ARTICLE_REQUEST = "crowdspring/help/FETCH_ARTICLE_REQUEST";
export const FETCH_ARTICLE_FAILURE = "crowdspring/help/FETCH_ARTICLE_FAILURE";
export const FETCH_ARTICLE_SUCCESS = "crowdspring/help/FETCH_ARTICLE_SUCCESS";

export const VOTE_ARTICLE = "crowdspring/help/VOTE_ARTICLE";
export const VOTE_ARTICLE_REQUEST = "crowdspring/help/VOTE_ARTICLE_REQUEST";
export const VOTE_ARTICLE_FAILURE = "crowdspring/help/VOTE_ARTICLE_FAILURE";
export const VOTE_ARTICLE_SUCCESS = "crowdspring/help/VOTE_ARTICLE_SUCCESS";

export const fetchArticleIfNeeded = (articleSlug) => (dispatch, getState) => {
  /* article already fetched */
  const article = getState().help.article;
  if (article.data && articleSlug === article.data.slug) {
    return Promise.resolve();
  }

  const promise = request({
    method: "get",
    url: `/api/v1/articles/${articleSlug}/`,
  });

  /* Handle missing article.
     In case `browserHistory` is not available, it means server-rendering is being performed
     and the 404 error will be handled on the request level.
     Otherwise, user is redirected to /404. */
  promise.catch((response) => {
    if (response.status === 404 && browserHistory) {
      browserHistory.push("/404");
    }
  });

  return dispatch({
    type: FETCH_ARTICLE,
    promise,
  });
};

export function voteArticle(articleSlug, up) {
  const endpoint = up ? "helpful" : "unhelpful";
  const opts = {
    method: "get",
    url: `/api/v1/articles/${articleSlug}/${endpoint}`,
  };
  return {
    type: VOTE_ARTICLE,
    promise: request(opts),
    payload: { up },
  };
}

export const INITIAL_STATE = {
  isLoading: false,
  data: null,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ARTICLE_REQUEST:
      return {
        ...state,
        data: null,
        isLoading: true,
      };
    case FETCH_ARTICLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case VOTE_ARTICLE_REQUEST:
      const helpful = action.payload.up
        ? state.data.votes.helpful + 1
        : state.data.votes.helpful;
      const total = state.data.votes.total + 1;

      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          votes: { helpful, total },
        },
      };
    default:
      return state;
  }
}
