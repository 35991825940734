export const STAGE_VOTE = "vote";
export const STAGE_DONE = "done";

export const MAX_SELECTED_REVISIONS = 12;

export const ENTRIES_RESOURCE_NAME = "entries";

export const PARTICIPANT_WEIGHT_OPTIONS = [
  {
    field: 1,
    label: "Less",
  },
  {
    field: 2,
    label: "Normal",
  },
  {
    field: 3,
    label: "More",
  },
];

export const OKAY_PARTICIPANT_COUNT = 9;
export const ALMOST_GOOD_PARTICIPANT_COUNT = 16;
export const GOOD_PARTICIPANT_COUNT = 25;

export const MAX_COMMENT_LENGTH = 500;

export const HELP_URL = "/help/managing-project/what-is-a-focus-group/";
