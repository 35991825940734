import request from "axios";
import omit from "lodash/omit";
import pick from "lodash/pick";
import sortBy from "lodash/sortBy";
import moment from "moment";
import queryString from "query-string";
import { browserHistory } from "react-router";
import { setBannerError } from "error";
import { fetchWrapupsIfNeeded } from "project/ducks/wrapup";
import { fetchMessages } from "common/ducks/message";
import { PROJECTS_RESOURCE_NAME } from "admin/constants";
import { getAPIQueryString, getItemsFromString } from "common/utils";
import { savePageCount } from "common/pager/ducks";
import { formatStats } from "admin/ducks/common";

/* ACTIONS
================================================================================================ */
const FETCH = "admin/FETCH_PROJECTS";
const FETCH_REQUEST = "admin/FETCH_PROJECTS_REQUEST";
const FETCH_SUCCESS = "admin/FETCH_PROJECTS_SUCCESS";

const FETCH_SINGLE = "admin/FETCH_SINGLE_PROJECT";
const FETCH_SINGLE_REQUEST = "admin/FETCH_SINGLE_PROJECT_REQUEST";
export const FETCH_SINGLE_SUCCESS = "admin/FETCH_SINGLE_PROJECT_SUCCESS";

const FETCH_REFUND_INFO = "admin/FETCH_REFUND_INFO";
const FETCH_REFUND_INFO_SUCCESS = "admin/FETCH_REFUND_INFO_SUCCESS";

const FETCH_STATS = "admin/FETCH_PROJECTS_STATS";
const FETCH_STATS_SUCCESS = "admin/FETCH_PROJECTS_STATS_SUCCESS";

const FETCH_OPERATIONS = "admin/FETCH_OPERATIONS";
const FETCH_OPERATIONS_SUCCESS = "admin/FETCH_OPERATIONS_SUCCESS";

const FETCH_ADJUSTMENTS = "admin/FETCH_ADJUSTMENTS";
const FETCH_ADJUSTMENTS_SUCCESS = "admin/FETCH_ADJUSTMENTS_SUCCESS";

const FETCH_PARTICIPANTS = "admin/FETCH_PARTICIPANTS";
const FETCH_PARTICIPANTS_SUCCESS = "admin/FETCH_PARTICIPANTS_SUCCESS";

const UPDATE_AUTO_NDA = "admin/UPDATE_AUTO_NDA";
const UPDATE_AUTO_NDA_SUCCESS = "admin/UPDATE_AUTO_NDA_SUCCESS";

const FETCH_WATCHERS = "admin/FETCH_WATCHERS";
const FETCH_WATCHERS_SUCCESS = "admin/FETCH_WATCHERS_SUCCESS";

const FETCH_WRAPUPS_FEEDBACK = "admin/FETCH_WRAPUPS_FEEDBACK";
const FETCH_WRAPUPS_FEEDBACK_SUCCESS = "admin/FETCH_WRAPUPS_FEEDBACK_SUCCESS";

const FETCH_INVOICE = "admin/FETCH_PROJECT_INVOICE";
const FETCH_INVOICE_SUCCESS = "admin/FETCH_PROJECT_INVOICE_SUCCESS";

const FETCH_RESERVATIONS = "admin/FETCH_RESERVATIONS";
const FETCH_RESERVATIONS_SUCCESS = "admin/FETCH_RESERVATIONS_SUCCESS";

const FETCH_RANKING = "admin/FETCH_RANKING";
const FETCH_RANKING_SUCCESS = "admin/FETCH_RANKING_SUCCESS";

const FETCH_COUNTRY_TIERS = "admin/FETCH_COUNTRY_TIERS";
const FETCH_COUNTRY_TIERS_SUCCESS = "admin/FETCH_COUNTRY_TIERS_SUCCESS";

const FETCH_TITLE_WORDS = "admin/FETCH_TITLE_WORDS";
const FETCH_TITLE_WORDS_SUCCESS = "admin/FETCH_TITLE_WORDS_SUCCESS";

const FETCH_BLACKLISTED_TERMS = "admin/FETCH_BLACKLISTED_TERMS";
const FETCH_BLACKLISTED_TERMS_SUCCESS = "admin/FETCH_BLACKLISTED_TERMS_SUCCESS";

/* HELPERS
================================================================================================ */
export const getZdTicketRecipientFilters = (data) => ({
  reputation__gte: data.min_recipient_reputation,
});

export const fetchZdTicketRecipientCountRequest = (projectId, values) => {
  return request.put(
    `/api/v1/radmin/projects/${projectId}/zendesk_tickets_recipient_count/`,
    values
  );
};

/* INITIAL STATES
================================================================================================ */
export const initialState = {
  isFetching: false,
  isError: false,
  list: [],
  currentProject: null,
  projectCount: 0,
  refundInfo: {},
  stats: {},
  operations: [],
  adjustments: [],
  participants: [],
  reservations: [],
  ranking: [],
  watchers: [],
  wrapupsFeedback: {},
  invoice: null,
  countryTiers: [],
  titleWords: [],
  blacklistedTerms: {
    client_creative_communication: [],
  },
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_REQUEST:
      return {
        ...state,
        queryStr: action.queryStr,
        isFetching: true,
      };
    case FETCH_SINGLE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_SUCCESS: {
      /* avoid wrong results due to parallel requests */
      if (action.queryStr !== state.queryStr) {
        return state;
      }

      const data = action.payload;

      return {
        ...state,
        projectCount: data.count,
        isFetching: false,
        list: data.results,
      };
    }
    case FETCH_SINGLE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        currentProject: action.payload,
      };
    case FETCH_REFUND_INFO_SUCCESS:
      return {
        ...state,
        refundInfo: action.payload,
      };
    case FETCH_STATS_SUCCESS:
      return {
        ...state,
        stats: formatStats(action.payload),
      };
    case FETCH_OPERATIONS_SUCCESS:
      return {
        ...state,
        operations: sortBy(
          action.payload,
          (operation) => -moment(operation.date)
        ),
      };
    case FETCH_ADJUSTMENTS_SUCCESS:
      return {
        ...state,
        adjustments: action.payload,
      };
    case FETCH_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        participants: action.payload,
      };
    case FETCH_RESERVATIONS_SUCCESS:
      return {
        ...state,
        reservations: sortBy(action.payload, (r) => -moment(r.created_at)),
      };
    case UPDATE_AUTO_NDA_SUCCESS:
      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          nda_auto_approve: !state.currentProject.nda_auto_approve,
        },
      };
    case FETCH_WATCHERS_SUCCESS:
      return {
        ...state,
        watchers: action.payload,
      };
    case FETCH_WRAPUPS_FEEDBACK_SUCCESS:
      return {
        ...state,
        wrapupsFeedback: action.payload,
      };
    case FETCH_INVOICE_SUCCESS:
      return {
        ...state,
        invoice: action.payload,
      };
    case FETCH_RANKING_SUCCESS:
      return {
        ...state,
        ranking: action.payload,
      };
    case FETCH_COUNTRY_TIERS_SUCCESS:
      return {
        ...state,
        /* only A-tier countries are considered at the moment */
        countryTiers: action.payload.filter((c) => c.tier === "a"),
      };
    case FETCH_TITLE_WORDS_SUCCESS:
      return {
        ...state,
        titleWords: action.payload,
      };
    case FETCH_BLACKLISTED_TERMS_SUCCESS:
      return {
        ...state,
        blacklistedTerms: {
          ...state.blacklistedTerms,
          [action.context]: action.payload,
        },
      };
    default:
      return state;
  }
}

export const fetchProjects =
  (query, extraAPIQuery = {}) =>
  (dispatch) => {
    const queryStr = getAPIQueryString(PROJECTS_RESOURCE_NAME, query, {
      extraAPIQuery: {
        ...extraAPIQuery,
        admin_preset: query.preset,
      },
    });
    const promise = request.get(`/api/v1/projects/?${queryStr}`);

    /* save page count in pager state */
    promise.then((res) => dispatch(savePageCount(PROJECTS_RESOURCE_NAME, res)));

    return dispatch({
      type: FETCH,
      queryStr,
      promise,
    });
  };

export const fetchProject = (id) => (dispatch) => {
  const promise = request.get(`/api/v1/projects/${id}/`);

  promise.catch(() => browserHistory.replace("/404/"));

  return dispatch({
    type: FETCH_SINGLE,
    promise,
  });
};

export const adminActions =
  (id, action, fields, method = "patch") =>
  (dispatch) => {
    let data = fields;
    if (
      [
        "extend_deadline",
        "extend_wrapup_deadline",
        "extend_finalist_round",
      ].includes(action)
    ) {
      const deadline = moment(fields.deadline, "MM/DD/YYYY HH:mm");
      if (!deadline.isValid()) {
        window.alert("The deadline provided is not valid."); //eslint-disable-line
        return Promise.reject();
      }
      data = { ...omit(fields, "deadline"), deadline: deadline.format() };
    }

    if (action === "create_zendesk_tickets") {
      data = {
        ...data,
        recipient_filters: getZdTicketRecipientFilters(data),
      };
    }

    if (action === "update_awards") {
      data.items = data.awards;
    }

    const promise = request[method](
      `/api/v1/radmin/projects/${id}/${action}/`,
      data
    );

    promise
      .then(() => {
        dispatch(fetchProject(id));

        if (
          [
            "wrapup_backtrack",
            "change_wrapup_entry_name",
            "extend_wrapup_deadline",
          ].includes(action)
        ) {
          dispatch(fetchWrapupsIfNeeded(id, true));
        }
      })
      .catch((err) => {
        const errorMsg = err.data ? JSON.stringify(err.data) : "Unkown error.";
        dispatch(setBannerError("Error performing action", errorMsg));
      });

    return promise;
  };

export const adminUpdateBrief = (id, data) => (dispatch) => {
  const note = pick(data, "project_admin_note");
  const others = omit(data, ["contract", "project_admin_note"]);
  const massaged = {
    ...note,
    updates: { ...others },
  };
  return request
    .patch(`/api/v1/radmin/projects/${id}/update_brief/`, massaged)
    .then(() => dispatch(fetchProject(id)));
};

export const adminCreateMessage = (id, streamId, data) =>
  request.post(`/api/v1/radmin/projects/${id}/create_message/`, data);

export const messageActions =
  (projectId, id, action, fields) => (dispatch, getState) => {
    const { projects } = getState().admin;
    const project = projects.currentProject;
    const data = {
      ...fields,
      id,
    };

    return request
      .patch(`/api/v1/radmin/projects/${projectId}/${action}/`, data)
      .then(() =>
        dispatch(
          fetchMessages({ streamId: project.message_stream_id, project })
        )
      );
  };

export const fetchRefundInfo = (id) => (dispatch) =>
  dispatch({
    type: FETCH_REFUND_INFO,
    promise: request.get(`/api/v1/radmin/projects/${id}/refund_info/`),
  });

export const fetchStats =
  (extraAPIQuery = {}) =>
  (dispatch) =>
    dispatch({
      type: FETCH_STATS,
      promise: request.get(
        `/api/v1/projects/admin_stats/?${queryString.stringify(extraAPIQuery)}`
      ),
    });

export const fetchOperations = (id) => (dispatch) =>
  dispatch({
    type: FETCH_OPERATIONS,
    promise: request.get(`/api/v1/radmin/projects/${id}/payment_operations/`),
  });

export const fetchAdjustments = (id) => (dispatch) =>
  dispatch({
    type: FETCH_ADJUSTMENTS,
    promise: request.get(`/api/v1/projects/${id}/payment_adjustments/`),
  });

export const fetchParticipants = (id) => (dispatch) =>
  dispatch({
    type: FETCH_PARTICIPANTS,
    promise: request.get(`/api/v1/projects/${id}/participants/`),
  });

export const fetchReservations = (id) => (dispatch) =>
  dispatch({
    type: FETCH_RESERVATIONS,
    promise: request.get(`/api/v1/projects/${id}/elite_reservations/`),
  });

export const fetchRanking = (id) => (dispatch) =>
  dispatch({
    type: FETCH_RANKING,
    promise: request.get(`/api/v1/projects/${id}/participant_ranking/`),
  });

export const updateAutoNDA = (id, nda_auto_approve) => (dispatch) =>
  dispatch({
    type: UPDATE_AUTO_NDA,
    promise: request.patch(`/api/v1/projects/${id}/nda_auto_approve/`, {
      nda_auto_approve,
    }),
  });

export const fetchWatchers = (id) => (dispatch) =>
  dispatch({
    type: FETCH_WATCHERS,
    promise: request.get(`/api/v1/projects/${id}/watchers/`),
  });

export const fetchWrapupsFeedback = (id) => (dispatch) =>
  dispatch({
    type: FETCH_WRAPUPS_FEEDBACK,
    promise: request.get(`/api/v1/projects/${id}/wrapups_feedback/`),
  });

export const fetchInvoice = (id) => (dispatch) =>
  dispatch({
    type: FETCH_INVOICE,
    promise: request.get(`/api/v1/projects/${id}/invoice/`),
  });

export const fetchCountryTiers = () => (dispatch) =>
  dispatch({
    type: FETCH_COUNTRY_TIERS,
    promise: request.get("/api/v1/country_tiers/"),
  });

export const updateCountryTiers =
  (_, { codes }) =>
  (dispatch, getState) => {
    const currentCountries = getState().admin.projects.countryTiers;

    const toAdd = codes.filter(
      (n) => !currentCountries.find((c) => c.code === n)
    );
    const toRemove = currentCountries.filter(
      (c) => !codes.find((n) => c.code === n)
    );

    let premises = toAdd.reduce(
      (acc, c) =>
        acc.then(() =>
          request.post("/api/v1/country_tiers/", { code: c, tier: "a" })
        ),
      Promise.resolve()
    );
    premises = toRemove.reduce(
      (acc, c) =>
        acc.then(() => request.delete(`/api/v1/country_tiers/${c.id}/`)),
      premises
    );

    premises.then(() => dispatch(fetchCountryTiers()));

    return premises;
  };

export const fetchTitleWords = () => (dispatch) =>
  dispatch({
    type: FETCH_TITLE_WORDS,
    promise: request.get("/api/v1/projects/title_words/"),
  });

export const updateTitleWords =
  (_, { words }) =>
  (dispatch, getState) => {
    const currentWords = getState().admin.projects.titleWords;
    const updatedWords = words
      .split("\n")
      .map((w) => w.trim())
      .filter((w) => !!w);

    const toAdd = updatedWords.filter(
      (w) => !currentWords.find((o) => o.content === w)
    );
    const toRemove = currentWords.filter(
      (o) => !updatedWords.find((w) => w === o.content)
    );

    let premises = toAdd.reduce(
      (acc, w) =>
        acc.then(() =>
          request.post("/api/v1/projects/title_words/", { content: w })
        ),
      Promise.resolve()
    );
    premises = toRemove.reduce(
      (acc, o) =>
        acc.then(() => request.delete(`/api/v1/projects/title_words/${o.id}/`)),
      premises
    );

    premises.then(() => dispatch(fetchTitleWords()));

    return premises;
  };

export const fetchBlacklistedTerms = (context) => (dispatch) =>
  dispatch({
    type: FETCH_BLACKLISTED_TERMS,
    promise: request.get(
      `/api/v1/blacklisted_terms/all_with_context/?context=${context}`
    ),
    context,
  });

export const updateBlacklistedTerms = (context, phrases) => (dispatch) => {
  const data = getItemsFromString(phrases, "\n");

  return request
    .put(`/api/v1/blacklisted_terms/all_with_context/?context=${context}`, data)
    .then(() => {
      dispatch(fetchBlacklistedTerms(context));
    });
};
