import React from "react";
import PropTypes from "prop-types";
import TaggingField from "common/components/form_fields/tagging";

/**
 * Select filter field.
 *
 * @author Nathan Ozelim <nathan@startupfoundry.com>
 */
const Select = ({ field, options, value, onChange }) => {
  return (
    <div className="select-filter">
      <TaggingField
        options={options}
        meta={{}}
        value={value}
        input={{
          name: `filter_${field}`,
          /* make sure undefined is returned instead of null or '' */
          onChange: (val) => onChange(val || undefined),
        }}
      />
    </div>
  );
};

Select.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default Select;
