import request from "axios";
import { PARENT_ENTRIES_RESOURCE_NAME } from "project/constants";
import { getAPIQueryString } from "common/utils";
import { savePageCount } from "common/pager/ducks";

/* ACTIONS
================================================================================================ */
const TOGGLE = "entry_submission/TOGGLE";

const TOGGLE_ENTRY_SELECTION = "entry_submission/TOGGLE_ENTRY_SELECTION";

const FETCH_ENTRIES = "entry_submission/FETCH_ENTRIES";
const FETCH_ENTRIES_REQUEST = "entry_submission/FETCH_ENTRIES_REQUEST";
const FETCH_ENTRIES_SUCCESS = "entry_submission/FETCH_ENTRIES_SUCCESS";

const CLEAR_ENTRIES = "entry_submission/CLEAR_ENTRIES";

const FETCH_HAS_ENTRIES = "entry_submission/FETCH_HAS_ENTRIES";
const FETCH_HAS_ENTRIES_REQUEST = "entry_submission/FETCH_HAS_ENTRIES_REQUEST";
const FETCH_HAS_ENTRIES_SUCCESS = "entry_submission/FETCH_HAS_ENTRIES_SUCCESS";

const SET_HAS_ENTRIES = "entry_submission/SET_HAS_ENTRIES";

/* HELPERS
================================================================================================ */

/* INITIAL STATES
================================================================================================ */
const initialState = {
  submittingFor: null,
  selectingEntry: false,
  entries: [],
  isFetchingEntries: false,
  hasEntries: false,
};

/* REDUCERS
================================================================================================ */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE:
      return {
        ...state,
        submittingFor: action.payload,
      };
    case TOGGLE_ENTRY_SELECTION:
      return {
        ...state,
        selectingEntry: !state.selectingEntry,
      };
    case FETCH_ENTRIES_REQUEST:
      return {
        ...state,
        isFetchingEntries: true,
        entriesQueryStr: action.queryStr,
      };
    case FETCH_ENTRIES_SUCCESS:
      /* handle race conditions by only updating the state with the result of the
         last request made */
      if (state.entriesQueryStr !== action.queryStr) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        isFetchingEntries: false,
        entries: action.payload.results,
      };
    case CLEAR_ENTRIES:
      return {
        ...state,
        entries: [],
      };
    case FETCH_HAS_ENTRIES_REQUEST:
      return {
        ...state,
        hasEntries: false,
      };
    case FETCH_HAS_ENTRIES_SUCCESS:
      return {
        ...state,
        hasEntries: action.payload.results.length > 0,
      };
    case SET_HAS_ENTRIES:
      return {
        ...state,
        hasEntries: action.payload,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS
 ================================================================================================ */
export const toggle = (submittingFor) => (dispatch) => {
  return dispatch({
    type: TOGGLE,
    payload: submittingFor,
  });
};

export const toggleEntrySelection = () => (dispatch) => {
  return dispatch({
    type: TOGGLE_ENTRY_SELECTION,
  });
};

const getEntriesAPIUrl = ({ projectId, state, query }) => {
  const user = state.user.profile_data;
  const extraAPIQuery = {
    entry_eliminated: false,
    entry_withdrawn: false,
    entry_author_username: user.username,
  };
  const queryStr = getAPIQueryString(PARENT_ENTRIES_RESOURCE_NAME, query, {
    extraAPIQuery,
  });

  return {
    queryStr,
    url: `/api/v1/projects/${projectId}/entries/?${queryStr}`,
  };
};

export const fetchEntries = (projectId, query) => (dispatch, getState) => {
  const { queryStr, url } = getEntriesAPIUrl({
    projectId,
    query,
    state: getState(),
  });
  const promise = request.get(url);

  /* save page count in pager state */
  promise.then((res) =>
    dispatch(savePageCount(PARENT_ENTRIES_RESOURCE_NAME, res))
  );

  return dispatch({
    type: FETCH_ENTRIES,
    queryStr,
    promise,
  });
};

export const clearEntries = () => {
  return {
    type: CLEAR_ENTRIES,
  };
};

export const fetchHasEntries = (projectId) => (dispatch, getState) => {
  const { queryStr, url } = getEntriesAPIUrl({
    projectId,
    query: {},
    state: getState(),
  });

  return dispatch({
    type: FETCH_HAS_ENTRIES,
    promise: request.get(url),
  });
};

export const setHasEntries = (payload) => {
  return {
    type: SET_HAS_ENTRIES,
    payload,
  };
};
